import { BooleanAsString, PayloadAction } from 'src/app/store/app.types';
import { ProfileTypes } from 'src/core/department/department.types';

export enum PatientGender {
  MALE = 'male',
  FEMALE = 'female',
  UNSPECIFIED = 'unspecified',
  SELECTOPTION = '',
}

export enum PatientIsPregnant {
  TRUE = BooleanAsString.TRUE,
  FALSE = BooleanAsString.FALSE,
}

export enum PatientHasPortalAccess {
  TRUE = BooleanAsString.TRUE,
  FALSE = BooleanAsString.FALSE,
}

export type PatientFormState = {
  grantedPermissions: string[];
  profileType: ProfileTypes;
  patientInfo: {
    allowPatientAccess: boolean;
    gender?: PatientGender;
  };
  healthInfo: {
    pregnant?: PatientIsPregnant;
    gender?: PatientGender;
    associatedProfessional?: string;
  };
};

export type SwitchPatientParams = {
  patientId: number;
};

export type ClearPatientStateParams = {
  clearRelatedPatientData: boolean;
};

export enum PatientActionType {
  SWITCH_PATIENT = 'PATIENT/SWITCH',
  CLEAR_PATIENT_STATE = 'PATIENT/CLEAR_PATIENT_STATE',
}

export type SwitchPatientAction = PayloadAction<
  PatientActionType.SWITCH_PATIENT,
  SwitchPatientParams
>;

export type ClearPatientStateAction = PayloadAction<
  PatientActionType.CLEAR_PATIENT_STATE,
  ClearPatientStateParams
>;

export type PatientActions = SwitchPatientAction | ClearPatientStateAction;
