export const HELP_DOCUMENTATION_EVENTS_KEYS = {
  HELP_DOCUMENTATION_OPENED: 'Help Documentation Opened',
};

export const HELP_DOCUMENTATION_SEGMENT_KEYS = {
  HELP_OPTION: 'Help Option',
};

export const HELP_DOCUMENTATION_SEGMENT_VALUES = {
  USER_GUIDES: 'User Guides',
  PRODUCT_INFORMATION: 'Product Information',
  SUPPORT_CENTER: 'Support Center',
};
