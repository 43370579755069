import React from 'react';

import { InsulinDetailsContainer } from 'src/domains/diagnostics/widgets/insulin-details/insulin-details.container';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';

import {
  DetailSection,
  DetailWrapperDiv,
  BasalRateSection,
  BasalRateContent,
  BasalBolusDetailLabel,
} from './insulin.style';
import { BolusTable } from './components/bolus-table/bolus-table.component';
import { testId } from '@roche/roche-common';

const BasalRateContentWithLoader = withGraphLoader(
  BasalRateContent,
  'graphs.insulin.loading',
);

export const Insulin = ({
  bolusTableData,
  hasData,
  isLoading,
  expanded,
  hasBolusData,
}) => (
  <BasalRateSection {...testId('graph-template', 'basal-bolus-rate')}>
    <BasalRateContentWithLoader isLoading={isLoading} hasError={!hasData}>
      <DetailSection>
        <DetailWrapperDiv expanded={expanded}>
          <InsulinDetailsContainer expanded={expanded} />
        </DetailWrapperDiv>
        <BasalBolusDetailLabel expanded={expanded}>
          <LocalizedText textKey={'graphs.bolus.bolus'} />
        </BasalBolusDetailLabel>
        <BolusTable
          expanded={expanded}
          bolusTableData={bolusTableData}
          hasBolusData={hasBolusData}
        />
      </DetailSection>
    </BasalRateContentWithLoader>
  </BasalRateSection>
);
