import React from 'react';

import { translate } from 'src/i18n';

import { SvgIcon } from 'src/components/icon/icon.component';

export const CircleLowComponent = ({
  height,
  width,
  title = 'circle-low',
  fillColor,
}) => {
  const originalWidth = width ? width : 35;
  const originalHeight = height ? height : 38;
  const aspectRatio = width / height;
  const originalFillColor = fillColor ? fillColor : '#f5a724';
  return (
    <SvgIcon
      title={translate(title)}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      fillColor={originalFillColor}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Patient-Card-/-Expanded-/-Greta"
          transform="translate(-1095.000000, -132.000000)"
        >
          <g
            id="Icon/-circle-LOW"
            transform="translate(1095.000000, 132.000000)"
          >
            <g
              id="Group-2"
              transform="translate(18.000000, 18.000000) rotate(-180.000000) translate(-18.000000, -18.000000) translate(0.000000, -0.000000)"
            >
              <circle
                id="Oval"
                fill={fillColor}
                cx="18"
                cy="18"
                r="18"
              ></circle>
              <polyline
                id="Path"
                stroke="#FFFFFF"
                strokeWidth="1.98"
                strokeLinecap="round"
                fillRule="nonzero"
                transform="translate(18.000000, 17.100000) rotate(-315.000000) translate(-18.000000, -17.100000) "
                points="13.5 21.6 13.5 12.6 22.5 12.6"
              ></polyline>
              <line
                x1="18"
                y1="13.05"
                x2="18"
                y2="25.65"
                id="Line-4"
                stroke="#FFFFFF"
                strokeWidth="1.98"
                strokeLinecap="round"
                fillRule="nonzero"
              ></line>
            </g>
            <g id="Group" transform="translate(10.800000, 9.900000)"></g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const CircleLow = CircleLowComponent;
