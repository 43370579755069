import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  UnblindBodyData,
  UnblindPatientErrorResponse,
} from 'src/services/patient/unblind-clinical-study-patient/unblind-clinical-study-patient.types';
import {
  UnblindPatientStartAction,
  clearUnblindClinicalStudyPatientAction,
} from 'src/domains/patient/scenes/clinical-study/store/unblind-clinical-study-patient.types';
import {
  UnblindPatientActionType,
  UnblindPatientErrorAction,
  UnblindPatientSuccessAction,
} from './unblind-clinical-study-patient.types';

export const unblindClinicalStudyPatientStart = (
  payload: string,
): UnblindPatientStartAction =>
  createPayloadAction(UnblindPatientActionType.UNBLIND_PATIENT_START, payload);

export const unblindClinicalStudyPatientSuccess = (
  payload: UnblindBodyData,
): UnblindPatientSuccessAction =>
  createPayloadAction(
    UnblindPatientActionType.UNBLIND_PATIENT_SUCCESS,
    payload,
  );

export const unblindClinicalStudyPatientError = (
  payload: UnblindPatientErrorResponse,
): UnblindPatientErrorAction =>
  createPayloadAction(UnblindPatientActionType.UNBLIND_PATIENT_ERROR, payload);

export const clearUnblindClinicalStudyPatient =
  (): clearUnblindClinicalStudyPatientAction =>
    createAction(UnblindPatientActionType.CLEAR_UNBLIND_PATIENT_STATE);
