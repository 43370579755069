import { mockProfessionalsResponse } from './get-professionals.mock';
import {
  FetchProfessionalLoaderImpl,
  FetchProfessionalServiceImpl,
  FetchProfessionalTransformImpl,
} from './get-professionals.service';

export const mockLoader = () => Promise.resolve(mockProfessionalsResponse);

export const FetchProfessionalFactoryImpl = ({ devMode }) => {
  const load = devMode ? mockLoader : FetchProfessionalLoaderImpl;
  const transform = FetchProfessionalTransformImpl;
  return FetchProfessionalServiceImpl(load, transform);
};
