import { createPayloadAction, createAction } from 'src/app/store/app.actions';
import { FETCH_DEVICE_SETTINGS_LIST_REQUEST } from './device-settings-list.constant';
import {
  FetchDeviceSettingsListStartAction,
  FetchDeviceSettingsListSuccessAction,
} from './device-settings-list.types';

export const fetchSettingsListStart = (
  params,
): FetchDeviceSettingsListStartAction =>
  createPayloadAction(FETCH_DEVICE_SETTINGS_LIST_REQUEST.START, params);

export const fetchSettingsListSuccess = (
  params,
): FetchDeviceSettingsListSuccessAction =>
  createPayloadAction(FETCH_DEVICE_SETTINGS_LIST_REQUEST.SUCCESS, params);

export const fetchSettingsListError = () =>
  createAction(FETCH_DEVICE_SETTINGS_LIST_REQUEST.ERROR);
