import { requestSequence } from 'src/core/request/request.epics';

import {
  GET_CLINIC_GUIDES_REQUEST,
  SAVE_CLINIC_GUIDE_REQUEST,
  DELETE_CLINIC_GUIDE_REQUEST,
} from './clinic-guide.constants';
import {
  getClinicGuidesRequest,
  hideCustomClinicGuidesForm,
} from './clinic-guide.actions';

export const getClinicGuidesEpic = (getClinicGuidesService) =>
  requestSequence({
    service: getClinicGuidesService,
    actionTypes: GET_CLINIC_GUIDES_REQUEST,
    responseTransform: (data, action) => ({
      clinicGuides: data,
      id: action.payload.id,
    }),
  });

export const saveClinicGuideEpic = (saveClinicGuideService) =>
  requestSequence({
    service: saveClinicGuideService,
    actionTypes: SAVE_CLINIC_GUIDE_REQUEST,
  });

export const deleteClinicGuideEpic = (deleteClinicGuideService) =>
  requestSequence({
    service: deleteClinicGuideService,
    actionTypes: DELETE_CLINIC_GUIDE_REQUEST,
  });

export const refreshClinicGuidesEpic = () => (action$) =>
  action$
    .ofType(
      SAVE_CLINIC_GUIDE_REQUEST.SUCCESS,
      DELETE_CLINIC_GUIDE_REQUEST.SUCCESS,
    )
    .mapTo(getClinicGuidesRequest.start());

export const onSaveClinicGuideEpic = () => (action$, _state) =>
  action$
    .ofType(SAVE_CLINIC_GUIDE_REQUEST.SUCCESS)
    .mapTo(hideCustomClinicGuidesForm());
