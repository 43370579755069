import {
  Hba1cCardActions,
  Hba1cCardActionType,
  Hba1cCardState,
} from './hba1c-card.types';

export const HBA1C_CARD_INITIAL_STATE: Hba1cCardState = {
  fhirId: null,
  isFetchingHba1cCard: false,
  isFetchingHba1cCardError: false,
  hba1cCardInfo: null,
};

export const hba1cCardReducer = (
  state = HBA1C_CARD_INITIAL_STATE,
  action: Hba1cCardActions,
): Hba1cCardState => {
  switch (action.type) {
    case Hba1cCardActionType.FETCH_HBA1C_CARD_START:
      return {
        ...HBA1C_CARD_INITIAL_STATE,
        isFetchingHba1cCard: true,
      };
    case Hba1cCardActionType.FETCH_HBA1C_CARD_SUCCESS:
      return {
        ...state,
        hba1cCardInfo: action.payload,
        isFetchingHba1cCard: false,
      };
    case Hba1cCardActionType.FETCH_HBA1C_CARD_ERROR:
      return {
        ...state,
        isFetchingHba1cCard: false,
        isFetchingHba1cCardError: true,
      };
    default:
      return state;
  }
};
