import { dissoc, not } from 'ramda';
import { convertISO, toFormat } from 'src/utils/date';
import { CreatePatientActionType } from 'src/widgets/patient/create-patient/store/create-patient.types';

import {
  DeviceAssignmentActionType,
  DeviceAssignmentReducerActions,
  DeviceAssignmentState,
  GetAlreadyAssignedPatientSuccessAction,
  GetCreatedPatient,
  GetDeviceAssociationErrorAction,
  GetDeviceAssociationStartAction,
  GetDeviceAssociationSuccessAction,
  SelectPatientAction,
} from './device-assignment.types';

const STATE_BEFORE_PATIENT_SELECTION = {
  hasConfirmedDevice: true,
  selectedPatientId: undefined,
  newPatient: undefined,
  displayCreatePatientView: false,
};

export const INITIAL_DEVICE_ASSIGNMENT_STATE: DeviceAssignmentState = {
  isComplete: false,
  associationId: undefined,
  hasConfirmedDevice: false,
  deviceInfo: undefined,
  associatedPatientId: undefined,
  associatedPatient: undefined,
  selectedPatientId: undefined,
  displayCreatePatientView: false,

  isFetchingAssociation: false,
  isUpdatingAssociation: false,
  associationError: undefined,
  updatingError: undefined,
  newPatient: undefined,

  fullName: '',
};

const selectPatient = (
  state: DeviceAssignmentState,
  action: SelectPatientAction,
) => ({
  ...state,
  selectedPatientId: action.payload,
});

const deselectPatient = (state: DeviceAssignmentState) => ({
  ...state,
  ...STATE_BEFORE_PATIENT_SELECTION,
});

const getDeviceAssociationStart = (
  state: DeviceAssignmentState,
  action: GetDeviceAssociationStartAction,
) => ({
  ...state,
  isFetchingAssociation: true,
  associationId: action.payload.associationId,
});

const getDeviceAssociationSuccess = (
  state: DeviceAssignmentState,
  action: GetDeviceAssociationSuccessAction,
) => ({
  ...state,
  associatedPatientId: action.payload.patientId,
  deviceInfo: action.payload.deviceInfo,
  isFetchingAssociation: false,
});

const getDiviceAssociationError = (
  state: DeviceAssignmentState,
  action: GetDeviceAssociationErrorAction,
) => ({
  ...state,
  associationError: action.payload,
});

const updateDeviceAssociationStart = (state: DeviceAssignmentState) => ({
  ...state,
  isUpdatingAssociation: true,
});

const updateDeviceAssociationError = (state: DeviceAssignmentState) => ({
  ...state,
  updatingError: true,
  isUpdatingAssociation: false,
});

const updateDeviceAssociationSuccess = (state: DeviceAssignmentState) => ({
  ...state,
  isUpdatingAssociation: false,
  isComplete: true,
});

const getAlreadyAssignedPatientSuccess = (
  state: DeviceAssignmentState,
  action: GetAlreadyAssignedPatientSuccessAction,
) => ({
  ...state,
  associatedPatient: action.payload,
});

const resetAssignment = () => INITIAL_DEVICE_ASSIGNMENT_STATE;

const confirmDevice = (state: DeviceAssignmentState) => ({
  ...state,
  hasConfirmedDevice: true,
});

const toggleCreatePatientView = (state: DeviceAssignmentState) => ({
  ...state,
  displayCreatePatientView: not(state.displayCreatePatientView),
});

const cancelConfirmedDevice = (state: DeviceAssignmentState) => ({
  ...state,
  hasConfirmedDevice: false,
});

const getCreatedPatientSuccess = (
  state: DeviceAssignmentState,
  action: GetCreatedPatient,
) => {
  const newPatient = dissoc('dateOfBirth', {
    ...action.payload,
    birthDate: toFormat('dd/LL/yyyy')(convertISO(action.payload.dateOfBirth)),
  });
  return {
    ...state,
    newPatient,
  };
};

type ActionHandler = (state: unknown, action: unknown) => DeviceAssignmentState;

const actionHandlers: { [key: string]: ActionHandler } = {
  [DeviceAssignmentActionType.SELECT_PATIENT]: selectPatient,
  [DeviceAssignmentActionType.DESELECT_PATIENT]: deselectPatient,
  [DeviceAssignmentActionType.GET_DEVICE_ASSOCIATION_START]:
    getDeviceAssociationStart,
  [DeviceAssignmentActionType.GET_DEVICE_ASSOCIATION_SUCCESS]:
    getDeviceAssociationSuccess,
  [DeviceAssignmentActionType.GET_DEVICE_ASSOCIATION_ERROR]:
    getDiviceAssociationError,
  [DeviceAssignmentActionType.UPDATE_DEVICE_ASSOCIATION_START]:
    updateDeviceAssociationStart,
  [DeviceAssignmentActionType.UPDATE_DEVICE_ASSOCIATION_ERROR]:
    updateDeviceAssociationError,
  [DeviceAssignmentActionType.UPDATE_DEVICE_ASSOCIATION_SUCCESS]:
    updateDeviceAssociationSuccess,
  [DeviceAssignmentActionType.GET_ALREADY_ASSIGNED_PATIENT_SUCCESS]:
    getAlreadyAssignedPatientSuccess,
  [DeviceAssignmentActionType.RESET_ASSIGNMENT]: resetAssignment,
  [DeviceAssignmentActionType.CONFIRM_DEVICE]: confirmDevice,
  [DeviceAssignmentActionType.TOGGLE_CREATE_PATIENT_VIEW]:
    toggleCreatePatientView,
  [DeviceAssignmentActionType.CANCEL_CONFIRMED_DEVICE]: cancelConfirmedDevice,
  [CreatePatientActionType.GET_CREATED_PATIENT_SUCCESS]:
    getCreatedPatientSuccess,
};

export const deviceAssignmentReducer = (
  state = INITIAL_DEVICE_ASSIGNMENT_STATE,
  action: DeviceAssignmentReducerActions,
): DeviceAssignmentState => {
  const handler = actionHandlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
};
