import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  FetchLanguagesError,
  FetchLanguagesStart,
  FetchLanguagesSuccess,
  LanguagesActionType,
  LanguagesPayload,
} from './languages.types';

export const fetchLanguagesStart = (): FetchLanguagesStart =>
  createAction(LanguagesActionType.FETCH_LANGUAGES_START);

export const fetchLanguagesSuccess = (
  payload: LanguagesPayload,
): FetchLanguagesSuccess =>
  createPayloadAction(LanguagesActionType.FETCH_LANGUAGES_SUCCESS, payload);

export const fetchLanguagesError = (error: any): FetchLanguagesError =>
  createPayloadAction(LanguagesActionType.FETCH_LANGUAGES_ERROR, error);
