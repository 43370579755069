import * as React from 'react';
import { Time } from './time-tracker.style';
import { ClockIcon } from 'src/assets/icons';
import { formatTime } from './time-tracker.utils';
import { ITimeTrackerProps } from './time-tracker.types';

class TimeTrackerComponent extends React.Component<ITimeTrackerProps, {}> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { startTime = 0 } = this.props;
    return (
      <>
        <ClockIcon />
        <Time>{formatTime(startTime)}</Time>
      </>
    );
  }
}

export const TimeTracker = TimeTrackerComponent;
