import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { RectangleMarkIcon, HiValueIcon, LoValueIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';

import { LegendItem } from './legend-item.component';

import {
  LegendList,
  LegendListsContainer,
  LegendListSectionHeader,
} from '../graph-legend.style';

export const LogbookGraphLegendComponent = ({ t, _graph }) => (
  <LegendListsContainer>
    <LegendList noBorder width={'30%'}>
      <LegendListSectionHeader>
        {t('graphDetails.legend.targetRangeSectionHeader')}
      </LegendListSectionHeader>
      <LegendItem
        icon={
          <RectangleMarkIcon
            fillColor={colors.quartzBlue}
            strokeColor={colors.quartzBlue}
          />
        }
        label={t('graphDetails.legend.aboveTargetRange')}
      />
      <LegendItem
        icon={
          <RectangleMarkIcon
            fillColor={colors.trafficRed2}
            strokeColor={colors.trafficRed2}
          />
        }
        label={t('graphDetails.legend.belowTargetRange')}
      />
      <LegendItem
        icon={
          <RectangleMarkIcon
            fillColor={colors.white}
            strokeColor={colors.trafficRed2}
          />
        }
        label={t('graphDetails.legend.hypoSymptoms')}
      />
    </LegendList>

    <LegendList noBorder width={'30%'}>
      <LegendListSectionHeader />
      <LegendItem />
      <LegendItem />
      <LegendItem
        icon={<HiValueIcon height={10} />}
        label={t('graphDetails.legend.hiValue')}
      />

      <LegendItem
        icon={<LoValueIcon height={10} />}
        label={t('graphDetails.legend.loValue')}
      />
    </LegendList>
  </LegendListsContainer>
);

export const LogbookGraphLegend = withTranslation(LogbookGraphLegendComponent);
