import { Action, PayloadAction } from 'src/app/store/app.types';
import {
  InvitePatientParams,
  InvitePatientTransformedResponseType,
} from '../../../../../../services/rdac/invite-patient/invite-patient.types';

export enum InvitePatientActionType {
  INVITE_PATIENT_START = 'INVITE_PATIENT_START',
  INVITE_PATIENT_SUCCESS = 'INVITE_PATIENT_SUCCESS',
  INVITE_PATIENT_ERROR = 'INVITE_PATIENT_ERROR',
  RESET_INVITE = 'RESET_RDAC_INVITE',
}

export type InvitePatientPayloadType = InvitePatientParams;

export type InvitePatientError = {
  error: string;
  message: string;
};

export type InvitePatientSuccessPayload = InvitePatientTransformedResponseType;

export type InvitePatientErrorPayload = InvitePatientTransformedResponseType;

export type InvitePatientStartAction = PayloadAction<
  InvitePatientActionType.INVITE_PATIENT_START,
  any
>;

export type InvitePatientSuccessAction = PayloadAction<
  InvitePatientActionType.INVITE_PATIENT_SUCCESS,
  InvitePatientTransformedResponseType
>;

export type InvitePatientErrorAction = PayloadAction<
  InvitePatientActionType.INVITE_PATIENT_ERROR,
  InvitePatientErrorPayload
>;

export type ResetInviteAction = Action<InvitePatientActionType.RESET_INVITE>;

export type InvitePatientActions =
  | InvitePatientStartAction
  | InvitePatientSuccessAction
  | InvitePatientErrorAction
  | ResetInviteAction;

export type InvitePatientState = {
  isInvitingPatient: boolean;
  inviteWasSuccessful: boolean;
  inviteHasError: boolean;
};
