import { BLOOD_GLUCOSE_UNITS } from '../store/constants';

export const NUMBER_OF_BEDTIME_NIGHT_SECTION_CELLS = 3;
export const NUMBER_OF_BEFORE_MEALTIME_CELLS = 3;
export const NUMBER_OF_BEFORE_AND_AFTER_MEALTIME_SECTION_CELLS = 5;

export const MEAL_TIMES = {
  BEDTIME: 'BEDTIME',
  BEFORE_BREAKFAST: 'BEFORE_BREAKFAST',
  AFTER_BREAKFAST: 'AFTER_BREAKFAST',
  BEFORE_LUNCH: 'BEFORE_LUNCH',
  AFTER_LUNCH: 'AFTER_LUNCH',
  BEFORE_DINNER: 'BEFORE_DINNER',
  AFTER_DINNER: 'AFTER_DINNER',
  NIGHT: 'NIGHT',
};

export const LOGBOOK_UNITS = {
  GLUCOSE: 'glucose',
  CARBOHYDRATES: 'carbohydrates',
  BOLUS: 'bolus',
};

export const LOGBOOK_UNITS_UNIT = {
  CARBOHYDRATES: 'general.units.g',
  BOLUS: 'general.units.U',
  [BLOOD_GLUCOSE_UNITS.MG_PER_DL]: 'general.units.mgPerDL',
  [BLOOD_GLUCOSE_UNITS.MMOL_PER_L]: 'general.units.mmolPerL',
  INSULIN: 'U',
};

export const LOGBOOK_STATUS_COLOR = {
  TRANSPARENT: 'TRANSPARENT',
  GREEN: 'GREEN',
  RED: 'RED',
  BLUE: 'BLUE',
};

export const LOGBOOK_ROW_HEIGHT = 40;
export const LOGBOOK_ROW_PADDING = 32;

// Used to change the flow of the Tabs component to render a dropdown menu.
// Tabs is used in graphs-wrapper.component
export const LOGBOOK_HAS_DROPDOWN = false;

export const LOGBOOK_MODAL_WIDTH = 200;

export const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const DAY_START_MS = 0;
