import React from 'react';
import { NavLink } from 'react-router-dom';
import { testId } from '@roche/roche-common';

import styles from './styles.module.css';
import {
  JELLO_AVATAR_SIZES,
  JELLO_AVATAR_COLORS,
  JELLO_POPOVER_ALIGNMENTS,
} from 'src/app/app.jello.constants';
import { JelloAvatarComponent } from 'src/components/jello-avatar/jello-avatar.component';
import { JelloPopoverComponent } from 'src/components/jello-popover/jello-popover.component';
import { IconTextLinkComponent } from 'src/widgets/top-navigation-jello/components/icon-text-link/icon-text-link.component';
import { t } from 'i18next';
import { UserActivityContext } from 'src/app/session/components/user-activity/user-activity.component';
import { countlyEventTrigger } from 'src/navigation/countly';
import {
  USER_AVATAR_TEST_ID_BASE,
  USER_DROPDOWN_EVENTS_KEYS,
  USER_DROPDOWN_LIST,
  USER_DROPDOWN_LIST_ITEM_TEST_ID_BASE,
  USER_DROPDOWN_SEGMENT_KEYS,
  USER_DROPDOWN_SEGMENT_VALUES,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { customDatadogLog } from 'src/app/logger/utils';
import { LOGS } from 'src/app/logger/constants';
import { WithPermissions } from 'src/utils/with-permissions/with-permissions.container';

type UserAvatarProps = {
  name: string;
  routes: object;
};

export const UserAvatarComponent: React.FunctionComponent<UserAvatarProps> = ({
  name,
  routes,
}) => {
  // @ts-ignore
  const { onSignOutAction } = React.useContext(UserActivityContext);

  const onSingOut = () => {
    countlyEventTrigger(USER_DROPDOWN_EVENTS_KEYS.USER_SIGNED_OUT, {
      [USER_DROPDOWN_SEGMENT_KEYS.SIGNOUT_METHOD]:
        USER_DROPDOWN_SEGMENT_VALUES.MANUAL,
    });
    customDatadogLog('session', LOGS.signOutDropdown);
    onSignOutAction();
  };

  const userDropdownElementOnClick = {
    onSingOut,
  };

  return (
    <JelloPopoverComponent
      mainTestId={USER_AVATAR_TEST_ID_BASE}
      accessibilityText={name}
      popoverAlignment={JELLO_POPOVER_ALIGNMENTS.END}
      clickableComponent={{
        Component: JelloAvatarComponent,
        id: USER_AVATAR_TEST_ID_BASE,
        color: JELLO_AVATAR_COLORS.ACCENT_08,
        size: JELLO_AVATAR_SIZES.S,
        label: name,
      }}
    >
      <ul className={styles.list}>
        {USER_DROPDOWN_LIST.map((item) =>
          item.isLink ? (
            <li
              key={item.key}
              className={styles.liElement}
              {...testId(USER_DROPDOWN_LIST_ITEM_TEST_ID_BASE, item.key)}
            >
              <WithPermissions hasPermissions={item.permissions}>
                <NavLink
                  exact
                  className={styles.externalLink}
                  to={routes[item.baseRoute][item.specificRoute]}
                >
                  <IconTextLinkComponent
                    iconName={item.iconName}
                    linkText={t(item.linkText)}
                  />
                </NavLink>
              </WithPermissions>
            </li>
          ) : (
            <li
              key={item.key}
              className={styles.liElement}
              onClick={userDropdownElementOnClick[item.onClick]}
              {...testId(USER_DROPDOWN_LIST_ITEM_TEST_ID_BASE, item.key)}
            >
              <IconTextLinkComponent
                iconName={item.iconName}
                linkText={t(item.linkText)}
              />
            </li>
          ),
        )}
      </ul>
    </JelloPopoverComponent>
  );
};
