import { dataDogEvents } from 'src/app/logger/middleware';
import { SessionActionType } from 'src/app/session/core/session/session.types';
import { OidcActionTypes } from 'src/app/session/core/oidc/oidc.types';
import { MODAL_ACTIONS } from 'src/domains/diagnostics/core/modal/modal.constants';
import { setForcePersist } from 'src/store';
import { customDatadogLog } from 'src/app/logger/utils';

export const storageKey = 'redux-local-tab-sync';

export const LISTENER_TYPES = [
  'persist/PERSIST',
  MODAL_ACTIONS.REMOVE_ALL_MODALS,
  SessionActionType.SIGN_OUT_SUCCESS,
  SessionActionType.SIGN_OUT_ERROR,
  SessionActionType.SHOW_EXPIRING_MODAL,
  OidcActionTypes.OIDC_FETCH_TOKENS_SUCCESS,
  OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS,
  SessionActionType.VALIDATE_SESSION_SUCCESS,
  SessionActionType.GET_CURRENT_USER_SUCCESS,
];

export const multitabAction = (action) => {
  if (!action.source && !!LISTENER_TYPES.some((e) => e === action.type)) {
    try {
      const wrappedAction = Object.assign({ source: 'another tab' }, action);
      localStorage.setItem(storageKey, JSON.stringify(wrappedAction));
    } catch (error) {
      customDatadogLog('errors', error);
    }
  }
};

export const storageMiddleware = () => {
  return () => (next) => (action) => {
    if (!action.type) return;
    if (action.type === OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS) {
      setForcePersist(action);
    }
    dataDogEvents(action);
    multitabAction(action);
    next(action);
  };
};

export const storageListener = (store) => {
  return () => {
    if (localStorage.getItem(storageKey)) {
      const wrappedAction = JSON.parse(`${localStorage.getItem(storageKey)}`);
      delete wrappedAction.source;
      store.dispatch(wrappedAction);
      localStorage.removeItem(storageKey);
    }
  };
};
