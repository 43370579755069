import {
  assocPath,
  map,
  pipe,
  sort,
  last,
  propOr,
  ifElse,
  isNil,
  always,
  equals,
} from 'ramda';
import { countlyEventTrigger } from 'src/navigation/countly';

import {
  formatDateString,
  isBefore,
  hasSame,
  convertISO,
  jsDateToISOString,
  toJSDate,
} from 'src/utils/date';

import {
  EVENTS_KEYS,
  SEGMENT_KEYS,
  TEST_KEYS,
} from './org-stock.countly.constants';

export const formatHistoryDateString = (dateString) =>
  formatDateString({
    dateString,
    format: 'LLLL d yyyy',
  });
export const replaceStripHistoryDateTime = (stripHistory) =>
  assocPath(
    ['lastShipmentDate'],
    formatHistoryDateString(stripHistory.lastShipmentDate),
    stripHistory,
  );
export const formatOrgStockHistoryDates = map(replaceStripHistoryDateTime);

const compareDates = (d1, d2) =>
  hasSame('hours', d1, d2) ? 0 : isBefore(d1, d2) ? -1 : 1;
const sortByDateAscending = sort(compareDates);
const stripModelToDateTimeLastAddedDate = pipe(
  propOr(null, 'lastShipmentDate'),
  convertISO,
);
const dateTimeToISOString = ifElse(
  isNil,
  always(null),
  pipe(toJSDate, jsDateToISOString),
);
export const findMostRecentLastAddedDate = pipe(
  map(stripModelToDateTimeLastAddedDate),
  sortByDateAscending,
  last,
  dateTimeToISOString,
);

export const countlyDeviceFormatter = ({
  stripModelName,
  stripModelPackageUnits,
}) => ({
  deviceName: `${stripModelName} (${stripModelPackageUnits} ${
    stripModelPackageUnits > 1 ? TEST_KEYS.PLURAL_TEST : TEST_KEYS.SING_TEST
  })`,
  packageUnits: stripModelPackageUnits,
});

export const orgStockCountlyEvent = (prevState, nextState) => {
  let modifiedDeviceValues;
  const modifiedDeviceId = Object.keys(prevState.stripModelStocks)
    .filter(
      (device) =>
        !equals(prevState.stripModelStocks[device], nextState[device]),
    )
    .toString();
  for (const newDeviceValue in prevState.stripModelStocks) {
    if (newDeviceValue === modifiedDeviceId) {
      modifiedDeviceValues = nextState[newDeviceValue];
    }
  }
  const deviceFromList = prevState.stripModels.filter((device) =>
    equals(device.stripModelId, modifiedDeviceId),
  )[0];
  if (deviceFromList && deviceFromList !== undefined) {
    const { deviceName, packageUnits } = countlyDeviceFormatter(deviceFromList);
    const containersRecieved =
      modifiedDeviceValues.lastShipmentNumberOfStripsReceived / packageUnits;

    countlyEventTrigger(EVENTS_KEYS.NEW_STOCK_ADDED, {
      [SEGMENT_KEYS.MODEL]: deviceName,
      [SEGMENT_KEYS.STRIP_CONTAINERS_RECIEVED]: containersRecieved,
    });
  }
};
