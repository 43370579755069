/* tslint:disable */
/* eslint:disable */
import {
  DeviceSettingsServiceImpl,
  DeviceSettingsLoaderImpl,
  DeviceSettingsTransformImpl,
} from './device-settings.service';
import { mockDeviceSettingsResponse } from './device-settings.mock';

const mockLoader = () => Promise.resolve(mockDeviceSettingsResponse);

export const DeviceSettingsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : DeviceSettingsLoaderImpl;
  return DeviceSettingsServiceImpl(loader, DeviceSettingsTransformImpl);
};
