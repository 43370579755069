import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from 'src/core/styles/colors';
import { spacing } from 'src/core/styles/spacing';
import { Div } from 'src/components/div/div.component';
import { TopNavigationHeight } from 'src/navigation/utils/navigation.utils';

export const TopNav = styled.nav`
  height: ${TopNavigationHeight.SHORT}px;
  transition: height 0.2s;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border-bottom: 1px solid var(--jello-color-background-alternative);
  padding: 0.5rem;
`;
TopNav.displayName = 'TopNav';

export const RightSideItems = styled(Div)`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 10px;
`;
RightSideItems.displayName = 'RightSideItems';

export const TopNavSpacer = styled(Div)`
  margin: 0 ${spacing.three};
  height: 28px;
  width: 1px;
  background-color: ${colors.gray3};
`;
TopNavSpacer.displayName = 'TopNavSpacer';

export const AppLogoLink = styled(Link)`
  height: 36px;
`;
AppLogoLink.displayName = 'AppLogoLink';
