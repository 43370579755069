import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';

import { FetchConfigurablesLoaderType } from '../../services/configurables/configurables.types';

import {
  fetchConfigurablesError,
  fetchConfigurablesSuccess,
} from './configurables.actions';
import {
  ConfigurablePermissionsActions,
  ConfigurablePermissionsActionsType,
} from './configurables.types';

export const fetchConfigurablePermissionsEpic: (
  service: FetchConfigurablesLoaderType,
) => Epic<ConfigurablePermissionsActionsType, State> =
  (service) => (action$, store$) =>
    action$
      .ofType(ConfigurablePermissionsActions.FETCH_CONFIGURABLES_START)
      .switchMap(() => {
        const openId = {
          accessToken: selectAccessToken(store$.getState()),
          gigyaToken: selectGigyaToken(store$.getState()),
        };
        return Observable.fromPromise(service(openId))
          .map((data) => fetchConfigurablesSuccess(data))
          .pipe(catchError((err) => Observable.of(fetchConfigurablesError())));
      });
