import styled from 'styled-components';
import { space, width, borderColor, boxShadow } from 'styled-system';

import { borderRadius } from 'src/core/styles/border-radius';
import { ControlText } from 'src/components/forms/control-text/control-text.component';
import { convertPxToRem } from 'src/utils/rem-calc';

export const FormControlWrapper = styled.div`
  position: relative;
  ${space};
`;
export const FormControlIconOnTheRight = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.spacing.three};
`;

export const FormControlInput = styled.input`
  appearance: none;
  display: flex;
  outline: none;
  width: 100%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;

  border-radius: ${borderRadius.six};
  padding: ${(props) => props.theme.spacing.three}
    ${(props) => props.theme.spacing.four};

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, valid }) =>
      valid === false ? theme.colors.red : theme.colors.silverMedium};
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${({ theme }) => theme.fontSize.p};

  transition: ${({ theme }) => theme.transitions.default};
  width: 100%;

  &:placeholder {
    color: ${({ theme }) => theme.colors.grayMedium};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.brandBlue};
    color: ${({ theme }) => theme.colors.brandBlue};
    outline: none;
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.silverMedium};
    border-color: ${({ theme }) => theme.colors.silverDark};
    color: ${({ theme }) => theme.colors.grayMedium};
  }
  ${borderColor};
  ${boxShadow};
  ${width};
  ${space};
`;

export const SearchFormControlInput = styled(FormControlInput)`
  height: ${convertPxToRem(50)};
  padding: ${({ theme }) => theme.spacing.three}
    ${({ theme }) => theme.spacing.three};

  border-radius: ${({ theme }) => theme.borderRadius[0]};
`;

export const InputTextField = FormControlInput.withComponent(ControlText);
InputTextField.displayName = 'InputTextField';

export const InputTextArea = styled(InputTextField.withComponent('textarea'))`
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${(props) => props.theme.fontSize.p};
  min-height: 5rem;
  padding: ${(props) => props.theme.spacing.three};
`;
InputTextArea.displayName = 'InputTextArea';
