import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validateSessionStart } from 'src/app/session/core/session/session.actions';

import { mapDispatchers } from 'src/domains/diagnostics/utils/map-dispatchers';

import { ProtectedRoute as ProtectedRouteComponent } from './protected-route.component';
import { protectedRouteConnector } from './protected-route.selectors';

export const ProtectedRoute = compose(
  withRouter,
  connect(
    protectedRouteConnector,
    mapDispatchers({ validateSession: validateSessionStart }),
  ),
)(ProtectedRouteComponent);
