import { PayloadAction } from 'src/app/store/app.types';

export enum changePasswordActionType {
  IS_CHANGED_PASSWORD_SUCCESS = 'IS_CHANGED_PASSWORD_SUCCESS',
  IS_CHANGED_PASSWORD_RESET = 'IS_CHANGED_PASSWORD_RESET',
}

export type isChangedPassword = {
  changed: boolean;
};

export type GetIsChangedPasswordsSuccessActionType = PayloadAction<
  changePasswordActionType.IS_CHANGED_PASSWORD_SUCCESS,
  isChangedPassword
>;

export type GetIsChangedPasswordsResetActionType = PayloadAction<
  changePasswordActionType.IS_CHANGED_PASSWORD_RESET,
  isChangedPassword
>;

export type ChangesPasswordActions =
  | GetIsChangedPasswordsSuccessActionType
  | GetIsChangedPasswordsResetActionType;
