import React, { Fragment } from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  BolusStandardIcon,
  BolusExtendedIcon,
  BolusMultiwaveIcon,
  BolusQuickIcon,
} from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { addNoDataType } from 'src/domains/diagnostics/widgets/status-card/components/status-card-row/status-card-row.component';
import { NO_DATA_AVAILABLE_OBJECT } from 'src/components/no-data-tooltip/no-data-tooltip.constants';

import { BolusTableHeader } from './bolus-table-header.component';
import { RedCircle } from './red-circle.component';

import { ComparisonBar } from '../insulin-table/comparison-bar.component';
import { ComparisonBarWrapper } from '../insulin-table/comparison-bar.style';
import {
  Column,
  Row,
  SpanWithRightPadding,
} from '../insulin-table/insulin-table.style';
import { formatDecimalAsPercentage } from '../../utils/format-percentage';

export const BolusTableComponent = ({
  bolusTableData = [],
  t,
  expanded,
  hasBolusData,
}) => {
  if (bolusTableData.length === 0) {
    return null;
  }

  const [standardBolus, quickBolus, extendedBolus, multiwaveBolus] =
    bolusTableData;
  const bolusTypes = [
    {
      Icon: BolusStandardIcon,
      textKey: 'graphs.insulin.standardBolus',
      ...standardBolus,
    },
    {
      Icon: BolusQuickIcon,
      textKey: 'graphs.insulin.quickBolus',
      ...quickBolus,
    },
    {
      Icon: BolusExtendedIcon,
      textKey: 'graphs.insulin.extendedBolus',
      ...extendedBolus,
    },
    {
      Icon: BolusMultiwaveIcon,
      textKey: 'graphs.insulin.multiwaveBolus',
      ...multiwaveBolus,
    },
  ];
  return (
    <Fragment>
      <BolusTableHeader expanded={expanded} />
      {bolusTypes.map(
        (
          { Icon, textKey, bolus, remoteBolus, numberOfBolusesPerDay },
          index,
        ) => (
          <Row key={`${textKey}-${index}`}>
            <Column expanded={expanded}>
              <Icon /> <LocalizedText textKey={textKey} />
            </Column>
            <Column expanded={expanded}>
              <ComparisonBarWrapper>
                <ComparisonBar
                  line1Percentage={bolus}
                  line1FillColor={colors.red}
                  fillEmpty={colors.grayNoData}
                />
              </ComparisonBarWrapper>
            </Column>
            <Column expanded={expanded}>
              {!hasBolusData ? (
                addNoDataType(NO_DATA_AVAILABLE_OBJECT)
              ) : (
                <SpanWithRightPadding>
                  <RedCircle />
                  {` ${formatDecimalAsPercentage(bolus)} `}
                </SpanWithRightPadding>
              )}
            </Column>
            <Column expanded={expanded}>
              {!hasBolusData
                ? addNoDataType(NO_DATA_AVAILABLE_OBJECT)
                : `${numberOfBolusesPerDay.toFixed(1)} / ${t(
                    'graphs.bolus.day',
                  )}`}
            </Column>
          </Row>
        ),
      )}
    </Fragment>
  );
};

export const BolusTable = withTranslation(BolusTableComponent);
