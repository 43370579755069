import {
  EMPTY_VALUE_PLACEHOLDER,
  HYPO_RISK_MIN,
  HYPO_RISK_MAX,
  MIN_BG,
} from 'src/domains/diagnostics/store/constants';
import { average } from 'src/utils/stat';
import { BLOOD_GLUCOSE_UNITS } from 'src/domains/diagnostics/store/constants';
import { convertBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

export const roundToNDecimalPlaces = (number, n) => {
  const factor = Math.pow(10, n);
  return Math.round(number * factor) / factor;
};

export const calculateBloodGlucoseIndex = (bloodGlucose) => {
  if (bloodGlucose < HYPO_RISK_MIN) {
    bloodGlucose = HYPO_RISK_MIN;
  } else if (bloodGlucose > HYPO_RISK_MAX) {
    bloodGlucose = HYPO_RISK_MAX;
  } else {
    // eslint-disable-next-line no-empty
  }

  return {
    bgIndex: roundToNDecimalPlaces(
      10 *
        Math.pow(1.509 * (Math.pow(Math.log(bloodGlucose), 1.084) - 5.381), 2),
      1,
    ),
    bloodGlucose,
  };
};

export const getFormattedBloodGlucoseIndex = (
  bloodGlucoseIndexFunction,
  bloodGlucoseUnit,
  measurements = [],
) => {
  if (!measurements.length) {
    return EMPTY_VALUE_PLACEHOLDER;
  }
  return bloodGlucoseIndexFunction(bloodGlucoseUnit, measurements).toFixed(1);
};

export const calculateLowBloodGlucoseIndex = (bloodGlucoseUnit, measurements) =>
  average(
    measurements.map((measurement) => {
      const measurementValue = convertBGMeasurement(
        measurement.value,
        bloodGlucoseUnit,
        BLOOD_GLUCOSE_UNITS.MG_PER_DL,
      );
      return measurementValue < MIN_BG
        ? calculateBloodGlucoseIndex(measurementValue).bgIndex
        : 0;
    }),
  );

export const calculateHighBloodGlucoseIndex = (
  bloodGlucoseUnit,
  measurements,
) =>
  average(
    measurements.map((measurement) => {
      const measurementValue = convertBGMeasurement(
        measurement.value,
        bloodGlucoseUnit,
        BLOOD_GLUCOSE_UNITS.MG_PER_DL,
      );
      return measurementValue > MIN_BG
        ? calculateBloodGlucoseIndex(measurementValue).bgIndex
        : 0;
    }),
  );
