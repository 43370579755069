import React from 'react';
import { NodataToolTipContainer } from 'src/components/no-data-tooltip/no-data-tooltip.container';
import {
  NO_ENOUGH_DATA_OBJECT,
  NO_DATA_AVAILABLE_OBJECT,
} from 'src/components/no-data-tooltip/no-data-tooltip.constants';

import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

import {
  StatusCardRowWrapper,
  StatusCardDetail,
  StatusCardDetailWrapper,
  StatusCardDetailLabel,
  StatusCardDetailCaption,
  TrafficLightContainer,
  StatusCircle,
  CircleText,
  DataContainer,
  StatusTitle,
  StatusResult,
  CircleValue,
  CircleUnit,
} from './status-card-row.style';

export const StatusCardRow = ({
  caption,
  color,
  hasReliableInfo,
  statusLabel,
  showLabels,
  value,
  statusTitle,
  statusResult,
  valueSingle,
  unit,
  hasData,
  showPlaceholderStatusLabelAndHideValues,
  unitTitle,
}) => (
  <StatusCardRowWrapper>
    <TrafficLightContainer>
      <StatusCircle
        color={color}
        emptyInnerCircle={!hasReliableInfo}
        label=""
        showTooltip={true}
        size={20}
        mb={0}
      >
        <CircleText>
          <CircleValue>
            {!hasData
              ? addNoDataType(NO_DATA_AVAILABLE_OBJECT)
              : hasReliableInfo
              ? valueSingle
              : addNoDataType(NO_ENOUGH_DATA_OBJECT)}
          </CircleValue>
          {hasReliableInfo && (
            <CircleUnit {...(unitTitle && { title: unitTitle })}>
              {unit}
            </CircleUnit>
          )}
        </CircleText>
      </StatusCircle>
    </TrafficLightContainer>
    <StatusCardDetailWrapper>
      <StatusCardDetail>
        <StatusCardDetailLabel>
          <RenderIf validate={showLabels}>
            <DataContainer>
              <StatusTitle>{statusTitle}</StatusTitle>
              <StatusResult>{statusResult}</StatusResult>
            </DataContainer>
          </RenderIf>
          <RenderIf validate={!showLabels}>
            <DataContainer>
              <StatusTitle>{statusTitle}</StatusTitle>
              <StatusResult>{'- - '}</StatusResult>
            </DataContainer>
          </RenderIf>
        </StatusCardDetailLabel>
      </StatusCardDetail>
      <RenderIf validate={caption}>
        <StatusCardDetailCaption>{caption}</StatusCardDetailCaption>
      </RenderIf>
    </StatusCardDetailWrapper>
  </StatusCardRowWrapper>
);
export const addNoDataType = (notEnoughDataType) => (
  <NodataToolTipContainer type={notEnoughDataType} />
);
