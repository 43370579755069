import styled, { css } from 'styled-components';

import { colors } from '../colors';
import fonts from '../fonts';

export const highestZIndexInHcpClient = 250;

const ModalOverlayBase = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.transparentBlack};
  overflow: auto;
`;

const ModalWrapperCSS = css`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 24px;
  left: 0;
  right: 0;
  z-index: ${highestZIndexInHcpClient + 5};
`;

export const ModalOverlay = styled.div`
  ${ModalOverlayBase}
  z-index: ${highestZIndexInHcpClient + 4};
`;
export const ErrorModalOverlay = styled.div`
  ${ModalOverlayBase}
  z-index: ${highestZIndexInHcpClient + 6};
`;

export const ModalWrapper = styled.div`
  ${ModalWrapperCSS};
`;

export const ModalWrapperInTheMiddle = styled.div`
  ${ModalWrapperCSS};
  top: 25%;
`;

const ModalCardBase = css`
  border-radius: 0.1875rem;
  box-shadow: 0 0.5625rem 0.8125rem 0.125rem rgba(51, 51, 51, 0.14),
    0 0.1875rem 1rem 0.1875rem rgba(51, 51, 51, 0.12),
    0 0.3125rem 0.375rem 0 rgba(51, 51, 51, 0.2);
  display: inline-block;
  position: relative;
  background: #fff;
  width: 65em;
  font-size: 16px;
`;

export const ModalCard = styled.div`
  ${ModalCardBase}
`;

export const ErrorModalCard = styled.div`
  ${ModalCardBase}
`;

export const ModalBody = styled.div`
  font-family: ${fonts.type.mainFont};
`;

export const ModalBottom = styled.div`
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: stretch;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  padding: 0 2rem;
`;

export const ModalHeader = styled.div`
  display: flex;
  color: ${colors.white};
  background-color: #004d99;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
  font-size: 1em;
  text-transform: uppercase;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
`;

export const CreateNoteModalContent = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2em 2em 0 2em;
  overflow-y: scroll;
  max-height: 80vh;
`;

export const ModalErrorMainText = styled.div`
  color: ${colors.black};
  margin: 1.5em;
  font-size: 1.2em;
`;

export const ModalErrorSubText = styled.div`
  color: ${colors.anotherGray};
  margin-bottom: 2em;
  font-size: 0.9em;
`;

export const ModalErrorIcon = styled.div`
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0.18em rgba(207, 2, 27, 0.3);
  height: 74px;
  width: 74px;
  background: ${colors.red};
  color: ${colors.white};
  font-size: 2.8em;
  margin: 0.3em;
`;

export const InnerModalErrorOverlay = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: calc(100% - 54px);
  display: inline-block;
  position: absolute;
  top: 54px;
  right: 0;
`;
