import React from 'react';
import { getConfig, testId } from '@roche/roche-common';

import styles from './styles.module.css';
import { IconTextLinkComponent } from 'src/widgets/top-navigation-jello/components/icon-text-link/icon-text-link.component';
import {
  JELLO_BUTTON_VARIANTS,
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_NAMES,
  JELLO_POPOVER_ALIGNMENTS,
  JELLO_TOOLTIP_POSITIONS,
} from 'src/app/app.jello.constants';
import { JelloIconButton } from 'src/components/jello-icon-button/jello-icon-button.component';
import { JelloPopoverComponent } from 'src/components/jello-popover/jello-popover.component';
import { INFOS } from 'src/domains/diagnostics/components/additional-info/additional-info.constants';
import { AdditionalInfo } from 'src/domains/diagnostics/components/additional-info/additional-info.container';
import {
  countlyHandleListItemLink,
  getVersionIFUForLinkPDF,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.utils';
import {
  HELP_ELEMENT_LIST,
  HELP_MENU_ID,
  HELP_MENU_LABEL,
  HELP_MENU_MODAL_BASE_ID,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { t } from 'i18next';
import { HelpCenterProps } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';

const { REACT_APP_IFUS, REACT_APP_VERSION, REACT_APP_ACC_VERSION } =
  getConfig();

export const HelpCenterComponent: React.FunctionComponent<HelpCenterProps> = (
  props,
) => {
  const appVersion = getVersionIFUForLinkPDF({
    rebranding: props.rebranding,
    appVersion: REACT_APP_VERSION,
    appAccVersion: REACT_APP_ACC_VERSION,
  });

  return (
    <JelloPopoverComponent
      mainTestId={HELP_MENU_ID}
      accessibilityText={t(HELP_MENU_LABEL)}
      popoverAlignment={JELLO_POPOVER_ALIGNMENTS.CENTER}
      tooltipProps={{
        whitTooltip: true,
        tooltipPosition: JELLO_TOOLTIP_POSITIONS.BOTTOM,
        tooltipLabel: t(HELP_MENU_LABEL) || undefined,
      }}
      clickableComponent={{
        Component: JelloIconButton,
        iconName: JELLO_ICON_NAMES.HELP,
        size: JELLO_ICON_BUTTON_SIZES.L,
        variant: JELLO_BUTTON_VARIANTS.TERTIARY,
        accessibilityText: t(HELP_MENU_LABEL),
      }}
      popoverStyle={styles.popoverIndex}
    >
      <ul className={styles.list}>
        {HELP_ELEMENT_LIST.map((item) => (
          <li
            key={item.key}
            className={styles.liElement}
            onClick={() => countlyHandleListItemLink(item.countlySegmentValue)}
          >
            {item.isAdditionalInfo ? (
              <AdditionalInfo info={INFOS.gettingStarted}>
                <IconTextLinkComponent
                  iconName={item.iconName}
                  linkText={t(item.linkText)}
                />
              </AdditionalInfo>
            ) : item.onClickModal ? (
              <div
                onClick={() => props.createModal({ key: item.modalKey })}
                {...testId(item.modalKey, HELP_MENU_MODAL_BASE_ID)}
              >
                <IconTextLinkComponent
                  iconName={item.iconName}
                  linkText={t(item.linkText)}
                />
              </div>
            ) : (
              <jello-link
                class={styles.externalLink}
                href={`${item.link}`
                  .replace('{{locale}}', `${props.locale}`)
                  .replace('{{version}}', `${appVersion}`)
                  .replace('{{REACT_APP_IFUS}}', REACT_APP_IFUS)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconTextLinkComponent
                  iconName={item.iconName}
                  linkText={t(item.linkText)}
                />
              </jello-link>
            )}
          </li>
        ))}
      </ul>
    </JelloPopoverComponent>
  );
};
