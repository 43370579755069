import styled, { StyledFunction } from 'styled-components';
import { colors } from 'src/core/styles/colors';
import { fontSize } from 'src/core/styles/font-sizes';

export type ModalProps = {};

const ModalDiv: StyledFunction<ModalProps> = styled.div;

export const Modal = ModalDiv`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    width: 660px;
    height: 389px;
    background: ${colors.white};
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 25px 0px rgba(6, 77, 50, 0.08);
    border-radius: 4px;
`;

export type ModalHeaderProps = {};

const ModalHeaderDiv: StyledFunction<ModalHeaderProps> = styled.div;

export const ModalHeader = ModalHeaderDiv`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    width: 100%;
    min-height: 66px;
    height: 66px;
    background: rgb(0, 102, 204);
    letter-spacing: 0px;
    font-size: ${fontSize.display5};
    padding: 0 32px;
    color: ${colors.white};
`;

export type ModalColHeader = {
  width?: string;
};

const ModalColHeaderDiv: StyledFunction<ModalColHeader> = styled.div;

export const ModalColHeader = ModalColHeaderDiv`
    width:  ${(props) => (props.width ? props.width : '100%')};
`;

export type ModalBodyProps = {};

const ModalBodyDiv: StyledFunction<ModalBodyProps> = styled.div;

export const ModalBody = ModalBodyDiv`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    width: 80%;
    height: 100%;
`;

export type IconWrapperProps = {
  onClick(): void;
};

const IconWrapperSpan: StyledFunction<IconWrapperProps> = styled.span;

export const IconWrapper = IconWrapperSpan`
    cursor: pointer;
`;

export type TextModalProps = {
  textAlign?: string;
};

const TextModalDiv: StyledFunction<TextModalProps> = styled.div;

export const TextModal = TextModalDiv`
    color: ${colors.black} ;
    font-size: ${fontSize.p};
    font-weight: normal;
    line-height: 22px;
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
    display: inline-flex;
`;

export const TimePeriodModal = TextModalDiv`
    color: ${colors.black} ;
    font-size: ${fontSize.p};
    font-weight: bold;
    line-height: 22px;
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
    padding-bottom: 20px;
    padding-left: 5px;
`;
