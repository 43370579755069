import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  checkPairingCodeSuccess,
  checkPairingCodeError,
} from './check-pairing-code.actions';

import {
  CheckPairingCodeActionType,
  CheckPairingCodeStartAction,
  CheckPairingCodeSuccessPayload,
} from './check-pairing-code.types';
import { checkPairingCodeLoaderImplType } from 'src/services/pairing-code/pairing-code-check/pairing-code-check.types';

export const checkPairingCodeEpic: (
  checkPairingCodeService: checkPairingCodeLoaderImplType,
) => Epic<any, any> = (checkPairingCodeService) => (action$, store$) =>
  action$
    .ofType(CheckPairingCodeActionType.CHECK_PAIRING_CODE_START)
    .flatMap(({ payload }: CheckPairingCodeStartAction) => {
      const accessToken = selectAccessToken(store$.getState());
      const apiKey = selectGigyaToken(store$.getState());
      return Observable.fromPromise(
        checkPairingCodeService(accessToken, apiKey, payload),
      )
        .switchMap((response: CheckPairingCodeSuccessPayload) => {
          return [checkPairingCodeSuccess(response)];
        })
        .pipe(catchError((err) => Observable.of(checkPairingCodeError(err))));
    });
