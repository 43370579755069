import { path, propOr } from 'ramda';
import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import { CIAM_SDK_NAMESPACE } from '../../constants';

export const selectSession = (state: State) =>
  path([CIAM_SDK_NAMESPACE, 'session'], state);

export const selectLegacyToken = createSelector([selectSession], (session) =>
  propOr('', 'legacyToken', session),
);

export const selectSessionError = createSelector([selectSession], (session) =>
  propOr(false, 'error', session),
);

export const selectIsSignedOut = createSelector([selectSession], (session) =>
  propOr(true, 'isSignedOut', session),
);

export const selectIsSessionValid = createSelector([selectSession], (session) =>
  propOr(false, 'isValidSession', session),
);

export const selectTimeout = createSelector([selectSession], (session) =>
  propOr(null, 'timeout', session),
);

export const selectSuccessRedirect = createSelector(
  [selectSession],
  (session) => propOr(null, 'successRedirect', session),
);

export const selectComeFromLogin = createSelector([selectSession], (session) =>
  propOr(null, 'comeFromLogin', session),
);

export const selectIsGettingToken = createSelector([selectSession], (session) =>
  propOr(null, 'isGettingToken', session),
);

export const selectIsSigningout = createSelector([selectSession], (session) =>
  propOr(null, 'isSigningout', session),
);
