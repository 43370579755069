import {
  CheckDuplicatedEmailActions,
  CheckDuplicatedEmailActionType,
  CheckDuplicatedEmailState,
} from './check-duplicated-email.actions.types';

export const CheckDuplicatedEmailInitialState: CheckDuplicatedEmailState = {
  isCheckingDuplicatedEmail: false,
  checkDuplicatedEmailWasSuccessful: false,
  checkDuplicatedEmailHasError: false,
  isEmailDuplicated: null,
};

export const checkDuplicatedEmailReducer = (
  state = CheckDuplicatedEmailInitialState,
  action: CheckDuplicatedEmailActions,
) => {
  switch (action.type) {
    case CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_START:
      return {
        ...state,
        isCheckingDuplicatedEmail: true,
        isEmailDuplicated: null,
      };
    case CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckingDuplicatedEmail: false,
        checkDuplicatedEmailWasSuccessful: true,
        isEmailDuplicated: action.payload,
      };
    case CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_ERROR:
      return {
        ...state,
        isCheckingDuplicatedEmail: false,
        checkDuplicatedEmailHasError: true,
      };
    case CheckDuplicatedEmailActionType.RESET_DUPLICATED_EMAIL:
      return CheckDuplicatedEmailInitialState;
    default:
      return state;
  }
};
