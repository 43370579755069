// @ts-nocheck
import * as React from 'react';

import { GenerateContents } from '../icon-components.component';
import { JelloIcon } from 'src/components/jello-icon/jello-icon.component';

import {
  V3HomeIcon,
  NewPatientIcon,
  V3StockIcon,
  LogbookIcon,
  LogbookDiaryIcon,
  V3HelpIcon,
  BoxGraphIcon,
  LineGraphIcon,
  InformationIcon,
  V3MenuIcon,
  V3CalendarIcon,
  V3ExportTimeTrackerIcon,
  NoDataIcon,
  ArrowIcon,
  PdfDownloadIcon,
  V3UploadDataIcon,
  InformationFilledIcon,
} from 'src/assets/icons';

import {
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
} from 'src/app/app.jello.constants';

import { V3UserIcon } from 'src/assets/icons/v3-user-icon/v3-user-icon.component';
import { Logbook24HourIcon } from 'src/assets/icons/index.js';
import { V3RpmSettingsIcon } from 'src/assets/icons/v3-rpm-settings-icon/v3-rpm-settings-icon.component';

import { colors } from 'src/core/styles/colors';

const prefix = 'manufacturerInfo.generalIcons.';
const title = `${prefix}generalIconsTitle`;

const getJelloIcon = (iconName) => (
  <JelloIcon
    iconName={iconName}
    size={JELLO_ICON_SIZES.S}
    styles={{ color: colors.brandBlue }}
  />
);

export const content = [
  [
    {
      tag: `${prefix}home`,
      icon: (
        <V3HomeIcon
          key={1}
          height={24}
          fillColor={colors.white}
          strokeColor={colors.brandBlue}
          originalWidth={24}
          originalHeight={24}
          minX={4}
          minY={5}
        />
      ),
    },
    {
      tag: `${prefix}stock`,
      icon: (
        <V3StockIcon
          key={4}
          height={20}
          fillColor={colors.brandBlue}
          strokeColor={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}deviceLink`,
      icon: getJelloIcon(JELLO_ICON_NAMES.CLOUD_UPLOAD),
    },
    {
      tag: `${prefix}help`,
      icon: (
        <V3HelpIcon
          key={10}
          height={24}
          width={18}
          minX={2}
          originalWidth={6}
          originalHeight={24}
          tspanY={20}
          fillColor={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}information`,
      icon: (
        <InformationFilledIcon
          key={13}
          height={20}
          fillColor={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}productInformation`,
      icon: (
        <InformationIcon key={16} height={20} fillColor={colors.brandBlue} />
      ),
    },
    {
      tag: `${prefix}calendarPicker`,
      icon: (
        <V3CalendarIcon
          key={19}
          height={20}
          dotColor={colors.brandBlue}
          fillColor={colors.white}
          strokeColor={colors.brandBlue}
          styles={{ paddingLeft: '5px' }}
        />
      ),
    },
    {
      tag: `${prefix}noData`,
      icon: <NoDataIcon key={22} height={20} />,
    },
  ],
  [
    {
      tag: `${prefix}bgm`,
      icon: getJelloIcon(JELLO_ICON_NAMES.ACCU_CHEK_GUIDE),
    },
    {
      tag: `${prefix}cgm`,
      icon: getJelloIcon(JELLO_ICON_NAMES.CGM_PATCH),
    },
    {
      tag: `${prefix}patterns`,
      icon: getJelloIcon(JELLO_ICON_NAMES.TREND),
    },
    {
      tag: `${prefix}stripManagement`,
      icon: getJelloIcon(JELLO_ICON_NAMES.ACTIVE_TEST_STRIPS),
    },
    {
      tag: `${prefix}devices`,
      icon: getJelloIcon(JELLO_ICON_NAMES.DEPRECATED_DEVICES),
    },
    {
      tag: `${prefix}editPatient`,
      icon: getJelloIcon(JELLO_ICON_NAMES.SETTINGS),
    },
  ],
  [
    {
      tag: `${prefix}createNewPatient`,
      icon: (
        <NewPatientIcon
          key={2}
          height={20}
          originalWidth={20}
          originalHeight={21}
          fillColor={colors.white}
          strokeColor={colors.brandBlue}
          minX={6}
          minY={-2}
        />
      ),
    },
    {
      tag: `${prefix}hcpProfile`,
      icon: (
        <V3UserIcon
          key={5}
          height={20}
          innerColor={colors.white}
          width={15}
          originalWidth={20}
          originalHeight={44}
          minX={8}
          fillColor={colors.white}
          strokeColor={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}diary`,
      icon: (
        <LogbookDiaryIcon
          key={9}
          height={24}
          fillColor={colors.brandBlue}
          originalWidth={6}
          originalHeight={17}
          minX={6}
          minY={-2}
        />
      ),
    },
    {
      tag: `${prefix}details`,
      icon: <BoxGraphIcon key={11} height={17} fillColor={colors.brandBlue} />,
    },
    {
      tag: `${prefix}trend`,
      icon: <LineGraphIcon key={14} height={17} fillColor={colors.brandBlue} />,
    },
    {
      tag: `${prefix}expand`,
      icon: (
        <JelloIcon
          iconName={JELLO_ICON_NAMES.MAXIMIZE}
          size={'XS'}
          color={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}collapse`,
      icon: (
        <JelloIcon
          iconName={JELLO_ICON_NAMES.MINIMIZE}
          size={'XS'}
          color={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}dropdown`,
      icon: <ArrowIcon key={23} height={15} fillColor={colors.brandBlue} />,
    },
  ],
  [
    {
      tag: `${prefix}notEnoughDataAvailable`,
      icon: getJelloIcon(JELLO_ICON_NAMES.WARNING),
    },
    {
      tag: `${prefix}logbook`,
      icon: <LogbookIcon key={6} height={18} fillColor={colors.brandBlue} />,
    },
    {
      tag: `${prefix}24HRLogbook`,
      icon: (
        <Logbook24HourIcon key={9} height={17} fillColor={colors.brandBlue} />
      ),
    },
    {
      tag: `${prefix}RPMSettings`,
      icon: (
        <V3RpmSettingsIcon
          key={12}
          height={17}
          fillColor={colors.white}
          strokeColor={colors.brandBlue}
          lineColor={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}disclaimer`,
      icon: (
        <JelloIcon
          size="S"
          color={colors.brandBlue}
          iconName={JELLO_ICON_NAMES.WARNING}
        />
      ),
    },
    {
      tag: `${prefix}generalFunctions`,
      icon: (
        <V3MenuIcon
          key={18}
          height={18}
          originalWidth={31}
          originalHeight={31}
          fillColor={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}exportBillingReport`,
      icon: (
        <V3ExportTimeTrackerIcon
          key={21}
          height={26}
          width={26}
          originalWidth={34}
          originalHeight={34}
          minX={7}
          minY={6}
        />
      ),
    },
    {
      tag: `${prefix}sendEMR`,
      icon: (
        <PdfDownloadIcon
          key={24}
          borderColor={colors.white}
          color={colors.brandBlue}
          viewBox={'0 4 30 35'}
        />
      ),
    },
  ],
];

export const GeneralIconsComponent = () => (
  <GenerateContents content={[...content]} title={title} />
);
