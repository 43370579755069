type StockAdjustmentsLoaderOptionsType = {
  patientId: number;
};

export enum AdjustmentType {
  AUTO = 'automatic',
  MANUAL = 'manual',
}

export type StockAdjustmentsLoaderImplType = (
  options: StockAdjustmentsLoaderOptionsType,
  accessToken: string,
  gigyaToken: string,
) => Promise<Adjustment[]>;

export type StockAdjustmentsTransformType = (data: FixMe) => Adjustment[];

export type StockAdjustmentsServiceImplType = (
  load: StockAdjustmentsLoaderImplType,
  transform: StockAdjustmentsTransformType,
) => (
  options: StockAdjustmentsLoaderOptionsType,
  accessToken: string,
  gigyaToken: string,
) => Promise<Adjustment[]>;

export type Adjustment = {
  createdAt: string;
  type: AdjustmentType;
  before: number;
  delivered: number;
  after: number;
  lost: number | string;
};
