import { createRequestActionTypes } from '../request/request.actions';

export const CHANGE_LOCALE_ERROR = 'CHANGE_LOCALE_ERROR';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const GET_CURRENT_USER_BASE = 'GET_CURRENT_USER';
export const GET_CURRENT_USER = createRequestActionTypes(GET_CURRENT_USER_BASE);

export const HCP_MASTER_PROFILE = 'HCP_MASTER';
export const ADMINISTRATIVE = 'ADMINISTRATIVE';
export const GENERAL_PRACTITIONER = 'GENERAL_PRACTITIONER';

export const MERIDIAN_TIME_FORMAT = 'AM/PM';
