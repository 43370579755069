import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'src/components/protected-route/protected-route.container';
import {
  PERMISSIONS,
  PATIENT_PERMISSIONS,
} from 'src/core/permissions/permissions.constants';
import { connectWithPlatformPermission } from './utils/utils';
import { LoadingRing } from 'src/components/loading-ring/loading-ring.component';
import { Column } from 'src/components/column/column.component';
import { DeliveryRouteWrapperContainer } from 'src/domains/patient/utils/delivery-route-wrapper.component';

import {
  CreatePatientBundle,
  DeactivatePatientBundle,
  DeliveryConfigurationBundle,
  DeviceSettingsJelloBundle,
  EditPatientBundle,
  EditPatientBundleJello,
  EditPatientWithPlatformBundle,
  GraphicSettingsBundle,
  HomeDeliveryJelloBundle,
  ListDevicesBundle,
  NextShipmentBundle,
  TimePeriodsBundle,
} from './bundles';
import { WithPatientPermissions } from 'src/utils/with-permissions/with-permissions.container';

export const patientLinks = {
  createPatient: '/patient/create',
  deactivatePatient: '/patients/:id/deactivate',
  delivery: '/patients/bg/:id/delivery',
  deviceSettings: '/patients/bg/:id/device-settings',
  editPatient: '/patients/:id/edit',
  editTimePeriods: '/patients/:id/time-periods',
  graphSettings: '/patients/:id/graph-settings',
  listDevices: '/patients/:id/devices',
  nextShipment: '/patients/bg/:id/next-shipment',
  patientBGDashboard: '/patients/bg/:id',
  patientById: '/patients/:id',
  patients: '/patients',
};

const loadingRing = (
  <Column align="center" height="100vh" justifyContent="center">
    <LoadingRing infinite />
  </Column>
);

export const PatientRoutes = () => {
  const isNotBlindedPatient = (patientPermissions) => {
    return patientPermissions?.includes(PATIENT_PERMISSIONS.BLINDED_STUDY)
      ? false
      : true;
  };

  return (
    <DeliveryRouteWrapperContainer>
      <Switch>
        <ProtectedRoute
          exact
          path={patientLinks.editPatient}
          component={connectWithPlatformPermission((props) => {
            if (props.isFetchingPermissions) {
              return loadingRing;
            }

            if (props.isJelloActivated) {
              return <EditPatientBundleJello />;
            }

            if (props.hasUserPlatformPermission) {
              return <EditPatientWithPlatformBundle />;
            }

            return <EditPatientBundle />;
          })}
        />

        <ProtectedRoute
          exact
          path={patientLinks.deactivatePatient}
          component={DeactivatePatientBundle}
          hasPermissions={[PERMISSIONS.PATIENT_MANAGEMENT_DELETE]}
        />
        <ProtectedRoute
          exact
          path={patientLinks.createPatient}
          component={CreatePatientBundle}
          hasPermissions={[PERMISSIONS.REGISTER_PATIENT]}
        />
        <ProtectedRoute
          exact
          path={patientLinks.editTimePeriods}
          component={TimePeriodsBundle}
          hasPermissions={[PERMISSIONS.TIME_BLOCKS_MANAGEMENT]}
        />
        <ProtectedRoute
          exact
          path={patientLinks.listDevices}
          component={ListDevicesBundle}
          hasPermissions={[PERMISSIONS.PATIENT_DEVICES]}
        />
        <ProtectedRoute
          exact
          path={patientLinks.deviceSettings}
          hasPermissions={[PERMISSIONS.PATIENT_DEVICES]}
          accessConditions={[
            ({ patientPermissions }) => isNotBlindedPatient(patientPermissions),
          ]}
          validationRedirectPath={'/patients/:id'}
          component={(routerProps) => (
            <WithPatientPermissions
              patientId={routerProps.match.params.id}
              hasPermissions={[]}
              onAccessDenied={() =>
                routerProps.history.push(
                  patientLinks.patientBGDashboard.replace(
                    ':id',
                    routerProps.match.params.id,
                  ),
                )
              }
            >
              <DeviceSettingsJelloBundle {...routerProps} />
            </WithPatientPermissions>
          )}
        />
        <ProtectedRoute
          exact
          path={patientLinks.graphSettings}
          component={GraphicSettingsBundle}
          hasPermissions={[PERMISSIONS.RELEVANT_CLINICAL_DATA]}
        />
        <ProtectedRoute
          exact
          path={patientLinks.delivery}
          hasPermissions={[
            PERMISSIONS.NEXT_SHIPMENT,
            PERMISSIONS.PATIENT_DELIVERY_PROGRAM_CONFIG,
          ]}
          component={(routerProps) => (
            <WithPatientPermissions
              patientId={routerProps.match.params.id}
              hasPermissions={[PATIENT_PERMISSIONS.HOME_DELIVERY_PATIENT]}
              onAccessDenied={() =>
                routerProps.history.push(
                  patientLinks.patientBGDashboard.replace(
                    ':id',
                    routerProps.match.params.id,
                  ),
                )
              }
            >
              <HomeDeliveryJelloBundle />
            </WithPatientPermissions>
          )}
        />
        <ProtectedRoute
          exact
          path={patientLinks.nextShipment}
          component={NextShipmentBundle}
          hasPermissions={[PERMISSIONS.NEXT_SHIPMENT]}
        />
      </Switch>
    </DeliveryRouteWrapperContainer>
  );
};
