import { createRequestActionTypes } from 'src/core/request/request.actions';

export const PATIENT_DASHBOARD_ACTIONS = {
  CHANGE_GRAPH: 'CHANGE_GRAPH',
  CHANGE_GRAPH_TYPE: 'CHANGE_GRAPH_TYPE',
  CHANGE_LOGBOOK_TYPE: 'CHANGE_LOGBOOK_TYPE',
  CHANGE_DEVICE_SETTINGS: 'CHANGE_DEVICE_SETTINGS',
  SET_DATES: 'SET_DATES',
  SET_BLOOD_GLUCOSE_OVERVIEW_END_DATE: 'SET_BLOOD_GLUCOSE_OVERVIEW_END_DATE',
  SET_CLINICAL_DATA: 'SET_CLINICAL_DATA',
  TOGGLE_BLOOD_GLUCOSE_LINES: 'TOGGLE_BLOOD_GLUCOSE_LINES',
  TOGGLE_BLOOD_GLUCOSE_POINTS: 'TOGGLE_BLOOD_GLUCOSE_POINTS',
  TOGGLE_BLOOD_GLUCOSE_BEFORE_MEAL_POINTS:
    'TOGGLE_BLOOD_GLUCOSE_BEFORE_MEAL_POINTS',
  TOGGLE_BLOOD_GLUCOSE_AFTER_MEAL_POINTS:
    'TOGGLE_BLOOD_GLUCOSE_AFTER_MEAL_POINTS',
  TOGGLE_CARBOHYDRATES: 'TOGGLE_CARBOHYDRATES',
  TOGGLE_INSULIN: 'TOGGLE_INSULIN',
  TOGGLE_BASAL: 'TOGGLE_BASAL',
  TOGGLE_MEAN_BLOOD_GLUCOSE: 'TOGGLE_MEAN_BLOOD_GLUCOSE',
  TOGGLE_DTC_MODAL: 'TOGGLE_DTC_MODAL',
  HIDE_BANNER_FOR_DATE_RANGE: 'HIDE_BANNER_FOR_DATE_RANGE',
  RESET_HIDE_BANNER_FOR_DATE_RANGE: 'RESET_HIDE_BANNER_FOR_DATE_RANGE',
};

export const GET_PATIENT = 'GET_PATIENT';
export const GET_PATIENT_REQUEST = createRequestActionTypes(GET_PATIENT);

export const GET_CLINICAL_DATA = 'GET_CLINICAL_DATA';
export const GET_CLINICAL_DATA_REQUEST =
  createRequestActionTypes(GET_CLINICAL_DATA);

export const GET_CLINICAL_DATA_FROM_FHIR = 'GET_CLINICAL_DATA_FROM_FHIR';
export const GET_CLINICAL_DATA_FROM_FHIR_REQUEST = createRequestActionTypes(
  GET_CLINICAL_DATA_FROM_FHIR,
);

export const FETCH_BG_OVERVIEW_CLINICAL_DATA =
  'FETCH_BG_OVERVIEW_CLINICAL_DATA';
export const FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST = createRequestActionTypes(
  FETCH_BG_OVERVIEW_CLINICAL_DATA,
);

export const FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR =
  'FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR';
export const FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST =
  createRequestActionTypes(FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR);

export const FETCH_BG_OVERVIEW_END_DATE = 'FETCH_BG_OVERVIEW_END_DATE';
export const FETCH_BG_OVERVIEW_END_DATE_REQUEST = createRequestActionTypes(
  FETCH_BG_OVERVIEW_END_DATE,
);
export const FETCH_CHECK_EXTERNAL_PATIENT = 'FETCH_CHECK_EXTERNAL_PATIENT';
export const FETCH_CHECK_EXTERNAL_PATIENT_REQUEST = createRequestActionTypes(
  FETCH_CHECK_EXTERNAL_PATIENT,
);
export const SET_IS_EXTERNAL_PATIENT = 'SET_IS_EXTERNAL_PATIENT';

export const GRAPH_TYPE = {
  TREND: 'trend',
  STANDARD_DAY: 'standard-day',
  STANDARD_WEEK: 'standard-week',
  LOGBOOK: 'logbook',
  METABOLIC_RATE: 'metabolic-rate',
  INSULIN: 'insulin',
  INSULIN_PUMP: 'insulin-pump',
};

export const BLOOD_GLUCOSE_UNITS = {
  MG_PER_DL: 'mg/dL',
  MMOL_PER_L: 'mmol/L',
};

export const BG_UNIT_KEYS = {
  [BLOOD_GLUCOSE_UNITS.MG_PER_DL]: 'general.units.mgPerDL',
  [BLOOD_GLUCOSE_UNITS.MMOL_PER_L]: 'general.units.mmolPerL',
};
