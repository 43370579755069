import { UNITS } from '../../device-settings.utils';

export const getLabelFromAttribute = (attr) => {
  const labels = {
    InsulinIncrement: 'deviceSettings.tabGeneral.insulinIncrement',
    CartridgeLowAlarmQuantity: 'deviceSettings.tabGeneral.cartridgeLowAlarm',
    InfusionSetCannulaFillAmount: 'deviceSettings.tabGeneral.cannulaFillAmount',
    InfusionSetTubingFillAmount:
      'deviceSettings.tabGeneral.setTubingFillAmount',
    DeliverySpeed: 'deviceSettings.tabGeneral.deliverySpeed',
    BolusIncrement: 'deviceSettings.tabGeneral.bolusIncrement',
    Carbohydrates: 'deviceSettings.tabGeneral.carbohydrates',
    BloodGlucose: 'deviceSettings.tabGeneral.bloodGlucose',
    Insuline: 'deviceSettings.tabGeneral.insulin',
    DeviceType: 'deviceSettings.tabGeneral.deviceType',
    TimeFormat: 'deviceSettings.tabGeneral.timeFormat',
    PrimeQuantity: 'deviceSettings.tabGeneral.primeQuantity',
    MaxBolusQuantity: 'deviceSettings.tabGeneral.maxBolusQuantity',
    LagStatus: 'deviceSettings.tabGeneral.lagStatus',
    MaxBolusDuration: 'deviceSettings.tabGeneral.maxBolusDuration',
  };
  return labels[attr];
};

export const getUnitFromAttribute = (attr, units = {}) => {
  const labels = {
    InsulinIncrement: units[UNITS.INSULINE] || UNITS.INSULINE_UNIT,
    CartridgeLowAlarmQuantity: units[UNITS.INSULINE] || UNITS.INSULINE_UNIT,
    InfusionSetCannulaFillAmount: units[UNITS.INSULINE] || UNITS.INSULINE_UNIT,
    InfusionSetTubingFillAmount: units[UNITS.INSULINE] || UNITS.INSULINE_UNIT,
    BolusIncrement: units[UNITS.INSULINE] || UNITS.INSULINE_UNIT,
    PrimeQuantity: units[UNITS.INSULINE] || UNITS.INSULINE_UNIT,
    MaxBolusQuantity: units[UNITS.INSULINE] || UNITS.INSULINE_UNIT,
    MaxBolusDuration: 'min',
  };
  return labels[attr];
};

export const StringConstants = {
  VALUE: 'value',
  VALUE_HL: 'valueHighlighted',
  YES: 'Yes',
  NO: 'No',
};
