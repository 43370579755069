import styled, { StyledFunction } from 'styled-components';

const flexContainerColumns = 12;

const calculateWidthFromFlexSpan = (span, columns = flexContainerColumns) => {
  const percentageWidth = (span / columns) * 100;
  return `calc(${percentageWidth}%)`;
};

export type FlexContainerProps = {
  width?: string;
  justifyContainer?: string;
  alignItems?: string;
  alignContent?: string;
  flexWrap?: string;
  direction?: string;
};

const FlexContainerDiv: StyledFunction<FlexContainerProps> = styled.div;

export const FlexContainer = FlexContainerDiv`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  flex-wrap:  ${(props) => (props.flexWrap ? props.flexWrap : 'no-wrap')};
  justify-content: ${(props) =>
    props.justifyContainer ? props.justifyContainer : 'flex-start'};
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : 'stretch'};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : 'flex-start'};
`;

export type GridItemProps = {
  grid?: string | number;
  offsetGrid?: string | number;
  columns?: number;
  order?: number;
  flex?: string;
  alignSelf?: string;
  paddings?: boolean;
};

const FlexItemDiv: StyledFunction<GridItemProps> = styled.div;

export const FlexItem = FlexItemDiv`
  display: flex;
  box-sizing: content-box;
  padding: ${(props) => (props.paddings === false ? '0' : '0 1.5rem')};
  width: ${(props) => calculateWidthFromFlexSpan(props.grid, props.columns)};
  margin-left: ${(props) =>
    calculateWidthFromFlexSpan(props.offsetGrid, props.columns)};
  order: ${(props) => (props.order ? props.order : '0')};
  flex: ${(props) => (props.flex ? props.flex : '0 1 auto')};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'auto')};

  &:first-child {
    padding-left: ${(props) => (props.paddings === false ? '' : '0')};
  }
  &:last-child {
    padding-right: ${(props) => (props.paddings === false ? '' : '0')};
  }
`;

FlexItem.displayName = 'FlexItem';
