import {
  GetTargetRangesServiceImpl,
  GetTargetRangesLoaderImpl,
  GetTargetRangesTransformImpl,
} from './get-target-ranges.service';
import { mockGetTargetRanges } from './get-target-ranges.mock';

const mockLoader = (q) => Promise.resolve(mockGetTargetRanges);

export const GetTargetRangesFetchFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : GetTargetRangesLoaderImpl;
  return GetTargetRangesServiceImpl(loader, GetTargetRangesTransformImpl);
};
