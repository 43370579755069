/* tslint:disable */

import { createRequestActionTypes } from '../request/request.actions';

const FETCH_DEVICE_SETTINGS = 'DEVICE_SETTINGS/FETCH';

export const FETCH_DEVICE_SETTINGS_REQUEST = createRequestActionTypes(
  FETCH_DEVICE_SETTINGS,
);

export enum fetchAllDevicesActionType {
  FETCH_BASE = 'FETCH_DEVICE_SETTINGS_ALL_NONE',
  FETCH_START = 'FETCH_DEVICE_SETTINGS_ALL_START',
  FETCH_SUCCESS = 'FETCH_DEVICE_SETTINGS_ALL_SUCCESS',
  FETCH_ERROR = 'FETCH_DEVICE_SETTINGS_ALL_ERROR',
}
