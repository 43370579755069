import { addDomainNamespace } from 'src/utils/domain-namespace';

import { createRequestActionTypes } from '../../request/request.actions';

export const therapyTranslationKeyBase = 'prescription.therapy.';
const DOMAIN_NAMESPACE = 'PATIENT_THERAPY';
const addNamespacing = (actionName) =>
  addDomainNamespace(actionName, DOMAIN_NAMESPACE);

export const GET_THERAPIES = addNamespacing('GET_THERAPIES');
export const GET_THERAPIES_REQUEST = createRequestActionTypes(GET_THERAPIES);
