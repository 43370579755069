export const mockPrograms = {
  model: {
    0: {
      deliveryConfiguration: {
        deliveryProgramId: 2,
        initialStock: 150,
        securityPercentage: 10,
        supplyModelName: 'Accu-Chek Aviva',
        timeGap: 60,
      },
      name: 'Program Accu-Chek Aviva',
    },
    1: {
      deliveryConfiguration: {
        initialStock: 150,
        securityPercentage: 10,
        supplyModelName: 'Accu-Chek Performa',
        timeGap: 60,
      },
      name: 'Program Accu-Chek Performa',
    },
    2: {
      deliveryConfiguration: {
        deliveryProgramId: 6,
        initialStock: 150,
        securityPercentage: 10,
        supplyModelName: 'Accu-Chek Instant',
        timeGap: 60,
      },
      name: 'Program Accu-Chek Instant',
    },
    3: {
      deliveryConfiguration: {
        deliveryProgramId: 11,
        initialStock: 150,
        securityPercentage: 10,
        supplyModelName: 'Accu-Chek Compact',
        timeGap: 60,
      },
      name: 'Program Accu-Chek Compact',
    },
  },
};

export const mockPatientProgram = {
  model: {
    name: 'Program Accu-Chek Performa',
    deliveryConfiguration: {
      supplyModelName: 'Accu-Chek Performa',
      initialStock: 128,
      securityPercentage: 22,
      timeGap: 75,
      deliveryProgramId: 4,
    },
  },
};
