import {
  BLOOD_GLUCOSE_UNIT_PLACEHOLDER,
  CARBOHYDRATES_UNIT_PLACEHOLDER,
  LOGBOOK_STATS_TABLE_HEADER_COLUMNS,
  LogbookStatsTableColumn,
} from './logbook-stats-table-header.constants';

export const getTableHeaderColumnConfig = (
  bloodGlucoseUnit: string,
  carbLabel: string,
): LogbookStatsTableColumn[] =>
  LOGBOOK_STATS_TABLE_HEADER_COLUMNS.map((column) => ({
    ...column,
    headerRows: column.headerRows.map((headerRow) =>
      headerRow.map((subColumn) => ({
        ...subColumn,
        textKeys: subColumn.textKeys.map((key) => {
          if (key === BLOOD_GLUCOSE_UNIT_PLACEHOLDER) {
            return bloodGlucoseUnit;
          } else if (key === CARBOHYDRATES_UNIT_PLACEHOLDER) {
            return carbLabel;
          } else {
            return key;
          }
        }),
      })),
    ),
  }));
