import { PayloadAction } from 'src/app/store/app.types';

// PatientFormState is not used here because it is not complete
type EditPatientFormState = {
  patientInfo: {
    email: string;
  };
};

// to save state in app-wide forms that is not saved by react-redux-forms
// specifically, this should represent the initial form state on successful API calls
export type CurrentFormsState = {
  editPatient: EditPatientFormState;
};

export enum CurrentFormsActionType {
  SET_CURRENT_FORMS_PROP = 'SET_CURRENT_FORMS_PROP',
}

export type SetCurrentFormsPropPayload = {
  path: any;
  value: any;
};

export type SetCurrentFormsProp = PayloadAction<
  CurrentFormsActionType.SET_CURRENT_FORMS_PROP,
  SetCurrentFormsPropPayload
>;

export type SetCurrentFormsReducerActions = SetCurrentFormsProp;
