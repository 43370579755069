import { ensureNever } from 'src/utils/typescript';
import { setIn } from 'src/utils/ramda/ramda.utils';

import {
  LanguagesActionType,
  LanguagesReducerActions,
  LanguagesState,
} from './languages.types';

export const initialUserState: LanguagesState = {
  isLoading: true,
  activeLanguages: [],
  error: '',
};

export const languagesReducer = (
  state = initialUserState,
  action: LanguagesReducerActions,
): LanguagesState => {
  switch (action.type) {
    case LanguagesActionType.FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        activeLanguages: action.payload,
        isLoading: false,
      };
    case LanguagesActionType.FETCH_LANGUAGES_ERROR:
      return setIn(['error'], action.payload, state);
    default:
      ensureNever(action);
      return state;
  }
};
