// @ts-nocheck

import * as React from 'react';

import { GenerateContents } from '../icon-components.component';

import {
  V3StockFilledIcon,
  DisclaimerIcon,
  CheckmarkFilledIcon,
} from 'src/assets/icons';

import { colors } from 'src/core/styles/colors';
import { JelloIcon } from 'src/components/jello-icon/jello-icon.component';

import {
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
} from 'src/app/app.jello.constants';

import {
  GLUCOSE_HI_COLOR,
  GLUCOSE_LO_COLOR,
} from 'src/domains/general/widgets/constants';

const prefix = 'manufacturerInfo.status.';
const title = `${prefix}statusIconsTitle`;

const getJelloIcon = (iconName, color) => (
  <JelloIcon
    iconName={iconName}
    size={JELLO_ICON_SIZES.S}
    styles={{ color: color ?? colors.brandBlue }}
  />
);

export const _content = [
  [
    {
      tag: `${prefix}success`,
      icon: (
        <CheckmarkFilledIcon
          key={1}
          height={20}
          width={20}
          fillColor={colors.white}
          fillCircleColor={colors.brandBlue}
          circle={true}
          originalWidth={55}
          originalHeight={40}
          minX={-6}
          minY={0}
          radius={26}
        />
      ),
    },
    {
      tag: `${prefix}heighVeryHigh`,
      icon: (
        <div
          style={{
            background: colors.blueMarine,
            borderRadius: '50%',
            height: '22px',
            width: '22px',
            padding: '3px 3px',
            display: 'flex',
            margin: '0px 10px',
          }}
        >
          <JelloIcon
            iconName={JELLO_ICON_NAMES.ARROW_UP}
            size={JELLO_ICON_SIZES.XS}
            styles={{ color: colors.white }}
          />
        </div>
      ),
    },
  ],
  [
    {
      tag: `${prefix}warning`,
      icon: getJelloIcon(JELLO_ICON_NAMES.WARNING, colors.red),
    },
    {
      tag: `${prefix}low`,
      icon: (
        <div
          style={{
            background: GLUCOSE_HI_COLOR,
            borderRadius: '50%',
            height: '22px',
            width: '22px',
            padding: '3px 3px',
            display: 'flex',
            margin: '0px 10px',
          }}
        >
          <JelloIcon
            iconName={JELLO_ICON_NAMES.ARROW_DOWN}
            size={JELLO_ICON_SIZES.XS}
            styles={{ color: colors.white }}
          />
        </div>
      ),
    },
  ],
  [
    {
      tag: `${prefix}stockStatus`,
      icon: (
        <V3StockFilledIcon
          key={3}
          height={20}
          width={20}
          originalWidth={20}
          originalHeight={12}
          minX={0}
          minY={4}
          fillCircleColor={colors.brandBlue}
        />
      ),
    },
    {
      tag: `${prefix}veryLow`,
      icon: getJelloIcon(JELLO_ICON_NAMES.WARNING_ALT_FILLED, GLUCOSE_LO_COLOR),
    },
  ],
  [
    {
      tag: `${prefix}noDataAvailable`,
      icon: (
        <DisclaimerIcon
          key={4}
          height={20}
          width={20}
          fillColor={colors.white}
          iconColor={colors.greyMediumTimeRange}
          borderFillColor={colors.white}
          withBorder={true}
        />
      ),
    },
  ],
];

export const StatusIconsComponent = () => (
  <GenerateContents content={[..._content]} title={title} />
);
