// tslint:disable
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AlertType } from 'src/core/alert/alert.types';
import { catchError } from 'rxjs/operators';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { State } from 'src/app/store/app.types';
import { addAlertAction } from 'src/core/alert/alert.actions';

import {
  SAVE_PATIENT_SESSION_REQUEST,
  EXPORT_PATIENT_SESSION_REQUEST,
  START_PATIENT_SESSION_REQUEST,
} from './patient-session.constants';
import {
  savePatientSessionSuccessAction,
  savePatientSessionErrorAction,
  resetSessionStatusAction,
  exportPatientsSessionSuccessAction,
  exportPatientsSessionErrorAction,
  ExportStartAction,
  startPatientSessionSuccessAction,
  startPatientSessionErrorAction,
} from './patient-session.actions';
import {
  selectIsTimeTrackerEnrolled,
  selectIsRPMEnrolled,
  selectJelloPermission,
} from '../permissions/permissions.selectors';
import { ALERT_POSITION } from 'src/core/alert/alert.constans';

export const savePatientSessionEndEpic: (
  patientSessionSaveService: any,
) => Epic<any, State> = (patientSessionEndService) => (action$, store) =>
  action$.ofType(SAVE_PATIENT_SESSION_REQUEST.START).switchMap((action) => {
    const state: any = store.getState();
    const { payload } = action;
    const openId = {
      accessToken: selectAccessToken(store.getState()),
      gigyaToken: selectGigyaToken(store.getState()),
    };
    const { shouldClearSession } = payload;
    const updatedPayload = {
      ...payload,
      visitId: state.patientSession.visitId,
    };
    return Observable.fromPromise(
      patientSessionEndService(updatedPayload, openId),
    )
      .map((result = {}) => savePatientSessionSuccessAction(result))
      .pipe(
        catchError((err) => Observable.of(savePatientSessionErrorAction(err))),
      )
      .concatMap((result: any = {}) => {
        const isSuccess = isSaveRequestSuccessful(result?.type);
        const isTimeTrackerEnrolled = selectIsTimeTrackerEnrolled(
          store.getState(),
        );
        const isRpmEnrolled = selectIsRPMEnrolled(store.getState());
        const isJelloDsEnabled = selectJelloPermission(store.getState());
        let alertAction: any = null;
        if (isTimeTrackerEnrolled && isRpmEnrolled) {
          alertAction = addAlertAction({
            type: isSuccess ? AlertType.SUCCESS : AlertType.ERROR,
            text: {
              [AlertType.SUCCESS]: 'timetracker.success',
              [AlertType.ERROR]: 'timetracker.fail',
            },
            position: isJelloDsEnabled ? ALERT_POSITION.BOTTOM_RIGHT : null,
          });
        }
        return getSaveActionListByStatus({
          alertAction,
          result,
          isSuccess,
          shouldClearSession,
        });
      })
      .catch(() => [console.error('savePatientSessionEndEpic error')]);
  });
export const exportPatientsSessionEpic: (
  PatientsSessionExportService: any,
) => Epic<ExportStartAction, State> =
  (PatientsSessionExportService) => (action$, store) =>
    action$.ofType(EXPORT_PATIENT_SESSION_REQUEST.START).switchMap((action) => {
      const { payload }: ExportStartAction = action;
      return Observable.fromPromise(
        PatientsSessionExportService(
          payload,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .map((result) => exportPatientsSessionSuccessAction(result))
        .pipe(
          catchError((err) =>
            Observable.of(exportPatientsSessionErrorAction(err)),
          ),
        )
        .concatMap((result: any = {}) => {
          const isSuccess = isExportRequestSuccessful(result?.type);
          const alertAction = addAlertAction({
            type: isSuccess ? AlertType.SUCCESS : AlertType.ERROR,
            position: ALERT_POSITION.TOP_RIGHT,
            text: {
              [AlertType.SUCCESS]: 'timetracker.report.success',
              [AlertType.ERROR]: 'timetracker.report.fail',
            },
          });
          return getExportActionListByStatus({
            alertAction,
            result,
            isSuccess,
          });
        });
    });

export const startPatientSessionEpic: (
  StartPatientSessionService,
) => Epic<any, State> = (StartPatientSessionService) => (action$, store) =>
  action$.ofType(START_PATIENT_SESSION_REQUEST.START).switchMap((action) => {
    const { payload }: any = action;
    const openId = {
      accessToken: selectAccessToken(store.getState()),
      gigyaToken: selectGigyaToken(store.getState()),
    };
    return Observable.fromPromise(StartPatientSessionService(payload, openId))
      .map((result) => startPatientSessionSuccessAction(result))
      .pipe(
        catchError((err) => Observable.of(startPatientSessionErrorAction(err))),
      )
      .concatMap((result: any = {}) => {
        const isTimeTrackerEnrolled = selectIsTimeTrackerEnrolled(
          store.getState(),
        );
        const isRpmEnrolled = selectIsRPMEnrolled(store.getState());
        let alertAction: any = null;
        if (isTimeTrackerEnrolled && isRpmEnrolled) {
          alertAction = addAlertAction({
            type: AlertType.ERROR,
            text: {
              [AlertType.ERROR]: 'timetracker.start.fail',
            },
          });
        }
        if (isStartSesssionRequestSuccessful(result?.type)) {
          return [startPatientSessionSuccessAction(result?.payload)];
        }
        // TODO: in case of error show alert
        return [alertAction, startPatientSessionErrorAction(result?.payload)];
      });
  });

const getSaveActionListByStatus = ({
  alertAction = () => ({ type: 'undefined' }),
  result,
  isSuccess,
  shouldClearSession = false,
}) => [
  alertAction,
  isSuccess
    ? savePatientSessionSuccessAction({
        visitId: result?.payload?.id,
      })
    : savePatientSessionErrorAction(result),
  shouldClearSession ? resetSessionStatusAction() : null,
];

const getExportActionListByStatus = ({ alertAction, result, isSuccess }) => [
  alertAction,
  isSuccess
    ? exportPatientsSessionSuccessAction(result?.payload)
    : exportPatientsSessionErrorAction(result?.payload),
  // reset api call status if needed
];

export const isStartSesssionRequestSuccessful = (type) =>
  type === START_PATIENT_SESSION_REQUEST.SUCCESS;

export const isSaveRequestSuccessful = (type) =>
  type === SAVE_PATIENT_SESSION_REQUEST.SUCCESS;

export const isExportRequestSuccessful = (type) =>
  type === EXPORT_PATIENT_SESSION_REQUEST.SUCCESS;
