import { PayloadAction } from 'src/app/store/app.types';
import { ProfessionalFormState } from 'src/core/professional/professional.types';
import {
  CreateProfessionalErrorResponse,
  CreateProfessionalParams as CreateProfessionalServiceParams,
  CreateProfessionalTransformedResponse,
} from 'src/services/professionals/create-professional/create-professional.types';

export enum CreateProfessionalActionType {
  CREATE_PROFESSIONAL_START = 'CREATE_PROFESSIONAL_START',
  CREATE_PROFESSIONAL_SUCCESS = 'CREATE_PROFESSIONAL_SUCCESS',
  CREATE_PROFESSIONAL_ERROR = 'CREATE_PROFESSIONAL_ERROR',
}

export type CreateProfessionalParams = CreateProfessionalServiceParams;
export type CreateProfessionalSuccessPayload =
  CreateProfessionalTransformedResponse;
export type CreateProfessionalErrorPayload = CreateProfessionalErrorResponse;

export type CreateProfessionalStartAction = PayloadAction<
  CreateProfessionalActionType.CREATE_PROFESSIONAL_START,
  CreateProfessionalParams
>;
export type CreateProfessionalSuccessAction = PayloadAction<
  CreateProfessionalActionType.CREATE_PROFESSIONAL_SUCCESS,
  CreateProfessionalSuccessPayload
>;
export type CreateProfessionalErrorAction = PayloadAction<
  CreateProfessionalActionType.CREATE_PROFESSIONAL_ERROR,
  CreateProfessionalErrorPayload
>;

export type CreateProfessionalActions =
  | CreateProfessionalStartAction
  | CreateProfessionalSuccessAction
  | CreateProfessionalErrorAction;

export type CreateProfessionalState = ProfessionalFormState & {
  creationHasError: boolean;
  creationWasSuccessful: boolean;
  isCreatingProfessional: boolean;
};
