import {
  all,
  allPass,
  equals,
  find,
  isEmpty,
  isNil,
  not,
  pipe,
  trim,
  values,
} from 'ramda';

export const isNotNil = pipe(isNil, not);
export const trimIfString = (value: any) =>
  typeof value === 'string' ? trim(value) : value;
export const isNotEmptyString = (value: any) =>
  value ? trimIfString(value).length > 0 : true;
export const isNotEmpty = pipe(trimIfString, isEmpty, not);
export const hasValue = allPass([isNotNil, isNotEmpty]);
export const isNotFalse = pipe(equals(false), not);
export const pass = equals(true);
export const isNotEqual = (val) => pipe(equals(val), not);

// Apply "R.all" method to a keyed object
export const allObj = (fn) => (obj) => pipe(values, all(fn))(obj);

export const hasMatchingListItem = (fn) => (obj) =>
  pipe(find(fn), hasValue)(obj);

export const isNumberBetween = (min, max, value) =>
  min <= value && value <= max;
