import { JELLO_ICON_NAMES } from '../../../../app/app.jello.constants';
export const insModalHeader = 'createEditPatient.insIdentity.modal.header';
export const insModalBtns = 'createEditPatient.insIdentity.modal.btns';
export const insModalTitles = 'createEditPatient.insIdentity.modal.titles';
export const insModalSubtitles =
  'createEditPatient.insIdentity.modal.subtitles';

export const INS_MODAL_ID = 'INS-identity-request-modal';
export const INS_ERROR_MODAL_ID = 'jello-dialog-save-error';

export const ONE_MATCH_FOUND_CODE = '00';
export const NO_MATCH_FOUND_CODE = '01';
export const LOADER_SIZE = 39.38;

export const CHECKMARK_ICON = JELLO_ICON_NAMES.CHECKMARK_FILLED;
export const CROSS_ICON = JELLO_ICON_NAMES.CLOSE_FILLED;

export const TITLE_MARGIN_TOP = '19px';
export const SUBTITLE_MARGIN_TOP = '8px';
