import {
  NextShipmentFetchLoaderImpl,
  NextShipmentFetchServiceImpl,
  NextShipmentFetchTransformImpl,
} from './next-shipment-fetch.service';
import { mockNextShipmentNoDeliveryProgram } from './next-shipment-fetch.mock';

const mockLoader = (q) => Promise.resolve(mockNextShipmentNoDeliveryProgram);

export const NextShipmentFetchFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : NextShipmentFetchLoaderImpl;
  return NextShipmentFetchServiceImpl(loader, NextShipmentFetchTransformImpl);
};
