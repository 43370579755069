import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectPlatformPermission,
  selectIsFetchingPermissions,
  selectJelloPermission,
} from 'src/core/permissions/permissions.selectors';

const platformPermissionConnector = createStructuredSelector({
  isFetchingPermissions: selectIsFetchingPermissions,
  hasUserPlatformPermission: selectPlatformPermission,
  isJelloActivated: selectJelloPermission,
});

export const connectWithPlatformPermission = compose(
  connect(platformPermissionConnector),
);

export const checkIsEmpty = (data) =>
  !Object.values(data).some((x) => x === null || x === '');

const routesNotDisplayingPeriodSelectors = [
  'strip-information',
  'delivery',
  'device-settings',
  'edit',
  'blood-glucose-overview',
];

const routesToDisplayTimeIntervalsFilter = ['blood-glucose-overview'];

export const isRouteNotDisplayingPeriodSelectors = (location: string) => {
  return !routesNotDisplayingPeriodSelectors.some((route) =>
    location?.includes(route),
  );
};

export const isRouteDisplayingTimeIntervalsFilter = (location: string) => {
  return routesToDisplayTimeIntervalsFilter.some((route) =>
    location?.includes(route),
  );
};
