import React from 'react';

const preparePoint = (p, width, height, padding) => ({
  ...p,
  x: padding + p.x * (width - padding * 2),
  y: -padding - p.y * (height - padding * 2),
});

export const DisconnectedLineSeries = ({
  points,
  Line,
  x,
  y,
  width,
  height,
  padding = 0,
}) => (
  <g>
    {Array.from({ length: points.length }).map((_, index) => {
      const [a, b] = points[index];
      return Line(
        preparePoint(a, width, height, padding),
        preparePoint(b, width, height, padding),
        index,
      );
    })}
  </g>
);
