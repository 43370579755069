import {
  MysugrSendInviteLoaderImpl,
  MysugrSendInviteServiceImpl,
  MysugrSendInviteTransformImpl,
} from './mysugr-send-invite.service';

const mockResponse = 'OK';

const mockLoader = (): Promise<any> => Promise.resolve(mockResponse);

export const MysugrSendInviteFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : MysugrSendInviteLoaderImpl;
  return MysugrSendInviteServiceImpl(loader, MysugrSendInviteTransformImpl);
};
