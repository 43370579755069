import React from 'react';

import { Table } from 'src/domains/diagnostics/components/table/table/table.component';
import { TableBody } from 'src/domains/diagnostics/components/table-body/table-body.component';

import { BloodGlucoseOverviewTableHeader } from '../blood-glucose-overview-table-header/blood-glucose-overview-table-header.component';

export const BloodGlucoseOverviewTable = ({ children, title }) => (
  <Table borderRadius={'0.375rem'}>
    <BloodGlucoseOverviewTableHeader title={title} />
    <TableBody>{children}</TableBody>
  </Table>
);
