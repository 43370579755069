import * as React from 'react';

import {
  ManufacturerLeftItem,
  ManufacturerLeftItemLink,
} from './manufacturer-info-modal.style';

type ManufacturerLeftItemProps = {
  item: string;
  id: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  active: boolean;
  key: number;
};

export const ManufacturerLeftItemComponent = ({
  item,
  id,
  onClick,
  active,
}: ManufacturerLeftItemProps) => (
  <ManufacturerLeftItem>
    <ManufacturerLeftItemLink
      id={id}
      href="#"
      onClick={onClick}
      active={active}
    >
      {item}
    </ManufacturerLeftItemLink>
  </ManufacturerLeftItem>
);
