import { borderRadiusMap } from 'src/core/styles/border-radius';
import { colors } from 'src/core/styles/colors';
import { fontSize } from 'src/core/styles/font-sizes';
import { fontWeights } from 'src/core/styles/font-weights/font-weights';
import { spacing } from 'src/core/styles/spacing';
import { transitions } from 'src/core/styles/transitions';
import { convertPxToRem } from 'src/utils/rem-calc';
import { createStyledComponent } from 'src/utils/styles';
import { css } from 'styled-components';

const RadioButtonIndicatorActive = css<any>`
  background-color: ${({ theme, disabled }) =>
    disabled ? colors.grayLighter : colors.brandBlue};
  box-shadow: 0 0 0 1px
    ${({ theme, disabled }) =>
      disabled ? colors.grayLighter : colors.brandBlue};
`;

export const RadioButtonIndicatorSpan = createStyledComponent<'span', any>(
  'span',
)`
  border-radius: ${borderRadiusMap[5]};
  border: ${convertPxToRem(2)} solid ${colors.white};
  box-shadow: 0 0 0 1px ${({ theme, disabled }) =>
    disabled ? colors.grayLighter : colors.charcoal};
  content: '';
  cursor: pointer;
  display: inline-block;
  height: ${spacing.three};
  width: ${spacing.three};
  transition: ${transitions.default};

  &:hover {
    ${RadioButtonIndicatorActive};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const RadioButtonLabelSpan = createStyledComponent<'span', any>('span')`
  color: ${({ theme, disabled }) =>
    disabled ? colors.grayLighter : colors.charcoal};
  font-size: ${fontSize.p};
  font-weight: ${fontWeights.semiBold};
  margin-left: ${spacing.two};
`;

export const RadioButtonLabelWrapper = createStyledComponent<any, any>('label')`
  align-items: center;
  display: flex;

  input {
    left: -999px;
    opacity: 0;
    position: absolute;
  }

  input:focus + ${RadioButtonIndicatorSpan} {
    background-color: ${colors.blueMarine};
    box-shadow: 0 0 0 1px ${colors.blueMarine};
  }

  input:checked + ${RadioButtonIndicatorSpan} {
    ${RadioButtonIndicatorActive};
  }
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
