import { isNil, mergeDeepLeft, reject, values } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';

import { selectBloodGlucoseUnit } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { fixToDecimalPlace } from 'src/utils/stat';

import {
  getCarbohydrates,
  getDay,
  getMeanBloodGlucose,
  getNumberOfHypos,
  getStandardDeviation,
  convertToCellValue,
  groupMeasurementsByDay,
  groupInsulinByDay,
  getNumberOfHIorLOValues,
  getTotalNumberOfTest,
  getTotalHypos,
  checkHasHIorLOValues,
} from './logbook-stats.util';

import {
  selectGlucoseMeasurementsIncludingNullValues,
  selectGraphThreshold,
  selectInsulin,
  selectGraphLoading,
} from '../../scenes/graphs/logbook/logbook.core.selector';
import { HI_VALUE, LO_VALUE } from 'src/domains/diagnostics/store/constants';
import { selectCarbUnitMeasurementForService } from 'src/core/patient-date-range/patient-date-range.selector';

export const selectLogbookStatsData = createSelector(
  selectGlucoseMeasurementsIncludingNullValues,
  selectGraphThreshold,
  selectInsulin,
  selectBloodGlucoseUnit,
  (
    measurements,
    { hypoglycemiaThreshold, glucoseIdealIntervalMin, glucoseIdealIntervalMax },
    insulinData,
    bloodGlucoseUnit,
  ) => {
    const glucoseByDay = groupMeasurementsByDay(measurements);
    const insulinByDay = groupInsulinByDay(insulinData);

    const allMeasurementsByDay = values(
      mergeDeepLeft(insulinByDay, glucoseByDay),
    ).sort((a, b) => a.date - b.date);

    return allMeasurementsByDay.map(
      ({
        date,
        glucoseMeasurements = [],
        totalBolusPlusBasal = 0,
        totalBolus = 0,
        numberOfBolus = 0,
        totalInsulinBolusType = 0,
      }) => {
        const measurementValues = reject(
          isNil,
          glucoseMeasurements.map(({ value }) => value),
        );
        const numberOfHIValues = getNumberOfHIorLOValues(
          glucoseMeasurements,
          HI_VALUE,
        );
        const numberOfLOValues = getNumberOfHIorLOValues(
          glucoseMeasurements,
          LO_VALUE,
        );
        const carbohydrateValues = reject(
          isNil,
          glucoseMeasurements.map(({ carbohydrates }) => carbohydrates),
        );
        const day = getDay(date);
        const dayInsulin1 = glucoseMeasurements.reduce((acc, val) => {
          if (val.insulin1) {
            acc += val.insulin1;
          }
          return acc;
        }, 0);
        const dayInsulin2 = glucoseMeasurements.reduce((acc, val) => {
          if (val.insulin2) {
            acc += val.insulin2;
          }
          return acc;
        }, 0);
        const totalInsulinTypes12 = glucoseMeasurements.reduce(
          (acc, { insulin1 = 0, insulin2 = 0 }) => acc + insulin1 + insulin2,
          0,
        );
        const numberOfInsulinTypes1 = glucoseMeasurements.reduce((acc, val) => {
          if (val.insulin1) {
            acc++;
          }
          return acc;
        }, 0);

        const numberOfTests = convertToCellValue(measurementValues.length);
        const totalNumberOfTest = getTotalNumberOfTest(
          numberOfTests,
          numberOfHIValues,
          numberOfLOValues,
        );

        const meanBloodGlucose =
          measurementValues.length > 0
            ? getMeanBloodGlucose(
                measurementValues,
                hypoglycemiaThreshold,
                glucoseIdealIntervalMin,
                glucoseIdealIntervalMax,
              )
            : { label: null };

        const standardDeviation = getStandardDeviation(
          measurementValues,
          bloodGlucoseUnit,
        );

        const hypos = getNumberOfHypos(
          measurementValues,
          glucoseIdealIntervalMin,
        );
        const totalHypos = getTotalHypos(hypos, numberOfLOValues);

        const carbohydrates = getCarbohydrates(carbohydrateValues);
        const bolus =
          totalBolus === 0
            ? totalInsulinBolusType === 0
              ? 0
              : totalInsulinBolusType
            : totalBolus;
        const bolusBasal =
          totalBolusPlusBasal === 0
            ? totalInsulinBolusType === 0
              ? 0
              : totalInsulinBolusType
            : totalBolusPlusBasal;
        const bolusFixed = fixToDecimalPlace(bolus + dayInsulin1, 2);
        const insulinFixed = fixToDecimalPlace(
          bolusBasal + totalInsulinTypes12,
          2,
        );
        const basalFixed = fixToDecimalPlace(
          totalBolusPlusBasal - totalBolus + dayInsulin2,
          2,
        );
        const basalPercentage = Math.round((basalFixed / insulinFixed) * 100);
        const bolusPercentage = Math.round((bolusFixed / insulinFixed) * 100);

        const basalBolusPercentage =
          basalPercentage > 0 && bolusPercentage > 0
            ? `${basalPercentage} / ${bolusPercentage}`
            : '';

        return {
          day: day.value.join(' '),
          date,
          columns: [
            day,
            totalNumberOfTest,
            meanBloodGlucose,
            standardDeviation,
            totalHypos,
            carbohydrates,
            convertToCellValue(insulinFixed),
            convertToCellValue(basalFixed),
            convertToCellValue(bolusFixed),
            convertToCellValue(numberOfBolus + numberOfInsulinTypes1),
            basalBolusPercentage,
          ],
          hasBasal: basalFixed > 0,
          hasBolus: bolusFixed > 0,
          isNotSufficiencyData: !basalFixed > 0 || !bolusFixed > 0,
          hasData: basalFixed > 0 || bolusFixed > 0,
          hasHIorLOValues: checkHasHIorLOValues(
            numberOfHIValues,
            numberOfLOValues,
          ),
          hasLOValues: !!numberOfLOValues,
        };
      },
    );
  },
);

export const logbookStatsConnector = createStructuredSelector({
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  logbookStatsData: selectLogbookStatsData,
  isLoading: selectGraphLoading,
  carbUnit: selectCarbUnitMeasurementForService,
});
