import { EC6ActiveLanguagesResponse } from './active-system-languages.types';

export const mockActiveLanguages: EC6ActiveLanguagesResponse = {
  resultDescription: 'getActiveLanguagestOK',
  model: [
    {
      id: null,
      languageId: '1',
      labelProperty: 'enum.Spanish',
      labelText: 'Spanish',
      languageCountryIsoCode: 'ES',
      isoCode: 'es',
      locale: 'es-ES',
      key: '1',
      value: 'Spanish',
    },
    {
      id: null,
      languageId: '3',
      labelProperty: 'enum.English',
      labelText: 'English',
      languageCountryIsoCode: 'GB',
      isoCode: 'en',
      locale: 'en-GB',
      key: '3',
      value: 'English',
    },
    {
      id: null,
      languageId: '5',
      labelProperty: 'enum.French',
      labelText: 'French',
      languageCountryIsoCode: 'FR',
      isoCode: 'fr',
      locale: 'fr-FR',
      key: '5',
      value: 'French',
    },
    {
      id: null,
      languageId: '16',
      labelProperty: 'enum.Polish',
      labelText: 'Polish',
      languageCountryIsoCode: 'PL',
      isoCode: 'pl',
      locale: 'pl-PL',
      key: '16',
      value: 'Polish',
    },
    {
      id: null,
      languageId: '11',
      labelProperty: 'enum.Portuguese',
      labelText: 'Portuguese',
      languageCountryIsoCode: 'PT',
      isoCode: 'pt',
      locale: 'pt-PT',
      key: '11',
      value: 'Portuguese',
    },
    {
      id: null,
      languageId: '12',
      labelProperty: 'enum.Portuguese(BR)',
      labelText: 'Portuguese (BR)',
      languageCountryIsoCode: 'BR',
      isoCode: 'pt',
      locale: 'pt-BR',
      key: '12',
      value: 'Portuguese (BR)',
    },
  ],
};
