export const STATUS = {
  IS_READY: 'isReady',
  IS_ERROR: 'isError',
  IS_LOADING: 'isLoading',
  IS_CONSENT: 'isConsent',
  IS_ACCEPTED: 'isAccepted',
};

export const BGM = 'BG';
export const CGM = 'CGM';
export const UNIT_MMOLL = ' MMOLL';
export const UNIT_MGDL = ' MGDL';

export const SHORT_CUT = {
  BG: 'shortcutBG',
  CGM: 'shortcutCGM',
};

export const RESET_PWD_PATH = '/reset-password-professional';

export const HOME = 'home';
export const HOME_CGM = 'homeCgm';

export const PLATFORM_FAV = 'favicon.png';
export const REBRANDING_FAV = 'faviconrb.png';
export const PLATFORM_TITLE = 'RocheDiabetes Care Platform';
export const REBRANDING_TITLE = 'Accu-Chek Care';
