import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type Props = {
  width?: number,
  height?: number,
  fillColor?: string,
};

export const HouseIcon = ({
  width = 24,
  height = 24,
  fillColor = colors.grayMedium,
}: Props) => {
  const originalWidth = 67;
  const originalHeight = 61;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M26.21 57.337V39.025h14.564v18.312c0 2.015 1.639 3.663 3.642 3.663H55.34c2.002 0 3.64-1.648 3.64-3.663V31.7h6.191c1.675 0 2.476-2.087 1.202-3.186L35.932.934a3.653 3.653 0 0 0-4.88 0L.612 28.514c-1.239 1.098-.474 3.185 1.2 3.185h6.191v25.638c0 2.015 1.639 3.663 3.641 3.663h10.924c2.003 0 3.641-1.648 3.641-3.663z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
