import { countlyEventTrigger } from 'src/navigation/countly';
import {
  BG_STATISTICS_DEVICES_KEYS,
  BG_STATISTICS_DEVICES_SEGMENT_KEYS,
  BG_STATISTICS_DEVICES_SEGMENT_VALUES,
} from 'src/domains/diagnostics/widgets/blood-glucose-general-stats/components/device-details/device.countly.constans';

import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { graphTypeSelected } from 'src/domains/diagnostics/components/graph-controls/graph-controls.utils';

import {
  BG_GRAPH_KEYS,
  BG_GRAPH_SEGMENT_KEYS,
} from './tabs-header.countly.constans';

export const tabSelectedCountly = (tab) => {
  if (tab && tab.activeTitle) {
    countlyEventTrigger(BG_GRAPH_KEYS.BG_GRAPH_CHANGED, {
      [BG_GRAPH_SEGMENT_KEYS.BG_GRAPH_SELECTED]: graphTypeSelected(
        tab.activeTitle,
      ),
    });
    if (tab.activeTitle === GRAPHS.BLOOD_GLUCOSE_GENERAL_STATS) {
      countlyEventTrigger(
        BG_STATISTICS_DEVICES_KEYS.BG_STATISTICS_DEVICE_SELECTED,
        {
          [BG_STATISTICS_DEVICES_SEGMENT_KEYS.DEVICE_SELECTED]:
            BG_STATISTICS_DEVICES_SEGMENT_VALUES.ALL,
        },
      );
    }
  }
};
