import * as React from 'react';
import { RenderIfElse } from 'src/utils/render-if';
import { testId } from '@roche/roche-common';

import { DepCenterNameProps } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';
import {
  JELLO_GENERAL_ALIGNMENTS,
  JELLO_GENERAL_POSITIONS,
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';

import {
  ATTRIBUTE,
  DEPARTMENT_CENTER_ID,
  MOUSSE_LEAVE,
  MOUSSE_OVER,
  POPOVER_ID,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { useCustomEventListener } from 'src/hooks';

export const DepCenterNameComponent = ({
  departmentName,
  centerName,
  c2aIndividual,
}: DepCenterNameProps) => {
  React.useEffect(() => {
    document.getElementById(POPOVER_ID)?.removeAttribute(ATTRIBUTE);
  });

  const popoverRef = useCustomEventListener(
    [MOUSSE_OVER, MOUSSE_LEAVE],
    (e) => {
      if (e.type === MOUSSE_OVER)
        document.getElementById(POPOVER_ID)?.setAttribute(ATTRIBUTE, ATTRIBUTE);
      else document.getElementById(POPOVER_ID)?.removeAttribute(ATTRIBUTE);
    },
  );

  const formatC2AName = (center: string) => {
    const formatNames = center.split(' - ');
    return (
      <jello-text
        size={JELLO_TEXT_SIZES.S}
        weight={JELLO_TEXT_WEIGHTS.LIGHT}
        ellipsis={true}
      >
        {formatNames[0]}
        <br />
        {formatNames[1]}
      </jello-text>
    );
  };

  const renderNameAndDepartmentName = () => {
    return (
      <>
        <RenderIfElse validate={c2aIndividual}>
          {formatC2AName(centerName)}
          <jello-text
            size={JELLO_TEXT_SIZES.S}
            weight={JELLO_TEXT_WEIGHTS.LIGHT}
            ellipsis={true}
          >
            {centerName}
            <br />
            {departmentName}
          </jello-text>
        </RenderIfElse>
      </>
    );
  };

  return (
    <jello-popover
      id={POPOVER_ID}
      ref={popoverRef}
      alignment={JELLO_GENERAL_ALIGNMENTS.CENTER}
      position={JELLO_GENERAL_POSITIONS.BOTTOM}
      visible
      {...testId(DEPARTMENT_CENTER_ID, POPOVER_ID)}
    >
      <div slot="trigger" style={{ display: 'grid' }}>
        {renderNameAndDepartmentName()}
      </div>
      <div slot="content">{renderNameAndDepartmentName()}</div>
    </jello-popover>
  );
};
