export const mockListDevices = {
  resultDescription: 'listDeviceAssociationsOk',
  model: {
    sEcho: null,
    iTotalRecords: 16,
    iTotalDisplayRecords: 16,
    patientDevices: [
      {
        fromDate: 1557744297439,
        toDate: null,
        lastDownloadDate: null,
        active: true,
        device: {
          id: 184905,
          serialNumber: 'SN1557744297',
          deviceModel: {
            brandName: 'ACCU-CHEK',
          },
          deviceTypeIcon: 'ICO_3-18_meter',
          name: 'Aviva',
          resourceType: 'PUMP',
        },
      },
    ],
  },
};
