// tslint:disable:prefer-immediate-return
// tslint:disable:no-collapsible-if
// tslint:disable
import * as React from 'react';
import { includes, isEmpty, isNil } from 'ramda';
import { DateTime } from 'luxon';
import { breakpointsMap } from 'src/core/styles/breakpoints';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';
import { colors } from 'src/core/styles/colors';
import { FetchStatus } from 'src/core/list-devices/list-devices.types';
import { monthsLabels } from 'src/utils/date';
import { V3Device1Icon } from 'src/assets/icons/v3-device1-icon/v3-device1-icon.component';
import { V3Device2Icon } from 'src/assets/icons/v3-device2-icon/v3-device2-icon.component';
import { V3Device3Icon } from 'src/assets/icons/v3-device3-icon/v3-device3-icon.component';
import { DevicesTypes } from 'src/domains/patient/scenes/list-devices/list-devices.types';

import { Block } from 'src/components/block/block.component';

import {
  InfoText,
  PatientName,
  PatientId,
  Spacer,
  Title,
  RowPatientInfo,
  TimeTrackerWrapper,
  SpacerShort,
  BlockDescription,
} from './patient-info.style';

import { PatientDropdown } from 'src/widgets/top-patient-bar/patient-info/components/patient-dropdown/patient-dropdown.component';
import { TimeTracker } from 'src/widgets/top-patient-bar/patient-info/components/time-tracker/time-tracker.component';
import { Chip } from 'src/widgets/top-patient-bar/patient-info/components/chip/chip.component';
import { CGMSummary } from 'src/domains/patient-dashboards/cgm/store/cgm.reducers';
import { RenderIf } from 'src/utils/render-if';
import { TimeTrackerModal } from './components/time-tracker-modal/time-tracker-modal.component';
import { PatientInfoProps, PatientInfoState } from './patient-info.types';
import { checkDiabetesType } from 'src/domains/general/widgets/components/patient-info/patient-info.utils';
import { testId } from '@roche/roche-common';

const disabledIconProps = {
  screenColor: '#e7e7e7',
  screenStrokeColor: '#c0c8cb',
  outlineColor: '#c0c8cb',
  buttonsStrokeColor: '#d8dfe1',
};

export class PatientInfoComponent extends React.Component<
  PatientInfoProps,
  PatientInfoState
> {
  public modalIntervalHandler: any = 0;
  public globalIntervalHandler: any = 0;
  public sessionEndEnpointCalled = false;

  public constructor(props) {
    super(props);
    this.state = {
      wrapWidth: window.outerWidth,
      isTimeTrackerStarted: true,
      showErrorMessage: false,
      currentSessionTime: countCurrentSessionTime(props.patientSessionTime),
      destroyModal: () => {},
    };
  }

  public componentDidMount() {
    this.startSessionInterval();
    this.adaptToWindowSize();
    window.addEventListener('resize', this.adaptToWindowSize);
  }

  public isTimeTrackerAllowed() {
    const { isRPMEnrolled, isTimeTrackerEnrolled } = this.props;
    return isRPMEnrolled && isTimeTrackerEnrolled;
  }

  public startSessionInterval() {
    this.globalIntervalHandler = setInterval(() => {
      const { currentSessionTime } = this.state;
      this.setState({ currentSessionTime: currentSessionTime + 1 });
    }, 1000);
  }

  public componentDidUpdate(prevProps) {
    if (!this.isTimeTrackerAllowed()) {
      this.globalIntervalHandler = 0;
    }
    if (this.props.patientSessionTime !== prevProps.patientSessionTime) {
      this.setState({ currentSessionTime: 0 });
    }
    const {
      sessionEndStatus,
      resetIsRunning,
      visitId,
      isPatientSessionRunning,
    } = this.props;
    if (isPatientSessionRunning) {
      if (visitId) {
        sessionStorage.setItem('visitId', visitId.toString());
      }
    }
    if (sessionEndStatus === FetchStatus.SUCCESS) {
      resetIsRunning();
      // close modal
      this.state.destroyModal();
      clearInterval(this.modalIntervalHandler);
    }
  }

  public componentWillUnmount() {
    if (this.globalIntervalHandler) {
      clearTimeout(this.globalIntervalHandler);
      this.globalIntervalHandler = 0;
    }
    window.removeEventListener('resize', this.adaptToWindowSize);
  }
  public render() {
    const {
      t,
      patient: {
        firstName,
        surName,
        healthCareSystemId,
        dateOfBirth,
        diagnosticDate,
        diabetesTypeEC6,
      },
      isRPMEnrolled,
      isTimeTrackerEnrolled,
      CGMSummary,
      isPatientSessionRunning,
    } = this.props;
    const healthCareId = healthCareSystemId ?? '';
    const { wrapWidth } = this.state;
    const isTimeTrackerStarted = isPatientSessionRunning;
    const breakPoint = breakpointsMap[1];
    const getSpacer = () =>
      this.state.wrapWidth > breakPoint ? <Spacer /> : <SpacerShort />;
    return (
      <div {...testId('patient-info-component', 'patient-info-bar')}>
        <Block
          flex={1}
          position="relative"
          mr={2}
          // tslint:disable-next-line
          width={'225px'}
          maxWidth={wrapWidth > breakPoint ? '265px' : '225px'}
        >
          <PatientDropdown />
          <RowPatientInfo>
            <PatientName>{`${firstName} ${surName}`}</PatientName>
          </RowPatientInfo>
          <RowPatientInfo>
            <PatientId>
              {'ID: '}
              {!isEmpty(healthCareId) && `${healthCareId}`}
            </PatientId>
            <RenderIf validate={isRPMEnrolled && isTimeTrackerEnrolled}>
              <RenderIf validate={isTimeTrackerStarted}>
                <TimeTrackerWrapper>
                  <Chip
                    backgroundColor={'#A254A8'}
                    onClick={() => this.onTimeTrackerClick()}
                  >
                    <TimeTracker startTime={this.state.currentSessionTime} />
                  </Chip>
                </TimeTrackerWrapper>
              </RenderIf>
              <RenderIf validate={!isTimeTrackerStarted}>
                <Chip
                  backgroundColor={colors.brandBlue}
                  onClick={() => this.onTimeTrackerClick()}
                >
                  {t('timetracker.startVisit')}
                </Chip>
              </RenderIf>
            </RenderIf>
          </RowPatientInfo>
          {/* <SettingsLink to="patientsettingsroute">
            <V3SettingsIcon />
          </SettingsLink> */}
        </Block>
        {getSpacer()}
        <Block
          display="flex"
          height="43px"
          width="408px"
          flex={1}
          justifyContent="space-between"
        >
          <BlockDescription width={wrapWidth > breakPoint ? '103px' : '85px'}>
            <Title>{t('patientNav.dateOfBirth')}</Title>
            <InfoText>{this.formatDoB(dateOfBirth)}</InfoText>
          </BlockDescription>
          {getSpacer()}
          <BlockDescription width="47px">
            <Title>
              {wrapWidth < breakpointsMap[1]
                ? t('patientNav.diabetes')
                : t('patientNav.diabetesTitle')}
            </Title>
            <InfoText>{checkDiabetesType(diabetesTypeEC6)}</InfoText>
          </BlockDescription>
          {getSpacer()}
          <BlockDescription width={wrapWidth > breakPoint ? '57px' : '53px'}>
            <Title>{t('patientNav.diagnosis')}</Title>
            <InfoText>{this.formatDiagnosticsDate(diagnosticDate)}</InfoText>
          </BlockDescription>
          {getSpacer()}
          <BlockDescription width="112px">
            <Title>{t('patientBar.deviceTitle')}</Title>
            <Block display="flex" alignItems="center" mt="2px">
              <V3Device1Icon
                height={23}
                {...this.constructIconPropsByDeviceType([
                  DevicesTypes.Meter,
                  DevicesTypes.Normal,
                ])}
              />
              <Block mr="13px" />
              <V3Device2Icon
                height={18}
                {...this.constructIconPropsByDeviceType([
                  DevicesTypes.Meterpump,
                ])}
              />
              <Block mr="12px" />
              <V3Device3Icon
                height={25}
                {...this.constructCGMIconProps(CGMSummary)}
              />
            </Block>
          </BlockDescription>
        </Block>
        <SpacerShort />
      </div>
    );
  }

  public adaptToWindowSize = () => {
    const wrapWidth = window.outerWidth;
    this.setState({ ...this.state, wrapWidth });
  };

  public onTimeTrackerClick = () => {
    const {
      createModal,
      resetPatientSession,
      updateModal,
      resetSessionEndStatus,
    } = this.props;
    // prevent passing error message
    resetSessionEndStatus();

    if (this.props.isPatientSessionRunning) {
      // first load creates modal
      this.updateModalComponent(createModal);
      // each second update modal local/store data
      this.modalIntervalHandler = setInterval(() => {
        return this.updateModalComponent(updateModal);
      }, 1000);
    } else {
      resetPatientSession();
    }
  };

  public updateModalComponent = (updateModal: (props) => void) => {
    const { patient, endSessionStart, patientId, t, sessionEndStatus } =
      this.props;

    updateModal({
      key: MODAL_TYPES.CUSTOM,
      data: {
        customComponent: TimeTrackerModal,
        patientSessionTime: this.state.currentSessionTime,
        patient,
        showErrorMessage:
          sessionEndStatus === FetchStatus.FAILURE ? true : false,
        onSaveModal: ({ data, destroyModal }) => {
          this.setState({ destroyModal });
          endSessionStart({
            patientId: patientId.toString(),
            data: data,
            t,
          });
        },
        onClearInterval: () => {
          clearInterval(this.modalIntervalHandler);
        },
      },
    });
  };

  private constructIconPropsByDeviceType = (deviceTypes: DevicesTypes[]) => {
    const isDeviceTypeActivated =
      this.doesDeviceListContainsActivedDevice(deviceTypes);

    return this.generateIconProps(isDeviceTypeActivated);
  };

  private constructCGMIconProps = (cgmSummary: CGMSummary) => {
    const isCgmActivated =
      this.hasPatientCGMAssociation(cgmSummary) ||
      this.doesDeviceListContainsActivedDevice([DevicesTypes.Cgm]);

    return this.generateIconProps(isCgmActivated);
  };

  private doesDeviceListContainsActivedDevice(
    deviceTypes: DevicesTypes[],
  ): boolean {
    const { activeDevices } = this.props;
    return deviceTypes.some((deviceType) =>
      includes(deviceType, activeDevices),
    );
  }
  private hasPatientCGMAssociation = ({
    lastMeasurementDate,
  }: CGMSummary): boolean => {
    return !isEmpty(lastMeasurementDate) && !isNil(lastMeasurementDate);
  };

  private generateIconProps = (isActivated: boolean) =>
    isActivated ? {} : disabledIconProps;

  private formatDiagnosticsDate(date: string | false): string {
    if (!date) return '-';
    const { t } = this.props;
    const luxonDate = DateTime.fromISO(date);
    const textKey = monthsLabels[luxonDate.month - 1];
    const year = luxonDate.year;
    return `${t(textKey)} ${year}`;
  }
  private formatDoB(date: string | false): string {
    if (!date) return '-';
    const luxon = DateTime.fromISO(date);
    const format = luxon.toFormat('dd LLL yyyy');
    const age = Math.floor(Math.abs(luxon.diffNow('years').years));
    return `${format} (${age})`;
  }
}

export const countCurrentSessionTime = (patientSessionTime: number) => {
  if (!countCurrentSessionTime) return 0;

  const now = Math.round(new Date().getTime() / 1000);
  const timeDifference = now - patientSessionTime;

  return timeDifference;
};
