// Every mismatch between key and value was there for 6 years already, if errors are found, investigate
export const relatedMealTimesMap = {
  BEDTIME: 'NIGHT',
  NIGHT: 'BEDTIME',
  BEFORE_BREAKFAST: 'AFTER_BREAKFAST',
  AFTER_BREAKFAST: 'BEFORE_BREAKFAST',
  BEFORE_LUNCH: 'AFTER_LUNCH',
  AFTER_LUNCH: 'BEFORE_LUNCH',
  BEFORE_DINNER: 'AFTER_DINNER',
  AFTER_DINNER: 'BEFORE_DINNER',
};

export const mealTimeSortMap = {
  NIGHT: 0,
  BEFORE_BREAKFAST: 1,
  AFTER_BREAKFAST: 2,
  BEFORE_LUNCH: 3,
  AFTER_LUNCH: 4,
  BEFORE_DINNER: 5,
  AFTER_DINNER: 6,
  BEDTIME: 7,
};
