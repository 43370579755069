export const mockLoaderSuccess = () =>
  Promise.resolve({
    message: 'Everything ok',
  });

export const mockLoaderError = () =>
  Promise.reject({
    error: {
      code: 401,
      message: 'Error',
    },
  });
