import {
  ChangesPasswordActions,
  changePasswordActionType,
  isChangedPassword,
} from './change-password.types';

export const isChangedPasswordState: isChangedPassword = {
  changed: false,
};

const handleChangePasswordSuccess = (
  state = isChangedPasswordState,
): isChangedPassword => ({
  ...state,
  changed: true,
});

const handleChangePasswordReset = (
  state = isChangedPasswordState,
): isChangedPassword => ({
  ...state,
  changed: false,
});

const actionHandlers = {
  [changePasswordActionType.IS_CHANGED_PASSWORD_SUCCESS]:
    handleChangePasswordSuccess,
  [changePasswordActionType.IS_CHANGED_PASSWORD_RESET]:
    handleChangePasswordReset,
};

export const changedPasswordReducer = (
  state = isChangedPasswordState,
  action: ChangesPasswordActions,
): isChangedPassword => {
  const handler = actionHandlers[action.type];
  return handler ? handler() : state;
};
