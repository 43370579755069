import { DateTime } from 'luxon';

export const transformData = (data: any, t: any, is12HourFormat: boolean) =>
  data.map((val) => ({
    ...val,
    methodOfCommunication:
      val.methodOfCommunication === 'NONE'
        ? ''
        : t(`timetracker.modal.${val.methodOfCommunication}`),
    dateOfIntervention: getDataLastSync(val.dateOfIntervention, is12HourFormat),
    patientCareActivities: val.patientCareActivities
      .map((val) => t(`timetracker.modal.${val}`))
      .join(' \n'),
  }));

export const getDataLastSync = (time, is12hourTimeFormat) =>
  is12hourTimeFormat
    ? DateTime.fromISO(time).toFormat('dd LLL yyyy ') +
      DateTime.fromISO(time).toFormat('hh:mm a').toUpperCase()
    : DateTime.fromISO(time).toFormat('dd LLL yyyy HH:mm');

export const getTranslatedHeaders = (t) => [
  { label: t('patientExport.csv.patientId'), key: 'patientId' },
  { label: t('patientExport.csv.patientName'), key: 'patientName' },
  { label: t('patientExport.csv.hcpName'), key: 'hcpName' },
  {
    label: t('patientExport.csv.patientCenterName'),
    key: 'patientCenterName',
  },
  {
    label: t('patientExport.csv.dateOfIntervention'),
    key: 'dateOfIntervention',
  },
  {
    label: t('patientExport.csv.timeSpent'),
    key: 'timeSpent',
  },
  {
    label: t('patientExport.csv.communicationMethod'),
    key: 'methodOfCommunication',
  },
  {
    label: t('patientExport.csv.patientCareActivities'),
    key: 'patientCareActivities',
  },
  { label: t('patientExport.csv.observations'), key: 'reportSummary' },
];
