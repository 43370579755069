import * as React from 'react';

import { SvgIcon } from 'src/components/icon/icon.component';
import { colors } from 'src/core/styles/colors';

type Props = {
  height?: number;
  width?: number;
  fillColor?: string;
  circle?: boolean;
  fillOpacity?: number;
  fillCircleColor?: string;
  originalWidth?: number;
  originalHeight?: number;
  minX?: number;
  minY?: number;
  radius?: string | number;
  strokeColor?: string;
};

export const V3StockFilledIcon = ({
  fillColor = colors.white,
  width,
  height = 35,
  fillOpacity = 1,
  fillCircleColor = colors.white,
  originalWidth = 45,
  originalHeight = 35,
  minX,
  minY,
  radius = '10',
  strokeColor = colors.white,
}: Props) => (
  <SvgIcon
    title="Checkmark"
    width={width}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
    minX={minX}
    minY={minY}
    radius={radius}
  >
    <circle
      id="Oval-3"
      fillOpacity={fillOpacity}
      fill={fillCircleColor}
      cx="10"
      cy="10"
      r={radius}
    />
    <svg width="35" height="35" viewBox="-12 -6 70 70">
      <g
        id="v3-stock"
        stroke={strokeColor}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Main-Menu"
          transform="translate(-124.000000, -139.000000)"
          fill="#FFF"
        >
          <g id="Group-20" transform="translate(111.000000, 130.000000)">
            <g id="Stock" transform="translate(1.000000, 9.000000)">
              <g id="stock-icon" transform="translate(12.000000, 0.000000)">
                <g id="Group-19">
                  <path
                    d="M1,7 C1.55228475,7 2,7.44771525 2,8 L2,22 C2,22.5522847 1.55228475,23 1,23 C0.44771525,23 6.76353751e-17,22.5522847 0,22 L0,8 C-6.76353751e-17,7.44771525 0.44771525,7 1,7 Z"
                    id="Rectangle"
                  />
                  <path
                    d="M6,7 C6.55228475,7 7,7.44771525 7,8 L7,22 C7,22.5522847 6.55228475,23 6,23 C5.44771525,23 5,22.5522847 5,22 L5,8 C5,7.44771525 5.44771525,7 6,7 Z"
                    id="Rectangle"
                  />
                  <path
                    d="M16,7 C16.5522847,7 17,7.44771525 17,8 L17,22 C17,22.5522847 16.5522847,23 16,23 C15.4477153,23 15,22.5522847 15,22 L15,8 C15,7.44771525 15.4477153,7 16,7 Z"
                    id="Rectangle"
                  />
                  <rect
                    id="Rectangle"
                    x="10"
                    y="0"
                    width="2"
                    height="23"
                    rx="1"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
);
