import styled from 'styled-components';
import { Span } from 'src/components/span/span.component';
import { colors } from 'src/core/styles/colors';

export const MediumTitle = styled(Span)`
  color: ${colors.gray33};
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.3px;
`;
MediumTitle.displayName = 'MediumTitle';
