import { compose, isNil } from 'ramda';
import React, { Component, Fragment } from 'react';
import { Subject } from 'rxjs/Subject';
import { withTheme } from 'styled-components';
import { AutoSizer } from 'react-virtualized';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';
import { Graph } from 'src/lib/graph.component';
import { PointSeries } from 'src/lib/graph-point-series.component';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import {
  DetailBasalEventTooltip,
  DetailBasalSetbackTooltip,
  DetailBasalTooltip,
  DetailCarbohydratesToolTip,
  DetailGlucoseToolTip,
  DetailInsulinToolTip,
} from 'src/domains/diagnostics/components/tool-tip/detail-tool-tip/detail-tool-tip.component';
import { BasalAxis } from 'src/domains/diagnostics/components/graph/graph-axes/basal-axis.component';
import { CarbohydratesAxis } from 'src/domains/diagnostics/components/graph/graph-axes/carbohydrates-axis.component';
import { BasalGraph } from 'src/domains/diagnostics/components/graph/basal-graph.component';
import { BasalRateChanges } from 'src/domains/diagnostics/components/graph/basal-rate-changes.component';
import { BasalRateProfileChanges } from 'src/domains/diagnostics/components/graph/basal-rate-profile-changes.component';
import { CarbohydratesGraph } from 'src/domains/diagnostics/components/graph/carbohydrates-graph.component';
import { DayRangeAxis } from 'src/domains/diagnostics/components/graph/graph-axes/day-range-axis.component';
import { InsulinAxis } from 'src/domains/diagnostics/components/graph/graph-axes/insulin-axis.component';
import { InsulinGraph } from 'src/domains/diagnostics/components/graph/insulin-graph.component';
import { GRAPH_LEGEND_TYPES } from 'src/domains/diagnostics/components/graph-legend/graph-legend.constant';
import { SetbackShape } from 'src/domains/diagnostics/components/graph/point-shapes.component';
import { BloodGlucoseAxis } from 'src/domains/diagnostics/components/graph/graph-axes/blood-glucose-axis.component';
import { GraphPlot } from 'src/domains/diagnostics/components/graph/graph-plot/graph-plot.component';
import { ToolTip } from 'src/domains/diagnostics/components/tool-tip/tool-tip.component';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls/graph-controls.component';
import { ResizeWrapper } from 'src/components/resize-wrapper/resize-wrapper.component';
import { AppleEatenIcon, AppleIcon } from 'src/assets/icons';
import {
  BASAL_PROFILE_CHANGE_TYPE,
  COLLAPSED_STD_GRAPH_HEIGHT,
  DATA_TYPE,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { filterVerticalTicksForViewAndVisibilityTolerance } from 'src/domains/diagnostics/utils/graphs.util';
import { getYAxisTickVisibilityTolerance } from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph.util';
import { withToolTip } from 'src/utils/with-tool-tip';

import { DetailGraph } from '../components/detail-graph/detail-graph.component';
import { TrendPlotWrapperDiv } from '../trend.style';
import {
  createEventHandlerStream,
  getToolTipValueColor,
  navigateToLogbook,
} from '../../../graph-shared/graph.util';
import {
  LOGBOOK_TYPE_DETAILS,
  LOGBOOK_TYPE_DIARY,
  PLOT_HEIGHT,
  PLOT_WIDTH,
  SUB_PLOT_HEIGHT,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from '../../../graph.constants';
import { areDatesTheSameDay } from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph-date';
import { graphHasMeasurement } from 'src/domains/diagnostics/scenes/graphs/graph-shared/measurements';
import { getClickableCursorStyle } from 'src/domains/diagnostics/scenes/graphs/graph-shared/presentation.util';
import { testId } from '@roche/roche-common';
import { getCarbohydratesUnitsLabel } from 'src/domains/diagnostics/scenes/graphs/graph-statistics.util';

const TrendPlotWrapperDivWithLoader = withGraphLoader(TrendPlotWrapperDiv);

const clickStream$ = new Subject();
const stop$ = new Subject();

const FULL_OPACITY = 1;
const FADED_OPACITY = 0.1;

class TrendDetail extends Component {
  state = {
    selectedDate: null,
  };

  componentDidMount() {
    createEventHandlerStream(clickStream$, stop$)(
      ([{ date: selectedDate }]) =>
        this.setState((state) => ({ ...state, selectedDate })),
      ([{ date, type }]) => {
        const logbookType =
          type === DATA_TYPE.GLUCOSE
            ? LOGBOOK_TYPE_DETAILS
            : LOGBOOK_TYPE_DIARY;

        navigateToLogbook(
          this.props.history,
          date,
          this.props.changeLogbookType,
          logbookType,
        );
      },
    );
  }

  render() {
    const {
      onLineClick,
      props: {
        bloodGlucoseUnit,
        collapsed,
        collapsedGP,
        measurements,
        graphData,
        targetRange,
        threshold,
        verticalTicks,
        horizontalDayTicks,
        horizontalMonthYearTicks,
        insulinTicks,
        basalLines,
        basalTicks,
        tbrLines,
        tbrPoints,
        profileChanges,
        basalRateChanges,
        timeSetback,
        carbohydratesTicks,
        showGridLines,
        graphDetails,
        yDirection = -1,
        toolTip,
        showToolTip,
        hideToolTip,
        flexibleHeight,
        graphYMax,
        isLoading,
        insulinPoints,
        theme,
        graphToggles,
        showChangeGraphToggle,
        t,
        timeFormat,
        graph,
        carbUnit,
      },
      state: { selectedDate },
    } = this;

    const carbLabel = getCarbohydratesUnitsLabel[carbUnit];

    const yAxisTickVisibilityTolerance = getYAxisTickVisibilityTolerance({
      bloodGlucoseUnit,
    });
    return (
      <Fragment>
        <RenderIf
          validate={
            graphHasMeasurement(
              measurements,
              insulinPoints,
              basalLines,
              graphData.carbohydratesLines,
            ) && !isLoading
          }
        >
          <GraphControls
            showChangeGraphToggle={showChangeGraphToggle}
            graphStatistics={graphDetails}
            graphType={GRAPH_LEGEND_TYPES.TREND_DETAIL}
            collapsed={collapsed}
            collapsedGP={collapsedGP}
            graph={graph}
          />
        </RenderIf>
        <ResizeWrapper
          minHeight={
            collapsed
              ? COLLAPSED_STD_GRAPH_HEIGHT
              : MIN_EXPANDED_STD_GRAPH_HEIGHT
          }
          render={(height) => {
            const filteredVerticalTicks =
              filterVerticalTicksForViewAndVisibilityTolerance(
                verticalTicks,
                flexibleHeight,
                threshold,
                targetRange,
                yAxisTickVisibilityTolerance,
                graphYMax,
              );

            return (
              <TrendPlotWrapperDivWithLoader
                {...testId('graph-template', 'trend-detail')}
                hasError={
                  !graphHasMeasurement(
                    measurements,
                    insulinPoints,
                    basalLines,
                    graphData.carbohydratesLines,
                  ) && !isLoading
                }
                isLoading={isLoading}
                onClick={this.onGraphClick}
                collapsed={collapsed}
                collapsedGP={collapsedGP}
              >
                <AutoSizer>
                  {({ width }) => (
                    <RenderIf validate={width && height}>
                      <Graph
                        viewportRight={width}
                        viewportBottom={height}
                        height={height}
                        anchor="xMidYMid"
                      >
                        <GraphPlot
                          width={width}
                          height={height}
                          xAxisHeight={X_AXIS_HEIGHT}
                          yAxisWidth={Y_AXIS_WIDTH}
                          plotHeight={PLOT_HEIGHT}
                          plotWidth={PLOT_WIDTH}
                          subPlotHeight={SUB_PLOT_HEIGHT}
                          mainGraph={(dimensions) => (
                            <Fragment>
                              <DetailGraph
                                {...dimensions}
                                yDirection={yDirection}
                                targetRange={targetRange}
                                threshold={threshold.value}
                                horizontalDayTicks={horizontalDayTicks}
                                showGridLines={showGridLines}
                                graphData={graphData}
                                selectedDate={selectedDate}
                                onLineClick={onLineClick}
                                onPointMouseOver={showToolTip}
                                onPointMouseOut={hideToolTip}
                                graphYMax={graphYMax}
                                tbrLines={
                                  graphToggles.showBasalRate ? tbrLines : []
                                }
                                tbrPoints={
                                  graphToggles.showBasalRate ? tbrPoints : []
                                }
                                collapsed={collapsed}
                                bloodGlucoseUnit={bloodGlucoseUnit}
                              />
                              <RenderIf validate={graphToggles.showBasalRate}>
                                <BasalRateProfileChanges
                                  {...{
                                    profileChanges,
                                    dimensions,
                                    selectedDate,
                                    showToolTip,
                                    hideToolTip,
                                    onLineClick,
                                    collapsed,
                                  }}
                                />
                                <BasalRateChanges
                                  {...{
                                    basalRateChanges,
                                    dimensions,
                                    selectedDate,
                                    showToolTip,
                                    hideToolTip,
                                    onLineClick,
                                    collapsed,
                                  }}
                                />
                                <PointSeries
                                  points={timeSetback}
                                  {...dimensions}
                                  Shape={({ x, y, duration, date }) => (
                                    <SetbackShape
                                      x={x}
                                      y={y}
                                      opacity={
                                        isNil(selectedDate) ||
                                        areDatesTheSameDay(date, selectedDate)
                                          ? FULL_OPACITY
                                          : FADED_OPACITY
                                      }
                                      onClick={this.onLineClick({
                                        type: DATA_TYPE.SETBACK,
                                        date: date,
                                      })}
                                      onMouseMove={(event) =>
                                        showToolTip(
                                          event,
                                          {
                                            type: DATA_TYPE.SETBACK,
                                            date,
                                            duration,
                                          },
                                          180,
                                        )
                                      }
                                      onMouseOut={hideToolTip}
                                      cursor={getClickableCursorStyle(
                                        !collapsed,
                                      )}
                                    />
                                  )}
                                />
                              </RenderIf>
                            </Fragment>
                          )}
                          upperSubGraph={(dimensions) => (
                            <RenderIf validate={graphToggles.showInsulin}>
                              <InsulinGraph
                                {...dimensions}
                                insulinPoints={insulinPoints}
                                selectedDate={selectedDate}
                                onLineClick={onLineClick}
                                onPointMouseOver={showToolTip}
                                onPointMouseOut={hideToolTip}
                                collapsed={collapsed}
                              />
                            </RenderIf>
                          )}
                          yAxisLeft={(dimensions) => (
                            <BloodGlucoseAxis
                              {...dimensions}
                              ticks={filteredVerticalTicks}
                              unit={bloodGlucoseUnit}
                            />
                          )}
                          yAxisRightTop={(dimensions) => (
                            <RenderIf validate={graphToggles.showInsulin}>
                              <InsulinAxis
                                {...dimensions}
                                ticks={insulinTicks}
                              />
                            </RenderIf>
                          )}
                          yAxisRightBottom={(dimensions) => (
                            <Fragment>
                              <RenderIf validate={graphToggles.showBasalRate}>
                                <BasalAxis {...dimensions} ticks={basalTicks} />
                              </RenderIf>
                              <RenderIf
                                validate={
                                  !graphToggles.showBasalRate &&
                                  graphToggles.showCarbohydrates
                                }
                              >
                                <CarbohydratesAxis
                                  {...dimensions}
                                  ticks={carbohydratesTicks}
                                />
                              </RenderIf>
                            </Fragment>
                          )}
                          xAxisBottom={(dimensions) => (
                            <DayRangeAxis
                              {...dimensions}
                              dayTicks={horizontalDayTicks}
                              monthYearTicks={horizontalMonthYearTicks}
                              collapsed={collapsed}
                            />
                          )}
                          lowerSubGraph={(dimensions) => (
                            <Fragment>
                              <RenderIf
                                validate={graphToggles.showCarbohydrates}
                              >
                                <CarbohydratesGraph
                                  {...dimensions}
                                  lines={graphData.carbohydratesLines}
                                  selectedDate={selectedDate}
                                  onLineClick={onLineClick}
                                  onPointMouseOver={showToolTip}
                                  onPointMouseOut={hideToolTip}
                                  collapsed={collapsed}
                                />
                              </RenderIf>
                              <RenderIf validate={graphToggles.showBasalRate}>
                                <BasalGraph
                                  {...dimensions}
                                  basalLines={[basalLines]}
                                  yDirection={yDirection}
                                  selectedDate={selectedDate}
                                  onLineClick={onLineClick}
                                  onMouseMove={showToolTip}
                                  onMouseOut={hideToolTip}
                                  collapsed={collapsed}
                                />
                              </RenderIf>
                            </Fragment>
                          )}
                        />
                      </Graph>
                    </RenderIf>
                  )}
                </AutoSizer>
              </TrendPlotWrapperDivWithLoader>
            );
          }}
        />
        <RenderIf validate={toolTip.x && toolTip.y}>
          {renderToolTip(
            toolTip,
            threshold,
            targetRange,
            theme,
            t,
            timeFormat,
            carbLabel,
          )}
        </RenderIf>
      </Fragment>
    );
  }

  onLineClick = (data) => (e) => {
    e.stopPropagation();
    clickStream$.next(data);
  };

  onGraphClick = () => {
    if (!this.props.collapsed) {
      this.setState({ selectedDate: null });
    }
  };
}

const renderToolTip = (
  { x, y, data },
  { data: thresholdData },
  { data: targetRangeData },
  theme,
  t,
  timeFormat,
  carbLabel,
) => {
  const {
    date,
    endDate,
    value,
    bloodGlucoseUnit,
    beforeMeal,
    afterMeal,
    bolusValue,
    bolusValueOriginal,
    bolusRemark,
    basalCbrf,
    insulin1,
    insulin2,
    insulin3,
    tbr,
    tbrLabel,
    type,
    changeType,
    duration,
    manuallyEntered,
  } = data;
  const icon = beforeMeal ? (
    <AppleIcon height="17" />
  ) : afterMeal ? (
    <AppleEatenIcon height="17" />
  ) : null;
  const insulinValues = [
    {
      label: t('graphs.insulin.bolusIns1'),
      value: bolusValueOriginal || bolusValue,
    },
    { label: t('graphs.insulin.bolusIns1'), value: insulin1 },
    { label: t('graphs.insulin.bolusIns2'), value: insulin2 },
    { label: t('graphs.insulin.bolusIns3'), value: insulin3 },
  ].filter(({ value }) => !isNil(value));
  return (
    <ToolTip x={x} y={y}>
      <RenderIf validate={type === DATA_TYPE.INSULIN}>
        <DetailInsulinToolTip
          topBarColor={colors.blueMarine}
          values={insulinValues}
          date={date}
          remark={bolusRemark}
          timeFormat={timeFormat}
        />
      </RenderIf>
      <RenderIf validate={type === DATA_TYPE.TBR_EVENT}>
        <DetailBasalEventTooltip
          topBarColor={colors.blueMarine}
          type={tbrLabel}
          date={date}
          timeFormat={timeFormat}
        />
      </RenderIf>
      <RenderIf validate={type === DATA_TYPE.PROFILE_CHANGE}>
        <DetailBasalEventTooltip
          topBarColor={colors.blueMarine}
          type={
            changeType === BASAL_PROFILE_CHANGE_TYPE.ACTIVE
              ? 'graphs.iconTitles.activeProfile'
              : 'graphs.iconTitles.basalRateProfile'
          }
          date={date}
          timeFormat={timeFormat}
        />
      </RenderIf>
      <RenderIf validate={type === DATA_TYPE.BASAL_RATE_CHANGE}>
        <DetailBasalEventTooltip
          topBarColor={colors.blueMarine}
          type={'graphs.iconTitles.basalRateChange'}
          date={date}
          timeFormat={timeFormat}
        />
      </RenderIf>
      <RenderIf validate={type === DATA_TYPE.SETBACK}>
        <DetailBasalSetbackTooltip
          topBarColor={colors.blueMarine}
          date={date}
          duration={duration}
          timeFormat={timeFormat}
        />
      </RenderIf>
      <RenderIf validate={type === DATA_TYPE.CARBOHYDRATES}>
        <DetailCarbohydratesToolTip
          topBarColor={theme.colors.blueMarine}
          timeFormat={timeFormat}
          {...{ date, value, carbLabel }}
        />
      </RenderIf>
      <RenderIf
        validate={
          value &&
          (type === DATA_TYPE.GLUCOSE || type === DATA_TYPE.MEAN_GLUCOSE)
        }
      >
        <DetailGlucoseToolTip
          timeFormat={timeFormat}
          topBarColor={getToolTipValueColor(
            value,
            thresholdData.value,
            targetRangeData,
          )}
          {...{ bloodGlucoseUnit, date, icon, type, value, manuallyEntered }}
        />
      </RenderIf>
      <RenderIf validate={basalCbrf != null && type === DATA_TYPE.BASAL}>
        <DetailBasalTooltip
          timeFormat={timeFormat}
          topBarColor={theme.colors.blueMarine}
          value={basalCbrf}
          {...{ date, endDate, tbr }}
        />
      </RenderIf>
    </ToolTip>
  );
};

export const TrendDetailComponent = compose(
  withTheme,
  withToolTip,
  withTranslation,
)(TrendDetail);
