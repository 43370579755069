import * as React from 'react';

import { GenerateRowIconsComponent } from '../icon-components.component';

import {
  NightIcon,
  OvernightIcon,
  AppleIcon,
  AppleEatenIcon,
} from 'src/assets/icons';

import { colors } from 'src/core/styles/colors';

const logbookIconsTitle = 'manufacturerInfo.logbook.logbookIconsTitle';

const logbookComponents = [
  {
    icon: (
      <NightIcon
        key={1}
        height={20}
        width={20}
        fillColor={colors.blueMarine}
        iconColor={colors.white}
        borderFillColor={colors.blueMarine}
        withBorder={true}
        borderColor={colors.white}
      />
    ),
    name: 'manufacturerInfo.logbook.night',
  },
  {
    icon: (
      <OvernightIcon
        key={2}
        height={20}
        width={20}
        fillColor={colors.blueMarine}
        iconColor={colors.white}
        borderFillColor={colors.blueMarine}
        withBorder={true}
        borderColor={colors.white}
      />
    ),
    name: 'manufacturerInfo.logbook.bedtime',
  },
  {
    icon: (
      <AppleIcon
        key={3}
        height={22}
        width={22}
        fillColor={colors.blueMarine}
        iconColor={colors.white}
        borderFillColor={colors.blueMarine}
        withBorder={true}
        borderColor={colors.white}
      />
    ),
    name: 'manufacturerInfo.logbook.beforeMeal',
  },
  {
    icon: (
      <AppleEatenIcon
        key={4}
        height={22}
        width={22}
        fillColor={colors.blueMarine}
        iconColor={colors.white}
        borderFillColor={colors.blueMarine}
        withBorder={true}
        borderColor={colors.white}
      />
    ),
    name: 'manufacturerInfo.logbook.afterMeal',
  },
];

export const LogbookIconsComponent = () => (
  <GenerateRowIconsComponent
    generalIconTags={logbookComponents}
    generalIconsTitle={logbookIconsTitle}
  />
);
