import * as React from 'react';
import {
  PLATFORM_FAV,
  PLATFORM_TITLE,
  REBRANDING_FAV,
  REBRANDING_TITLE,
} from './favicon.constants';

type FaviconProps = {
  rebranding: boolean;
};

export const FaviconComponent = ({ rebranding }: FaviconProps) => {
  React.useEffect(() => {
    const linkElement = document.querySelector('link[rel="shortcut icon"]');
    const title = document.querySelector('title');

    if (linkElement) {
      linkElement.setAttribute(
        'href',
        rebranding ? REBRANDING_FAV : PLATFORM_FAV,
      );
    }

    if (title) {
      title.textContent = rebranding ? REBRANDING_TITLE : PLATFORM_TITLE;
    }
  }, [rebranding]);

  return null;
};
