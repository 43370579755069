import { path } from 'ramda';
import { createStructuredSelector, createSelector } from 'reselect';

export const selectINSIdentityState = path(['insIdentity']);

export const selectINSIdentity = createSelector(
  selectINSIdentityState,
  path(['patientIdentity']),
);

export const selectIsFetchingINSIdentity = createSelector(
  selectINSIdentityState,
  path(['isFetchingINSIdentity']),
);
export const selectIsFetchingINSIdentityError = createSelector(
  selectINSIdentityState,
  path(['isFetchingINSIdentityError']),
);

export const selectSuccessINSIdentity = createSelector(
  selectINSIdentityState,
  path(['successINSIdentity']),
);
export const selectUserHasCancelledRequest = createSelector(
  selectINSIdentityState,
  path(['userHasCancelledRequest']),
);

export const INSIdentityConnector = createStructuredSelector({
  insIdentity: selectINSIdentity,
  isFetchingINS: selectIsFetchingINSIdentity,
  hasErrorINS: selectIsFetchingINSIdentityError,
  successINS: selectSuccessINSIdentity,
  userCancelledRequest: selectUserHasCancelledRequest,
});
