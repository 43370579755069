import {
  NextShipmentChangeDateLoaderImpl,
  NextShipmentChangeDateServiceImpl,
  NextShipmentChangeDateTransformImpl,
} from './next-shipment-change-date.service';
import { mockNextShipmentChangeDateData } from '././next-shipment-change-date.mock';

const mockLoader = (q) => Promise.resolve(mockNextShipmentChangeDateData);

export const NextShipmentChangeDateFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : NextShipmentChangeDateLoaderImpl;
  return NextShipmentChangeDateServiceImpl(
    loader,
    NextShipmentChangeDateTransformImpl,
  );
};
