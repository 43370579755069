import * as React from 'react';
import { TFunction } from 'i18next';

import { getLabel } from 'src/utils/i18n/with-translation';

import {
  ManufacturerInfoTitle,
  ManufacturerInfoIntenUseDescription,
} from '../manufacturer-info-modal.style';
import { appendSuffixIfRebranded } from '../manufacturer-info-modal.utils';

type Props = {
  t: TFunction;
  rebranding: boolean;
};

export const IntendedUseView = ({ t, rebranding }: Props) => (
  <div style={{ marginBottom: '1.5rem' }}>
    <ManufacturerInfoTitle>
      {t('manufacturerInfo.intendedUse.title')}
    </ManufacturerInfoTitle>
    <ManufacturerInfoIntenUseDescription>
      {getLabel(
        t(
          appendSuffixIfRebranded(
            'manufacturerInfo.intendedUse.descriptionText',
            rebranding,
          ),
        ),
      )}
    </ManufacturerInfoIntenUseDescription>
  </div>
);
