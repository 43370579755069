import * as React from 'react';

type BundleProps = {
  bundleDidLoad: (component: any) => React.ReactNode;
  bundleWillLoad: () => void;
};

type BundleState = {
  bundleDidLoad: () => void;
  bundleWillLoad: () => void;
  component: null;
};

export class Bundle extends React.Component<BundleProps, BundleState> {
  public state = {
    bundleDidLoad: () => undefined,
    bundleWillLoad: () => undefined,
    component: null,
  };

  public componentWillMount() {
    this.load(this.props.bundleWillLoad);
  }

  public render() {
    return this.state.component
      ? this.props.bundleDidLoad(this.state.component)
      : null;
  }

  private async load(loadBundle) {
    const component = await loadBundle();
    this.setState({ component });
  }
}
