import React from 'react';
import { INFOS } from 'src/domains/diagnostics/components/additional-info/additional-info.constants';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  Tabs,
  TabsContent,
  TabsHeader,
  TabLinkItem,
} from 'src/components/tabs/tabs.component';
import { Card } from 'src/components/card/card.component';
import { GridContainer, GridItem } from 'src/components/grid-layout/index';
import { JELLO_ICON_BUTTON_SIZES } from 'src/app/app.jello.constants';

export const OrgStripManagementComponent = ({ match, children, t }) => (
  <GridContainer marginBottom>
    <GridItem span="12">
      <Card
        info={{
          info: INFOS.stockDetails,
          style: { paddingTop: '18px' },
        }}
        iconSize={JELLO_ICON_BUTTON_SIZES.L}
        customHeaderComponent={
          <Tabs>
            <TabsHeader mb={4} mr="auto" ml="0">
              <TabLinkItem name={t('orgStock.title')} path="" />
            </TabsHeader>
            <TabsContent>{children}</TabsContent>
          </Tabs>
        }
      ></Card>
    </GridItem>
  </GridContainer>
);

export const OrgStripManagement = withTranslation(OrgStripManagementComponent);
