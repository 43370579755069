import { pathOr, propOr, map } from 'ramda';
import { DateTime } from 'luxon';
import { PatientGender } from 'src/core/patient/patient.types';

import { Gender as EC6Gender } from './patient.types';
import { convertISO } from 'src/utils/date';

export const convertPatientIdToString = (id: string | number) =>
  String(id || '');

// Hardcoded values representing those passed from EC6
// TODO: Revisit our translation strategy
// NOTE: The values in this map are now coded into pi-translations.
const diabetesTypeMap = {
  DIABETES_TYPE1: '1',
  DIABETES_TYPE2: '2',
  DIABETES_GESTATIONAL: 'Gestational',
  DIABETES_TYPE2_MDI: '2MDI',
  PENDING_TO_IDENTIFY: 'Pending',
};

export const serverToClientDiabetesType = (serverDiabetesType) =>
  propOr(null, serverDiabetesType)(diabetesTypeMap);

export const convertPatientGenderToEC6Gender = (
  gender: PatientGender,
): EC6Gender => {
  switch (gender) {
    case PatientGender.MALE:
      return EC6Gender.MALE;
    case PatientGender.FEMALE:
      return EC6Gender.FEMALE;
    default:
      return EC6Gender.UNSPECIFIED;
  }
};

export const convertPatientGenderStringToEC6Gender = (
  gender: string,
): EC6Gender | null => {
  switch (gender) {
    case PatientGender.MALE:
      return EC6Gender.MALE;
    case PatientGender.FEMALE:
      return EC6Gender.FEMALE;
    case PatientGender.UNSPECIFIED:
      return EC6Gender.UNSPECIFIED;
    case PatientGender.SELECTOPTION:
      return null;
    default:
      return null;
  }
};

export const convertEC6GenderToPatientGender = (
  gender: EC6Gender,
): PatientGender | null => {
  switch (gender) {
    case EC6Gender.MALE:
      return PatientGender.MALE;
    case EC6Gender.FEMALE:
      return PatientGender.FEMALE;
    case EC6Gender.UNSPECIFIED:
      return PatientGender.UNSPECIFIED;
    case EC6Gender.SELECTOPTION:
      return null;
    default:
      return null;
  }
};

export const transformDevice = ({ id, active, lastDownloadDate, device }) => ({
  id,
  active,
  lastDownloadDate: DateTime.fromISO(lastDownloadDate, {
    setZone: true,
  }).toISO(),
  deviceModel: pathOr('', ['deviceModel'], device),
  deviceTypeIcon: pathOr('', ['deviceTypeIcon'], device),
  serialNumber: pathOr('', ['serialNumber'], device),
});

export const fromEc6Patient = (PatientData) => {
  return {
    id: PatientData.id,
    healthCareSystemId: PatientData.healthCareSystemId,
    firstName: PatientData.user.name,
    surName: PatientData.user.surname,
    surName2: PatientData.user.surname2,
    fullName: PatientData.user.fullname,
    dateOfBirth: PatientData.user.birthday
      ? convertISO(PatientData.user.birthday).toString()
      : null,
    diabetesType: serverToClientDiabetesType(PatientData.diabetesType),
    diabetesTypeEC6: PatientData.diabetesType,
    diagnosticDate: PatientData.diagnosticDate,
    therapyType: PatientData.therapyType,
    treatmentName: PatientData.treatmentName,
    centerName: PatientData.user.centerName,
    timezone: PatientData.user.address.country.timeZone,
    devices: map(transformDevice, PatientData.patientDevices),
    profile: PatientData.user.profile,
    lastGlucoseDate: convertISO(PatientData.lastGlucoseDate).toJSDate(),
    gigyaUid: PatientData.user.gigyaUid,
    fhirId: PatientData.user.fhirId,
    unknownBirthdate: PatientData.user.unknownBirthdate,
    isEMRCompleted: PatientData.emrcomplete ? PatientData.emrcomplete : false,
    birthNames: pathOr(
      null,
      ['user', 'userAdditionalInfo', 'birthNames'],
      PatientData,
    ),
    usedName: pathOr(
      null,
      ['user', 'userAdditionalInfo', 'usedName'],
      PatientData,
    ),
    usedSurnames: pathOr(
      null,
      ['user', 'userAdditionalInfo', 'usedSurnames'],
      PatientData,
    ),
    birthPlaceCode: pathOr(
      99999,
      ['user', 'userAdditionalInfo', 'birthPlaceCode'],
      PatientData,
    ),
    proofOfIdentity: pathOr(
      null,
      ['patientInsStatus', 'proofOfIdentity'],
      PatientData,
    ),
    insCode: pathOr(null, ['patientInsStatus', 'insCode'], PatientData),
    insStatus: pathOr(
      'provisional',
      ['patientInsStatus', 'insStatus'],
      PatientData,
    ),
    validatedStatusDate: pathOr(
      null,
      ['patientInsStatus', 'validatedStatusDate'],
      PatientData,
    ),
  };
};
