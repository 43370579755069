import {
  CheckDataSharingConsentActionType,
  CheckDataSharingErrorAction,
  CheckDataSharingStartAction,
  ClearCheckDataSharingStateAction,
} from './check-data-sharing-consent.types';
import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  ConsentSuccessPayload,
  ConsentErrorPayload,
} from 'src/services/patient/check-data-sharing-consent/check-data-sharing-consent.types';
import {
  createRequestActions,
  createRequestActionTypes,
} from 'src/core/request/request.actions';

const FETCH_DATA_SHARING_CONSENT = createRequestActionTypes(
  CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT,
);
export const fetchDataSharingConsent = createRequestActions(
  FETCH_DATA_SHARING_CONSENT,
);

export const checkDataSharingConsentStart = (
  payload: string,
): CheckDataSharingStartAction => {
  return createPayloadAction(
    CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_START,
    payload,
  );
};

export const checkDataSharingConsentSuccess = (
  payload: ConsentSuccessPayload,
): ConsentSuccessPayload => {
  return createPayloadAction(
    CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_SUCCESS,
    payload,
  );
};

export const checkDataSharingConsentError = (
  payload: ConsentErrorPayload,
): CheckDataSharingErrorAction =>
  createPayloadAction(
    CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_ERROR,
    payload,
  );

export const clearCheckDataSharingConsentState =
  (): ClearCheckDataSharingStateAction =>
    createAction(
      CheckDataSharingConsentActionType.CLEAR_CHECK_DATA_SHARING_STATE,
    );
