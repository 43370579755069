import { compose, withHandlers, withState } from 'recompose';

// Higher Order Component that wraps tool-tip functionality to any component
export const withToolTip = compose(
  withState('toolTip', 'toggleToolTip', { x: null, y: null, data: {} }),
  withHandlers({
    showToolTip:
      ({ toggleToolTip }) =>
      (event, data, toolTipWidth) => {
        const ySpacing = 5; // y spacing between cursor and corner of tool-tip
        const xSpacing = 8; // x spacing between cursor and corner of tool-tip
        const eventTriggerer = event.target && event.target.classList[0]; // in case that the tool-tip is rendered in the logbook-stats table
        const isNoDataToolTip = eventTriggerer === 'no-data-tool-tip-text';
        const toolTipY = isNoDataToolTip
          ? event.pageY + ySpacing - 150
          : event.pageY + ySpacing;

        let toolTipX;

        // if width of tooltip is provided - make sure to not have it cut off when displayed at the edge of screen
        if (toolTipWidth) {
          toolTipX =
            event.pageX + toolTipWidth > window.innerWidth ||
            (toolTipWidth && isNoDataToolTip)
              ? event.pageX - toolTipWidth - xSpacing
              : event.pageX + xSpacing;
        } else {
          toolTipX = isNoDataToolTip
            ? event.pageX + xSpacing - 130
            : event.pageX + xSpacing;
        }
        toggleToolTip({ x: toolTipX, y: toolTipY, data, toolTipWidth });
      },
    hideToolTip:
      ({ toggleToolTip }) =>
      () => {
        toggleToolTip({ x: null, y: null, data: {} });
      },
  }),
);
