import { push } from 'react-router-redux';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';

import { requestSequence } from 'src/core/request/request.epics';
import { clearPatientDateRange } from 'src/core/patient-date-range/patient-date-range.action';

import { PATIENT_ACTIONS, FETCH_PATIENT_REQUEST } from './patient.constant';
import { clearPatientState, fetchPatientRequest } from './patient.action';

export const patientChangeEpic = () => (action$, store) =>
  action$
    .ofType(PATIENT_ACTIONS.SWITCH_PATIENT)
    .flatMap((action) => [
      clearPatientState(),
      clearPatientDateRange(),
      fetchPatientRequest.start({ patientId: action.payload }),
    ]);

export const fetchCurrentPatientErrorEpic = () => (action$, store$) =>
  action$.ofType(FETCH_PATIENT_REQUEST.ERROR).map(() => {
    const routes = getCombinedRoutes(store$.getState());
    return push(routes.general.home);
  });

export const fetchPatientEpic = (fetchPatientService) =>
  requestSequence({
    service: fetchPatientService,
    actionTypes: FETCH_PATIENT_REQUEST,
  });
