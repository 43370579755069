import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { insulinPumpConnector } from 'src/domains/diagnostics/scenes/insulin-pump/store/insulin-pump.selector';

import { InsulinPump } from './insulin-pump.component';

export const InsulinPumpContainer = compose(
  connect(insulinPumpConnector, null),
  withTranslation,
)(InsulinPump);
