import { path, equals } from 'ramda';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  selectEC6UserUnitMeasurement,
  selectCarbsUserUnitMeasurement,
} from 'src/core/user/user.selectors';

import {
  selectDataSharingConsentResponse,
  selectDataSharingHasBeenCalled,
} from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.selector';
import {
  selectFhirPermission,
  selectPlatformPermission,
} from '../permissions/permissions.selectors';

export const UNIT_MMOLL = 'MMOLL';
export const UNIT_MGDL = 'MGDL';
export const GRAMS = 'GRAMS';
export const G10 = 'G10';
export const G12 = 'G12';
export const G15 = 'G15';
export const G20 = 'G20';

export const GET_CARB_VALUES = {
  [GRAMS]: GRAMS,
  [G10]: G10,
  [G12]: G12,
  [G15]: G15,
  [G20]: G20,
};

export const selectPatientStartDate = path([
  'ui',
  'patientDateRange',
  'startDate',
]);

export const selectPatientRange = path(['ui', 'patientDateRange', 'range']);

export const selectPatientEndDate = path(['ui', 'patientDateRange', 'endDate']);

export const selectPatientFirstMeasurementDate = path([
  'ui',
  'patientDateRange',
  'firstMeasurementDate',
]);

export const selectPatientLastMeasurementDate = path([
  'ui',
  'patientDateRange',
  'lastMeasurementDate',
]);

export const selectUnitMeasurementForService = createSelector(
  selectEC6UserUnitMeasurement,
  (bgUnit) => (equals(bgUnit, 'mmol/L') ? UNIT_MMOLL : UNIT_MGDL),
);

export const selectCarbUnitMeasurementForService = createSelector(
  selectCarbsUserUnitMeasurement,
  (carbUnit) => GET_CARB_VALUES[carbUnit] || GRAMS,
);

export const dateRangeConnector = createStructuredSelector({
  range: selectPatientRange,
  startDate: selectPatientStartDate,
  endDate: selectPatientEndDate,
  firstMeasurementDate: selectPatientFirstMeasurementDate,
  lastMeasurementDate: selectPatientLastMeasurementDate,
  dataSharingResponse: selectDataSharingConsentResponse,
  dataSharingCalled: selectDataSharingHasBeenCalled,
  hasUserFhirPermission: selectFhirPermission,
  hasUserPlatformPermission: selectPlatformPermission,
});
