export const patientNameValidators = {
  isNameLongEnough: (value = '') => {
    const searchValue = value.trim().length;
    return !searchValue || searchValue > 2;
  },
  charactersNotAllowed: (value = '') => {
    const searchValue = value.trim().length;
    const isSearchValueContainsSpecialCharacters =
      value.indexOf('[') > -1 || value.indexOf(']') > -1;
    return (
      !searchValue ||
      (searchValue > 2 && !isSearchValueContainsSpecialCharacters)
    );
  },
};

export const patientNameErrors = {
  isNameLongEnough: 'searchBar.patientNameIsTooShort',
  charactersNotAllowed: 'searchBar.charactersNotAllowed',
};
