import * as Cookies from 'cookies-js';
import { getConfig } from '@roche/roche-common';
import { configureStore } from 'src/store';
export const { persistor, store, history } = configureStore({});

export const isConsentActivated = () =>
  `${getConfig().REACT_APP_CONSENTS}` === 'true';

export const doesUserAcceptedCookies = () =>
  // tslint:disable-next-line
  Cookies.get('OptanonAlertBoxClosed') ? true : false;
