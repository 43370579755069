import { push } from 'react-router-redux';
import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { State, Action } from 'src/app/store/app.types';
import {
  oidcTokensService,
  oidcRefreshService,
} from 'src/app/session/services/oidc/oidc.service';
import {
  selectApiGateway,
  selectGigyaToken,
  selectRedirectUri,
} from '../config/config.selectors';
import { SessionActionType } from '../session/session.types';

import {
  fetchOidcTokensError,
  fetchOidcTokensSuccess,
  refreshOidcTokensError,
  refreshOidcTokensSuccess,
} from './oidc.actions';
import { selectRefreshToken } from './oidc.selectors';
import {
  OidcActionTypes,
  OidcTokensServiceImplType,
  OidcSuccessActions,
} from './oidc.types';
import {
  signoutStart,
  signoutAfterRefreshStart,
} from 'src/app/session/core/session/session.actions';
import { customDatadogLog } from 'src/app/logger/utils';
import { LOGS } from 'src/app/logger/constants';
/* istanbul ignore next */ export const getOidcTokensEpic: (
  oidcTokensService: OidcTokensServiceImplType,
) => Epic<OidcSuccessActions, State> = (tokensService) => (action$) =>
  action$
    .ofType(OidcActionTypes.OIDC_FETCH_TOKENS_START)
    .switchMap(({ payload }) => {
      const { token, gigyaToken, apiGateway, redirectUri } = payload;
      return Observable.fromPromise(
        tokensService({
          token,
          gigyaToken,
          apiGateway,
          redirectUri,
        }),
      )
        .switchMap((res) => [fetchOidcTokensSuccess(res)])
        .pipe(
          catchError((error) => {
            customDatadogLog('session', LOGS.tokenError, {
              ...error,
            });
            return Observable.of(fetchOidcTokensError(error));
          }),
        );
    });

/* istanbul ignore next */ const getProps = (store) => {
  return {
    token: selectRefreshToken(store.getState()),
    gigyaToken: selectGigyaToken(store.getState()),
    apiGateway: selectApiGateway(store.getState()),
    redirectUri: selectRedirectUri(store.getState()),
  };
};

export const oidcTokensSuccessEpic: () => Epic<FixMe, State> =
  () => (actions$) =>
    actions$
      .ofType(SessionActionType.LOGIN_SUCCESS)
      .switchMap(({ payload }) => [push(payload)]);

/* istanbul ignore next */ export const refreshTokensEpic: (
  refreshTokensService: OidcTokensServiceImplType,
) => Epic<FixMe, State> = (refreshTokensService) => (actions$, store$) =>
  actions$.ofType(OidcActionTypes.REFRESH_OIDC_TOKENS_START).switchMap(() => {
    return Observable.fromPromise(
      [undefined, null, ''].includes(getProps(store$).token)
        ? Promise.resolve()
        : refreshTokensService({
            ...getProps(store$),
          }),
    )
      .switchMap((res) => {
        return [refreshOidcTokensSuccess(res)];
      })
      .pipe(
        catchError((error) => {
          customDatadogLog('session', LOGS.tokenRefreshmentError, {
            ...error,
          });
          return Observable.of([refreshOidcTokensError(error), signoutStart()]);
        }),
      );
  });

/* istanbul ignore next */ export const refreshTokensSignOutEpic: (
  refreshTokensService: OidcTokensServiceImplType,
  // @ts-ignore
) => Epic<Action, State> = (refreshTokensService) => (actions$, store$) => {
  return actions$.ofType(SessionActionType.SIGN_OUT_START).switchMap(() =>
    Observable.fromPromise(
      [undefined, null, ''].includes(getProps(store$).token)
        ? Promise.reject({ ...getProps(store$) })
        : refreshTokensService({
            ...getProps(store$),
          }),
    )
      .switchMap((res) => [
        refreshOidcTokensSuccess(res),
        signoutAfterRefreshStart(),
      ])
      .pipe(
        catchError((error) => {
          customDatadogLog('session', LOGS.tokenRefreshmentError, {
            ...error,
            ...getProps(store$),
          });
          return [refreshOidcTokensError(error), signoutAfterRefreshStart()];
        }),
      ),
  );
};

/* istanbul ignore next */ export const oidcTtlRefreshTokensSuccessEpic: () => Epic<
  FixMe,
  State
> = () => (actions$) =>
  actions$.ofType(OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS).map(() => {
    // @ts-ignore
    window.store?.persistor?.persist();
    return Observable.empty();
  });

export const oidcEpics = combineEpics(
  getOidcTokensEpic(oidcTokensService({ devMode: false })),
  refreshTokensEpic(oidcRefreshService({ devMode: false })),
  refreshTokensSignOutEpic(oidcRefreshService({ devMode: false })),
  oidcTokensSuccessEpic(),
  oidcTtlRefreshTokensSuccessEpic(),
);
