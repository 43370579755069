import { filter, map, pipe, equals } from 'ramda';
import { Professional } from 'src/services/professionals/get-professionals/get-professionals.types';

import { PROFESSIONAL_PROFILES } from 'src/widgets/forms/form.constants';

import { FormControlOption } from 'src/widgets/patient/forms/forms.types';

import { CreatePatientContexts } from './create-patient.types';

export const convertAssociatedPractitionerProfessionalsToDropdownOptions = (
  professionals: Professional[],
): FormControlOption[] => {
  const filterPractitioners = filter(
    ({ user: { profile } }: Professional) =>
      profile === PROFESSIONAL_PROFILES.HCP_MASTER ||
      profile === PROFESSIONAL_PROFILES.GENERAL_PRACTITIONER,
  );
  const extractFields = map(
    ({ id, user: { surname, surname2, name } }: Professional) => ({
      label: `${surname}${surname2 ? ' ' + surname2 : ''}, ${name}`,
      value: id,
    }),
  );

  return pipe(filterPractitioners, extractFields)(professionals);
};

export const isContextUDTC = (context: string) =>
  equals(context, CreatePatientContexts.UDTC);

export const profileTypeConverter = (profileType: string) => {
  switch (profileType) {
    case 'STANDARD':
      return 'Basic';
    case 'STANDARD_WITH_HOME_DELIVERY':
      return 'Home Delivery';
    case 'STANDARD_WITH_STRIP_MANAGEMENT':
      return 'Pick up at center';
    default:
      return profileType;
  }
};

export const diabetesTypeConverter = (diabetesType: string): string => {
  switch (diabetesType) {
    case 'DIABETES_TYPE1':
      return 'Type 1';
    case 'DIABETES_TYPE2':
      return 'Type 2';
    case 'DIABETES_GESTATIONAL':
      return 'Gestational';
    case 'DIABETES_TYPE2_MDI':
      return 'Type 2 MDI';
    case 'PENDING_TO_IDENTIFY':
      return 'Pending diagnosis';
    default:
      return diabetesType;
  }
};

export const valuesCapitalizer = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

export type UpdateFormState = {
  name: string;
  value: boolean;
};
