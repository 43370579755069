export const ITEMS_BG = [
  'age',
  'gender',
  'diabetesType',
  'testFrequency',
  'hbA1c',
  'variability',
  'lastDeviceSync',
  'hcpIsAccessible',
  'rpmStatus',
  'patientStatus',
];

export const ITEMS_CGM = [
  'age',
  'gender',
  'diabetesType',
  'variability',
  'cgmActiveTime',
  'timeInRange',
  'timeBellowRange',
  'gmi',
  'hcpIsAccessible',
  'rpmStatus',
];

type Filters = {
  age: any[];
  cgmActiveTime: any[];
  checkInStatus: any[];
  diabetesType: any[];
  gender: any[];
  gmi: any[];
  hbA1c: any[];
  hcpIsAccessible: any[];
  lastDeviceSync: any[];
  patientStatus: any[];
  rpmStatus: any[];
  testFrequency: any[];
  timeBellowRange: any[];
  timeInRange: any[];
  variability: any[];
};

export type FiltersType = Partial<Filters>;
