import {
  GetHba1cListLoaderImplType,
  GetHba1cListTransformImplType,
  GetHba1cListResponseType,
  GetHba1cListServiceImplType,
} from './hba1c-list.types';

import { getJSON, createAuthHeader } from '../../utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const GetHba1cListLoaderImpl: GetHba1cListLoaderImplType = (
  accessToken: string,
  gigyaToken: string,
  fhirID: string,
) => {
  const stamp = new Date().getTime();
  return getJSON(endpointWithParams(ENDPOINTS.hba1cList, { fhirID, stamp }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });
};

export const GetHba1cListTransformImpl: GetHba1cListTransformImplType = (
  data: GetHba1cListResponseType,
) => {
  return data.model.glycateds.map(({ id, fhirId, hba1cDate, hba1cValue }) => ({
    id,
    fhirId,
    hba1cValue,
    hba1cDate,
  }));
};

export const GetHba1cListServiceImpl: GetHba1cListServiceImplType =
  (
    load: GetHba1cListLoaderImplType,
    transform: GetHba1cListTransformImplType,
  ) =>
  (accessToken, gigyaToken, fhirID) =>
    load(accessToken, gigyaToken, fhirID).then(transform);
