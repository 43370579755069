export enum NewEntryHba1cActionType {
  SAVE_NEW_ENTRY_HBA1C_START = 'SAVE_NEW_ENTRY_HBA1C_START',
  SAVE_NEW_ENTRY_HBA1C_SUCCESS = 'SAVE_NEW_ENTRY_HBA1C_SUCCESS',
  SAVE_NEW_ENTRY_HBA1C_ERROR = 'SAVE_NEW_ENTRY_HBA1C_ERROR',
}

export type NewEntryHba1cActions = FixMe;

export type NewEntryHba1cState = {
  fhirId: string | null;
  isSavingNewEntryHba1c: boolean;
  isSavingNewEntryHba1cError: boolean;
  NewEntryHba1cSaved: boolean;
};
