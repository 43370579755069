import styled from 'styled-components';
import { fontSize } from 'src/core/styles/font-sizes';

import { colors } from 'src/core/styles/colors';

export const IconsViewComponentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconsViewComponentSections = styled.div`
  color: ${colors.black};
  font-family: Nunito;
  font-size: ${fontSize.headline};
  line-height: 24px;
`;

export const IconsSections = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;
