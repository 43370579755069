import { ALERT_ACTIONS } from './alert.actions';

export const INITIAL_ALERT_STATE = {
  activeAlerts: [],
};

export const alertReducer = (state = INITIAL_ALERT_STATE, action) => {
  switch (action.type) {
    case ALERT_ACTIONS.ADD_ALERT:
      return {
        ...state,
        activeAlerts: [action.payload],
      };
    case ALERT_ACTIONS.REMOVE_ALERT:
      const currentAlerts = state.activeAlerts;
      return {
        ...state,
        activeAlerts: [...currentAlerts.slice(0, currentAlerts.length - 1)],
      };
    default:
      return {
        ...state,
      };
  }
};
