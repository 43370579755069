import { addDomainNamespace } from 'src/utils/domain-namespace';

import { createRequestActionTypes } from '../request/request.actions';

export const clinicGuideTranslationKeyBase = 'prescription.clinicGuide.';
export const periodTranslationKeyBase = 'prescription.period.';

const DOMAIN_NAMESPACE = 'PATIENT_PRESCRIPTION';
const addNamespacing = (actionName) =>
  addDomainNamespace(actionName, DOMAIN_NAMESPACE);

export const PRESCRIPTION_ACTIONS = {
  GET_PRESCRIPTION: addNamespacing('GET_PRESCRIPTION'),
  SET_PRESCRIPTION: addNamespacing('SET_PRESCRIPTION'),
  SET_ACTIVE_PRESCRIPTION_FORM: addNamespacing('SET_ACTIVE_PRESCRIPTION_FORM'),
  ADD_PRESCRIPTION_ENTRY: addNamespacing('ADD_PRESCRIPTION_ENTRY'),
  CREATE_PRESCRIPTION_ENTRY: addNamespacing('CREATE_PRESCRIPTION_ENTRY'),
  UPDATE_QUANTITIES_AND_PERIODS: addNamespacing(
    'UPDATE_QUANTITIES_AND_PERIODS',
  ),
  RESET_PRESCRIPTION: addNamespacing('RESET_PRESCRIPTION'),
  INITIALIZE_PRESCRIPTION: addNamespacing('INITIALIZE_PRESCRIPTION'),
  SET_PRESCRIPTION_CLINIC_GUIDE_TYPE: addNamespacing(
    'SET_PRESCRIPTION_CLINIC_GUIDE_TYPE',
  ),
  REMOVE_UNSAVED_PRESCRIPTION: addNamespacing('REMOVE_UNSAVED_PRESCRIPTION'),
  SHOW_CUSTOM_CLINIC_GUIDES_FORM: addNamespacing(
    'SHOW_CUSTOM_CLINIC_GUIDES_FORM',
  ),
  HIDE_CUSTOM_CLINIC_GUIDES_FORM: addNamespacing(
    'HIDE_CUSTOM_CLINIC_GUIDES_FORM',
  ),
  SET_CLINIC_GUIDE_FILTER: addNamespacing('SET_CLINIC_GUIDE_FILTER'),
  SET_GUIDE_HISTORY_FILTER: addNamespacing('SET_GUIDE_HISTORY_FILTER'),
  SET_GUIDE_HISTORY_PAGE: addNamespacing('SET_GUIDE_HISTORY_PAGE'),
};

export const PATIENT_PRESCRIPTION_TYPES = {
  TEMPORARY: 'temporary',
  PERMANENT: 'permanent',
};

export const UNSAVED_PRESCRIPTION_IDS = {
  TEMPORARY: 'new-unsaved-temporary-prescription',
  PERMANENT: 'new-unsaved-permanent-prescription',
};

export const FLATTENED_UNSAVED_PRESCRIPTION_IDS = [
  UNSAVED_PRESCRIPTION_IDS.PERMANENT,
  UNSAVED_PRESCRIPTION_IDS.TEMPORARY,
];

export const GET_CURRENT_PRESCRIPTION = addNamespacing(
  'GET_CURRENT_PRESCRIPTION',
);
export const GET_CURRENT_PRESCRIPTION_REQUEST = createRequestActionTypes(
  GET_CURRENT_PRESCRIPTION,
);

export const SAVE_PRESCRIPTION = addNamespacing('SAVE_PRESCRIPTION');
export const SAVE_PRESCRIPTION_REQUEST =
  createRequestActionTypes(SAVE_PRESCRIPTION);

export const FETCH_TEMPORARY_REASONS = addNamespacing(
  'FETCH_TEMPORARY_REASONS',
);
export const FETCH_TEMPORARY_REASONS_REQUEST = createRequestActionTypes(
  FETCH_TEMPORARY_REASONS,
);

export const TEMPORARY_PRESCRIPTION_ID = 'temporaryPrescription';

// User modifiable fields to reset on SET_ACTIVE_PRESCRIPTION_FORM
const USER_EDITABLE_FIELDS = [
  'clinicGuide',
  'frequency',
  'period',
  'quantity',
  'stripModel',
  'therapy',
];

export const HYDRATED_FORM_VALUES = [
  ...USER_EDITABLE_FIELDS,
  'id',
  'prescriptionType',
];

export const MAX_PRESCRIPTIONS = 2;

export const GUIDE_HISTORY_FILTERS = {
  ALL: 'ALL',
  PERSONAL: 'PERSONAL',
};

export const GUIDES_PER_HISTORY_PAGE = 5;

export const TEMPORARY_TESTS_LIST = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
  {
    label: '13',
    value: '13',
  },
  {
    label: '14',
    value: '14',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '16',
    value: '16',
  },
  {
    label: '17',
    value: '17',
  },
  {
    label: '18',
    value: '18',
  },
  {
    label: '19',
    value: '19',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '21',
    value: '21',
  },
  {
    label: '2',
    value: '22',
  },
  {
    label: '23',
    value: '23',
  },
  {
    label: '24',
    value: '24',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '26',
    value: '26',
  },
  {
    label: '27',
    value: '27',
  },
  {
    label: '28',
    value: '28',
  },
  {
    label: '29',
    value: '29',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '31',
    value: '31',
  },
  {
    label: '32',
    value: '32',
  },
  {
    label: '33',
    value: '33',
  },
  {
    label: '34',
    value: '34',
  },
  {
    label: '35',
    value: '35',
  },
  {
    label: '36',
    value: '36',
  },
  {
    label: '37',
    value: '37',
  },
  {
    label: '38',
    value: '38',
  },
  {
    label: '39',
    value: '39',
  },
  {
    label: '40',
    value: '40',
  },
  {
    label: '41',
    value: '41',
  },
  {
    label: '42',
    value: '42',
  },
  {
    label: '43',
    value: '43',
  },
  {
    label: '44',
    value: '44',
  },
  {
    label: '45',
    value: '45',
  },
  {
    label: '46',
    value: '46',
  },
  {
    label: '47',
    value: '47',
  },
  {
    label: '48',
    value: '48',
  },
  {
    label: '49',
    value: '49',
  },
  {
    label: '50',
    value: '50',
  },
];

export const PRESCRIPTION_COUNTLY_KEYS = {
  PRIMARY_TITLE: 'Primary Guideline Edited',
  PATIENT_THERAPY: 'Patient Therapy',
  PATIENT_CLINICAL_GUIDE: 'Clinical Guide',
  NUMBER_OF_TESTS: 'Number of Tests',
  TEST_FREQUENCY: 'Test Frequency',
  MODEL: 'Model',
  DELIVERY_FREQUENCY: 'Delivery frequency',
  TEMPORARY_TITLE: 'Temporary Guideline Added',
  REASON: 'Reason',
};
