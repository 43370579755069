import {
  PatientSessionExportLoaderImpl,
  PatientsSessionExportTransformImpl,
  PatientsSessionExportServiceImpl,
} from './patients-session-export.service';
// mockLoaderError,
import { mockLoaderSuccess } from './patients-session-export.mock';

export const PatientsSessionExportFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoaderSuccess : PatientSessionExportLoaderImpl;
  const transform = PatientsSessionExportTransformImpl;
  return PatientsSessionExportServiceImpl(loader, transform);
};
