import styled, { StyledComponentClass } from 'styled-components';
import { ThemeInterface } from 'src/theme';
import { convertPxToRemWithTheme } from '../dropdown-select.styles';

export const DisplayValueContainer: StyledComponentClass<
  {},
  ThemeInterface
> = styled<{}, 'div'>('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .display-value-date {
    margin-left: ${convertPxToRemWithTheme('16px')};
  }

  .display-value-report {
    margin-left: ${convertPxToRemWithTheme('32px')};
    color: ${(props) => props.theme.colors.brandBlue};
    font-size: ${(props) => props.theme.fontSize.caption};
  }
`;
DisplayValueContainer.displayName = 'DisplayValueContainer';

export const DisplayOptionContainer: StyledComponentClass<
  {},
  ThemeInterface
> = styled(DisplayValueContainer)`
  min-width: 330px;
`;
DisplayOptionContainer.displayName = 'DisplayOptionContainer';

export const DisplayValueDate: StyledComponentClass<
  {},
  ThemeInterface
> = styled<{}, 'div'>('div')`
  margin-left: ${convertPxToRemWithTheme('16px')};
`;
DisplayValueDate.displayName = 'DisplayValueDate';

export const DisplayValueReport: StyledComponentClass<
  {},
  ThemeInterface
> = styled<{}, 'div'>('div')`
  margin-left: ${convertPxToRemWithTheme('32px')};
  color: ${(props) => props.theme.colors.brandBlue};
  font-size: ${(props) => props.theme.fontSize.caption};
`;
DisplayValueReport.displayName = 'DisplayValueReport';

export const VerticalSeparator: StyledComponentClass<
  {},
  ThemeInterface
> = styled<{}, 'div'>('div')`
  height: 16px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grayLighter};
  margin-right: ${convertPxToRemWithTheme('8px')};
  margin-left: ${convertPxToRemWithTheme('8px')};
`;
VerticalSeparator.displayName = 'VerticalSeparator';
