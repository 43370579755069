import {
  StockAdjustmentsLoaderImpl,
  StockAdjustmentsServiceImpl,
  StockAdjustmentsTransformImpl,
} from './stock-adjustments.service';

import mock from './stock-adjustments.mock';

const mockLoader = (): Promise<any> => Promise.resolve(mock);

export const StockAdjustmentsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : StockAdjustmentsLoaderImpl;
  return StockAdjustmentsServiceImpl(loader, StockAdjustmentsTransformImpl);
};
