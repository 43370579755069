export const complexStyles = {
  textStyles: {
    caps: {
      textTransform: 'uppercase',
    },
    italic: {
      fontStyle: 'italic',
    },
  },
};
