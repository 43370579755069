import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import {
  INS_IDENTITY_GET_REQUEST,
  getINSIdentity,
} from './INS-Identity.actions';
import { createModal, destroyModal } from 'src/core/modal/modal.actions';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';

import { INSErrorModal } from '../../../modal/components/INS-request-modal/ins-request-error-modal';
import {
  insModalSubtitles,
  insModalTitles,
  insModalBtns,
} from 'src/widgets/modal/components/INS-request-modal/ins-request.modal.constants';
import { INSSuccessModal } from 'src/widgets/modal/components/INS-request-modal/ins-request-success-modal';

export const getINSIdentityEpic =
  (getINSIdentityService) => (actions$, state$) =>
    actions$.ofType(INS_IDENTITY_GET_REQUEST.START).flatMap(({ payload }) => {
      const openId = {
        accessToken: selectAccessToken(state$.getState()),
        apiKey: selectGigyaToken(state$.getState()),
      };
      return Observable.fromPromise(getINSIdentityService(openId, payload))
        .switchMap((INSIdentity) => {
          const callHasBeenCancelled =
            state$.getState().insIdentity.userHasCancelledRequest;
          const createOrRemoveModal = callHasBeenCancelled
            ? destroyModal()
            : createModal({
                key: MODAL_TYPES.CUSTOM,
                data: {
                  customComponent: INSSuccessModal,
                },
              });
          return [getINSIdentity.success(INSIdentity), createOrRemoveModal];
        })
        .pipe(
          catchError((err) => {
            const callHasBeenCancelled =
              state$.getState().insIdentity.userHasCancelledRequest;
            const createOrRemoveModal = callHasBeenCancelled
              ? destroyModal()
              : createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: INSErrorModal,
                    title: `${insModalTitles}.errorTitle`,
                    content: `${insModalSubtitles}.errorSubtitle`,
                    textPrimaryBtn: `${insModalBtns}.errorBack`,
                  },
                });
            return Observable.concat(
              Observable.of(getINSIdentity.error(err)),
              Observable.of(createOrRemoveModal),
            );
          }),
        );
    });
