import {
  getJSON,
  createAuthHeader,
} from '../../../utils/service/service.utils';
import { ENDPOINTS } from 'src/services/service.constants';

import {
  CheckDataSharingConsentLoaderImplType,
  CheckDataSharingConsentResponseType,
  CheckDataSharingConsentServiceImplType,
  CheckDataSharingConsentTransformImpType,
} from './check-data-sharing-consent.types';

export const CheckDataSharingConsentLoaderImpl: CheckDataSharingConsentLoaderImplType =
  (accessToken: string, apiKey: string, fhirId: string) => {
    return getJSON(ENDPOINTS.checkDataSharingConsent, {
      headers: {
        Authorization: createAuthHeader(accessToken),
        fhirId,
      },
    });
  };

export const CheckDataSharingConsentTransformImpl: CheckDataSharingConsentTransformImpType =
  (response: CheckDataSharingConsentResponseType) => response;

export const CheckDataSharingConsentServiceImpl: CheckDataSharingConsentServiceImplType =
  (load, transform) => (accessToken: string, apiKey: string, fhirId: string) =>
    load(accessToken, apiKey, fhirId).then(transform);
