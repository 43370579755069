import React from 'react';
import { withTheme } from 'styled-components';
import { withTranslation } from 'src/utils/i18n/with-translation';

import { GraphSvgIcon, SvgIconSpan } from 'src/components/icon/icon.component';

const PumpTbrEndDecIconComponent = ({
  theme,
  height,
  width,
  x,
  y,
  isGraphIcon = true,
  t,
  opacity,
  style,
  onMouseMove,
  onMouseOut,
  onClick,
}) => {
  const originalWidth = 19;
  const originalHeight = 19;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  const iconTitle = t('graphs.iconTitles.pumpTbrEndDec');

  const coreIcon = (
    <GraphSvgIcon
      title={iconTitle}
      width={calculatedWidth}
      height={calculatedHeight}
      {...{ x, y, originalWidth, originalHeight }}
      titleNotToBeRendered={onMouseMove && true}
    >
      <g
        fill="none"
        fillRule="evenodd"
        opacity={opacity}
        style={style}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        onClick={onClick}
      >
        <rect
          fill={theme.colors.tbrBlue}
          x="1.362"
          y="1.81"
          width="16"
          height="16"
          rx="1"
        />
        <path
          d="M12.505 7.12l-3.138 3.453L6.218 7.12a.858.858 0 0 0-1.208-.056.857.857 0 0 0-.056 1.21l4.415 4.842 4.4-4.844a.854.854 0 1 0-1.264-1.15z"
          fill={theme.colors.white}
        />
        <path
          d="M18.348 17L11.04 9.694l7.075-7.074A1.165 1.165 0 0 0 16.47.972L9.395 8.047 2.518 1.171A1.165 1.165 0 0 0 .87 2.817l6.877 6.876-7.11 7.11a1.165 1.165 0 0 0 1.647 1.646l7.11-7.11 7.307 7.308a1.162 1.162 0 0 0 1.646 0 1.165 1.165 0 0 0 0-1.646"
          fill={theme.colors.red}
        />
      </g>
    </GraphSvgIcon>
  );

  return !isGraphIcon ? SvgIconSpan(iconTitle, coreIcon) : coreIcon;
};

export const PumpTbrEndDecIcon = withTranslation(
  withTheme(PumpTbrEndDecIconComponent),
);
