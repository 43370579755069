import { Moment } from 'moment';

export type OpenId = {
  accessToken: string;
  gigyaToken: string;
};

export enum MysugrStatusValues {
  INACTIVE = 'inactive',
  PENDING = 'pending',
  ACTIVE = 'active',
  NOT_INITIATED = 'not_initiated',
}

export type MysugrResponse = {
  status: MysugrStatusValues;
  lastSync: null | number;
};

export type MysugrResponseTransformed = {
  status: MysugrStatusValues;
  lastSync: null | Moment;
};

export type MysugrStatusLoaderImplType = (
  openId: OpenId,
  patientGigyaUid: string,
) => Promise<any>;

export type MysugrStatusTransformImplType = (
  data: MysugrStatusResponse,
) => MysugrStatusResponseTransformed;

export type MysugrStatusServiceImplType = (
  load: MysugrStatusLoaderImplType,
  transform: MysugrStatusTransformImplType,
) => (
  openId: OpenId,
  patientGigyaUid: string,
) => Promise<MysugrStatusResponseTransformed>;

// Uncomment when status service is not returning a single string anymore
// export type MysugrStatusResponse = MysugrResponse;
// export type MysugrStatusResponseTransformed = MysugrResponseTransformed;

export type MysugrStatusResponse = FixMe;
export type MysugrStatusResponseTransformed = FixMe;
