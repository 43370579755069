import styled from 'styled-components';

import { StatusCardWrapper } from '../../widgets/status-card/status-card.style';

const mediumBreakpoint = '1550px';

export const CardDeviceSettingWrapper = styled(StatusCardWrapper)`
  @media (max-width: ${mediumBreakpoint}) {
    padding: 0;
  }
`;
