import { routes } from 'src/domains/routes';

type NavigationState = {
  routes: FixMe;
};

type NavigationReducer = (
  state: NavigationState,
  action: FixMe,
) => NavigationState;

export const INITIAL_STATE: NavigationState = {
  routes,
};

export const navigationReducer: NavigationReducer = (
  state = INITIAL_STATE,
  action,
) => {
  return state;
};
