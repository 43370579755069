import * as React from 'react';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';

export const PrintAndDownloadIcon = ({ isEnabled }) => (
  <jello-icon-button
    icon-name={JELLO_ICON_NAMES.PRINTER}
    size="L"
    variant="secondary"
    {...(!isEnabled && {
      disabled: '',
    })}
  />
);
