import { STATE_ACTIONS } from './state.constants';

export const clearStripDelivery = () => ({
  type: STATE_ACTIONS.CLEAR_STRIP_DELIVERY,
  payload: true,
});

export const clearPrescriptions = () => ({
  type: STATE_ACTIONS.CLEAR_PRESCRIPTIONS,
  payload: true,
});

export const clearPatientDashboard = () => ({
  type: STATE_ACTIONS.CLEAR_PATIENT_DASHBOARD,
  payload: true,
});

export const clearPatientStock = () => ({
  type: STATE_ACTIONS.CLEAR_PATIENT_STOCK,
  payload: true,
});

export const clearAlerts = () => ({
  type: STATE_ACTIONS.CLEAR_ALERTS,
  payload: true,
});

export const clearPatientSearchResults = () => ({
  type: STATE_ACTIONS.CLEAR_PATIENT_SEARCH_RESULTS,
  payload: true,
});

export const clearOrgStock = () => ({
  type: STATE_ACTIONS.CLEAR_ORG_STOCK,
  payload: true,
});

export const clearOrganizationState = () => ({
  type: STATE_ACTIONS.CLEAR_ORGANIZATION_STATE,
});

export const clearPermissions = () => ({
  type: STATE_ACTIONS.CLEAR_PERMISSIONS,
});

export const clearPatientPermissions = () => ({
  type: STATE_ACTIONS.CLEAR_PATIENT_PERMISSIONS,
});

export const clearCreatePatient = () => ({
  type: STATE_ACTIONS.CLEAR_CREATE_PATIENT,
});

export const clearEditPatient = () => ({
  type: STATE_ACTIONS.CLEAR_EDIT_PATIENT,
});

export const clearCreateProfessional = () => ({
  type: STATE_ACTIONS.CLEAR_CREATE_PROFESSIONAL,
});

export const clearEditProfessional = () => ({
  type: STATE_ACTIONS.PROFESSIONAL,
});

export const addOpenConnectivity = () => ({
  type: STATE_ACTIONS.ADD_OPEN_CONNECTIVITY,
});

export const clearPairingCodeIdentity = () => ({
  type: STATE_ACTIONS.CLEAR_PAIRING_CODE_IDENTITY,
});
