import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { SearchPatient } from '../../domains/device-assignment/scenes/select-patient/select-patient.types';
import { convertISO } from '../../utils/date';

import {
  GetLatestPatientsEC6Response,
  GetLatestPatientsServiceFactoryType,
  GetLatestPatientsServiceType,
  GetLatestPatientsTransform,
} from './get-latest-patients.types';

import { ENDPOINTS } from 'src/services/service.constants';

export const GetLatestPatientsLoaderImpl: GetLatestPatientsServiceType = (
  accessToken: string,
) =>
  getJSON(ENDPOINTS.getLatestPatients, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const GetLatestPatientsTransformImpl: GetLatestPatientsTransform = (
  data,
) => data.model;

export const GetLatestPatientsSimpleTransformImpl: (
  data: GetLatestPatientsEC6Response,
) => SearchPatient[] = (data) =>
  data.model.map((row) => {
    const birthDateString = row.user.birthday
      ? `${convertISO(row.user.birthday).toFormat('dd/MM/yyyy')}`
      : '';

    return {
      id: row.id,
      fullName: row.user.fullname,
      healthCareSystemId: row.healthCareSystemId || '',
      birthDate: birthDateString,
    };
  });

export const GetLatestPatientsServiceFactoryImpl: GetLatestPatientsServiceFactoryType =
  (load, transform) => (accessToken, gigyaToken, userId) =>
    load(accessToken, gigyaToken, userId).then(transform);
