// tslint:disable:max-union-size
import * as React from 'react';
import { StyledText } from './text.style';
import { ColorsType } from '../colors';

export interface BaseProps {
  dataTestId?: string;
  children?: React.ReactNode;
}

interface Props {
  color?: ColorsType;
  size?:
    | 'small'
    | 'regular'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | string;
  weight?:
    | 'light'
    | 'semiLight'
    | 'regular'
    | 'semiBold'
    | 'bold'
    | 'extraBold'
    | 'ultraBold'
    | string;
}

export type TextProps = Props & BaseProps;

export const Text: React.FunctionComponent<TextProps> = ({
  color,
  size,
  ...rest
}: TextProps) => (
  <StyledText
    data-testid={rest.dataTestId}
    colorProp={color}
    sizeProp={size}
    {...rest}
  />
);
