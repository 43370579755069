import {
  ListDevicesLoaderImpl,
  ListDevicesServiceImpl,
  ListDevicesTransformImpl,
} from './list-devices.service';
import { mockListDevices } from './list-devices.mock';

const mockLoader = (): Promise<any> => Promise.resolve(mockListDevices);

export const ListDevicesFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : ListDevicesLoaderImpl;
  return ListDevicesServiceImpl(loader, ListDevicesTransformImpl);
};
