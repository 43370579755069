import {
  CheckExternalPatientLoaderImpl,
  CheckExternalPatientServiceImpl,
  CheckExternalPatientTransformImpl,
} from './check-external-patient.service';
import { mockCheckExternalPatientResponse } from './check-external-patient.mock';

const mockLoader = () => Promise.resolve(mockCheckExternalPatientResponse);

export const CheckExternalPatientFactoryImpl = ({ devMode }) => {
  const load = devMode ? mockLoader : CheckExternalPatientLoaderImpl;
  const transform = CheckExternalPatientTransformImpl;
  return CheckExternalPatientServiceImpl(load, transform);
};
