import {
  CheckEmailLoaderImpl,
  CheckEmailServiceImpl,
  CheckEmailTransformImpl,
} from './check-email.service';

import { CheckEmailResponse } from './check-email.types';

const mockLoader = (): Promise<CheckEmailResponse> =>
  Promise.resolve({ resultDescription: '', model: true });

export const CheckEmailFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : CheckEmailLoaderImpl;
  return CheckEmailServiceImpl(loader, CheckEmailTransformImpl);
};
