import * as C from './calendar.constants';

export const noMeasurements = (
  firstMeasurementDate: string,
  lastMeasurementDate: string,
  day: moment.Moment,
) => {
  const moment = require('moment');
  return !day
    .startOf(C.DAY)
    .isBetween(
      moment(firstMeasurementDate.split('T')[0])
        .startOf(C.DAY)
        .subtract(2, C.HOURS),
      moment(lastMeasurementDate.split('T')[0]).endOf(C.DAY),
    );
};

export const getDateFormat = (width: number | undefined) =>
  !!width && width < C.FORMAT_BREACKPOINT
    ? C.DATE_FORMAT_SHORT
    : C.DATE_FORMAT_LONG;

export const getClassName = (width: number | undefined) =>
  !!width && width < C.FORMAT_BREACKPOINT ? C.PICKER_SMALL : C.PICKER_LARGE;
