/* tslint:disable */

import styled from 'styled-components';
import { TableHeader } from 'src/domains/diagnostics/components/table-header/table-header.component';

export const TablePrimaryHeader = styled(TableHeader)`
  // color: red
`;

TablePrimaryHeader.displayName = 'TablePrimaryHeaderDiv';
