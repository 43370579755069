import { createSelector } from 'reselect';

import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';
import {
  selectNumberOfTests,
  selectTestsPerDay,
  selectTestsPerMeasuredDay,
  selectMeanBG,
  selectMeanBGBeforeMeal,
  selectMeanBGAfterMeal,
  selectStandardDeviation,
  selectBGIndexes,
} from 'src/domains/diagnostics/store/selectors/statistics.selector';
import { selectBloodGlucoseUnit } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { roundBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

export const selectBGStats = createSelector(
  selectBloodGlucoseUnit,
  selectMeanBG,
  selectMeanBGBeforeMeal,
  selectMeanBGAfterMeal,
  selectStandardDeviation,
  (unit, mean, meanBeforeMeal, meanAfterMeal, stdDev) => ({
    bloodGlucoseUnit: unit,
    mean: roundBGMeasurement(unit)(mean),
    meanBeforeMeal: roundBGMeasurement(unit)(meanBeforeMeal),
    meanAfterMeal: roundBGMeasurement(unit)(meanAfterMeal),
    stdDev: roundBGMeasurement(unit)(stdDev),
    stdDevMeanRatio:
      stdDev === EMPTY_VALUE_PLACEHOLDER
        ? stdDev
        : Math.round((100 * stdDev) / mean),
  }),
);

export const selectGraphStatistics = createSelector(
  selectNumberOfTests,
  selectTestsPerDay,
  selectTestsPerMeasuredDay,
  selectBGStats,
  selectBGIndexes,
  (numberOfTests, testsPerDay, testsPerMeasuredDay, bgStats, indexes) => ({
    tests: {
      numberOfTests,
      testsPerDay,
      testsPerMeasuredDay,
    },
    bloodGlucose: bgStats,
    indexes,
  }),
);
