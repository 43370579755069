import {
  NextShipmentChangeStatusLoaderImpl,
  NextShipmentChangeStatusServiceImpl,
  NextShipmentChangeStatusTransformImpl,
} from './next-shipment-change-status.service';
import { mockNextShipmentChangeStatusData } from './next-shipment-change-status.mock';

const mockLoader = (q) => Promise.resolve(mockNextShipmentChangeStatusData);

export const NextShipmentChangeStatusFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : NextShipmentChangeStatusLoaderImpl;
  return NextShipmentChangeStatusServiceImpl(
    loader,
    NextShipmentChangeStatusTransformImpl,
  );
};
