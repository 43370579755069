import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  InvitePatientActionType,
  InvitePatientStartAction,
  InvitePatientSuccessPayload,
} from './invite-patient.types';
// import { editPatientWithFhirStart } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/edit-patient-with-fhir';
import { InvitePatientLoaderImplType } from '../../../../../../services/rdac/invite-patient/invite-patient.types';
import {
  invitePatientError,
  invitePatientSuccess,
} from './invite-patient.actions';

export const invitePatientEpic: (
  invitePatientService: InvitePatientLoaderImplType,
) => Epic<any, any> = (invitePatientService) => (action$, store$) =>
  action$
    .ofType(InvitePatientActionType.INVITE_PATIENT_START)
    .flatMap(({ payload }: InvitePatientStartAction) => {
      const accessToken = selectAccessToken(store$.getState());
      const gigyaToken = selectGigyaToken(store$.getState());
      return (
        Observable.fromPromise(
          invitePatientService(
            accessToken,
            gigyaToken,
            payload.patientID,
            payload.email,
          ),
        ) as any
      )
        .switchMap((response: InvitePatientSuccessPayload) => {
          return [
            invitePatientSuccess(response),
            // TODO check if something needs to be done at this step
            /*             editPatientWithFhirStart({
              patientId: payload.patientId,
              patientDto: payload.patientDto,
            }), */
          ];
        })
        .pipe(catchError((err) => Observable.of(invitePatientError(err))));
    });
