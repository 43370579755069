import * as React from 'react';
import { testId } from '@roche/roche-common';

type JelloButtonProps = {
  iconName: string;
  size?: string;
  variant?: string;
  customClass?: string;
  slot?: string;
  accessibilityText?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const JelloIconButton: React.FunctionComponent<JelloButtonProps> = (
  props,
) => {
  const {
    iconName,
    customClass = [],
    slot,
    size,
    variant,
    accessibilityText,
    disabled,
    onClick,
  } = props;

  return (
    <jello-icon-button
      disabled={disabled || null}
      icon-name={iconName}
      size={size}
      variant={variant}
      accessibility-text={accessibilityText}
      class={customClass}
      slot={slot}
      onClick={onClick}
      {...testId('jello-icon-button', iconName)}
    />
  );
};
