import * as React from 'react';

import { RenderIf } from 'src/utils/render-if';
import { CrossMarkIcon } from 'src/assets/icons';
import { theme } from 'src/theme';

import {
  InputTextField,
  FormControlWrapper,
  FormControlInput,
  SearchFormControlInput,
  FormControlIconOnTheRight,
} from './input.style';

export const Input: FixMe = ({ id, model, type, ...props }) => (
  <InputTextField id={`Input--${id}`} model={model} type={type} {...props} />
);

export const InputText: FixMe = (props) => <Input type="text" {...props} />;

export const InputEmail: FixMe = (props) => <Input type="email" {...props} />;

export const InputPassword: FixMe = (props) => (
  <Input type="password" {...props} />
);

export const FormControl = ({ valid, focus, wrapperProps, ...props }) => (
  <FormControlWrapper {...wrapperProps}>
    <FormControlInput valid={valid} {...props} />
    <RenderIf validate={!focus && valid === false}>
      <FormControlIconOnTheRight
        children={<CrossMarkIcon fillColor={theme.colors.red} />}
      />
    </RenderIf>
  </FormControlWrapper>
);
FormControl.displayName = 'FormControl';

export const SearchFormControl = ({ valid, focus, wrapperProps, ...props }) => (
  <FormControlWrapper mt={2} {...wrapperProps}>
    <SearchFormControlInput valid={valid} {...props} />
    <RenderIf validate={!focus && valid === false}>
      <FormControlIconOnTheRight
        children={<CrossMarkIcon fillColor={theme.colors.red} />}
      />
    </RenderIf>
  </FormControlWrapper>
);
FormControl.displayName = 'FormControl';
