import { addDomainNamespace } from 'src/utils/domain-namespace';

import { createRequestActionTypes } from '../request/request.actions';

const DOMAIN_NAMESPACE = 'STRIP_MANAGEMENT_ALERTS';
const addNamespacing = (actionName) =>
  addDomainNamespace(actionName, DOMAIN_NAMESPACE);

export const ALERTS_THRESHOLD_LIMITS = {
  LIMIT_MAX: 99,
  LIMIT_MIN: 0,
};
export const DEFAULT_THRESHOLD_LIMIT = ALERTS_THRESHOLD_LIMITS.LIMIT_MIN;

export const GET_ALERTS = addNamespacing('GET_ALERTS');
export const GET_ALERTS_REQUEST = createRequestActionTypes(GET_ALERTS);

export const SAVE_ALERTS = addNamespacing('SAVE_ALERTS');
export const SAVE_ALERTS_REQUEST = createRequestActionTypes(SAVE_ALERTS);

export const ALERTS_MODEL_PATH = 'alerts';

export const ALERTS_IDS = {
  HYPO: 'hypoglycemiaAlert',
  LOWER: 'lowerAlert',
  HYPER: 'hyperglycemiaAlert',
};

export const INITIAL_ALERT_SETTINGS_STATE = {
  [ALERTS_IDS.HYPO]: {
    active: false,
    thresholdLimit: DEFAULT_THRESHOLD_LIMIT,
  },
  [ALERTS_IDS.LOWER]: {
    active: false,
    thresholdLimit: DEFAULT_THRESHOLD_LIMIT,
  },
  [ALERTS_IDS.HYPER]: {
    active: false,
    thresholdLimit: DEFAULT_THRESHOLD_LIMIT,
  },
};

// Countly constants
export const EVENTS_KEYS = {
  ALERTS_EDITED: 'Alerts Edited',
};

export const SEGMENT_KEYS = {
  HYPO_ALERTS_MODIFIED: 'Hypo Alerts Modified',
  HYPER_ALERTS_MODIFIED: 'Hyper Alerts Modified',
  LOWER_LIMITS_ALERTS_MODIFIED: 'Lower Limit Alerts Modified',
};
