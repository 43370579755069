import React from 'react';

import { Block, CursorBlock } from 'src/components/block/block.component';
import { Button } from 'src/components/button/button.component';
import { LocalizedText } from 'src/components/localized-text/localized-text.component';
import { XIcon, DeviceDownloadIcon, SystemTrayIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';
import { RenderIf } from 'src/utils/render-if';

import { generateDTCDownloadURL } from './dtc.utils';
import {
  ContainerDiv,
  DTCCaptionline,
  DTCHeadline,
  DTCHelpAnchor,
  DTCSubheadline,
  DTCSubheadlineBottom,
  DTCHr,
  DTCIconPanel,
  HelpAnchorContainer,
  GlobalDtcStyle,
} from './dtc.style';

import { ButtonContainerDiv, ModalBody, CommonHeader } from '../../modal.style';

const url = generateDTCDownloadURL();

export const DTCModalComponent = ({ destroyModal, t }) => (
  <React.Fragment>
    <GlobalDtcStyle />
    <ModalBody>
      <CommonHeader>
        <strong>
          <LocalizedText fontSize={4} textKey={'dtcModal.headerMessage'} />
        </strong>
        <CursorBlock onClick={destroyModal}>
          <span>
            <XIcon height={14} fillColor={colors.white} />
          </span>
        </CursorBlock>
      </CommonHeader>
      <ContainerDiv>
        <Block textAlign="center">
          <DTCHeadline>
            <LocalizedText textKey="dtcModal.title" />
          </DTCHeadline>
          <DTCSubheadline>
            <LocalizedText textKey="deviceLink.descriptionOne" />
          </DTCSubheadline>
          <ButtonContainerDiv>
            <RenderIf validate={true}>
              <a href={url} target="_blank" rel="noopener noreferrer" download>
                <Button label={t('dtcModal.download')} minWidth="7.75rem" />
              </a>
            </RenderIf>
          </ButtonContainerDiv>
          <Block mb={3} mt={4}>
            <DTCIconPanel>
              <DTCSubheadline>
                <LocalizedText textKey="deviceLink.descriptionTwo" />
              </DTCSubheadline>
              <DeviceDownloadIcon height={75} />
            </DTCIconPanel>
          </Block>
          <Block my={3}>
            <DTCIconPanel>
              <DTCSubheadline>
                <LocalizedText textKey="deviceLink.descriptionThree" />
              </DTCSubheadline>
              <SystemTrayIcon height={56} />
              <DTCSubheadlineBottom>
                <LocalizedText textKey="deviceLink.descriptionFour" />
              </DTCSubheadlineBottom>
            </DTCIconPanel>
          </Block>
          <DTCHr />
          <ButtonContainerDiv>
            <Button
              label={t('deviceLink.confirmation')}
              onClick={destroyModal}
            />
          </ButtonContainerDiv>
          <HelpAnchorContainer>
            <DTCCaptionline>
              <LocalizedText textKey="deviceLink.descriptionFive" />
              <DTCHelpAnchor>
                <LocalizedText textKey="deviceLink.descriptionSix" />
              </DTCHelpAnchor>
            </DTCCaptionline>
          </HelpAnchorContainer>
        </Block>
      </ContainerDiv>
    </ModalBody>
  </React.Fragment>
);
