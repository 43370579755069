import { actions } from 'react-redux-form';
import { CREATE_PATIENT_FORM_DATA_MODEL } from 'src/domains/patient/scenes/create-patient/create-patient.constants';

import { PATIENT_ACTIONS } from 'src/core/patient/patient.constant';
import { clearPatientDateRange } from 'src/core/patient-date-range/patient-date-range.action';

import {
  clearPrescriptions,
  clearStripDelivery,
  clearPatientStock,
  clearPatientDashboard,
  clearAlerts,
  clearPatientSearchResults,
  clearOrgStock,
  clearPatientPermissions,
  clearCreatePatient,
  clearEditPatient,
} from './state.actions';
import { STATE_ACTIONS } from './state.constants';

export const clearPatientStateEpic = (action$, store) =>
  action$
    .ofType(PATIENT_ACTIONS.CLEAR_PATIENT_STATE)
    .filter(
      ({ payload: { clearRelatedPatientData } }) => clearRelatedPatientData,
    ) // only emit values if payload is true, default payload is true
    .flatMap(() => [
      clearStripDelivery(),
      clearPrescriptions(),
      clearPatientDashboard(),
      clearPatientStock(),
      clearAlerts(),
      clearPatientSearchResults(),
      clearPatientPermissions(),
      clearCreatePatient(),
      clearEditPatient(),
      clearPatientDateRange(),
      actions.reset(CREATE_PATIENT_FORM_DATA_MODEL),
    ]);

export const clearOrganizationStateEpic = (action$, store) =>
  action$
    .ofType(STATE_ACTIONS.CLEAR_ORGANIZATION_STATE)
    .flatMap(() => [clearOrgStock()]);
