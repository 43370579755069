import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import * as React from 'react';
import { compose, lifecycle } from 'recompose';
import { JELLO_DIALOG_EVENTS } from 'src/components/jello-dialog/jello-dialog.constants';
import { colors } from 'src/core/styles/colors';
import { destroyModal } from 'src/core/modal/modal.actions';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { RangeDialogErrorWrapper } from 'src/widgets/modal/modal.style';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';

type SaveErrorModal = {
  t: TFunction;
  data: any;
};

const JELLO_DIALOG_SAVE_ERROR_ID = 'jello-dialog-save-error';

export const SaveErrorModalComponent: React.FunctionComponent<
  SaveErrorModal
> = ({ t, data }) => {
  const { title, content, textPrimaryBtn } = data;
  return (
    <React.Fragment>
      <jello-dialog
        id={JELLO_DIALOG_SAVE_ERROR_ID}
        visible=""
        primary-button-text={t(textPrimaryBtn)}
      >
        <RangeDialogErrorWrapper>
          <jello-icon
            className="jello-icon-demo__icon"
            size="M"
            icon-name={JELLO_ICON_NAMES.CLOSE_FILLED}
            style={{ color: colors.jelloColorFeedback07 }}
          />
          <jello-heading size="L" weight="bold">
            {t(title)}
          </jello-heading>
          <jello-text size="S" weight="regular">
            {t(content)}
          </jello-text>
        </RangeDialogErrorWrapper>
      </jello-dialog>
    </React.Fragment>
  );
};

export const SaveErrorModal = compose(
  connect(
    null,
    mapDispatchers({
      destroyModal,
    }),
  ),
  lifecycle<any, {}>({
    componentDidMount() {
      const modal = document.getElementById(JELLO_DIALOG_SAVE_ERROR_ID);
      if (modal) {
        modal.addEventListener(JELLO_DIALOG_EVENTS.primaryBtn, () =>
          this.props.destroyModal(),
        );
      }
    },
  }),
  withTranslation,
)(SaveErrorModalComponent);
