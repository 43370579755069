import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import { State } from 'src/app/store/app.types';
import { fetchHba1cCardStart } from 'src/domains/diagnostics/widgets/hba1c-card/store/hba1c-card.actions';
import { fetchHba1cListStart } from 'src/domains/diagnostics/widgets/hba1c-modal/components/list-values-hba1c/store/list-values-hba1c.actions';
import { HBA1C_EVENTS_KEYS } from 'src/domains/diagnostics/widgets/hba1c-modal/components/new-entry-hba1c/new-entry-hba1c.countly.constans';
import { countlyEventTrigger } from 'src/navigation/countly';
import { NewEntryHba1cInfo } from './../new-entry-hba1c.types';

import { NewEntryHba1cActionType } from './new-entry-hba1c.types';
import {
  saveNewEntryHba1cError,
  saveNewEntryHba1cSuccess,
} from './new-entry-hba1c.actions';

export const saveNewEntryHba1cEpic: (service: FixMe) => Epic<FixMe, State> = (
  service,
) => {
  return (action$, store) => {
    return action$
      .ofType(NewEntryHba1cActionType.SAVE_NEW_ENTRY_HBA1C_START)
      .switchMap(({ payload }: FixMe) => {
        const accessToken = selectAccessToken(store.getState());
        const apiKey = selectGigyaToken(store.getState());
        return Observable.fromPromise(
          service(accessToken, apiKey, payload.fhirId, payload.hba1cInfo),
        )
          .mergeMap((newEntryHba1cInfo) => {
            countlyEventTrigger(HBA1C_EVENTS_KEYS.HBA1C_VALUE_SAVED, {});
            return [
              saveNewEntryHba1cSuccess(newEntryHba1cInfo as NewEntryHba1cInfo),
              fetchHba1cCardStart(payload.fhirId),
              fetchHba1cListStart(payload.fhirId),
            ];
          })
          .pipe(
            // tslint:disable
            catchError(() => {
              return Observable.of(saveNewEntryHba1cError());
            }),
          );
      });
  };
};
