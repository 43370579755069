import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';

import {
  connectSharingCodeSuccess,
  connectSharingCodeError,
  checkSharingCodeAvailableSuccess,
  checkSharingCodeAvailableError,
  checkSharingCodeAvailableStart,
} from './connect-sharing-code.actions';

import {
  CheckSharingCodeAvailableResponse,
  ConnectSharingCodeActionType,
  ConnectSharingCodeStartAction,
  ConnectSharingCodeSuccessPayload,
  ConnectSharingCodeActions,
} from './connect-sharing-code.actions.types';
import { checkPairingCodeLoaderImplType } from 'src/services/pairing-code/pairing-code-check/pairing-code-check.types';

export const connectSharingCodeEpic: (
  checkPairingCodeService: checkPairingCodeLoaderImplType,
) => Epic<ConnectSharingCodeActions, State> =
  (checkPairingCodeService) => (action$, store$) =>
    action$
      .ofType(ConnectSharingCodeActionType.CONNECT_SHARING_CODE_START)
      .flatMap(({ payload }: ConnectSharingCodeStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        const apiKey = selectGigyaToken(store$.getState());
        return Observable.fromPromise(
          checkPairingCodeService(accessToken, apiKey, payload),
        )
          .switchMap((response: ConnectSharingCodeSuccessPayload) => [
            connectSharingCodeSuccess(response),
          ])
          .pipe(
            catchError((err) =>
              Observable.concat(
                Observable.of(connectSharingCodeError(err)),
                Observable.of(checkSharingCodeAvailableStart()),
              ),
            ),
          );
      });

export const checkIsSharingCodeAvailableEpic: (
  checkPairingCodeAvailabilityService,
) => Epic<ConnectSharingCodeActions, State> =
  (checkPairingCodeAvailabilityService) => (action$, store$) =>
    action$
      .ofType(ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_START)
      .flatMap(() => {
        const accessToken = selectAccessToken(store$.getState());
        const apiKey = selectGigyaToken(store$.getState());
        return Observable.fromPromise(
          checkPairingCodeAvailabilityService(accessToken, apiKey),
        )
          .switchMap((response: CheckSharingCodeAvailableResponse) => {
            return [checkSharingCodeAvailableSuccess(response)];
          })
          .pipe(
            catchError((err) =>
              Observable.of(checkSharingCodeAvailableError(err)),
            ),
          );
      });
