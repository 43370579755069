import { createPayloadAction } from 'src/app/store/app.actions';

import {
  EditEMRProfileErrorAction,
  EditEMRProfileErrorPayload,
  EditEMRProfileParams,
  EditEMRProfileStartAction,
  EditEMRProfileSuccessAction,
  EditEMRProfileSuccessPayload,
  EditProfileActionType,
  EditProfileErrorAction,
  EditProfileErrorPayload,
  EditProfileParams,
  EditProfileStartAction,
  EditProfileSuccessAction,
  EditProfileSuccessPayload,
} from './edit-profile.types';

export const editProfileStart = (
  payload: EditProfileParams,
): EditProfileStartAction =>
  createPayloadAction(EditProfileActionType.EDIT_PROFILE_START, payload);

export const editProfileSuccess = (
  payload: EditProfileSuccessPayload,
): EditProfileSuccessAction =>
  createPayloadAction(EditProfileActionType.EDIT_PROFILE_SUCCESS, payload);

export const editProfileError = (
  payload: EditProfileErrorPayload,
): EditProfileErrorAction =>
  createPayloadAction(EditProfileActionType.EDIT_PROFILE_ERROR, payload);

export const editEMRProfileStart = (
  payload: EditEMRProfileParams,
): EditEMRProfileStartAction =>
  createPayloadAction(EditProfileActionType.EDIT_EMR_PROFILE_START, payload);

export const editEMRProfileSuccess = (
  payload: EditEMRProfileSuccessPayload,
): EditEMRProfileSuccessAction =>
  createPayloadAction(EditProfileActionType.EDIT_EMR_PROFILE_SUCCESS, payload);

export const editEMRProfileError = (
  payload: EditEMRProfileErrorPayload,
): EditEMRProfileErrorAction =>
  createPayloadAction(EditProfileActionType.EDIT_EMR_PROFILE_ERROR, payload);
