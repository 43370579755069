import { createRequestActions } from 'src/core/request/request.actions';

import {
  FETCH_BG_OVERVIEW_END_DATE_REQUEST,
  FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST,
  FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST,
  GET_CLINICAL_DATA_REQUEST,
  GET_CLINICAL_DATA_FROM_FHIR_REQUEST,
  PATIENT_DASHBOARD_ACTIONS,
  FETCH_CHECK_EXTERNAL_PATIENT_REQUEST,
  SET_IS_EXTERNAL_PATIENT,
} from './bg.constants';

export const changeDeviceSettingsType = (deviceSettingsType) => ({
  type: PATIENT_DASHBOARD_ACTIONS.CHANGE_DEVICE_SETTINGS_TYPE,
  payload: deviceSettingsType,
});

export const changeGraph = (graph) => ({
  type: PATIENT_DASHBOARD_ACTIONS.CHANGE_GRAPH,
  payload: graph,
});

export const onBloodGlucoseOverviewEndDateChange = (endDate) => ({
  type: PATIENT_DASHBOARD_ACTIONS.SET_BLOOD_GLUCOSE_OVERVIEW_END_DATE,
  payload: { endDate },
});

export const changeGraphStartTime = (graphStartTime) => ({
  type: PATIENT_DASHBOARD_ACTIONS.CHANGE_GRAPH_START_TIME,
  payload: graphStartTime,
});

export const changeGraphType = (graphType) => ({
  type: PATIENT_DASHBOARD_ACTIONS.CHANGE_GRAPH_TYPE,
  payload: graphType,
});

export const changeLogbookType = (logbookType) => ({
  type: PATIENT_DASHBOARD_ACTIONS.CHANGE_LOGBOOK_TYPE,
  payload: logbookType,
});

export const getClinicalData = createRequestActions(GET_CLINICAL_DATA_REQUEST);

export const getClinicalDataFromFhir = createRequestActions(
  GET_CLINICAL_DATA_FROM_FHIR_REQUEST,
);

export const setClinicalData = (unit, measurements, insulin) => ({
  type: PATIENT_DASHBOARD_ACTIONS.SET_CLINICAL_DATA,
  payload: { unit, measurements, insulin },
});

export const toggleBloodGlucoseLines = () => ({
  type: PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_LINES,
});

export const toggleBloodGlucosePoints = () => ({
  type: PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_POINTS,
});

export const toggleBloodGlucoseBeforeMealPoints = () => ({
  type: PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_BEFORE_MEAL_POINTS,
});

export const toggleBloodGlucoseAfterMealPoints = () => ({
  type: PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_AFTER_MEAL_POINTS,
});
export const toggleCarbohydrates = () => ({
  type: PATIENT_DASHBOARD_ACTIONS.TOGGLE_CARBOHYDRATES,
});
export const toggleMeanBloodGlucose = () => ({
  type: PATIENT_DASHBOARD_ACTIONS.TOGGLE_MEAN_BLOOD_GLUCOSE,
});

export const fetchBGOverviewEndDate = createRequestActions(
  FETCH_BG_OVERVIEW_END_DATE_REQUEST,
);

export const fetchBGOverviewClinicalData = createRequestActions(
  FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST,
);

export const fetchBGOverviewClinicalDataFromFhir = createRequestActions(
  FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST,
);

export const fetchCheckExternalPatient = createRequestActions(
  FETCH_CHECK_EXTERNAL_PATIENT_REQUEST,
);

export const setIsExternalPatient = (isExternalPatient) => ({
  type: SET_IS_EXTERNAL_PATIENT,
  payload: { isExternalPatient },
});

export const hideBannerForDateRange = (startDate, endDate) => {
  const dateRangeKey = `${startDate}-${endDate}`;
  return {
    type: PATIENT_DASHBOARD_ACTIONS.HIDE_BANNER_FOR_DATE_RANGE,
    payload: { dateRangeKey },
  };
};

export const resetHideBannerForDateRange = () => ({
  type: PATIENT_DASHBOARD_ACTIONS.RESET_HIDE_BANNER_FOR_DATE_RANGE,
});
