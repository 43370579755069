import React from 'react';

import { colors } from 'src/core/styles/colors';
import {
  TbrIncreaseShape,
  TbrDecreaseShape,
  TbrEndIncreaseShape,
  TbrEndDecreaseShape,
} from 'src/domains/diagnostics/components/graph/point-shapes.component';
import { BLOOD_GLUCOSE_UNITS } from 'src/domains/diagnostics/store/constants';
import { convertPxToRem } from 'src/domains/diagnostics/utils/rem-calc';

export const GRAPH_ROOT = 'graph';
export const GRAPH_TREND = 'trend';
export const GRAPH_STANDARD_DAY = 'standard-day';
export const GRAPH_STANDARD_WEEK = 'standard-week';
export const GRAPH_METABOLIC_RATE = 'metabolic-rate';
export const GRAPH_INSULIN = 'insulin';
export const GRAPH_INSULIN_PUMP = 'insulin-pump';
export const GRAPH_LOGBOOK = 'logbook';
export const GRAPH_LOGBOOK_DIARY = 'logbook-diary';
export const GRAPH_LOGBOOK_STATS = 'logbook-stats';
export const GRAPH_BLOOD_GLUCOSE_GENERAL_STATS = 'blood-glucose-general-stats';
export const GRAPH_DEVICE_SETTINGS = 'device-settings';

export const GRAPH_TYPE_TREND = 'trend';
export const GRAPH_TYPE_DETAILS = 'details';
export const GRAPH_TYPE_METABOLIC = 'metabolic';

export const LOGBOOK_TYPE_DIARY = 'diary';
export const LOGBOOK_TYPE_STATS = 'statistics';
export const LOGBOOK_TYPE_DETAILS = 'details';
export const LOGBOOK_TYPE_24HOUR = '24hour';
export const LOGBOOK_TYPE_DETAILS_COUNTLY = 'logbook-details';

export const DEVICE_SETTINGS_TYPE_BASAL_BOLUS = 'device-settings-basal-bolus';
export const DEVICE_SETTINGS_TYPE_GENERAL = 'device-settings-general';
export const DEVICE_SETTINGS_TYPE_REMINDERS = 'device-settings-reminders';

export const GRAPHS = {
  ROOT: GRAPH_ROOT,
  TREND: GRAPH_TREND,
  STANDARD_DAY: GRAPH_STANDARD_DAY,
  STANDARD_WEEK: GRAPH_STANDARD_WEEK,
  METABOLIC_RATE: GRAPH_METABOLIC_RATE,
  INSULIN: GRAPH_INSULIN,
  INSULIN_PUMP: GRAPH_INSULIN_PUMP,
  LOGBOOK: GRAPH_LOGBOOK,
  LOGBOOK_DIARY: GRAPH_LOGBOOK_DIARY,
  LOGBOOK_STATS: GRAPH_LOGBOOK_STATS,
  LOGBOOK_24HR: LOGBOOK_TYPE_24HOUR,
  BLOOD_GLUCOSE_GENERAL_STATS: GRAPH_BLOOD_GLUCOSE_GENERAL_STATS,
  DEVICE_SETTINGS: GRAPH_DEVICE_SETTINGS,
};

export const GRAPHS_NO_TABS = [GRAPH_BLOOD_GLUCOSE_GENERAL_STATS];
export const GRAPH_DEFAULT_DASHBOARD = GRAPH_STANDARD_DAY;

export const GRAPH_CONTAINER_MIN_HEIGHT = '54rem';
export const GRAPH_MAX_HEIGHT = 550; // Safari graphs grow indefinitely, unless bounded by fixed height somewhere.

export const MIN_EXPANDED_STD_GRAPH_HEIGHT = 420;
export const MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM = convertPxToRem(
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
);
export const COLLAPSED_DETAIL_GRAPH_HEIGHT = 250;
// ^ COLLAPSED_DETAIL_GRAPH_HEIGHT can be deleted once standard-day-detail graph
// is convereted to use new library, as only COLLAPSED_STD_GRAPH_HEIGHT will be used
export const COLLAPSED_STD_GRAPH_HEIGHT = 234;
export const COLLAPSED_STD_GRAPH_HEIGHT_IN_REM = convertPxToRem(
  COLLAPSED_STD_GRAPH_HEIGHT,
);
export const COLLAPSED_STD_GRAPH_HEIGHT_GP = 366;
export const COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM = convertPxToRem(
  COLLAPSED_STD_GRAPH_HEIGHT_GP,
);

export const LOGBOOK_LOGBOOK_MIN_WIDTH = '88rem';

export const GRAPH_Y_MIN = 0;
export const GRAPH_Y_MAX_MG = 400;
export const GRAPH_Y_MAX_MMOL = 25;
export const GRAPH_Y_INTERVAL_MG = 50;
export const GRAPH_Y_INTERVAL_MMOL = 5;

export const BASAL_Y_MIN = 0;
export const CARBOHYDRATES_Y_MAX = 150;
export const INSULIN_Y_MAX = 20;

export const BOLUS_PADDING_THRESHOLD = 10;

export const X_AXIS_HEIGHT = 0.22;
export const Y_AXIS_WIDTH = 0.065;
export const SUB_PLOT_HEIGHT_MODIFIER = 0.375;

export const PLOT_WIDTH = 1 - Y_AXIS_WIDTH * 2;
export const PLOT_HEIGHT = 1 - X_AXIS_HEIGHT * 2;
export const SUB_PLOT_HEIGHT = PLOT_HEIGHT * SUB_PLOT_HEIGHT_MODIFIER;

export const Y_AXIS_TICK_VISIBILITY_TOLERANCE = {
  [BLOOD_GLUCOSE_UNITS.MG_PER_DL]: 14,
  [BLOOD_GLUCOSE_UNITS.MMOL_PER_L]: 0.8,
};

export const FULL_OPACITY = 1;
export const FADED_OPACITY = 0.1;

export const ARBITRARY_PROFILE_Y_VALUE = 0.65;
export const ARBITRARY_RATE_Y_VALUE = 0.75;

export const PRIMARY_TICK_COLOR = colors.silverDark;
export const HYPO_TICK_COLOR = colors.red;
export const TARGET_RANGE_TICK_COLOR = colors.green;

export const TARGET_RANGE_MAX_TICK_KEY = 'targetRangeMax';
export const TARGET_RANGE_MIN_TICK_KEY = 'targetRangeMin';
export const THRESHOLD_TICK_KEY = 'threshold';

export const GRAPH_AXES_FONT_MULTIPLIER = {
  COLLAPSED: 0.04,
  EXPANDED: 0.02,
};

export const BOLUS_TYPE_ICONS = {
  STANDARD: 'standard',
  QUICK: 'quick',
  EXTENDED: 'extended',
  MULTIWAVE: 'multiwave',
  EMPTY: 'empty', // to handle null bolusType
};

export const BOLUS_CONDITIONS = {
  ADVICE: 'ADVICE',
  MODIFIED: 'MODIFIED',
};

export const BASAL_RATE_PLUS_BOLUS = 'BASAL_RATE_PLUS_BOLUS';

export const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const GROUPED_TIME_INTERVALS = {
  BREAKFAST: 'BREAKFAST',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
  NIGHT: 'NIGHT',
  BEDTIME: 'BEDTIME',
};

export const DATA_TYPE = {
  CARBOHYDRATES: 'CARBOHYDRATES',
  BASAL: 'BASAL',
  GLUCOSE: 'GLUCOSE',
  MEAN_GLUCOSE: 'MEAN_GLUCOSE',
  INSULIN: 'INSULIN',
  PUMP_EVENT: 'PUMP_EVENT',
  PROFILE_CHANGE: 'PROFILE_CHANGE',
  BASAL_RATE_CHANGE: 'BASAL_RATE_CHANGE',
  TBR_EVENT: 'TBR_EVENT',
  SETBACK: 'SETBACK',
};

export const dataTypeToTranslationMap = {
  [DATA_TYPE.GLUCOSE]: 'graphs.detailGraph.toolTip.bloodGlucose',
  [DATA_TYPE.MEAN_GLUCOSE]: 'graphs.detailGraph.toolTip.meanBloodGlucose',
};

export const BG_UNIT_TO_AXIS_LABEL = {
  [BLOOD_GLUCOSE_UNITS.MG_PER_DL]:
    'graphDetails.legend.bloodGlucoseWithMgPerDL',
  [BLOOD_GLUCOSE_UNITS.MMOL_PER_L]:
    'graphDetails.legend.bloodGlucoseWithMmolPerL',
};

export const BG_UNIT_TO_UNIT_KEY = {
  [BLOOD_GLUCOSE_UNITS.MG_PER_DL]: 'graphDetails.mgPerDL',
  [BLOOD_GLUCOSE_UNITS.MMOL_PER_L]: 'graphDetails.mmolPerL',
};

export const TBR_TYPE = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
  END_INCREASE: 'END_INCREASE',
  END_DECREASE: 'END_DECREASE',
  END_INCREASE_CANCELLED: 'END_INCREASE_CANCELLED',
  END_DECREASE_CANCELLED: 'END_DECREASE_CANCELLED',
  NONE: 'NONE',
};

export const TBR_LABEL = {
  INCREASE: 'graphs.iconTitles.pumpTbrInc',
  DECREASE: 'graphs.iconTitles.pumpTbrDec',
  END_INCREASE: 'graphs.iconTitles.pumpTbrEnd',
  END_DECREASE: 'graphs.iconTitles.pumpTbrEnd',
  END_INCREASE_CANCELLED: 'graphs.iconTitles.pumpTbrEndCancelled',
  END_DECREASE_CANCELLED: 'graphs.iconTitles.pumpTbrEndCancelled',
};

export const tbrTypeContentMap = {
  [TBR_TYPE.INCREASE]: { Icon: TbrIncreaseShape, label: TBR_LABEL.INCREASE },
  [TBR_TYPE.DECREASE]: { Icon: TbrDecreaseShape, label: TBR_LABEL.DECREASE },
  [TBR_TYPE.END_INCREASE]: {
    Icon: TbrEndIncreaseShape,
    label: TBR_LABEL.END_INCREASE,
  },
  [TBR_TYPE.END_DECREASE]: {
    Icon: TbrEndDecreaseShape,
    label: TBR_LABEL.END_DECREASE,
  },
  [TBR_TYPE.END_INCREASE_CANCELLED]: {
    Icon: TbrEndIncreaseShape,
    label: TBR_LABEL.END_INCREASE_CANCELLED,
  },
  [TBR_TYPE.END_DECREASE_CANCELLED]: {
    Icon: TbrEndDecreaseShape,
    label: TBR_LABEL.END_DECREASE_CANCELLED,
  },
  [TBR_TYPE.NONE]: { Icon: () => <React.Fragment />, label: '' },
};

export const BASAL_TICKS = [
  {
    label: '0.0',
    value: 0,
  },
  {
    label: '1.0',
    value: 1 / 2,
  },
  {
    label: '2.0',
    value: 2 / 2,
  },
];

export const BASAL_PROFILE_CHANGE_TYPE = {
  ACTIVE: 'ACTIVE',
  PROFILE_CHANGE: 'PROFILE_CHANGE',
};

export const EXTENDED_MULTIWAVE_DEFAULT_BOLUS = '0:05 h';
export const MIDNIGHT_START_TIME = 24;
