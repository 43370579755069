import { isEmpty, last, split } from 'ramda';
import React, { Component } from 'react';

import {
  Tabs,
  TabLinkItemSmall,
  TabsHeaderSmall,
  TabsContent,
} from 'src/domains/diagnostics/components/tabs/tabs.component';
import { Block } from 'src/domains/diagnostics/components/block/block.component';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { AdditionalInformationButtonContainer as AdditionalInformationButton } from 'src/domains/diagnostics/components/additional-information/additional-information.container';
import {
  statisticsDisclaimers,
  logbookDisclaimersLastUpdated,
} from 'src/domains/diagnostics/components/additional-information/additional-information.constant';
import { colors } from 'src/core/styles/colors';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import {
  BloodGlucoseGeneralStatsSection,
  HeaderContainer,
} from 'src/domains/diagnostics/widgets/blood-glucose-general-stats/blood-glucose-general-stats.style';
import { additionalInfoActivated } from 'src/domains/diagnostics/components/additional-info/additional.info.utils';

export class BloodGlucoseGeneralStats extends Component {
  componentDidMount() {
    // if the link refers back to the BG Stats Dashboard Card,
    // jump to the top of the window, otherwise stay at the same level as
    // the rest of the graphs.
    if (last(split('/', this.props.location.pathname)) === 'devices') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { isLoading, hasData, t } = this.props;
    const TabsWithLoader = withGraphLoader(Tabs, t('general.loading'));

    return (
      <BloodGlucoseGeneralStatsSection>
        <HeaderContainer>
          <LocalizedText
            textKey={'bloodGlucoseStats.bloodGlucoseGeneralStatsTitle'}
            fontSize={5}
            pl={2}
            color={colors.charcoal}
          />
          {!additionalInfoActivated() && (
            <AdditionalInformationButton
              content={statisticsDisclaimers}
              updated={logbookDisclaimersLastUpdated}
            />
          )}
        </HeaderContainer>
        <TabsWithLoader isLoading={isLoading} hasError={!hasData}>
          <Block display="flex" justifyContent="space-between">
            <TabsHeaderSmall>
              <RenderIf validate={!isEmpty(this.props.deviceDetails)}>
                <TabLinkItemSmall
                  path="all"
                  name={t('devices.allDevicesTitle')}
                />
                {this.props.deviceDetails.map((device, index) => (
                  <TabLinkItemSmall
                    exact
                    path={device.id}
                    name={device.name}
                    key={index}
                  />
                ))}
              </RenderIf>
            </TabsHeaderSmall>
          </Block>
          <TabsContent>{this.props.children}</TabsContent>
        </TabsWithLoader>
      </BloodGlucoseGeneralStatsSection>
    );
  }
}
