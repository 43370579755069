import React, { useRef, useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import styled from 'styled-components';
export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  min-height: inherit;
  justify-content: center;
`;
Wrapper.displayName = 'Wrapper';

export const ResizeWrapper = (props) => {
  const {
    minHeight = 0,
    render,
    disableResize = false,
    resizeFunction = (clientHeight) => clientHeight,
    firstUpdate = false,
  } = props;

  const [height, setHeight] = useState(minHeight);
  const wrapperRef = useRef();
  let observer = null;

  useEffect(() => {
    if (firstUpdate) {
      updateHeight();
    }
    observer = Observable.fromEvent(window, 'resize').subscribe((event) => {
      updateHeight();
    });
    return () => {
      observer.unsubscribe();
    };
  }, []);

  const updateHeight = () => {
    if (wrapperRef?.current && !disableResize) {
      setHeight(resizeFunction(wrapperRef.current.clientHeight));
    }
  };

  return <Wrapper ref={wrapperRef}>{render(height)}</Wrapper>;
};
