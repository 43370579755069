import { isNil } from 'ramda';
import React from 'react';

import { CarbohydratesLine } from 'src/domains/diagnostics/components/graph/point-shapes.component';
import { DisconnectedLineSeries } from 'src/lib/graph-disconnected-line-series.component';
import {
  DATA_TYPE,
  FADED_OPACITY,
  FULL_OPACITY,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { toolTipWidth } from 'src/domains/diagnostics/components/tool-tip/detail-tool-tip/detail-tool-tip.style';
import { areDatesTheSameDay } from '../../scenes/graphs/graph-shared/graph-date';
import { getClickableCursorStyle } from '../../scenes/graphs/graph-shared/presentation.util';

export const CarbohydratesGraph = ({
  width,
  height,
  lines,
  selectedDate,
  onPointMouseOver,
  onPointMouseOut,
  onLineClick,
  collapsed,
}) => (
  <DisconnectedLineSeries
    points={lines}
    width={width}
    height={height}
    Line={(a, b, index) => (
      <CarbohydratesLine
        ax={a.x}
        bx={b.x}
        ay={a.y}
        by={b.y}
        key={`${a.x}-${b.x}-${a.y}-${b.y}-${index}`}
        opacity={
          isNil(selectedDate) || areDatesTheSameDay(a.date, selectedDate)
            ? FULL_OPACITY
            : FADED_OPACITY
        }
        onClick={onLineClick({
          type: DATA_TYPE.CARBOHYDRATES,
          date: a.date,
        })}
        onMouseMove={(event) =>
          onPointMouseOver(
            event,
            { type: DATA_TYPE.CARBOHYDRATES, ...a },
            toolTipWidth,
          )
        }
        onMouseOut={onPointMouseOut}
        cursor={getClickableCursorStyle(!collapsed)}
        className="no-user-select"
      />
    )}
  />
);
