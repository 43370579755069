export const mockCgm = {
  resultDescription: 'OK',
  model: {
    clinicalStatisticDTOs: [],
    cgmClinicalStatisticDTOs: [
      {
        patientInfoDTO: {
          fullName: '1420, Password Patient',
          birthday: '2018-02-02',
          diabetesType: 'DIABETES_TYPE1',
          healthCareSystemId: 'ewrtg',
          hcpIsAccessible: false,
          hasEtapesProgram: false,
          etapesEnrollName: null,
          hasRPMProgram: false,
          rpmEnrollName: null,
          rpmProgramName: null,
          patientId: 323793,
          gigyaUid: '9733dac2bd644a79b6b45d2e3126882c',
          gender: 'MALE',
          fhirId: 'rvSAHrp-Vmd-1349',
        },
        patientCgmClinicalStatisticsDTO: {
          gldVeryLow: 17.4,
          gldLow: 4.0,
          gldInRange: 78.6,
          gldHigh: 0.0,
          gldVeryHigh: 0.0,
          averageBloodGlucose: 98.0,
          standardDeviation: 41.0,
          coefficientOfVariation: 41.8,
          cgmActiveTime: 100,
          glucoseManagementIndicator: 7.5,
          lowestReading: 7.0,
          highestReading: 160.0,
          highestDate: '2021-03-15T11:45:00.000+00:00',
          lowestDate: '2021-03-14T20:45:00.000+00:00',
          lastCgmDate: '2021-03-15T13:15:00.000+00:00',
          hyperTR: null,
          highTR: null,
          lowTR: null,
          hipoTR: null,
        },
      },
      {
        patientInfoDTO: {
          fullName: '2 devices, Strips',
          birthday: null,
          diabetesType: 'DIABETES_TYPE1',
          healthCareSystemId: '1234',
          hcpIsAccessible: true,
          hasEtapesProgram: false,
          etapesEnrollName: null,
          hasRPMProgram: false,
          rpmEnrollName: null,
          rpmProgramName: null,
          patientId: 304216,
          gigyaUid: '3585de901b7f46d68b80142607efafec',
          gender: 'UNSPECIFIED',
          fhirId: 'n0eHbhp-Ko-67',
        },
        patientCgmClinicalStatisticsDTO: {
          gldVeryLow: 16.4,
          gldLow: 6.0,
          gldInRange: 70,
          gldHigh: 0.0,
          gldVeryHigh: 0.0,
          averageBloodGlucose: 97.0,
          standardDeviation: 40.0,
          coefficientOfVariation: 41.8,
          cgmActiveTime: 70,
          glucoseManagementIndicator: 4.7,
          lowestReading: 6.0,
          highestReading: 160.0,
          highestDate: '2021-03-11T11:45:00.000+00:00',
          lowestDate: '2021-03-12T20:45:00.000+00:00',
          lastCgmDate: '2021-03-10T13:15:00.000+00:00',
          hyperTR: null,
          highTR: null,
          lowTR: null,
          hipoTR: null,
        },
      },
      {
        patientInfoDTO: {
          fullName: '870 3, MySugr',
          birthday: null,
          diabetesType: 'DIABETES_TYPE2_MDI',
          healthCareSystemId: '8702',
          hcpIsAccessible: true,
          hasEtapesProgram: false,
          etapesEnrollName: null,
          hasRPMProgram: false,
          rpmEnrollName: null,
          rpmProgramName: null,
          patientId: 289803,
          gigyaUid: '67456ff21ca44993aa2daf348bbde496',
          gender: 'FEMALE',
          fhirId: 't40Me5p-Cg4-255',
        },
        patientCgmClinicalStatisticsDTO: {
          gldVeryLow: 1,
          gldLow: 0,
          gldInRange: 99,
          gldHigh: 0.0,
          gldVeryHigh: 0.0,
          averageBloodGlucose: 91.0,
          standardDeviation: 37.0,
          coefficientOfVariation: 41.8,
          cgmActiveTime: 72,
          glucoseManagementIndicator: 3.7,
          lowestReading: 2.0,
          highestReading: 165.0,
          highestDate: '2021-03-09T11:45:00.000+00:00',
          lowestDate: '2021-03-08T20:45:00.000+00:00',
          lastCgmDate: '2021-03-09T13:15:00.000+00:00',
          hyperTR: null,
          highTR: null,
          lowTR: null,
          hipoTR: null,
        },
      },
      {
        patientInfoDTO: {
          fullName: '870 6, Charly',
          birthday: '1990-11-30',
          diabetesType: 'DIABETES_TYPE2_MDI',
          healthCareSystemId: '8706',
          hcpIsAccessible: true,
          hasEtapesProgram: false,
          etapesEnrollName: null,
          hasRPMProgram: false,
          rpmEnrollName: null,
          rpmProgramName: null,
          patientId: 290562,
          gigyaUid: 'cec73dc3fd43414bbd8acd3e726a8ff2',
          gender: 'UNSPECIFIED',
          fhirId: 'jWd9d6p-iG5-2',
        },
        patientCgmClinicalStatisticsDTO: {
          gldVeryLow: null,
          gldLow: null,
          gldInRange: 40,
          gldHigh: null,
          gldVeryHigh: null,
          averageBloodGlucose: null,
          standardDeviation: null,
          coefficientOfVariation: null,
          cgmActiveTime: 30,
          glucoseManagementIndicator: 8,
          lowestReading: null,
          highestReading: null,
          highestDate: null,
          lowestDate: null,
          lastCgmDate: null,
          hyperTR: null,
          highTR: null,
          lowTR: null,
          hipoTR: null,
        },
      },
    ],
    clinicalStatisticsSummaryDTO: {
      totalPatientsBg: 32,
      totalPatientsCgm: 8,
    },
  },
};
