import styled, { StyledFunction } from 'styled-components';
import { colors } from 'src/core/styles/colors';
export type IconWrapperProps = {};

const IconWrapperDiv: StyledFunction<IconWrapperProps> = styled.div;

export const IconWrapper = IconWrapperDiv`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: ${colors.brandBlue};
    height: 80px;
    width: 80px;
    border-radius: 100px;
    margin-bottom: 24px;
`;

export type ButtonWrapperProps = {};

const ButtonWrapperDiv: StyledFunction<ButtonWrapperProps> = styled.div;

export const ButtonWrapper = ButtonWrapperDiv`
    margin-top: 30px;
`;
