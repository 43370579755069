import { Action, PayloadAction } from 'src/app/store/app.types';

import { MysugrStatusResponseTransformed } from 'src/services/mysugr-status/mysugr-status.types';

export enum MysugrStatusActionType {
  MYSUGR_STATUS_START = 'MYSUGR_STATUS_START',
  MYSUGR_STATUS_SUCCESS = 'MYSUGR_STATUS_SUCCESS',
  MYSUGR_STATUS_ERROR = 'MYSUGR_STATUS_ERROR',
}

export enum MysugrInviteActionType {
  MYSUGR_INVITE_START = 'MYSUGR_INVITE_START',
  MYSUGR_INVITE_SUCCESS = 'MYSUGR_INVITE_SUCCESS',
  MYSUGR_INVITE_ERROR = 'MYSUGR_INVITE_ERROR',
  MYSUGR_INVITE_RESET = 'MYSUGR_INVITE_RESET',
}

export type MysugrStatusStartAction = PayloadAction<
  MysugrStatusActionType.MYSUGR_STATUS_START,
  FixMe
>;

export type MysugrStatusSuccessAction = PayloadAction<
  MysugrStatusActionType.MYSUGR_STATUS_SUCCESS,
  FixMe
>;

export type MysugrStatusErrorAction = PayloadAction<
  MysugrStatusActionType.MYSUGR_STATUS_ERROR,
  FixMe
>;

export type MysugrInviteStartAction = PayloadAction<
  MysugrInviteActionType.MYSUGR_INVITE_START,
  FixMe
>;

export type MysugrInviteSuccessAction = PayloadAction<
  MysugrInviteActionType.MYSUGR_INVITE_SUCCESS,
  FixMe
>;

export type MysugrInviteErrorAction = PayloadAction<
  MysugrInviteActionType.MYSUGR_INVITE_ERROR,
  FixMe
>;

export type MysugrInviteResetAction =
  Action<MysugrInviteActionType.MYSUGR_INVITE_RESET>;

export type MysugrStatusActions =
  | MysugrStatusStartAction
  | MysugrStatusSuccessAction
  | MysugrStatusErrorAction;

export type MysugrInviteActions =
  | MysugrInviteStartAction
  | MysugrInviteSuccessAction
  | MysugrInviteErrorAction
  | MysugrInviteResetAction;

export type MysugrState = {
  isFetchingStatus: boolean;
  isError: boolean;
  status: null | MysugrStatusResponseTransformed;
  isSendingInvitation: boolean;
  isSendingInvitationError: boolean;
  invitationSent: boolean;
};
