import { compose, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import {
  search,
  clearCriteria,
  filterPatients,
  filterPatientsSet,
} from 'src/domains/general/widgets/store/hcp-dashboard.actions';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { search as searchAdmin } from 'src/core/patient-search/patient-search.actions';

import { PatientSearchComponent } from './patient-search.component';
import { searchConnector } from './store/patient-search.selectors';

const dispatchers = mapDispatchers({
  goHome: () => {
    const path = window.location.pathname;
    const dash = path.includes('cgm') ? '/cgm' : '';
    return push(`/home${dash}`);
  },
  search,
  searchAdmin,
  clearCriteria,
  filterPatients,
  filterPatientsSet,
});

type searchProps = {
  filterPatientsSet: (filters: object) => any;
  search: (
    unitGlucoseForService: string,
    location?: string,
    searchTerm?: string,
    // @ts-ignore
    fhirRole: boolean,
  ) => any;
  unitGlucoseForService: string;
  defaultFilters: object;
  goHome: () => any;
  searchTerm: string;
  clearCriteria: () => any;
  searchAdmin: () => any;
  isFhir: boolean;
  dashboardLocation: string;
};

type searchState = {};

const onSearchHcp =
  ({
    filterPatientsSet,
    search,
    unitGlucoseForService,
    goHome,
    defaultFilters,
    isFhir,
    dashboardLocation = 'BG',
  }: searchProps) =>
  ({ searchTerm }) => {
    filterPatientsSet(defaultFilters);
    search(unitGlucoseForService, dashboardLocation, searchTerm, isFhir);
    goHome();
  };

const onSearchAdmin =
  ({ goHome, searchAdmin }) =>
  ({ searchTerm: fullName }: searchProps) => {
    searchAdmin({ fullName });
    goHome();
  };

export const PatientSearch = compose(
  connect(searchConnector, dispatchers),
  withRouter,
  withHandlers({
    onSearchHcp: (props: searchProps) => onSearchHcp(props),
    onSearchAdmin: (props: searchProps) => onSearchAdmin(props),
  }),
  withTranslation,
  lifecycle<searchProps, searchState>({
    componentWillUnmount() {
      this.props.clearCriteria();
    },
  }),
)(PatientSearchComponent);
