import styled, { StyledComponentClass } from 'styled-components';

import { colors } from 'src/core/styles/colors';
import { fontSize } from 'src/core/styles/font-sizes';
import { weight } from 'src/domains/diagnostics/components/fonts/weights/weight.style';
import { GraphHintDiv } from 'src/domains/diagnostics/scenes/graphs/graph.style';
import { convertPxToRem } from 'src/domains/diagnostics/utils/rem-calc';
import { ThemeInterface } from 'src/theme';

export const defToolTipWidth = 200;
export const toolTipWidth = defToolTipWidth;

interface ContainerDivProps {
  toolTipWidth?: number;
}

export const ContainerDiv: StyledComponentClass<
  ContainerDivProps,
  ThemeInterface
> = styled<ContainerDivProps, any>(GraphHintDiv)`
  width: ${(props) => convertPxToRem(props.toolTipWidth || defToolTipWidth)};
  user-select: none;
`;
ContainerDiv.displayName = 'ContainerDiv';

export const TopBar = styled.div`
  height: 7px;
  background-color: ${(props) => props.color || colors.black};
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${colors.grayMedium};
  margin-right: 3px;
  transform: scale(0.7);
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  margin-bottom: 5px;
  font-size: ${fontSize.p};
  color: ${colors.grayMedium};
  font-weight: ${weight.semiBold};
`;

export const DateLabel = styled.div`
  margin-left: auto;
`;

export const TimeLabel = styled.div`
  font-size: ${fontSize.p};
  color: ${colors.grayMedium};
  font-weight: ${weight.semiBold};
  margin-right: auto;
`;

export const TimeLabelValue = styled.span`
  text-transform: uppercase;
`;
TimeLabelValue.displayName = 'TimeLabelValue';

export const TimeLabelValueDiv = styled.div`
  text-transform: uppercase;
`;
TimeLabelValueDiv.displayName = 'TimeLabelValueDiv';

export const MeasurementWrapper = styled.div`
  padding: 7px;
`;

export const InsulinMeasurementWrapper = styled(MeasurementWrapper)`
  display: flex;
`;

InsulinMeasurementWrapper.displayName = 'InsulinMeasurementWrapper';

export const CarboMeasurementWrapper = styled(MeasurementWrapper)`
  display: flex;
`;

CarboMeasurementWrapper.displayName = 'CarboMeasurementWrapper';

export const SingleInsulinMeasurementWrapper = styled.div`
  flex: auto;
`;

SingleInsulinMeasurementWrapper.displayName = 'SingleMeasurementWrapper';

export const SingleCarboMeasurementWrapper = styled.div`
  flex: auto;
`;

SingleCarboMeasurementWrapper.displayName = 'SingleCarboMeasurementWrapper';

export const CarboMeasurementValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 7px 7px 7px 0;
  margin-bottom: 5px;
  font-size: ${fontSize.graphLabel};
  border-bottom: 1px solid ${colors.grayNoData};
  :last-child {
    border-bottom: 1px solid ${colors.grayNoData};
    margin-bottom: 13px;
  }
`;

CarboMeasurementValueWrapper.displayName = 'CarboMeasurementValueWrapper';

export const MeasurementLabel = styled.div`
  font-size: ${fontSize.graphLabel};
  color: ${colors.grayMedium};
  line-height: 13px;
  letter-spacing: 1.07px;
`;

export const CarboMeasurementLabel = styled.div`
  font-size: ${fontSize.caption};
  color: ${colors.grayMedium};
  line-height: 13px;
  letter-spacing: 1.07px;
`;

export const MeasurementValueWrapper = styled.div`
  color: ${colors.darkestGray};
  font-size: 11px;
  font-weight: ${weight.semiBold};
  display: flex;
  flex-direction: row;
`;
MeasurementValueWrapper.displayName = 'MeasurementValueWrapper';

export const MultipleCarboMeasurementWrapper = styled.div`
  color: ${colors.darkestGray};
  font-size: 11px;
  font-weight: ${weight.semiBold};
  display: flex;
  flex-direction: column;
`;
MultipleCarboMeasurementWrapper.displayName = 'MultipleCarboMeasurementWrapper';

export const MeasurementValue = styled.span`
  font-size: 30px;
`;
MeasurementValue.displayName = 'MeasurementValue';

export const MeasurementUnitRemarkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin: 0 0 5px 5px;
`;
MeasurementUnitRemarkWrapper.displayName = 'MeasurementUnitRemarkWrapper';

export const MeasurementUnit = styled.div``;
MeasurementUnit.displayName = 'MeasurementUnit';

export const MeasurementRemark = styled.div``;
MeasurementUnit.displayName = 'MeasurementRemark';

export const BasalValue = styled.div`
  font-size: ${fontSize.subHeading};
  font-weight: ${weight.regular};
`;

export const BasalSetback = styled.div`
  font-size: ${fontSize.caption};
`;
export const ManualLabelWrapper = styled.div<any>`
  color: ${({
    manuallyEntered,
    theme: {
      colors: { green, red },
    },
  }) => (manuallyEntered ? green : red)};
  font-size: ${({ theme: { fontSize } }) => fontSize.graphLabel};
  line-height: ${({ theme: { fontSize } }) => fontSize.graphLabel};
  letter-spacing: ${({ theme: { letterSpacing } }) => letterSpacing.small};
  text-transform: uppercase;
  margin: 0.3125rem 0;
`;
export const HandPointIconWrapper = styled.span`
  margin-right: 0.25rem;
  display: flex;
  align-items: center;
`;
