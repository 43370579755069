import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { retryLogin } from 'src/core/login-system-error/login-system-error.action';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { mapDispatchers } from 'src/utils/map-dispatchers';

import { LoginSystemErrorModalComponent } from './login-system-error.modal';

const loginSystemErrorConnector = createStructuredSelector({});

export const loginSystemErrorModal = compose(
  connect(
    loginSystemErrorConnector,
    mapDispatchers({
      retryLogin,
    }),
  ),
  withTranslation,
)(LoginSystemErrorModalComponent);
