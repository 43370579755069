import * as React from 'react';
import { TFunction } from 'i18next';
import { getLabel } from 'src/utils/i18n/with-translation';

import {
  GeneralUseTextBold,
  ManufacturerInfoTitle,
  TextBlock,
  ManufacturerInfoSubTitle,
  GeneralUseTextImpressum,
} from '../manufacturer-info-modal.style';

type Props = {
  t: TFunction;
};

export const ImpressumView = ({ t }: Props) => (
  <div>
    <ManufacturerInfoTitle>
      {t('manufacturerInfo.impressum.title')}
    </ManufacturerInfoTitle>
    <ManufacturerInfoSubTitle>
      {t('manufacturerInfo.impressum.subtitle1')}
    </ManufacturerInfoSubTitle>

    <TextBlock>
      <GeneralUseTextImpressum>
        {getLabel(t('manufacturerInfo.impressum.text1'))}
      </GeneralUseTextImpressum>

      <GeneralUseTextImpressum>
        {getLabel(t('manufacturerInfo.impressum.text2'))}
      </GeneralUseTextImpressum>

      <GeneralUseTextImpressum>
        <GeneralUseTextBold>
          {t('manufacturerInfo.impressum.subtitle2')}
        </GeneralUseTextBold>
        {getLabel(t('manufacturerInfo.impressum.text3'))}
      </GeneralUseTextImpressum>
    </TextBlock>
  </div>
);
