import * as React from 'react';
import { TFunction } from 'i18next';

import { getLabel } from 'src/utils/i18n/with-translation';
import { LocalizedText } from 'src/components/localized-text/localized-text.component';

import { trademarkText } from './../manufacturer-info.constants';
import {
  ManufacturerInfoTitle,
  TextBlock,
  GeneralUseText,
  TrademarkSpan,
} from './../manufacturer-info-modal.style';

type Props = {
  t: TFunction;
};

const lastTrademark = (elem) => (
  <LocalizedText
    textKey="manufacturerInfo.trademark.lastTrademark"
    values={{ trademark: elem }}
  />
);

export const TradeMarkView = ({ t }: Props) => (
  <div>
    <ManufacturerInfoTitle>
      {t('manufacturerInfo.trademark.title')}
    </ManufacturerInfoTitle>
    <TextBlock>
      <GeneralUseText>
        {trademarkText.map((elem, i) => (
          <TrademarkSpan key={i}>
            {i + 1 === trademarkText.length ? lastTrademark(elem) : elem}
          </TrademarkSpan>
        ))}
      </GeneralUseText>
      <GeneralUseText>
        {getLabel(t('manufacturerInfo.trademark.text1'))}
      </GeneralUseText>
      <GeneralUseText>{t('manufacturerInfo.trademark.text2')}</GeneralUseText>
      <GeneralUseText>
        {t('manufacturerInfo.trademark.rocheTrademark')}
      </GeneralUseText>
    </TextBlock>
  </div>
);
