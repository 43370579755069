export const EVENTS_KEYS = {
  NEW_STOCK_ADDED: 'New Stock Added',
};

export const SEGMENT_KEYS = {
  MODEL: 'Model',
  STRIP_CONTAINERS_RECIEVED: 'Strip Containers Received',
};

export const TEST_KEYS = {
  SING_TEST: 'test',
  PLURAL_TEST: 'tests',
};
