import React from 'react';
import { Bundle } from 'src/navigation/bundle';

export const HomeBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { Home } = await import(
        /* webpackChunkName: "home" */ './scenes/home/home.component'
      );
      return Home;
    }}
    bundleDidLoad={(Home) => <Home {...props} />}
  />
);

export const HomeCgmBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { HomeCgm } = await import(
        /* webpackChunkName: "homeCgm" */ './scenes/home-cgm/home-cgm.component'
      );
      return HomeCgm;
    }}
    bundleDidLoad={(HomeCgm) => <HomeCgm {...props} />}
  />
);

export const HelpBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { HelpContainer } = await import(
        /* webpackChunkName: "help" */ './scenes/help/help.container'
      );
      return HelpContainer;
    }}
    bundleDidLoad={(HelpContainer) => <HelpContainer {...props} />}
  />
);
