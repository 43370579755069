export const mockSaveAlertsResponse = {
  hyper: {
    amount: 42,
    isActive: false,
  },
  hypo: {
    amount: 44,
    isActive: true,
  },
  warning: {
    amount: 48,
    isActive: true,
  },
};

export const mockSaveAlertsPayload = {
  patientId: '1234',
  alerts: null,
};
