import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { createService } from 'src/services/service';
import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';
import { mockResponse } from './gigya.mock';

const getGigyaAccountInfo = ({ token, gigyaToken, gigyaUid }) => {
  if (!token || !gigyaToken || !gigyaUid) return Promise.resolve();

  return getJSON(endpointWithParams(ENDPOINTS.accountInfo, { gigyaUid }), {
    headers: {
      Authorization: createAuthHeader(token),
    },
  });
};

export const gigyaAccountService = ({
  devMode = false,
}: {
  devMode: boolean;
}) => {
  if (devMode) {
    return createService(
      () => Promise.resolve(mockResponse as FixMe),
      (res) => res,
    );
  }
  return createService(getGigyaAccountInfo, (res) => res);
};
