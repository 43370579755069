import {
  GetDeviceAssociationEC6Response,
  DEVICE_TYPE,
} from './get-device-association.types';

export const mockDeviceAssociation: GetDeviceAssociationEC6Response = {
  resultDescription: 'getDeviceByAssociationId',
  model: {
    id: void 0,
    modelDevice: 'NuevoModeloPrueba',
    patientId: void 0,
    serialNumber: '925010078480',
    brandName: 'ACCU-CHEK',
    description: 'Nuevo modelo prueba',
    formatName: 'UNIT',
    reference: '1925',
    resourceType: DEVICE_TYPE.Meter,
  },
};
