import { FetchConfigurablesLoaderType } from './configurables.types';

import {
  FetchConfigurablesLoaderImpl,
  FetchConfigurablesServiceImpl,
} from './configurables.service';

const loaderImpl = FetchConfigurablesLoaderImpl;
const mockLoader = (q) =>
  Promise.resolve([
    'ROLE_OPEN_CONNECTIVITY_DATA_DOWN',
    'ROLE_RPM_PROGRAM',
    'ROLE_MYSUGR_INVITATION_PAT',
  ]);

export const FetchConfigurablesFactoryImpl = (
  { devMode = false },
  transform = (data) => data,
): FetchConfigurablesLoaderType => {
  const loader = devMode ? mockLoader : loaderImpl;
  return FetchConfigurablesServiceImpl(loader, transform);
};
