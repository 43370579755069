import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

export const HiValueIcon = ({ width, height, fillColor = colors.black }) => {
  const originalWidth = 11;
  const originalHeight = 10;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      minY={-0.5}
    >
      <path
        d="M0.902922 9.084C0.486922 9.084 0.278922 8.872 0.278922 8.448V1.092C0.278922 0.668 0.486922 0.456 0.902922 0.456C1.31892 0.456 1.52692 0.668 1.52692 1.092V4.188H6.26692V1.092C6.26692 0.668 6.47492 0.456 6.89092 0.456C7.29892 0.456 7.50292 0.668 7.50292 1.092V8.448C7.50292 8.872 7.29892 9.084 6.89092 9.084C6.47492 9.084 6.26692 8.872 6.26692 8.448V5.232H1.52692V8.448C1.52692 8.872 1.31892 9.084 0.902922 9.084ZM10.1021 9.084C9.68614 9.084 9.47814 8.872 9.47814 8.448V1.092C9.47814 0.668 9.68614 0.456 10.1021 0.456C10.5181 0.456 10.7261 0.668 10.7261 1.092V8.448C10.7261 8.872 10.5181 9.084 10.1021 9.084Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
