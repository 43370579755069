import { Action, PayloadAction } from 'src/app/store/app.types';
import { TimeInterval } from './../time-periods.types';

export enum PatientTimeBlocksActionType {
  FETCH_PATIENT_TIMEBLOCKS_START = 'FETCH_PATIENT_TIMEBLOCKS_START',
  FETCH_PATIENT_TIMEBLOCKS_SUCCESS = 'FETCH_PATIENT_TIMEBLOCKS_SUCCESS',
  FETCH_PATIENT_TIMEBLOCKS_ERROR = 'FETCH_PATIENT_TIMEBLOCKS_ERROR',
  SAVE_PATIENT_TIMEBLOCKS_START = 'SAVE_PATIENT_TIMEBLOCKS_START',
  SAVE_PATIENT_TIMEBLOCKS_SUCCESS = 'SAVE_PATIENT_TIMEBLOCKS_SUCCESS',
  SAVE_PATIENT_TIMEBLOCKS_ERROR = 'SAVE_PATIENT_TIMEBLOCKS_ERROR',
  MOVE_TIME_BLOCKS_UI = 'MOVE_TIME_BLOCKS_UI',
  PATIENT_TIMEBLOCKS_STATUS_RESET = 'PATIENT_TIMEBLOCKS_STATUS_RESET',
}

export type PatientTimeBlocksActions =
  | FetchPatientTimeBlocksStartAction
  | FetchPatientTimeBlocksSuccessAction
  | FetchPatientTimeBlocksErrorAction
  | MoveTimeBlocksUIAction
  | PatientTimeBlocksStatusResetAction
  | FixMe;

export type FetchPatientTimeBlocksStartAction = PayloadAction<
  PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_START,
  FetchPatientTimeBlocksStartPayloadType
>;

export type FetchPatientTimeBlocksSuccessAction = PayloadAction<
  PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_SUCCESS,
  TimeInterval[]
>;

export type FetchPatientTimeBlocksErrorAction =
  Action<PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_ERROR>;

export type MoveTimeBlocksUIAction = PayloadAction<
  PatientTimeBlocksActionType.MOVE_TIME_BLOCKS_UI,
  TimeInterval[]
>;

export type PatientTimeBlocksStatusResetAction =
  Action<PatientTimeBlocksActionType.PATIENT_TIMEBLOCKS_STATUS_RESET>;

export type FetchPatientTimeBlocksStartPayloadType = {
  patientId: string;
};

export type TimePeriodsState = {
  isFetchingBlocks: boolean;
  isFetchingBlocksError: boolean;
  isEditingBlocks: boolean;
  isSavingBlocks: boolean;
  isSavingBlocksError: boolean;
  blocksSaved: boolean;
  blocks: TimeInterval[] | null;
};
