import React from 'react';
import { Switch } from 'react-router-dom';
import { equals, map } from 'ramda';
import { RouterOutlet } from 'src/navigation/components/router-outlet';

import { ProtectedRoute } from 'src/components/protected-route/protected-route.container';
import { ProfileTypes } from 'src/core/department/department.types';
import {
  PERMISSIONS,
  PATIENT_PERMISSIONS,
} from 'src/core/permissions/permissions.constants';
import { patientLinks } from 'src/domains/patient/routes';
import { WithPatientPermissions } from 'src/utils/with-permissions/with-permissions.container';

import {
  OrgStockBundle,
  StripDeliveryBundle,
  PrescriptionInformationBundle,
  AlertsBundle,
  CustomClinicGuidesBundle,
} from './bundles';
import { PatientStripManagementBundle } from './bundles';
import { OrgStripManagementWrapper } from './scenes/org-strip-management/org-strip-management.container';

import { routes } from 'src/domains/routes';

export const stripManagementLinks = {
  stock: '/stock',
  stripInfoByPatient: '/patients/bg/:id/strip-information',
  prescriptionInfoByPatient: '/patients/bg/:id/strip-information/prescription',
  alerts: '/patients/bg/:id/strip-information/alerts',
  customClinicGuides:
    '/patients/bg/:id/strip-information/prescription/guide-settings',
};

export const stripManagementDashboardLinks = {
  stripInfoByPatient: '/patients/bg/:id',
  prescriptionInfoByPatient: '/patients/bg/:id/prescription',
  alerts: '/patients/bg/:id/alerts',
};

export const stripManagementDashboardAdmLinks = {
  stripInfoByPatient: '/patients/:id',
  prescriptionInfoByPatient: '/patients/:id/prescription',
  alerts: '/patients/:id/alerts',
};

export const stripManagementInnerRoutes = map((link) =>
  link.replace('/patients/bg/:id/', ''),
)(stripManagementLinks);

export const stripManagementLinksByPatient = (patientId) =>
  map((link) => link.replace('patients/bg/:id', `patients/bg/${patientId}`))(
    stripManagementLinks,
  );

const apiResponsePending = (patientProfileType) =>
  patientProfileType === undefined;

const isPickupProfileType = (patientProfileType) =>
  equals(patientProfileType)(ProfileTypes.pickup);

export const StripManagementRoutes = () => (
  <Switch>
    <RouterOutlet path={stripManagementLinks.stripInfoByPatient}>
      <PatientStripManagementBundle>
        <Switch>
          <ProtectedRoute
            exact
            path={stripManagementLinks.stripInfoByPatient}
            component={(routerProps) => (
              <WithPatientPermissions
                patientId={routerProps.match.params.id}
                hasPermissions={[PATIENT_PERMISSIONS.STRIP_MNG_PATIENT]}
                onAccessDenied={() =>
                  routerProps.history.push(
                    routes.dashboard.bgId.replace(
                      ':id',
                      routerProps.match.params.id,
                    ),
                  )
                }
              >
                <StripDeliveryBundle {...routerProps} />
              </WithPatientPermissions>
            )}
            hasPermissions={[PERMISSIONS.STRIP_TRAFFIC_LIGHT]}
            accessConditions={[
              ({ patientProfileType }) =>
                apiResponsePending(patientProfileType) ||
                isPickupProfileType(patientProfileType),
            ]}
            validationRedirectPath={routes.general.home}
          />
          <ProtectedRoute
            exact
            path={stripManagementLinks.prescriptionInfoByPatient}
            component={(routerProps) => {
              return (
                <WithPatientPermissions
                  patientId={routerProps.match.params.id}
                  hasPermissions={[PATIENT_PERMISSIONS.STRIP_MNG_PATIENT]}
                  onAccessDenied={() =>
                    routerProps.history.push(
                      routes.dashboard.bgId.replace(
                        ':id',
                        routerProps.match.params.id,
                      ),
                    )
                  }
                >
                  <PrescriptionInformationBundle {...routerProps} />
                </WithPatientPermissions>
              );
            }}
            hasPermissions={[
              PERMISSIONS.STRIP_TRAFFIC_LIGHT,
              PERMISSIONS.STRIP_PRESCRIPTIONS,
            ]}
            accessConditions={[
              ({ patientProfileType }) =>
                apiResponsePending(patientProfileType) ||
                isPickupProfileType(patientProfileType),
            ]}
            validationRedirectPath={routes.general.home}
          />
          <ProtectedRoute
            exact
            path={stripManagementLinks.alerts}
            component={(routerProps) => (
              <WithPatientPermissions
                patientId={routerProps.match.params.id}
                hasPermissions={[PATIENT_PERMISSIONS.STRIP_MNG_PATIENT]}
                onAccessDenied={() =>
                  routerProps.history.push(
                    routes.dashboard.bgId.replace(
                      ':id',
                      routerProps.match.params.id,
                    ),
                  )
                }
              >
                <AlertsBundle {...routerProps} />
              </WithPatientPermissions>
            )}
            hasPermissions={[
              PERMISSIONS.STRIP_TRAFFIC_LIGHT,
              PERMISSIONS.STRIP_PATIENT_ALERTS,
            ]}
            accessConditions={[
              ({ patientProfileType }) =>
                apiResponsePending(patientProfileType) ||
                isPickupProfileType(patientProfileType),
            ]}
            validationRedirectPath={routes.general.home}
          />
          <ProtectedRoute
            exact
            path={stripManagementLinks.customClinicGuides}
            component={(routerProps) => (
              <WithPatientPermissions
                patientId={routerProps.match.params.id}
                hasPermissions={[PATIENT_PERMISSIONS.STRIP_MNG_PATIENT]}
                onAccessDenied={() =>
                  routerProps.history.push(
                    routes.dashboard.bgId.replace(
                      ':id',
                      routerProps.match.params.id,
                    ),
                  )
                }
              >
                <CustomClinicGuidesBundle {...routerProps} />
              </WithPatientPermissions>
            )}
            hasPermissions={[
              PERMISSIONS.STRIP_TRAFFIC_LIGHT,
              PERMISSIONS.STRIP_PRESCRIPTIONS,
            ]}
            accessConditions={[
              ({ patientProfileType }) =>
                apiResponsePending(patientProfileType) ||
                isPickupProfileType(patientProfileType),
            ]}
            validationRedirectPath={routes.general.home}
          />
        </Switch>
      </PatientStripManagementBundle>
    </RouterOutlet>
    <RouterOutlet path={stripManagementLinks.stock}>
      <OrgStripManagementWrapper>
        <Switch>
          <ProtectedRoute
            exact
            path={stripManagementLinks.stock}
            component={OrgStockBundle}
            hasPermissions={[PERMISSIONS.STRIP_TRAFFIC_LIGHT]}
          />
        </Switch>
      </OrgStripManagementWrapper>
    </RouterOutlet>
  </Switch>
);

export const StripManagementDashboardInnerRoutes = () => (
  <Switch>
    <RouterOutlet path={patientLinks.patientById}>
      <PatientStripManagementBundle>
        <Switch>
          <ProtectedRoute
            exact
            path={[
              stripManagementDashboardLinks.stripInfoByPatient,
              stripManagementDashboardAdmLinks.stripInfoByPatient,
            ]}
            component={StripDeliveryBundle}
            hasPermissions={[PERMISSIONS.STRIP_TRAFFIC_LIGHT]}
          />
          <ProtectedRoute
            exact
            path={[
              stripManagementDashboardLinks.prescriptionInfoByPatient,
              stripManagementDashboardAdmLinks.prescriptionInfoByPatient,
            ]}
            component={PrescriptionInformationBundle}
            hasPermissions={[
              PERMISSIONS.STRIP_TRAFFIC_LIGHT,
              PERMISSIONS.STRIP_PRESCRIPTIONS,
            ]}
          />
          <ProtectedRoute
            exact
            path={[
              stripManagementDashboardLinks.alerts,
              stripManagementDashboardAdmLinks.alerts,
            ]}
            component={AlertsBundle}
            hasPermissions={[
              PERMISSIONS.STRIP_TRAFFIC_LIGHT,
              PERMISSIONS.STRIP_PATIENT_ALERTS,
            ]}
          />
        </Switch>
      </PatientStripManagementBundle>
    </RouterOutlet>
  </Switch>
);
