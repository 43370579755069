import { hasValue } from 'src/utils/validation-helpers';
import { frequencyToString } from 'src/services/frequencies/frequencies.utils';

export const convertFrequencyObjectToFrequencyId = (prescription) =>
  hasValue(prescription)
    ? {
        ...prescription,
        frequency: frequencyToString(prescription.frequency),
      }
    : prescription;
