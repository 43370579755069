export const trademarkText = [
  'ROCHEDIABETES, ',
  'ACCU-CHEK, ',
  'ACCU-CHEK AVIVA, ',
  'ACCU-CHEK AVIVA COMBO, ',
  'ACCU-CHEK AVIVA CONNECT, ',
  'ACCU-CHEK AVIVA EXPERT, ',
  'ACCU-CHEK AVIVA INSIGHT, ',
  'ACCU-CHEK AVIVA NANO, ',
  'ACCU-CHEK AVIVA SOLO, ',
  'ACCU-CHEK CARE, ',
  'ACCU-CHEK COMBO, ',
  'ACCU-CHEK CONNECT, ',
  'ACCU-CHEK GO, ',
  'ACCU-CHEK GUIDE, ',
  'ACCU-CHEK GUIDE ME, ',
  'ACCU-CHEK INSTANT, ',
  'ACCU-CHEK INSIGHT, ',
  'ACCU-CHEK MOBILE, ',
  'ACCU-CHEK NANO, ',
  'ACCU-CHEK PERFORMA, ',
  'ACCU-CHEK PERFORMA COMBO, ',
  'ACCU-CHEK PERFORMA CONNECT, ',
  'ACCU-CHEK PERFORMA EXPERT, ',
  'ACCU-CHEK PERFORMA INSIGHT, ',
  'ACCU-CHEK PERFORMA NANO, ',
  'ACCU-CHEK SMART PIX, ',
  'ACCU-CHEK SOLO, ',
  'ACCU-CHEK SPIRIT, ',
  'ACCU-CHEK SPIRIT COMBO, ',
  'ACCU-CHEK 360°, ',
  'D-TRONPLUS, ',
  'MYSUGR ',
  'VOICEMATE',
];
