import { propOr } from 'ramda';
import { createSelector } from 'reselect';
import moment from 'moment';
import { FORMAT, TIMEOUT } from '../../constants';

import { selectSession } from '../session/session.selectors';

export const selectAccessToken = createSelector([selectSession], (session) =>
  propOr('', 'accessToken', session),
);

export const selectRefreshToken = createSelector([selectSession], (session) =>
  propOr('', 'refreshToken', session),
);

export const selectExpiration = createSelector([selectSession], (session) =>
  propOr(null, 'expiration', session),
);

export const selectExpires_in = createSelector([selectSession], (session) =>
  propOr(TIMEOUT, 'expires_in', session),
);

export const selectNotExpired = createSelector(
  [selectExpiration],
  (expiration) =>
    parseInt(expiration, 10) > parseInt(moment().format(FORMAT), 10),
);

export const selectIsOidcSessionValid = createSelector(
  [selectAccessToken, selectRefreshToken, selectNotExpired],
  (accessToken, refreshToken, notExpired) =>
    Boolean(accessToken && refreshToken) && notExpired,
);
