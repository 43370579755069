import * as React from 'react';
import styled from 'styled-components';
import {
  alignItems,
  alignSelf,
  borderColor,
  borders,
  bottom,
  color,
  display,
  flex,
  flexDirection,
  flexWrap,
  fontSize,
  fontWeight,
  height,
  justifyContent,
  justifySelf,
  left,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  position,
  right,
  space,
  top,
  width,
} from 'styled-system';

import { StyledSystemProps } from '../../theme/theme.types';

import { BaseStyleProps } from '../component.types';

type SpanProps = BaseStyleProps & StyledSystemProps;

export const SpanComponent: React.FunctionComponent<SpanProps> = ({
  className,
  children = null,
}) => <span className={className}>{children}</span>;

export const Span = styled(SpanComponent)`
  ${color};
  ${space};
  ${width};
  ${height};
  ${flex};
  ${flexWrap};
  ${justifyContent};
  ${justifySelf};
  ${alignItems};
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${display};
  ${flexDirection};
  ${fontSize};
  ${maxWidth};
  ${minWidth};
  ${minHeight};
  ${maxHeight};
  ${position};
  ${top};
  ${left};
  ${right};
  ${bottom};
  ${fontWeight};
`;
