import {
  GetHba1cCardLoaderImplType,
  GetHba1cCardTransformImplType,
  GetHba1cCardResponseType,
  GetHba1cCardServiceImplType,
} from './hba1c-card.types';

import { getJSON, createAuthHeader } from '../../utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const GetHba1cCardLoaderImpl: GetHba1cCardLoaderImplType = (
  accessToken: string,
  gigyaToken: string,
  fhirID: string,
) =>
  getJSON(endpointWithParams(ENDPOINTS.hba1c, { fhirID }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const GetHba1cCardTransformImpl: GetHba1cCardTransformImplType = (
  data: GetHba1cCardResponseType,
) => ({
  hba1cLastValue: data.model.hba1cValue,
  dateLastValue: data.model.hba1cDate,
});

export const GetHba1cCardServiceImpl: GetHba1cCardServiceImplType =
  (
    load: GetHba1cCardLoaderImplType,
    transform: GetHba1cCardTransformImplType,
  ) =>
  (accessToken, gigyaToken, fhirID) =>
    load(accessToken, gigyaToken, fhirID).then(transform);
