import React from 'react';
import { Block } from 'src/components/block/block.component';

import { RenderIf } from 'src/utils/render-if';
import { isNotNil, isNotEmpty } from 'src/utils/validation-helpers';
import { PlusIcon } from 'src/assets/icons';
import { AdditionalInfo } from 'src/domains/diagnostics/components/additional-info/additional-info.container';

import { CardHeaderWrapper, CardTitle, IconDiv } from './card-header.style';

import { CardIcon } from '../card-icon/card-icon.component';
import { JelloIcon } from 'src/components/jello-icon/jello-icon.component';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';

const createCardHeaderIcons = ({
  expandable,
  info,
  openModal,
  expandClickHandler,
  openModalClickHandler,
  onInfoClickCallback,
  link,
  cardStyles,
  expanded,
  size,
}) => (
  <IconDiv cardStyles={cardStyles} expanded={expanded}>
    {isNotNil(info) && isNotEmpty(info) && (
      <Block mr={7} mt={-8}>
        <AdditionalInfo
          onInfoClickCallback={onInfoClickCallback}
          info={info.info}
          active={info.active}
          style={info.style}
          size={size}
        />
      </Block>
    )}

    <RenderIf validate={expandable}>
      <CardIcon
        icon={
          <span onClick={expandClickHandler}>
            <RenderIf validate={!expanded}>
              <JelloIcon iconName={JELLO_ICON_NAMES.MAXIMIZE} size={'XS'} />
            </RenderIf>
            <RenderIf validate={!!expanded}>
              <JelloIcon iconName={JELLO_ICON_NAMES.MINIMIZE} size={'XS'} />
            </RenderIf>
          </span>
        }
        link={link}
      />
    </RenderIf>

    <RenderIf validate={openModal}>
      <CardIcon
        icon={
          <span onClick={openModalClickHandler}>
            <PlusIcon />
          </span>
        }
      />
    </RenderIf>
  </IconDiv>
);
export const CardHeader = ({
  cardStyles,
  title,
  customHeaderComponent,
  expandable,
  openModal,
  expandClickHandler,
  openModalClickHandler,
  link,
  info,
  size,
  expanded,
  onInfoClickCallback,
  ...props
}) => (
  <CardHeaderWrapper cardStyles={cardStyles} {...props}>
    <RenderIf validate={customHeaderComponent}>
      {customHeaderComponent}
    </RenderIf>
    <RenderIf validate={!customHeaderComponent && title}>
      <CardTitle cardStyles={cardStyles} {...props}>
        {title}
      </CardTitle>
    </RenderIf>
    {createCardHeaderIcons({
      expanded,
      expandable,
      info,
      openModal,
      expandClickHandler,
      openModalClickHandler,
      link,
      size,
      cardStyles,
      onInfoClickCallback,
      ...props,
    })}
  </CardHeaderWrapper>
);
