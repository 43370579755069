import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

export const V3AddProfessionalIcon = ({
  fillColor = colors.charcoal,
  height = 15,
  strokeColor = colors.white,
}) => {
  const originalWidth = 17;
  const originalHeight = 22;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
    >
      <g
        id="v3-professional"
        stroke={strokeColor}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g
          id="Main-Menu"
          transform="translate(-203.000000, -44.000000)"
          fillRule="nonzero"
          stroke={strokeColor}
        >
          <g id="Group-18-Copy-4" transform="translate(186.000000, 35.000000)">
            <g id="Group-4" transform="translate(5.000000, 0.000000)">
              <g id="icon" transform="translate(13.000000, 10.000000)">
                <path
                  d="M8.70557442,9.41 C6.56330081,9.39582325 4.69891363,7.96562408 4.16338144,5.92558359 C3.60688838,3.87459611 4.50452048,1.70978784 6.36066729,0.626416409 C8.20459849,-0.434946118 10.5466516,-0.123991997 12.0384085,1.38024946 C13.8671972,3.2344344 13.8671972,6.18213121 12.0384085,8.03631615 C11.1623136,8.91898937 9.95977736,9.41462325 8.70557442,9.41 Z"
                  id="Shape"
                  strokeWidth="1.5"
                  fill="#E7EEFA"
                ></path>
                <path
                  d="M0.43447642,21.0588235 C0.291717535,21.0520268 0.158967665,20.9824184 0.0709049832,20.8681823 C-0.0020154769,20.7440021 -0.0196323151,20.594265 0.0224287917,20.4561512 C-0.00201547691,16.3278198 4.36812648,13 8.23611349,13 C12.1041005,13 16.3770839,16.4804687 16.4497982,20.4561512 C16.4939953,20.5972607 16.4667909,20.7514126 16.3770839,20.8681823 C16.287667,20.9840185 16.1523607,21.0538042 16.0074529,21.0588235 L0.43447642,21.0588235 Z"
                  id="Shape-2"
                  strokeWidth="1.5"
                  fill="#E7EEFA"
                ></path>
                <path
                  d="M7.64272767,17.2497559 C6.26130613,16.9359223 5.27664737,15.7138758 5.26380876,14.2973123 L5.26177528,14.0729457 L5.26177528,14.0729457"
                  id="Path-3"
                  strokeWidth="1.42857143"
                ></path>
                <circle
                  id="Oval"
                  strokeWidth="1.42857143"
                  fill="#FFFFFF"
                  strokeLinecap="round"
                  cx="9.42857143"
                  cy="17.4285714"
                  r="1.42857143"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
