import { createStructuredSelector, createSelector } from 'reselect';
import { path } from 'ramda';
import {
  selectIsSignedOut,
  selectIsSessionValid,
} from 'src/app/session/core/session/session.selectors';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { selectPatientPermissions } from 'src/core/permissions/permissions.selectors';

export const selectPatientProfileType = path(['patient', 'profile']);

export const protectedRouteConnector = createStructuredSelector({
  hasLoggedOut: selectIsSignedOut,
  isAuthenticated: selectIsSessionValid,
  routes: getCombinedRoutes,
  validationFunctionInputs: createSelector(
    selectPatientProfileType,
    (patientProfileType) => patientProfileType,
  ),
  validationBlindedPatient: createSelector(
    selectPatientPermissions,
    (patientPermissions) => patientPermissions,
  ),
});
