import { colors } from 'src/core/styles/colors';

export const getBorder = (border) => {
  if (!border) {
    return 'none';
  }

  const { color, size, thick } = border;
  const borderSize = size ? size : '0.0625rem';

  if (!thick) {
    return `${borderSize} solid ${color ? color : colors.silverDark}`;
  }
  return `${borderSize} solid ${color ? color : colors.grayLight}`;
};
