import { lensPath, set } from 'ramda';

import { STATE_ACTIONS } from 'src/core/state/state.constants';
import { FETCH_PATIENT_DATE_RANGE_REQUEST } from 'src/core/patient-date-range/patient-date-range.constant';
import { FETCH_PATIENT_REQUEST } from 'src/core/patient/patient.constant';
import { DEVICE_SETTINGS_TYPE_BASAL_BOLUS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { TIME_INTERVAL } from 'src/domains/diagnostics/constants/diagnostics.constants';

import {
  FETCH_BG_OVERVIEW_END_DATE_REQUEST,
  FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST,
  FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST,
  GET_CLINICAL_DATA_REQUEST,
  GET_CLINICAL_DATA_FROM_FHIR_REQUEST,
  PATIENT_DASHBOARD_ACTIONS,
  FETCH_CHECK_EXTERNAL_PATIENT_REQUEST,
  SET_IS_EXTERNAL_PATIENT,
} from './bg.constants';

export const INITIAL_PATIENT_DASHBOARD_STATE = {
  bgOverview: {
    endDate: null,
    glucoseMeasurements: [],
    timeInterval: TIME_INTERVAL.WEEKLY_INTERVALS, // Update default to UPLOAD_DATE when available
  },
  externalPatient: {
    patientId: '',
    patientExternalId: '',
  },
  bloodGlucoseUnit: null,
  glucoseMeasurements: [],
  graph: 'standard-day',
  graphStartTime: '0:00',
  graphType: 'details',
  logbookType: '24hour',
  deviceSettingsType: DEVICE_SETTINGS_TYPE_BASAL_BOLUS,
  insulin: { basals: [], bolus: [] },
  isFetchingClinicalData: false,
  isFetchingPatient: false,
  isFetchingPatientDateRange: false,
  isFetchingStripDeliveryInfo: false,
  isFetchingThreshold: false,
  isFetchingTimeIntervals: false,
  isExternalPatient: false,
  showBloodGlucoseAfterMealPoints: true,
  showBloodGlucoseBeforeMealPoints: true,
  showBloodGlucoseLines: true,
  showBloodGlucosePoints: true,
  showBasalRate: true,
  showCarbohydrates: true,
  showInsulin: true,
  showGridLines: true,
  showMeanBloodGlucose: true,
  bannersHiddenForDateRanges: {},
};

const patientDashboardReducerHandlers = {
  [FETCH_CHECK_EXTERNAL_PATIENT_REQUEST.START]: (state) => ({
    ...state,
    isExternalPatient: true,
  }),
  [FETCH_CHECK_EXTERNAL_PATIENT_REQUEST.ERROR]: (state) => ({
    ...state,
    isExternalPatient: false,
  }),
  [FETCH_CHECK_EXTERNAL_PATIENT_REQUEST.SUCCESS]: (state, action) => {
    const payload = action.payload || {};
    return {
      ...state,
      externalPatient: {
        patientId:
          payload !== 404
            ? payload.PWDID
            : FETCH_CHECK_EXTERNAL_PATIENT_REQUEST.ERROR,
        patientExternalId: payload.HEALTH_CARE_SYSTEM_ID,
      },
      isExternalPatient: action.payload !== 409,
    };
  },
  [SET_IS_EXTERNAL_PATIENT]: (state, { payload }) => ({
    ...state,
    isExternalPatient: payload.isExternalPatient,
  }),
  [FETCH_PATIENT_DATE_RANGE_REQUEST.START]: (state) => ({
    ...state,
    isFetchingPatientDateRange: true,
  }),
  [FETCH_PATIENT_DATE_RANGE_REQUEST.ERROR]: (state) => ({
    ...state,
    isFetchingPatientDateRange: false,
  }),
  [FETCH_PATIENT_DATE_RANGE_REQUEST.SUCCESS]: (state, { payload }) => {
    const { bgOverview } = state;
    return {
      ...state,
      isFetchingPatientDateRange: false,
      bgOverview: {
        ...bgOverview,
        endDate: payload.latestMeasurement,
      },
    };
  },
  [FETCH_BG_OVERVIEW_END_DATE_REQUEST.SUCCESS]: (state, { payload }) => ({
    ...state,
    bgOverview: {
      ...state.bgOverview,
      endDate: payload.latestMeasurement,
    },
  }),
  [FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST.SUCCESS]: (
    state,
    { payload: { measurements } },
  ) => {
    const path = lensPath(['bgOverview', 'glucoseMeasurements']);
    return set(path, measurements, state);
  },
  [FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST.SUCCESS]: (
    state,
    { payload: { measurements } },
  ) => {
    const path = lensPath(['bgOverview', 'glucoseMeasurements']);
    return set(path, measurements, state);
  },
  [FETCH_PATIENT_REQUEST.START]: (state) => ({
    ...state,
    isFetchingPatient: true,
  }),
  [FETCH_PATIENT_REQUEST.SUCCESS]: (state) => ({
    ...state,
    isFetchingPatient: false,
  }),
  [FETCH_PATIENT_REQUEST.ERROR]: (state) => ({
    ...state,
    isFetchingPatient: false,
  }),
  [STATE_ACTIONS.CLEAR_PATIENT_DASHBOARD]: () =>
    INITIAL_PATIENT_DASHBOARD_STATE,
  [PATIENT_DASHBOARD_ACTIONS.CHANGE_GRAPH]: (state, { payload }) => ({
    ...state,
    graph: payload,
  }),
  [PATIENT_DASHBOARD_ACTIONS.CHANGE_GRAPH_TYPE]: (state, { payload }) => ({
    ...state,
    graphType: payload,
  }),
  [PATIENT_DASHBOARD_ACTIONS.CHANGE_LOGBOOK_TYPE]: (state, { payload }) => ({
    ...state,
    logbookType: payload,
  }),
  [PATIENT_DASHBOARD_ACTIONS.CHANGE_DEVICE_SETTINGS]: (state, { payload }) => ({
    ...state,
    deviceSettingsType: payload,
  }),
  [PATIENT_DASHBOARD_ACTIONS.SET_BLOOD_GLUCOSE_OVERVIEW_END_DATE]: (
    state,
    { payload },
  ) => {
    const { bgOverview } = state;
    return {
      ...state,
      bgOverview: {
        ...bgOverview,
        endDate: payload.endDate,
      },
    };
  },
  [GET_CLINICAL_DATA_REQUEST.START]: (state) => ({
    ...state,
    isFetchingClinicalData: true,
  }),
  [GET_CLINICAL_DATA_FROM_FHIR_REQUEST.START]: (state) => ({
    ...state,
    isFetchingClinicalData: true,
  }),
  [GET_CLINICAL_DATA_REQUEST.SUCCESS]: (state) => ({
    ...state,
    isFetchingClinicalData: false,
  }),
  [GET_CLINICAL_DATA_FROM_FHIR_REQUEST.SUCCESS]: (state) => ({
    ...state,
    isFetchingClinicalData: false,
  }),
  [GET_CLINICAL_DATA_REQUEST.ERROR]: (state) => ({
    ...state,
    isFetchingClinicalData: false,
  }),
  [GET_CLINICAL_DATA_FROM_FHIR_REQUEST.ERROR]: (state) => ({
    ...state,
    isFetchingClinicalData: false,
  }),
  [PATIENT_DASHBOARD_ACTIONS.SET_CLINICAL_DATA]: (
    state,
    { payload: { unit, measurements, insulin } },
  ) => ({
    ...state,
    bloodGlucoseUnit: unit,
    glucoseMeasurements: measurements,
    insulin,
  }),
  [PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_LINES]: (state) => ({
    ...state,
    showBloodGlucoseLines: !state.showBloodGlucoseLines,
  }),
  [PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_POINTS]: (state) => ({
    ...state,
    showBloodGlucosePoints: !state.showBloodGlucosePoints,
  }),
  [PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_BEFORE_MEAL_POINTS]: (
    state,
  ) => ({
    ...state,
    showBloodGlucoseBeforeMealPoints: !state.showBloodGlucoseBeforeMealPoints,
  }),
  [PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_AFTER_MEAL_POINTS]: (
    state,
  ) => ({
    ...state,
    showBloodGlucoseAfterMealPoints: !state.showBloodGlucoseAfterMealPoints,
  }),
  [PATIENT_DASHBOARD_ACTIONS.TOGGLE_MEAN_BLOOD_GLUCOSE]: (state) => ({
    ...state,
    showMeanBloodGlucose: !state.showMeanBloodGlucose,
  }),
  [PATIENT_DASHBOARD_ACTIONS.TOGGLE_BASAL]: (state) => ({
    ...state,
    showBasalRate: !state.showBasalRate,
  }),
  [PATIENT_DASHBOARD_ACTIONS.TOGGLE_INSULIN]: (state) => ({
    ...state,
    showInsulin: !state.showInsulin,
  }),
  [PATIENT_DASHBOARD_ACTIONS.TOGGLE_CARBOHYDRATES]: (state) => ({
    ...state,
    showCarbohydrates: !state.showCarbohydrates,
  }),
  [PATIENT_DASHBOARD_ACTIONS.HIDE_BANNER_FOR_DATE_RANGE]: (state, action) => {
    const { dateRangeKey } = action.payload;
    return {
      ...state,
      bannersHiddenForDateRanges: {
        [dateRangeKey]: true,
      },
    };
  },
  [PATIENT_DASHBOARD_ACTIONS.RESET_HIDE_BANNER_FOR_DATE_RANGE]: (state) => {
    return {
      ...state,
      bannersHiddenForDateRanges: {},
    };
  },
};

export const patientDashboardReducer = (
  state = INITIAL_PATIENT_DASHBOARD_STATE,
  action,
) =>
  patientDashboardReducerHandlers[action.type]
    ? patientDashboardReducerHandlers[action.type](state, action)
    : state;
