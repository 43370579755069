export const logbookDisclaimers = [
  {
    titleKey: 'modals.disclaimer.hiLoControlResult.title',
    descKey: 'modals.disclaimer.hiLoControlResult.desc',
  },
  {
    titleKey: 'modals.disclaimer.flags.title',
    descKey: 'modals.disclaimer.flags.desc',
  },
  {
    titleKey: 'modals.disclaimer.timeAdjustments.title',
    descKey: 'modals.disclaimer.timeAdjustments.desc',
  },
];

export const statisticsDisclaimers = [
  {
    titleKey: 'modals.disclaimer.hiLoControlResult.title',
    descKey: 'modals.disclaimer.hiLoControlResult.desc',
  },
  {
    titleKey: 'modals.disclaimer.standardBolus.title',
    descKey: 'modals.disclaimer.standardBolus.desc',
  },
];

export const logbookDisclaimersLastUpdated = '2018-02-16';
