import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { PatientType } from './patient.types';
import { fromEc6Patient } from './patient.util';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

// Mule Api V1 points to Middleware
export const PatientLoaderImpl = ({ patientId }: any, accessToken: string) =>
  getJSON(endpointWithParams(ENDPOINTS.patient, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const PatientTransformImpl = ({ model }: { model: PatientType }) =>
  fromEc6Patient(model);

export const PatientServiceImpl =
  (loader, transform) => (query, accessToken, gigyaToken) =>
    loader(query, accessToken, gigyaToken).then(transform);
