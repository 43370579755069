import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import {
  CheckDataSharingConsentLoaderImplType,
  ConsentSuccessPayload,
} from '../../../../../services/patient/check-data-sharing-consent/check-data-sharing-consent.types';
import {
  CheckDataSharingConsentActionType,
  CheckDataSharingStartAction,
} from './check-data-sharing-consent.types';
import {
  checkDataSharingConsentSuccess,
  checkDataSharingConsentError,
} from './check-data-sharing-consent.actions';
import { catchError } from 'rxjs/operators';

export const checkDataSharingConsentEpic: (
  checkDataSharingConsentService: CheckDataSharingConsentLoaderImplType,
) => Epic<any, any> = (checkDataSharingConsentService) => (action$, store$) =>
  action$
    .ofType(CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_START)
    .flatMap(({ payload }: CheckDataSharingStartAction) => {
      const accessToken = selectAccessToken(store$.getState());
      const apiKey = selectGigyaToken(store$.getState());
      return Observable.fromPromise(
        checkDataSharingConsentService(accessToken, apiKey, payload),
      )
        .switchMap((response: ConsentSuccessPayload) => {
          // PLEASE!!!!! NO "response.status", has to be "response"
          return [checkDataSharingConsentSuccess(response)];
        })
        .pipe(
          catchError((response) =>
            Observable.of(checkDataSharingConsentError(response)),
          ),
        );
    });
