import styled from 'styled-components';
import { colors } from 'src/core/styles/colors';
import { borderRadius } from 'src/core/styles/border-radius';
import { boxShadow } from 'src/core/styles/box-shadow';
import { createStyledComponent } from 'src/utils/styles';

type TabsProps = {
  bp: number;
  expanded: boolean;
};

export const TabWrapper = styled.ul`
  display: none;
  margin: 0;
  max-width: 84%;
  a {
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    color: ${colors.black};
  }
`;

export const TabsWrapperSmall = createStyledComponent<FixMe, TabsProps>(
  TabWrapper,
)`
  padding: 12px 10px 9px 10px;
  @media (max-width: ${(props) => props.bp}px) {
    display: flex;
  }
`;
TabsWrapperSmall.displayName = 'TabsWrapperSmall';

export const TabsWrapperBig = createStyledComponent<FixMe, TabsProps>(
  TabWrapper,
)`
  padding: 18px 16px 14px 16px;
  .tab {
    &:hover,
    &.active {
      &:after {
        bottom: -16px;
      }
    }
  }
  @media (min-width: ${(props) => props.bp + 1}px) {
    display: flex;
    max-width: ${(props) => (props.expanded ? '80%' : '84%')}
  }
  @media (min-width: 1700px) and (max-width: 1800px){
    max-width: ${(props) => (props.expanded ? '75%' : '84%')}
  }
`;
TabsWrapperBig.displayName = 'TabsWrapperBig';

export const Btn = styled.li`
  display: flex;
  position: relative;
  &:hover {
    span {
      color: ${colors.blue};
    }
  }
  &.active {
    span {
      color: ${colors.blue};
    }
  }
  &.left,
  &.right {
    margin-right: 6px;
  }
  &.right {
    margin-left: -10px;
    span {
      transform: rotate(180deg);
    }
  }
  &.enabled {
    cursor: pointer;
  }
`;
Btn.displayName = 'Btn';

export const Tab = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-right: 16px;
  position: relative;
  text-transform: uppercase;
  &.active,
  &:hover {
    &:after {
      background: ${colors.blue};
      bottom: -10px;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      height: 3px;
    }
  }
`;
Tab.displayName = 'Tab';

export const Dropdown = styled.div`
  left: 0;
  position: absolute;
  top: 25px;
  background: ${colors.white};
  border-radius: ${borderRadius.three};
  box-shadow: ${boxShadow({
    color: colors.charcoal,
    depth: 'popover',
  })};
  font-size: 12px;
  overflow: hidden;
  text-transform: uppercase;
  width: max-content;
  z-index: 3;
`;
Dropdown.displayName = 'Dropdown';

export const DropdownTabWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;
DropdownTabWrapper.displayName = 'DropdownTabWrapper';

export const DropdownTab = styled.li`
  cursor: pointer;
  display: block;
  font-size: 12px;
  padding: 13px;
  &.active,
  &:hover {
    color: ${colors.blue};
    border-left: 3px solid ${colors.blue};
    padding-left: 10px;
  }
`;
DropdownTab.displayName = 'DropdownTab';
