import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';

import {
  ListSection,
  ListsContainer,
  List,
  ListElement,
  ListElementSectionHeader,
} from '../../graph-statistics.style';

export const GraphStatisticsInsulinComponent = ({
  totalUPerDay = 0,
  basalUPerDay = 0,
  bolusUPerDay = 0,
  bolusesPerDay = 0,
  t,
}) => {
  const hasData = basalUPerDay > 0 || bolusesPerDay > 0;
  const hasSufficiencyData = basalUPerDay > 0 && bolusesPerDay > 0;
  const uPerDayLabel = 'graphDetails.statistics.insulin.uPerDay';
  const noDataAvailableLabel =
    'graphDetails.statistics.bloodGlucose.noDataAvailable';
  return (
    <ListSection>
      <ListElementSectionHeader noIcon>
        <LocalizedText textKey={'graphDetails.statistics.insulin.insulin'} />
      </ListElementSectionHeader>
      <ListsContainer>
        <List flex="none" iconPadding>
          <ListElement>
            <LocalizedText textKey={'graphDetails.statistics.insulin.total'} />
          </ListElement>
          <ListElement>
            <LocalizedText
              textKey={'graphDetails.statistics.insulin.basalInsulin'}
            />
          </ListElement>
          <ListElement>
            <LocalizedText textKey={'graphDetails.statistics.insulin.bolus'} />
          </ListElement>
          <ListElement>
            <LocalizedText
              textKey={'graphDetails.statistics.insulin.bolusFrequency'}
            />
          </ListElement>
        </List>
        <List>
          <ListElement bold>
            {hasData ? (
              hasSufficiencyData ? (
                `${totalUPerDay} ${t(uPerDayLabel)}`
              ) : (
                <LocalizedText textKey="graphDetails.statistics.bloodGlucose.notEnoughData" />
              )
            ) : (
              <LocalizedText textKey={noDataAvailableLabel} />
            )}
          </ListElement>
          <ListElement bold>
            {basalUPerDay > 0 ? (
              `${basalUPerDay} ${t(uPerDayLabel)}`
            ) : (
              <LocalizedText textKey={noDataAvailableLabel} />
            )}
          </ListElement>
          <ListElement bold>
            {bolusUPerDay > 0 ? (
              `${bolusUPerDay} ${t(uPerDayLabel)}`
            ) : (
              <LocalizedText textKey={noDataAvailableLabel} />
            )}
          </ListElement>
          <ListElement bold>
            {bolusesPerDay > 0 ? (
              `${bolusesPerDay}${t('graphDetails.statistics.insulin.perDay')}`
            ) : (
              <LocalizedText textKey={noDataAvailableLabel} />
            )}
          </ListElement>
        </List>
      </ListsContainer>
    </ListSection>
  );
};

export const GraphStatisticsInsulin = withTranslation(
  GraphStatisticsInsulinComponent,
);
