import { PayloadAction } from 'src/app/store/app.types';

export enum TargetRangesActionType {
  TARGET_RANGES_GET = 'TARGET_RANGES_GET',
  TARGET_RANGES_GET_START = 'TARGET_RANGES_GET_START',
  TARGET_RANGES_GET_SUCCESS = 'TARGET_RANGES_GET_SUCCESS',
  TARGET_RANGES_GET_ERROR = 'TARGET_RANGES_GET_ERROR',
  TARGET_RANGES_SAVE = 'TARGET_RANGES_SAVE',
  TARGET_RANGES_SAVE_START = 'TARGET_RANGES_SAVE_START',
  TARGET_RANGES_SAVE_SUCCESS = 'TARGET_RANGES_SAVE_SUCCESS',
  TARGET_RANGES_SAVE_ERROR = 'TARGET_RANGES_SAVE_ERROR',
  TARGET_RANGES_CLEAR = 'TARGET_RANGES_CLEAR',
  TARGET_RANGES_CLEAR_SUCCESS = 'TARGET_RANGES_CLEAR_SUCCESS',
  TARGET_RANGES_CLEAR_START = 'TARGET_RANGES_CLEAR_START',
  TARGET_RANGES_CLEAR_SUCCESS_START = 'TARGET_RANGES_CLEAR_SUCCESS_START',
}

export type GetTargetRangesAction = PayloadAction<
  TargetRangesActionType.TARGET_RANGES_GET_START,
  TargetRangesParamsPayloadType
>;

export type GetTargetRangesSuccessAction = PayloadAction<
  TargetRangesActionType.TARGET_RANGES_GET_SUCCESS,
  Ranges
>;

export type GetTargetRangesErrorAction = PayloadAction<
  TargetRangesActionType.TARGET_RANGES_GET_ERROR,
  Ranges
>;

export type SaveTargetRangesAction = PayloadAction<
  TargetRangesActionType.TARGET_RANGES_SAVE_START,
  TargetRangesParamsPayloadType
>;

export type SaveTargetRangesSuccessAction = PayloadAction<
  TargetRangesActionType.TARGET_RANGES_SAVE_SUCCESS,
  Ranges
>;

export type SaveTargetRangesErrorAction = PayloadAction<
  TargetRangesActionType.TARGET_RANGES_SAVE_ERROR,
  Ranges
>;

export type TargetRangesClearAction = PayloadAction<
  TargetRangesActionType.TARGET_RANGES_CLEAR_START,
  Ranges
>;

export type TargetRangesClearSuccessAction = PayloadAction<
  TargetRangesActionType.TARGET_RANGES_CLEAR_SUCCESS_START,
  Ranges
>;

export type RangeValues = {
  actualHyper: string;
  hyper: string;
  inTarget: string;
  hypo: string;
  warning: string;
};

export type Ranges = {
  unit: string;
  afterEating: RangeValues;
  beforeBed: RangeValues;
  beforeEating: RangeValues;
  nonMealRelated: RangeValues;
};

export type SimpleRanges = {
  actualHyper: number;
  hyper: number;
  hypo: number;
  warning: number;
};

export type TimeDayRange = {
  hour: number;
  minutes: number;
};

export type TimeOfEachRange = {
  actualHyper: TimeDayRange;
  hyper: TimeDayRange;
  inTarget: TimeDayRange;
  hypo: TimeDayRange;
  warning: TimeDayRange;
};

export type TargetRangesParamsPayloadType = {
  patientId: string;
};

export type TargetRangesState = {
  isFetchingTargetRanges: boolean;
  isFetchingTargetRangesError: boolean;
  successTargetRanges: boolean;
  errorTargetRanges: boolean;
  ranges: Ranges;
};

export type TargetRangesActions =
  | GetTargetRangesAction
  | GetTargetRangesSuccessAction
  | GetTargetRangesErrorAction
  | SaveTargetRangesAction
  | SaveTargetRangesSuccessAction
  | SaveTargetRangesErrorAction
  | TargetRangesClearAction
  | TargetRangesClearSuccessAction;
