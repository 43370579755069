import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  SendInvitationActionType,
  SendInvitationErrorAction,
  SendInvitationErrorPayload,
  SendInvitationPayloadType,
  SendInvitationStartAction,
  SendInvitationSuccessAction,
  SendInvitationSuccessPayload,
  ResetInviteAction,
} from './send-invitation.actions.types';

export const sendInvitationStart = (
  payload: SendInvitationPayloadType,
): SendInvitationStartAction =>
  createPayloadAction(SendInvitationActionType.SEND_INVITATION_START, payload);

export const sendInvitationSuccess = (
  payload: SendInvitationSuccessPayload,
): SendInvitationSuccessAction =>
  createPayloadAction(
    SendInvitationActionType.SEND_INVITATION_SUCCESS,
    payload,
  );

export const sendInvitationError = (
  payload: SendInvitationErrorPayload,
): SendInvitationErrorAction =>
  createPayloadAction(SendInvitationActionType.SEND_INVITATION_ERROR, payload);

export const resetsendInvitation = (): ResetInviteAction =>
  createAction(SendInvitationActionType.RESET_INVITATION);
