import { match } from 'ramda';
import {
  putJSON,
  createAuthHeader,
  stringifyBody,
} from 'src/utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';

import {
  EditEMRProfileParams,
  EditEMRProfileResponse,
  EC6Model,
} from './edit-emr-profile.types';

export const EditEMRProfileLoaderImpl = (
  { profile }: EditEMRProfileParams,
  accessToken: string,
) =>
  putJSON(ENDPOINTS.profileEmr, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody(profile),
  });

export const EditEMRProfileTransformImpl = ({
  model,
}: EditEMRProfileResponse): EC6Model => ({
  profileId: Number(match(/([0-9](.*))/g, model)[0]),
});

export const EditEMRProfileServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);
