import { isEmpty } from 'ramda';

export const getMeasurementDates = (measurements) =>
  measurements.map((measurement) => measurement.date);
export const measurementsToDateRange = (measurements) =>
  getMeasurementDates(measurements).reduce(
    (acc, date) => ({
      start: !acc.start || acc.start > date ? date : acc.start,
      end: !acc.end || acc.end < date ? date : acc.end,
    }),
    {},
  );
export const graphHasMeasurement = (bg, insulin, basal, carbs) =>
  !isEmpty(bg) || !isEmpty(insulin) || !isEmpty(basal) || !isEmpty(carbs);
