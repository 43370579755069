import { PayloadAction } from '@roche/rdcp-hcp-pi-client/core';
import { ModuleEntries } from '@roche/roche-common';

export enum ManifestModulesActionType {
  SAVE_MANIFEST_MODULES = 'SAVE_MANIFEST_MODULES',
}

// tslint:disable-next-line: no-empty-interface
export type ManifestModulesState = ModuleEntries;

export type ManifestModulesPayload = ModuleEntries;

export type SaveManifestModules = PayloadAction<
  ManifestModulesActionType.SAVE_MANIFEST_MODULES,
  ManifestModulesPayload
>;

export type ManifestModulesActions = SaveManifestModules;
