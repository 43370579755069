import { match } from 'ramda';
import {
  postJSON,
  createAuthHeader,
  stringifyBody,
} from 'src/utils/service/service.utils';
import { ENDPOINTS } from 'src/services/service.constants';

import {
  CreatePatientParams,
  CreatePatientResponse,
  EC6Model,
} from './create-patient.types';

export const CreatePatientLoaderImpl = (
  { professionalId, patient }: CreatePatientParams,
  accessToken: string,
) =>
  postJSON(ENDPOINTS.createPatient, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody(patient),
  });

export const CreatePatientTransformImpl = ({
  model,
}: CreatePatientResponse): EC6Model => ({
  patientId: Number(match(/patients\/(.*)/, model)[1]),
});

export const CreatePatientServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);
