import React from 'react';

import { TableHeader } from 'src/domains/diagnostics/components/table-header/table-header.component';
import { TableRow } from 'src/domains/diagnostics/components/table-row/table-row.component';
import { colors } from 'src/core/styles/colors';
import { spacing } from 'src/core/styles/spacing';

import { Title } from './blood-glucose-table-header.style';

import { BloodGlucoseOverviewTableCell } from '../blood-glucose-overview-table-cell/blood-glucose-overview-table-cell.component';

export const BloodGlucoseOverviewTableHeader = ({ title }) => (
  <TableHeader>
    <TableRow>
      <BloodGlucoseOverviewTableCell
        p={[spacing.three, spacing.two]}
        w="15rem"
        borderBottom={{
          color: colors.silver,
          size: '0.125rem',
          thick: true,
        }}
      >
        <Title color={colors.blueMarine}>{title}</Title>
      </BloodGlucoseOverviewTableCell>
      <BloodGlucoseOverviewTableCell
        borderBottom={{
          color: colors.silver,
          size: '0.125rem',
          thick: true,
        }}
      />
    </TableRow>
  </TableHeader>
);
