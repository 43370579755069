export const MODAL_ACTIONS = {
  ADD_MODAL: 'ADD_MODAL',
  REMOVE_MODAL_BY_KEY: 'REMOVE_MODAL_BY_KEY',
  REMOVE_ALL_MODALS: 'REMOVE_ALL_MODALS',
  UPDATE_MODAL: 'UPDATE_MODAL',
  DOM_SHOW_BODY_OVERFLOW: 'DOM_SHOW_BODY_OVERFLOW',
  DOM_HIDE_BODY_OVERFLOW: 'DOM_HIDE_BODY_OVERFLOW',
};

export const MODAL_TYPES = {
  LOADING: 'LOADING',
  DISCLAIMER: 'DISCLAIMER',
  SUPPORT: 'SUPPORT',
  DTC: 'DTC',
  MANUFACTURER_INFO: 'MANUFACTURER_INFO',
  CUSTOM: 'CUSTOM',
  LOGIN_SYSTEM_ERROR: 'LOGIN_SYSTEM_ERROR',
  TTL_EXPIRING: 'TTL_EXPIRING',
  CHANGE_PASSWORD_CONFIRMATION: 'CHANGE_PASSWORD_CONFIRMATION',
  DISCLAIMER_BROWSER: 'DISCLAIMER_BROWSER',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  STRIPS_MODAL: 'STRIPS_MODAL',
};

export const LOADING_MODAL_STATUS = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  SUCCESS_REQUIRES_CONFIRMATION: 'SUCCESS_REQUIRES_CONFIRMATION',
  ERROR: 'ERROR',
};
