import { createStyledComponent } from 'src/utils/styles';
import styled from 'styled-components';

export const PatientDashboardSelectorContainer = createStyledComponent<
  'div',
  {}
>('div')`
  position: relative;
  display: flex;
`;
PatientDashboardSelectorContainer.displayName =
  'PatientDashboardSelectorContainer';

export const PatientDashboardSelectorTitle = styled.div`
  margin-bottom: 6px;
  margin-top: -12px;
`;
PatientDashboardSelectorTitle.displayName = 'PatientDashboardSelectorTitle';
