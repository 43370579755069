import 'isomorphic-fetch';
import { propOr, isNil } from 'ramda';
import { isString, StringMap } from '../typescript';
import { ErrorResponseBody, EndpointTransfParams } from './service.utils.types';
import { getConfig } from '@roche/roche-common';
import { ENDPOINTS as E } from 'src/services/service.constants';

const { REACT_APP_CLIENT_SECRET, REACT_APP_CLIENT_ID, REACT_APP_GIGYA_TOKEN } =
  getConfig();

const defaultClientHeaders: StringMap = {
  client_id: REACT_APP_CLIENT_ID,
  client_secret: REACT_APP_CLIENT_SECRET,
  ApiKey: REACT_APP_GIGYA_TOKEN,
};

export const defaultContentTypeHeaders: StringMap = {
  'Content-Type': 'application/json',
};

export const handleResponseError = (
  response: Response,
  errorResponseBody: any,
): ErrorResponseBody => {
  throw {
    url: response.url,
    errorCode: propOr(undefined, 'error', errorResponseBody),
    status: response.status,
    statusText: response.statusText,
    errorResponseBody,
  };
};

export const handleResponse = (response: Response) => {
  const { status } = response;
  const contentLenght = response.headers?.get('content-length');
  const success = response.ok;
  const requestForPairingCode = response.url === E.pairingCodeAvailability;
  // @ts-ignore
  const urlParams = new URL(response.url).pathname;
  const editPatientFhirRegExp = new RegExp(
    /^\/[a-z]{9}\/[a-z]{3}\/[a-z]{1}[2]\/[a-z]{8}\/\d{7}$/,
  );
  const requestEditPatientFhir = !isNil(urlParams.match(editPatientFhirRegExp));
  /* istanbul ignore next */
  if (requestForPairingCode) {
    return Promise.resolve(response);
  }
  /* istanbul ignore next */
  if (requestEditPatientFhir) {
    return response.ok
      ? Promise.resolve(response)
      : handleResponseError(response, response.body);
  }
  /* istanbul ignore next */
  if (success && contentLenght === '0') {
    return Promise.resolve(response);
  }

  /* istanbul ignore next */
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response
      .json()
      .then((responseBody: any) =>
        response.ok
          ? responseBody
          : handleResponseError(response, responseBody),
      );
  } else {
    return Promise.resolve(status);
  }
};

/* istanbul ignore next */
export const stringifyBody = (body: any): string =>
  isString(body) ? body : JSON.stringify(body);

export const endpointTransformer = (
  endpoint: string,
  [replacedSlice, replacer, oldParam, newParam, flag]: EndpointTransfParams,
): string => {
  if (flag) {
    const newSlice =
      replacer && replacedSlice
        ? endpoint.replace(replacedSlice, replacer)
        : endpoint;
    return newParam && oldParam
      ? newSlice.replace(oldParam, newParam)
      : newSlice;
  }
  return endpoint;
};

const buildDefaultHeaders = (headers?: StringMap): StringMap => ({
  ...defaultContentTypeHeaders,
  ...defaultClientHeaders,
  ...headers,
});

const buildDefaultOptions = ({
  headers,
  body,
  ...options
}: RequestInit): RequestInit => ({
  headers: buildDefaultHeaders(headers as StringMap),
  body: stringifyBody(body),
  ...options,
});

export const fetchJSON = (url: RequestInfo, options: RequestInit = {}) =>
  fetch(url, buildDefaultOptions(options)).then(handleResponse);

export const getJSON = (url: RequestInfo, options: RequestInit = {}) =>
  fetchJSON(url, { method: 'GET', ...options });

export const postJSON = (url: RequestInfo, options: RequestInit = {}) =>
  fetchJSON(url, { method: 'POST', ...options });

export const putJSON = (url: RequestInfo, options: RequestInit = {}) =>
  fetchJSON(url, { method: 'PUT', ...options });

export const deleteJSON = (url: RequestInfo, options: RequestInit = {}) =>
  fetchJSON(url, { method: 'DELETE', ...options });

export const createAuthHeader = (token) => `Bearer ${token}`;
