import * as React from 'react';
import { refreshOidcTokensStart } from 'src/app/session/core/oidc/oidc.actions';
import { connect } from 'react-redux';

interface Props {
  refreshAccessToken: () => void;
  children?: React.ReactNode;
}

class RefreshAccessToken extends React.Component<Props, {}> {
  private readonly refreshAccessTokenEvent = 'RefreshAccessTokenForVisitModule';
  public componentDidMount() {
    document.body.addEventListener(
      this.refreshAccessTokenEvent,
      this.props.refreshAccessToken,
    );
  }

  public componentWillUnmount() {
    document.body.removeEventListener(
      this.refreshAccessTokenEvent,
      this.props.refreshAccessToken,
    );
  }

  public render() {
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch): Props => ({
  refreshAccessToken: () => dispatch(refreshOidcTokensStart()),
});

export const withRefreshAccessToken = (WrappedComponent) => {
  const RefreshAccessTokenComponent = (props: Props) => (
    <RefreshAccessToken {...props}>
      <WrappedComponent {...props} />
    </RefreshAccessToken>
  );

  return connect(null, mapDispatchToProps)(RefreshAccessTokenComponent);
};
