import {
  getJSON,
  createAuthHeader,
} from '../../../utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';

import {
  PairingCodeIdentityStatusLoaderImplType,
  PairingCodeIdentityStatusResponseType,
  PairingCodeIdentityStatusServiceImplType,
  PairingCodeIdentityStatusTransformImplType,
} from './pairing-code-identity-status.types';

export const PairingCodeIdentityStatusLoaderImpl: PairingCodeIdentityStatusLoaderImplType =
  (accessToken: string, apiKey: string, fhirId: string) => {
    return getJSON(ENDPOINTS.pairingCodeIdentityStatus, {
      headers: {
        Authorization: createAuthHeader(accessToken),
        ApiKey: apiKey,
        fhirId,
      },
    });
  };

export const PairingCodeIdentityStatusTransformImpl: PairingCodeIdentityStatusTransformImplType =
  (response: PairingCodeIdentityStatusResponseType) => response;

export const PairinCodeIdentityStatusServiceImpl: PairingCodeIdentityStatusServiceImplType =
  (load, transform) => (accessToken: string, apiKey: string, fhirId: string) =>
    load(accessToken, apiKey, fhirId).then(transform);
