import * as React from 'react';
import { testId } from '@roche/roche-common';

import { getProps, additionalInfoActivated } from './additional.info.utils';
import { ID, BTN } from './additional-info.constants';
import { AdditionalInfoWrapper } from './additional-info.style';
import {
  JELLO_BUTTON_SIZES,
  JELLO_BUTTON_VARIANTS,
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_BUTTON_VARIANTS,
  JELLO_ICON_NAMES,
} from 'src/app/app.jello.constants';

export const AdditionalInfoElement = ({
  children,
  handleClick,
  onInfoClickCallback,
  info,
  profile,
  active,
  style,
  size,
  t,
  textButton = false,
  textButtonLabel,
}) => {
  if (additionalInfoActivated() && !children) {
    return textButton ? (
      <jello-button
        size={size ? size : JELLO_BUTTON_SIZES.S}
        variant={JELLO_BUTTON_VARIANTS.OPEN_COLOR}
        left-icon-name={JELLO_ICON_NAMES.INFORMATION}
        onClick={() => handleClick(getProps(t, info, profile, active))}
        {...testId(ID, `${info}${BTN}`)}
      >
        {textButtonLabel}
      </jello-button>
    ) : (
      <jello-icon-button
        style={style}
        icon-name={JELLO_ICON_NAMES.INFORMATION}
        size={size ? size : JELLO_ICON_BUTTON_SIZES.M}
        variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
        onClick={() => {
          handleClick(getProps(t, info, profile, active));
          if (typeof onInfoClickCallback === 'function') {
            onInfoClickCallback();
          }
        }}
        {...testId(ID, `${info}${BTN}`)}
      />
    );
  }
  if (additionalInfoActivated() && !!children) {
    return (
      <AdditionalInfoWrapper
        onClick={() => handleClick(getProps(t, info, profile, active))}
        {...testId(ID, `${info}${BTN}`)}
      >
        {children}
      </AdditionalInfoWrapper>
    );
  }

  return <></>;
};
