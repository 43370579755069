export const mockNextShipmentFetchData = {
  resultDescription: 'getDeliveryProgramStatusOK',
  model: {
    shipDateString: '23/12/2019',
    packagingFormat: null,
    patientProfile: null,
    shipment: {
      id: 297197,
      shipCode: null,
      shipDate: '2019-12-23',
      shipStatus: 'ESTIMATED',
      shipQtty: 4,
      patient: {
        timeZone: null,
        clinicalRecordNumber: null,
        healthCareSystemId: '1234567',
        diagnosticDate: null,
        expectedDeliverDate: null,
        nursingHome: false,
        pregnant: false,
        grantRevokeFunctDTO: {
          grantFunctionalities: ['ROLE_OPEN_CONNECTIVITY_DATA_DOWN'],
          revokeFunctionalities: [
            'ROLE_MYSUGR_DATA_DOWN',
            'ROLE_MYSUGR_INVITATION_PAT',
          ],
        },
        diabetesType: 'DIABETES_TYPE2',
        downloadNoticePeriod: 'THIRTY',
        professionalList: [94918],
        user: {
          name: 'active home delivery',
          surname: 'New UI',
          surname2: null,
          phone: null,
          email: 'activeHome@yopmail.com',
          languageId: 3,
          departmentProfileId: 30427,
          birthday: null,
          gender: 'UNSPECIFIED',
          address: {
            address: '1',
            city: '1',
            province: '1',
            postalCode: '1',
            country: {
              id: 1,
            },
          },
          hcpIsAccessible: true,
        },
      },
      supply: {
        id: 3,
        deviceModel: {
          id: 6483,
          name: 'Accu-Chek Aviva',
          description: null,
          brandName: 'ACCU-CHEK',
          brand: null,
          presentationFormat: null,
          materialType: null,
          modelId: 10606,
          reference: null,
          formatLabel: null,
          modelCountryName: 'Accu-Chek Aviva',
        },
        value: 'Accu-Chek Aviva',
        key: '10606',
      },
    },
  },
};

export const mockNextShipmentFetchDataTransformed = {
  id: 297197,
  shipDate: '2019-12-23',
  shipStatus: 'ESTIMATED',
  shipQtty: 4,
  resultDescription: 'getDeliveryProgramStatusOK',
};

export const mockNextShipmentNoDeliveryProgram = {
  resultDescription: 'getDeliveryProgramStatusERROR',
  error: [
    {
      paramName: 'patientId',
      expectedFormat: 'Shipment not found for that patient',
    },
  ],
};
