import { mockHba1cList } from './hba1c-list.mock';
import {
  GetHba1cListLoaderImpl,
  GetHba1cListServiceImpl,
  GetHba1cListTransformImpl,
} from './hba1c-list.service';

const mockListHba1c = () => Promise.resolve(mockHba1cList);

export const GetHba1cListFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockListHba1c : GetHba1cListLoaderImpl;
  return GetHba1cListServiceImpl(loader, GetHba1cListTransformImpl);
};
