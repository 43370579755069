import { Action, PayloadAction } from 'src/app/store/app.types';
import { checkPairingCodeTransformedResponseType } from 'src/services/pairing-code/pairing-code-check/pairing-code-check.types';

export enum CheckPairingCodeActionType {
  CHECK_PAIRING_CODE_START = 'CHECK_PAIRING_CODE_START',
  CHECK_PAIRING_CODE_SUCCESS = 'CHECK_PAIRING_CODE_SUCCESS',
  CHECK_PAIRING_CODE_ERROR = 'CHECK_PAIRING_CODE_ERROR',
  CONFIRM_POPULATE_FORM = 'CONFIRM_POPULATE_FORM',
  CLEAR_PAIRING_CODE_DATA = 'CLEAR_PAIRING_CODE_DATA',
}

export type CheckPairingCodeError = {
  errorCode: string;
  status: number;
  statusText: string;
  url: string;
};

export type CheckPairingCodeSuccessPayload =
  checkPairingCodeTransformedResponseType;

export type CheckPairingCodeStartAction = PayloadAction<
  CheckPairingCodeActionType.CHECK_PAIRING_CODE_START,
  string
>;

export type CheckPairingCodeSuccessAction = PayloadAction<
  CheckPairingCodeActionType.CHECK_PAIRING_CODE_SUCCESS,
  checkPairingCodeTransformedResponseType
>;

export type CheckPairingCodeErrorAction = PayloadAction<
  CheckPairingCodeActionType.CHECK_PAIRING_CODE_ERROR,
  CheckPairingCodeError
>;

export type ConfirmPopulateFormAction =
  Action<CheckPairingCodeActionType.CONFIRM_POPULATE_FORM>;

export type ClearPairingCodeDataAction =
  Action<CheckPairingCodeActionType.CLEAR_PAIRING_CODE_DATA>;

export type CheckPairingCodeActions =
  | CheckPairingCodeStartAction
  | CheckPairingCodeSuccessAction
  | CheckPairingCodeErrorAction
  | ConfirmPopulateFormAction
  | ClearPairingCodeDataAction;

export type CheckPairingCodeState = {
  isCheckingPairingCode: boolean;
  checkWasSuccessful: boolean;
  checkHasError: boolean;
  isPopulationConfirmed: boolean;
  isPopulationCleared: boolean;
  error: CheckPairingCodeError | null;
  patientData: checkPairingCodeTransformedResponseType | {};
};
