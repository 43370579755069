import { checkPairingCodeResponseType } from './pairing-code-check.types';

export const pairingCodeCheckMockResponse: checkPairingCodeResponseType = {
  clinicalRecordNumber: '000',
  diabetesType: 'DIABETES_TYPE1',
  diagnosticDate: '12/03/2002',
  downloadNoticePeriod: 'string',
  expectedDeliverDate: 'string',
  grantRevokeFunctDTO: {
    grantFunctionalities: [],
    revokeFunctionalities: ['ROLE_OPEN_CONNECTIVITY_DATA_DOWN'],
  },
  healthCareSystemId: '123456789',
  nursingHome: true,
  patientInsStatus: {
    insStatus: 'none',
    proofOfIdentity: 'none',
    validatedStatusDate: 'none',
  },
  pregnant: false,
  professionalList: [259603, 2293008, 2303073, 2360424],
  timeZone: 'Europe/Madrid',
  user: {
    address: {
      address: 'Calle Cualquiera, 3',
      city: 'Madrid',
      country: {
        id: 1,
      },
      postalCode: '28011',
      province: 'Madrid',
    },
    birthday: '1988-01-01',
    departmentProfileId: 2292612,
    email: 'test.patient.pairing@yopmail.com',
    fhirId: 'C8jaAPp-Smf-3222',
    gender: 'unspecified',
    hcpIsAccessible: true,
    languageId: 21,
    name: 'John',
    phone: '123456789',
    surname: 'Doe',
    surname2: '',
    unknownBirthdate: false,
    userAdditionalInfo: {
      birthNames: '',
      birthPlaceCode: '99999',
      usedName: '',
      usedSurnames: '',
    },
  },
};
