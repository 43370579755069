import {
  SendInvitationActions,
  SendInvitationActionType,
  SendInvitationState,
} from './send-invitation.actions.types';

export const sendInvitationInitialState: SendInvitationState = {
  isSendingInvitation: false,
  invitationWasSuccessful: false,
  invitationHasError: false,
};

export const sendInvitationReducer = (
  state = sendInvitationInitialState,
  action: SendInvitationActions,
) => {
  switch (action.type) {
    case SendInvitationActionType.SEND_INVITATION_START:
      return {
        ...state,
        isSendingInvitation: true,
        invitationWasSuccessful: false,
        invitationHasError: false,
      };
    case SendInvitationActionType.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        isSendingInvitation: false,
        invitationWasSuccessful: true,
        invitationHasError: false,
      };
    case SendInvitationActionType.SEND_INVITATION_ERROR:
      return {
        ...state,
        isSendingInvitation: false,
        invitationWasSuccessful: false,
        invitationHasError: true,
      };
    case SendInvitationActionType.RESET_INVITATION:
      return sendInvitationInitialState;
    default:
      return state;
  }
};
