export const mockGetTargetRanges = {
  resultDescription: 'getThresholdOK',
  model: {
    unit: 'MGDL',
    preHyper: '240',
    preHigh: '125',
    preLow: '80',
    preHipo: '70',
    postHyper: '240',
    postHigh: '95',
    postLow: '80',
    postHipo: '70',
    noctHyper: '240',
    noctHigh: '95',
    noctLow: '80',
    noctHipo: '70',
    nonMealHyper: '250',
    nonMealHigh: '180',
    nonMealLow: '70',
    nonMealHipo: '54',
  },
};

export const mockGetTargetRangesTransformed = {
  unit: 'mg/dL',
  beforeEating: {
    actualHyper: '240',
    hyper: '125',
    hypo: '70',
    warning: '80',
  },
  afterEating: {
    actualHyper: '240',
    hyper: '95',
    hypo: '70',
    warning: '80',
  },
  beforeBed: {
    actualHyper: '240',
    hyper: '95',
    hypo: '70',
    warning: '80',
  },
  nonMealRelated: {
    actualHyper: '250',
    hyper: '180',
    inTarget: '',
    warning: '70',
    hypo: '54',
  },
};
