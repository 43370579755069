import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

// @ts-ignore
import BGImage from 'src/assets/images/bg.jpg';
import { Block, BlockNavigation } from 'src/components/block/block.component';
import { TopNav } from 'src/widgets/top-navigation/top-navigation.style';
import { spacing } from 'src/core/styles/spacing';
import { TopNavigation } from 'src/widgets/top-navigation/top-navigation.component';
import { TopNavigationJello } from 'src/widgets/top-navigation-jello/top-navigation-jello.container';
import { TopNavigationHeight } from './utils/navigation.utils';

import { PERMISSIONS } from 'src/core/permissions/permissions.constants';
import { CountlyLoader } from './countly-loader';

const AppWrapper = styled.div`
  background-position: center center;
  background-size: cover;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
AppWrapper.displayName = 'AppWrapper';

// tslint:disable:no-unused-expression
const GlobalNavigationStyle = createGlobalStyle`
  body {
    &.no-nav {
      ${TopNav} {
        display: none;
      }
    }
    &._${TopNavigationHeight.REGULAR} {
      ${TopNav} {
        height: ${TopNavigationHeight.REGULAR}px;
      }
    }
    &._${TopNavigationHeight.INTERMEDIATE} {
      ${TopNav} {
        height: ${TopNavigationHeight.INTERMEDIATE}px;
      }
    }
    &.bg-image {
      ${AppWrapper} {
        background: var(--jello-color-background-alternative);
      }
    }
    &.no-m {
      ${AppWrapper} > ${Block} {
        margin: 0;
      }
    }
  }
`;

type NavWrapperPropsTypes = {
  gigyaUID: string;
  permissions: any;
  routes: any;
  goTo: (path: string) => void;
  children: React.ReactNode;
  isJelloActivated: boolean;
};

export const NavigatorWrapper: React.FunctionComponent<
  NavWrapperPropsTypes
> = ({ goTo, routes, gigyaUID, permissions, isJelloActivated, children }) => {
  useEffect(() => {
    if (
      permissions.includes(PERMISSIONS.PAYER) &&
      permissions.includes(PERMISSIONS.PAYER_DASHBOARD) &&
      location.pathname !== routes.payerPortal.main &&
      location.pathname !== routes.profile.changePassword &&
      location.pathname !== routes.profile.editProfile
    ) {
      goTo(routes.payerPortal.main);
      return;
    }
  }, [permissions]);

  return (
    <React.Fragment>
      <GlobalNavigationStyle />
      <AppWrapper>
        {isJelloActivated ? <TopNavigationJello /> : <TopNavigation />}
        <BlockNavigation
          ml={isJelloActivated ? 0 : spacing.three}
          mr={isJelloActivated ? 0 : spacing.three}
        >
          {children}
        </BlockNavigation>
      </AppWrapper>

      <CountlyLoader permissions={permissions} gigyaUID={gigyaUID} />
    </React.Fragment>
  );
};
