import { Errors as RRFErrors } from 'react-redux-form';
import styled from 'styled-components';
import {
  alignItems,
  alignSelf,
  color,
  flex,
  flexWrap,
  fontSize,
  justifyContent,
  space,
  width,
} from 'styled-system';

export const ControlError = styled(RRFErrors)`
  ${space};
  ${width};
  ${flex};
  ${flexWrap};
  ${justifyContent};
  ${alignItems};
  ${alignSelf};
  ${color};
  ${fontSize};
`;
