/* tslint:disable */

export type TablePrimaryProps = {
  bodyData: TableStructure[];
  headerData: TableStructure[];
  rowStyles?: RowStyles;
  cellStyles?: CellStyles;
};

export type TableStructure = {
  type?: HeaderRowType | BodyRowType;
  data: TableData[];
};

export type TableData = {
  value: string;
  valueHighlighted?: string;
  colSpan?: number;
  icon?: React.ReactNode;
};

export type TablePrimaryCellProps = {
  value: string;
  valueHighlighted?: string;
  unit?: string;
  id: number;
  colSpan?: number;
  highlight?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  semiBold?: boolean;
  cellWidth?: number;
};

export type TablePrimaryRowsProps = {
  headerRowsList: TableStructure[];
  rowStyles?: RowStyles;
  cellStyles?: CellStyles;
};

export enum HeaderRowType {
  Basic = 'HEADER_BASIC',
  Extended = 'HEADER_EXTENDED',
  Empty = 'HEADER_EMPTY',
}

export enum BodyRowType {
  Basic = 'BODY_BASIC',
}

type RowStyles = {
  [key in HeaderRowType]?: RowStylesItem;
};

type CellStyles = {
  [key in HeaderRowType]?: CellStylesItem;
};

type CellStylesItem = {
  semiBold?: boolean;
};

type RowStylesItem = {
  paddingBottom?: string;
  borderBottom?: boolean;
  backgroundColor?: string;
};
