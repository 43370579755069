import { Action, PayloadAction } from 'src/app/store/app.types';
import { Diff } from 'src/utils/typescript';

export enum SessionActionType {
  VALIDATE_SESSION_START = 'VALIDATE_SESSION_START',
  VALIDATE_SESSION_ERROR = 'VALIDATE_SESSION_ERROR',
  VALIDATE_SESSION_SUCCESS = 'VALIDATE_SESSION_SUCCESS',
  SIGN_OUT_START = 'SIGN_OUT_START',
  SIGN_OUT_AFTER_REFRESH_START = 'SIGN_OUT_AFTER_REFRESH_START',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
  SIGN_OUT_ERROR = 'SIGN_OUT_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_START = 'LOGIN_START',
  LOGIN_REDIRECT = 'LOGIN_REDIRECT',
  SET_SUCCESS_REDIRECT = 'SET_SUCCESS_REDIRECT',
  COME_FROM_LOGIN = 'COME_FROM_LOGIN',
  OIDC_FETCH_TOKENS_SUCCESS = 'OIDC_FETCH_TOKENS_SUCCESS',
  REFRESH_OIDC_TOKENS_SUCCESS = 'REFRESH_OIDC_TOKENS_SUCCESS',
  SHOW_EXPIRING_MODAL = 'SHOW_EXPIRING_MODAL',
  GET_CURRENT_USER_START = 'GET_CURRENT_USER_START',
  GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR',
}

type FHIRUserType = 'Patient' | 'Professional' | 'Practitioner' | '';

type BooleanString = 'true' | 'false';

export type SessionState = {
  readonly legacyToken: string;
  readonly error: any;
  readonly expiration: any;
  readonly ttlLength: number;
  readonly accessToken: string;
  readonly refreshToken: string;
  readonly isSignedOut: boolean;
  readonly isValidSession: boolean;
  readonly successRedirect: string;
  readonly comeFromLogin: boolean;
  readonly isGettingToken: boolean;
  readonly isSigningout: boolean;
};

export type PhoneNumber = {
  number: string;
  type?: string;
};

export type AccountInfoProfile = {
  firstName: string;
  lastName: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  email: string;
  gender: string;
  languages: string;
  photoURL: string;
  phones: PhoneNumber[];
};

export type AccountInfoData = {
  HCPIsActive: BooleanString | boolean;
  HCPIsAccessible: BooleanString | boolean;
  billAddressCity: string;
  billAddressCountry: string;
  billAddressCountryCode: string;
  billAddressProvince: string;
  homeCountryCode: string;
  Cypher_Cloud_Gateway: string;
  billAddressStreet: string;
  nationalHealthSystem: string;
  SFDCaccountID: string;
  HCP_ID: string;
  billAddressPostalCode: string;
  homeCountry: string;
  nationalHealthNumber: string;
  FHIR_UserType: FHIRUserType;
};

export type AccountInfo = {
  UID: string;
  errorCode: string | number;
  data: AccountInfoData;
  profile: AccountInfoProfile;
};

export type SessionValidationErrorPayload = {
  account?: AccountInfo;
  error: string;
};

export type ValidateSessionStartAction =
  Action<SessionActionType.VALIDATE_SESSION_START>;
export type ValidateSessionSuccessAction = PayloadAction<
  SessionActionType.VALIDATE_SESSION_SUCCESS,
  any
>;
export type ValidateSessionErrorAction = PayloadAction<
  SessionActionType.VALIDATE_SESSION_ERROR,
  any
>;
export type SetSuccessRedirectAction = PayloadAction<
  SessionActionType.SET_SUCCESS_REDIRECT,
  string
>;
export type SignoutStartAction = Action<SessionActionType.SIGN_OUT_START>;
export type SignoutAfterRefreshStartAction =
  Action<SessionActionType.SIGN_OUT_AFTER_REFRESH_START>;
export type SignoutSuccessAction = Action<SessionActionType.SIGN_OUT_SUCCESS>;
export type SignoutErrorAction = Action<SessionActionType.SIGN_OUT_ERROR>;
export type ShowExpiringModalAction =
  Action<SessionActionType.SHOW_EXPIRING_MODAL>;
export type LoginSuccessAction = PayloadAction<
  SessionActionType.LOGIN_SUCCESS,
  string
>;
export type LoginStartAction = Action<SessionActionType.LOGIN_START>;

export type LoginRedirectAction = Action<SessionActionType.LOGIN_REDIRECT>;

export type ComeFromLoginAction = Action<SessionActionType.COME_FROM_LOGIN>;

export type SessionEpicOnlyActions =
  | ValidateSessionStartAction
  | LoginStartAction
  | SignoutStartAction;

export type SessionActions =
  | SessionEpicOnlyActions
  | ValidateSessionSuccessAction
  | ValidateSessionErrorAction
  | SignoutStartAction
  | SignoutSuccessAction
  | LoginSuccessAction
  | SignoutErrorAction
  | SetSuccessRedirectAction
  | ComeFromLoginAction;

export type SessionReducerActions = Diff<
  SessionActions,
  SessionEpicOnlyActions
>;
