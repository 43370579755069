import {
  CurrentUserLoaderImpl,
  CurrentUserServiceImpl,
  CurrentUserTransformImpl,
} from './current-user.service';
import { mockCurrentUser } from './current-user.mock.js';

const mockLoader = () => Promise.resolve(mockCurrentUser);

export const CurrentUserFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : CurrentUserLoaderImpl;
  return CurrentUserServiceImpl(loader, CurrentUserTransformImpl);
};
