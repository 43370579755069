/* tslint:disable */

import {
  FETCH_DEVICE_SETTINGS_REQUEST,
  fetchAllDevicesActionType,
} from './device-settings.constant';
import { FetchStatus } from './device-settings.types';

export const INITIAL_DEV_SETTINGS_STATE: any = {
  status: FetchStatus.NONE,
  statusAllData: FetchStatus.NONE,
  settings: [],
  error: '',
};

export const deviceSettingsReducer = (
  state = INITIAL_DEV_SETTINGS_STATE,
  action: any,
): any => {
  switch (action.type) {
    case FETCH_DEVICE_SETTINGS_REQUEST.START: {
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    }

    case FETCH_DEVICE_SETTINGS_REQUEST.SUCCESS: {
      return {
        ...state,
        settings: action.payload,
        status: FetchStatus.SUCCESS,
      };
    }

    case FETCH_DEVICE_SETTINGS_REQUEST.ERROR:
      return {
        ...state,
        error: action.payload,
        status: FetchStatus.FAILURE,
      };

    case fetchAllDevicesActionType.FETCH_BASE:
      return {
        ...state,
        statusAllData: FetchStatus.NONE,
      };

    case fetchAllDevicesActionType.FETCH_START:
      return {
        ...state,
        statusAllData: FetchStatus.LOADING,
      };

    case fetchAllDevicesActionType.FETCH_SUCCESS:
      return {
        ...state,
        statusAllData: FetchStatus.SUCCESS,
      };

    case fetchAllDevicesActionType.FETCH_ERROR:
      return {
        ...state,
        statusAllData: FetchStatus.FAILURE,
      };

    default:
      return state;
  }
};
