export const mockPermissions = [
  'ROLE_PROFESSIONAL',
  'ROLE_STRIP_MNG_VIEW_TRAFFIC_LIGHT',
  'ROLE_STRIP_MNG_DELIVERY',
  'ROLE_STRIP_MNG_STOCK',
  'ROLE_STRIP_MNG_ALERTS_CONFIG',
  'ROLE_STRIP_MNG_FORCE_TRAFFIC_LIGHT',
  'ROLE_STRIP_MNG_STRIP_PRESCRIPTION',
  'ROLE_DATA_DOWNLOAD',
  'ROLE_PATIENT_MANAGEMENT_CREATE',
  'ROLE_PATIENT_MANAGEMENT_EDIT',
  'ROLE_PATIENT_MANAGEMENT_LIST',
  'ROLE_PROFESSIONAL_MANAGEMENT_EDIT',
  'ROLE_PROFESSIONAL_MANAGEMENT_LIST',
  'ROLE_USER_SETTINGS_GRAPH',
  'ROLE_USER_SETTINGS_OVERVIEW',
  'ROLE_USER_SETTINGS_REPORT',
  'ROLE_USER_SETTINGS_CHANGE_PASSWORD',
  'ROLE_USER_SETTINGS_EXPORT_DATA',
  'ROLE_USER_SETTINGS_CONFIGURE_PATTERN',
  'ROLE_USER_SETTINGS_CHANGE_SECURITY_QUESTION',
  'ROLE_USER_MANAGEMENT_RESET_PASSWORD',
  'ROLE_VIEW_PATIENT_DEVICES',
  'ROLE_DEACTIVATE_PATIENT_DEVICES',
  'ROLE_DATA_DOWNLOAD_ASSIGNMENT',
  'ROLE_PATIENT_MANAGEMENT_DELETE',
  'ROLE_PROFESSIONAL_MANAGEMENT_CREATE',
  'ROLE_PROFESSIONAL_MANAGEMENT_DELETE',
  'ROLE_PROFESSIONAL_MANAGEMENT_EDIT_ALL',
  'ROLE_PATIENT_HISTORICAL_DEVICE_DATA_LIST',
  'ROLE_RELEVANT_CLINICAL_DATA',
  'ROLE_TIME_BLOCKS_MANAGEMENT',
  'ROLE_USER_SHOW_GRAPHIC_CONFIGURATION',
  'ROLE_USER_SHOW_LOGBOOK_CHART',
  'ROLE_USER_MANAGEMENT_REACTIVATE',
  'ROLE_TREATMENT_LIST',
  'ROLE_TREATMENT_CREATE',
  'ROLE_TREATMENT_EDIT',
  'ROLE_TREATMENT_COPY',
  'ROLE_TREATMENT_VIEW',
  'ROLE_THERAPY_ADD',
  'ROLE_THERAPY_REMOVE',
  'ROLE_TREATMENT_GRAPH',
  'ROLE_CONFIG_PATTERNS_ADVANCED_INDICATORS',
  'ROLE_RPM_PROGRAM',
  'ROLE_RPM_PROGRAM_PROF',
  'ROLE_TIME_TRACKING',
  'ROLE_PATIENT_MANAGEMENT_HOME',
  'ROLE_COUNTRY_FR',
  'ROLE_THERAPY',
  'ROLE_DEVICE_LINK',
];
