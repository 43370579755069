import {
  ClinicalDataFromFhirReponseType,
  GetClinicalDataFromFhirTranformedResponseType,
} from './clinical-data-from-fhir.types';

export const mockClinicalDataFromFhir: ClinicalDataFromFhirReponseType = {
  resultDescription: 'getClinicalDataOK',
  model: {
    glucoseValues: [
      {
        glucose: 117.0,
        date: '2016-11-30T06:23:33Z',
        carbohydrates: null,
        insulin1: null,
        insulin2: null,
        insulin3: null,
        flagAboveTargetRange: false,
        flagBelowTargetRange: false,
        flagUsedDrum: false,
        flagExpiredStrip: false,
        flagManuallyEntered: false,
        flagTemperatureWarning: false,
        beforeMeal: true,
        afterMeal: false,
        mealSizeNoMeal: false,
        mealSizeSmall: false,
        mealSizeMedium: false,
        mealSizeLarge: false,
        metadata: null,
        control: false,
        controlType: null,
        hypoSymptoms: true,
        events: null,
        fType: null,
        fEvt: null,
        serialNumber: '000030053',
        specialMeasurement: false,
        advicedBolus: null,
        glucoseUnit: 'mg/dL',
      },
      {
        glucose: 57.0,
        date: '2016-11-30T11:23:33Z',
        carbohydrates: null,
        insulin1: null,
        insulin2: null,
        insulin3: null,
        flagAboveTargetRange: false,
        flagBelowTargetRange: true,
        flagUsedDrum: false,
        flagExpiredStrip: false,
        flagManuallyEntered: false,
        flagTemperatureWarning: false,
        beforeMeal: false,
        afterMeal: false,
        mealSizeNoMeal: false,
        mealSizeSmall: false,
        mealSizeMedium: false,
        mealSizeLarge: false,
        metadata: null,
        control: false,
        controlType: null,
        hypoSymptoms: true,
        events: null,
        fType: null,
        fEvt: null,
        serialNumber: '000030053',
        specialMeasurement: false,
        advicedBolus: null,
        glucoseUnit: 'mg/dL',
      },
      {
        glucose: 157.0,
        date: '2016-11-30T16:23:33Z',
        carbohydrates: null,
        insulin1: null,
        insulin2: null,
        insulin3: null,
        flagAboveTargetRange: true,
        flagBelowTargetRange: false,
        flagUsedDrum: false,
        flagExpiredStrip: false,
        flagManuallyEntered: false,
        flagTemperatureWarning: false,
        beforeMeal: true,
        afterMeal: false,
        mealSizeNoMeal: false,
        mealSizeSmall: false,
        mealSizeMedium: false,
        mealSizeLarge: false,
        metadata: null,
        control: false,
        controlType: null,
        hypoSymptoms: true,
        events: null,
        fType: null,
        fEvt: null,
        serialNumber: '000030053',
        specialMeasurement: false,
        advicedBolus: null,
        glucoseUnit: 'mg/dL',
      },
    ],
    insulinPump: {
      name: null,
      serialNumber: null,
      pumpDate: null,
      restUseDays: null,
      activeProfile: null,
      ipProfile: [],
    },
    newData: {
      bolus: [
        {
          date: '2018-02-14T16:01:00+0000',
          value: 0,
          remark: 'Bolus Total',
          registerType: 'BolusTotal',
          bolusType: null,
          cmdType: null,
        },
        {
          date: '2018-02-18T09:38:00+0000',
          value: 17.6,
          remark: 'Bolus+Basal Total',
          registerType: 'BolusPlusBasalTotal',
          bolusType: null,
          cmdType: null,
        },
        {
          date: '2018-02-27T17:13:00+0000',
          value: 2,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
          cmdType: null,
        },
        {
          date: '2018-02-28T00:00:00+0000',
          value: 8,
          remark: 'Bolus Total',
          registerType: 'BolusTotal',
          bolusType: null,
          cmdType: null,
        },
        {
          date: '2018-02-28T08:17:00+0000',
          value: 1.5,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
          cmdType: null,
        },
        {
          date: '2018-02-28T13:00:00+0000',
          value: 3.5,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
          cmdType: null,
        },
        {
          date: '2018-02-28T18:00:00+0000',
          value: 3,
          remark: '1.00 / 3.00  1:58 h',
          registerType: 'Bolus',
          bolusType: 'Mul',
          cmdType: null,
        },
      ],
      basals: [
        {
          date: '2018-02-14T16:01:00+0000',
          cbrf: 1.1,
          profile: '1',
          cmd: null,
          remark: null,
          virtual: null,
          tsb: null,
          tsbDiffMins: null,
          tbrdec: null,
          tbrinc: null,
        },
        {
          date: '2018-02-18T09:38:00+0000',
          cbrf: 0.7,
          profile: '1',
          cmd: null,
          remark: null,
          virtual: null,
          tsb: null,
          tsbDiffMins: null,
          tbrdec: null,
          tbrinc: null,
        },
        {
          date: '2018-02-22T00:40:00+0000',
          cbrf: 0.9,
          profile: '1',
          cmd: null,
          remark: null,
          virtual: null,
          tsb: null,
          tsbDiffMins: null,
          tbrdec: null,
          tbrinc: null,
        },
      ],
      events: [],
      lastEventDate: null,
      lastBasalDate: null,
      lastBolusDate: null,
    },
    removedData: {
      bolus: [],
      basals: [],
      events: [],
      lastEventDate: null,
      lastBasalDate: null,
      lastBolusDate: null,
    },
    modelDevice: null,
    lastGlucoseDate: null,
    info: 'Gluco Values : 59\nNew Data - Bolus: 0\nNew Data - Basal: 0\nNew Data - Events: 0',
    pumpData: false,
    unit: 'mg/dL',
  },
};

export const mockTransformedClinicalData: GetClinicalDataFromFhirTranformedResponseType =
  {
    measurements: [
      {
        aboveTargetRange: false,
        afterMeal: false,
        beforeMeal: true,
        belowTargetRange: false,
        carbohydrates: null,
        control: false,
        date: new Date(
          'Mon Nov 22 2021 12:59:00 GMT+0100 (Central European Standard Time)',
        ),
        events: '29, 31',
        hypoSymptoms: true,
        insulin1: null,
        insulin2: null,
        insulin3: null,
        manuallyEntered: false,
        value: 117.0,
        advicedBolus: null,
        bloodGlucoseUnit: 'mg/dL',
        rangeType: null,
      },
      {
        aboveTargetRange: false,
        afterMeal: false,
        beforeMeal: false,
        belowTargetRange: true,
        carbohydrates: null,
        control: false,
        date: new Date(
          'Mon Nov 22 2021 12:59:00 GMT+0100 (Central European Standard Time)',
        ),
        events: null,
        hypoSymptoms: true,
        insulin1: null,
        insulin2: null,
        insulin3: null,
        manuallyEntered: false,
        value: 57.0,
        rangeType: null,
        advicedBolus: {
          bolusType: 'Scr',
          confirmed: { bgIU: 1, carbIU: 0.65, totalIU: 0.65 },
          recommended: { bgIU: 1, carbIU: 0.65, totalIU: 1.65 },
          selected: { bgIU: 1, carbIU: 0.65, totalIU: 0.65 },
        },
        bloodGlucoseUnit: 'mg/dL',
      },
      {
        aboveTargetRange: true,
        afterMeal: false,
        beforeMeal: true,
        belowTargetRange: false,
        carbohydrates: null,
        control: false,
        date: new Date(
          'Mon Nov 22 2021 12:59:00 GMT+0100 (Central European Standard Time)',
        ),
        events: null,
        hypoSymptoms: true,
        insulin1: null,
        insulin2: null,
        insulin3: null,
        manuallyEntered: false,
        value: 157.0,
        rangeType: null,
        advicedBolus: null,
        bloodGlucoseUnit: 'mg/dL',
      },
    ],
    insulin: {
      bolus: [
        {
          date: new Date('2018-02-14T16:01:00+0000'),
          value: 0,
          remark: 'Bolus Total',
          registerType: 'BolusTotal',
          bolusType: null,
        },
        {
          date: new Date('2018-02-18T09:38:00+0000'),
          value: 17.6,
          remark: 'Bolus+Basal Total',
          registerType: 'BolusPlusBasalTotal',
          bolusType: null,
        },
        {
          date: new Date('2018-02-27T17:13:00+0000'),
          value: 2,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
        },
        {
          date: new Date('2018-02-28T00:00:00+0000'),
          value: 8,
          remark: 'Bolus Total',
          registerType: 'BolusTotal',
          bolusType: null,
        },
        {
          date: new Date('2018-02-28T08:17:00+0000'),
          value: 1.5,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
        },
        {
          date: new Date('2018-02-28T13:00:00+0000'),
          value: 3.5,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
        },
        {
          date: new Date('2018-02-28T18:00:00+0000'),
          value: 3,
          remark: '1.00 / 3.00  1:58 h',
          registerType: 'Bolus',
          bolusType: 'Mul',
        },
      ],
      basals: [
        {
          date: new Date('2018-02-14T16:01:00+0000'),
          cbrf: 1.1,
          profile: '1',
          remark: null,
          tbrdec: null,
          tbrinc: null,
          tsb: null,
          tsbDiffMins: null,
          virtual: null,
        },
        {
          date: new Date('2018-02-18T09:38:00+0000'),
          cbrf: 0.7,
          profile: '1',
          remark: null,
          tbrdec: null,
          tbrinc: null,
          tsb: null,
          tsbDiffMins: null,
          virtual: null,
        },
        {
          date: new Date('2018-02-22T00:40:00+0000'),
          cbrf: 0.9,
          profile: '1',
          remark: null,
          tbrdec: null,
          tbrinc: null,
          tsb: null,
          tsbDiffMins: null,
          virtual: null,
        },
      ],
    },
  };
