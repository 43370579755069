import * as React from 'react'; // importing FunctionComponent
import { TabsContent, TabContent } from './tabs.style';
import { TabsData, TabsProps, TabsState } from './tabs.types';

export class Tabs extends React.Component<TabsProps, TabsState> {
  constructor(props) {
    super(props);

    const data = props.data || [];
    let active = props.active;
    if (!active) {
      active = data.length > 0 ? data[0].key : '';
    }
    this.state = {
      active,
    };
  }

  public handleOnClick(tab: TabsData) {
    this.setState({
      active: tab.key,
    });
    if (this.props.onChange) {
      this.props.onChange(tab);
    }
  }

  public render() {
    const data: TabsData[] = this.props.data || [];
    return (
      <TabsContent>
        {data.map((tab) => {
          const TabComponent = tab.component;
          return (
            <TabContent
              className={tab.key === this.state.active ? 'active' : ''}
              key={tab.key}
              onClick={() => this.handleOnClick(tab)}
            >
              {TabComponent ? <TabComponent /> : tab.label}
            </TabContent>
          );
        })}
      </TabsContent>
    );
  }
}
