import { path } from 'ramda';

export const selectDeviceSettingsList = path([
  'deviceSettingsList',
  'settings',
]);

export const selectDeviceSettingsListStatus = path([
  'deviceSettingsList',
  'status',
]);
