import {
  ClinicalStatisticsLoaderImpl,
  ClinicalStatisticsServiceImpl,
  ClinicalStatisticsTransformImpl,
} from './clinical-statistics.service';
import { mock } from './clinical-statistics.mock';
import { mockCgm } from './clinical-statistics-cgm.mock';

const mockLoader = ({ isBg }): Promise<any> =>
  new Promise((resolve, reject) =>
    setTimeout(() => (isBg ? resolve(mock) : resolve(mockCgm)), 4000),
  );

export const ClinicalStatisticsFactoryImpl = ({ devMode, isBg }) => {
  const loader = devMode ? mockLoader : ClinicalStatisticsLoaderImpl;
  return ClinicalStatisticsServiceImpl(loader, ClinicalStatisticsTransformImpl);
};
