import { createRequestActionTypes } from '../request/request.actions';

export const PATIENT_ACTIONS = {
  START_PATIENT_SESSION: 'START_PATIENT_SESSION',
  RESET_PATIENT_SESSION: 'RESET_PATIENT_SESSION',
  RESET_IS_RUNNING: 'RESET_IS_RUNNING',
  CLEAR_SESSION_STATUS: 'CLEAR_SESSION_STATUS',
};

export const SAVE_PATIENT_SESSION = 'PATIENT_SESSION/SAVE';

export const SAVE_PATIENT_SESSION_REQUEST =
  createRequestActionTypes(SAVE_PATIENT_SESSION);

export const EXPORT_PATIENT_SESSION = 'PATIENT_SESSION/EXPORT';

export const EXPORT_PATIENT_SESSION_REQUEST = createRequestActionTypes(
  EXPORT_PATIENT_SESSION,
);

export const START_PATIENT_SESSION = 'END_PATIENT_SESSION/SAVE';

export const START_PATIENT_SESSION_REQUEST = createRequestActionTypes(
  START_PATIENT_SESSION,
);

export const CLEAR_PATIENT_EXPORT_DATA = 'CLEAN_PATIENT_EXPORT_DATA';

export enum FetchStatus {
  LOADING = 'loading',
  FAILURE = 'failure',
  SUCCESS = 'success',
  NONE = 'none',
}
