import { DateTime } from 'luxon';

export const getDaysLastSync = (dateLastSync) =>
  Math.abs(
    Math.trunc(
      Number(DateTime.fromISO(dateLastSync).diffNow('days').toObject().days),
    ),
  );

export const getHourLastSync = (dateLastSync, is12hourTimeFormat) =>
  is12hourTimeFormat
    ? DateTime.fromISO(dateLastSync)
        .toFormat('h:mm a', { locale: 'us' })
        .toUpperCase()
    : DateTime.fromISO(dateLastSync).toFormat('HH:mm');
