import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import { State } from 'src/app/store/app.types';
import { createModal } from 'src/core/modal/modal.actions';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';
import { ListDevicesLoaderImplType } from 'src/services/patient/list-devices/list-devices.types';
import { RemoveDeviceLoaderImplType } from 'src/services/patient/remove-device/remove-device.types';
import { ListDevicesActionType } from './list-devices.types';
import {
  fetchDevicesStart,
  fetchDevicesSuccess,
  fetchDevicesError,
} from './list-devices.action';

export const fetchPatientDevicesEpic: (
  service: ListDevicesLoaderImplType,
) => Epic<any, State> = (service) => (action$, store) =>
  action$
    .ofType(ListDevicesActionType.FETCH_DEVICES_START)
    .switchMap((action) => {
      const openId = {
        accessToken: selectAccessToken(store.getState()),
        gigyaToken: selectGigyaToken(store.getState()),
      };
      const patientId = action.payload;
      return Observable.fromPromise(service(openId, patientId))
        .map((devices) => fetchDevicesSuccess(devices))
        .pipe(catchError((err) => Observable.of(fetchDevicesError())));
    });

export const removePatientDeviceEpic: (
  service: RemoveDeviceLoaderImplType,
) => Epic<any, State> = (service) => (action$, store) =>
  action$
    .ofType(ListDevicesActionType.REMOVE_DEVICE_START)
    .switchMap((action) => {
      const accessToken = selectAccessToken(store.getState());
      const gigyaToken = selectGigyaToken(store.getState());
      const { patientId, deviceId, errorComponent, successComponent } =
        action.payload;
      return Observable.fromPromise(
        service(accessToken, gigyaToken, patientId, deviceId),
      )
        .mergeMap(() => [
          fetchDevicesStart(patientId),
          createModal({
            key: MODAL_TYPES.CUSTOM,
            data: {
              customComponent: successComponent,
            },
          }),
        ])
        .pipe(
          catchError(() =>
            Observable.of(
              createModal({
                key: MODAL_TYPES.CUSTOM,
                data: {
                  customComponent: errorComponent,
                },
              }),
            ),
          ),
        );
    });
