import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const SaveDeliveryLoaderImpl = (
  {
    patientId,
    numberOfStrips,
    stripModelReference,
    trafficLightStatus,
    forced,
  },
  accessToken,
) =>
  postJSON(endpointWithParams(ENDPOINTS.delivery, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody({
      numberOfStrips,
      stripModelReference,
      trafficLightStatus: {
        id: trafficLightStatus.id,
      },
      forced,
    }),
  });

export const SaveDeliveryTransformImpl = ({
  id,
  dateCalculated,
  prescriptionId,
  stripsDelivered,
  patientId,
}) => ({
  id,
  lastCollectedDate: dateCalculated,
  prescriptionId,
  numberOfStripsToDeliver: stripsDelivered,
  patientId,
});

export const SaveDeliveryServiceImpl =
  (post, transform) => (params, accessToken, gigyaToken) =>
    post(params, accessToken, gigyaToken).then(transform);
