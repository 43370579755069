export const mockPatientData = {
  resultDescription: 'getPatientOK',
  model: {
    id: 176666,
    user: {
      id: 176664,
      name: 'a &amp;',
      surname: 'a &amp;',
      surname2: null,
      address: {
        id: 176665,
        address: null,
        postalCode: null,
        city: null,
        province: null,
        country: {
          id: 1,
          name: 'Spain',
          isoCode: 'es',
          labelProperty: 'configuration.country.es',
          labelText: null,
          language: {
            id: 1,
            languageId: 1,
            labelProperty: 'enum.Spanish',
            labelText: null,
            isoCode: 'es',
            languageCountryIsoCode: 'ES',
            locale: 'es-ES',
            key: 1,
            value: null,
          },
          timeZone: 'Europe/Madrid',
          profilesList: null,
          currency: 'EURO',
          prefix: +34,
          key: 1,
          value: null,
        },
      },
      fullname: 'a &amp; a &amp;',
      inactivate: false,
      gender: 'UNSPECIFIED',
      phone: null,
      birthday: null,
      email: 'noemailPTAOUiFBjEBMXVj@accu-chek.com',
      loginId: null,
      language: 'Español',
      languageId: 1,
      languageIsoCode: 'es',
      languageCountryIsoCode: 'ES',
      locale: 'es-ES',
      role: 'PATIENT',
      departmentProfileId: 95234,
      profile: 'STANDARD',
      countryId: 1,
      inactivateReason: null,
      inactivateComment: null,
      departmentId: 95233,
      centerId: 95227,
      areaId: 39,
      locked: false,
      lastAccess: null,
      passwordReset: false,
      viewNotifications: false,
      countryIsoCode: 'es',
      departmentName: 'Charly Dev Department',
      centerName: 'Charly Dev Center',
      accessEnum: null,
      profileId: 41,
      gigyaUid: '482d3b124ada424cbf95b35c62f538b1',
      activeInGigya: null,
      hcpIsAccessible: false,
      unitMeasurement: null,
      noPhone: false,
      fakeMail: false,
      headerWelcome: 'a &amp; a &amp;',
      languageIsoCodeComplete: 'es_ES',
    },
    clinicalRecordNumber: null,
    healthCareSystemId: null,
    deviceType: null,
    diagnosticDate: null,
    deviceTypeLabel: null,
    timeZone: null,
    pregnant: false,
    nursingHome: false,
    professionalList: [95240],
    relevantClinicalData: null,
    units: null,
    expectedDeliverDate: null,
    downloadNoticePeriod: 'THIRTY',
    diabetesType: 'DIABETES_TYPE1',
    lastGlucoseDate: '2019-01-23T13:00:00+0000',
    patientDevices: [
      {
        id: 184561,
        associationName: 'ACCU-CHEK Aviva Connect-001190878-1556866438',
        associationStatus: 'MAPPED',
        clientInstanceId: null,
        clientClassId: null,
        associationId: '9065bbff-589a-4c8f-8519-2176fd7af11d',
        fromDate: '2019-05-03T06:54:01+0000',
        toDate: '2019-05-03T00:00:00+0000',
        lastDownloadDate: '2019-05-03T06:54:30+0000',
        active: false,
        professionalInfo: null,
        device: {
          id: 184560,
          serialNumber: 'SN1556866438',
          deviceModel: {
            id: 125,
            name: 'Aviva Connect',
            description: null,
            brandName: 'ACCU-CHEK',
            brand: {
              id: 3,
              name: 'ACCU-CHEK',
              description: 'ACCU-CHEK',
            },
            presentationFormat: 'UNIT',
            reference: 500,
            formatLabel: null,
            packagingUnits: 1,
            countriesList: null,
            countriesStList: null,
            materialType: {
              id: 1,
              name: 'METER',
              label: 'materialsdefinition.modelType.label.device.meter',
              description: 'Device of type meter',
              characteristicModel: 'DEVICES',
              characteristicModelLabel: null,
              modelsList: null,
              labelText: null,
              unitMeasurement: {
                id: 1,
                code: 1,
                text: 'mg/dL',
                unit: 'MGDL',
                family: 'BLOOD_GLUCOSE',
              },
              value: null,
              key: 1,
            },
            brandId: 3,
            modelCountryName: null,
            countriesSelList: null,
            countrieSelUnique: null,
            countriesSelStringList: null,
            createdProfile: 'ADMINISTRATOR',
            imageBase64: '',
            imageContentType: 'image/png',
            imageFileName: 'connect.png',
            modelCountryId: null,
            myCatalog: null,
            addAndRemoveModel: null,
          },
          deviceTypeIcon: 'ICO_3-18_meter',
        },
      },
    ],
    treatmentName: 'treatmentName',
    deviceTypeIcon: 'deviceTypeIcon',
    multilingualDeviceTypeLabel: 'deviceTypeIcon',
  },
};
