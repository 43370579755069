import { countlyEventTrigger } from 'src/navigation/countly';
import { equals } from 'ramda';

import {
  transformAlertToServerAlert,
  transformServerAlertsToAlerts,
} from 'src/services/alerts/alerts.utils';

import { ALERTS_IDS, EVENTS_KEYS, SEGMENT_KEYS } from './alerts.constants';

export const transformAlertsFormValuesToServerAlerts = ({
  patientId,
  alertSettings,
}) => ({
  alerts: {
    hyper: transformAlertToServerAlert(alertSettings[ALERTS_IDS.HYPER]),
    hypo: transformAlertToServerAlert(alertSettings[ALERTS_IDS.HYPO]),
    warning: transformAlertToServerAlert(alertSettings[ALERTS_IDS.LOWER]),
  },
  patientId,
});

export const transformServerAlertsToAlertSettings = (data, action) =>
  transformServerAlertsToAlerts(data);

export const countlyStripsAlertsEvent = (prevState, nextState) => {
  countlyEventTrigger(EVENTS_KEYS.ALERTS_EDITED, {
    [SEGMENT_KEYS.HYPO_ALERTS_MODIFIED]: !equals(
      prevState.hypoglycemiaAlert,
      nextState.hypoglycemiaAlert,
    ),
    [SEGMENT_KEYS.HYPER_ALERTS_MODIFIED]: !equals(
      prevState.hyperglycemiaAlert,
      nextState.hyperglycemiaAlert,
    ),
    [SEGMENT_KEYS.LOWER_LIMITS_ALERTS_MODIFIED]: !equals(
      prevState.lowerAlert,
      nextState.lowerAlert,
    ),
  });
};
