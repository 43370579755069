import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { colors } from 'src/core/styles/colors';
import { spacing } from 'src/core/styles/spacing';

import { TitleMed } from '../../fonts/subheading/subheading.style';

// Reset native button styles to generic styling
// Negative margin increases tap target size while maintaining inline layout
export const CardMinimizerButton = styled(TitleMed.withComponent(Link))`
  padding: ${spacing.two};
  position: absolute;
  top: ${spacing.two};
  right: ${spacing.two};

  &:hover,
  &:focus {
    outline: none;

    jello-icon {
      color: ${colors.brandBlue};
    }
  }
`;
