import * as React from 'react';

import { ChipWrapper } from './chip.style';
import { IChipProps } from './chip.types';

export const Chip = ({ children, backgroundColor, onClick }: IChipProps) => (
  <ChipWrapper onClick={onClick} backgroundColor={backgroundColor}>
    {children}
  </ChipWrapper>
);
