import {
  TargetRangesActions,
  TargetRangesActionType,
  TargetRangesState,
} from './target-ranges.types';

import { TARGET_RANGES_STATE } from '../target-ranges.constants';

export const targetRangesState: TargetRangesState = {
  isFetchingTargetRanges: false,
  isFetchingTargetRangesError: false,
  successTargetRanges: false,
  errorTargetRanges: false,
  ranges: TARGET_RANGES_STATE,
};

export const targetRangesReducer = (
  state = targetRangesState,
  action: TargetRangesActions,
): TargetRangesState => {
  switch (action.type) {
    case TargetRangesActionType.TARGET_RANGES_GET_START:
      return {
        ...targetRangesState,
        isFetchingTargetRanges: true,
      };
    case TargetRangesActionType.TARGET_RANGES_GET_SUCCESS:
      return {
        ...state,
        ranges: action.payload,
        isFetchingTargetRanges: false,
      };
    case TargetRangesActionType.TARGET_RANGES_SAVE_SUCCESS:
      return {
        ...state,
        successTargetRanges: true,
        errorTargetRanges: false,
      };
    case TargetRangesActionType.TARGET_RANGES_SAVE_ERROR:
      return {
        ...state,
        successTargetRanges: false,
        errorTargetRanges: true,
      };
    case TargetRangesActionType.TARGET_RANGES_CLEAR_SUCCESS_START: {
      return {
        ...state,
        successTargetRanges: false,
        errorTargetRanges: false,
      };
    }

    case TargetRangesActionType.TARGET_RANGES_GET_ERROR:
      return {
        ...state,
        isFetchingTargetRangesError: true,
      };
    case TargetRangesActionType.TARGET_RANGES_CLEAR_START:
      return targetRangesState;
    default:
      return state;
  }
};
