import { createStructuredSelector, createSelector, Selector } from 'reselect';
import { pathOr } from 'ramda';

import { selectIs12HourFormat } from 'src/core/user/user.selectors';

import {
  BasalIPProfile,
  DeviceSetting,
  BasalSettings,
  TimeBlock,
  CustomTBR,
} from '../../device-settings.types';
import { selectMeterSettings } from '../tab-general/tab-general.selector';
import {
  selectLatestDeviceSetting,
  selectPreviousDeviceSetting,
} from '../../device-settings.selector';

export const selectBasalSettings: Selector<DeviceSetting[], BasalSettings> =
  createSelector([selectLatestDeviceSetting], (latestSetting: DeviceSetting) =>
    pathOr({}, ['settings', 'BasalSettings'], latestSetting),
  );

export const selectPreviousBasalSettings: Selector<
  DeviceSetting[],
  BasalSettings
> = createSelector(
  [selectPreviousDeviceSetting],
  (previousSetting: DeviceSetting) =>
    pathOr({}, ['settings', 'BasalSettings'], previousSetting),
);

export const selectBasalIPProfiles: Selector<
  DeviceSetting[],
  BasalIPProfile[]
> = createSelector([selectBasalSettings], (basalSettings: BasalSettings) =>
  pathOr([], ['BasalIPProfiles'], basalSettings),
);

export const selectPreviousBasalIPProfiles: Selector<
  DeviceSetting[],
  BasalIPProfile[]
> = createSelector(
  [selectPreviousBasalSettings],
  (previousBasalSetting: BasalSettings) =>
    pathOr([], ['BasalIPProfiles'], previousBasalSetting),
);

export const selectActiveIpProfile: Selector<DeviceSetting[], number | null> =
  createSelector([selectBasalSettings], (basalSettings) =>
    pathOr(null, ['ActiveProfile'], basalSettings),
  );

export const selectBolusTimeBlocks: Selector<DeviceSetting[], TimeBlock[]> =
  createSelector([selectLatestDeviceSetting], (latestSetting) =>
    pathOr([], ['settings', 'Device', 'TimeBlocks'], latestSetting),
  );

export const selectPreviousBolusTimeBlocks: Selector<
  DeviceSetting[],
  TimeBlock[]
> = createSelector([selectPreviousDeviceSetting], (previousSetting) =>
  pathOr([], ['settings', 'Device', 'TimeBlocks'], previousSetting),
);

export const selectBolusAdvice: Selector<DeviceSetting[], TimeBlock[]> =
  createSelector([selectLatestDeviceSetting], (latestSetting) =>
    pathOr({}, ['settings', 'BolusSettings', 'BolusAdvice'], latestSetting),
  );

export const selectPreviousBolusAdvice: Selector<DeviceSetting[], TimeBlock[]> =
  createSelector([selectPreviousDeviceSetting], (latestSetting) =>
    pathOr({}, ['settings', 'BolusSettings', 'BolusAdvice'], latestSetting),
  );
export const selectBolusHealthEvents: Selector<DeviceSetting[], TimeBlock[]> =
  createSelector([selectLatestDeviceSetting], (latestSetting) =>
    pathOr({}, ['settings', 'Device', 'HealthEvents'], latestSetting),
  );

export const selectPreviousBolusHealthEvents: Selector<
  DeviceSetting[],
  TimeBlock[]
> = createSelector([selectPreviousDeviceSetting], (latestSetting) =>
  pathOr({}, ['settings', 'Device', 'HealthEvents'], latestSetting),
);

export const selectCustomTBR: Selector<DeviceSetting[], CustomTBR[]> =
  createSelector([selectBasalSettings], (basalSettings) =>
    pathOr([], ['CustomTBR'], basalSettings),
  );

export const selectPreviousCustomTBR: Selector<DeviceSetting[], CustomTBR[]> =
  createSelector([selectPreviousBasalSettings], (previousBasalSettings) =>
    pathOr([], ['CustomTBR'], previousBasalSettings),
  );

export const selectDisabledBasalProfileIds: Selector<DeviceSetting[], any[]> =
  createSelector([selectBasalIPProfiles], (basalProfiles: any) =>
    basalProfiles
      .map((profile) => {
        const reducer = (acc, currentValue) => acc + currentValue.InsulinUnits;
        const summedBlocks = profile.IPTimeBlocks.reduce(reducer, 0);
        if (summedBlocks === 0) {
          return profile.IdProfile;
        }
      })
      .filter((item) => item),
  );

export const deviceSettingsBasalBolusConnector = () =>
  createStructuredSelector({
    ipBasalProfiles: selectBasalIPProfiles,
    previousIpBasalProfiles: selectPreviousBasalIPProfiles,
    activeIpProfile: selectActiveIpProfile,
    disabledBasalProfileIds: selectDisabledBasalProfileIds,
    bolusTimeBlocks: selectBolusTimeBlocks,
    prevTimeBlocks: selectPreviousBolusTimeBlocks,
    bolusAdvice: selectBolusAdvice,
    prevBolusAdvice: selectPreviousBolusAdvice,
    bolusHealthEvents: selectBolusHealthEvents,
    prevBolusHealthEvents: selectPreviousBolusHealthEvents,
    meterSettings: selectMeterSettings,
    customTBR: selectCustomTBR,
    previousCustomTBR: selectPreviousCustomTBR,
    is12HourFormat: selectIs12HourFormat,
  });
