import { path, pathOr } from 'ramda';

export const selectPatient = path(['patient']);
export const selectPatientId = pathOr(null, ['patient', 'id']);
export const selectPatientFhirId = pathOr(null, ['patient', 'fhirId']);
export const selectPatientDevices = pathOr([], ['patient', 'devices']);
export const selectPatientProfileId = path(['patient', 'profile', 'id']);
export const selectPatientProfileType = path(['patient', 'profile']);
export const selectPatientGigyaUid = path(['patient', 'gigyaUid']);
export const selectPatientLastGlucoseDate = path([
  'patient',
  'lastGlucoseDate',
]);
export const selectPatientProfile = path(['patient', 'profile']);
export const selectPatientSurname = path(['patient', 'surName']);
export const selectPatientSurname2 = path(['patient', 'surName2']);
