import { createStyledComponent } from 'src/utils/styles';

export const PdfReportButtonContainer = createStyledComponent<
  'div',
  { disabled?: boolean }
>('div')`
  padding-top: 4px;
  svg {
    cursor: pointer;
    ${({ disabled }) => (disabled ? 'pointer-events: none' : '')}
  }
`;
PdfReportButtonContainer.displayName = 'PatientSummaryBarDropdownWrapper';
