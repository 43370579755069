import styled from 'styled-components';
import {
  space,
  width,
  height,
  flex,
  flexWrap,
  justifyContent,
  alignItems,
  alignSelf,
  color,
} from 'styled-system';

import { Div } from '../div/div.component';

export const Column = styled(Div)`
  flex: 1;
  flex-direction: column;
  display: flex;
  ${color};
  ${space};
  ${width};
  ${height};
  ${flex};
  ${flexWrap};
  ${justifyContent};
  ${alignItems};
  ${alignSelf};
`;

export default Column;
