// tslint:disable
import { CGMClinicalDataResponse } from './cgm-clinical-data.types';

import {
  CGMClinicalDataLoaderImpl,
  CGMClinicalDataServiceImpl,
  CGMClinicalDataTransformImpl,
} from './cgm-clinical-data.service';

// import { mock } from './cgm-clinical-data.mock';
import { dynamicMock3 } from './cgm-clinical-data.mock';

// const mockLoader = (): Promise<CGMClinicalDataResponse> =>
//   new Promise((resolve, reject) => setTimeout(() => resolve(mock), 3500));

const dMockLoader = (): Promise<CGMClinicalDataResponse> =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(dynamicMock3 as CGMClinicalDataResponse), 3500),
  );

export const CGMClinicalDataFactoryImpl = ({ devMode }) => {
  const loader = devMode ? dMockLoader : CGMClinicalDataLoaderImpl;
  return CGMClinicalDataServiceImpl(loader, CGMClinicalDataTransformImpl);
};
