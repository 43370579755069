import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

export const FactoryIconComponent = ({
  height = 26,
  fillColor = colors.grayMedium,
  strokeColor = colors.grayMedium,
  strokeWidth = '2',
  t,
  title = 'graphs.iconTitles.factory',
}) => {
  const originalWidth = 46;
  const originalHeight = 41;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      title={t(title)}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M36.808.097v19.297l-6.183-8.992-5.808 8.993-6.182-8.993-5.808 8.993-6.183-8.993-5.761 9.262-.047 20.714H45.8V.098z"
        fill={fillColor}
        fillRule="evenodd"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </SvgIcon>
  );
};

export const FactoryIcon = withTranslation(FactoryIconComponent);
