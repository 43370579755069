import { DateTime } from 'luxon';
import { DiabetesType } from 'src/services/patient/patient.types';
import { t } from 'i18next';

export const getDate = (date) => {
  if (date) {
    const luxon = DateTime.fromISO(date);
    const format = luxon.toFormat('dd LLL yyyy');
    const age = Math.floor(Math.abs(luxon.diffNow('years').years));
    return `${format} (${age})`;
  }
  return date;
};

export const checkDiabetesType = (diabetesType) => {
  if (diabetesType === null) return '-';
  if (diabetesType === DiabetesType.DIABETES_UNKNOWN)
    return t(`hcpDashboard.${DiabetesType.UNKNOWN}`);
  if (diabetesType === DiabetesType.PENDING_TO_IDENTIFY)
    return t(`hcpDashboard.${DiabetesType.PENDING_TO_IDENTIFY}`);
  return t(`hcpDashboard.${diabetesType}`);
};
