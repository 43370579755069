import { path } from 'ramda';
import { createSelector } from 'reselect';
import { State } from 'src/app/store/app.types';

const selectConfigurablesPermissionsState = (state: State) =>
  state.configurablePermissions;

export const selectConfigurablesLoading = createSelector(
  [selectConfigurablesPermissionsState],
  path(['isLoading']),
);

export const selectConfigurablePermissions = createSelector(
  [selectConfigurablesPermissionsState],
  path(['configurables']),
);

export const selectConfigurablesError = createSelector(
  [selectConfigurablesPermissionsState],
  path(['error']),
);
