/* eslint-disable */
import * as React from 'react';
import { CSVLink } from 'react-csv';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { transformData, getTranslatedHeaders } from './excel-export.utils';

type ExcelExportProps = {
  dataExcel: any;
  is12HourFormat: boolean;
  t: any;
};
export class ExcelExport extends React.Component<ExcelExportProps, {}> {
  private inputElement: any;

  public componentDidMount() {
    if (this.inputElement) {
      setTimeout(() => this.inputElement.link.click(), 0);
    }
  }

  public render() {
    const { dataExcel, t, is12HourFormat } = this.props;
    const headers = getTranslatedHeaders(t);

    return (
      <CSVLink
        ref={(input) => (this.inputElement = input)}
        filename={'patients-export.csv'}
        separator={';'}
        data={transformData(dataExcel, t, is12HourFormat)}
        headers={headers}
        target="_blank"
      />
    );
  }
}
export const ExportExcelComponent = withTranslation(ExcelExport);
