import * as React from 'react';

import { CheckmarkIcon } from 'src/assets/icons';
import { Badge } from 'src/components/badge/badge.component';
import { colors } from 'src/core/styles/colors';

type SuccessBadgeProps = {
  size: number;
  fillColor?: string;
};

export const SuccessBadge: React.FunctionComponent<SuccessBadgeProps> = ({
  size,
  fillColor,
}) => (
  <Badge
    icon={<CheckmarkIcon height={size * 0.42} />}
    bgColor={fillColor || colors.trafficGreen}
    size={size}
    emptyInnerCircle={false}
    disabled={false}
  />
);
