import * as React from 'react';
import { StickyContainer } from 'react-sticky';

import { GridContainer, GridItem } from 'src/components/grid-layout';
import { RenderIf } from 'src/utils/render-if';
import { PatientDataBar } from 'src/widgets/top-patient-bar/patient-data-bar.component';

export const withPatientSummary = (Component) => (props) => {
  const { isJelloActivated } = props;

  return (
    <>
      <RenderIf validate={!isJelloActivated}>
        <StickyContainer>
          <GridContainer marginBottom={true}>
            <GridItem span="12">
              <PatientDataBar
                dateControls={false}
                isJelloActivated={isJelloActivated}
              />
            </GridItem>
            <GridItem span="12">
              <Component {...props} />
            </GridItem>
          </GridContainer>
        </StickyContainer>
      </RenderIf>
      <RenderIf validate={isJelloActivated}>
        <Component {...props} />
      </RenderIf>
    </>
  );
};
