/* tslint:disable */

import * as React from 'react';
import { TablePrimaryRow } from '../table-primary-row/table-primary-row.component';
import { getStylesByRowType } from '../table-primary.utils';
import { TablePrimaryRowsProps } from '../table-primary.types';

export const TablePrimaryRows = ({
  headerRowsList,
  rowStyles,
  cellStyles,
}: TablePrimaryRowsProps) => (
  <>
    {headerRowsList.map((row, key) => {
      const { type: rowType, data: cellList } = row;
      return (
        <TablePrimaryRow
          cellList={cellList}
          rowStyles={getStylesByRowType(rowStyles, rowType)}
          cellStyles={getStylesByRowType(cellStyles, rowType)}
          key={key}
        />
      );
    })}
  </>
);
