// tslint:disable
const moment = require('moment');

export const TIME_FORMAT_24_HOURS = 'HH:mm';
export const TIME_FORMAT_12_HOURS = 'hh:mm a';

export const MERIDIAN_TIME_FORMAT = 'AM/PM';

export const is12HourFormat = (format: string): boolean =>
  format === MERIDIAN_TIME_FORMAT;

export const getHCPTimeFormat = (format: string) =>
  is12HourFormat(format) ? TIME_FORMAT_12_HOURS : TIME_FORMAT_24_HOURS;

export const formatLuxonTime = (
  time: any,
  is12hourTimeFormat: boolean,
): string =>
  is12hourTimeFormat
    ? time.toFormat('h:mm a', { locale: 'us' }).toUpperCase()
    : time.toFormat('HH:mm');

// Given a TimePeriod end or start string (22:15:00),
// converts it into a display format like 10:15 pm.
// f.i. 09:00:00 -> 9:00 AM
// f.i. 23:15:00 -> 11:15 PM

export const convertTimeToFormat = (
  time: string,
  is12hourTimeFormat: boolean,
): string =>
  is12hourTimeFormat
    ? moment(time, 'HH:mm:ss').format('h:mm A')
    : moment(time, 'HH:mm:ss').format('HH:mm');
