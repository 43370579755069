import { GetLatestPatientsEC6Response } from './get-latest-patients.types';

export const mockGetLatestPatients: GetLatestPatientsEC6Response = {
  resultDescription: 'patientLatestOK',
  model: [
    {
      id: 57770,
      user: {
        id: undefined,
        name: 'JoeBlow',
        surname: 'McJoeington',
        surname2: undefined,
        address: undefined,
        fullname: 'JoeBlow McJoeington',
        inactivate: false,
        gender: 'FEMALE',
        phone: undefined,
        birthday: '2018-08-02T00:00:00+0000',
        email: 'joe@mail.com',
        loginId: undefined,
        language: undefined,
        languageId: undefined,
        languageIsoCode: undefined,
        languageCountryIsoCode: undefined,
        role: undefined,
        departmentProfileId: undefined,
        profile: undefined,
        countryId: undefined,
        inactivateReason: undefined,
        inactivateComment: undefined,
        departmentId: undefined,
        centerId: undefined,
        areaId: undefined,
        locked: false,
        lastAccess: undefined,
        passwordReset: false,
        viewNotifications: false,
        countryIsoCode: undefined,
        departmentName: undefined,
        centerName: undefined,
        accessEnum: undefined,
        profileId: undefined,
        gigyaUid: undefined,
        activeInGigya: undefined,
        hcpIsAccessible: true,
        noPhone: false,
        fakeMail: false,
        headerWelcome: 'JoeBlow McJoeington',
        languageIsoCodeComplete: 'undefined_undefined',
      },
      clinicalRecordNumber: undefined,
      healthCareSystemId: undefined,
      deviceType: undefined,
      diagnosticDate: undefined,
      deviceTypeLabel: undefined,
      timeZone: undefined,
      pregnant: undefined,
      nursingHome: undefined,
      professionalList: undefined,
      relevantClinicalData: undefined,
      units: undefined,
      expectedDeliverDate: undefined,
      downloadNoticePeriod: undefined,
      diabetesType: undefined,
      lastGlucoseDate: undefined,
      patientDevices: undefined,
      deviceTypeIcon: '',
      multilingualDeviceTypeLabel: '',
    },
  ],
};
