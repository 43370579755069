import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { path, pathEq, pathOr, pipe, toLower, includes, or } from 'ramda';
import { getIn } from 'src/utils/ramda/ramda.utils';
import { createSelector, createStructuredSelector } from 'reselect';
import { selectCountries } from 'src/core/countries/countries.selectors';
import {
  selectConfigurablePermissions,
  selectConfigurablesError,
  selectConfigurablesLoading,
} from 'src/core/configurables/configurables.selectors';
import {
  selectDepartmentProfessionals,
  selectDepartmentTypes,
} from 'src/core/department/department.selectors';
import { ProfileTypes } from 'src/core/department/department.types';
import { selectLanguages } from 'src/core/languages/languages.selectors';
import { selectProfileType } from 'src/widgets/patient/forms/select-profile/store/select-profile-type.selectors';
import {
  convertActiveLanguagesToDropdownOptions,
  convertEC6CountriesToDropdownOptions,
  findCountryByIsoCode,
} from 'src/widgets/forms/store/utils/index';

import { selectIsFranceCountry } from 'src/core/permissions/permissions.selectors';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';
import {
  selectEC6DepartmentId,
  selectEC6UserId,
  selectEC6UserLanguage,
  selectHomeCountry,
} from 'src/core/user/user.selectors';
import { hasValue } from 'src/utils/validation-helpers';

import { convertAssociatedPractitionerProfessionalsToDropdownOptions } from '../create-patient/create-patient.utils';
import { selectActivePatientForm } from '../forms/store/forms.selectors';
import {
  selectSuccessINSIdentity,
  selectIsFetchingINSIdentityError,
  selectUserHasCancelledRequest,
} from '../INS-patient/store/INS-identity.selectors';

const selectCreatePatientState = path(['createPatient']);

const selectIsCreatingPatient = createSelector(
  selectCreatePatientState,
  path(['isCreatingPatient']),
);

const selectCreationWasSuccessful = createSelector(
  selectCreatePatientState,
  path(['creationWasSuccessful']),
);

const selectCreationHasError = createSelector(
  selectCreatePatientState,
  path(['creationHasError']),
);

export const selectNewPatient = createSelector(
  selectCreatePatientState,
  (state) => path(['newPatient'])(state),
);

export const selectCountryDropdownOptions = createSelector(
  selectCountries,
  (countries) => convertEC6CountriesToDropdownOptions(countries),
);

export const selectLanguageDropdownOptions = createSelector(
  selectLanguages,
  (languages) => convertActiveLanguagesToDropdownOptions(languages),
);

export const selectAssociatedPractitionerProfessionalsToDropdownOptions =
  createSelector(selectDepartmentProfessionals, (professionals) =>
    convertAssociatedPractitionerProfessionalsToDropdownOptions(professionals),
  );

export const selectIsHomeDeliveryProfile = (isEditing: boolean) =>
  createSelector(selectActivePatientForm(isEditing), (patientFormState) =>
    pathEq(['profileType'], ProfileTypes.homeDelivery)(patientFormState),
  );

export const selectIsAllowPatientAccessTrue = (isEditing: boolean) =>
  createSelector(selectActivePatientForm(isEditing), (patientFormState) =>
    pathEq(['patientInfo', 'allowPatientAccess'], true)(patientFormState),
  );

export const selectOpenConnectivityTrue = (isEditing: boolean) =>
  createSelector(selectActivePatientForm(isEditing), (patientFormState) =>
    includes(
      PERMISSIONS.OPEN_CONNECTIVITY_DATA_DOWN,
      pathOr([], ['grantedPermissions'], patientFormState),
    ),
  );

export const selectRPMProgramTrue = (isEditing: boolean) =>
  createSelector(selectActivePatientForm(isEditing), (patientFormState) =>
    includes(
      PERMISSIONS.RPM_PROGRAM,
      pathOr([], ['grantedPermissions'], patientFormState),
    ),
  );

export const selectMySugarInvitationTrue = (isEditing: boolean) =>
  createSelector(selectActivePatientForm(isEditing), (patientFormState) =>
    includes(
      PERMISSIONS.MYSUGR_INVITATION_PAT,
      pathOr([], ['grantedPermissions'], patientFormState),
    ),
  );

export const selectMandatoryMailTrue = (isEditing: boolean) =>
  createSelector(
    selectIsAllowPatientAccessTrue(isEditing),
    selectMySugarInvitationTrue(isEditing),
    selectRPMProgramTrue(isEditing),
    (selectIsAllowPatientAccess, selectMySugarInvitation, selectRPMProgram) =>
      or(
        or(selectIsAllowPatientAccess, selectMySugarInvitation),
        selectRPMProgram,
      ),
  );

export const selectCreatedPatientPregnancyState = createSelector(
  selectCreatePatientState,
  (state) => path(['healthInfo', 'pregnant'])(state),
);

export const selectCreatedPatientDiabetesType = createSelector(
  selectCreatePatientState,
  (state) => path(['healthInfo', 'diabetesType'])(state),
);

export const selectCreatedPatientGender = createSelector(
  selectCreatePatientState,
  (state) => path(['patientInfo', 'gender'])(state),
);

export const selectDepartmentCountryId = createSelector(
  selectHomeCountry,
  selectCountries,
  (homeCountry, activeCountries) =>
    hasValue(homeCountry) && hasValue(activeCountries)
      ? pipe(
          findCountryByIsoCode(toLower(homeCountry)),
          path(['id']),
        )(activeCountries)
      : null,
);

export const selectCurrentUserByEC6UserId = createSelector(
  selectEC6UserId,
  selectDepartmentProfessionals,
  (EC6UserId, professionals) =>
    professionals.find(
      (professional) => pathOr('', ['user', 'id'], professional) === EC6UserId,
    ),
);

export const selectCurrentUserLanguageByLocale = createSelector(
  selectEC6UserLanguage,
  selectLanguages,
  (languageISOCode, activeLanguages) =>
    activeLanguages.find((language) => {
      const lng = pathOr('', ['locale'], language);
      return lng.toLowerCase() === languageISOCode;
    }),
);

export const selectRequirementsReady = createSelector(
  selectConfigurablesLoading,
  selectConfigurablesError,
  (isLoading, hasError) => !isLoading && !hasError,
);

const selectCreatePatientFormData = (state) =>
  getIn(['forms', 'createPatient'], state);

export const selectPatientInfoFormData = createSelector(
  selectCreatePatientFormData,
  (patientFormData) => getIn(['patientInfo'], patientFormData),
);

export const createPatientConnector = (state) => {
  const isEditing = false;
  return createStructuredSelector({
    accessToken: selectAccessToken,
    apiKey: selectGigyaToken,
    isLoadingConfigurables: selectConfigurablesLoading,
    isConfigurablesError: selectConfigurablesError,
    isReadyToRender: selectRequirementsReady,
    configurablePermissions: selectConfigurablePermissions,
    associatedProfessionals:
      selectAssociatedPractitionerProfessionalsToDropdownOptions,
    countries: selectCountryDropdownOptions,
    departmentId: selectEC6DepartmentId,
    languages: selectLanguageDropdownOptions,
    professionalId: selectEC6UserId,
    creationWasSuccessful: selectCreationWasSuccessful,
    creationHasError: selectCreationHasError,
    isCreatingPatient: selectIsCreatingPatient,
    newPatient: selectNewPatient,
    isHomeDeliveryProfile: selectIsHomeDeliveryProfile(isEditing),
    isMandatoryMailTrue: selectMandatoryMailTrue(isEditing),
    departmentProfileTypes: selectDepartmentTypes,
    profileType: selectProfileType(isEditing),
    isFranceCountry: selectIsFranceCountry,
    hasPatientPortalAccess: selectIsAllowPatientAccessTrue(isEditing),
    hasOpenConnectivity: selectOpenConnectivityTrue(isEditing),
    isRPMProgramEnrolled: selectRPMProgramTrue(isEditing),
    hasMySugarInvitation: selectMySugarInvitationTrue(isEditing),
    createdPatientPregnancyState: selectCreatedPatientPregnancyState,
    createdPatientDiabetesType: selectCreatedPatientDiabetesType,
    createdPatientGender: selectCreatedPatientGender,
    patientInfoFormData: selectPatientInfoFormData,
    insRequestError: selectIsFetchingINSIdentityError,
    insRequestSuccess: selectSuccessINSIdentity,
    insRequestCancelled: selectUserHasCancelledRequest,
  });
};
