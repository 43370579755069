import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef();
  let newDelay = delay;
  const ONE_SECOND_DELAY = 1000;

  useEffect(() => {
    savedCallback.current = callback as FixMe;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      (savedCallback as FixMe).current();
    };
    const id = setInterval(tick, (newDelay = ONE_SECOND_DELAY));
    return () => clearInterval(id);
  }, [newDelay]);
};
