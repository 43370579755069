import * as React from 'react';

import { GraphTabListProps } from './graph-tab-list.types';

import { GraphTabItem } from '../graph-tab-item/graph-tab-item.component';

export const GraphTabList = ({
  listOfTabs,
  graphType,
  eventFunc,
  graph,
}: GraphTabListProps) => (
  <>{graphTabList(listOfTabs, graphType, eventFunc, graph)}</>
);

const graphTabList = (listOfTabs, graphType, eventFunc, graph) =>
  listOfTabs.map((tab) => (
    <GraphTabItem
      isActive={graphType === tab.type}
      eventFunc={eventFunc}
      eventName={tab.type}
      icon={tab.icon}
      text={tab.text}
      key={tab.type}
      graph={graph}
    />
  ));
