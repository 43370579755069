export const mockAddOrgStockResponse = {
  totalStock: 137500,
  lastShipments: [
    {
      stripModel: {
        id: 1,
        name: 'Accu Chek Aviva',
      },
      currentStock: 27500,
      lastShipmentAt: '2018-03-02T18:21:03.182Z',
      numberOfStripsReceived: 2500,
    },
    {
      stripModel: {
        id: 2,
        name: 'Accu Chek Performa',
      },
      currentStock: 50000,
      lastShipmentAt: '2018-02-12T18:21:03.182Z',
      numberOfStripsReceived: 20000,
    },
    {
      stripModel: {
        id: 3,
        name: 'Bayer Contour',
      },
      currentStock: 60000,
      lastShipmentAt: '2017-06-07T18:21:03.182Z',
      numberOfStripsReceived: 35000,
    },
  ],
};

export const mockAddOrgStockPayload = {
  clinicId: '12345',
  stripModelReference: '10601',
  actionPerformedAt: '2018-05-05T00:00:00.000Z',
  numberOfStrips: 100,
};
