import { createSelector, createStructuredSelector } from 'reselect';
import { append, flatten, groupWith, map, values } from 'ramda';

import {
  selectBasalTicks,
  selectBasalYMax,
  selectGraphDetails,
  selectShowGridLines,
  selectTargetRangePadForBolus,
  selectThresholdPadForBolus,
  selectVerticalAxesCeilingPadForBolus,
  selectVerticalLabel,
  selectVerticalTicksPadForBolus,
} from 'src/domains/diagnostics/scenes/graphs/graph.selector';
import {
  ARBITRARY_PROFILE_Y_VALUE,
  ARBITRARY_RATE_Y_VALUE,
  BASAL_PROFILE_CHANGE_TYPE,
  CARBOHYDRATES_Y_MAX,
  DAYS_OF_WEEK,
  GRAPH_Y_MAX_MG,
  GRAPH_Y_MAX_MMOL,
  GRAPH_Y_MIN,
  INSULIN_Y_MAX,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  addBasalEndDatesAndMidnightMeasurements,
  basalRateProfileChanged,
  filterBasalRateChanges,
  filterDuplicateLines,
  getBasalProfile,
  getBasalVerticalPadding,
  getYFromTwoPointsAndOneXValue,
  sortAllInsulinBarsDescending,
  togglePointsFilter,
  transformBasal,
} from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph.util';
import { convertDateToWeeklyFloat } from '../../../../../../utils/time';
import {
  toDayOfWeekNumFormat,
  toEndOfDay,
  toStartOfDay,
} from '../../../../../../utils/date';
import {
  selectBasalsInDateSliderRange,
  selectBloodGlucoseUnit,
  selectCarbohydratesMeasurementsInDateSliderRange,
  selectGlucoseMeasurementsIncludingNullValuesInDateSliderRange,
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphLoading,
  selectGraphThreshold,
  selectGraphToggles,
  selectValidBolusesInDateSliderRange,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { selectPatientStartDate } from 'src/domains/diagnostics/store/selectors/patient-date-range.selector';
import { isEqual as luxonDatesEqual } from 'src/utils/date';
import { selectEC6TimeFormat } from 'src/core/user/user.selectors';

import {
  calculateInsulinDimensions,
  convertISOToGMT,
  createMeanBgPoints,
  crossoverDay,
  crossoverWeek,
} from './standard-week-detail.util';

import {
  combineOverlappingInsulinOneBolusMeasurements,
  flagOverlappingBolusMeasurements,
} from '../../insulin/insulin.utilities';
import {
  selectCarbohydratesTicks,
  selectInsulinTicks,
} from '../trend/trend-detail/trend-detail.selector';
import { BLOOD_GLUCOSE_UNITS } from 'src/domains/patient-dashboards/bg/store/bg.constants';
import { colors } from '../../../../../../core/styles/colors';
import { areDatesTheSameDay } from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph-date';
import {
  adjustValueByDay,
  hourGapNotGreaterThanXHours,
} from 'src/domains/diagnostics/scenes/graphs/template/utils/graphs-template.util';
import { selectCarbUnitMeasurementForService } from 'src/core/patient-date-range/patient-date-range.selector';

const WEEK_LENGTH = 7;
const ALLOWED_HOUR_GAP = 10;
const groupByDay = groupWith(
  ({ data: { date: dateA } }, { data: { date: dateB } }) =>
    areDatesTheSameDay(dateA, dateB),
);

const shouldAddInvisiblePoint = (
  pointA,
  pointB,
  adjustmentModifier, // -1 or 1
) =>
  Math.abs(
    adjustValueByDay(pointA.x) - (pointB.x - WEEK_LENGTH * adjustmentModifier),
  ) < ALLOWED_HOUR_GAP;

const groupClinicalDataByWeek = (clinicalData) =>
  values(
    clinicalData.reduce((datesGroupedByWeek, val) => {
      const date = val.date ? val.date : val.data.date;
      const week = date.weekNumber;
      const year = date.weekYear;

      const yearAndWeek = `${year}-${week}`;

      const entries = datesGroupedByWeek[yearAndWeek] || [];

      return {
        ...datesGroupedByWeek,
        [yearAndWeek]: [...entries, val],
      };
    }, {}),
  );

const glucoseValueLinesConnector = (glucoseValueLineData) =>
  glucoseValueLineData.map((line, index, array) => {
    // An array.length of 2 indicates there's data selected for two different weeks
    // connecting lines are only needed when data spans two different weeks.
    if (array.length <= 1) {
      return line;
    } else {
      const previous = array[index - 1];
      let current = [...line];
      const next = array[index + 1];
      if (previous) {
        const firstPointOfCurrent = current[0];
        const lastPointOfPrevious = previous[previous.length - 1];

        // get dates to check for 10 hour gap.
        const aDate = lastPointOfPrevious.data.date;
        const bDate = firstPointOfCurrent.data.date;

        if (
          hourGapNotGreaterThanXHours(aDate, bDate, 10) &&
          shouldAddInvisiblePoint(firstPointOfCurrent, lastPointOfPrevious, 1)
        ) {
          // Add lastPointPrevious to the beginning of current.
          current = [
            {
              ...lastPointOfPrevious,
              x: lastPointOfPrevious.x - 7,
              data: {
                ...lastPointOfPrevious.data,
                date: bDate,
              },
              leaveOffGraph: true,
            },
            ...current,
          ];
        }

        // current.unshift(lastPointOfPrevious);
      }
      if (next) {
        const lastPointOfCurrent = current[current.length - 1];
        const firstPointOfNext = next[0];
        // get dates to check for 10 hour gap.
        const aDate = lastPointOfCurrent.data.date;
        const bDate = firstPointOfNext.data.date;

        if (
          hourGapNotGreaterThanXHours(aDate, bDate, 10) &&
          shouldAddInvisiblePoint(lastPointOfCurrent, firstPointOfNext, -1)
        ) {
          // Add firstPointOfNext to the end of current
          current = [
            ...current,
            {
              ...firstPointOfNext,
              x: firstPointOfNext.x + 7,
              data: {
                ...firstPointOfNext.data,
                date: aDate,
              },
              leaveOffGraph: true,
            },
          ];
        }
      }

      return current;
    }
  });

const convertMeasurementsToLinePoints = (measurements) =>
  measurements.map((measurement) => {
    const x = convertDateToWeeklyFloat(measurement.date);
    const y = measurement.value;

    return {
      x,
      y,
      data: measurement,
    };
  });

const createGlucoseValueLines = (measurements) =>
  convertMeasurementsToLinePoints(measurements).sort((a, b) => a.x - b.x);

const createConnectedGlucoseValueLines = (measurements) =>
  glucoseValueLinesConnector(
    groupClinicalDataByWeek(measurements).map((weeklyGlucoseValues) =>
      createGlucoseValueLines(weeklyGlucoseValues),
    ),
  );

const normalizeGlucoseLines = (weeks, floor, ceiling) =>
  weeks.map((week) =>
    week.map((datum, index) => {
      const { x, y } = datum;

      return {
        x: x / DAYS_OF_WEEK.length,
        y: y / ceiling,
        data: datum.data,
      };
    }),
  );

const normalizeGraphLines = (measurements, graphYMax) =>
  normalizeGlucoseLines(
    createConnectedGlucoseValueLines(measurements),
    GRAPH_Y_MIN,
    graphYMax,
  );

export const generateLines = (week) => groupByDay(week);

const selectGraphLines = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectVerticalAxesCeilingPadForBolus,
  normalizeGraphLines,
);
const convertMSToWeeklyFloat = (ms) => {
  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
  return (7 * ms) / sevenDaysInMs;
};

const pointsXValueComparator = (a, b) => a.x - b.x;
const tenHoursXValue = (1 / 24) * 10;
const tenHoursNormalizedXValue = (1 / 7 / 24) * 10;
const areDatesMoreThan10HoursApart = (dateA, dateB) =>
  convertMSToWeeklyFloat(dateB - dateA) > tenHoursXValue;
const generateDayCrossoverPoints = (week) => {
  const oneMillisecondXValue = tenHoursXValue / 3600000;

  return week.map((day, index, array) => {
    const nextDay = array[index + 1];

    if (!day.length || !nextDay || (nextDay && !nextDay.length)) {
      return day;
    }

    const lastPointOfCurrentDay = day[day.length - 1];
    const lastPointOfCurrentDayDate = lastPointOfCurrentDay.data.date;
    const firstPointOfNextDay = nextDay[0];
    const firstPointOfNextDayDate = firstPointOfNextDay.data.date;

    if (
      areDatesMoreThan10HoursApart(
        lastPointOfCurrentDayDate,
        firstPointOfNextDayDate,
      )
    ) {
      return day;
    }

    const dayOfWeekNum =
      parseInt(toDayOfWeekNumFormat(lastPointOfCurrentDayDate), 10) /
      DAYS_OF_WEEK.length;

    const x = dayOfWeekNum <= 0 ? dayOfWeekNum + 7 : dayOfWeekNum;

    const y = getYFromTwoPointsAndOneXValue(
      lastPointOfCurrentDay.x,
      lastPointOfCurrentDay.y,
      firstPointOfNextDay.x,
      firstPointOfNextDay.y,
      x,
    );

    return [
      ...day,
      {
        crossOverPoint: false,
        x: x - oneMillisecondXValue,
        y,
        data: {
          date: toEndOfDay(lastPointOfCurrentDayDate),
        },
      },
      {
        crossOverPoint: true,
        x,
        y,
        data: {
          date: toStartOfDay(firstPointOfNextDayDate),
        },
      },
    ];
  });
};

const getFinalLines = (sortedPointsWithDayCrossoverPointsAdded) => {
  let previousX = 0;
  let previousIndex = 0;
  let lines = [];

  sortedPointsWithDayCrossoverPointsAdded.forEach(
    (point, index, originalArray) => {
      if (
        point.x - previousX > tenHoursNormalizedXValue ||
        point.crossOverPoint
      ) {
        lines = append(originalArray.slice(previousIndex, index), lines);
        previousIndex = index;
      }

      if (index === originalArray.length - 1) {
        lines = append(originalArray.slice(previousIndex), lines);
      }

      previousX = point.x;
    },
  );

  return lines;
};

export const generateGroupedLinesWithDayCrossovers = (
  linesGroupedByWeekAndDay,
) =>
  linesGroupedByWeekAndDay.map((week) => {
    const crossoverPoints = flatten(generateDayCrossoverPoints(week)).filter(
      (point) =>
        point.crossOverPoint === false || point.crossOverPoint === true,
    );

    return [...flatten(week), ...crossoverPoints].sort(pointsXValueComparator);
  });

const selectFilteredLines = createSelector(
  selectGraphLines,
  selectGraphToggles,
  (linesGroupedByWeek, toggles) => {
    const linesGroupedByWeekAndDay = linesGroupedByWeek.map(generateLines);
    const groupedLinesWithDayCrossovers = generateGroupedLinesWithDayCrossovers(
      linesGroupedByWeekAndDay,
    );

    const newLines = groupedLinesWithDayCrossovers.map((week) =>
      getFinalLines(week),
    );
    return toggles.showBloodGlucoseLines ? newLines : [];
  },
);

const selectHorizontalTicks = () =>
  DAYS_OF_WEEK.map((day, index) => ({
    value: index / DAYS_OF_WEEK.length,
    label: `general.days.${DAYS_OF_WEEK[index]}`,
  }));

const normalizePoints = (points = [], floor, ceiling) => {
  const filteredPoints = points.filter((glucoseValue) => glucoseValue.y);
  const filteredPointsToMap = filteredPoints ? filteredPoints : [];

  return filteredPointsToMap.map((point, index) => {
    const { x, y } = point;

    return {
      ...point,
      x: x / DAYS_OF_WEEK.length,
      y: y / ceiling,
    };
  });
};

const convertMeasurementsToPoints = (
  measurements,
  thresholds = {},
  bloodGlucoseUnit = '',
) => {
  const GRAPH_Y_MAX =
    bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
      ? GRAPH_Y_MAX_MMOL
      : GRAPH_Y_MAX_MG;
  return measurements.map((measurement) => {
    let shape;

    if (measurement.value > GRAPH_Y_MAX) {
      shape = 'triangle';
    } else if (measurement.beforeMeal || measurement.afterMeal) {
      shape = 'square';
    } else {
      shape = 'x';
    }

    const x = convertDateToWeeklyFloat(measurement.date);
    const y = shape === 'triangle' ? GRAPH_Y_MAX : measurement.value;

    let strokeColor;
    if (measurement.value > thresholds.glucoseIdealIntervalMax) {
      strokeColor = colors.blueLight;
    } else if (measurement.value < thresholds.hypoglycemiaThreshold) {
      strokeColor = colors.red;
    } else {
      strokeColor = colors.black;
    }

    const fillColor =
      measurement.afterMeal || shape === 'triangle'
        ? strokeColor
        : colors.white;

    return {
      shape,
      x,
      y,
      strokeColor,
      fillColor,
      data: measurement,
    };
  });
};
const selectGlucoseMeasurementPoints = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphThreshold,
  selectVerticalAxesCeilingPadForBolus,
  selectBloodGlucoseUnit,
  (measurements, thresholds, graphYMax, bloodGlucoseUnit) => {
    const normalized = normalizePoints(
      convertMeasurementsToPoints(measurements, thresholds, bloodGlucoseUnit),
      GRAPH_Y_MIN,
      graphYMax,
    );
    return normalized;
  },
);

const selectFilteredGlucoseMeasurementPoints = createSelector(
  selectGlucoseMeasurementPoints,
  selectGraphToggles,
  togglePointsFilter,
);

const selectDayMeanPoints = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectVerticalAxesCeilingPadForBolus,
  (measurements, graphYMax) =>
    normalizePoints(createMeanBgPoints(measurements), GRAPH_Y_MIN, graphYMax),
);

const selectFilteredMeanPoints = createSelector(
  selectDayMeanPoints,
  selectGraphToggles,
  (meanPoints, toggles) => (toggles.showMeanBloodGlucose ? meanPoints : []),
);

const normalizeCarbohydratesPoints = (carbohydrates, graphYMax) =>
  carbohydrates.map(([a, b]) => [
    {
      ...a,
      x: a.x / DAYS_OF_WEEK.length,
    },
    {
      ...b,
      x: b.x / DAYS_OF_WEEK.length,
      y: b.y / CARBOHYDRATES_Y_MAX,
    },
  ]);

const convertCarbohydratesToPoints = (carbohydrates) =>
  carbohydrates.map(({ date, carbohydrates }) => {
    const x = convertDateToWeeklyFloat(date);
    return [
      { x, y: 0, date, value: carbohydrates },
      { x, y: carbohydrates, date, value: carbohydrates },
    ];
  });

export const selectCarbohydratesLines = createSelector(
  selectCarbohydratesMeasurementsInDateSliderRange,
  selectVerticalAxesCeilingPadForBolus,
  (carbohydrates, graphYMax) =>
    normalizeCarbohydratesPoints(
      convertCarbohydratesToPoints(carbohydrates),
      graphYMax,
    ),
);

const selectInsulinPoints = createSelector(
  selectGlucoseMeasurementsIncludingNullValuesInDateSliderRange,
  selectValidBolusesInDateSliderRange,
  selectPatientStartDate,
  (glucose, boluses, startDate) => {
    const processedGlucose = combineOverlappingInsulinOneBolusMeasurements(
      luxonDatesEqual,
    )(glucose, boluses);
    const processedBoluses = flagOverlappingBolusMeasurements(luxonDatesEqual)(
      glucose,
      boluses,
    );

    return (
      flatten([processedGlucose, processedBoluses])
        .map(convertISOToGMT)
        .reduce(calculateInsulinDimensions(startDate, INSULIN_Y_MAX), [])
        // we must apply the crossover week logic first because a crossover week
        // measurement must also a crossover day measurement (but the inverse
        // is not necessarily true), and broken down crossover week measurements
        // pass through the crossover day logic (we assume that all crossover
        // measurements can only crossover one day at most from a medical perspective)
        .reduce(crossoverWeek, [])
        .reduce(crossoverDay, [])
        .sort(sortAllInsulinBarsDescending)
    );
  },
);

const createHorizontalBasalLine = (xValues, y, basal) =>
  xValues.map((x) => ({ x, y, show: y > 0, ...basal }));

const createVerticalBasalLine = (yValues, x, basal) =>
  yValues.map((y) => ({ x, y, show: true, ...basal }));

const getBasalX2Value = (x1, endDate, nextBasal, isLastBasal) => {
  if (isLastBasal) {
    return x1;
  }

  const x2 = nextBasal
    ? convertDateToWeeklyFloat(nextBasal.date)
    : convertDateToWeeklyFloat(endDate);

  return x2 < x1 ? DAYS_OF_WEEK.length : x2;
};

const getBasalY2Value = (y1, nextBasal, previousBasal, isLastBasal, yMax) => {
  if (nextBasal) {
    return nextBasal.basalCbrf;
  }

  if (!isLastBasal) {
    return y1;
  }

  return y1 - getBasalVerticalPadding(y1, previousBasal, yMax);
};
const normalizeBasalLine =
  (yMax) =>
  ([a, b]) =>
    [
      { ...a, x: a.x / DAYS_OF_WEEK.length, y: a.y / yMax },
      { ...b, x: b.x / DAYS_OF_WEEK.length, y: b.y / yMax },
    ];

const getVerticalDate = (ascending, basal, nextBasal) =>
  ascending && !!nextBasal ? nextBasal.date : basal.date;
const getVerticalBasalCbrf = (ascending, basal, nextBasal) =>
  ascending && !!nextBasal ? nextBasal.basalCbrf : basal.basalCbrf;
const getVerticalEndDate = (ascending, basal, nextBasal) =>
  ascending && !!nextBasal ? nextBasal.endDate : basal.endDate;

const convertBasalToLines =
  (isLastWeek, yMax) => (basalLines, basal, index, originalArray) => {
    const { date, basalCbrf, endDate } = basal;
    const previousBasal = originalArray[index - 1];
    const nextBasal = originalArray[index + 1];
    const isLastBasal = isLastWeek && index === originalArray.length - 1;

    const x1 = convertDateToWeeklyFloat(date);
    const y1 = basalCbrf;
    const x2 = getBasalX2Value(x1, endDate, nextBasal, isLastBasal);
    const y2 = getBasalY2Value(y1, nextBasal, previousBasal, isLastBasal, yMax);

    const horizontalLine = createHorizontalBasalLine([x1, x2], y1, basal);
    const verticalLine = createVerticalBasalLine([y1, y2], x2, {
      ...basal,
      date: getVerticalDate(y1 < y2, basal, nextBasal),
      basalCbrf: getVerticalBasalCbrf(y1 < y2, basal, nextBasal),
      endDate: getVerticalEndDate(y1 < y2, basal, nextBasal),
    });

    return [...basalLines, horizontalLine, verticalLine];
  };

const convertBasalsToLines = (yMax) => (basals, index, originalArray) =>
  basals.reduce(
    convertBasalToLines(index === originalArray.length - 1, yMax),
    [],
  );

const filterDuplicateLinesExceptForLastLine = (
  acc,
  basals,
  index,
  originalArray,
) => {
  const lastWeek = index === originalArray.length - 1;
  if (lastWeek && basals.length > 1) {
    const lastBasalIndex = basals.length - 1;
    const lastBasalLine = basals[lastBasalIndex];

    return [
      ...acc,
      [...filterDuplicateLines(basals.slice(0, lastBasalIndex)), lastBasalLine],
    ];
  }

  return [...acc, filterDuplicateLines(basals)];
};

export const selectBasalLines = createSelector(
  selectBasalsInDateSliderRange,
  selectBasalYMax,
  (basals, yMax) =>
    groupClinicalDataByWeek(addBasalEndDatesAndMidnightMeasurements(basals))
      .map(map(transformBasal))
      .map(convertBasalsToLines(yMax))
      .map(map(normalizeBasalLine(yMax)))
      .reduce(filterDuplicateLinesExceptForLastLine, []),
);

const convertBasalProfileChangeToPoint = (measurement, index) => ({
  x: convertDateToWeeklyFloat(measurement.date) / DAYS_OF_WEEK.length,
  y: ARBITRARY_PROFILE_Y_VALUE,
  profile: getBasalProfile(measurement, index),
  date: measurement.date,
  changeType: measurement.changeType,
});

const convertBasalRateChangeToPoint = ({ date, basalRemark }) => ({
  x: convertDateToWeeklyFloat(date) / DAYS_OF_WEEK.length,
  y: ARBITRARY_RATE_Y_VALUE,
  profile: basalRemark.split('-')[1],
  date,
});

const filterProfileChanges = (measurements) =>
  measurements.reduce(
    (acc, measurement) =>
      basalRateProfileChanged(measurement)
        ? [
            ...acc,
            {
              ...measurement,
              changeType: BASAL_PROFILE_CHANGE_TYPE.PROFILE_CHANGE,
            },
          ]
        : acc,
    [],
  );

export const selectProfileChanges = createSelector(
  selectBasalsInDateSliderRange,
  (basals) =>
    filterProfileChanges(basals).map(convertBasalProfileChangeToPoint),
);
const selectBasalRateChanges = createSelector(
  selectBasalsInDateSliderRange,
  (basals) => filterBasalRateChanges(basals).map(convertBasalRateChangeToPoint),
);

export const standardWeekDetailConnector = createStructuredSelector({
  basalLines: selectBasalLines,
  basalTicks: selectBasalTicks,
  basalRateChanges: selectBasalRateChanges,
  basalRateProfileChanges: selectProfileChanges,
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  carbohydratesLines: selectCarbohydratesLines,
  carbohydratesTicks: selectCarbohydratesTicks,
  horizontalTicks: selectHorizontalTicks,
  graphDetails: selectGraphDetails,
  graphToggles: selectGraphToggles,
  graphYMax: selectVerticalAxesCeilingPadForBolus,
  insulinPoints: selectInsulinPoints,
  insulinTicks: selectInsulinTicks,
  isLoading: selectGraphLoading,
  lines: selectFilteredLines,
  meanPoints: selectFilteredMeanPoints,
  measurements: selectGlucoseMeasurementsInDateSliderRange,
  points: selectFilteredGlucoseMeasurementPoints,
  showGridLines: selectShowGridLines,
  targetRange: selectTargetRangePadForBolus,
  threshold: selectThresholdPadForBolus,
  verticalLabel: selectVerticalLabel,
  verticalTicks: selectVerticalTicksPadForBolus,
  timeFormat: selectEC6TimeFormat,
  carbUnit: selectCarbUnitMeasurementForService,
});
