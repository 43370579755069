import {
  DeactivatePatientLoaderImpl,
  DeactivatePatientServiceImpl,
  DeactivatePatientTransformImpl,
} from './deactivate-patient.service';

const mockLoader = (): Promise<any> =>
  Promise.resolve({
    resultDescription: 'deactivatePatientOk',
    model: 'SUCCESS',
  });

export const DeactivatePatientFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : DeactivatePatientLoaderImpl;
  return DeactivatePatientServiceImpl(loader, DeactivatePatientTransformImpl);
};
