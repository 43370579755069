export const AXIS_FONT_SIZE = 12;
export const TEXT_DELTA_Y = `0.${AXIS_FONT_SIZE / 2}em`;

export const X_AXIS_RIGHT = {
  TICK_END: 0.13,
  TEXT_OFFSET: 0.2,
};

export const X_AXIS_LEFT = {
  TICK_END: 0.87,
  TEXT_OFFSET: 0.8,
};

export const DAY_RANGE_AXIS = {
  RECT_RADIUS: 1 / 4,
  DAY_RECT_WIDTH: 0.8,
  DAY_RECT_HEIGHT: 0.4,
  DAY_RECT_MARGIN: 0.2,
  TICK: 0.3,
  DAY_TICK_OFFSET: 0.14,
  X_AXIS_WIDTH: 0.95,
  MONTH_LINE_X_OFFSET: 0.003,
  MONTH_LINE_Y_OFFSET: 0.25,
  Y_AXIS_HEIGHT: 0.8,
  Y_AXIS_Y_OFFSET: 0.15,
  Y_AXIS_TICK_LABEL_MARGIN: 0.045,
  X_AXIS_DAY_Y_OFFSET: 0.06,
  X_AXIS_DAY_HEIGHT: 0.2,
  X_AXIS_MONTH_Y_OFFSET: 0.005,
  X_AXIS_LINE_Y_OFFSET: 0.13,
};
