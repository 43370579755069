export const mockEMRProfile = {
  firstName: 'Name',
  surname: 'Surname',
  language: 1,
  email: 'email@email.es',
  hcpId: '1234',
  departmentProfileId: 1234,
  countryId: 2,
  openConnectivity: 'false',
  dateOfBirthDay: '01',
  dateOfBirthMonth: '12',
  dateOfBirthYear: '2020',
  phone: '609876564',
};

export const mockEMRProfileBaseObj = {
  profile: {
    languageId: '123',
    grantRevokeFunctDTO: {
      grantFunctionalities: ['OPEN_CONNECTIVITY_DATA_DOWN'],
      revokeFunctionalities: [],
    },
  },
};

export const mockEditEMRProfileResponse = {
  resultDescription: 'createdProfileOK',
  model: 'ProfessionalId:1234',
};

export const mockEditEMRProfileError = {
  resultDescription: 'INTERNAL_SERVER_ERROR',
  error: [{ error: 'User already exist!' }],
};
