import * as React from 'react';

import { StyledTextInput } from './text-input.style';

export interface HTMLTextAreaElement {
  onChange?: (e: any) => void;
  dataTestId?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  maxLength?: number;
}

export const TextInput: React.FunctionComponent<HTMLTextAreaElement> = (
  props: HTMLTextAreaElement,
) => (
  <StyledTextInput
    data-testid={props.dataTestId}
    placeholder={props.placeholder}
    onChange={props.onChange}
    value={props.value}
    disabled={props.disabled}
    width={props.width}
    height={props.height}
    maxLength={props.maxLength}
    autoFocus={true}
  />
);

TextInput.defaultProps = {
  dataTestId: 'default_test_id',
  placeholder: '',
  value: '',
  disabled: false,
};
