import {
  createRequestActionTypes,
  createRequestActions,
} from 'src/core/request/request.actions';
import { NextShipmentActionType } from './next-shipment.types';

export const NEXT_SHIPMENT_FETCH_REQUEST = createRequestActionTypes(
  NextShipmentActionType.NEXT_SHIPMENT_FETCH,
);

export const CLEAR_NEXT_SHIPMENT_REQUEST = createRequestActionTypes(
  NextShipmentActionType.CLEAR_NEXT_SHIPMENT,
);

export const NEXT_SHIPMENT_CHANGE_STATUS_REQUEST = createRequestActionTypes(
  NextShipmentActionType.NEXT_SHIPMENT_CHANGE_STATUS,
);

export const NEXT_SHIPMENT_CHANGE_DATE_REQUEST = createRequestActionTypes(
  NextShipmentActionType.NEXT_SHIPMENT_CHANGE_DATE,
);

export const fetchNextShipment = createRequestActions(
  NEXT_SHIPMENT_FETCH_REQUEST,
);

export const clearNextShipment = createRequestActions(
  CLEAR_NEXT_SHIPMENT_REQUEST,
);

export const changeStatusNextShipment = createRequestActions(
  NEXT_SHIPMENT_CHANGE_STATUS_REQUEST,
);

export const changeDateNextShipment = createRequestActions(
  NEXT_SHIPMENT_CHANGE_DATE_REQUEST,
);
