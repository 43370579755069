export const BG_GRAPH_LEGEND_STATISTIC_KEYS = {
  BG_GRAPH_LEGEND_OPENED: 'BG Graph Legend Opened',
  BG_GRAPH_STATISTICS_OPENED: 'BG Graph Statistics Opened',
};

export const BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS = {
  BG_GRAPH_TYPE: 'Graph',
  BG_LOCATION: 'Location',
};

export const BG_GRAPH_LEGEND_SEGMENT_VALUES = {
  BG_DASHBOARD: 'BG Dashboard',
  GRAPH_PAGE: 'Graph Page',
  GRAPH_NO_NAME: 'No name graph page',
};
