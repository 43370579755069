import { LOGBOOK_HAS_DROPDOWN } from 'src/domains/diagnostics/constants/logbook.constants';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

export const getGraphNameFromUrl = (path, url) =>
  path.replace(`${url}/`, '').split('/')[0];

export const historyRoutePush = (dropdownValue, history) =>
  LOGBOOK_HAS_DROPDOWN ? history.push(dropdownValue) : true;

const hasVisitManagement = (hcpPermissions) => {
  return (
    hcpPermissions && hcpPermissions.includes(PERMISSIONS.VISIT_MANAGEMENT)
  );
};

const patientIsEnrolled = (patientPermissions) => {
  return (
    patientPermissions.includes(PERMISSIONS.ETAPES_PROGRAM) ||
    patientPermissions.includes(PERMISSIONS.RPM_PROGRAM)
  );
};
export const visitManagementPresent = (hcpPermissions, patientPermissions) => {
  return (
    hasVisitManagement(hcpPermissions) && patientIsEnrolled(patientPermissions)
  );
};
