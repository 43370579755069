// @ts-nocheck
import { stickyHeaderHeight } from 'src/widgets/top-patient-bar/patient-data-bar.style';
import { TopNavigationHeight } from 'src/navigation/utils/navigation.utils';
import { VISIT_MODULE_SIDEBAR_ANCHOR_NAME } from 'src/modules/visit-module/visit-module-constants';

const getVisitModuleContainer = () => {
  const anchor = document.getElementsByTagName(
    VISIT_MODULE_SIDEBAR_ANCHOR_NAME,
  )[0];
  if (anchor === undefined) return null;
  return anchor as HTMLElement;
};

export class SidebarHeightSetter {
  private scrollY = 0;
  private isScrollingY = false;

  private renderedTimeout: NodeJS.Timeout;
  private resizeTimer: NodeJS.Timeout;

  private readonly anchorIsLoadedEvent = 'visitModuleAnchorIsLoaded';

  public setup = () => {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);

    window.addEventListener(this.anchorIsLoadedEvent, this.onAnchorIsLoaded);
  };

  public cleanup = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);

    window.removeEventListener(this.anchorIsLoadedEvent, this.onAnchorIsLoaded);

    clearInterval(this.renderedTimeout);
    clearInterval(this.resizeTimer);
  };

  public onScroll = () => {
    // window.scrollY is not supported by IE11
    // IE11 instead uses window.pageYOffset
    this.scrollY =
      window.scrollY !== undefined ? window.scrollY : window.pageYOffset;
    if (this.isScrollingY) {
      return;
    }
    this.isScrollingY = true;
    requestAnimationFrame(() => {
      this.setSidebarHeight();
      this.isScrollingY = false;
    });
  };

  public onResize = async () => {
    await this.doneResizing();
    this.setSidebarHeight();
  };

  public onAnchorIsLoaded = async () => {
    await this.visitModuleToBeRendered();
    this.setSidebarHeight();
  };

  private visitModuleToBeRendered() {
    return new Promise((resolve) => {
      this.renderedTimeout = setInterval(() => {
        if (getVisitModuleContainer() !== null) {
          clearInterval(this.renderedTimeout);
          resolve();
        }
      }, 100);
    });
  }

  private doneResizing = () => {
    return new Promise((resolve) => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        clearTimeout(this.resizeTimer);
        resolve();
      }, 100);
    });
  };

  private setSidebarHeight = () => {
    const visitModuleContainer = getVisitModuleContainer();
    if (visitModuleContainer === null) return;
    const sidebarHeight = this.calculateSidebarHeight();
    visitModuleContainer.style.height = sidebarHeight + 'px';
  };

  private calculateSidebarHeight() {
    const staticHeaderHeight = this.getStaticHeaderVisibleHeight();
    const headersTotalVisibleHeight = staticHeaderHeight + stickyHeaderHeight;
    return window.innerHeight - headersTotalVisibleHeight;
  }

  private getStaticHeaderVisibleHeight = () => {
    const staticHeaderHeight = +TopNavigationHeight.SHORT;
    const visibleHeight = staticHeaderHeight - this.scrollY;
    if (visibleHeight < 0) {
      return 0;
    }
    return visibleHeight;
  };
}
