import {
  putJSON,
  createAuthHeader,
  stringifyBody,
} from 'src/utils/service/service.utils';
import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

import {
  EditPatientWithFhirParams,
  EditPatientWithFhirResponse,
} from './edit-patient-with-fhir.types';

export const EditPatientWithFhirLoaderImpl = (
  { patientId, patientDto }: EditPatientWithFhirParams,
  accessToken: string,
) =>
  putJSON(endpointWithParams(ENDPOINTS.editPatientWithFhir, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody(patientDto),
  });

export const EditPatientWithFhirTransformImpl = ({
  status,
}: EditPatientWithFhirResponse): number => status;

export const EditPatientWithFhirServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);
