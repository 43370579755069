import { lifecycle } from 'recompose';

// IE 11 compatible fix for sticky header disappearing
// when tabbing through expanded graphs
export const withScrollEvent = lifecycle<{}, {}, {}>({
  componentDidMount() {
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  },
});
