// istanbul ignore file
import * as React from 'react';
import { ScrollSync, AutoSizer, Grid, ColumnSizer } from 'react-virtualized';
import { Sticky, StickyContainer } from 'react-sticky';
import { isNil, isEmpty } from 'ramda';
import { testId } from '@roche/roche-common';
import {
  BodyColumn,
  DateColumn,
  DateColumnBody,
  DateColumnHeader,
  DateInnerCell,
  DateOuterCell,
  DateText,
  InnerCell,
  Logbook24hrsGridRow,
  SummaryCell,
  SummaryColumn,
  SummaryColumnBody,
  SummaryColumnHeader,
  SummaryColumnIconContainer,
  SummaryLabelCell,
  SummaryLabelContainer,
  SummaryOuterCell,
  SummaryValueContainer,
  SummaryValueCell,
  TimeHeaderCell,
  TimeOuterCell,
  MeasurementOuterCell,
  BorderTop,
  Logbook24hrsLoader,
  Logbook24hrsInnerWrapper,
  ScrollButtonsContainer,
  Logbook24hrsOuterWrapper,
} from 'src/domains/diagnostics/widgets/logbook-24hrs/logbook-24hrs.style';
import {
  MEASUREMENT_TYPE,
  rowHeight,
  formatHour,
  setMainTableHeaderCSS,
  isArrowShown,
  checkIsThereHiAndLo,
} from 'src/domains/diagnostics/widgets/logbook-24hrs/logbook-24hrs.util';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { getMealTimeModalMeasurements } from 'src/domains/diagnostics/widgets/logbook/logbook.util';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import {
  Logbook24hourBgIcon,
  Logbook24hourInsulin1Icon,
  Logbook24hourInsulin2Icon,
  Logbook24hourInsulin3Icon,
  Logbook24hourCarbsIcon,
} from 'src/assets/icons';
import { HourToolTip } from './components/hour-tool-tip.component';
import { MeasurementCellGroup } from './components/measurement-cell-group.component';
import {
  MIN_CELL_HEIGHT,
  DATE_COLUMN_WIDTH,
  SUMMARY_COLUMN_WIDTH,
  TIME_COLUMN_MIN_WIDTH,
  ROW_GAP,
  STANDARD_ROW_HEIGHT,
  NUMBER_OF_TIME_BLOCKS,
  STICKY_OFFSET,
  MEASUREMENTS_GROUPED_BY_HOURS,
  ID_SELECTED_ROW,
  WEEKEND_DAYS,
  ID_24H_WRAPPER,
  scrollTo,
  SCROLL_BUTTONS_MARGIN,
  SCROLL_TO_HRS_COLUMN,
  MAIN_TABLE_ID,
  RIGHT_BUTTON,
  DATES_HEADER_NAME,
} from './logbook-24hrs.constants';

import { colors } from 'src/core/styles/colors';
import { LOGBOOK_UNITS_UNIT } from '../../constants/logbook.constants';
import { HeaderBackgroundImage } from '../../components/graph/header-card/header-card.style';
import TooltipPreventRender from '../../components/tooltip-prevent-render/tooltip-prevent-render.component';
import { I18nTranslate } from 'src/utils/i18n/i18n.utils.types';
import { match } from 'node_modules/@types/react-router';
import { ToolTip } from 'node_modules/@roche/rdcp-hcp-pi-client';
import { getSelectedRowId } from 'src/domains/diagnostics/widgets/logbook/graph/logbook.graph-utils';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';
// @ts-ignore
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls/graph-controls.component';
import { getCarbohydratesUnitsLabel } from '../../scenes/graphs/graph-statistics.util';

type logbook24hrsProps = {
  match: match;
  dateCells: string[];
  rowCounts;
  measurements: object[];
  measurementsForToolTip: object[][];
  daysForToolTip: string[];
  bloodGlucoseUnit: string;
  isLoading: boolean;
  t: I18nTranslate;
  showToolTip: () => void;
  hideToolTip: () => void;
  toolTip: ToolTip<any>;
  is12hourTimeFormat: boolean;
  timeFormat: string;
  graph: string;
  collapsed: boolean;
  collapsedGP: boolean;
  isNotExpanded: boolean;
  wrapWidth: number;
  carbUnit: string;
};

type logbook24hrsState = {
  scrolledArea: number;
  availableScrollArea: number | null;
};

const Logbook24hrsWithLoader = withGraphLoader(
  Logbook24hrsLoader,
  'graphs.logbook.loading',
);

/* istanbul ignore next */
class Logbook24HoursComponent extends React.Component<
  logbook24hrsProps,
  logbook24hrsState
> {
  constructor(props) {
    super(props);
    this.state = {
      scrolledArea: 0,
      availableScrollArea: 0,
    };
  }

  public componentDidMount() {
    this.setSelectedRowFromGraphs();
  }

  public render() {
    const {
      match,
      dateCells,
      rowCounts,
      measurements,
      measurementsForToolTip,
      daysForToolTip,
      bloodGlucoseUnit,
      isLoading,
      t,
      showToolTip,
      hideToolTip,
      toolTip,
      is12hourTimeFormat,
      timeFormat,
      graph,
      collapsed,
      collapsedGP,
      carbUnit,
    } = this.props;

    const carbLabel = getCarbohydratesUnitsLabel[carbUnit];

    const totalRowCounts = rowCounts?.reduce(
      (acc, element) => acc + element.rowCount,
      0,
    );
    const noRowCounts = totalRowCounts === 0;

    const allProfilesNotExpanded = [isLoading, collapsed, collapsedGP];

    const isRenderable =
      !isEmpty(measurementsForToolTip) &&
      !noRowCounts &&
      allProfilesNotExpanded.every((element) => element === false);
    const thereIsData = measurements?.length !== 0 && !noRowCounts;

    const isGPArrowShown = collapsedGP && measurements.length !== 0;

    return (
      <>
        <TooltipPreventRender toolTip={toolTip}>
          <RenderIf validate={thereIsData}>
            <GraphControls
              showChangeGraphToggle
              graphType={GRAPHS.LOGBOOK_24HR}
              graph={graph}
              collapsed={collapsed}
              collapsedGP={collapsedGP}
            />
          </RenderIf>
          <Logbook24hrsOuterWrapper hasMeasurement={thereIsData}>
            <RenderIf
              validate={
                (isGPArrowShown && this.state.availableScrollArea! > 0) ||
                (isArrowShown(collapsed, isLoading, measurements) &&
                  this.state.availableScrollArea! > 0)
              }
            >
              {this.renderScrollButtons()}
            </RenderIf>
            <RenderIf validate={isRenderable}>
              <HeaderBackgroundImage />
            </RenderIf>
            <Logbook24hrsInnerWrapper
              id={ID_24H_WRAPPER}
              blueBackground={isRenderable}
              collapsed={collapsed}
              collapsedGP={collapsedGP}
            >
              <Logbook24hrsWithLoader
                hasError={
                  (isEmpty(measurementsForToolTip) && !isLoading) || noRowCounts
                }
                isLoading={isLoading}
              >
                <StickyContainer>
                  <ScrollSync>
                    {({ onScroll, scrollLeft, scrollTop }) => (
                      <Logbook24hrsGridRow graph={graph}>
                        {this.renderDates(
                          match,
                          dateCells,
                          measurements,
                          rowCounts,
                          collapsed,
                          collapsedGP,
                        )}
                        {this.renderMainTable(
                          onScroll,
                          scrollLeft,
                          scrollTop,
                          collapsed,
                          collapsedGP,
                          {
                            match,
                            measurements,
                            rowCounts,
                            is12hourTimeFormat,
                            bloodGlucoseUnit,
                            showToolTip,
                            hideToolTip,
                          },
                        )}
                        {this.renderSummary(
                          onScroll,
                          measurements,
                          rowCounts,
                          bloodGlucoseUnit,
                          collapsed,
                          collapsedGP,
                          t,
                          carbLabel,
                        )}
                      </Logbook24hrsGridRow>
                    )}
                  </ScrollSync>
                </StickyContainer>
              </Logbook24hrsWithLoader>
            </Logbook24hrsInnerWrapper>
          </Logbook24hrsOuterWrapper>
        </TooltipPreventRender>
        <RenderIf validate={this.isValidToolTip(toolTip)}>
          {this.renderToolTip(
            toolTip,
            bloodGlucoseUnit,
            measurementsForToolTip,
            daysForToolTip,
            is12hourTimeFormat,
            timeFormat,
            carbLabel,
          )}
        </RenderIf>
      </>
    );
  }

  /*
   * ************ Renders ************
   */

  private renderDates = (
    match,
    dateCells,
    measurements,
    rowCounts,
    collapsed,
    collapsedGP,
  ) => (
    <DateColumn>
      <Sticky topOffset={STICKY_OFFSET} relative={collapsed || collapsedGP}>
        {({ style, isSticky }) => (
          <DateColumnHeader
            standardRowHeight={STANDARD_ROW_HEIGHT}
            collapsed={collapsed}
            collapsedGP={collapsedGP}
            isSticky={isSticky}
            properStyle={style}
            name={DATES_HEADER_NAME}
          >
            <LocalizedText textKey="graphs.logbook.date" />
          </DateColumnHeader>
        )}
      </Sticky>
      <DateColumnBody>
        <Grid
          width={DATE_COLUMN_WIDTH}
          columnCount={1}
          columnWidth={DATE_COLUMN_WIDTH}
          cellRenderer={this.renderDateCell(match, dateCells, measurements)}
          height={this.tableHeightCalculator(rowCounts)}
          rowCount={rowCounts?.length}
          rowHeight={rowHeight(rowCounts)(STANDARD_ROW_HEIGHT, ROW_GAP)}
          autoHeight={true}
          style={{ overflow: 'hidden' }}
        />
      </DateColumnBody>
    </DateColumn>
  );

  private renderMainTable = (
    onScroll,
    scrollLeft,
    scrollTop,
    collapsed,
    collapsedGP,
    otherProps,
  ) => {
    const {
      measurements,
      rowCounts,
      is12hourTimeFormat,
      bloodGlucoseUnit,
      showToolTip,
      hideToolTip,
    } = otherProps;
    return (
      <BodyColumn collapsed collapsedGP>
        <AutoSizer disableHeight>
          {({ width }) => (
            <>
              <Sticky
                topOffset={STICKY_OFFSET}
                relative={collapsed || collapsedGP}
              >
                {({ style, isSticky }) => (
                  <ColumnSizer
                    width={width}
                    columnMinWidth={TIME_COLUMN_MIN_WIDTH}
                    columnCount={NUMBER_OF_TIME_BLOCKS}
                  >
                    {({ adjustedWidth, getColumnWidth, registerChild }) => (
                      <Grid
                        ref={registerChild}
                        width={adjustedWidth}
                        rowCount={1}
                        rowHeight={STANDARD_ROW_HEIGHT}
                        columnWidth={getColumnWidth}
                        columnCount={NUMBER_OF_TIME_BLOCKS}
                        height={STANDARD_ROW_HEIGHT}
                        cellRenderer={this.renderTimeHeaderCell(
                          is12hourTimeFormat,
                        )}
                        scrollLeft={scrollLeft}
                        onScroll={(e) => this.handleScrollButtonsClick(e)}
                        style={setMainTableHeaderCSS(
                          style,
                          collapsed,
                          isSticky,
                          width,
                        )}
                      />
                    )}
                  </ColumnSizer>
                )}
              </Sticky>
              <ColumnSizer
                width={width}
                columnMinWidth={TIME_COLUMN_MIN_WIDTH}
                columnCount={NUMBER_OF_TIME_BLOCKS}
              >
                {({ adjustedWidth, getColumnWidth, registerChild }) => (
                  <Grid
                    ref={registerChild}
                    width={adjustedWidth}
                    onScroll={onScroll}
                    scrollTop={scrollTop}
                    rowCount={rowCounts?.length}
                    rowHeight={rowHeight(rowCounts)(
                      STANDARD_ROW_HEIGHT,
                      ROW_GAP,
                    )}
                    id={MAIN_TABLE_ID}
                    scrollToAlignment={'start'}
                    scrollToColumn={
                      (collapsed && SCROLL_TO_HRS_COLUMN) ||
                      (collapsedGP && SCROLL_TO_HRS_COLUMN)
                    }
                    columnWidth={getColumnWidth}
                    columnCount={NUMBER_OF_TIME_BLOCKS}
                    height={this.tableHeightCalculator(rowCounts)}
                    cellRenderer={this.renderTimeCell(
                      bloodGlucoseUnit,
                      showToolTip,
                      hideToolTip,
                    )(measurements)}
                    overscanColumnCount={24}
                    autoHeight={true}
                    style={{
                      outline: 'none',
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}
                  />
                )}
              </ColumnSizer>
            </>
          )}
        </AutoSizer>
      </BodyColumn>
    );
  };

  private renderSummary = (
    onScroll,
    measurements,
    rowCounts,
    bloodGlucoseUnit,
    collapsed,
    collapsedGP,
    t,
    carbLabel,
  ) => (
    <SummaryColumn style={{ maxWidth: `${SUMMARY_COLUMN_WIDTH}px` }}>
      <Sticky topOffset={STICKY_OFFSET} relative={collapsed || collapsedGP}>
        {({ style, isSticky }) => (
          <SummaryColumnHeader
            standardRowHeight={STANDARD_ROW_HEIGHT}
            properStyle={style}
            collapsed={collapsed}
            collapsedGP={collapsedGP}
            isSticky={isSticky}
          >
            <LocalizedText textKey="graphs.logbook24hours.summary" />
          </SummaryColumnHeader>
        )}
      </Sticky>
      <SummaryColumnBody>
        <Grid
          width={SUMMARY_COLUMN_WIDTH}
          columnCount={1}
          columnWidth={SUMMARY_COLUMN_WIDTH}
          cellRenderer={this.renderSummaryCell(
            measurements,
            bloodGlucoseUnit,
            t,
            carbLabel,
          )}
          height={this.tableHeightCalculator(rowCounts)}
          rowCount={rowCounts?.length}
          rowHeight={rowHeight(rowCounts)(STANDARD_ROW_HEIGHT, ROW_GAP)}
          onScroll={onScroll}
          style={{ overflow: 'hidden' }}
          autoHeight={true}
        />
      </SummaryColumnBody>
    </SummaryColumn>
  );

  private renderScrollButtons = () => (
    <ScrollButtonsContainer>
      <jello-icon-button
        disabled={!this.state.scrolledArea || null}
        icon-name={JELLO_ICON_NAMES.CHEVRON_LEFT}
        size="XS"
        style={{ marginRight: SCROLL_BUTTONS_MARGIN }}
        onClick={(e) => this.handleScrollButtonsClick(e)}
        {...testId('logbook-24h-collapsed', 'left-arrow')}
      />
      <jello-text size="XS" weight="regular">
        <LocalizedText textKey="graphs.logbook24hours.scrollMessage" />
      </jello-text>
      <jello-icon-button
        disabled={this.disableRightScrollButton() || null}
        icon-name={JELLO_ICON_NAMES.CHEVRON_RIGHT}
        size="XS"
        style={{ marginLeft: SCROLL_BUTTONS_MARGIN }}
        onClick={(e) => this.handleScrollButtonsClick(e)}
        {...testId('logbook-24h-collapsed', 'right-arrow')}
      />
    </ScrollButtonsContainer>
  );

  private renderDateCell =
    (match, dateCells, measurements) =>
    ({ key, rowIndex, style }) => {
      const day = dateCells[rowIndex];
      const selectedRowId = getSelectedRowId(
        match,
        measurements.map(({ originalDate }) => ({
          date: !!originalDate && !!originalDate.ts ? originalDate.ts : null,
        })),
      );

      const isThereHiAndLo = checkIsThereHiAndLo(measurements, rowIndex);

      const isSelected = selectedRowId === rowIndex;
      const {
        bloodGlucose,
        carbohydrates,
        bolusType1Value,
        bolusType2Value,
        bolusType3Value,
      } = measurements[rowIndex].measurementCounts;
      // to avoid empty rows
      if (
        !bloodGlucose &&
        !carbohydrates &&
        !bolusType1Value &&
        !bolusType2Value &&
        !bolusType3Value &&
        !isThereHiAndLo
      )
        return;

      return (
        <div
          key={key}
          style={style}
          id={isSelected ? ID_SELECTED_ROW : undefined}
        >
          <DateOuterCell
            rowGap={ROW_GAP}
            borderTop={rowIndex !== 0}
            highlight={isSelected}
          >
            <DateInnerCell>
              <DateText
                style={
                  day[0] === WEEKEND_DAYS.SATURDAY ||
                  day[0] === WEEKEND_DAYS.SUNDAY
                    ? { color: colors.blue }
                    : { color: colors.black }
                }
              >
                {day[0]}
              </DateText>
              <DateText
                style={
                  day[0] === WEEKEND_DAYS.SATURDAY ||
                  day[0] === WEEKEND_DAYS.SUNDAY
                    ? { color: colors.blue }
                    : { color: colors.black }
                }
              >
                {day[1]}
              </DateText>
            </DateInnerCell>
          </DateOuterCell>
        </div>
      );
    };

  private renderSummaryCell =
    (data, bloodGlucoseUnit, t, carbLabel) =>
    ({ key, rowIndex, style }) => {
      const { totalRows } = data[rowIndex];
      const { averages, totals } = data[rowIndex].measurementStatistics;

      const activeBloodGlucoseUnit = LOGBOOK_UNITS_UNIT[bloodGlucoseUnit];
      const carbohydratesUnit = carbLabel;
      const insulinUnit = LOGBOOK_UNITS_UNIT.INSULIN;
      const {
        bloodGlucose,
        carbohydrates,
        bolusType1Value,
        bolusType2Value,
        bolusType3Value,
      } = data[rowIndex].measurementCounts;

      const isThereHiAndLo = checkIsThereHiAndLo(data, rowIndex);
      const summaryColumnData = [
        {
          validate: bloodGlucose > 0 || isThereHiAndLo,
          icon: <Logbook24hourBgIcon height={6} />,
          value: averages[MEASUREMENT_TYPE.BG],
          labelTextKey: 'graphs.logbook24hours.bgAvg',
          unitTextKey: activeBloodGlucoseUnit,
          rowHeight: totalRows[MEASUREMENT_TYPE.BG],
        },
        {
          validate: carbohydrates > 0,
          icon: <Logbook24hourCarbsIcon height={16} width={3} />,
          value: totals[MEASUREMENT_TYPE.CARBS],
          labelTextKey: 'graphs.logbook24hours.carbsSum',
          unitTextKey: carbohydratesUnit,
          rowHeight: totalRows[MEASUREMENT_TYPE.CARBS],
        },
        {
          validate: bolusType1Value > 0,
          icon: <Logbook24hourInsulin1Icon height={13} />,
          value: totals[MEASUREMENT_TYPE.INS1],
          labelTextKey: `${t('graphs.logbook24hours.bolusSum')}`,
          unitTextKey: insulinUnit,
          rowHeight: totalRows[MEASUREMENT_TYPE.INS1],
        },
        {
          validate: bolusType2Value > 0,
          icon: <Logbook24hourInsulin2Icon height={11} />,
          value: totals[MEASUREMENT_TYPE.INS2],
          labelTextKey: `${t('graphs.logbook24hours.basalSum')}`,
          unitTextKey: insulinUnit,
          rowHeight: totalRows[MEASUREMENT_TYPE.INS2],
        },
        {
          validate: bolusType3Value > 0,
          icon: <Logbook24hourInsulin3Icon height={11} />,
          value: totals[MEASUREMENT_TYPE.INS3],
          labelTextKey: `${t('graphs.logbook24hours.otherSum')}`,
          unitTextKey: insulinUnit,
          rowHeight: totalRows[MEASUREMENT_TYPE.INS3],
        },
      ];

      const renderDataValue = (value) => (Number.isNaN(value) ? '--' : value);

      return (
        <div key={key} style={style}>
          <SummaryOuterCell rowGap={ROW_GAP}>
            <InnerCell>
              {summaryColumnData.map((data, index) => (
                <RenderIf
                  validate={data.validate}
                  key={`summary-cell-${index}`}
                >
                  <SummaryCell
                    rowHeight={data.rowHeight}
                    borderTop={rowIndex !== 0}
                  >
                    <SummaryLabelContainer>
                      <SummaryColumnIconContainer>
                        {data.icon}
                      </SummaryColumnIconContainer>
                      <SummaryLabelCell>
                        <LocalizedText textKey={data.labelTextKey} />
                      </SummaryLabelCell>
                    </SummaryLabelContainer>
                    <SummaryValueContainer>
                      <SummaryValueCell>
                        {renderDataValue(data.value)}
                      </SummaryValueCell>
                      <SummaryLabelCell>
                        <LocalizedText textKey={data.unitTextKey} />
                      </SummaryLabelCell>
                    </SummaryValueContainer>
                  </SummaryCell>
                </RenderIf>
              ))}
            </InnerCell>
          </SummaryOuterCell>
        </div>
      );
    };

  private renderTimeCell =
    (bloodGlucoseUnit, showToolTip, hideToolTip) =>
    (data) =>
    ({ columnIndex, key, rowIndex, style }) => {
      const { totalRows, rowVariant } = data[rowIndex];

      const {
        bloodGlucose,
        carbohydrates,
        bolusType1Value,
        bolusType2Value,
        bolusType3Value,
      } = data[rowIndex][MEASUREMENTS_GROUPED_BY_HOURS];

      const listOfMeasurementsGroupedByType = [
        { bloodGlucose },
        { carbohydrates },
        { bolusType1Value },
        { bolusType2Value },
        { bolusType3Value },
      ];

      const listOfDifferences = {
        bloodGlucose:
          totalRows[MEASUREMENT_TYPE.BG] - bloodGlucose[columnIndex]?.length,
        carbohydrates:
          totalRows[MEASUREMENT_TYPE.CARBS] -
          carbohydrates[columnIndex]?.length,
        bolusType1Value:
          totalRows[MEASUREMENT_TYPE.INS1] -
          bolusType1Value[columnIndex]?.length,
        bolusType2Value:
          totalRows[MEASUREMENT_TYPE.INS2] -
          bolusType2Value[columnIndex]?.length,
        bolusType3Value:
          totalRows[MEASUREMENT_TYPE.INS3] -
          bolusType3Value[columnIndex]?.length,
      };

      return (
        <div key={key} style={style}>
          <TimeOuterCell
            rowGap={ROW_GAP}
            onMouseOver={(e) =>
              showToolTip(e, {
                rowIndex,
                columnIndex,
              })
            }
            onMouseOut={hideToolTip}
          >
            <MeasurementOuterCell className={rowVariant} variant={rowVariant}>
              <BorderTop borderTop={rowIndex !== 0}>
                {listOfMeasurementsGroupedByType.map(
                  (measurementsGroupedByType, key) => {
                    const measurementType = Object.keys(
                      measurementsGroupedByType,
                    )[0];
                    return (
                      <MeasurementCellGroup
                        key={key}
                        measurements24hours={
                          measurementsGroupedByType[measurementType][
                            columnIndex
                          ]
                        }
                        measurementType={measurementType}
                        cellKey={key}
                        measurementRowDifference={
                          listOfDifferences[measurementType]
                        }
                        bloodGlucoseUnit={bloodGlucoseUnit}
                      />
                    );
                  },
                )}
              </BorderTop>
            </MeasurementOuterCell>
          </TimeOuterCell>
        </div>
      );
    };

  private renderTimeHeaderCell =
    (is12hourTimeFormat) =>
    ({ columnIndex, key, style }) => {
      const formattedHour = formatHour(columnIndex, is12hourTimeFormat);

      return (
        <TimeHeaderCell
          key={key}
          style={style}
          standardRowHeight={STANDARD_ROW_HEIGHT}
        >
          {`${formattedHour}`}
        </TimeHeaderCell>
      );
    };

  private readonly renderToolTip = (
    toolTip,
    bloodGlucoseUnit,
    measurementsForToolTip,
    daysForToolTip,
    is12hourTimeFormat,
    timeFormat,
    carbLabel,
  ) => {
    const { rowIndex = null, columnIndex = null } = { ...toolTip?.data };
    if (isNil(rowIndex) && isNil(columnIndex)) return null;

    let measurements = [];
    if (
      !!measurementsForToolTip &&
      !!measurementsForToolTip[rowIndex] &&
      !!measurementsForToolTip[rowIndex][columnIndex]
    ) {
      measurements = measurementsForToolTip[rowIndex][columnIndex];
    } else {
      return null;
    }
    if (measurements.length === 0) return null;

    const measurementsFormattedForToolTip = getMealTimeModalMeasurements(
      { measurements },
      bloodGlucoseUnit,
      carbLabel,
    );

    return (
      <HourToolTip
        x={toolTip.x}
        y={toolTip.y}
        hour={formatHour(columnIndex, is12hourTimeFormat)}
        day={daysForToolTip[rowIndex]}
        measurements={measurementsFormattedForToolTip}
        timeFormat={timeFormat}
      />
    );
  };

  /*
   * ************ Helpers ************
   */

  private setSelectedRowFromGraphs = () => {
    const selectedRow = document.getElementById(ID_SELECTED_ROW);
    if (selectedRow) {
      const selectedRowTop = selectedRow.getBoundingClientRect().top;
      const scrollToY =
        selectedRowTop -
        window.innerHeight +
        selectedRow.offsetHeight * scrollTo.Yincrement;
      window.scrollBy(scrollTo.X, scrollToY);
    }
  };

  private handleScrollButtonsClick = (e) => {
    const mainTable = document.getElementById(MAIN_TABLE_ID);
    const availableScrollArea =
      mainTable && Math.abs(mainTable.clientWidth - mainTable.scrollWidth);

    if (e.target) {
      if (e.target.iconName.includes(RIGHT_BUTTON)) {
        this.setState(
          { scrolledArea: this.state.scrolledArea + TIME_COLUMN_MIN_WIDTH },
          () => (mainTable!.scrollLeft = this.state.scrolledArea),
        );
      } else {
        this.setState(
          { scrolledArea: this.state.scrolledArea - TIME_COLUMN_MIN_WIDTH },
          () => (mainTable!.scrollLeft = this.state.scrolledArea),
        );
      }
    } else {
      this.setState({ scrolledArea: mainTable!.scrollLeft });
    }
    this.setState({ availableScrollArea });
  };

  private disableRightScrollButton = () => {
    return (
      this.state.scrolledArea > 0 &&
      Math.abs(this.state.availableScrollArea! - this.state.scrolledArea) === 0
    );
  };

  private readonly isValidToolTip = (toolTip) =>
    !isNil(toolTip?.x) && !isNil(toolTip?.y) && !isEmpty({ ...toolTip?.data });

  private tableHeightCalculator = (totalRows) =>
    totalRows
      .map((rows) => rows?.rowCount)
      .reduce((acc, currentVal) => acc + currentVal * MIN_CELL_HEIGHT, 0);
}

export const Logbook24Hours = withTranslation(Logbook24HoursComponent);
