import {
  MIN_BG,
  BLOOD_GLUCOSE_UNITS,
} from 'src/domains/diagnostics/store/constants';
import { calculateBloodGlucoseIndex } from 'src/domains/diagnostics/utils/graph-statistics.util';
import { average } from 'src/utils/stat';
import { convertBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

export const roundToNDecimalPlaces = (number, n) => {
  const factor = Math.pow(10, n);
  return Math.round(number * factor) / factor;
};

export const calculateLowBloodGlucoseIndex = (measurements, bloodGlucoseUnit) =>
  average(
    measurements.map((measurement) => {
      const measurementValue = convertBGMeasurement(
        measurement.value,
        bloodGlucoseUnit,
        BLOOD_GLUCOSE_UNITS.MG_PER_DL,
      );
      return measurementValue <= MIN_BG
        ? calculateBloodGlucoseIndex(measurementValue).bgIndex
        : 0;
    }),
  );

export const calculateHighBloodGlucoseIndex = (
  measurements,
  bloodGlucoseUnit,
) =>
  average(
    measurements.map((measurement) => {
      const measurementValue = convertBGMeasurement(
        measurement.value,
        bloodGlucoseUnit,
        BLOOD_GLUCOSE_UNITS.MG_PER_DL,
      );
      return measurementValue > MIN_BG
        ? calculateBloodGlucoseIndex(measurementValue).bgIndex
        : 0;
    }),
  );

const GRAPHS_LABEL_G = 'graphs.logbook.g';
const GRAPHS_LABEL_EX = 'graphs.exchangedLabel';

const CARBOHYDRATES_VALUES = {
  GRAMS: 'GRAMS',
  G10: 'G10',
  G12: 'G12',
  G15: 'G15',
  G20: 'G20',
};

export const getCarbohydratesUnitsLabel = {
  [CARBOHYDRATES_VALUES.GRAMS]: GRAPHS_LABEL_G,
  [CARBOHYDRATES_VALUES.G10]: GRAPHS_LABEL_EX,
  [CARBOHYDRATES_VALUES.G12]: GRAPHS_LABEL_EX,
  [CARBOHYDRATES_VALUES.G15]: GRAPHS_LABEL_EX,
  [CARBOHYDRATES_VALUES.G20]: GRAPHS_LABEL_EX,
};

export const carbDecimalsAccordingToCarbUnit = {
  [CARBOHYDRATES_VALUES.GRAMS]: 0,
  [CARBOHYDRATES_VALUES.G10]: 1,
  [CARBOHYDRATES_VALUES.G12]: 1,
  [CARBOHYDRATES_VALUES.G15]: 1,
  [CARBOHYDRATES_VALUES.G20]: 1,
};
