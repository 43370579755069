/* tslint:disable */

import { createStructuredSelector, createSelector, Selector } from 'reselect';
import { pathOr } from 'ramda';

import { DeviceSetting, BasalSettings } from '../../device-settings.types';
import {
  selectLatestDeviceSetting,
  selectPreviousDeviceSetting,
} from '../../device-settings.selector';

export const selectMeterSettings: Selector<DeviceSetting[], BasalSettings> =
  createSelector(
    [selectLatestDeviceSetting],
    (latestSetting: DeviceSetting) => {
      const meterSettings = pathOr(
        {},
        ['settings', 'Device', 'UnitsSettings'],
        latestSetting,
      );
      const deviceInfo = pathOr(
        {},
        ['settings', 'Device', 'DeviceInformation'],
        latestSetting,
      );
      return {
        DeviceType: deviceInfo.DeviceType || undefined,
        ...meterSettings,
      };
    },
  );

export const selectPreviousMeterSettings: Selector<any, any> = createSelector(
  [selectPreviousDeviceSetting],
  (latestSetting: DeviceSetting) => {
    const meterSettings = pathOr(
      {},
      ['settings', 'Device', 'UnitsSettings'],
      latestSetting,
    );
    const deviceInfo = pathOr(
      {},
      ['settings', 'Device', 'DeviceInformation'],
      latestSetting,
    );
    return { DeviceType: deviceInfo.DeviceType || undefined, ...meterSettings };
  },
);

export const selectInsulinDeliverySettings: Selector<any, any> = createSelector(
  [selectLatestDeviceSetting],
  (latestSetting: DeviceSetting) => {
    const pumpSettings = pathOr(
      {},
      ['settings', 'PumpSettings'],
      latestSetting,
    );
    delete pumpSettings.BolusIncrement;
    return pumpSettings;
  },
);

export const selectPreviousInsulinDeliverySettings: Selector<any, any> =
  createSelector(
    [selectPreviousDeviceSetting],
    (latestSetting: DeviceSetting) => {
      const pumpSettings = pathOr(
        {},
        ['settings', 'PumpSettings'],
        latestSetting,
      );
      delete pumpSettings.BolusIncrement;
      return pumpSettings;
    },
  );

export const selectBolusDeliverySettings: Selector<any, any> = createSelector(
  [selectLatestDeviceSetting],
  (latestSetting: DeviceSetting) => {
    const bolusSettings = pathOr(
      {},
      ['settings', 'BolusSettings'],
      latestSetting,
    );
    delete bolusSettings.BolusAdvice;
    return bolusSettings;
  },
);

export const selectPreviousBolusDeliverySettings: Selector<any, any> =
  createSelector(
    [selectPreviousDeviceSetting],
    (latestSetting: DeviceSetting) => {
      const bolusSettings = pathOr(
        {},
        ['settings', 'BolusSettings'],
        latestSetting,
      );
      delete bolusSettings.BolusAdvice;
      return bolusSettings;
    },
  );

export const deviceSettingsGeneralConnector = () => {
  return createStructuredSelector({
    meterSettings: selectMeterSettings,
    insulinDeliverySettings: selectInsulinDeliverySettings,
    bolusDeliverySettings: selectBolusDeliverySettings,
    previousInsulinDeliverySettings: selectPreviousInsulinDeliverySettings,
    previousBolusDeliverySettings: selectPreviousBolusDeliverySettings,
    previousMeterSettings: selectPreviousMeterSettings,
  });
};
