import { validateSessionStart } from 'src/app/session/core/session/session.actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mapDispatchers } from 'src/utils/map-dispatchers';

import { ProtectedRoute as ProtectedRouteComponent } from './protected-route.component';
import { protectedRouteConnector } from './protected-route.selectors';

export const ProtectedRoute = compose(
  withRouter,
  connect(
    protectedRouteConnector,
    mapDispatchers({
      validateSession: validateSessionStart,
    }),
  ),
)(ProtectedRouteComponent);
