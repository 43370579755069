import React from 'react';
import { testId } from '@roche/roche-common';
import { useCustomEventListener } from 'src/hooks';

type Option = {
  label: string;
  value: string;
};

type OptionPickerProps = {
  id: string;
  backgroundColor?: string;
  onChange: (e) => void;
  onClick?: () => void;
  options: Option[];
  value?: string;
  accessibilityText?: string;
  label?: string;
  selectedIndex?: string;
  disabled?: boolean;
};

export const JelloOptionPicker: React.FunctionComponent<OptionPickerProps> = (
  props,
) => {
  const {
    id,
    backgroundColor,
    label,
    onChange,
    onClick,
    options,
    value,
    selectedIndex,
    accessibilityText,
    disabled,
  } = props;

  const optionPickerRef = useCustomEventListener(
    'jello-option-picker-change',
    onChange,
  );

  return (
    <>
      <jello-option-picker
        id={id}
        background-color={backgroundColor}
        options={JSON.stringify(options)}
        label={label}
        value={value}
        selected-index={selectedIndex}
        accessibility-text={accessibilityText}
        ref={optionPickerRef}
        onClick={onClick}
        {...(disabled ? { disabled: '' } : null)}
        {...testId('jello-option-picker', id)}
      />
    </>
  );
};
