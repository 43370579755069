import { OidcActionTypes, OidcReducerActions } from '../oidc/oidc.types';

import {
  SessionActionType,
  SessionReducerActions,
  SessionState,
} from './session.types';

export const INITIAL_SESSION_STATE: SessionState = {
  legacyToken: '',
  error: null,
  accessToken: '',
  refreshToken: '',
  expiration: null,
  ttlLength: -1,
  isSignedOut: true,
  isValidSession: false,
  successRedirect: '',
  comeFromLogin: false,
  isGettingToken: false,
  isSigningout: false,
};

const handleComeFromLogin = (state: SessionState) => ({
  ...state,
  comeFromLogin: true,
});

const handleLoginSuccess = (state: SessionState) => ({
  ...state,
  isSignedOut: false,
  isValidSession: true,
  comeFromLogin: false,
});

const handleValidateSessionSuccess = (
  state: SessionState,
  action: SessionReducerAction,
) => ({
  ...state,
  ...action.payload,
  error: null,
  isValidSession: true,
  isSignedOut: false,
  comeFromLogin: false,
});

const handleValidateSessionError = (
  state: SessionState,
  action: SessionReducerAction,
) => ({
  ...state,
  legacyToken: '',
  error: action.payload,
  refreshToken: '',
  isValidSession: false,
  comeFromLogin: false,
});

const handleSignOutStart = (state: SessionState) => ({
  ...state,
  isSigningout: true,
});

const handleSignOutErrorSuccess = (state: SessionState) => ({
  ...INITIAL_SESSION_STATE,
  isSignedOut: true,
});

const handleOidcFetchTokensStart = (state: SessionState) => ({
  ...state,
  isGettingToken: true,
});

const handleOidcFetchTokensSuccess = (
  state: SessionState,
  action: SessionReducerAction,
) => ({
  ...state,
  error: null,
  isGettingToken: false,
  ...action.payload,
});

const handleOidcFetchTokensError = (
  state: SessionState,
  action: SessionReducerAction,
) => ({
  ...state,
  error: action.payload,
  comeFromLogin: false,
  isGettingToken: false,
});

const handleSetSuccessRedirect = (
  state: SessionState,
  action: SessionReducerAction,
) => ({
  ...state,
  successRedirect: action.payload,
});

const actionHandlers = {
  [SessionActionType.COME_FROM_LOGIN]: handleComeFromLogin,
  [SessionActionType.LOGIN_SUCCESS]: handleLoginSuccess,
  [SessionActionType.VALIDATE_SESSION_SUCCESS]: handleValidateSessionSuccess,
  [SessionActionType.VALIDATE_SESSION_ERROR]: handleValidateSessionError,
  [SessionActionType.SIGN_OUT_START]: handleSignOutStart,
  [SessionActionType.SIGN_OUT_ERROR]: handleSignOutErrorSuccess,
  [SessionActionType.SIGN_OUT_SUCCESS]: handleSignOutErrorSuccess,
  [OidcActionTypes.OIDC_FETCH_TOKENS_START]: handleOidcFetchTokensStart,
  [OidcActionTypes.REFRESH_OIDC_TOKENS_START]: handleOidcFetchTokensStart,
  [OidcActionTypes.OIDC_FETCH_TOKENS_SUCCESS]: handleOidcFetchTokensSuccess,
  [OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS]: handleOidcFetchTokensSuccess,
  [OidcActionTypes.REFRESH_OIDC_TOKENS_ERROR]: handleOidcFetchTokensError,
  [OidcActionTypes.OIDC_FETCH_TOKENS_ERROR]: handleOidcFetchTokensError,
  [SessionActionType.SET_SUCCESS_REDIRECT]: handleSetSuccessRedirect,
};

type SessionReducerAction = SessionReducerActions | OidcReducerActions | FixMe;
export const sessionReducer = (
  state = INITIAL_SESSION_STATE,
  action: SessionReducerAction = '',
): SessionState => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};
