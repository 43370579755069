import { createSelector, createStructuredSelector } from 'reselect';
import { map, pipe, prop, flatten, reduce, addIndex, values } from 'ramda';

import {
  selectGlucoseMeasurementsIncludingNullValues,
  selectAllGraphThresholds,
  selectGraphLoading,
  selectInsulin,
  selectTimeIntervals,
  selectBoluses,
} from 'src/domains/diagnostics/scenes/graphs/logbook/logbook.core.selector';
import {
  splitDayByDelimiter,
  countRows,
  groupByDayAndTimeAndType,
  convertMeasurementDateToGMT,
  reduceMeasurements,
  calculateRowPatternStyle,
  generateEmpty24HourArray,
} from './logbook-24hrs.util';
import { selectBloodGlucoseUnit } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { reduceIndexed } from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.util';
import { logbookDiaryRowDataTransformer } from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.utils';
import { convertJSDateGMT } from 'src/utils/date';
import {
  selectIs12HourFormat,
  selectEC6TimeFormat,
  selectCarbsUserUnitMeasurement,
} from 'src/core/user/user.selectors';
import { selectCarbUnitMeasurementForService } from 'src/core/patient-date-range/patient-date-range.selector';
import { BLOOD_GLUCOSE_UNITS_TYPES } from '../logbook-diary/logbook-diary.types';
import { groupMeasurementsByDay } from 'src/domains/diagnostics/widgets/logbook/meal-time/grouping/meal-time.data-grouping';
const mapIndexed = addIndex(map);

const selectMeasurementsByDayAndTypeAndHour = createSelector(
  selectGlucoseMeasurementsIncludingNullValues,
  selectInsulin,
  selectTimeIntervals,
  selectAllGraphThresholds,
  selectBloodGlucoseUnit,
  selectCarbsUserUnitMeasurement,
  (
    measurements,
    insulin,
    timeIntervals,
    allThresholds,
    bloodGlucoseUnit: BLOOD_GLUCOSE_UNITS_TYPES,
    carbsUserUnitMeasurement,
  ) => {
    const { basals, bolus } = insulin;
    const flattenedMeasurements = flatten([measurements, basals, bolus]);

    return pipe(
      reduceIndexed(
        logbookDiaryRowDataTransformer(
          timeIntervals as any,
          allThresholds,
          bloodGlucoseUnit,
          carbsUserUnitMeasurement,
        ),
        [],
      ),
      map(convertMeasurementDateToGMT),
      reduce(reduceMeasurements, {}),
      groupByDayAndTimeAndType,
    )(flattenedMeasurements);
  },
);

const selectDateCells = createSelector(
  selectMeasurementsByDayAndTypeAndHour,
  pipe(map(prop('dayDate')), map(splitDayByDelimiter)),
);

const selectRowCounts = createSelector(
  selectMeasurementsByDayAndTypeAndHour,
  countRows,
);

const applyPatternToMeasurementRows = createSelector(
  selectMeasurementsByDayAndTypeAndHour,
  mapIndexed(calculateRowPatternStyle),
);

const selectMeasurementsByDayForToolTip = createSelector(
  selectGlucoseMeasurementsIncludingNullValues,
  selectBoluses,
  selectTimeIntervals,
  selectAllGraphThresholds,
  (measurements, bolusesWithoutJSDate, timeIntervals, allThresholds) => {
    const boluses = bolusesWithoutJSDate.map((bolus) => ({
      ...bolus,
      date: new Date(bolus.date.ts),
      value: Number(bolus.bolusValue.toFixed(2)),
    }));

    return groupMeasurementsByDay(
      measurements,
      boluses,
      allThresholds,
      timeIntervals,
    );
  },
);

const selectDaysForToolTip = createSelector(
  selectMeasurementsByDayForToolTip,
  pipe(
    map((measurements) =>
      convertJSDateGMT(measurements[0]?.date).toFormat('dd LLL yy'),
    ),
    values,
  ),
);

const selectMeasurementsForToolTip = createSelector(
  selectMeasurementsByDayForToolTip,
  (measurementsByDay) => {
    const measurementsGroupedByDayAndHour = map((measurements) => {
      const hours = generateEmpty24HourArray();
      measurements.forEach((measurement) => {
        const hour = convertJSDateGMT(measurement.date).hour.toString();
        hours[hour].push(measurement);
      });
      return hours;
    })(measurementsByDay);

    return values(measurementsGroupedByDayAndHour);
  },
);

export const logbook24HoursConnector = createStructuredSelector({
  dateCells: selectDateCells,
  rowCounts: selectRowCounts,
  measurements: applyPatternToMeasurementRows,
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  isLoading: selectGraphLoading,
  measurementsForToolTip: selectMeasurementsForToolTip,
  daysForToolTip: selectDaysForToolTip,
  is12hourTimeFormat: selectIs12HourFormat,
  timeFormat: selectEC6TimeFormat,
  carbUnit: selectCarbUnitMeasurementForService,
});
