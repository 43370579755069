import { getConfig } from '@roche/roche-common';

export const trimVersionNumberToOnlyMajorMinor = (versionNumber) =>
  versionNumber.match(/(\d+.\d+)/)[0];

export const appVersion = getConfig().REACT_APP_VERSION
  ? getConfig().REACT_APP_VERSION
  : '';

export const appAccVersion = getConfig().REACT_APP_ACC_VERSION || '';

export const getCurrentAppVersion = (rebranding: boolean) =>
  rebranding ? appAccVersion : appVersion;

export const appendSuffixIfRebranded = (
  baseName: string,
  isRebranded: boolean,
) => `${baseName}${isRebranded ? '_ACC' : ''}`;
