import { ICON_SIZES } from '@rdcs/jello-ds/components/icon/constants.js';
import {
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from '@rdcs/jello-ds/components/button/constants.js';
import {
  CHIP_SIZES,
  CHIP_COLORS,
} from '@rdcs/jello-ds/components/chip/constants.js';
import {
  TEXT_SIZES,
  TEXT_WEIGHTS,
} from '@rdcs/jello-ds/components/text/constants.js';
import {
  HEADING_WEIGHTS,
  HEADING_SIZES,
  HEADING_LEVELS,
} from '@rdcs/jello-ds/components/heading/constants.js';
import {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
} from '@rdcs/jello-ds/lib/constants/positions';
import { START, CENTER, END } from '@rdcs/jello-ds/lib/constants/alignments';
import {
  ICON_BUTTON_SIZES,
  ICON_BUTTON_VARIANTS,
} from '@rdcs/jello-ds/components/icon-button/constants.js';
import {
  BOX_BACKGROUND_COLORS,
  BOX_BORDER_RADIUS_SIZES,
} from '@rdcs/jello-ds/components/box/constants.js';
import {
  TOOLBAR_BORDER_POSITIONS,
  TOOLBAR_SIZES,
  TOOLBAR_ORIENTATIONS,
} from '@rdcs/jello-ds/components/toolbar/constants';

import { ALERT_VARIANTS } from '@rdcs/jello-ds/components/alert/constants';

import { TABS_POSITIONS } from '@rdcs/jello-ds/components/tabs/constants';

import { TOOLTIP_POSITIONS } from '@rdcs/jello-ds/components/tooltip/constants';

import { OPTION_PICKER_BACKGROUND_COLORS } from '@rdcs/jello-ds/components/option-picker/constants';

import { LOADING_SIZES } from '@rdcs/jello-ds/components/loading/constants';

import { TOGGLE_LABEL_POSITIONS } from '@rdcs/jello-ds/components/toggle/constants';

import { SEARCH_BOX_SIZES } from '@rdcs/jello-ds/components/search-box/constants';

import {
  POPOVER_ALIGNMENTS,
  POPOVER_POSITIONS,
} from '@rdcs/jello-ds/components/popover/constants';

import {
  AVATAR_SIZES,
  AVATAR_COLORS,
} from '@rdcs/jello-ds/components/avatar/constants';

import { DATA_UNIT_SIZES } from '@rdcs/jello-ds/components/data-unit/constants';

import { JELLO_ICON_NAMES as JELLO_ICON_NAMES_CONSTANTS } from '../setupIcons';

import {
  AGP_RANGE_NAMES,
  AGP_PERCENTILE_NAMES,
} from '@rdcs/jello-graphs-cgm/agp.js';

import { DAILY_VIEW_VARIANTS } from '@rdcs/jello-graphs-cgm/daily-view.js';

export const JELLO_GENERAL_ALIGNMENTS = {
  START,
  CENTER,
  END,
};

export const JELLO_GENERAL_POSITIONS = {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
};

export const JELLO_ICON_SIZES = ICON_SIZES;

export const JELLO_ICON_BUTTON_SIZES = ICON_BUTTON_SIZES;

export const JELLO_ICON_BUTTON_VARIANTS = ICON_BUTTON_VARIANTS;

export const JELLO_BUTTON_SIZES = BUTTON_SIZES;

export const JELLO_BUTTON_VARIANTS = BUTTON_VARIANTS;

export const JELLO_CHIP_SIZES = CHIP_SIZES;

export const JELLO_CHIP_COLOR = CHIP_COLORS;

export const JELLO_TEXT_SIZES = TEXT_SIZES;

export const JELLO_TEXT_WEIGHTS = TEXT_WEIGHTS;

export const JELLO_HEADINGS_LEVELS = HEADING_LEVELS;

export const JELLO_HEADINGS_SIZES = HEADING_SIZES;

export const JELLO_HEADINGS_WEIGHTS = HEADING_WEIGHTS;

export const JELLO_ICON_NAMES = JELLO_ICON_NAMES_CONSTANTS;

export const JELLO_BOX_BACKGROUND = BOX_BACKGROUND_COLORS;

export const JELLO_BOX_BORDER_RADIUS = BOX_BORDER_RADIUS_SIZES;

export const JELLO_TOOLBAR_BORDER_POSITIONS = TOOLBAR_BORDER_POSITIONS;

export const JELLO_TOOLBAR_SIZES = TOOLBAR_SIZES;

export const JELLO_TOOLBAR_ORIENTATIONS = TOOLBAR_ORIENTATIONS;

export const JELLO_OPTION_PICKER_BACKGROUND = OPTION_PICKER_BACKGROUND_COLORS;

export const JELLO_ALERT_VARIANTS = ALERT_VARIANTS;

export const JELLO_LOADING_SIZES = LOADING_SIZES;

export const JELLO_TOGGLE_LABEL_POSITIONS = TOGGLE_LABEL_POSITIONS;

export const JELLO_TABS_POSITIONS = TABS_POSITIONS;

export const JELLO_SEARCHBOX_SIZES = SEARCH_BOX_SIZES;

export const JELLO_TOOLTIP_POSITIONS = TOOLTIP_POSITIONS;

export const JELLO_POPOVER_ALIGNMENTS = POPOVER_ALIGNMENTS;

export const JELLO_POPOVER_POSITIONS = POPOVER_POSITIONS;

export const JELLO_AVATAR_SIZES = AVATAR_SIZES;

export const JELLO_AVATAR_COLORS = AVATAR_COLORS;

export const JELLO_DATA_UNIT_SIZES = DATA_UNIT_SIZES;

export const JELLO_AGP_RANGE_NAMES = AGP_RANGE_NAMES;

export const JELLO_AGP_PERCENTILE_NAMES = AGP_PERCENTILE_NAMES;

export const JELLO_DAILY_VIEW_VARIANTS = DAILY_VIEW_VARIANTS;
