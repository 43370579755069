import { groupBy, head, map, pipe } from 'ramda';

const groupByModelReference = groupBy(
  ({ stripModelReference }) => stripModelReference,
);

const flattenStripModel = ({
  stripModel: { stripModelReference },
  currentStock,
  lastShipmentAt,
  numberOfStripsReceived,
}) => ({
  stripModelReference,
  currentStripStock: currentStock,
  lastShipmentDate: lastShipmentAt,
  lastShipmentNumberOfStripsReceived: numberOfStripsReceived,
});

export const serverToClientStripModelStocks = pipe(
  map(flattenStripModel),
  groupByModelReference,
  map(head),
);
