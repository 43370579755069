import React from 'react';

import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { BG_UNIT_KEYS } from 'src/domains/diagnostics/store/constants';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

import {
  ContainerDiv,
  TopBarDiv,
  DateWrapper,
  MeasurementsWrapper,
  Measurement,
  MeasurementLabel,
  MeasurementValue,
  MeasurementUnit,
} from './metabolic-tooltip.style';

export const MetabolicTooltip = ({ date, mean, stdDev, bloodGlucoseUnit }) => (
  <ContainerDiv>
    <TopBarDiv />
    <RenderIf validate={date}>
      <DateWrapper>{date}</DateWrapper>
    </RenderIf>
    <MeasurementsWrapper>
      <Measurement>
        <MeasurementLabel>
          <LocalizedText textKey="graphs.metabolicGraph.tooltip.meanbg" />
        </MeasurementLabel>
        <MeasurementValue>
          {formatBGMeasurement(bloodGlucoseUnit)(mean)}
          <MeasurementUnit>
            <LocalizedText textKey={BG_UNIT_KEYS[bloodGlucoseUnit]} />
          </MeasurementUnit>
        </MeasurementValue>
      </Measurement>
      <Measurement>
        <MeasurementLabel>
          <RenderIf validate={date}>
            <LocalizedText textKey="graphs.metabolicGraph.tooltip.standardDeviation" />
          </RenderIf>
          <RenderIf validate={!date}>
            <LocalizedText textKey="graphs.metabolicGraph.tooltip.meanStandardDeviation" />
          </RenderIf>
        </MeasurementLabel>
        <MeasurementValue>
          {formatBGMeasurement(bloodGlucoseUnit)(stdDev)}
          <MeasurementUnit>
            <LocalizedText textKey={BG_UNIT_KEYS[bloodGlucoseUnit]} />
          </MeasurementUnit>
        </MeasurementValue>
      </Measurement>
    </MeasurementsWrapper>
  </ContainerDiv>
);
