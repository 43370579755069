export const mockThresholds = {
  resultDescription: 'getThresholdOK',
  model: {
    noctLow: '80',
    preLow: '80',
    postHyper: '240',
    noctHyper: '240',
    preHipo: '70',
    unit: 'mg/dL',
    preHigh: '125',
    postHipo: '70',
    postLow: '80',
    preHyper: '240',
    postHigh: '95',
    noctHipo: '70',
    noctHigh: '95',
  },
};

export const mockThresholdsTransformed = {
  actualHyper: {
    preIdealInterval: '240',
    postIdealInterval: '240',
    noctIdealInterval: '240',
  },
  hyper: {
    preIdealInterval: '125',
    postIdealInterval: '95',
    noctIdealInterval: '95',
  },
  hypo: {
    preIdealInterval: '70',
    postIdealInterval: '70',
    noctIdealInterval: '70',
  },
  warning: {
    preIdealInterval: '80',
    postIdealInterval: '80',
    noctIdealInterval: '80',
  },
};
