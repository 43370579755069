import React from 'react';
import { TFunction } from 'i18next';

import { GraphSettingsWrapper } from './graph-settings-row.style';

type Props = {
  graphType: GraphType,
  logbookType: LogbookType,
  changeGraphType: (GraphType) => void,
  changeLogbookType: (LogbookType) => void,
  onDatesChange: (startDate: Date, endDate: Date) => void,
  startDate?: Date,
  endDate?: Date,
  t: TFunction,
};

export const GraphSettingsRowComponent = (props: Props) => {
  const { children } = props;
  return <GraphSettingsWrapper>{children}</GraphSettingsWrapper>;
};
