import styled from 'styled-components';

import { colors } from 'src/core/styles/colors';

const { brandBlue } = colors;
interface ChipWrapperProps {
  backgroundColor?: string;
}
export const ChipWrapper = styled.span<ChipWrapperProps>`
  display: flex;
  min-height: 24px;
  min-width: 96px;
  border-radius: 12px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : brandBlue};
  justify-content: center;
  align-content: stretch;
  align-items: center;
  color: #fff;
  font-family: Nunito;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  outline: 0;
  cursor: pointer;
  padding: 4px 0;
  &:focus {
    outline: 0;
  }
`;

ChipWrapper.displayName = 'ChipWrapper';
