// @ts-nocheck
import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { formatDateString, convertISO } from 'src/utils/date';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const ClinicalDataLoaderImpl = (
  { patientId, startDate, endDate },
  accessToken: string,
  gigyaToken: string,
) => {
  if (startDate === 'Invalid DateTime' && endDate === 'Invalid DateTime') {
    startDate = '1969-11-24';
    endDate = '1970-01-01';
  }
  return getJSON(endpointWithParams(ENDPOINTS.clinicalData, { patientId }), {
    headers: {
      rangeStartDate: formatDateString({
        dateString: startDate,
        format: 'yyyy-LL-dd',
        timeZone: 'Etc/GMT+0',
      }),
      rangeEndDate: formatDateString({
        dateString: endDate,
        format: 'yyyy-LL-dd',
        timeZone: 'Etc/GMT+0',
      }),
      Authorization: createAuthHeader(accessToken),
    },
  });
};

export const ClinicalDataTransformImpl = (results) => {
  const { unit } = results.model;

  const measurements = results.model.glucoseValues.map(
    ({
      advicedBolus,
      afterMeal,
      beforeMeal,
      carbohydrates,
      date,
      flagAboveTargetRange,
      flagBelowTargetRange,
      flagManuallyEntered,
      glucose,
      hypoSymptoms,
      control,
      insulin1,
      insulin2,
      insulin3,
      events,
      rangeType,
    }) => ({
      aboveTargetRange: flagAboveTargetRange,
      advicedBolus,
      afterMeal,
      beforeMeal,
      belowTargetRange: flagBelowTargetRange,
      carbohydrates,
      date: convertISO(date).toJSDate(),
      hypoSymptoms,
      value: glucose,
      bloodGlucoseUnit: unit,
      manuallyEntered: flagManuallyEntered,
      control,
      insulin1,
      insulin2,
      insulin3,
      events,
      rangeType,
    }),
  );

  // USE THIS CODE FOR INSULIN DATA FROM MIDDLEWARE
  // (INSULIN DATA ONLY AVAILABLE IN DEV INTEGRATION ENV )
  const basals = results.model.newData.basals.map(
    ({
      date,
      cbrf,
      profile,
      remark,
      virtual,
      tsb,
      tsbDiffMins,
      tbrdec,
      tbrinc,
    }) => ({
      date: convertISO(date).toJSDate(),
      cbrf,
      profile,
      remark,
      virtual,
      tsb,
      tsbDiffMins,
      tbrdec,
      tbrinc,
    }),
  );

  const bolus = results.model.newData.bolus.map(
    ({ date, value, remark, registerType, bolusType }) => ({
      date: convertISO(date).toJSDate(),
      value,
      remark,
      registerType,
      bolusType,
    }),
  );

  return { unit, measurements, insulin: { basals, bolus } };
};

export const ClinicalDataServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);
