import * as React from 'react';

import { StyledCheckbox, StyledCheckboxLabel } from './checkbox.style';

import { Text } from '../text/text.component';

interface CheckboxProps {
  onChange?: (e: any) => void;
  value?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  label?: string;
  id?: string;
}
interface CheckboxState {
  checked: boolean;
}
export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.defaultChecked || false,
    };
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.defaultChecked !== this.props.defaultChecked) {
      this.setState({
        checked: nextProps.defaultChecked || false,
      });
    }
  }

  public onChange(e: any) {
    this.setState({
      checked: !this.state.checked,
    });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  public render() {
    return (
      <StyledCheckboxLabel>
        <StyledCheckbox
          id={this.props.id}
          type="checkbox"
          onChange={(e) => this.onChange(e)}
          value={this.props.value}
          disabled={this.props.disabled}
          checked={this.state.checked}
        />
        <Text size={'regular'} weight={'regular'} color={'rgb(74, 74, 74)'}>
          {this.props.label}
        </Text>
      </StyledCheckboxLabel>
    );
  }
}
