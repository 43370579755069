import { map, pipe } from 'ramda';

import { EC6ProfileType, ProfileType } from './profile-types.types';

const transformProfileType = ({
  id,
  profile: { id: profileId, code, role },
}) => ({
  departmentProfileId: id,
  id: profileId,
  name: code,
  role,
});

export const transformProfileTypes = (
  response: EC6ProfileType[],
): ProfileType[] => pipe(map(transformProfileType))(response);
