import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type Props = {
  height: number,
  fillColor?: string,
  strokeColor?: string,
};

export const V3NextShipmentIcon = ({
  fillColor = colors.white,
  height = 32,
  strokeColor = colors.iconColor,
}: Props) => {
  const originalWidth = 45;
  const originalHeight = 30;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      viewBox="0 0 45 30"
    >
      <g
        id="v3-next-shipment"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Patient-dashboard---BG-View"
          transform="translate(-1235.000000, -590.000000)"
        >
          <g
            id="CARD/Strip-Management"
            transform="translate(980.000000, 489.000000)"
          >
            <g id="Group-49" transform="translate(48.000000, 99.000000)">
              <g id="Group-8" transform="translate(208.000000, 3.000000)">
                <polygon
                  id="Path"
                  stroke="#333C70"
                  strokeWidth="1.5"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="7.848 5.02545455 7.848 23.3672727 21.8736 29.4981818 34.2 23.6363636 34.2 6.36363636 20.7 -5.86133162e-14"
                ></polygon>
                <polyline
                  id="Path"
                  stroke="#333C70"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="7.848 5.02545455 21.8736 10.8654545 21.8736 28.5890909"
                ></polyline>
                <line
                  x1="21.8736"
                  y1="10.8654545"
                  x2="33.1992"
                  y2="6.12363636"
                  id="Path"
                  stroke="#333C70"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M12.8232,13.0618182 L12.8232,17.8981818 C12.8033451,18.21833 12.9934869,18.5138106 13.2912,18.6254545 L18.5283134,20.9533764 C18.610703,20.9851986 18.7345986,20.9704368 18.9,20.9090909 C19.0165262,20.7755905 19.0750307,20.6642917 19.0755134,20.5751946 L18.9,10 L32.4,4.54545455 L26.1,1.81818182 L14.976,7.57090909 L12.8232,13.0618182 Z"
                  id="Path"
                  fill="#A7AAB7"
                  fillRule="nonzero"
                  opacity="0.48"
                  style={{ mixBlendMode: 'multiply' }}
                ></path>
                <polygon
                  id="Path"
                  stroke="#333C70"
                  strokeWidth="1.5"
                  fill="#CDD6FB"
                  fillRule="nonzero"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="30.8736 3.07636364 17.0208 8.84363636 17.0208 18.0872727 11.7648 16.0290909 11.7648 6.77818182 26.028 1.01818182"
                ></polygon>
                <line
                  x1="0.362988281"
                  y1="16.3636364"
                  x2="7.2"
                  y2="16.3636364"
                  id="Path-2"
                  stroke="#333C70"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></line>
                <line
                  x1="34.2"
                  y1="16.3636364"
                  x2="39.6123047"
                  y2="16.3636364"
                  id="Path-2-Copy"
                  stroke="#333C70"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></line>
                <line
                  x1="43.0283936"
                  y1="16.3636364"
                  x2="44.1"
                  y2="16.3636364"
                  id="Path-2-Copy-2"
                  stroke="#333C70"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></line>
                <line
                  x1="12.15"
                  y1="6.81818182"
                  x2="17.1"
                  y2="9.09090909"
                  id="Path-5"
                  stroke="#333C70"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
