export const mockSaveDeliveryStatusCommentResponse = {
  comment: 'Jacked up and good to go.',
  commentStatus: 'important',
  createdAt: '2018-03-23T04:15:51.144Z',
  id: '8c47e0c0-aa9c-490b-bac7-3f136d1f6a53',
  updatedAt: '2018-05-16T16:00:51.286Z',
};

export const mockSaveDeliveryStatusCommentPayload = {
  patientId: '1234',
  deliveryStatusId: {
    type: 'string',
    description: 'Delivery status id',
  },
  comment: 'string',
};
