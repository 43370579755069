export const TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const scrollTo = { Yincrement: 1.2, X: 0 };

export const MIN_CELL_HEIGHT = 45;
export const DATE_COLUMN_WIDTH = 80;
export const SUMMARY_COLUMN_WIDTH = 200; // 172
export const TIME_COLUMN_MIN_WIDTH = 40;
export const ROW_GAP = 10;
export const STANDARD_ROW_HEIGHT = 40;
export const NUMBER_OF_TIME_BLOCKS = 24;
export const STICKY_OFFSET = -76;
export const SCROLL_TO_HRS_COLUMN = 6;
export const ID_24H_WRAPPER = 'lb-24hrsWrapper';
export const MEASUREMENTS_GROUPED_BY_HOURS =
  'measurementsGroupedByTypeAndHours';
export const ID_SELECTED_ROW = 'selected-row';
export const STICKY_MARGIN_TOP = '76px';
export const NO_STICKY_MARGIN_TOP = '0px';
export const LEFT_DATE_HEADER_POSITION = '10px';
export const LEFT_MAINTABLE_HEADER_POSITION = '90px';
export const LEFT_SUMMARY_HEADER_POSITION = 'unset';
export const WEEKEND_DAYS = {
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};
export const SCROLL_BUTTONS_MARGIN = '8px';
export const MAIN_TABLE_ID = 'main-table';
export const RIGHT_BUTTON = 'right';
export const DATES_HEADER_NAME = 'dates-header';
export const SHARED_COLLAPSED_PROPS = {
  top: 'unset',
  position: 'absolute',
  bottom: '200px',
};
