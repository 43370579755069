import React from 'react';

import { colors } from 'src/core/styles/colors';
import { spacing } from 'src/core/styles/spacing';
import { MealTime } from 'src/components/meal-times/meal-times.style';
import { MealTitleWithTooltip } from 'src/components/meal-times/meal-title.component';

import { LogbookTableCell } from 'src/domains/diagnostics/components/table/logbook-table-cell/logbook-table-cell.component';
import { LogbookCellBlock } from 'src/domains/diagnostics/components/table/logbook-cell-block/logbook-cell-block.component';

export const MealTimeLabelCell = ({ keyText, mealTime, alignItems, width }) => (
  <LogbookTableCell key={`${mealTime}-${keyText}`} p={1} width={width}>
    <LogbookCellBlock
      color={colors.blueMarine}
      p={spacing.one}
      height={'1.5rem'}
    >
      <MealTime key={keyText} bold>
        <MealTitleWithTooltip textAlign={alignItems}>
          {mealTime}
        </MealTitleWithTooltip>
      </MealTime>
    </LogbookCellBlock>
  </LogbookTableCell>
);
