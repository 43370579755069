import {
  NewEntryHba1cActions,
  NewEntryHba1cActionType,
  NewEntryHba1cState,
} from './new-entry-hba1c.types';

export const NEW_ENTRY_HBA1C_INITIAL_STATE: NewEntryHba1cState = {
  fhirId: null,
  isSavingNewEntryHba1c: false,
  isSavingNewEntryHba1cError: false,
  NewEntryHba1cSaved: false,
};

export const newEntryHba1cReducer = (
  state = NEW_ENTRY_HBA1C_INITIAL_STATE,
  action: NewEntryHba1cActions,
): NewEntryHba1cState => {
  switch (action.type) {
    case NewEntryHba1cActionType.SAVE_NEW_ENTRY_HBA1C_START:
      return {
        ...NEW_ENTRY_HBA1C_INITIAL_STATE,
        isSavingNewEntryHba1c: true,
      };
    case NewEntryHba1cActionType.SAVE_NEW_ENTRY_HBA1C_SUCCESS:
      return {
        ...state,
        isSavingNewEntryHba1c: false,
      };
    case NewEntryHba1cActionType.SAVE_NEW_ENTRY_HBA1C_ERROR:
      return {
        ...state,
        isSavingNewEntryHba1cError: true,
        isSavingNewEntryHba1c: false,
      };
    default:
      return state;
  }
};
