export const EVENTS_KEYS = {
  NEW_PATIENT_PROCESS_INITIATED: 'New Patient Process Initiated',
  NEW_PATIENT_STEP1_COMPLETED: 'New Patient Step 1 Completed',
  NEW_PATIENT_STEP2_COMPLETED: 'New Patient Step 2 Completed',
  NEW_PATIENT_CREATED: 'New Patient Created',
  NEW_RPM_PATIENT_CREATED: 'New RPM Patient Created',
};

export const SEGMENT_KEYS = {
  PROFILE_TYPE: 'Profile Type',
  PATIENT_PORTAL_ACCESS: 'Patient Portal Access',
  OPEN_CONNECTIVITY: 'Open Connectivity',
  RPM_PROGRAM: 'RPM Program',
  MYSUGR_DATA_SHARING: 'mySugr Data Sharing',
  PREGNANT: 'Pregnant',
  GENDER: 'Gender',
  DIABETES_TYPE: 'Diabetes Type',
  NO_APPLY: 'N/A',
};
