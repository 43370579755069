import React from 'react';
import { testId } from '@roche/roche-common';
import { JELLO_DIALOG_EVENTS, ID } from './jello-dialog.constants';
import { useCustomEventListener } from 'src/hooks';

type JelloDialogProps = {
  id?: string;
  visible?: boolean;
  headerTitle?: string;
  showCloseButton?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  tertiaryButtonText?: string;
  primaryButtonAction?: () => void;
  secondaryButtonAction?: () => void;
  tertiaryButtonAction?: () => void;
  closeOnAction?: () => void;
  className?: string;
  children?: JSX.Element;
};

export const JelloDialog: React.FunctionComponent<JelloDialogProps> = ({
  id = ID,
  visible,
  headerTitle,
  showCloseButton,
  primaryButtonText,
  secondaryButtonText,
  tertiaryButtonText,
  primaryButtonAction = () => undefined,
  secondaryButtonAction = () => null,
  tertiaryButtonAction = () => null,
  closeOnAction = () => null,
  className = ID,
  children,
}) => {
  const events = Object.values(JELLO_DIALOG_EVENTS);
  const modalRef = useCustomEventListener(events, (event) => {
    const { type } = event;
    const actions = {
      [JELLO_DIALOG_EVENTS.primaryBtn]: primaryButtonAction,
      [JELLO_DIALOG_EVENTS.secondaryBtn]: secondaryButtonAction,
      [JELLO_DIALOG_EVENTS.tertiaryBtn]: tertiaryButtonAction,
      [JELLO_DIALOG_EVENTS.closeBtn]: closeOnAction,
    };
    actions?.[type] && actions[type]();
  });

  return (
    <jello-dialog
      id={id}
      ref={modalRef}
      header-title={headerTitle}
      primary-button-text={primaryButtonText}
      secondary-button-text={secondaryButtonText}
      show-close-button={showCloseButton ? true : null}
      visible={visible ? true : null}
      tertiary-button-text={tertiaryButtonText}
      close-on-action={closeOnAction}
      class={className}
      {...testId(`${id}`, ID)}
    >
      {children}
    </jello-dialog>
  );
};
