import { Action, PayloadAction } from 'src/app/store/app.types';
import {
  CheckDataSharingConsentResponseType,
  CheckDataSharingConsentTransformedResponseType,
} from '../../../../../services/patient/check-data-sharing-consent/check-data-sharing-consent.types';

export enum CheckDataSharingConsentActionType {
  CHECK_DATA_SHARING_CONSENT = 'CHECK_DATA_SHARING_CONSENT',
  CHECK_DATA_SHARING_CONSENT_START = 'CHECK_DATA_SHARING_CONSENT_START',
  CHECK_DATA_SHARING_CONSENT_SUCCESS = 'CHECK_DATA_SHARING_CONSENT_SUCCESS',
  CHECK_DATA_SHARING_CONSENT_ERROR = 'CHECK_DATA_SHARING_CONSENT_ERROR',
  CLEAR_CHECK_DATA_SHARING_STATE = 'CLEAR_CHECK_DATA_SHARING_STATE',
}

export type CheckDataSharingConsentPayloadType =
  | CheckDataSharingConsentResponseType
  | string;

export type CheckDataSharingConsentSuccessType =
  CheckDataSharingConsentTransformedResponseType;

export type CheckDataSharingConsentErrorType =
  CheckDataSharingConsentTransformedResponseType;

export type CheckDataSharingStartAction = PayloadAction<
  CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_START,
  string
>;

export type CheckDataSharingtAction = PayloadAction<
  CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT,
  string
>;

export type CheckDataSharingSuccessAction = PayloadAction<
  CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_SUCCESS,
  CheckDataSharingConsentSuccessType
>;

export type CheckDataSharingErrorAction = PayloadAction<
  CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_ERROR,
  CheckDataSharingConsentErrorType
>;

export type ClearCheckDataSharingStateAction =
  Action<CheckDataSharingConsentActionType.CLEAR_CHECK_DATA_SHARING_STATE>;

export type CheckDataSharingConsentActions =
  | CheckDataSharingStartAction
  | CheckDataSharingSuccessAction
  | CheckDataSharingErrorAction
  | ClearCheckDataSharingStateAction
  | CheckDataSharingtAction;

export type CheckDataSharingConsentState = {
  checkDataSharingHasBeenCalled: boolean;
  isCheckingDataSharingConsent: boolean;
  checkDataSharingConsentSuccessful: boolean;
  checkDataSharingConsentHasError: boolean;
  dataSharingConsentResponse: CheckDataSharingConsentSuccessType;
  status: CheckDataSharingConsentSuccessType;
  last_update: string;
};
