export const mockLoaderSuccess = () =>
  Promise.resolve({
    report: [
      {
        patientId: '9py7QLp-uO6-3782',
        patientName: '',
        patientCenterName: '',
        hcpName: 'Gonzalo m v',
        dateOfIntervention: '2021-03-18T12:19:53.297363Z',
        timeSpent: '23',
        methodOfCommunication: 'NONE',
        patientCareActivities: ['Hola', 'Adios'],
        reportSummary: '',
      },
      {
        patientId: '9py7QLp-uO6-3782',
        patientName: '',
        patientCenterName: '',
        hcpName: 'Gonzalo m v',
        dateOfIntervention: '2021-03-19T09:39:01.650418Z',
        timeSpent: '0',
        methodOfCommunication: 'NONE',
        patientCareActivities: [],
        reportSummary: '',
      },
      {
        patientId: '9py7QLp-uO6-3782',
        patientName: '',
        patientCenterName: '',
        hcpName: 'Gonzalo m v',
        dateOfIntervention: '2021-03-19T09:49:08.947709Z',
        timeSpent: '0',
        methodOfCommunication: 'NONE',
        patientCareActivities: [],
        reportSummary: '',
      },
      {
        patientId: '9py7QLp-uO6-3782',
        patientName: '',
        patientCenterName: '',
        hcpName: 'Gonzalo m v',
        dateOfIntervention: '2021-03-19T10:06:15.079644Z',
        timeSpent: '0',
        methodOfCommunication: 'NONE',
        patientCareActivities: [],
        reportSummary: '',
      },
      {
        patientId: '9py7QLp-uO6-3782',
        patientName: '',
        patientCenterName: '',
        hcpName: 'Gonzalo m v',
        dateOfIntervention: '2021-03-19T10:38:16.437447Z',
        timeSpent: '0',
        methodOfCommunication: 'NONE',
        patientCareActivities: [],
        reportSummary: '',
      },
      {
        patientId: '9py7QLp-uO6-3782',
        patientName: '',
        patientCenterName: '',
        hcpName: 'Gonzalo m v',
        dateOfIntervention: '2021-03-19T10:38:51.277585Z',
        timeSpent: '0',
        methodOfCommunication: 'NONE',
        patientCareActivities: [],
        reportSummary: '',
      },
      {
        patientId: '9py7QLp-uO6-3782',
        patientName: '',
        patientCenterName: '',
        hcpName: 'Gonzalo m v',
        dateOfIntervention: '2021-03-19T10:42:32.845710Z',
        timeSpent: '0',
        methodOfCommunication: 'NONE',
        patientCareActivities: [],
        reportSummary: '',
      },
    ],
  });
export const mockLoaderError = () => Promise.reject({});
