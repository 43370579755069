import {
  GetUserUnitsLoaderImpl,
  GetUserUnitsServiceImpl,
  GetUserUnitsTransformImpl,
} from './get-user-units.service';
import { mockGetUserUnits } from './get-user-units.mock';
import { UserUnitsResponse } from './get-user-unit.types';

const mockLoader = (): Promise<UserUnitsResponse> =>
  Promise.resolve(mockGetUserUnits);

export const GetUserUnitsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : GetUserUnitsLoaderImpl;
  return GetUserUnitsServiceImpl(loader, GetUserUnitsTransformImpl);
};
