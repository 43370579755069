import * as React from 'react';
import { testId, getConfig } from '@roche/roche-common';
import styles from './styles.module.css';

import { RenderIf } from 'src/utils/render-if';
import { t } from 'i18next';

import {
  MANUFACTURER_INFO_MANUFACTURER_KEY,
  MANUFACTURER_INFO_ADDRESS_LINE1_KEY,
  MANUFACTURER_INFO_ADDRESS_LINE2_KEY,
  MANUFACTURER_INFO_ADDRESS_LINE3_KEY,
  MANUFACTURER_INFO_ADDRESS_LINE4_KEY,
  MANUFACTURER_INFO_MANUFACTURE_DATE_KEY,
  MANUFACTURER_INFO_GEN_INFO_RDCP_DATE_KEY,
  MANUFACTURER_SWITZ_NAME_KEY,
  MANUFACTURER_SWITZ_ADDRESS_LINE1_KEY,
  MANUFACTURER_SWITZ_ADDRESS_LINE2_KEY,
  MANUFACTURER_SWITZ_ADDRESS_LINE3_KEY,
  MANUFACTURER_SWITZ_ADDRESS_LINE4_KEY,
  MANUFACTURER_SWITZ_PHONE_NUMBER_KEY,
  MANUFACTURER_SWITZ_EMAIL_KEY,
  MANUFACTURER_BRAZIL_NAME_KEY,
  MANUFACTURER_BRAZIL_ADDRESS_LINE1_KEY,
  MANUFACTURER_BRAZIL_ADDRESS_LINE2_KEY,
  MANUFACTURER_BRAZIL_ADDRESS_LINE3_KEY,
  MANUFACTURER_BRAZIL_ADDRESS_LINE4_KEY,
  MANUFACTURER_BRAZIL_ADDRESS_LINE5_KEY,
  MANUFACTURER_BRAZIL_ADDRESS_LINE6_KEY,
  MANUFACTURER_INFO_EXPLANATION_SYMBOLS_DEVICE_ID_KEY,
  MANUFACTURER_INFO_GENERAL_KEY,
  MANUFACTURER_INFO_VALID_INFO_FOR_KEY,
  MANUFACTURER_INFO_VERSION_KEY,
  MANUFACTURER_INFO_CAUTION_KEY,
  MANUFACTURER_INFO_MED_DEVICE_KEY,
  MANUFACTURER_INFO_CE_INFO_KEY,
  MANUFACTURER_INFO_CONSULT_ELECTRONIC_KEY,
  MANUFACTURER_INFO_EXPLANATION_SYMBOLS_TITLE_KEY,
  MANUFACTURER_INFO_EXPLANATION_SYMBOLS_DESCRIPTION_KEY,
  MANUFACTURER_ICON_ALT,
  DATE_OF_MANUFACTURE_ICON_ALT,
  IMPORTER_CH_ICON_ALT,
  MEDICAL_DEVICE_ICON_ALT,
  MEDICAL_DEVICE_ID_ICON_ALT,
  CE_CODE_ICON_ALT,
  ADDITIONAL_INFORMATION_ALT,
  IMPORTER_BR_ICON_ALT,
} from './constants';

import {
  JELLO_HEADINGS_SIZES,
  JELLO_HEADINGS_WEIGHTS,
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
  JELLO_TEXT_SIZES,
} from 'src/app/app.jello.constants';

import { getLabel } from 'src/utils/i18n/with-translation';
import {
  appendSuffixIfRebranded,
  getCurrentAppVersion,
} from '../../manufacturer-info-modal.utils';

const { REACT_APP_IFUS } = getConfig();

const REGULATORY_PATH = '/images/regulatory/';
const ICON_TYPE = 'svg';
const ICON_WIDTH = 40;
const MANUFACTURER_INFO = 'manufacturerInfo';

const generateAssetURL = (host, assetFolder, iconType, fileName) => {
  return `${host}${assetFolder}${iconType}/${fileName}`;
};

const generateIfusRegulatoryAssetURL = (fileName) => {
  return generateAssetURL(REACT_APP_IFUS, REGULATORY_PATH, ICON_TYPE, fileName);
};

const MANUFACTURER_ICON_URL = generateIfusRegulatoryAssetURL('manufacture.svg');
const MANUFACTURER_ID_ICON_URL = generateIfusRegulatoryAssetURL(
  'manufacturer_date.svg',
);
const IMPORTER_CH_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_ch_rep.svg');
const IMPORTER_BR_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_br_rep.svg');
const MEDICAL_DEVICE_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_md.svg');
const MEDICAL_DEVICE_ID_ICON_URL =
  generateIfusRegulatoryAssetURL('sym_mdr_udi.svg');
const CE_CODE_ICON_URL = generateIfusRegulatoryAssetURL('ce_marking_mdr.svg');
const ADDITIONAL_INFO_ICON_URL =
  generateIfusRegulatoryAssetURL('user_manual.svg');

type ModuleDataListProps = {
  name: string;
  publishedDate: string;
  version: string;
};

type Props = {
  moduleDataList: ModuleDataListProps[];
  rdcpManufactureDate?: string;
  rebranding: boolean;
};

const manufactureInfo = (date) => (
  <>
    <div className={styles.iconAndInfoRow}>
      <img
        width={ICON_WIDTH}
        alt={MANUFACTURER_ICON_ALT}
        src={MANUFACTURER_ICON_URL}
      />

      <jello-text size={JELLO_TEXT_SIZES.S}>
        {t(MANUFACTURER_INFO_MANUFACTURER_KEY)}
        <br />
        {t(MANUFACTURER_INFO_ADDRESS_LINE1_KEY)}
        <br />
        {t(MANUFACTURER_INFO_ADDRESS_LINE2_KEY)}
        <br />
        {t(MANUFACTURER_INFO_ADDRESS_LINE3_KEY)}
        <br />
        {t(MANUFACTURER_INFO_ADDRESS_LINE4_KEY)}
      </jello-text>
    </div>

    <div className={styles.iconAndInfoRow}>
      <img
        width={ICON_WIDTH}
        alt={DATE_OF_MANUFACTURE_ICON_ALT}
        src={MANUFACTURER_ID_ICON_URL}
      />

      <jello-text size={JELLO_TEXT_SIZES.S}>
        {getLabel(t(MANUFACTURER_INFO_MANUFACTURE_DATE_KEY))} <br />
        {date}
      </jello-text>
    </div>
  </>
);

const importerSchweizAndBrazil = () => (
  <>
    <div className={styles.iconAndInfoRow}>
      <img
        width={ICON_WIDTH}
        alt={IMPORTER_CH_ICON_ALT}
        src={IMPORTER_CH_ICON_URL}
      />

      <jello-text size={JELLO_TEXT_SIZES.S}>
        {t(MANUFACTURER_SWITZ_NAME_KEY)}
        <br />
        {t(MANUFACTURER_SWITZ_ADDRESS_LINE1_KEY)}
        <br />
        {t(MANUFACTURER_SWITZ_ADDRESS_LINE2_KEY)}
        <br />
        {t(MANUFACTURER_SWITZ_ADDRESS_LINE3_KEY)}
        <br />
        {t(MANUFACTURER_SWITZ_ADDRESS_LINE4_KEY)}
        <br />
        {getLabel(t(MANUFACTURER_SWITZ_PHONE_NUMBER_KEY))}
        <br />
        {getLabel(t(MANUFACTURER_SWITZ_EMAIL_KEY))}
      </jello-text>
    </div>
    <div className={styles.iconAndInfoRow}>
      <img
        width={ICON_WIDTH}
        alt={IMPORTER_BR_ICON_ALT}
        src={IMPORTER_BR_ICON_URL}
      />

      <jello-text size={JELLO_TEXT_SIZES.S}>
        {t(MANUFACTURER_BRAZIL_NAME_KEY)}
        <br />
        {t(MANUFACTURER_BRAZIL_ADDRESS_LINE1_KEY)}
        <br />
        {t(MANUFACTURER_BRAZIL_ADDRESS_LINE2_KEY)}
        <br />
        {t(MANUFACTURER_BRAZIL_ADDRESS_LINE3_KEY)}
        <br />
        {t(MANUFACTURER_BRAZIL_ADDRESS_LINE4_KEY)}
        <br />
        {t(MANUFACTURER_BRAZIL_ADDRESS_LINE5_KEY)}
        <br />
        {t(MANUFACTURER_BRAZIL_ADDRESS_LINE6_KEY)}
      </jello-text>
    </div>
  </>
);

const getUniqueDeviceIdentifier = (appVersion, rebranding) => {
  const deviceIdTextKey = appendSuffixIfRebranded(
    MANUFACTURER_INFO_EXPLANATION_SYMBOLS_DEVICE_ID_KEY,
    rebranding,
  );

  const versionRegEx = /(.{5})\s*$/;
  const versionLabel = t(deviceIdTextKey).replace(versionRegEx, appVersion);
  return getLabel(versionLabel);
};

export const GeneralInformationViewComponent = ({
  moduleDataList,
  rdcpManufactureDate,
  rebranding,
}: Props) => {
  const moduleData = moduleDataList[0] || null;
  const lokaliseModuleNameTarget = moduleData
    ? `${MANUFACTURER_INFO}.${moduleData.name}.name`
    : '';
  const appVersion = getCurrentAppVersion(rebranding) || '';

  const manufacturerInfo = getLabel(
    t(
      appendSuffixIfRebranded(MANUFACTURER_INFO_VALID_INFO_FOR_KEY, rebranding),
      {
        version: appVersion,
      },
    ),
  );

  const uniqueDeviceIdentifier = getUniqueDeviceIdentifier(
    appVersion,
    rebranding,
  );

  return (
    <div className={styles.generalInfoContainer}>
      <section>
        <jello-heading
          level={JELLO_HEADINGS_SIZES.H3}
          size={JELLO_HEADINGS_SIZES.XL}
          weight={JELLO_HEADINGS_WEIGHTS.SEMIBOLD}
        >
          {t(MANUFACTURER_INFO_GENERAL_KEY)}
        </jello-heading>
        <p>
          <jello-text size={JELLO_TEXT_SIZES.S}>{manufacturerInfo}</jello-text>
        </p>
      </section>

      <section>
        <jello-heading
          level={JELLO_HEADINGS_SIZES.H4}
          size={JELLO_HEADINGS_SIZES.L}
          weight={JELLO_HEADINGS_WEIGHTS.SEMIBOLD}
        >
          {t(MANUFACTURER_INFO_EXPLANATION_SYMBOLS_TITLE_KEY)}
        </jello-heading>

        <jello-text size={JELLO_TEXT_SIZES.S}>
          {t(MANUFACTURER_INFO_EXPLANATION_SYMBOLS_DESCRIPTION_KEY)}
        </jello-text>

        <div className={styles.iconAndInfoRow} style={{ paddingTop: '16px' }}>
          <jello-icon
            size={JELLO_ICON_SIZES.M}
            icon-name={JELLO_ICON_NAMES.WARNING_ALT}
            class={styles.blueIcon}
            styles={{ margin: '0 4px' }}
          />

          <jello-text size={JELLO_TEXT_SIZES.S}>
            {t(MANUFACTURER_INFO_CAUTION_KEY)}
          </jello-text>
        </div>

        <div className={styles.iconAndInfoRow}>
          <img
            width={ICON_WIDTH}
            alt={MEDICAL_DEVICE_ICON_ALT}
            src={MEDICAL_DEVICE_ICON_URL}
          />

          <jello-text size={JELLO_TEXT_SIZES.S}>
            {t(MANUFACTURER_INFO_MED_DEVICE_KEY)}
          </jello-text>
        </div>
        <div className={styles.iconAndInfoRow}>
          <img
            width={ICON_WIDTH}
            alt={MEDICAL_DEVICE_ID_ICON_ALT}
            src={MEDICAL_DEVICE_ID_ICON_URL}
          />

          <jello-text size={JELLO_TEXT_SIZES.S}>
            {uniqueDeviceIdentifier}
          </jello-text>
        </div>

        {manufactureInfo(
          rdcpManufactureDate || t(MANUFACTURER_INFO_GEN_INFO_RDCP_DATE_KEY),
        )}

        {importerSchweizAndBrazil()}

        <div className={styles.iconAndInfoRow}>
          <img
            width={ICON_WIDTH}
            alt={CE_CODE_ICON_ALT}
            src={CE_CODE_ICON_URL}
          />

          <jello-text size={JELLO_TEXT_SIZES.S} style={{ maxWidth: '200px' }}>
            {t(MANUFACTURER_INFO_CE_INFO_KEY)}
          </jello-text>
        </div>
        <div className={styles.iconAndInfoRow}>
          <img
            width={ICON_WIDTH}
            alt={ADDITIONAL_INFORMATION_ALT}
            src={ADDITIONAL_INFO_ICON_URL}
          />

          <jello-text size={JELLO_TEXT_SIZES.S} style={{ maxWidth: '120px' }}>
            {t(MANUFACTURER_INFO_CONSULT_ELECTRONIC_KEY)}
          </jello-text>
        </div>
      </section>

      <RenderIf validate={moduleData}>
        <section>
          <jello-heading
            level={JELLO_HEADINGS_SIZES.H5}
            size={JELLO_HEADINGS_SIZES.M}
            weight={JELLO_HEADINGS_WEIGHTS.SEMIBOLD}
            {...testId(MANUFACTURER_INFO, moduleData.name)}
          >
            {`${t(lokaliseModuleNameTarget)} ${t(
              MANUFACTURER_INFO_VERSION_KEY,
            )}${moduleData.version}`}
          </jello-heading>

          {manufactureInfo(
            moduleData.publishedDate ||
              t(MANUFACTURER_INFO_GEN_INFO_RDCP_DATE_KEY),
          )}
        </section>
      </RenderIf>
    </div>
  );
};
