// @ts-nocheck
import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { GenerateTripleColumnIconsComponent } from '../icon-components.component';

import {
  BolusStandardIcon,
  BolusQuickIcon,
  BolusExtendedIcon,
  BolusMultiwaveIcon,
  PumpTbrincIcon,
  PumpTbrdecIcon,
  PumpRunIcon,
  PumpStopIcon,
  PumpPauseIcon,
} from 'src/assets/icons';

import {
  InsulinToggleIcon,
  CarbohydratesToggleIcon,
  BasalToggleIcon,
} from 'src/assets/icons/index.js';

const bolusIconsTitle = 'manufacturerInfo.bolus.bolusIconsTitle';

const bolusIconTags = [
  {
    objectComponent: (
      <BolusStandardIcon height={17} width={14} minY={-2} key={1} />
    ),
    nametag: 'manufacturerInfo.bolus.standardBolus',
  },
  {
    objectComponent: <PumpRunIcon height={20} width={20} key={2} />,
    nametag: 'manufacturerInfo.bolus.run',
  },
  {
    objectComponent: <PumpTbrincIcon height={20} width={20} key={3} />,
    nametag: 'manufacturerInfo.bolus.tbrIncrease',
  },
  {
    objectComponent: <BolusQuickIcon height={13} width={15} key={4} />,
    nametag: 'manufacturerInfo.bolus.quickBolus',
  },
  {
    objectComponent: <PumpStopIcon height={20} width={20} key={5} />,
    nametag: 'manufacturerInfo.bolus.stop',
  },
  {
    objectComponent: <PumpTbrdecIcon height={20} width={20} key={6} />,
    nametag: 'manufacturerInfo.bolus.tbrDecrease',
  },
  {
    objectComponent: <BolusExtendedIcon height={12} width={14} key={7} />,
    nametag: 'manufacturerInfo.bolus.extendedBolus',
  },
  {
    objectComponent: (
      <InsulinToggleIcon
        width={20}
        height={22}
        fillColor={colors.carb}
        minX={0}
        minY={2}
        key={8}
        originalWidth={16}
        originalHeight={9}
      />
    ),
    nametag: 'manufacturerInfo.bolus.insulin',
  },
  {
    objectComponent: <PumpPauseIcon height={20} width={20} key={9} />,
    nametag: 'manufacturerInfo.bolus.pause',
  },
  {
    objectComponent: <BolusMultiwaveIcon height={14} width={14} key={10} />,
    nametag: 'manufacturerInfo.bolus.multiWaveBolus',
  },
  {
    objectComponent: (
      <CarbohydratesToggleIcon
        height={20}
        fillColor={colors.carb}
        minX={0}
        minY={0}
        key={11}
      />
    ),
    nametag: 'manufacturerInfo.bolus.carbohydrates',
  },
  {
    objectComponent: (
      <BasalToggleIcon
        height={20}
        fillColor={colors.basalIconBlue}
        minX={0}
        minY={0}
        key={12}
      />
    ),
    nametag: 'manufacturerInfo.bolus.basalRate',
  },
];

export const BolusIconsComponent = () => (
  <GenerateTripleColumnIconsComponent
    generalIconTags={bolusIconTags}
    generalIconsTitle={bolusIconsTitle}
  />
);
