import * as React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'src/components/protected-route/protected-route.container';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

import { TherapyBundle } from './bundles';

export const therapyLinks = {
  main: '/patients/:id/therapy-management',
  nexmed: '/patients/:id/therapy-management/nexmed',
  insulin: '/patients/:id/therapy-management/insulin',
  medication: '/patients/:id/therapy-management/medication',
  all: '/patients/:id/therapy-management/*',
};

export const TherapyRoutes = () => (
  <Switch>
    <ProtectedRoute
      exact
      path={therapyLinks.main}
      component={TherapyBundle}
      hasPermissions={[PERMISSIONS.THERAPY]}
    />
    <ProtectedRoute
      exact
      path={therapyLinks.all}
      component={TherapyBundle}
      hasPermissions={[PERMISSIONS.THERAPY]}
    />
  </Switch>
);
