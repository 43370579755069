import * as React from 'react';
import { Subject } from 'rxjs';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';
import { createModal, destroyModal } from 'src/core/modal/modal.actions';
import { selectPatientFhirId } from 'src/core/patient/patient.selector';
import { savePatientReportsStart } from 'src/core/patient-reports/patient-reports.action';
import {
  selectPatientStartDate,
  selectPatientEndDate,
} from 'src/core/patient-date-range/patient-date-range.selector';
import {
  selectPatientHasData,
  selectPatientIsEMRCompleted,
} from './pdf-report.selector';
import { selectPatientInfo } from './pdf-report.selector';
import { selectPatientReportStatus } from './pdf-report.selector';
import {
  PdfReportButtonProps,
  PdfReportButtonState,
  PdfReportButtonIProps,
  PdfReportButtonEProps,
} from './pdf-report.types';
import { PdfReportsModal } from 'src/components/pdf-report/pdf-report.component';

import { PdfReportButtonContainer } from './pdf-report.style';
import { PdfDownloadIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';

export class PdfReportButtonComponent extends React.Component<
  PdfReportButtonProps,
  PdfReportButtonState
> {
  public state: PdfReportButtonState = {
    reportObservable: new Subject(),
    disabled: true,
  };

  constructor(props) {
    super(props);
  }

  public render() {
    const { patientHasData, fhirId, patientReportStatus, isEMRCompleted } =
      this.props;
    const disabled = !patientHasData || !fhirId || !isEMRCompleted;
    this.state.disabled = disabled;
    const borderColor = disabled ? colors.gray : colors.brandBlueDark;
    const iconColor = disabled ? colors.gray : colors.iconColor;

    this.state.reportObservable.next(patientReportStatus);
    return (
      <PdfReportButtonContainer disabled={disabled}>
        <PdfDownloadIcon
          borderColor={borderColor}
          iconColor={iconColor}
          onClick={this.onClickPdfReportHandle}
        />
      </PdfReportButtonContainer>
    );
  }

  private toIso = (stringDate) => {
    const moment = require('moment');
    return moment(stringDate).utc().startOf('day').format('YYYY-MM-DD');
  };

  private onClickPdfReportHandle = () => {
    if (this.state.disabled) return;
    const { createModal } = this.props;
    this.callSavePatientReports();
    createModal({
      key: MODAL_TYPES.CUSTOM,
      data: {
        customComponent: PdfReportsModal,
        patient: this.props.patient,
        patientEndDate: this.props.patientEndDate,
        patientStartDate: this.props.patientStartDate,
        statusReportObservable: this.state.reportObservable.asObservable(),
        onRetry: this.callSavePatientReports,
      },
    });
  };

  private callSavePatientReports = () => {
    const { onSavePatientReports, patientStartDate, patientEndDate, fhirId } =
      this.props;
    const patientStartDateIso = this.toIso(patientStartDate);
    const patientEndDateIso = this.toIso(patientEndDate);
    onSavePatientReports({
      patientId: fhirId,
      reportType: 'EMR',
      startDate: patientStartDateIso,
      endDate: patientEndDateIso,
    });
  };
}

export const PdfReportButton = compose<
  PdfReportButtonIProps,
  PdfReportButtonEProps
>(
  connect(
    createStructuredSelector({
      fhirId: selectPatientFhirId,
      patientStartDate: selectPatientStartDate,
      patientEndDate: selectPatientEndDate,
      patientHasData: selectPatientHasData,
      patient: selectPatientInfo,
      patientReportStatus: selectPatientReportStatus,
      isEMRCompleted: selectPatientIsEMRCompleted,
    }),
    mapDispatchers({
      createModal,
      destroyModal,
      onSavePatientReports: savePatientReportsStart,
    }),
  ),
)(PdfReportButtonComponent);
