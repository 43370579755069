import * as React from 'react';
import { Route } from 'react-router-dom';

interface ConditionalRouteProps {
  component: JSX.Element;
}

export const ConditionalRouteForVisitModuleStickyPatientSummaryBar: React.FunctionComponent<
  ConditionalRouteProps
> = (props) => (
  <Route exact={true} path={['/patients/*']} render={() => props.component} />
);

export const ConditionalRouteForVisitModulePatientSummaryBar: React.FunctionComponent<
  ConditionalRouteProps
> = (props) => (
  <Route
    exact={true}
    path={[
      '/patients/:patientId/strip-information',
      '/patients/:patientId/strip-information/:anyTab',
      '/patients/:patientId/strip-information/:anyTab/*',
    ]}
    render={() => props.component}
  />
);
