import React from 'react';
import styles from './styles.module.css';

import { IconTextLinkProps } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';
import { JelloIcon } from 'src/components/jello-icon/jello-icon.component';
import {
  JELLO_ICON_SIZES,
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';

export const IconTextLinkComponent: React.FunctionComponent<
  IconTextLinkProps
> = ({ iconName, linkText }) => (
  <div className={styles.listItem}>
    <JelloIcon
      iconName={iconName}
      size={JELLO_ICON_SIZES.XS}
      color={'var(--jello-color-foreground-highlight-base)'}
    />
    <jello-text
      class={styles.listItemText}
      weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
      size={JELLO_TEXT_SIZES.S}
    >
      {linkText}
    </jello-text>
  </div>
);
