import { createAuthHeader, deleteJSON } from 'src/utils/service/service.utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const DeleteClinicGuideLoaderImpl = (
  { clinicGuideId },
  accessToken,
  gigyaToken,
) =>
  deleteJSON(
    endpointWithParams(ENDPOINTS.deleteClinicGuide, { clinicGuideId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const DeleteClinicGuideTransformImpl = (data) => data;

export const DeleteClinicGuideServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
