import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';

export const SaveClinicGuideLoaderImpl = (
  { therapyId, name, period, maximumStrips, minimumStrips, ownerName },
  accessToken,
  gigyaToken,
) =>
  postJSON(ENDPOINTS.saveClinicGuide, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody({
      therapyId,
      name,
      period,
      maximumStrips,
      minimumStrips,
      ownerName,
    }),
  });

export const SaveClinicGuideTransformImpl = (data) => data;

export const SaveClinicGuideServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
