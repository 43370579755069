import styled from 'styled-components';

type CommonStyledComponentProps = {
  style?: React.CSSProperties;
};

export const createStyledComponent = <
  Tag extends keyof JSX.IntrinsicElements,
  Props,
>(
  tag: Tag,
) => styled<CommonStyledComponentProps & Props, Tag>(tag);
