import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { catchError } from 'rxjs/operators';
import {
  UnblindBodyData,
  UnblindPatientLoaderImpl,
} from 'src/services/patient/unblind-clinical-study-patient/unblind-clinical-study-patient.types';
import {
  UnblindPatientActionType,
  UnblindPatientStartAction,
} from './unblind-clinical-study-patient.types';
import {
  unblindClinicalStudyPatientError,
  unblindClinicalStudyPatientSuccess,
} from './unblind-clinical-study-patient.actions';
import { createModal } from 'src/core/modal/modal.actions';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';
import { UnblindStudyErrorModalComponent } from 'src/domains/patient/scenes/clinical-study/unblind-study-error-modal/unblind-study-error-modal.component';
import { addAlertAction } from 'src/core/alert/alert.actions';
import { AlertType } from 'src/core/alert/alert.types';
import { ALERT_POSITION } from 'src/core/alert/alert.constans';
import { State } from 'src/app/store/app.types';

export const unblindClinicalStudyPatientEpic: (
  unblindClinicalStudyPatientService: UnblindPatientLoaderImpl,
) => Epic<FixMe, State> =
  (unblindClinicalStudyPatientService) => (action$, store$) =>
    action$
      .ofType(UnblindPatientActionType.UNBLIND_PATIENT_START)
      .flatMap(({ payload }: UnblindPatientStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        const apiKey = selectGigyaToken(store$.getState());
        const patientId = payload;
        return Observable.fromPromise(
          unblindClinicalStudyPatientService(accessToken, apiKey, patientId),
        )
          .flatMap((data: UnblindBodyData) => {
            return [
              unblindClinicalStudyPatientSuccess(data),
              addAlertAction({
                type: AlertType.SUCCESS,
                text: {
                  [AlertType.SUCCESS]: 'clinicalStudy.successAlertText',
                },
                position: ALERT_POSITION.TOP_RIGHT,
              }),
            ];
          })
          .pipe(
            catchError((err) =>
              Observable.concat(
                Observable.of(unblindClinicalStudyPatientError(err)),
                Observable.of(
                  createModal({
                    key: MODAL_TYPES.CUSTOM,
                    data: {
                      customComponent: UnblindStudyErrorModalComponent,
                    },
                  }),
                ),
              ),
            ),
          );
      });
