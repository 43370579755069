export const mockCheckExternalPatientResponse = {
  GigyaID: 'cee5638cc9f14087972745b61e8f97e0',
  UserID: '250509',
  UserRole: 'PATIENT',
  PWDID: '250509',
  HCPID: null,
  FHIRID: '7XUcgNp-Bn-1287',
  DepartmentId: '305451',
  CenterId: '305443',
  EMail: 'EndPEMR2@yopmail.com',
  PROFESSIONAL_NUMBER: null,
  HEALTH_CARE_SYSTEM_ID: 'EndPEMR',
  CN: 'Middleware BB',
  EMRID: 'Middleware BB',
  Name: 'End',
  Surname: 'Point EMR2',
};
