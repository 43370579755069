import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const BloodGlucoseGeneralStatsSection = styled.div`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-bottom: 1.6rem;
  height: 100%;
  min-height: 18rem;
`;
BloodGlucoseGeneralStatsSection.displayName = 'BloodGlucoseGeneralStatsSection';
