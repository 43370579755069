export type LogbookStatsTableColumn = {
  colSpan: number;
  headerRows: LogbookStatsTableColumnHeaderRow[];
};

export type LogbookStatsTableColumnHeaderRow = LogbookStatsTableSubColumn[];

export type LogbookStatsTableSubColumn = {
  colSpan: number;
  textKeys: string[];
};

// Placeholder for dynamic insertion of blood glucose unit
export const BLOOD_GLUCOSE_UNIT_PLACEHOLDER = 'BLOOD_GLUCOSE_UNIT_PLACEHOLDER';
export const CARBOHYDRATES_UNIT_PLACEHOLDER = 'CARBOHYDRATES_UNIT_PLACEHOLDER';

const GLUCOSE_COLUMN: LogbookStatsTableColumn = {
  colSpan: 4,
  headerRows: [
    [{ colSpan: 4, textKeys: ['graphs.logbookStats.bloodGlucose'] }],
    [
      { colSpan: 1, textKeys: ['graphs.logbookStats.numOfTests'] },
      { colSpan: 1, textKeys: ['graphs.logbookStats.meanBG'] },
      {
        colSpan: 1,
        textKeys: [
          'graphs.logbookStats.standard',
          'graphs.logbookStats.deviation',
        ],
      },
      { colSpan: 1, textKeys: ['graphs.logbookStats.hypos'] },
    ],
    [
      { colSpan: 1, textKeys: [] },
      { colSpan: 1, textKeys: [BLOOD_GLUCOSE_UNIT_PLACEHOLDER] },
      { colSpan: 1, textKeys: [BLOOD_GLUCOSE_UNIT_PLACEHOLDER] },
      { colSpan: 1, textKeys: [] },
    ],
  ],
};

const CARBOHYDRATES_COLUMN: LogbookStatsTableColumn = {
  colSpan: 2,
  headerRows: [
    [{ colSpan: 2, textKeys: ['graphs.logbookStats.carbohydrates'] }],
    [{ colSpan: 2, textKeys: [''] }],
    [{ colSpan: 2, textKeys: [CARBOHYDRATES_UNIT_PLACEHOLDER] }],
  ],
};

const INSULIN_COLUMN: LogbookStatsTableColumn = {
  colSpan: 5,
  headerRows: [
    [
      {
        colSpan: 5,
        textKeys: ['graphs.logbookStats.insulinInAllCaps'],
      },
    ],
    [
      {
        colSpan: 1,
        textKeys: ['graphs.logbookStats.insulin'],
      },
      {
        colSpan: 1,
        textKeys: ['graphs.logbookStats.basal'],
      },
      {
        colSpan: 1,
        textKeys: ['graphs.logbookStats.bolus'],
      },
      {
        colSpan: 1,
        textKeys: ['graphs.logbookStats.numOf', 'graphs.logbookStats.boluses'],
      },
      {
        colSpan: 1,
        textKeys: [
          'graphs.logbookStats.basalSlash',
          'graphs.logbookStats.bolus',
        ],
      },
    ],
    [
      {
        colSpan: 1,
        textKeys: ['graphs.logbookStats.u'],
      },
      {
        colSpan: 1,
        textKeys: ['graphs.logbookStats.u'],
      },
      {
        colSpan: 1,
        textKeys: ['graphs.logbookStats.u'],
      },
      {
        colSpan: 1,
        textKeys: [''],
      },
      {
        colSpan: 1,
        textKeys: ['%'],
      },
    ],
  ],
};

export const LOGBOOK_STATS_TABLE_HEADER_COLUMNS: LogbookStatsTableColumn[] = [
  GLUCOSE_COLUMN,
  CARBOHYDRATES_COLUMN,
  INSULIN_COLUMN,
];
