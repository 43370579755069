import * as React from 'react';
import { createStyledComponent } from 'src/utils/styles';
import { spacing } from 'src/core/styles/spacing';
import { colors } from 'src/core/styles/colors';

type StickyHeaderWrapper = {
  isSticky: boolean;
  reactStickyStyles: React.CSSProperties;
};
export const stickyHeaderHeight = 76;
export const StickyHeaderWrapper = createStyledComponent<
  'div',
  StickyHeaderWrapper
>('div').attrs({
  style: ({ style }) => {
    return {
      ...style,
      minWidth: '100%',
    };
  },
})`
  z-index: ${({ theme }) => theme.zIndexes.routeNav - 1};
  margin: 0 -${spacing.three};
  padding: 0 ${spacing.three};
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  height: ${stickyHeaderHeight}px;
  border-bottom: 2px solid ${colors.gray3};
`;
StickyHeaderWrapper.displayName = 'StickyHeaderWrapper';
