import { push } from 'react-router-redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { clearPatientSearchResults } from 'src/core/state/state.actions';
import { GetDeviceAssociationServiceType } from 'src/services/device-assignment/get-device-association/get-device-association.types';
import {
  UpdateDeviceAssociationResponse,
  UpdateDeviceAssociationServiceType,
} from 'src/services/device-assignment/update-device-association/update-device-association.types';

import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import {
  getAlreadyAssignedPatientError,
  getAlreadyAssignedPatientStart,
  getAlreadyAssignedPatientSuccess,
  getDeviceAssociationError,
  getDeviceAssociationSuccess,
  resetDeviceAssignment,
  updateDeviceAssociationError,
  updateDeviceAssociationStart,
  updateDeviceAssociationSuccess,
} from './device-assignment.actions';
import { selectDeviceAssignmentUpdateRequestParams } from './device-assignment.selectors';
import {
  AlreadyAssignedPatient,
  AssociationErrorType,
  DeviceAssignmentActions,
  DeviceAssignmentActionType,
  GetAlreadyAssignedPatientStartAction,
  GetDeviceAssociationStartAction,
  UpdateDeviceAssociationStartAction,
} from './device-assignment.types';
import { constructDashboardUrlByResourceType } from './device-assignment.utils';

export const getDeviceAssociationEpic: (
  getDeviceAssociationService: GetDeviceAssociationServiceType,
) => Epic<DeviceAssignmentActions, any> =
  (getDeviceAssociationService) => (action$, store) =>
    action$
      .ofType(DeviceAssignmentActionType.GET_DEVICE_ASSOCIATION_START)
      .switchMap<{}, DeviceAssignmentActions>(
        ({ payload }: GetDeviceAssociationStartAction) =>
          Observable.fromPromise(
            getDeviceAssociationService(
              payload,
              selectAccessToken(store.getState()),
              selectGigyaToken(store.getState()),
            ),
          )
            .flatMap((response) =>
              response.patientId
                ? Observable.of<DeviceAssignmentActions>(
                    getDeviceAssociationSuccess(response),
                    getDeviceAssociationError(
                      AssociationErrorType.DEVICE_ALREADY_ASSIGNED_ERROR_KEY,
                    ),
                    getAlreadyAssignedPatientStart({
                      patientId: response.patientId,
                    }),
                  )
                : Observable.of(getDeviceAssociationSuccess(response)),
            )
            .pipe(
              catchError((err) =>
                Observable.of(
                  getDeviceAssociationError(
                    AssociationErrorType.INVALID_ASSOCIATION_ID_ERROR_KEY,
                  ),
                ),
              ),
            ),
      );

export const updateDeviceAssociationEpic: (
  updateDeviceAssociationService: UpdateDeviceAssociationServiceType,
) => Epic<DeviceAssignmentActions, any> =
  (updateDeviceAssociationService) => (action$, store) =>
    action$
      .ofType(DeviceAssignmentActionType.UPDATE_DEVICE_ASSOCIATION_START)
      .switchMap(({ payload }: UpdateDeviceAssociationStartAction) =>
        Observable.fromPromise(
          updateDeviceAssociationService(
            payload,
            selectAccessToken(store.getState()),
            selectGigyaToken(store.getState()),
          ),
        )
          .map((response: UpdateDeviceAssociationResponse) =>
            !response.success
              ? updateDeviceAssociationError(
                  AssociationErrorType.ASSOCIATION_FAILED,
                )
              : updateDeviceAssociationSuccess(response),
          )
          .pipe(
            catchError((err) =>
              Observable.of(
                updateDeviceAssociationError(
                  AssociationErrorType.ASSOCIATION_FAILED,
                ),
              ),
            ),
          ),
      );

export const redirectAfterDeviceAssignmentCancelEpic =
  (redirectToLink) => (action$, store$) =>
    action$
      .ofType(DeviceAssignmentActionType.CANCEL_ASSIGNMENT)
      .flatMap(() => [clearPatientSearchResults(), push(redirectToLink)]);

export const updateAssociationOnConfirmEpic: () => Epic<
  DeviceAssignmentActions,
  any
> = () => (action$, store$) =>
  action$
    .ofType(DeviceAssignmentActionType.CONFIRM_ASSIGNMENT)
    .map(() =>
      updateDeviceAssociationStart(
        selectDeviceAssignmentUpdateRequestParams(store$.getState()),
      ),
    );

export const resetFeatureStateAfterCancelEpic = () => (action$) =>
  action$
    .ofType(DeviceAssignmentActionType.CANCEL_ASSIGNMENT)
    .mapTo(resetDeviceAssignment());

export const getAlreadyAssignedPatientEpic: (
  getPatientService,
) => Epic<DeviceAssignmentActions, any> =
  (getPatientService) => (action$, store) =>
    action$
      .ofType(DeviceAssignmentActionType.GET_ALREADY_ASSIGNED_PATIENT_START)
      .switchMap(({ payload }: GetAlreadyAssignedPatientStartAction) => {
        const accessToken = selectAccessToken(store.getState());
        const gigyaToken = selectGigyaToken(store.getState());
        return Observable.fromPromise(
          getPatientService(payload, accessToken, gigyaToken),
        )
          .map((response: AlreadyAssignedPatient) =>
            getAlreadyAssignedPatientSuccess(response),
          )
          .pipe(
            catchError((err) =>
              Observable.of(getAlreadyAssignedPatientError()),
            ),
          );
      });

export const redirectAfterDeviceAssignmentDoneEpic = () => (action$, store$) =>
  action$.ofType(DeviceAssignmentActionType.DONE_ASSIGNMENT).switchMap(() => {
    const state = store$.getState();
    const patientDashboardUrl = constructDashboardUrlByResourceType(state);
    return [push(patientDashboardUrl), resetDeviceAssignment()];
  });
