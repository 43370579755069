import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { boxShadow } from 'src/core/styles/box-shadow';
import { zIndexes } from 'src/core/styles/z-index';
import { borderRadius } from 'src/core/styles/border-radius';
import {
  transitions,
  transitionSpeed,
  transitionEasing,
} from 'src/core/styles/transitions';
import { fadeIn } from 'src/core/styles/animations';
import { ListReset } from 'src/core/styles/resets';

import { POPOVER_LINK_ACTIVE_CLASS } from './menu-popover.constants';

export const PopoverContainerDiv = styled.div`
  display: ${(props) => (props.show ? 'inherit' : 'none')};
  position: relative;
  top: 0;
  z-index: ${zIndexes.popover};
`;

export const PopoverRectangleContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${borderRadius.three};
  border-top: 3px solid ${(props) => props.theme.colors.brandBlue};
  box-shadow: ${(props) =>
    boxShadow({
      color: props.theme.colors.charcoal,
      depth: 'popover',
    })};
  left: ${(props) => `-${props.pushLeft}rem`};
  position: absolute;
  width: 264px;
  display: inline-block;
  margin: 6px;
  margin-left: 40px;
  padding: 1rem;

  animation: ${fadeIn} ${transitionSpeed.fast} ${transitionEasing.enter}
    backwards;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #0066cc;
    position: absolute;
    top: -10px;
    left: 20px;
  }
`;

export const PopoverListContainerDiv = styled.div`
  ul:last-of-type {
    margin-top: 1rem;
    border-top: 1px solid ${(props) => props.theme.colors.grayLighter};
  }
`;
PopoverListContainerDiv.displayName = 'PopoverListContainerDiv';

export const PopoverListHeader = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighter};
  color: ${(props) => props.theme.colors.charcoal};
  font-size: ${(props) => props.theme.fontSize.title};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  margin: 0 ${(props) => props.theme.spacing.four} 0;
  padding-bottom: ${(props) => props.theme.spacing.three};
`;
PopoverListHeader.displayName = 'PopoverListHeader';

export const PopoverList = styled(ListReset)`
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  font-size: ${(props) => props.theme.fontSize.p};
  display: inline-block;
  width: 100%;
  padding-left: 0;
`;
PopoverList.displayName = 'PopoverList';

export const PopoverListItem = styled.li`
  display: inline-block;
  width: calc(33% - 0px);
  text-align: center;
  vertical-align: top;
`;
PopoverListItem.displayName = 'PopoverListItem';

export const PopoverListItemLink = styled(NavLink)`
  border-left: 3px solid ${(props) => props.theme.colors.clear};
  color: ${(props) => props.theme.colors.grayDark};
  display: block;
  padding: ${(props) =>
    `${props.theme.spacing.three} ${props.theme.spacing.three}`};
  padding-bottom: 10px;
  text-decoration: none;
  transition: ${transitions.default};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.colors.silverLight};
    color: ${(props) => props.theme.colors.charcoal};
  }

  &:active,
  &.${POPOVER_LINK_ACTIVE_CLASS} {
    border-color: ${(props) => props.theme.colors.brandBlue};
    color: ${(props) => props.theme.colors.brandBlue};
  }
`;
PopoverListItemLink.displayName = 'PopoverListItemLink';

export const PopoverListItemLinkTextSpan = styled.span`
  text-decoration: none;
`;
PopoverListItemLinkTextSpan.displayName = 'PopoverListItemLinkTextSpan';
