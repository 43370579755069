import { Action, PayloadAction } from 'src/app/store/app.types';
import {
  correlatePairingCodeTransformedResponseType,
  CorrelatePatientWithPairingCodeParams,
} from '../../../../../../services/pairing-code/pairing-code-correlation/pairing-code-correlation.types';

export enum CorrelatePairingCodeActionType {
  CORRELATE_PAIRING_CODE_START = 'CORRELATE_PAIRING_CODE_START',
  CORRELATE_PAIRING_CODE_SUCCESS = 'CORRELATE_PAIRING_CODE_SUCCESS',
  CORRELATE_PAIRING_CODE_ERROR = 'CORRELATE_PAIRING_CODE_ERROR',
  CORRELATE_PAIRING_CODE_ERROR_CONFLICT = 'CORRELATE_PAIRING_CODE_ERROR_CONFLICT',
  CLEAR_PAIRING_CODE_CORRELATION_STATE = 'CLEAR_PAIRING_CODE_CORRELATION_STATE',
}

export type CorrelatePairingCodePayloadType =
  CorrelatePatientWithPairingCodeParams;

export type CorrelatePairingCodeError = {
  error: string;
  message: string;
};

export type CorrelatePairingCodeResponseType =
  | CorrelatePairingCodeSuccessPayload
  | CorrelatePairingCodeErrorPayload;

export type CorrelatePairingCodeSuccessPayload =
  correlatePairingCodeTransformedResponseType;

export type CorrelatePairingCodeErrorPayload =
  correlatePairingCodeTransformedResponseType;

export type CorrelatePairingCodeErrorConflictPayload =
  correlatePairingCodeTransformedResponseType;

export type CorrelatePairingCodeStartAction = PayloadAction<
  CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_START,
  any
>;

export type CorrelatePairingCodeSuccessAction = PayloadAction<
  CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_SUCCESS,
  correlatePairingCodeTransformedResponseType
>;

export type CorrelatePairingCodeErrorAction = PayloadAction<
  CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_ERROR,
  CorrelatePairingCodeErrorPayload
>;

export type CorrelatePairingCodeErrorConflictAction = PayloadAction<
  CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_ERROR_CONFLICT,
  CorrelatePairingCodeErrorConflictPayload
>;

export type ClearPairingCodeCorrelationState =
  Action<CorrelatePairingCodeActionType.CLEAR_PAIRING_CODE_CORRELATION_STATE>;

export type CorrelatePairingCodeActions =
  | CorrelatePairingCodeStartAction
  | CorrelatePairingCodeSuccessAction
  | CorrelatePairingCodeErrorAction
  | CorrelatePairingCodeErrorConflictAction
  | ClearPairingCodeCorrelationState;

export type CorrelatePairingCodeState = {
  isCheckingPairingCodeCorrelation: boolean;
  correlationCheckWasSuccessful: boolean;
  correlationCheckHasError: boolean;
  correlationCheckHasErrorConflict: boolean;
};
