import { testId as _testId } from '@roche/roche-common';
import {
  NOT_CONNECTED,
  CONNECTED,
  REVOKED,
  PREFIX,
  BTN_ID,
  ALERT_ID,
  INFO,
  SUCCESS,
} from './constants';
import { Id, Status, Variant } from './types';

import {
  JELLO_BUTTON_VARIANTS,
  JELLO_ALERT_VARIANTS,
  JELLO_ICON_NAMES,
} from 'src/app/app.jello.constants';

export const getVariant = (id: Id, variant: Variant) => {
  if (id === BTN_ID) return JELLO_BUTTON_VARIANTS[variant];
  if (id === ALERT_ID) return JELLO_ALERT_VARIANTS[variant];
  return null;
};

export const getStatusProps = (
  id: Id,
  status: Status,
  isInterventionalStudyPatient,
) => {
  return {
    [NOT_CONNECTED]: {
      id: NOT_CONNECTED,
      icon: JELLO_ICON_NAMES.LINKED,
      label: isInterventionalStudyPatient
        ? `${PREFIX}.${CONNECTED}`
        : `${PREFIX}.${NOT_CONNECTED}`,
      content: `${PREFIX}.${id}.${NOT_CONNECTED}`,
      testId: { ..._testId(id, NOT_CONNECTED) },
      variant: getVariant(id, SUCCESS),
    },
    [CONNECTED]: {
      id: CONNECTED,
      icon: `${JELLO_ICON_NAMES.CHECKMARK_FILLED}`,
      label: `${PREFIX}.${CONNECTED}`,
      content: `${PREFIX}.${id}.${CONNECTED}`,
      testId: { ..._testId(id, CONNECTED) },
      variant: getVariant(id, SUCCESS),
    },
    [REVOKED]: {
      id: REVOKED,
      icon: `${JELLO_ICON_NAMES.PAUSE_OUTLINE}`,
      label: `${PREFIX}.${REVOKED}`,
      content: `${PREFIX}.${id}.${REVOKED}`,
      testId: { ..._testId(id, REVOKED) },
      variant: getVariant(id, INFO),
    },
  }[status];
};

export const getStatus = (consentState: string) =>
  `${consentState}`.split(' ').join('_');
