import {
  CreatePatientWithFhirLoaderImpl,
  CreatePatientWithFhirServiceImpl,
  CreatePatientWithFhirTransformImpl,
} from './create-patient-with-fhir.service';

import { mockCreatePatientResponse } from './create-patient-with-fhir.mock';

const mockPoster = () => Promise.resolve(mockCreatePatientResponse);

export const CreatePatientWithFhirFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPoster : CreatePatientWithFhirLoaderImpl;
  return CreatePatientWithFhirServiceImpl(
    loader,
    CreatePatientWithFhirTransformImpl,
  );
};
