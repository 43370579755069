import {
  GET_CURRENT_PRESCRIPTION_REQUEST,
  PRESCRIPTION_ACTIONS,
} from '../prescription.constants';
import { pipe } from 'ramda';
import { addPrescriptionEntry } from '../prescription.actions';
import { requestSequence } from '../../request/request.epics';
import { GET_STRIP_MODELS_REQUEST } from './strip-model.constants';
import {
  constructUnsavedPrescription,
  prescriptionTypeFromAction,
} from '../prescription.epics';

export const createUnsavedPrescriptionEntryEpic = () => (action$, _state) =>
  action$
    .ofType(
      GET_CURRENT_PRESCRIPTION_REQUEST.ERROR,
      PRESCRIPTION_ACTIONS.CREATE_PRESCRIPTION_ENTRY,
    )
    .map(
      pipe(
        prescriptionTypeFromAction,
        constructUnsavedPrescription,
        addPrescriptionEntry,
      ),
    );
export const getStripModelsEpic = (stripModelsService) =>
  requestSequence({
    service: stripModelsService,
    actionTypes: GET_STRIP_MODELS_REQUEST,
  });
