import styled from 'styled-components';

export const InternetExplorerNoSupportContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
InternetExplorerNoSupportContainer.displayName =
  'InternetExplorerNoSupportContainer';

export const InternetExplorerNoSupportTitle = styled.h2`
  font-size: 24px;
`;
InternetExplorerNoSupportTitle.displayName = 'InternetExplorerNoSupportTitle';
