import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const LostStripsLoaderImpl = (
  { patientId, stripModelReference, numberOfLostStrips },
  accessToken,
) =>
  postJSON(endpointWithParams(ENDPOINTS.lostStrips, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody({
      numberOfLostStrips,
      stripModelReference,
    }),
  });

export const LostStripsTransformImpl = (results) => results;

export const LostStripsServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
