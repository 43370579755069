import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { isEmpty } from 'ramda';

import { mapDispatchers } from '../../../utils/map-dispatchers';
import { fetchPatientRequest } from '../../../core/patient/patient.action';
import { selectPatient } from '../../../core/patient/patient.selector';

const DeliveryRouteWrapper = (props) => {
  const { children, location } = props;

  const extractIdFromPath = (path: string): string | null => {
    const match = path.match(/\/(\d+)\/.*$/);
    return match ? match[1] : null;
  };

  const id = extractIdFromPath(location.pathname);

  React.useEffect(() => {
    if (isEmpty(props.patient) && id) {
      props.fetchPatient({ patientId: id });
    }
  }, [id]);

  return children;
};

const mapDispatchersToProps = mapDispatchers({
  fetchPatient: fetchPatientRequest.start,
});

const mapStateToProps = (state) => ({
  patient: selectPatient(state),
});

export const DeliveryRouteWrapperContainer = withRouter(
  connect(mapStateToProps, mapDispatchersToProps)(DeliveryRouteWrapper),
);
