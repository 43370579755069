const fonts: any = {
  type: {
    mainFont: `'Nunito', sans-serif;`,
    mediumFont: `'Nunito-Medium', sans-serif;`,
  },
  weight: {
    light: '200',
    semiLight: '300',
    regular: '400',
    semiBold: '500',
    bold: '600',
    extraBold: '800',
    ultraBold: '900',
  },
  size: {
    s1: '12',
    s2: '14',
    s3: '16',
    m1: '20',
    m2: '24',
    m3: '28',
    l1: '32',
    l2: '40',
    l3: '48',
    code: '90',
  },
  text: {
    size: {
      small: '0.8em',
      regular: '1.0em',
      medium: '1.2em',
      large: '1.4em',
      xlarge: '1.6em',
      xxlarge: '1.8em',
    },
  },
};

export default fonts;
