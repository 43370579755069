import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { oidcEpics } from 'src/app/session/core/oidc/oidc.epics';
import { sessionEpics } from 'src/app/session/core/session/session.epics';
import { generatePatientReportsEpic } from 'src/core/patient-reports/patient-reports.epic';
import { continuousMonitoringEpics } from 'src/domains/patient-dashboards/cgm/store/cgm.epics';
import { savePatientSessionEndEpic } from 'src/core/patient-session/patient-session.epics';
import {
  logoutEpic,
  reloadAfterLogoutEpic,
} from 'src/core/login-system-error/login-system-error.epics';
import { pairingCodeIdentityStatusEpic } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/pairing-code-identity-status/pairing-code-identity-status.epic';
import { checkDataSharingConsentEpic } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.epic';

import {
  getDeliveryStatusEpic,
  getThresholdsEpic,
  getTimeIntervalsEpic,
  saveDeliveryEpic,
  setDeliveryStatusEpic,
  getDeliveryEpic,
  onDeliveryStatusChangeFetchStripStockEpic,
  getDeliveryHistoryEpic,
  createPatientStatusNotificationModalEpic,
  updateLastDeliveryStatusEpic,
  createSendPatientStatusModalEpic,
  getLastDeliveryStatusEpic,
  saveDeliveryStatusCommentEpic,
} from 'src/core/strip-delivery/strip-delivery.epics';
import {
  patientChangeEpic,
  fetchPatientEpic,
  fetchCurrentPatientErrorEpic,
} from 'src/core/patient/patient.epic';
import {
  fetchPatientDateRangeEpic,
  getClinicalDataOnDatesChangeEpic,
} from 'src/core/patient-date-range/patient-date-range.epic';
import {
  getPatientStockEpic,
  submitLostStripsEpic,
  submitManualDeliveryEpic,
} from 'src/core/patient-stock/patient-stock.epics';
import { getPatientSearchEpic } from 'src/core/patient-search/patient-search.epics';
import {
  addPrescriptionEntryEpic,
  getCurrentPrescriptionEpic,
  savePrescriptionEpic,
  setCurrentPrescriptionsEpic,
  onPatientChangeResetPrescriptionEpic,
  initializePrescriptionEpic,
  fetchTemporaryPrescriptionReasonsEpic,
  onClinicGuidesUpdateEpic,
  onPrescriptionSaveFetchLatestEpic,
} from 'src/core/prescription/prescription.epics';
import {
  createUnsavedPrescriptionEntryEpic,
  getStripModelsEpic,
} from 'src/core/prescription/strip-model/strip-model.epic';
import {
  getClinicGuidesEpic,
  saveClinicGuideEpic,
  deleteClinicGuideEpic,
  onSaveClinicGuideEpic,
  refreshClinicGuidesEpic,
} from 'src/core/prescription/clinic-guide/clinic-guide.epics';
import { getFrequenciesEpic } from 'src/core/prescription/frequencies/frequencies.epics';
import { getTherapiesEpic } from 'src/core/prescription/therapy/therapy.epics';
import {
  clearPatientStateEpic,
  clearOrganizationStateEpic,
} from 'src/core/state/state.epics';
import { getAlertsEpic, saveAlertsEpic } from 'src/core/alerts/alerts.epics';
import {
  fetchOrgStockEpic,
  addOrgStockEpic,
} from 'src/core/org-stock/org-stock.epic';
import { changeLanguageEpic, userEpic } from 'src/core/user/user.epics';
import {
  modalDomSideEffectsEpics,
  createLoadingModalEpic,
  successLoadingModalEpic,
  removeLoadingModalEpic,
  errorLoadingModalEpic,
  successRequiresConfirmationModalEpic,
} from 'src/core/modal/modal.epics';
import {
  getDeviceAssociationEpic,
  updateDeviceAssociationEpic,
  redirectAfterDeviceAssignmentCancelEpic,
  updateAssociationOnConfirmEpic,
  resetFeatureStateAfterCancelEpic,
  getAlreadyAssignedPatientEpic,
  redirectAfterDeviceAssignmentDoneEpic,
} from 'src/core/device-assignment/device-assignment.epics';
import {
  fetchPatientForEditEpic,
  checkAssociatedProfessionalsExistOnEditPatientEpic,
  updateAllowPatientAccessOnProfileEditEpic,
  fetchPatientPermissionsEpic,
  updateProfileTypeOnFetchPatientSuccessEpic,
  editPatientEpic,
  editPatientWithFhirEpic,
  editEMRPatientEpic,
} from 'src/core/edit-patient/edit-patient.epics';
import { getProfessionalsEpic } from 'src/core/department/department.epics';
import {
  oidcLoginErrorEpic,
  retryLoginEpic,
} from 'src/core/login-system-error/login-system-error.epics';
import { fetchConfigurablePermissionsEpic } from 'src/core/configurables/configurables.epics';
import {
  fetchPatientDevicesEpic,
  removePatientDeviceEpic,
} from 'src/core/list-devices/list-devices.epics';
import {
  mysugrStatusEpic,
  mysugrSendInviteEpic,
  mysugrResetInviteEpic,
} from 'src/core/mysugr/mysugr.epics';
import { fetchDeviceSettingsListEpic } from 'src/core/device-settings-list/device-settings-list.epic';
import {
  fetchDeviceSettingsEpic,
  fetchAllDeviceSettingsDataEpic,
} from 'src/core/device-settings/device-settings.epic';
import {
  exportPatientsSessionEpic,
  startPatientSessionEpic,
} from 'src/core/patient-session/patient-session.epics';

import { PatientStockFactoryImpl } from './services/patient-stock/get-patient-stock/get-patient-stock.factory';
import { PatientListFactoryImpl } from './services/patient-list/patient-list.factory';
import { PatientFactoryImpl } from './services/patient/patient.factory';
import { DateRangeFactoryImpl } from './services/date-range/patient-date-range.factory';
import { ClinicalDataFactoryImpl } from './services/clinical-data/clinical-data.factory';
import { ClinicalDataFromFhirFactoryImpl } from './services/clinical-data-from-fhir/clinical-data-from-fhir.factory';
import { TimeIntervalFactoryImpl } from './services/time-interval/time-interval.factory';
import { ThresholdFactoryImpl } from './services/threshold/threshold.factory';
import { GetDeliveryStatusFactoryImpl } from './services/delivery-status/get-delivery-status/get-delivery-status.factory';
import { GetAlertsFactoryImpl } from './services/alerts/get-alerts/get-alerts.factory';
import { SaveAlertsFactoryImpl } from './services/alerts/save-alerts/save-alerts.factory';
import { TherapiesFactoryImpl } from './services/therapies/therapies.factory';
import { FrequenciesFactoryImpl } from './services/frequencies/frequencies.factory';
import { PatientSearchFactoryImpl } from './services/patient-search/patient-search.factory';
import { GetPrescriptionFactoryImpl } from './services/prescription/get-prescription/get-prescription.factory';
import { SavePrescriptionFactoryImpl } from './services/prescription/save-prescription/save-prescription.factory';
import { SaveDeliveryFactoryImpl } from './services/delivery/save-delivery/save-delivery.factory';
import { SetDeliveryStatusFactoryImpl } from './services/delivery-status/set-delivery-status/set-delivery-status.factory';
import { GetDeliveryFactoryImpl } from './services/delivery/get-delivery/get-delivery.factory';
import { CurrentUserFactoryImpl } from './services/current-user/current-user.factory';
import { GetClinicGuidesFactoryImpl } from './services/clinic-guides/get-clinic-guides/get-clinic-guides.factory';
import { SaveClinicGuideFactoryImpl } from './services/clinic-guides/save-clinic-guide/save-clinic-guide.factory';
import { DeleteClinicGuideFactoryImpl } from './services/clinic-guides/delete-clinic-guide/delete-clinic-guide.factory';
import { StripModelsFactoryImpl } from './services/strip-models/strip-models.factory';
import { LostStripsFactoryImpl } from './services/patient-stock/lost-strips/lost-strips.factory';
import { ManualDeliveryFactoryImpl } from './services/patient-stock/manual-delivery/manual-delivery.factory';
import { PrescriptionReasonsFactoryImpl } from './services/prescription-reasons/prescription-reasons.factory';
import { GetOrgStockFactoryImpl } from './services/org-stock/get-org-stock/get-org-stock.factory';
import { PermissionsFactory } from './services/permissions/permissions.factory';
import { AddOrgStockFactoryImpl } from './services/org-stock/add-org-stock/add-org-stock.factory';
import { SaveDeliveryStatusCommentFactoryImpl } from './services/delivery-status/save-delivery-status-comment/save-delivery-status-comment.factory';
import { GetDeviceAssociationFactoryImpl } from './services/device-assignment/get-device-association/get-device-association.factory';
import { UpdateDeviceAssociationFactoryImpl } from './services/device-assignment/update-device-association/update-device-association.factory';
import { ProfileTypesFactory } from './services/department/profile-types/profile-types.factory';
import { GetActiveSystemLanguagesFactoryImpl } from './services/active-system-languages/active-system-languages.factory';
import { GetPatientInfoFactoryImpl } from './services/patient/patient-info/patient-info.factory';
import { FetchProfessionalFactoryImpl } from './services/professionals/get-professionals/get-professionals.factory';
import { GetLatestPatientsFactoryImpl } from './services/get-latest-patients/get-latest-patients.factory';
import { GetLatestPatientsSimpleTransformImpl } from './services/get-latest-patients/get-latest-patients.service';
import { EditPatientFactoryImpl } from './services/patient/edit-patient/edit-patient.factory';
import { EditEMRPatientFactoryImpl } from './services/patient/edit-emr-patient/edit-emr-patient.factory';
import { FetchConfigurablesFactoryImpl } from './services/configurables/configurables.factory';
import { ListDevicesFactoryImpl } from './services/patient/list-devices/list-devices.factory';
import { RemoveDeviceFactoryImpl } from './services/patient/remove-device/remove-device.factory';
import { DeactivatePatientFactoryImpl } from './services/patient/deactivate-patient/deactivate-patient.factory';
import { NextShipmentFetchFactoryImpl } from './services/next-shipment/next-shipment-fetch/next-shipment-fetch.factory';
import { NextShipmentChangeStatusFactoryImpl } from './services/next-shipment/next-shipment-change-status/next-shipment-change-status.factory';
import { NextShipmentChangeDateFactoryImpl } from './services/next-shipment/next-shipment-change-date/next-shipment-change-date.factory';
import { GetTimeBlocksFactoryImpl } from './services/patient/time-blocks/get-time-blocks/time-blocks.factory';
import {
  GetDeliveryConfigurationFactoryImpl,
  GetPatientDeliveryConfigurationFactoryImpl,
} from './services/patient/delivery-configuration/get-delivery-configuration/delivery-configuration.factory';
import { PostDeliveryConfigurationFactoryImpl } from './services/patient/delivery-configuration/post-delivery-configuration/post-delivery-configuration.factory';
import { PostTimeBlocksFactoryImpl } from './services/patient/time-blocks/post-time-blocks/post-time-blocks.factory';
import { MysugrStatusFactoryImpl } from './services/mysugr-status/mysugr-status.factory';
import { MysugrSendInviteFactoryImpl } from './services/mysugr-send-invite/mysugr-send-invite.factory';
import { StockAdjustmentsFactoryImpl } from './services/patient-stock/stock-adjustments/stock-adjustments.factory';
import { GetTargetRangesFetchFactoryImpl } from './services/target-ranges/get-target-ranges/get-target-ranges.factory';
import { SaveTargetRangesFetchFactoryImpl } from './services/target-ranges/save-target-ranges/save-target-ranges.factory';
import { GetHba1cCardFactoryImpl } from './services/hba1c-card/hba1c-card.factory';
import { GetHba1cListFactoryImpl } from './services/hba1c-list-values/hba1c-list.factory';
import { PostNewEntryHba1cFactoryImpl } from './services/new-entry-hba1c/new-entry-hba1c.factory';
import { ClinicalStatisticsFactoryImpl } from './services/clinical-statistics/clinical-statistics.factory';
import { DeviceSettingsListFactoryImpl } from './services/device-settings-list/device-settings-list.factory';
import { DeviceSettingsFactoryImpl } from './services/device-settings/device-settings.factory';
import { PatientReportsFactoryImpl } from './services/patient-reports/patient-reports.factory';
import {
  GetRpmSettingsFactoryImpl,
  UpdateRpmSettingsFactoryImpl,
} from './services/rpm/rpm-settings/rpm-settings.factory';
import { PatientSessionEndFactoryImpl } from './services/patient-session/patient-session-end/patient-session-end.factory';
import { PatientsSessionExportFactoryImpl } from './services/patients-session-export/patients-session-export.factory';
import { PatientSessionStartFactoryImpl } from './services/patient-session/patient-session-start/patient-session-start.factory';
import { CheckExternalPatientFactoryImpl } from './services/patient/check-external-patient/check-external-patient.factory';
import { SaveUserUnitsFactoryImpl } from './services/user-units/save-user-units/save-user-units.factory';
import { GetUserUnitsFactoryImpl } from './services/user-units/get-user-units/get-user-units.factory';
import { GetINSIdentityFetchFactoryImpl } from './services/INS-identity/get-INS-identity/get-INS-identity.factory';
import { CheckPairingCodeFactoryImp } from './services/pairing-code/pairing-code-check/pairing-code-check.factory';
import { CorrelatePairingCodeFactoryImp } from './services/pairing-code/pairing-code-correlation/pairing-code-correlation.factory';
import { EditPatientWithFhirFactoryImpl } from './services/patient/edit-patient-with-fhir/edit-patient-with-fhir.factory';
import { InvitePatientFactoryImp } from './services/rdac/invite-patient/invite-patient.factory';
import { CheckPairingCodeAvailabilityFactoryImpl } from './services/pairing-code/pairing-code-availability/pairing-code-availability.factory';
import { CheckEmailFactoryImpl } from './services/check-email/check-email.factory';
import { CheckHCIDFactoryImpl } from './services/check-hcid/check-hcid.factory';
import { correlatePairingCodeEpic } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/correlate-pairing-code/correlate-pairing-code.epics';
import { invitePatientEpic } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/invite-patient/invite-patient.epics.ts';
import { getPatientsListEpic } from './domains/patient/scenes/patients/store/patients.epic';
import {
  fetchBGOverviewClinicalDataEpic,
  fetchBGOverviewClinicalDataFromFhirEpic,
  fetchBGOverviewEndDateEpic,
  fetchCheckExternalPatientEpic,
  getClinicalDataEpic,
  getClinicalDataFromFhirEpic,
  getClinicalDataOnDateRangeEpic,
  getClinicalDataOnBGOverviewEndDateEpic,
  setClinicalDataEpic,
} from './domains/patient-dashboards/bg/store/bg.epics';
import {
  permissionsEpic,
  patientPermissionsEpic,
} from './core/permissions/permissions.epics';
import { GetLastDeliveryStatusFactoryImpl } from './services/delivery-status/get-last-delivery-status/get-last-delivery-status.factory';
import { CreatePatientFactoryImpl } from './services/patient/create-patient/create-patient.factory';
import { CreatePatientWithFhirFactoryImpl } from './services/patient/create-patient-with-fhir/create-patient-with-fhir.factory';
import { CreateProfessionalFactoryImpl } from './services/professionals/create-professional/create-professional.factory';
import { EditProfileFactoryImpl } from './services/profile/edit-profile.factory';
import { EditEMRProfileFactoryImpl } from './services/profile-emr/edit-emr-profile.factory';
import {
  createPatientEpic,
  createPatientWithFhirEpic,
  getCreatedPatientEpic,
  populateCreatePatientLanguageEpic,
  populateCreatePatientProfessionalsEpic,
  setDefaultHCPWhenCreatingPatientEpic,
  setDefaultLanguageWhenCreatingPatientEpic,
  setPatientCountryFromDepartmentEpic,
  updateAllowPatientAccessOnProfileChangeEpic,
  updateCreatePatient,
  updateCreatePatientHealthInfo,
} from './widgets/patient/create-patient/store/create-patient.epics';
import {
  populateCreateProfessionalLanguageEpic,
  createProfessionalEpic,
  setDefaultLanguageWhenCreatingProfessionalEpic,
  setOpenConnectivityWhenCreatingProfessionalEpic,
} from './widgets/professional/create-professional/store/create-professional.epics';
import {
  editProfileEpic,
  editEMRProfileEpic,
} from './widgets/profile/edit-profile/store/edit-profile.epics';
import { generalLinks } from './domains/general/routes';
import { getDepartmentProfileTypesEpic } from './core/department/department.epics';
import { countryService } from './services/country/country.service';
import { countriesEpic } from './core/countries/countries.epics';
import { fetchLanguagesEpic } from './core/languages/languages.epics';
import {
  getLatestPatientsEpic,
  getLatestPatientsAfterPatientCreatedEpic,
} from './core/latest-patients/latest-patients.epics';
import { deactivatePatientEpic } from './domains/patient/scenes/deactivate-patient/store/deactivate-patient.epics';
import {
  fetchNextShipmentEpic,
  changeStatusNextShipmentEpic,
  changeDateNextShipmentEpic,
} from './domains/patient/scenes/next-shipment/store/next-shipment.epics';
import {
  fetchPatientTimeBlocksEpic,
  savePatientTimeBlocksEpic,
} from './domains/patient/scenes/time-periods/store/time-periods.epics';
import {
  fetchDeliveryConfigurationEpic,
  saveDeliveryConfigurationEpic,
} from './domains/patient/scenes/delivery-configuration/store/delivery-configuration.epics';
import { fetchHba1cCardEpics } from './domains/diagnostics/widgets/hba1c-card/store/hba1c-card.epics';
import { fetchHba1cListEpics } from './domains/diagnostics/widgets/hba1c-modal/components/list-values-hba1c/store/list-values-hba1c.epics';
import { expiringModalEpics } from './app/session/core/expiring-modal/expiring-modal.epics';
import { saveNewEntryHba1cEpic } from './domains/diagnostics/widgets/hba1c-modal/components/new-entry-hba1c/store/new-entry-hba1c.epics';
import { fetchPatientsEpic } from './domains/general/widgets/store/hcp-dashboard.epics';
import {
  fetchRpmSettingsEpic,
  updateRpmSettingsEpic,
} from './core/rpm/rpm-settings/rpm-settings.epics';
import {
  saveUserUnitsEpic,
  getUserUnitsEpic,
} from './widgets/user-units-selector/store/user-units-selector.epics';
import { getINSIdentityEpic } from './widgets/patient/INS-patient/store/INS-identity.epic';
import {
  getTargetRangesEpic,
  saveTargetRangesEpic,
} from './widgets/target-ranges/store/target-ranges.epics';
import { checkPairingCodeEpic } from './widgets/patient/create-patient/create-patient-with-platform/store/check-pairing-code/check-pairing-code.epics';
import { pairingCodeIdentityStatusFactoryImp } from 'src/services/pairing-code/pairing-code-identity-status/pairing-code-identity-status.factory';
import { CheckDataSharingConsentFactoryImp } from 'src/services/patient/check-data-sharing-consent/check-data-sharing-consent.factory';
import {
  checkIsSharingCodeAvailableEpic,
  connectSharingCodeEpic,
} from 'src/widgets/patient/create-edit-form-jello/store/connect-sharing-code/connect-sharing-code.epic';
import { sendInvitationtEpic } from 'src/widgets/patient/create-edit-form-jello/store/send-invitation/send-invitation.epic';
import { checkDuplicatedEmailEpic } from 'src/widgets/patient/create-edit-form-jello/store/check-duplicated-email/check-duplicated-email.epic';
import { checkDuplicatedHealthcareIdEpic } from 'src/widgets/patient/create-edit-form-jello/store/check-duplicated-healthcare-id/check-duplicated-healthcare-id.epic';
import { createFhirPatientEpic } from 'src/widgets/patient/create-edit-form-jello/store/create-patient/create-patient.epic';
import {
  editPatientPlatformEpic,
  fetchEditPatientDataEpic,
} from 'src/widgets/patient/create-edit-form-jello/store/edit-patient/edit-patient.epic';
import { correlateSharingCodeEpic } from 'src/widgets/patient/create-edit-form-jello/store/correlate-sharing-code/correlate-sharing-code.epic';

import { UnblindClinicalStudyPatientFactoryImp } from 'src/services/patient/unblind-clinical-study-patient/unblind-clinical-study-patient.factory';
import { unblindClinicalStudyPatientEpic } from 'src/domains/patient/scenes/clinical-study/store/unblind-clinical-study-patient.epic';

export const rootEpic = combineEpics(
  getPatientStockEpic(PatientStockFactoryImpl({ devMode: false })),
  getPatientsListEpic(PatientListFactoryImpl({ devMode: false })),
  fetchPatientEpic(PatientFactoryImpl({ devMode: false })),
  fetchCurrentPatientErrorEpic(),
  oidcLoginErrorEpic(),
  retryLoginEpic(),
  fetchPatientDateRangeEpic(DateRangeFactoryImpl({ devMode: false })),
  fetchBGOverviewEndDateEpic(DateRangeFactoryImpl({ devMode: false })),
  getClinicalDataEpic(ClinicalDataFactoryImpl({ devMode: false })),
  getClinicalDataFromFhirEpic(
    ClinicalDataFromFhirFactoryImpl({ devMode: false }),
  ),
  fetchCheckExternalPatientEpic(
    CheckExternalPatientFactoryImpl({ devMode: false }),
  ),
  fetchBGOverviewClinicalDataEpic(ClinicalDataFactoryImpl({ devMode: false })),
  fetchBGOverviewClinicalDataFromFhirEpic(
    ClinicalDataFromFhirFactoryImpl({ devMode: false }),
  ),
  getClinicalDataOnDateRangeEpic(),
  getClinicalDataOnBGOverviewEndDateEpic(),
  setClinicalDataEpic(),
  getClinicalDataOnDatesChangeEpic(),
  getTimeIntervalsEpic(TimeIntervalFactoryImpl({ devMode: false })),
  getThresholdsEpic(ThresholdFactoryImpl({ devMode: false })),
  getDeliveryStatusEpic(GetDeliveryStatusFactoryImpl({ devMode: false })),
  getAlertsEpic(GetAlertsFactoryImpl({ devMode: false })),
  getCurrentPrescriptionEpic(GetPrescriptionFactoryImpl({ devMode: false })),
  saveAlertsEpic(SaveAlertsFactoryImpl({ devMode: false })),
  getTherapiesEpic(TherapiesFactoryImpl({ devMode: false })),
  getClinicGuidesEpic(GetClinicGuidesFactoryImpl({ devMode: false })),
  saveClinicGuideEpic(SaveClinicGuideFactoryImpl({ devMode: false })),
  deleteClinicGuideEpic(DeleteClinicGuideFactoryImpl({ devMode: false })),
  getFrequenciesEpic(FrequenciesFactoryImpl({ devMode: true })),
  getPatientSearchEpic(PatientSearchFactoryImpl({ devMode: false })),
  getStripModelsEpic(StripModelsFactoryImpl({ devMode: false })),
  savePrescriptionEpic(SavePrescriptionFactoryImpl({ devMode: false })),
  getDeliveryEpic(GetDeliveryFactoryImpl({ devMode: false })),
  saveDeliveryEpic(SaveDeliveryFactoryImpl({ devMode: false })),
  setDeliveryStatusEpic(SetDeliveryStatusFactoryImpl({ devMode: false })),
  getDeliveryHistoryEpic(StockAdjustmentsFactoryImpl({ devMode: false })),
  getTargetRangesEpic(GetTargetRangesFetchFactoryImpl({ devMode: false })),
  saveTargetRangesEpic(SaveTargetRangesFetchFactoryImpl({ devMode: false })),
  addPrescriptionEntryEpic(),
  createUnsavedPrescriptionEntryEpic(),
  patientChangeEpic(),
  setCurrentPrescriptionsEpic(),
  onPatientChangeResetPrescriptionEpic(),
  submitLostStripsEpic(LostStripsFactoryImpl({ devMode: false })),
  submitManualDeliveryEpic(ManualDeliveryFactoryImpl({ devMode: false })),
  initializePrescriptionEpic(),
  fetchTemporaryPrescriptionReasonsEpic(
    PrescriptionReasonsFactoryImpl({ devMode: false }),
  ),
  checkAssociatedProfessionalsExistOnEditPatientEpic(),
  fetchPatientPermissionsEpic(),
  onClinicGuidesUpdateEpic(),
  onSaveClinicGuideEpic(),
  clearPatientStateEpic,
  onPrescriptionSaveFetchLatestEpic(),
  onDeliveryStatusChangeFetchStripStockEpic(),
  fetchOrgStockEpic(GetOrgStockFactoryImpl({ devMode: false })),
  addOrgStockEpic(AddOrgStockFactoryImpl({ devMode: false })),
  permissionsEpic(PermissionsFactory({ devMode: false })),
  changeLanguageEpic(),
  clearOrganizationStateEpic,
  modalDomSideEffectsEpics(),
  createPatientStatusNotificationModalEpic(),
  updateLastDeliveryStatusEpic(),
  patientPermissionsEpic(
    PermissionsFactory({ devMode: false, type: 'patient' }),
  ),
  createSendPatientStatusModalEpic(),
  getLastDeliveryStatusEpic(
    GetLastDeliveryStatusFactoryImpl({ devMode: false }),
  ),
  userEpic(CurrentUserFactoryImpl({ devMode: false })),
  saveDeliveryStatusCommentEpic(
    SaveDeliveryStatusCommentFactoryImpl({ devMode: false }),
  ),
  createLoadingModalEpic(),
  successLoadingModalEpic(),
  removeLoadingModalEpic(),
  errorLoadingModalEpic(),
  successRequiresConfirmationModalEpic(),
  refreshClinicGuidesEpic(),
  getDeviceAssociationEpic(GetDeviceAssociationFactoryImpl({ devMode: false })),
  updateDeviceAssociationEpic(
    UpdateDeviceAssociationFactoryImpl({ devMode: false }),
  ),
  updateAssociationOnConfirmEpic(),
  redirectAfterDeviceAssignmentCancelEpic(generalLinks.home),
  resetFeatureStateAfterCancelEpic(),
  getAlreadyAssignedPatientEpic(PatientFactoryImpl({ devMode: false })),
  createPatientEpic(CreatePatientFactoryImpl({ devMode: false })),
  createProfessionalEpic(CreateProfessionalFactoryImpl({ devMode: false })),
  editProfileEpic(EditProfileFactoryImpl({ devMode: false })),
  editEMRProfileEpic(EditEMRProfileFactoryImpl({ devMode: false })),
  getProfessionalsEpic(FetchProfessionalFactoryImpl({ devMode: false })),
  getDepartmentProfileTypesEpic(ProfileTypesFactory({ devMode: false })),
  redirectAfterDeviceAssignmentDoneEpic(),
  countriesEpic(countryService({ devMode: false })),
  getCreatedPatientEpic(PatientFactoryImpl({ devMode: false })),
  fetchLanguagesEpic(
    GetActiveSystemLanguagesFactoryImpl({
      devMode: false,
    }),
  ),
  fetchPatientForEditEpic(
    GetPatientInfoFactoryImpl({ devMode: false }),
    FetchConfigurablesFactoryImpl({ devMode: false }),
  ),
  updateProfileTypeOnFetchPatientSuccessEpic(),
  updateAllowPatientAccessOnProfileChangeEpic(),
  updateCreatePatient(),
  updateCreatePatientHealthInfo(),
  updateAllowPatientAccessOnProfileEditEpic(),
  getLatestPatientsEpic(
    GetLatestPatientsFactoryImpl(
      { devMode: false },
      GetLatestPatientsSimpleTransformImpl,
    ),
  ),
  getLatestPatientsAfterPatientCreatedEpic(),
  editPatientEpic(EditPatientFactoryImpl({ devMode: false })),
  editEMRPatientEpic(EditEMRPatientFactoryImpl({ devMode: false })),
  populateCreatePatientLanguageEpic(),
  populateCreateProfessionalLanguageEpic(),
  populateCreatePatientProfessionalsEpic(),
  setPatientCountryFromDepartmentEpic(),
  setDefaultHCPWhenCreatingPatientEpic(),
  setDefaultLanguageWhenCreatingPatientEpic(),
  setDefaultLanguageWhenCreatingProfessionalEpic(),
  setOpenConnectivityWhenCreatingProfessionalEpic(),
  expiringModalEpics,
  fetchConfigurablePermissionsEpic(
    FetchConfigurablesFactoryImpl({ devMode: false }),
  ),
  fetchPatientDevicesEpic(ListDevicesFactoryImpl({ devMode: false })),
  fetchAllDeviceSettingsDataEpic(ListDevicesFactoryImpl({ devMode: false })),
  removePatientDeviceEpic(RemoveDeviceFactoryImpl({ devMode: false })),
  deactivatePatientEpic(DeactivatePatientFactoryImpl({ devMode: false })),
  fetchNextShipmentEpic(NextShipmentFetchFactoryImpl({ devMode: false })),
  changeStatusNextShipmentEpic(
    NextShipmentChangeStatusFactoryImpl({ devMode: false }),
  ),
  changeDateNextShipmentEpic(
    NextShipmentChangeDateFactoryImpl({ devMode: false }),
  ),
  fetchPatientTimeBlocksEpic(GetTimeBlocksFactoryImpl({ devMode: false })),
  fetchDeliveryConfigurationEpic(
    GetDeliveryConfigurationFactoryImpl({ devMode: false }),
    GetPatientDeliveryConfigurationFactoryImpl({ devMode: false }),
  ),
  fetchHba1cCardEpics(GetHba1cCardFactoryImpl({ devMode: false })),
  fetchHba1cListEpics(GetHba1cListFactoryImpl({ devMode: false })),
  saveNewEntryHba1cEpic(PostNewEntryHba1cFactoryImpl({ devMode: false })),
  savePatientTimeBlocksEpic(PostTimeBlocksFactoryImpl({ devMode: false })),
  saveDeliveryConfigurationEpic(
    PostDeliveryConfigurationFactoryImpl({ devMode: false }),
  ),
  fetchPatientsEpic(ClinicalStatisticsFactoryImpl({ devMode: false })),
  mysugrStatusEpic(MysugrStatusFactoryImpl({ devMode: false })),
  mysugrSendInviteEpic(MysugrSendInviteFactoryImpl({ devMode: false })),
  mysugrResetInviteEpic,
  oidcEpics,
  sessionEpics,
  fetchDeviceSettingsListEpic(
    DeviceSettingsListFactoryImpl({ devMode: false }),
  ),
  fetchDeviceSettingsEpic(DeviceSettingsFactoryImpl({ devMode: false })),
  generatePatientReportsEpic(PatientReportsFactoryImpl({ devMode: false })),
  continuousMonitoringEpics,
  fetchRpmSettingsEpic(GetRpmSettingsFactoryImpl({ devMode: false })),
  updateRpmSettingsEpic(UpdateRpmSettingsFactoryImpl({ devMode: false })),
  savePatientSessionEndEpic(PatientSessionEndFactoryImpl({ devMode: false })),
  exportPatientsSessionEpic(
    PatientsSessionExportFactoryImpl({ devMode: false }),
  ),
  startPatientSessionEpic(PatientSessionStartFactoryImpl({ devMode: false })),
  saveUserUnitsEpic(SaveUserUnitsFactoryImpl({ devMode: false })),
  getUserUnitsEpic(GetUserUnitsFactoryImpl({ devMode: false })),
  // getTargetRangesEpic(GetTargetRangesFetchFactoryImpl({ devMode: false })),
  // saveTargetRangesEpic(SaveTargetRangesFetchFactoryImpl({ devMode: false })),
  getINSIdentityEpic(GetINSIdentityFetchFactoryImpl({ devMode: true })),
  // OLD FORM EPIC
  checkPairingCodeEpic(CheckPairingCodeFactoryImp({ devMode: false })),
  correlatePairingCodeEpic(CorrelatePairingCodeFactoryImp({ devMode: false })),
  invitePatientEpic(InvitePatientFactoryImp({ devMode: false })),
  createPatientWithFhirEpic(
    CreatePatientWithFhirFactoryImpl({ devMode: false }),
  ),
  editPatientWithFhirEpic(EditPatientWithFhirFactoryImpl({ devMode: false })),
  // NEW FORM EPIC
  connectSharingCodeEpic(CheckPairingCodeFactoryImp({ devMode: false })),
  sendInvitationtEpic(InvitePatientFactoryImp({ devMode: false })),
  checkIsSharingCodeAvailableEpic(
    CheckPairingCodeAvailabilityFactoryImpl({ devMode: false }),
  ),
  checkDuplicatedEmailEpic(CheckEmailFactoryImpl({ devMode: false })),
  checkDuplicatedHealthcareIdEpic(CheckHCIDFactoryImpl({ devMode: false })),
  createFhirPatientEpic(CreatePatientWithFhirFactoryImpl({ devMode: false })),
  fetchEditPatientDataEpic(
    GetPatientInfoFactoryImpl({ devMode: false }),
    FetchConfigurablesFactoryImpl({ devMode: false }),
  ),
  editPatientPlatformEpic(EditPatientWithFhirFactoryImpl({ devMode: false })),
  correlateSharingCodeEpic(CorrelatePairingCodeFactoryImp({ devMode: false })),
  //END FORM EPICS
  logoutEpic(),
  reloadAfterLogoutEpic(),
  pairingCodeIdentityStatusEpic(
    pairingCodeIdentityStatusFactoryImp({ devMode: false }),
  ),
  checkDataSharingConsentEpic(
    CheckDataSharingConsentFactoryImp({ devMode: false }),
  ),
  unblindClinicalStudyPatientEpic(
    UnblindClinicalStudyPatientFactoryImp({ devMode: false }),
  ),
);
const epic$ = new BehaviorSubject(rootEpic);

export const appEpic = (action$, store) =>
  epic$.mergeMap((epic) => epic(action$, store, null));

export const injectEpic = (asyncEpic) => epic$.next(asyncEpic);
export const injectEpicFactory =
  (injectedEpicNamespaces) => (namespace, asyncEpic) => {
    if (injectedEpicNamespaces.indexOf(namespace) >= 0) {
      return epic$;
    }
    injectedEpicNamespaces = [...injectedEpicNamespaces, namespace];
    return epic$.next(asyncEpic);
  };
