import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  WithIntermediateNavHeight,
  WithNoNav,
  WithNoMargins,
} from 'src/navigation/utils/navigation.utils';

import { ProtectedRoute } from 'src/components/protected-route/protected-route.container';

import { HomeBundle, HomeCgmBundle, HelpBundle } from './bundles';

export const generalLinks = {
  home: '/home',
  dtc: '/dtc',
  help: '/help',
  homeCgm: '/home/cgm',
};

export const GeneralRoutes = () => (
  <Switch>
    <Route key="home-route-w" exact path={generalLinks.home}>
      <WithIntermediateNavHeight>
        <ProtectedRoute
          key="home-route"
          exact
          path={generalLinks.home}
          component={HomeBundle}
        />
      </WithIntermediateNavHeight>
    </Route>
    <Route key="home-cgm-route-w" exact path={generalLinks.homeCgm}>
      <WithIntermediateNavHeight>
        <ProtectedRoute
          key="home-cgm-route"
          exact
          path={generalLinks.homeCgm}
          component={HomeCgmBundle}
        />
      </WithIntermediateNavHeight>
    </Route>
    <Route key="help-route-w" exact path={generalLinks.help}>
      <WithNoMargins>
        <WithNoNav>
          <ProtectedRoute
            key="help"
            exact
            path={generalLinks.help}
            component={HelpBundle}
          />
        </WithNoNav>
      </WithNoMargins>
    </Route>
  </Switch>
);
