import { postJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

const transformBody = (hba1cInfo) =>
  JSON.stringify({
    hba1cDate: hba1cInfo.actionPerformedAt,
    hba1cValue: parseFloat(hba1cInfo.newEntryValue),
  });

export const PostNewEntryHba1cLoaderImpl: FixMe = (
  accessToken: string,
  gigyaToken: string,
  fhirID: string,
  hba1cInfo: FixMe,
) =>
  postJSON(endpointWithParams(ENDPOINTS.newEntryHba1c, { fhirID }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: transformBody(hba1cInfo),
  });

export const PostNewEntryHba1cTransformImpl = (data: FixMe): FixMe =>
  data.model;

export const PostNewEntryHba1cServiceImpl: FixMe =
  (load: FixMe, transform) => (accessToken, gigyaToken, fhirID, hba1cInfo) =>
    load(accessToken, gigyaToken, fhirID, hba1cInfo).then(transform);
