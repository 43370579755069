import * as React from 'react';
import { pathOr } from 'ramda';
import { countlyEventTrigger } from 'src/navigation/countly';

import { LocalizedText } from 'src/components/localized-text/localized-text.component';

import { INFOS } from 'src/domains/diagnostics/components/additional-info/additional-info.constants';
import { AdditionalInfo } from 'src/domains/diagnostics/components/additional-info/additional-info.container';

import {
  PopoverList,
  PopoverListItem,
  PopoverListItemLink,
  PopoverListItemExternalLink,
} from './help-popover.style';
import {
  IconContainer,
  IconContainerIconHelp,
  PopoverListItemLinkTextSpan,
  PopoverListItemNoLink,
  RowWrapper,
} from './help-dropdown-popover.style';
import { Popover } from './help-popover.component';

import {
  HELP_DOCUMENTATION_EVENTS_KEYS,
  HELP_DOCUMENTATION_SEGMENT_KEYS,
  HELP_DOCUMENTATION_SEGMENT_VALUES,
} from '../help-dropdown.countly.constants';

import { JelloIcon } from 'src/components/jello-icon/jello-icon.component';

import { getConfig } from '@roche/roche-common';

import {
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
} from 'src/app/app.jello.constants';

import { colors } from 'src/core/styles/colors';

const { REACT_APP_IFUS } = getConfig();

export const listIcons = {
  items: [
    {
      key: 'gettingstarted',
      iconName: JELLO_ICON_NAMES.LOGBOOK,
      linkText: 'helpDropdown.gettingStarted',
      isAdditionalInfo: true,
    },
    {
      key: 'info',
      iconName: JELLO_ICON_NAMES.INFORMATION,
      linkText: 'helpDropdown.productInfo',
      onClick: 'showManufacturerInfoModal',
    },
    {
      key: 'ifu',
      iconName: JELLO_ICON_NAMES.LOGBOOK,
      linkText: 'helpDropdown.ifu',
      link: `${REACT_APP_IFUS}/rdcp/ifu/{{locale}}.pdf`,
      external: true,
    },
    {
      key: 'support',
      iconName: JELLO_ICON_NAMES.LAUNCH,
      linkText: 'helpDropdown.supportCenter',
      link: 'https://support.rochediabetes.com/careplatform/',
      external: true,
    },
  ],
};

type HelpDropdownPopoverType = {
  onSignOut: () => void;
  show: boolean;
  routes: FixMe;
  showDtcModal: () => void;
  showManufacturerInfoModal: () => void;
  closeMenu: () => void;
  locale: string;
};

type ListItemType = {
  permissions?: string[];
  id: string;
  link?: string;
  iconName: any;
  iconSize?: number;
  linkText: string;
  onClick?: any;
  isAdditionalInfo?: boolean;
  closeMenu: () => void;
  external?: boolean;
};

const ListItem: React.FunctionComponent<ListItemType> = ({
  id,
  link,
  iconName,
  linkText,
  iconSize,
  onClick,
  external,
  isAdditionalInfo,
  closeMenu,
}) => {
  const countlyHandleListItemLink = (linkValue, keyValue) => {
    closeMenu();
    if (linkValue === '/help') {
      countlyEventTrigger(
        HELP_DOCUMENTATION_EVENTS_KEYS.HELP_DOCUMENTATION_OPENED,
        {
          [HELP_DOCUMENTATION_SEGMENT_KEYS.HELP_OPTION]:
            HELP_DOCUMENTATION_SEGMENT_VALUES.USER_GUIDES,
        },
      );
    }
    if (keyValue === 'support') {
      countlyEventTrigger(
        HELP_DOCUMENTATION_EVENTS_KEYS.HELP_DOCUMENTATION_OPENED,
        {
          [HELP_DOCUMENTATION_SEGMENT_KEYS.HELP_OPTION]:
            HELP_DOCUMENTATION_SEGMENT_VALUES.SUPPORT_CENTER,
        },
      );
    }
  };

  return (
    <PopoverListItem
      key={`pma-${id}`}
      id={id}
      onClick={() => countlyHandleListItemLink(link, id)}
    >
      {isAdditionalInfo ? (
        <AdditionalInfo info={INFOS.gettingStarted}>
          <PopoverListItemNoLink>
            <RowWrapper>
              <IconContainerIconHelp>
                <JelloIcon
                  className="jello-icon-help"
                  size={JELLO_ICON_SIZES.XS}
                  iconName={JELLO_ICON_NAMES.HELP}
                />
              </IconContainerIconHelp>
              <PopoverListItemLinkTextSpan>
                <LocalizedText textKey={linkText} />
              </PopoverListItemLinkTextSpan>
            </RowWrapper>
          </PopoverListItemNoLink>
        </AdditionalInfo>
      ) : (
        <>
          {!!link && !!external && (
            <PopoverListItemExternalLink
              href={link}
              target={'_blank'}
              className={getActive(link)}
            >
              <RowWrapper>
                {drawIcon(iconName, closeMenu)}
                <PopoverListItemLinkTextSpan>
                  <LocalizedText textKey={linkText} />
                </PopoverListItemLinkTextSpan>
              </RowWrapper>
            </PopoverListItemExternalLink>
          )}
          {!!link && !external && (
            <PopoverListItemLink
              to={link}
              target={'_blank'}
              className={getActive(link)}
            >
              <RowWrapper>
                {drawIcon(iconName, closeMenu)}
                <PopoverListItemLinkTextSpan>
                  <LocalizedText textKey={linkText} />
                </PopoverListItemLinkTextSpan>
              </RowWrapper>
            </PopoverListItemLink>
          )}
          {!link && (
            <PopoverListItemNoLink onClick={onClick}>
              <RowWrapper>
                {drawIcon(iconName, closeMenu)}
                <PopoverListItemLinkTextSpan>
                  <LocalizedText textKey={linkText} />
                </PopoverListItemLinkTextSpan>
              </RowWrapper>
            </PopoverListItemNoLink>
          )}
        </>
      )}
    </PopoverListItem>
  );
};
ListItem.displayName = 'HelpDropdownPopoverListItem';

export const HelpDropdownPopover: React.FunctionComponent<
  HelpDropdownPopoverType
> = (props) => {
  const { items } = listIcons;

  return (
    <Popover pushLeft={3} width={17} show={props.show}>
      <PopoverList>{items.map((icon) => drawItem(props, icon))}</PopoverList>
    </Popover>
  );
};

const getActive = (link) => {
  const location = pathOr(null, ['location', 'pathname'], window);
  return location === link ? 'selected' : '';
};

const drawIcon = (iconName, closeMenu) => (
  <IconContainer onClick={() => closeMenu()}>
    <JelloIcon
      size={JELLO_ICON_SIZES.XS}
      iconName={iconName}
      color={colors.brandBlue}
    />
  </IconContainer>
);

const drawItem = (props, icon) => {
  const {
    key,
    permissions,
    iconName,
    iconSize,
    linkText,
    onClick,
    link,
    external,
    isAdditionalInfo,
  } = icon;
  const { routes, closeMenu, locale } = props;
  const _onClick = onClick ? props[onClick] : null;
  let _link;

  if (external) {
    _link = link;
    _link = `${_link}`.replace('{{locale}}', `${locale}`);
  } else if (!!link && !external) {
    _link = routes[link[0]][link[1]];
  }

  return (
    <React.Fragment key={key}>
      <ListItem
        id={key}
        permissions={permissions}
        iconName={iconName}
        iconSize={iconSize}
        linkText={linkText}
        closeMenu={closeMenu}
        onClick={_onClick}
        link={_link}
        external={external}
        isAdditionalInfo={isAdditionalInfo}
      />
    </React.Fragment>
  );
};
