import { prop, last } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import {
  selectHasRebranding,
  selectPatientPermissions,
} from 'src/core/permissions/permissions.selectors';

import { isNotEmpty } from 'src/utils/validation-helpers';

const selectModals = prop('modals');

const selectIsOpen = createSelector(selectModals, isNotEmpty);

const selectCurrentModal = createSelector(selectModals, last);

const selectCurrentModalType = createSelector(selectCurrentModal, prop('key'));
const selectCurrentModalData = createSelector(selectCurrentModal, prop('data'));

export const selectModalProps = createStructuredSelector({
  data: selectCurrentModalData,
  isOpen: selectIsOpen,
  token: selectAccessToken,
  type: selectCurrentModalType,
  rebranding: selectHasRebranding,
  patientPermissions: selectPatientPermissions,
});
