/* eslint-disable */
import { Settings as LuxonSettings } from 'luxon';
import { Observable } from 'rxjs/Rx';
import { SessionActionType } from 'src/app/session/core/session/session.types';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import {
  selectGigyaToken,
  selectUID,
} from 'src/app/session/core/config/config.selectors';
import { selectIsSignedOut } from 'src/app/session/core/session/session.selectors';
import i18n from 'src/i18n';

import { selectEC6UserLanguage } from './user.selectors';
import {
  getCurrentUser,
  onChangeLocale,
  onChangeLocaleError,
} from './user.actions';
import { GET_CURRENT_USER } from './user.constants';
/* eslint-enable */

export const changeLanguageEpic = () => {
  const currentLocale = null;
  return (action$, store$) =>
    action$.ofType(GET_CURRENT_USER.SUCCESS).flatMap(() => {
      const languageIsoCode = selectEC6UserLanguage(store$.getState());
      if (languageIsoCode && languageIsoCode !== currentLocale) {
        i18n.changeLanguage(languageIsoCode);
        LuxonSettings.defaultLocale = languageIsoCode;
        return [onChangeLocale(languageIsoCode)];
      }
      return [onChangeLocaleError()];
    });
};

export const userEpic = (currentUserService) => (action$, state$) =>
  action$
    .ofType(GET_CURRENT_USER.START, SessionActionType.VALIDATE_SESSION_SUCCESS)
    .debounceTime(1000)
    .flatMap(() => {
      const accessToken = selectAccessToken(state$.getState());
      const gigyaToken = selectGigyaToken(state$.getState());
      return Observable.create((ob) =>
        currentUserService(accessToken, gigyaToken)
          .then((user) => {
            const languageIsoCode = selectEC6UserLanguage(state$.getState());
            LuxonSettings.defaultLocale = languageIsoCode;
            window.user_id = selectUID(state$.getState());
            return ob.next(getCurrentUser.success({ user }));
          })
          .catch((errResponse) => {
            if (!selectIsSignedOut(state$.getState())) {
              return ob.next(
                getCurrentUser.error({
                  error: true,
                  message: JSON.stringify(errResponse),
                }),
              );
            }
          }),
      );
    });
