import React from 'react';
import { withTheme } from 'styled-components';

import { HorizontalAxis } from 'src/lib/graph-horizontal-axis.component';
import { colors } from 'src/core/styles/colors';
import { strokeWidth } from 'src/core/styles/stroke-width';

import { X_AXIS_WIDTH } from '../../standard-week-detail.constant';

const Tick = ({ tick, totalTicks, height, width, theme }) => {
  const adjustedWidth = width * X_AXIS_WIDTH;

  return (
    <React.Fragment>
      <line
        x1={tick.value}
        y1={0}
        x2={tick.value}
        y2={height * 0.1}
        strokeWidth={strokeWidth.one}
        stroke={colors.grayLight}
      />
      <text
        textAnchor="middle"
        x={tick.value + ((1 / totalTicks) * adjustedWidth) / 2}
        y={height * 0.35}
        fontSize={theme.fontSize.label}
      >
        {tick.label}
      </text>
    </React.Fragment>
  );
};

const WeekAxisComponent = ({
  x = 0,
  width,
  height,

  yDirection,
  ticks,
  theme,
}) => {
  const totalTicks = ticks.length;
  return (
    <HorizontalAxis
      x={x}
      y={height}
      width={width}
      height={height}
      Tick={(tick) => (
        <Tick
          tick={tick}
          height={height}
          width={width}
          totalTicks={totalTicks}
          key={tick.value}
          yDirection={yDirection}
          theme={theme}
        />
      )}
      ticks={ticks}
      axisColor={colors.grayLight}
    />
  );
};

export const WeekAxis = withTheme(WeekAxisComponent);
