import styled from 'styled-components';
import { flex } from 'styled-system';

import { spacing } from 'src/core/styles/spacing';
import { colors } from 'src/core/styles/colors';
import { weight } from 'src/domains/diagnostics/components/fonts/weights/weight.style';
import { breakpoints } from 'src/core/styles/breakpoints';

export const DetailBlockContainerDiv = styled.div`
  ${flex};
  display: flex;
  align-items: flex-start;
  margin: 0 16px 0 0;
  min-width: 65px;
  max-width: 115px;
  &:last-of-type {
    margin-right: 0;
  }
  div.ToolTipNoDataContainer {
    margin-left: -121px;
    white-space: initial;
  }
  span.no-data-tool-tip-text {
    font-size: 16px;
    color: ${colors.grayNoData};
  }
`;

export const PrimaryTitleHeader = styled.h4`
  font-size: 10px;
  color: ${colors.charcoal};
  font-weight: ${weight.regular};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 22px;
  margin-top: 0;
  margin-bottom: 4px;
`;

export const SecondaryTitleHeader = styled.h4`
  font-size: 12px;
  color: ${colors.charcoal};
  font-weight: ${weight.regular};
  margin-top: 4px;
  margin-bottom: 8px;
`;

export const CircleDiv = styled.div`
  height: 10px;
  min-width: 10px;
  border-radius: ${spacing.two};
  background-color: ${(props) => props.color};
  margin-right: ${spacing.two};
`;
CircleDiv.displayName = 'CircleDiv';

export const ValueContainerDiv = styled.div`
  flex: 1 1 auto;
  flex-direction: row;
  display: flex;
  border-bottom: 1px solid ${colors.grayLighter};
  align-items: center;
  white-space: nowrap;
  padding: 0 0 5px;
`;
ValueContainerDiv.displayName = 'ValueContainerDiv';

export const ValueDisplay = styled.div`
  font-size: 18px;
  @media (max-width: ${breakpoints.changeMedium}) {
    font-size: 15px;
  }
  color: ${colors.charcoal};
`;
