import { getConfig } from '@roche/roche-common';

const manufactureDateModules = {
  'rdcp-hcp-vc-client-module': getConfig().REACT_APP_VC_MANUFACTURE_DATE
    ? getConfig().REACT_APP_VC_MANUFACTURE_DATE
    : null,
};

export const generalInfoModuleDataToShow = (
  moduleDataList,
  selectedManifestModuleName,
) => {
  return {
    name: moduleDataList[selectedManifestModuleName].extra[
      'product-information'
    ].name,
    version: trimVersionNumberToOnlyMajorMinor(
      moduleDataList[selectedManifestModuleName].version,
    ),
    publishedDate: manufactureDateModules[selectedManifestModuleName]
      ? manufactureDateModules[selectedManifestModuleName]
      : moduleDataList[selectedManifestModuleName].extra['product-information']
          .publishedDate,
  };
};

export const trimVersionNumberToOnlyMajorMinor = (versionNumber) =>
  versionNumber.match(/(\d+.\d+.\d+)/)[0];
