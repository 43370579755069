export const colors = {
  transparentBlack: 'rgba(0, 0, 0, .6)',
  pureBlack: 'rgb(0, 0, 0)',
  titleBlack: 'rgb(51,51,51)',
  black: 'rgb(74, 74, 74)',
  mediumGray: 'rgb(116, 116, 116)',
  anotherGray: 'rgb(150, 150, 150)',
  gray: 'rgb(155, 155, 155)',
  lightGray: 'rgb(216, 216, 216)',
  titleGray: 'rgb(238, 240, 243)',
  indicatorGray: 'rgb(241, 238, 253)',
  visitModuleBackgroundGray: 'rgb(247, 247, 247)',
  white: 'rgb(255, 255, 255)',
  lightBlue: 'rgb(231, 238, 250)',
  bgBlue: 'rgb(225,233,249)',
  disabledGray: 'rgb(196, 207, 226)',
  pastelBlue: 'rgb(187, 209, 235)',
  disabledLightGray: 'rgb(234,235,236)',
  disabledDarkGray: 'rgb(143, 160, 188)',
  disabledDarkerGray: 'rgb(101,98,98)',
  indicatorBlue: 'rgb(5,140,207)',
  mainBlue: 'rgb(0, 102, 204)',
  mediumBlue: 'rgb(0, 62, 125)',
  red: 'rgb(207, 2, 27)',
  warning: 'rgb(255, 123, 123)',
};

type ValueOf<T> = T[keyof T];
export type ColorsType = ValueOf<typeof colors>;
