import * as React from 'react';
import { compose, lifecycle } from 'recompose';

export enum TopNavigationHeight {
  SHORT = '60',
  REGULAR = '67',
  INTERMEDIATE = '84',
  TALL = '100',
}

const lifecycleWithClass = (className: string) =>
  lifecycle<{}, {}>({
    componentWillMount() {
      document.body.classList.add(className);
    },
    componentWillUnmount() {
      document.body.classList.remove(className);
    },
  });

export const withNoNav = lifecycleWithClass('no-nav');

export const withNoMargins = lifecycleWithClass('no-m');

export const withBgImage = lifecycleWithClass('bg-image');

export const withRegularNavHeight = lifecycleWithClass(
  `_${TopNavigationHeight.REGULAR}`,
);

export const withIntermediateNavHeight = compose(
  lifecycleWithClass(`_${TopNavigationHeight.INTERMEDIATE}`),
);

export const withBgColor = (color: string) =>
  lifecycle<{}, {}>({
    componentWillMount() {
      document.body.style.backgroundColor = color;
    },
    componentWillUnmount() {
      document.body.style.backgroundColor = '';
    },
  });

const ProjectChildren = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
);

export const WithNoNav = compose(withNoNav)(ProjectChildren);

export const WithNoMargins = compose(withNoMargins)(ProjectChildren);

export const WithIntermediateNavHeight = compose(withIntermediateNavHeight)(
  ProjectChildren,
);
