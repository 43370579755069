import { pipe } from 'ramda';
import * as React from 'react';
import { compose, withProps } from 'recompose';

export const POINTER_EVENTS = {
  AUTO: 'auto',
  NONE: 'none',
};

const getPointerEvents = (opacity: number) =>
  opacity === 1 ? POINTER_EVENTS.AUTO : POINTER_EVENTS.NONE;

export const getPointerEventsStyle = pipe(
  getPointerEvents,
  (pointerEvents: typeof POINTER_EVENTS[keyof typeof POINTER_EVENTS]) => ({
    pointerEvents,
  }),
);

interface PointShapeProps {
  [key: string]: any;
  opacity: number;
}

export const withHoverableStyle = compose(
  (Component): React.FunctionComponent<PointShapeProps> =>
    ({ style, ...props }) =>
      (
        <g {...style}>
          <Component {...props} className="no-user-select" />
        </g>
      ),
  withProps((ownerProps: PointShapeProps) => ({
    ...ownerProps,
    style: ownerProps.opacity ? getPointerEventsStyle(ownerProps.opacity) : {},
  })),
);
