// tslint:disable
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { isEmpty, propEq, pathOr } from 'ramda';
import {
  actions as reactReduxFormActions,
  ModelAction,
} from 'react-redux-form';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fetchConfigurablesSuccess } from 'src/core/configurables/configurables.actions';
import { getGrantedConfigurables } from 'src/core/configurables/configurables.utils';
import {
  editEMRPatientError,
  editEMRPatientSuccess,
  fetchPatientForEditError,
  fetchPatientForEditStart,
} from 'src/core/edit-patient/edit-patient.actions';
import { fetchPatientRequest } from 'src/core/patient/patient.action';
import { FETCH_PATIENT_REQUEST } from 'src/core/patient/patient.constant';
import { fetchPatientPermissions } from 'src/core/permissions/permissions.actions';
import {
  EditPatientServiceImplType,
  TransformedEditPatientSuccessResponse,
} from 'src/services/patient/edit-patient/edit-patient.types';
import {
  EditPatientWithFhirServiceImplType,
  TransformedEditPatientWithFhirSuccessResponse,
} from 'src/services/patient/edit-patient-with-fhir/edit-patient-with-fhir.types';
import {
  EditEMRPatientServiceImplType,
  TransformedEditEMRPatientSuccessResponse,
} from 'src/services/patient/edit-emr-patient/edit-emr-patient.types';
import { FetchConfigurablesLoaderType } from 'src/services/configurables/configurables.types';
import { GetPatientInfoServiceImplType } from 'src/services/patient/patient-info/patient-info.types';

import { PatientFormModel } from '../../widgets/patient/forms/forms.types';
import { setCurrentFormsProp } from '../current-forms/current-forms.actions';
import {
  DepartmentActionType,
  ProfileTypes,
} from '../department/department.types';

import {
  editPatientError,
  editPatientSuccess,
  fetchPatientForEditSuccess,
  NoAssociatedProfessionals,
} from './edit-patient.actions';
import {
  editPatientWithFhirSuccess,
  editPatientWithFhirError,
} from 'src/widgets/patient/create-patient/create-patient-with-platform/store/edit-patient-with-fhir/edit-patient-with-fhir.actions';
import { EDIT_PATIENT_FORM_DATA_MODEL } from './edit-patient.constants';
import {
  EditPatientActions,
  EditPatientActionType,
  fetchPatientForEditStartAction,
  NoAssociatedProfessionalsAction,
} from './edit-patient.types';
import { EditPatientWithFhirActionType } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/edit-patient-with-fhir/edit-patient-with-fhir.types';

import { addAlertAction } from 'src/core/alert/alert.actions';
import { AlertType } from 'src/core/alert/alert.types';
import { ALERT_POSITION } from 'src/core/alert/alert.constans';

export const fetchPatientForEditEpic: (
  getPatientInfoService: GetPatientInfoServiceImplType,
  fetchConfigurablesService: FetchConfigurablesLoaderType,
  withTranslation,
) => Epic<EditPatientActions, any> =
  (getPatientInfoService, fetchConfigurablesService) => (action$, store) =>
    action$
      .ofType(EditPatientActionType.FETCH_PATIENT_FOR_EDIT_START)
      .switchMap(({ payload }: fetchPatientForEditStartAction) => {
        const openId = {
          accessToken: selectAccessToken(store.getState()),
          gigyaToken: selectGigyaToken(store.getState()),
        };
        const editablePatientInfo = Observable.fromPromise(
          getPatientInfoService(payload, openId),
        );
        const configurablePermissions = Observable.fromPromise(
          fetchConfigurablesService(openId),
        );
        return Observable.forkJoin(editablePatientInfo, configurablePermissions)
          .switchMap((data) => {
            const [EC6patient, configurables] = data;
            const grantedPermissions = getGrantedConfigurables(
              configurables,
              (EC6patient as any).revokedPermissions,
            );
            const profileType = pathOr(
              '',
              `${EDIT_PATIENT_FORM_DATA_MODEL}.profileType`.split('.'),
              store.getState(),
            );
            const editableEC6patient = {
              ...EC6patient,
              grantedPermissions,
              profileType,
            };
            return [
              fetchConfigurablesSuccess(configurables),
              reactReduxFormActions.load(
                EDIT_PATIENT_FORM_DATA_MODEL,
                editableEC6patient,
              ),
              setCurrentFormsProp({
                path: ['editPatient', 'patientInfo', 'email'],
                value: (EC6patient as any).patientInfo.email,
              }),
              fetchPatientForEditSuccess(EC6patient),
            ];
          })
          .pipe(
            catchError((err) => Observable.of(fetchPatientForEditError(err))),
          );
      });

export const fetchPatientPermissionsEpic: () => Epic<EditPatientActions, any> =
  () => (actions$, store) =>
    actions$.ofType(FETCH_PATIENT_REQUEST.SUCCESS).map(({ payload }) =>
      fetchPatientPermissions.start({
        patientId: payload.id,
      }),
    );

const filterAssociatedPatients = (allProfessionals, associatedProfessionals) =>
  associatedProfessionals
    .split(',')
    .filter((s) =>
      allProfessionals.some((professional) => professional.key === s),
    );

export const checkAssociatedProfessionalsExistOnEditPatientEpic: () => Epic<
  EditPatientActions,
  NoAssociatedProfessionalsAction
> = () => (actions$, store) =>
  actions$
    .ofType(EditPatientActionType.FETCH_PATIENT_FOR_EDIT_SUCCESS)
    .switchMap((editPatientAction) =>
      actions$
        .ofType(DepartmentActionType.GET_PROFESSIONALS_SUCCESS)
        .filter(({ payload }) =>
          isEmpty(
            filterAssociatedPatients(
              payload,
              editPatientAction.payload.healthInfo.associatedProfessional,
            ),
          ),
        )
        .map(() => NoAssociatedProfessionals()),
    );

export const updateProfileTypeOnFetchPatientSuccessEpic: () => Epic<
  EditPatientActions,
  any
> = () => (action$, store) =>
  action$
    .ofType(FETCH_PATIENT_REQUEST.SUCCESS)
    .flatMap(({ payload }) => [
      reactReduxFormActions.load(
        `${EDIT_PATIENT_FORM_DATA_MODEL}.profileType`,
        payload.profile,
      ),
      reactReduxFormActions.setPristine(EDIT_PATIENT_FORM_DATA_MODEL),
    ]);

export const updateAllowPatientAccessOnProfileEditEpic: () => Epic<
  ModelAction,
  ModelAction
> = () => (action$) =>
  action$
    .ofType('rrf/change')
    .filter(propEq('model', `${EDIT_PATIENT_FORM_DATA_MODEL}.profileType`))
    .filter(propEq('value', ProfileTypes.homeDelivery))
    .mapTo(
      reactReduxFormActions.change(
        `${EDIT_PATIENT_FORM_DATA_MODEL}${PatientFormModel.patientInfo.base}${PatientFormModel.patientInfo.allowPatientAccess}`,
        true,
      ),
    );

export const editPatientEpic =
  (
    editPatientService: EditPatientServiceImplType,
  ): Epic<EditPatientActions, any> =>
  (action$, store) =>
    action$
      .ofType(EditPatientActionType.EDIT_PATIENT_START)
      .switchMap((action) => {
        return Observable.fromPromise(
          editPatientService(
            action.payload,
            selectAccessToken(store.getState()),
            selectGigyaToken(store.getState()),
          ),
        )
          .switchMap((data: TransformedEditPatientSuccessResponse) => {
            return [
              editPatientSuccess(data),
              fetchPatientForEditStart(data),
              fetchPatientRequest.start(data),
            ];
          })
          .pipe(catchError((err) => Observable.of(editPatientError(err))));
      });

export const editPatientWithFhirEpic =
  (
    editPatientWithFhirService: EditPatientWithFhirServiceImplType,
  ): Epic<EditPatientActions, any> =>
  (action$, store$) =>
    action$
      .ofType(EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_START)
      .flatMap(({ payload }) => {
        const accessToken = selectAccessToken(store$.getState());
        const gigyaToken = selectGigyaToken(store$.getState());
        return Observable.fromPromise(
          editPatientWithFhirService(payload, accessToken, gigyaToken),
        )
          .switchMap((data: TransformedEditPatientWithFhirSuccessResponse) => {
            return [
              editPatientWithFhirSuccess(data.status),
              addAlertAction({
                type: AlertType.SUCCESS,
                text: {
                  [AlertType.SUCCESS]: 'editPatient.alertEditSuccess.title',
                },
                position: ALERT_POSITION.BOTTOM_RIGHT,
              }),
              fetchPatientRequest.start({ patientId: payload.patientId }),
            ];
          })
          .pipe(
            catchError((err: Error) =>
              Observable.of(editPatientWithFhirError(err.message)),
            ),
          );
      });

export const editEMRPatientEpic =
  (
    editPatientEMRService: EditEMRPatientServiceImplType,
  ): Epic<EditPatientActions, any> =>
  (action$, store) =>
    action$
      .ofType(EditPatientActionType.EDIT_EMR_PATIENT_START)
      .switchMap((action) => {
        return Observable.fromPromise(
          editPatientEMRService(
            action.payload,
            selectAccessToken(store.getState()),
            selectGigyaToken(store.getState()),
          ),
        )
          .switchMap((data: TransformedEditEMRPatientSuccessResponse) => {
            return [
              editEMRPatientSuccess(data),
              fetchPatientForEditStart(data),
              fetchPatientRequest.start(data),
            ];
          })
          .pipe(catchError((err) => Observable.of(editEMRPatientError(err))));
      });
