import { createPayloadAction, createAction } from 'src/app/store/app.actions';

import {
  ConnectSharingCodeActionType,
  ConnectSharingCodeStartAction,
  ConnectSharingCodeSuccessAction,
  ConnectSharingCodeErrorAction,
  ConnectSharingCodeSuccessPayload,
  ConnectSharingCodeError,
  CheckSharingCodeAvailableStartAction,
  CheckSharingCodeAvailableResponse,
  CheckSharingCodeAvailableSuccessAction,
  CheckSharingCodeAvailableErrorAction,
  ClearsharingCodeDataAction,
} from './connect-sharing-code.actions.types';

export const connectSharingCodeStart = (
  payload: string,
): ConnectSharingCodeStartAction =>
  createPayloadAction(
    ConnectSharingCodeActionType.CONNECT_SHARING_CODE_START,
    payload,
  );

export const connectSharingCodeSuccess = (
  payload: ConnectSharingCodeSuccessPayload,
): ConnectSharingCodeSuccessAction =>
  createPayloadAction(
    ConnectSharingCodeActionType.CONNECT_SHARING_CODE_SUCCESS,
    payload,
  );

export const connectSharingCodeError = (
  payload: ConnectSharingCodeError,
): ConnectSharingCodeErrorAction => ({
  type: ConnectSharingCodeActionType.CONNECT_SHARING_CODE_ERROR,
  payload,
});

export const checkSharingCodeAvailableStart =
  (): CheckSharingCodeAvailableStartAction =>
    createAction(
      ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_START,
    );

export const checkSharingCodeAvailableSuccess = (
  payload: CheckSharingCodeAvailableResponse,
): CheckSharingCodeAvailableSuccessAction => ({
  type: ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_SUCCESS,
  payload,
});

export const checkSharingCodeAvailableError = (
  payload: CheckSharingCodeAvailableResponse,
): CheckSharingCodeAvailableErrorAction => ({
  type: ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_ERROR,
  payload,
});

export const clearsharingCodeDataAction = (): ClearsharingCodeDataAction =>
  createAction(ConnectSharingCodeActionType.CLEAR_SHARING_CODE_DATA);
