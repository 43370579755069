import {
  createRequestActionTypes,
  createRequestActions,
} from 'src/core/request/request.actions';
import { createAction } from 'src/app/store/app.actions';

import {
  INSIdentityActionType,
  INSIdentityClearAction,
} from './INS-identity.types';

export const INS_IDENTITY_GET_REQUEST = createRequestActionTypes(
  INSIdentityActionType.INS_IDENTITY_GET,
);

export const getINSIdentity = createRequestActions(INS_IDENTITY_GET_REQUEST);

export const clearINSIdentity = (): INSIdentityClearAction =>
  createAction(INSIdentityActionType.INS_IDENTITY_CLEAR);
