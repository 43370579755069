import React from 'react';

import { translate } from 'src/i18n';

import { SvgIcon } from 'src/components/icon/icon.component';

export const CloudIconBlueComponent = ({
  height,
  title = null,
  iconColor = '',
}) => {
  const originalWidth = 27;
  const originalHeight = 20;
  const aspectRatio = originalWidth / originalHeight;
  return (
    <SvgIcon
      title={translate(title)}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g
          id="Remote-Access"
          transform="translate(-8.000000, 1.000000)"
          fill="#A3C9EE"
          fillRule="nonzero"
          stroke="#7EAADD"
        >
          <path
            d="M18.7327189,0 C21.5137964,0 23.8952951,1.67226832 24.8822149,4.04421456 C25.4686868,3.67397313 26.1672404,3.45945946 26.9170507,3.45945946 C28.993845,3.45945946 30.6774194,5.10511551 30.6774194,7.13513514 C30.6774194,7.2733419 30.6696158,7.40976711 30.654416,7.54401274 C32.4607452,8.08588348 33.7741935,9.73084808 33.7741935,11.6756757 C33.7741935,13.9180708 32.0280697,15.7618222 29.7923801,15.9786725 L29.7926267,16 L22.377,16 L22.3771935,11.6734839 L25.7741935,11.6737955 L20.8709677,5.93548387 L15.9677419,11.6737955 L19.2571935,11.6734839 L19.256,16 L13.4239631,16 C10.9806758,16 9,14.0639341 9,11.6756757 C9,9.70887466 10.3432957,8.04875086 12.1820205,7.52407467 L12.1626724,7.40417236 C12.1192393,7.10438157 12.0967742,6.79799845 12.0967742,6.48648649 C12.0967742,2.90409892 15.0677878,0 18.7327189,0 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
};

export const CloudIconBlue = CloudIconBlueComponent;
