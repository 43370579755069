import React from 'react';

import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { InsulinPumpLegend } from 'src/components/graph-legend/components/insulin-pump-legend.component';

import { DetailGraphLegend } from 'src/domains/diagnostics/components/graph-legend/components/detail-graph-legend.component';
import { LogbookGraphLegend } from 'src/domains/diagnostics/components/graph-legend/components/logbook-graph-legend.component';
import { LogbookStatsGraphLegend } from 'src/domains/diagnostics/components/graph-legend/components/logbook-stats-graph-legend.component';
import { TrendGraphLegend } from 'src/domains/diagnostics/components/graph-legend/components/trend-graph-legend.component';
import { MetabolicGraphLegend } from 'src/domains/diagnostics/components/graph-legend/components/metabolic-graph-legend.component';
import { DeviceSettingsLegend } from 'src/domains/diagnostics/components/graph-legend/components/device-settings-legend.component';
import { CgmDailyGraphLegend } from 'src/domains/diagnostics/components/graph-legend/components/cgm-daily-legend.component';
import { Container, Title } from './graph-legend.style';
import { GRAPH_LEGEND_TYPES } from './graph-legend.constant';

const graphTypeToLegendMap = (graphType, showChangeGraphToggle, graph) => {
  const graphTypesMap = {
    [GRAPH_LEGEND_TYPES.TREND_DETAIL]: (
      <DetailGraphLegend
        graph={graph}
        graphType={GRAPH_LEGEND_TYPES.TREND_DETAIL}
        expandedGraph={showChangeGraphToggle}
      />
    ),
    [GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL]: (
      <DetailGraphLegend
        graph={graph}
        graphType={GRAPH_LEGEND_TYPES.STANDARD_DAY_DETAIL}
        expandedGraph={showChangeGraphToggle}
      />
    ),
    [GRAPH_LEGEND_TYPES.STANDARD_WEEK_DETAIL]: (
      <DetailGraphLegend
        graph={graph}
        graphType={GRAPH_LEGEND_TYPES.STANDARD_WEEK_DETAIL}
        expandedGraph={showChangeGraphToggle}
      />
    ),
    [GRAPH_LEGEND_TYPES.LOGBOOK]: <LogbookGraphLegend />,
    [GRAPH_LEGEND_TYPES.DEVICE_SETTINGS]: <DeviceSettingsLegend />,
    [GRAPH_LEGEND_TYPES.LOGBOOK_STATS]: <LogbookStatsGraphLegend />,
    [GRAPH_LEGEND_TYPES.LOGBOOK_24HR]: (
      <LogbookGraphLegend
        graph={graph}
        graphType={GRAPH_LEGEND_TYPES.LOGBOOK_24HR}
      />
    ),
    [GRAPH_LEGEND_TYPES.TREND]: (
      <TrendGraphLegend
        graph={graph}
        graphType={GRAPH_LEGEND_TYPES.METABOLIC}
        expandedGraph={showChangeGraphToggle}
      />
    ),
    [GRAPH_LEGEND_TYPES.METABOLIC]: (
      <MetabolicGraphLegend
        graph={graph}
        graphType={GRAPH_LEGEND_TYPES.METABOLIC}
        expandedGraph={showChangeGraphToggle}
      />
    ),
    [GRAPH_LEGEND_TYPES.INSULIN_PUMP]: <InsulinPumpLegend />,
    [GRAPH_LEGEND_TYPES.CGM_DAILY_GLUCOSE]: <CgmDailyGraphLegend />,
  };
  return graphTypesMap[graphType];
};

export const GraphLegend = ({ graphType, showChangeGraphToggle, graph }) => (
  <Container graphType>
    <Title graphType={graphType}>
      <LocalizedText textKey="graphDetails.legend.legend" />
    </Title>
    {graphTypeToLegendMap(graphType, showChangeGraphToggle, graph)}
  </Container>
);
