import {
  PostDeliveryConfigurationLoaderImpl,
  PostDeliveryConfigurationServiceImpl,
  PostDeliveryConfigurationTransformImpl,
} from './post-delivery-configuration.service';

export const PostDeliveryConfigurationFactoryImpl = ({ devMode }) => {
  return PostDeliveryConfigurationServiceImpl(
    PostDeliveryConfigurationLoaderImpl,
    PostDeliveryConfigurationTransformImpl,
  );
};
