import * as React from 'react';
import {
  DoneButtonWrapper,
  DoneButton,
  NavButtonContainer,
} from './calendar.style';
import { ArrowIcon } from 'src/assets/icons';
import { DateRangePicker } from 'react-dates';
import { noMeasurements } from './calendar.utils';
import { t } from 'i18next';
import * as C from '../../calendar.constants';
import { JelloIconButton } from 'src/components/jello-icon-button/jello-icon-button.component';
import { JelloIcon } from 'src/components/jello-icon/jello-icon.component';
import styles from './styles.module.css';
import {
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
} from 'src/app/app.jello.constants';

const toBooleanAttribute = (value) => (value ? true : undefined);

export const CalendarRenderJello = ({
  startDate,
  endDate,
  focusedInput,
  dateInfo,
  disabled,
  showArrows,
  className,
  id,
  isCgmActive,
  cgmSelectedDate,
  onChangePeriod,
  shoWCalendar,
  getDate,
  handleDatesChange,
  handleFocusChange,
  onChangeDates,
  onHideCalendar,
}) => {
  const drawButton = (way) => (
    <button
      onClick={() => onChangePeriod(way)}
      className={styles.calendarNavBtn}
      disabled={toBooleanAttribute(disabled)}
    >
      {way === C.PREV && (
        <JelloIconButton
          size={JELLO_ICON_SIZES.M}
          iconName={JELLO_ICON_NAMES.CHEVRON_LEFT}
          variant="secondary"
          disabled={toBooleanAttribute(disabled)}
        />
      )}
      {way === C.NEXT && (
        <JelloIconButton
          size={JELLO_ICON_SIZES.M}
          iconName={JELLO_ICON_NAMES.CHEVRON_RIGHT}
          variant="secondary"
          disabled={toBooleanAttribute(disabled)}
        />
      )}
    </button>
  );

  const drawNavButton = (way) => {
    return (
      <NavButtonContainer className={`navBtn${way}`}>
        <ArrowIcon height={8} />
      </NavButtonContainer>
    );
  };

  const renderPresets = (handleClick) => {
    return (
      <DoneButtonWrapper>
        <DoneButton onClick={handleClick}>{t('datePicker.done')}</DoneButton>
      </DoneButtonWrapper>
    );
  };

  const pseudoCalendarButton = () => {
    return (
      <div
        className={
          disabled
            ? `${styles.calendarComponent} ${styles.calendarComponentDisabled}`
            : styles.calendarComponent
        }
      >
        <button
          disabled={toBooleanAttribute(disabled)}
          onClick={() => shoWCalendar()}
          className={`${styles.calendarIconBtn} ${disabled ? 'disabled' : ''}`}
        >
          <JelloIcon
            size={JELLO_ICON_SIZES.XS}
            iconName={JELLO_ICON_NAMES.CALENDAR}
            color={
              disabled
                ? 'var(--jello-color-foreground-interactive-disabled)'
                : 'var(--jello-color-foreground-highlight-base)'
            }
          />
        </button>
        <DateRangePicker
          id={id}
          customArrowIcon={
            <JelloIcon
              size={JELLO_ICON_SIZES.XS}
              iconName={JELLO_ICON_NAMES.SUBTRACT}
              color={
                disabled
                  ? 'var(--jello-color-foreground-interactive-disabled)'
                  : 'var(--jello-color-foreground-highlight-base)'
              }
            />
          }
          minimumNights={0}
          startDatePlaceholderText={C.NO_DATA}
          endDatePlaceholderText={C.NO_DATA}
          keepOpenOnDateSelect={true}
          horizontalMargin={C.HORIZONAL_MARGIN}
          daySize={C.DAY_SIZE}
          displayFormat={C.DATE_FORMAT}
          readOnly={true}
          noBorder={true}
          navPrev={drawNavButton(C.PREV)}
          navNext={drawNavButton(C.NEXT)}
          disabled={disabled}
          startDate={getDate(disabled, startDate, isCgmActive, cgmSelectedDate)}
          startDateId={C.START_DATE_ID}
          endDate={getDate(disabled, endDate, isCgmActive, cgmSelectedDate)}
          endDateId={C.END_DATE_ID}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={handleFocusChange}
          renderCalendarInfo={() =>
            renderPresets(() => {
              onChangeDates();
              onHideCalendar();
            })
          }
          isOutsideRange={(d) =>
            noMeasurements(
              dateInfo.firstMeasurementDate,
              dateInfo.lastMeasurementDate,
              d,
            )
          }
        />
      </div>
    );
  };

  const wrapperClasses = `${styles.pseudoCalendarButtonWrapper} ${
    className || ''
  }`;

  return (
    <div className={wrapperClasses}>
      {pseudoCalendarButton()}

      {showArrows ? drawButton(C.PREV) : ''}
      {showArrows ? drawButton(C.NEXT) : ''}
    </div>
  );
};
