import { History } from 'history';
import { match } from 'react-router';
import {
  FetchDevicesStartAction,
  PatientDevice,
  RemoveDeviceStartAction,
} from 'src/core/list-devices/list-devices.types';

export type DeviceListProps = {
  createModal: ({ key, data }) => void;
  destroyModal: () => void;
  match: match<{ id: string }>;
  history: History;
  fetchDevices: (patientId: number) => FetchDevicesStartAction;
  removeDevice: ({ deviceId, patientId }) => RemoveDeviceStartAction;
  patientId: number;
  patientDevices: PatientDevice[];
  readyToRender: boolean;
  isFetching: boolean;
  hasFailed: boolean;
  isJelloActivated?: boolean;
};

export type DeviceListColumn = {
  title: string;
  sort: string;
  sortFn: (any) => any;
  customComponentName?: any;
};

export enum SortDirection {
  ASC = 'ASC',
  DSC = 'DSC',
}

export enum DevicesTypes {
  Normal = 'ICO_4-51_normal',
  Meter = 'ICO_3-18_meter',
  Meterpump = 'ICO_3-21_meterpump',
  Cgm = 'ICO_3-21_cgm',
  NoDevice = '',
}

export type DevicesListState = {
  sort: string;
  sortDir: SortDirection;
  sortFn: (data: PatientDevice[]) => PatientDevice[];
};
