import { createPayloadAction } from 'src/app/store/app.actions';
import { SEARCH_PATIENTS_REQUEST } from './patient-search.constants';
import {
  PatientSearchActionType,
  SetPatientSearchResultsPageAction,
} from './patient-search.types';

export const search = ({ patientID = '', fullName = '' }) => ({
  type: SEARCH_PATIENTS_REQUEST.START,
  payload: {
    patientID,
    fullName,
  },
});

export const setPatientSearchResultsPage = ({
  page,
}: {
  page: number;
}): SetPatientSearchResultsPageAction =>
  createPayloadAction(PatientSearchActionType.SET_PATIENT_SEARCH_RESULTS_PAGE, {
    page,
  });
