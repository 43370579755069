import {
  EditEMRProfileLoaderImpl,
  EditEMRProfileServiceImpl,
  EditEMRProfileTransformImpl,
} from './edit-emr-profile.service';

import { mockEditEMRProfileResponse } from './edit-emr-profile.mock';

const mockPoster = () => Promise.resolve(mockEditEMRProfileResponse);

export const EditEMRProfileFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPoster : EditEMRProfileLoaderImpl;
  return EditEMRProfileServiceImpl(loader, EditEMRProfileTransformImpl);
};
