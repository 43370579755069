import {
  DeliveryConfigurationActions,
  DeliveryConfigurationActionType,
  DeliveryConfigurationState,
} from './delivery-configuration.types';

export const DELIVERY_CONFIGURATION_INITIAL_STATE: DeliveryConfigurationState =
  {
    isFetchingDeliveryConfiguration: false,
    isFetchingDeliveryConfigurationError: false,
    isEditingDeliveryConfiguration: false,
    isSavingDeliveryConfiguration: false,
    isSavingDeliveryConfigurationError: false,
    deliveryConfigurationSaved: false,
    patientProgram: null,
    configurations: [],
  };

export const deliveryConfigurationReducer = (
  state = DELIVERY_CONFIGURATION_INITIAL_STATE,
  action: DeliveryConfigurationActions,
): DeliveryConfigurationState => {
  switch (action.type) {
    case DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_START:
      return {
        ...DELIVERY_CONFIGURATION_INITIAL_STATE,
        isFetchingDeliveryConfiguration: true,
      };
    case DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configurations: action.payload,
        isFetchingDeliveryConfiguration: false,
      };
    case DeliveryConfigurationActionType.GET_PATIENT_PROGRAM_SUCCESS:
      return {
        ...state,
        patientProgram: action.payload,
      };
    case DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_ERROR:
      return {
        ...state,
        isFetchingDeliveryConfigurationError: true,
      };
    case DeliveryConfigurationActionType.SAVE_DELIVERY_CONFIGURATION_START:
      return {
        ...state,
        isSavingDeliveryConfiguration: true,
      };
    default:
      return state;
  }
};
