import { compose } from 'recompose';
import { connect } from 'react-redux';
import { TabReminderComponent } from './tab-reminders.component';
import { deviceSettingsReminderConnector } from './tab-reminders.selector';
import { withTranslation } from 'src/utils/i18n/with-translation';

export const TabReminderContainer = compose<any, any>(
  withTranslation,
  connect(deviceSettingsReminderConnector, null),
)(TabReminderComponent);
