import {
  CreatePatientWithFhirActions,
  CreatePatientWithFhirActionType,
  CreatePatientWithFhirState,
} from './create-patient-with-fhir.types';

export const initialCreatePatientState: CreatePatientWithFhirState = {
  creationHasError: false,
  creationWasSuccessful: false,
  isCreatingPatient: false,
};

export const createPatientWithFhirReducer = (
  state = initialCreatePatientState,
  action: CreatePatientWithFhirActions,
): CreatePatientWithFhirState => {
  switch (action.type) {
    case CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_START:
      return {
        isCreatingPatient: true,
        creationWasSuccessful: false,
        creationHasError: false,
      };
    case CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_SUCCESS:
      return {
        isCreatingPatient: false,
        creationWasSuccessful: true,
        creationHasError: false,
      };
    case CreatePatientWithFhirActionType.CREATE_PATIENT_WITH_FHIR_ERROR:
      return {
        isCreatingPatient: false,
        creationWasSuccessful: false,
        creationHasError: true,
      };
    case CreatePatientWithFhirActionType.CLEAR_CREATE_PATIENT_WITH_FHIR_STATE:
      const resetState = initialCreatePatientState;
      return resetState;
    default:
      return state;
  }
};
