import { compose } from 'ramda';

import {
  subtractDays,
  toISO,
  toStartOfDay,
  toEndOfDay,
  convertISOGMT,
  convertJSDateGMT,
  convertStringToJSDate,
  isDateStringValid,
} from 'src/utils/date';

import {
  FETCH_PATIENT_DATE_RANGE_REQUEST,
  PATIENT_DATE_ACTIONS,
} from './patient-date-range.constant';

export const INITIAL_PATIENT_DATE_RANGE_STATE = {
  firstMeasurementDate: null,
  lastMeasurementDate: null,
  startDate: null,
  endDate: null,
  range: 14,
};

const returnNullIfInvalidDateString = (dateString) =>
  isDateStringValid(dateString) ? convertStringToJSDate(dateString) : null;

const validateAndTransformDates = (dates) =>
  dates.map(returnNullIfInvalidDateString);

export const patientDateRangeReducer = (
  state = INITIAL_PATIENT_DATE_RANGE_STATE,
  action = {},
) => {
  switch (action.type) {
    case FETCH_PATIENT_DATE_RANGE_REQUEST.SUCCESS: {
      const { firstMeasurement, latestMeasurement } = action.payload;

      const [lastMeasurementDate, firstMeasurementDate] =
        validateAndTransformDates([latestMeasurement, firstMeasurement]);

      const startDate = compose(
        toISO,
        toStartOfDay,
        subtractDays(13),
        convertJSDateGMT,
      )(lastMeasurementDate || new Date());

      const endDate = compose(
        toISO,
        toEndOfDay,
        convertJSDateGMT,
      )(lastMeasurementDate || new Date());

      return {
        ...state,
        firstMeasurementDate: compose(
          toISO,
          convertJSDateGMT,
        )(firstMeasurementDate),
        lastMeasurementDate: compose(
          toISO,
          convertJSDateGMT,
        )(lastMeasurementDate),
        startDate,
        endDate,
      };
    }
    case PATIENT_DATE_ACTIONS.CLEAR: {
      return INITIAL_PATIENT_DATE_RANGE_STATE;
    }
    case PATIENT_DATE_ACTIONS.SET_DATES: {
      return {
        ...state,
        startDate: compose(
          toISO,
          toStartOfDay,
          convertISOGMT,
        )(action.payload.startDate),
        endDate: compose(
          toISO,
          toEndOfDay,
          convertISOGMT,
        )(action.payload.endDate),
        range: action.payload.range,
      };
    }
    case PATIENT_DATE_ACTIONS.SET_FIRST_LAST_DATES: {
      const { firstMeasurementDate, latestMeasurementDate } = action.payload;

      const [firstDate, latestDate] = validateAndTransformDates([
        firstMeasurementDate,
        latestMeasurementDate,
      ]);

      return {
        ...state,
        firstMeasurementDate: compose(toISO, convertJSDateGMT)(firstDate),
        lastMeasurementDate: compose(toISO, convertJSDateGMT)(latestDate),
      };
    }
    default:
      return state;
  }
};
