import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import { searchTransform } from './clinical-statistics.utils';

import {
  ClinicalStatisticsLoaderImplType,
  ClinicalStatisticsTransformImplType,
  ClinicalStatisticsServiceImplType,
  ClinicalStatisticsResponse,
  ClinicalStatisticsResponseModel,
} from './clinical-statistics.types';

import { ENDPOINTS } from 'src/services/service.constants';

export const ClinicalStatisticsLoaderImpl: ClinicalStatisticsLoaderImplType = (
  { inputText, unitGlucose, role, location },
  accessToken,
  gigyaToken,
  unitGlucoseForService,
) => {
  const text = inputText ? inputText.trim() : inputText;
  const query = !text ? stringifyBody({}) : stringifyBody({ inputText: text });
  const url = role
    ? ENDPOINTS.clinicalStatisticsV2
    : ENDPOINTS.clinicalStatistics;
  return postJSON(url, {
    headers: {
      Authorization: createAuthHeader(accessToken),
      GlucoseUnit: unitGlucoseForService ? unitGlucoseForService : unitGlucose,
      ObservationType: window.location.pathname.includes('cgm')
        ? 'CGM'
        : location
        ? location
        : 'BG',
    },
    body: query,
  });
};

export const ClinicalStatisticsTransformImpl: ClinicalStatisticsTransformImplType =
  (data: ClinicalStatisticsResponse): ClinicalStatisticsResponseModel =>
    searchTransform(data);

export const ClinicalStatisticsServiceImpl: ClinicalStatisticsServiceImplType =
  (
    load: ClinicalStatisticsLoaderImplType,
    transform: ClinicalStatisticsTransformImplType,
  ) =>
  (query, accessToken, gigyaToken, glucoseUnit) =>
    load(query, accessToken, gigyaToken, glucoseUnit).then(transform);
