import { createRequestActionTypes } from 'src/domains/diagnostics/core/request/request.actions';

export const PATIENT_DASHBOARD_ACTIONS = {
  CHANGE_GRAPH: 'CHANGE_GRAPH',
  CHANGE_GRAPH_TYPE: 'CHANGE_GRAPH_TYPE',
  CHANGE_LOGBOOK_TYPE: 'CHANGE_LOGBOOK_TYPE',
  SET_DATES: 'SET_DATES',
  SET_BLOOD_GLUCOSE_OVERVIEW_END_DATE: 'SET_BLOOD_GLUCOSE_OVERVIEW_END_DATE',
  GET_CLINICAL_DATA: 'GET_CLINICAL_DATA',
  SET_CLINICAL_DATA: 'SET_CLINICAL_DATA',
  FETCH_BG_OVERVIEW_CLINICAL_DATA: 'FETCH_BG_OVERVIEW_CLINICAL_DATA',
  GET_CLINICAL_DATA_ERROR: 'GET_CLINICAL_DATA_ERROR',
  TOGGLE_BLOOD_GLUCOSE_LINES: 'TOGGLE_BLOOD_GLUCOSE_LINES',
  TOGGLE_BLOOD_GLUCOSE_POINTS: 'TOGGLE_BLOOD_GLUCOSE_POINTS',
  TOGGLE_BLOOD_GLUCOSE_BEFORE_MEAL_POINTS:
    'TOGGLE_BLOOD_GLUCOSE_BEFORE_MEAL_POINTS',
  TOGGLE_BLOOD_GLUCOSE_AFTER_MEAL_POINTS:
    'TOGGLE_BLOOD_GLUCOSE_AFTER_MEAL_POINTS',
  TOGGLE_CARBOHYDRATES: 'TOGGLE_CARBOHYDRATES',
  TOGGLE_MEAN_BLOOD_GLUCOSE: 'TOGGLE_MEAN_BLOOD_GLUCOSE',
  TOGGLE_DTC_MODAL: 'TOGGLE_DTC_MODAL',
  CHANGE_DEVICE_SETTINGS_TYPE: 'CHANGE_DEVICE_SETTINGS',
};

export const GET_PATIENT = 'GET_PATIENT';
export const GET_PATIENT_REQUEST = createRequestActionTypes(GET_PATIENT);

export const GET_CLINICAL_DATA_REQUEST = createRequestActionTypes(
  PATIENT_DASHBOARD_ACTIONS.GET_CLINICAL_DATA,
);

export const GET_BG_OVERVIEW_CLINICAL_DATA_REQUEST = createRequestActionTypes(
  PATIENT_DASHBOARD_ACTIONS.FETCH_BG_OVERVIEW_CLINICAL_DATA,
);

export const GRAPH_TYPE = {
  TREND: 'trend',
  STANDARD_DAY: 'standard-day',
  STANDARD_WEEK: 'standard-week',
  LOGBOOK: 'logbook',
  METABOLIC_RATE: 'metabolic-rate',
  INSULIN: 'insulin',
  INSULIN_PUMP: 'insulin-pump',
};

/* These values correspond to units specified by /clinical-data endpoint. */
export const BLOOD_GLUCOSE_UNITS = {
  MG_PER_DL: 'mg/dL',
  MMOL_PER_L: 'mmol/L',
};

export const BG_UNIT_KEYS = {
  [BLOOD_GLUCOSE_UNITS.MG_PER_DL]: 'general.units.mgPerDL',
  [BLOOD_GLUCOSE_UNITS.MMOL_PER_L]: 'general.units.mmolPerL',
};

export const EMPTY_VALUE_PLACEHOLDER = '-';
export const NO_DATA_EMPTY_PLACEHOLDER = 'NO_DATA';
export const NOT_ENOUGH_DATA_PLACEHOLDER = 'NOT_ENOUGH_DATA';

export const MINUTES_IN_DAY = 1440;

export const BOLUS_TYPE = {
  STANDARD: 'Std',
  QUICK: 'Scr',
  EXTENDED: 'Ext',
  MULTIWAVE: 'Mul',
};

export const BASAL_REMARKS = {
  POWER_DOWN: 'power down',
  POWER_UP: 'power up',
  PAUSE: 'Pause',
  STOP: 'Stop',
  RUN: 'Run',
};

export const HYPO_RISK_MIN = 20; //only for mg/dl

export const HYPO_RISK_MAX = 600; //only for mg/dl

export const MIN_BG = 113; //only for mg/dl

export const INSULIN_TYPE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

export const HI_VALUE = 'HI';
export const LO_VALUE = 'LO';
export const HI_OR_LO = 'HiOrLo';
