import {
  createRequestActions,
  createRequestActionTypes,
} from 'src/core/request/request.actions';

export const GET_CGM_SUMMARY = createRequestActionTypes('CGM_SUMMARY');

export const getCGMSummaryRequest = createRequestActions(GET_CGM_SUMMARY);

export const GET_CGM_CLINCAL_DATA =
  createRequestActionTypes('CGM_CLINICAL_DATA');

export const getCGMClinicalDataRequest =
  createRequestActions(GET_CGM_CLINCAL_DATA);

export const CGM_SET_DATES = 'CGM_SET_DATES';
export const CGM_CLEAR_DATES = 'CGM_CLEAR_DATES';
export const CGM_SET_SELECTED_DATE = 'CGM_SET_SELECTED_DATE';

export const clearCgmDateRange = () => ({
  type: CGM_CLEAR_DATES,
});

export const setSelectedDate = (selectedDate: string) => ({
  type: CGM_SET_SELECTED_DATE,
  payload: { selectedDate },
});

export const onCgmDatesRangeChange = (startDate, endDate, range) => ({
  type: CGM_SET_DATES,
  payload: { startDate, endDate, range },
});
