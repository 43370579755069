import React from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { testId } from '@roche/roche-common';

import { SEARCH_BAR_ID } from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { JELLO_TEXT_SIZES } from 'src/app/app.jello.constants';

import { useSearchBoxAlert } from './hooks/useSearchBoxAlert'; // Ajusta la ruta según tu estructura de directorios
import styles from './styles.module.css';

export type JelloSearchBoxProps = {
  id: string;
  ariaLabel: string;
  clearButtonAccessibilityText: string;
  searchButtonAccessibilityText: string;
  autofocus?: boolean;
  block?: boolean;
  disabled?: boolean;
  outlined?: boolean;
  placeholder: string;
  searchCallback: (e: unknown) => void;
  rounded?: boolean;
  size?: string;
  value?: string;
};

export const JelloSearchBox: React.FunctionComponent<JelloSearchBoxProps> = ({
  id,
  ariaLabel,
  autofocus,
  block,
  clearButtonAccessibilityText,
  searchButtonAccessibilityText,
  disabled,
  outlined,
  placeholder,
  rounded,
  size,
  value,
  searchCallback,
}) => {
  const { showMinAlert, searchBoxRef } = useSearchBoxAlert({ searchCallback });
  const alertText = t('searchBar.patientNameIsTooShort');

  return (
    <div className={styles.jelloSearchBoxContainer}>
      <jello-search-box
        ref={searchBoxRef}
        id={id}
        aria-label={ariaLabel}
        autofocus={autofocus}
        block={block}
        clear-button-accessibility-text={clearButtonAccessibilityText}
        search-button-accessibility-text={searchButtonAccessibilityText}
        disabled={disabled}
        outlined={outlined}
        placeholder={placeholder}
        rounded={rounded}
        size={size}
        value={value}
        {...testId(SEARCH_BAR_ID, id)}
      />
      {
        <div
          className={classNames(styles.jelloSearchBoxMinAlert, {
            [styles.show]: showMinAlert,
          })}
        >
          <jello-text
            size={JELLO_TEXT_SIZES.XS}
            {...testId(SEARCH_BAR_ID, 'too-short-alert')}
          >
            {alertText}
          </jello-text>
        </div>
      }
    </div>
  );
};
