import {
  EditPatientWithFhirActions,
  EditPatientWithFhirActionType,
  EditPatientWithFhirState,
} from './edit-patient-with-fhir.types';

export const initialEditPatientWhitFhirState: EditPatientWithFhirState = {
  editionWithFhirHasError: false,
  editionWithFhirWasSuccessful: false,
  isEditingWithFhirPatient: false,
};

export const editPatientWithFhirReducer = (
  state = initialEditPatientWhitFhirState,
  action: EditPatientWithFhirActions,
): EditPatientWithFhirState => {
  switch (action.type) {
    case EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_START:
      return {
        editionWithFhirHasError: false,
        editionWithFhirWasSuccessful: false,
        isEditingWithFhirPatient: true,
      };
    case EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_SUCCESS:
      return {
        editionWithFhirHasError: false,
        editionWithFhirWasSuccessful: true,
        isEditingWithFhirPatient: false,
      };
    case EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_ERROR:
      return {
        editionWithFhirHasError: true,
        editionWithFhirWasSuccessful: false,
        isEditingWithFhirPatient: false,
      };
    case EditPatientWithFhirActionType.CLEAR_EDIT_PATIENT_WITH_FHIR_STATE:
      return initialEditPatientWhitFhirState;
    default:
      return state;
  }
};
