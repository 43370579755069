import styled from 'styled-components';

import { convertPxToRem } from 'src/domains/diagnostics/utils/rem-calc';

export const Tooltip = styled.span`
  position: relative;
  z-index: 1;
  display: inline-block;
  text-align: center;
  line-height: ${({ theme }) => theme.spacing.three};
  margin-left: ${({ theme }) => theme.spacing.one};
  top: ${(props) => (props.noTop ? 0 : `${convertPxToRem(2)}`)};
`;
