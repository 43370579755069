import React from 'react';
import { Bundle } from 'src/navigation/bundle';

export const PayerPortalBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { PayerPortalContainer } = await import(
        /* webpackChunkName: "payerPortal" */ './payer-portal.container'
      );
      return PayerPortalContainer;
    }}
    bundleDidLoad={(PayerPortalContainer) => (
      <PayerPortalContainer {...props} />
    )}
  />
);
