import * as React from 'react';
import { testId } from '@roche/roche-common';
import { Link } from 'react-router-dom';
import { RenderIfElse } from 'src/utils/render-if';

import { AppLogoProps } from 'src/widgets/top-navigation-jello/top-navigation-jello.types';
import logo from 'src/assets/images/logo.png';

import {
  LOGO_ID,
  SCOPE_ID,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';
import { JelloTooltip } from 'src/components/jello-tooltip/jello-tooltip.component';
import { t } from 'i18next';
import { AccuChekLogo } from 'src/assets/icons';

export const AppLogoComponent = ({
  routes,
  isPayer,
  rebranding,
  clearSearchInput,
}: AppLogoProps) => {
  return (
    <JelloTooltip
      id={SCOPE_ID + LOGO_ID}
      label={t(`general.${rebranding ? 'appName_ACC' : 'appName'}`)}
      style={{ zIndex: '300' }}
    >
      <Link
        to={isPayer ? routes.payerPortal.main : routes.general.home}
        onClick={clearSearchInput}
        {...testId(SCOPE_ID, LOGO_ID)}
      >
        <RenderIfElse validate={rebranding}>
          <AccuChekLogo height={32} width={110} />
          <img src={logo} height={32} width={32} />
        </RenderIfElse>
      </Link>
    </JelloTooltip>
  );
};
