import React from 'react';

import { colors } from 'src/core/styles/colors';

const defaultColor = colors.brandBlue;

type Props = {
  color?: string,
  clickHandler: () => void,
  height?: number,
  width?: number,
};

export const V3Triangle = ({ color, clickHandler, height, width }: Props) => (
  <svg
    height={height || 8}
    width={width || 5}
    viewBox="0 0 8 5"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    onClick={(event) => {
      event.stopPropagation();
      clickHandler();
    }}
  >
    <g
      id="Patient-Management-Dashboard"
      stroke={color || defaultColor}
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="51.HCP-Dashboard"
        transform="translate(-579.000000, -384.000000)"
        fill={color || defaultColor}
      >
        <g id="TOP-PAGE" transform="translate(64.000000, 123.000000)">
          <g
            id="Cards-Headers--NEW--with-sorting"
            transform="translate(9.000000, 245.000000)"
          >
            <g
              id="Sorting-arrow-/-default"
              transform="translate(505.000000, 5.000000)"
            >
              <polygon
                id="Path-6-Copy"
                transform="translate(5.000000, 13.500000) rotate(-180.000000) translate(-5.000000, -13.500000) "
                points="1 16 9 16 5 11"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
