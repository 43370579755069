import * as React from 'react';

import { Dropdown } from 'src/components/dropdown/dropdown.component';

import { GraphStartTimeDropdownContainer } from './graph-start-time-dropdown.style';

export const options12h = [
  { label: '12:00 AM', value: '12:00AM' },
  { label: '6:00 AM', value: '6:00AM' },
  { label: '12:00 PM', value: '12:00PM' },
  { label: '6:00 PM', value: '6:00PM' },
];
export const options24h = [
  { label: '0:00', value: '0:00' },
  { label: '6:00', value: '6:00' },
  { label: '12:00', value: '12:00' },
  { label: '18:00', value: '18:00' },
];

export type GraphStartTimeDropdownProps = {
  is12hFormat: boolean;
};

export const GraphStartTimeDropdown = ({
  is12hFormat,
}: GraphStartTimeDropdownProps) => {
  const options = is12hFormat ? options12h : options24h;
  const placeholder = options[0] ? options[0].label : '';
  return (
    <GraphStartTimeDropdownContainer className="print-hide">
      <Dropdown
        modelPath="ui.patientDashboard.graphStartTime"
        options={options}
        arrowIconHeight={5}
        optionFontSize={'0.72rem'}
        selectedOptionPadding={'0.75rem'}
        selectHeight={'2.25rem'}
        searchable={false}
        placeholder={placeholder}
        simpleValue
      />
    </GraphStartTimeDropdownContainer>
  );
};
