import { NavigationComponentProps } from './navigation';

// tslint:disable
export type RouteHistory = {
  currentRoute: string;
};

export type HandleTimerResetProps = {
  routeHistory: any;
  children?: any;
} & NavigationComponentProps;

export const handlePatientSession = (
  handleTimerResetProps: HandleTimerResetProps,
): any => {
  const {
    resetPatientSession,
    startPatientSession,
    savePatientSessionStart,
    patientId,
    startPatientSessionRequest,
    patientFhirId,
    hcpTimezone,
  } = handleTimerResetProps;

  if (!patientId) {
    return;
  }
  if (shouldPatientSessionStart(handleTimerResetProps)) {
    const now = Math.round(new Date().getTime() / 1000);
    startPatientSessionRequest({
      patientId: patientFhirId,
      hcpTimezone: hcpTimezone,
    });
    return startPatientSession(patientId, now);
  }
  if (shouldPatientSessionStop(handleTimerResetProps)) {
    if (!handleTimerResetProps.isPatientSessionRunning) {
      return resetPatientSession();
    }
    savePatientSessionStart({
      patientId: patientId.toString(),
      shouldClearSession: true,
    });
    return resetPatientSession();
  }
};

export const shouldPatientSessionStop = ({
  routeHistory,
  patientId,
}: HandleTimerResetProps): boolean =>
  !isPatientRoute(routeHistory.currentRoute, patientId);

export const shouldPatientSessionStart = ({
  routeHistory,
  patientId,
  patientSessionId,
}: HandleTimerResetProps): boolean => {
  if (isPatientRoute(routeHistory.currentRoute, patientId)) {
    return patientSessionId !== patientId;
  }
  return false;
};

export const isPatientRoute = (route: string | null, patientId: number) =>
  route ? route.includes(patientId.toString()) : false;
