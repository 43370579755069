import { PayloadAction } from 'src/app/store/app.types';

export enum PatientSearchActionType {
  SET_PATIENT_SEARCH_RESULTS_PAGE = 'SET_PATIENT_SEARCH_RESULTS_PAGE',
}

export type SetPatientSearchResultsPageAction = PayloadAction<
  PatientSearchActionType.SET_PATIENT_SEARCH_RESULTS_PAGE,
  { page: number }
>;
