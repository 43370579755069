import { changePasswordActionType } from './change-password.types';

export const changedPasswordStateSuccess = () => ({
  type: changePasswordActionType.IS_CHANGED_PASSWORD_SUCCESS,
  payload: true,
});

export const changedPasswordStateReset = () => ({
  type: changePasswordActionType.IS_CHANGED_PASSWORD_RESET,
  payload: false,
});
