import styled from 'styled-components';

import { borderRadius } from 'src/core/styles/border-radius';
import { boxShadow, boxShadows } from 'src/core/styles/box-shadow';
import { colors } from 'src/core/styles/colors';
import { spacing } from 'src/core/styles/spacing';
import { transitions } from 'src/core/styles/transitions';
import { P } from 'src/components/fonts/body/body.style';
import { weight } from 'src/components/fonts/weights/weight.style';
import { ControlCheckbox } from 'src/components/forms/control-checkbox/control-checkbox.component';
import { convertPxToRem } from 'src/utils/rem-calc';

const paddingRatio = 1 / 12;
const bgColorsByState = {
  active: colors.brandBlue,
  disabled: colors.silver,
  focused: colors.blueMarine,
};

export const ToggleSwitchWrapperLabel = styled.label``;

export const ToggleSwitchP = styled(P)`
  color: ${colors.charcoal};
  font-weight: ${weight.semiBold};
  margin: 0 0 ${spacing.three};
`;

export const ToggleSwitchContainerSpan = styled.span`
  background-color: ${bgColorsByState.disabled};
  border-color: ${colors.clear};
  border-radius: ${borderRadius.twenty};
  border-style: solid;
  border-width: ${(props) => convertPxToRem(props.size * paddingRatio)};
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  transition: ${transitions.default};
  width: ${(props) => convertPxToRem(props.size * 2)};
`;

export const ToggleSwitchIndicatorSpan = styled.span`
  background-color: ${colors.white};
  border-radius: ${borderRadius.circle};
  box-shadow: ${boxShadows.base};
  display: block;
  height: ${(props) => convertPxToRem(props.size)};
  transition: ${transitions.exit};
  transform: translate(0, 0);
  will-change: transform;
  width: ${(props) => convertPxToRem(props.size)};
`;

export const ToggleSwitchInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + ${ToggleSwitchContainerSpan} {
    background-color: ${bgColorsByState.active};

    & ${ToggleSwitchIndicatorSpan} {
      transform: translateX(100%);
      transition: ${transitions.enter};
    }
  }

  &:focus + ${ToggleSwitchContainerSpan} {
    background-color: ${bgColorsByState.focused};
    box-shadow: ${boxShadow({
      color: bgColorsByState.focused,
      depth: 'base',
    })};
    & ${ToggleSwitchIndicatorSpan} {
      box-shadow: ${boxShadows.one};
    }
  }
`;

export const ToggleSwitchControl =
  ToggleSwitchInput.withComponent(ControlCheckbox);
