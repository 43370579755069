import * as React from 'react';
import { Block } from 'src/components/block/block.component';
import { Button } from 'src/components/button/button.component';
import { LocalizedText } from 'src/components/localized-text/localized-text.component';
import { SuccessBadge } from 'src/components/badge/success-badge/success-badge.component';

import { ModalBody, CommonHeader } from '../../modal.style';

import { ButtonReset } from 'src/core/styles/resets';
import { colors } from 'src/core/styles/colors';
import { XIcon } from 'src/assets/icons';

import { PwModalHeader } from './change-password-confirmation.style';
import { ChangePasswordConfirmationModalProps } from './change-password-confirmation.types';

export const ChangePasswordConfirmationComponent: React.FunctionComponent<
  ChangePasswordConfirmationModalProps
> = ({ onSignout, t }) => (
  <ModalBody>
    <CommonHeader>
      <strong>
        <LocalizedText
          fontSize={4}
          textKey={'modals.passwordChangeConfirmation.title'}
        />
      </strong>
      <ButtonReset onClick={() => onSignout()}>
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <Block py={2} px={6}>
      <Block p={4}>
        <Block pb={2} mb={3} display="flex" justifyContent="center">
          <SuccessBadge size={75} fillColor={colors.brandBlue} />
        </Block>
        <Block pb={3} mb={4} display="flex" justifyContent="center">
          <PwModalHeader>
            <LocalizedText
              textKey={'modals.passwordChangeConfirmation.description'}
            />
          </PwModalHeader>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            label={t('modals.passwordChangeConfirmation.confirm')}
            onClick={() => onSignout()}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);
