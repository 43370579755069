import { ensureNever } from '../../utils/typescript';

import {
  LatestPatientsActionType,
  LatestPatientsReducerActions,
  LatestPatientsState,
} from './latest-patients.types';

export const INITIAL_LATEST_PATIENTS_STATE: LatestPatientsState = {
  results: undefined,
  isFetching: false,
  error: undefined,
};

export const latestPatientsReducer = (
  state = INITIAL_LATEST_PATIENTS_STATE,
  action: LatestPatientsReducerActions,
): LatestPatientsState => {
  switch (action.type) {
    case LatestPatientsActionType.GET_LATEST_PATIENTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case LatestPatientsActionType.GET_LATEST_PATIENTS_SUCCESS:
      return {
        ...state,
        results: action.payload.slice(0, 5),
        isFetching: false,
      };
    case LatestPatientsActionType.GET_LATEST_PATIENTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case LatestPatientsActionType.RESET_LATEST_PATIENTS_STATE:
      return INITIAL_LATEST_PATIENTS_STATE;
    default:
      ensureNever(action);
      return state;
  }
};
