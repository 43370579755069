export const mockManualDeliveryResponse = {
  stripModelId: 1,
  patientStock: 150,
};

export const mockManualDeliveryPayload = {
  patientId: '1234',
  stripModelId: '1234',
  stripModelReference: '10601',
  numberOfStripsDelivered: '100',
  comment: 'string',
};
