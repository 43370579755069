/* tslint:disable */

export const mockDeviceListSettings = [
  {
    version: 2,
    timestamp: '2020-03-25T08:48:56.561978Z',
  },
  {
    version: 1,
    timestamp: '2020-03-23T08:48:56.561978Z',
  },
];

export const mockQueryParams = {
  patientId: 'magic_id',
  deviceSerialNumber: 'magic_serial',
  patientType: 'magic_type',
};
