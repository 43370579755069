import { ModuleEntries } from '@roche/roche-common';

import {
  ManifestModulesActions,
  ManifestModulesActionType,
  ManifestModulesState,
} from './manifestModules.types';

export const INITIAL_MODULE_MANIFEST_STATE: ModuleEntries = {};
export const manifestModulesReducer = (
  state = INITIAL_MODULE_MANIFEST_STATE,
  action: ManifestModulesActions,
): ManifestModulesState => {
  // tslint:disable-next-line: no-small-switch
  switch (action.type) {
    case ManifestModulesActionType.SAVE_MANIFEST_MODULES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
