export const mockTimeIntervals = {
  resultDescription: 'getTimeBlocksOK',
  model: {
    id: null,
    patientId: 176666,
    timeIntervals: [
      {
        id: null,
        description: 'BEFORE_BREAKFAST',
        startTime: '06:00:00',
        endTime: '09:29:59',
        label: null,
      },
      {
        id: null,
        description: 'AFTER_BREAKFAST',
        startTime: '09:30:00',
        endTime: '12:29:59',
        label: null,
      },
      {
        id: null,
        description: 'BEFORE_LUNCH',
        startTime: '12:30:00',
        endTime: '15:29:59',
        label: null,
      },
      {
        id: null,
        description: 'AFTER_LUNCH',
        startTime: '15:30:00',
        endTime: '18:29:59',
        label: null,
      },
      {
        id: null,
        description: 'BEFORE_DINNER',
        startTime: '18:30:00',
        endTime: '21:59:59',
        label: null,
      },
      {
        id: null,
        description: 'AFTER_DINNER',
        startTime: '22:00:00',
        endTime: '23:59:59',
        label: null,
      },
      {
        id: null,
        description: 'BEDTIME',
        startTime: '00:00:00',
        endTime: '00:59:59',
        label: null,
      },
      {
        id: null,
        description: 'NIGHT',
        startTime: '01:00:00',
        endTime: '05:59:59',
        label: null,
      },
    ],
  },
};
