import React from 'react';
import { withTheme } from 'styled-components';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { SvgIcon } from 'src/components/icon/icon.component';

const PumpTimeSetBackIconComponent = ({
  width = 23,
  height = 24,
  theme,
  t,
}) => {
  const originalWidth = 23;
  const originalHeight = 24;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphDetails.legend.timeSetBack')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g>
        <rect fill={theme.colors.charcoal} x="0" y="4" width="1" height="16" />
        <rect fill={theme.colors.charcoal} x="15" y="4" width="1" height="16" />
        <rect
          fill={theme.colors.charcoal}
          fillRule="nonzero"
          x="1"
          y="4"
          width="15"
          height="3"
        />
        <rect
          fill={theme.colors.charcoal}
          fillRule="nonzero"
          x="1"
          y="19"
          width="15"
          height="1"
        />
        <rect
          fill={theme.colors.red}
          fillRule="nonzero"
          x="0"
          y="23"
          width="23"
          height="1"
        />
        <rect
          fill={theme.colors.charcoal}
          fillRule="nonzero"
          x="4"
          y="0"
          width="1"
          height="4"
        />
        <rect
          fill={theme.colors.charcoal}
          fillRule="nonzero"
          x="12"
          y="0"
          width="1"
          height="4"
        />
        <path
          d="M4,14.0641283 L5.11704312,13.9098196 C5.15263536,14.2340252 5.24845918,14.4818517 5.40451745,14.6533066 C5.56057573,14.8247615 5.74948554,14.9104876 5.97125257,14.9104876 C6.20944678,14.9104876 6.40999234,14.8076163 6.57289528,14.6018704 C6.73579821,14.3961246 6.81724846,14.1186836 6.81724846,13.7695391 C6.81724846,13.4390988 6.73922049,13.1772443 6.58316222,12.9839679 C6.42710394,12.7906915 6.23682522,12.6940548 6.01232033,12.6940548 C5.86447565,12.6940548 5.68788604,12.7267866 5.4825462,12.7922512 L5.60985626,11.7214429 C5.92197281,11.730795 6.16016345,11.6536414 6.32443532,11.48998 C6.48870719,11.3263185 6.57084189,11.1088858 6.57084189,10.8376754 C6.57084189,10.6069906 6.51060977,10.423069 6.39014374,10.2859051 C6.2696777,10.1487412 6.10951503,10.0801603 5.90965092,10.0801603 C5.71252468,10.0801603 5.54414854,10.1580932 5.40451745,10.3139613 C5.26488637,10.4698293 5.18001384,10.6973933 5.14989733,10.99666 L4.0862423,10.7909152 C4.16016464,10.3763061 4.27173094,10.0450914 4.42094456,9.79726119 C4.57015817,9.54943096 4.77823275,9.35459879 5.04517454,9.21275885 C5.31211632,9.07091891 5.61122353,9 5.94250513,9 C6.50924308,9 6.96372177,9.20574277 7.30595483,9.61723447 C7.58795487,9.9539095 7.72895277,10.3342219 7.72895277,10.758183 C7.72895277,11.3598338 7.4401124,11.8399002 6.862423,12.1983968 C7.20739392,12.2825656 7.48322963,12.4711631 7.6899384,12.7641951 C7.89664717,13.057227 8,13.4110422 8,13.8256513 C8,14.4273021 7.80698345,14.9401003 7.42094456,15.3640615 C7.03490567,15.7880226 6.55441766,16 5.97946612,16 C5.43463109,16 4.98289023,15.8215337 4.62422998,15.4645959 C4.26556973,15.107658 4.05749515,14.6408401 4,14.0641283 Z M11,16 L9.69099379,16 L9.69099379,10.9639946 C9.21273053,11.4205186 8.64907157,11.7581511 8,11.9769022 L8,10.7642663 C8.34161661,10.6501353 8.71273092,10.4337652 9.11335404,10.1151495 C9.51397716,9.79653373 9.78881913,9.42482096 9.9378882,9 L11,9 L11,16 Z"
          fill={theme.colors.charcoal}
        />
        <path
          d="M21.5,15 C21.3026306,15 21.1348691,14.927537 20.9967105,14.7826087 C20.8585519,14.6376804 20.7763159,14.4275376 20.75,14.1521739 L20.0197368,5.69565217 L20,5.47826087 C20,5.02898326 20.141446,4.6702912 20.4243421,4.40217391 C20.7072383,4.13405663 21.0657873,4 21.5,4 C21.9342127,4 22.2927617,4.13405663 22.5756579,4.40217391 C22.858554,4.6702912 23,5.02898326 23,5.47826087 L22.9802632,5.7173913 L22.25,14.1521739 C22.2236841,14.4275376 22.1414481,14.6376804 22.0032895,14.7826087 C21.8651309,14.927537 21.6973694,15 21.5,15 Z M21.5,20 C21.0675654,20 20.7094609,19.8566681 20.4256757,19.57 C20.1418905,19.2833319 20,18.9266688 20,18.5 C20,18.0733312 20.1418905,17.7166681 20.4256757,17.43 C20.7094609,17.1433319 21.0675654,17 21.5,17 C21.9324346,17 22.2905391,17.1433319 22.5743243,17.43 C22.8581095,17.7166681 23,18.0733312 23,18.5 C23,18.9266688 22.8581095,19.2833319 22.5743243,19.57 C22.2905391,19.8566681 21.9324346,20 21.5,20 Z"
          fill={theme.colors.red}
        />
      </g>
    </SvgIcon>
  );
};

export const PumpTimeSetBackIcon = withTranslation(
  withTheme(PumpTimeSetBackIconComponent),
);
