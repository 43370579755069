/* tslint:disable */

import { path } from 'ramda';

export const selectDeviceSettings = path(['deviceSettings', 'settings']);

export const selectDeviceSettingsStatus = path(['deviceSettings', 'status']);

export const selectDeviceSettingsStatusAllData = path([
  'deviceSettings',
  'statusAllData',
]);
