import { createAction, createPayloadAction } from '../../app/store/app.actions';

import {
  GetLatestPatientsErrorAction,
  GetLatestPatientsErrorPayloadType,
  GetLatestPatientsStartAction,
  GetLatestPatientsSuccessAction,
  GetLatestPatientsSuccessPayloadType,
  LatestPatientsActionType,
  ResetLatestPatientsStateAction,
} from './latest-patients.types';

export const resetLatestPatientsState = (): ResetLatestPatientsStateAction =>
  createAction(LatestPatientsActionType.RESET_LATEST_PATIENTS_STATE);

export const getLatestPatientsStart = (): GetLatestPatientsStartAction =>
  createAction(LatestPatientsActionType.GET_LATEST_PATIENTS_START);

export const getLatestPatientsError = (
  payload: GetLatestPatientsErrorPayloadType,
): GetLatestPatientsErrorAction =>
  createPayloadAction(
    LatestPatientsActionType.GET_LATEST_PATIENTS_ERROR,
    payload,
  );

export const getLatestPatientsSuccess = (
  payload: GetLatestPatientsSuccessPayloadType,
): GetLatestPatientsSuccessAction =>
  createPayloadAction(
    LatestPatientsActionType.GET_LATEST_PATIENTS_SUCCESS,
    payload,
  );
