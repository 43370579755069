import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { GraphSvgIcon, SvgIconSpan } from 'src/components/icon/icon.component';

const PumpPauseIconComponent = ({
  x,
  y,
  height,
  width,
  opacity,
  title,
  isGraphIcon = true,
  t,
  cursor,
  onMouseMove,
  onMouseOut,
  onClick,
}) => {
  const originalWidth = 17;
  const originalHeight = 17;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  const iconTitle = title || t('graphs.iconTitles.pumpPause');

  const coreIcon = (
    <GraphSvgIcon
      x={x}
      y={y}
      title={iconTitle}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      titleNotToBeRendered={onMouseMove && true}
    >
      <g
        fill="none"
        fillRule="evenodd"
        opacity={opacity}
        cursor={cursor}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        onClick={onClick}
      >
        <rect
          fill="#188CE1"
          stroke="#188CE1"
          width={originalWidth}
          height={originalHeight}
        />
        <path
          fill="#FFF"
          d="M 5.025 4.786 h 2 v 7 h -2 z M 9.433 4.786 h 2 v 7 h -2 z"
        />
      </g>
    </GraphSvgIcon>
  );

  return !isGraphIcon ? SvgIconSpan(iconTitle, coreIcon) : coreIcon;
};

export const PumpPauseIcon = withTranslation(PumpPauseIconComponent);
