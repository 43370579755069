import { createRequestActionTypes } from '../../request/request.actions';
import { addDomainNamespace } from '../../../utils/domain-namespace';

const DOMAIN_NAMESPACE = 'PATIENT_STRIP_MODEL';
const addNamespacing = (actionName) =>
  addDomainNamespace(actionName, DOMAIN_NAMESPACE);
export const stripModelTranslationKeyBase = 'prescription.stripModel.';
export const GET_STRIP_MODELS = addNamespacing('GET_STRIP_MODELS');
export const GET_STRIP_MODELS_REQUEST =
  createRequestActionTypes(GET_STRIP_MODELS);
