import {
  CheckDuplicatedHealthcareIdActions,
  CheckDuplicatedHealthcareIdActionType,
  CheckDuplicatedHealthcareIdState,
} from './check-duplicated-healthcare-id.actions.types';

export const CheckDuplicatedHealthcareIdInitialState: CheckDuplicatedHealthcareIdState =
  {
    isCheckingDuplicatedHealthcareId: false,
    checkDuplicatedHealthcareIdWasSuccessful: false,
    checkDuplicatedHealthcareIdHasError: false,
    isHealthcareIdDuplicated: null,
  };

export const checkDuplicatedHealthcareIdReducer = (
  state = CheckDuplicatedHealthcareIdInitialState,
  action: CheckDuplicatedHealthcareIdActions,
) => {
  switch (action.type) {
    case CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_START:
      return {
        ...state,
        isCheckingDuplicatedHealthcareId: true,
        isHealthcareIdDuplicated: null,
      };
    case CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_SUCCESS:
      return {
        ...state,
        isCheckingDuplicatedHealthcareId: false,
        checkDuplicatedHealthcareIdWasSuccessful: true,
        isHealthcareIdDuplicated: true,
      };
    case CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_ERROR:
      return {
        ...state,
        isCheckingDuplicatedHealthcareId: false,
        checkDuplicatedHealthcareIdHasError: true,
        isHealthcareIdDuplicated: action.payload.message === '409',
      };
    case CheckDuplicatedHealthcareIdActionType.RESET_DUPLICATED_HEALTHCARE_ID:
      return CheckDuplicatedHealthcareIdInitialState;
    default:
      return state;
  }
};
