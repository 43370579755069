import {
  CreateProfessionalLoaderImpl,
  CreateProfessionalServiceImpl,
  CreateProfessionalTransformImpl,
} from './create-professional.service';

import { mockCreateProfessionalResponse } from './create-professional.mock';

const mockPoster = () => Promise.resolve(mockCreateProfessionalResponse);

export const CreateProfessionalFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPoster : CreateProfessionalLoaderImpl;
  return CreateProfessionalServiceImpl(loader, CreateProfessionalTransformImpl);
};
