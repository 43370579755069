import * as React from 'react';

import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

import { GraphDetailBloodGlucose } from 'src/domains/diagnostics/components/graph-statistics/components/graph-statistics-blood-glucose/graph-statistics-blood-glucose.component';
import { GraphDetailTargetRanges } from 'src/domains/diagnostics/components/graph-statistics/components/graph-statistics-target-ranges/graph-statistics-target-ranges.component';
import { GraphStatisticsInsulin } from 'src/domains/diagnostics/components/graph-statistics/components/graph-statistics-insulin/graph-statistics-insulin.component';
import { MetabolicStatisticsTests } from 'src/domains/diagnostics/components/graph-statistics/components/metabolic-statistics-tests.component';
import { MetabolicStatisticsBloodGlucose } from 'src/domains/diagnostics/components/graph-statistics/components/metabolic-statistics-blood-glucose.component';
import { MetabolicStatisticsIndexes } from 'src/domains/diagnostics/components/graph-statistics/components/metabolic-statistics-indexes.component';
import {
  Container,
  ListSectionsContainer,
  Title,
} from './graph-statistics.style';

const renderDefaultGraphStatistics = (
  graphType,
  {
    bloodGlucoseValues: {
      bloodGlucoseMean,
      bloodGlucoseStandardDeviation,
      bloodGlucoseUnit,
      testsPerDay,
    },
    targetRangesValues: {
      abovePercentage,
      belowAndHypoPercentage,
      hypoglycaemiaNumber,
      hypoglycaemiaPercentage,
      targetBloodGlucoseMinimum,
      targetBloodGlucoseMaximum,
      withinPercentage,
    },
    insulinValues,
    hasSufficiencyData,
    hasData,
  },
) => (
  <React.Fragment>
    <Title>
      <LocalizedText textKey="graphDetails.statistics.statistics" />
    </Title>
    <ListSectionsContainer>
      <GraphDetailBloodGlucose
        bloodGlucoseMean={bloodGlucoseMean}
        bloodGlucoseStandardDeviation={bloodGlucoseStandardDeviation}
        bloodGlucoseUnit={bloodGlucoseUnit}
        testsPerDay={testsPerDay}
        hasSufficiencyData={hasSufficiencyData}
        hasData={hasData}
      />
      <GraphDetailTargetRanges
        abovePercentage={abovePercentage}
        belowAndHypoPercentage={belowAndHypoPercentage}
        bloodGlucoseUnit={bloodGlucoseUnit}
        hypoglycaemiaNumber={hypoglycaemiaNumber}
        hypoglycaemiaPercentage={hypoglycaemiaPercentage}
        targetBloodGlucoseMinimum={targetBloodGlucoseMinimum}
        targetBloodGlucoseMaximum={targetBloodGlucoseMaximum}
        withinPercentage={withinPercentage}
        borderRight={graphType !== GRAPHS.TREND}
      />
      <RenderIf validate={graphType !== GRAPHS.TREND}>
        <GraphStatisticsInsulin {...insulinValues} />
      </RenderIf>
    </ListSectionsContainer>
  </React.Fragment>
);

const renderMetabolicGraphStatistics = ({
  tests: { numberOfTests, testsPerDay, testsPerMeasuredDay },
  bloodGlucose: {
    bloodGlucoseUnit,
    mean,
    meanBeforeMeal,
    meanAfterMeal,
    stdDev,
    stdDevMeanRatio,
  },
  indexes: { hbgi, lbgi },
}) => (
  <React.Fragment>
    <Title noIcon>
      <LocalizedText textKey="graphDetails.statistics.statistics" />
    </Title>
    <ListSectionsContainer>
      <MetabolicStatisticsTests
        numberOfTests={numberOfTests}
        testsPerDay={testsPerDay}
        testsPerMeasuredDay={testsPerMeasuredDay}
      />
      <MetabolicStatisticsBloodGlucose
        bloodGlucoseUnit={bloodGlucoseUnit}
        mean={mean}
        meanAfterMeal={meanAfterMeal}
        meanBeforeMeal={meanBeforeMeal}
        stdDev={stdDev}
        stdDevMeanRatio={stdDevMeanRatio}
      />
      <MetabolicStatisticsIndexes hbgi={hbgi} lbgi={lbgi} />
    </ListSectionsContainer>
  </React.Fragment>
);

export const GraphStatistics = ({ graphType, graphDetails }) => (
  <Container>
    {graphType === 'metabolic'
      ? renderMetabolicGraphStatistics(graphDetails)
      : renderDefaultGraphStatistics(graphType, graphDetails)}
  </Container>
);
