import { filter, path, pathEq } from 'ramda';
import { createSelector } from 'reselect';
import { State } from 'src/app/store/app.types';
import { getIn } from 'src/utils/ramda/ramda.utils';

import { ProfileTypes } from './department.types';
import { verifyType } from './department.util';

export const filterPatientProfiles = filter(pathEq(['role'], 'PATIENT'));
export const filterProfessionalProfiles = filter(
  pathEq(['role'], 'PROFESSIONAL'),
);

export const selectDepartmentState = (state: State) => state.department;

export const getProfessionals = path(['department', 'professionals']);

export const selectDepartmentLoading = createSelector(
  [selectDepartmentState],
  (department) => getIn(['isLoading'], department),
);

export const selectDepartmentTypes = createSelector(
  [selectDepartmentState],
  (department) => filterPatientProfiles(getIn(['types'], department)),
);

export const selectDepartmentTypesProfessional = createSelector(
  [selectDepartmentState],
  (department) => filterProfessionalProfiles(getIn(['types'], department)),
);

export const selectAllDepartmentTypes = createSelector(
  [selectDepartmentState],
  (department) => getIn(['types'], department),
);

export const selectHasBasic = createSelector(
  [selectDepartmentTypes],
  (types) =>
    types.filter((type) => verifyType(type)(ProfileTypes.basic)).length > 0,
);

export const selectHasPickup = createSelector(
  [selectDepartmentTypes],
  (types) =>
    types.filter((type) => verifyType(type)(ProfileTypes.pickup)).length > 0,
);

export const selectHasHomeDelivery = createSelector(
  [selectDepartmentTypes],
  (types) =>
    types.filter((type) => verifyType(type)(ProfileTypes.homeDelivery)).length >
    0,
);

export const selectDepartmentProfessionals = createSelector(
  [selectDepartmentState],
  (department) => getIn(['professionals'], department),
);
