import { SAVE_PATIENT_REPORTS_REQUEST } from './patient-reports.constant';
import { FetchStatus } from '../device-settings/device-settings.types';

export const INITIAL_REPORT_STATE: any = {
  status: FetchStatus.NONE,
  error: {},
};

export const patientReportsReducer = (
  state = INITIAL_REPORT_STATE,
  action: any,
): any => {
  switch (action.type) {
    case SAVE_PATIENT_REPORTS_REQUEST.BASE: {
      return {
        ...state,
        status: FetchStatus.NONE,
      };
    }

    case SAVE_PATIENT_REPORTS_REQUEST.START: {
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    }

    case SAVE_PATIENT_REPORTS_REQUEST.SUCCESS: {
      return {
        ...state,
        report: action.payload,
        status: FetchStatus.SUCCESS,
      };
    }

    case SAVE_PATIENT_REPORTS_REQUEST.ERROR:
      return {
        ...state,
        error: action.payload,
        status: FetchStatus.FAILURE,
      };

    default:
      return state;
  }
};
