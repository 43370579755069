export const mockProfile = {
  firstName: 'Name',
  surname: 'Surname',
  language: 1,
  email: 'email@email.es',
  hcpId: '1234',
  departmentProfileId: 1234,
  countryId: 2,
  openConnectivity: 'false',
  dateOfBirthDay: '01',
  dateOfBirthMonth: '12',
  dateOfBirthYear: '2020',
  phone: '609876564',
};

export const mockProfileBaseObj = {
  profile: {
    birthday: '2020-12-01',
    dateOfBirthMonth: '12',
    dateOfBirthDay: '01',
    dateOfBirthYear: '2020',
    countryId: '1',
    departmentId: '1234',
    email: 'email@email.es',
    originalEmail: 'email@email.es',
    id: '1234',
    languageId: '123',
    name: 'name',
    phone: '609876543',
    profileId: '1234',
    surname: 'surname',
    surname2: 'surname2',
    grantRevokeFunctDTO: {
      grantFunctionalities: ['OPEN_CONNECTIVITY_DATA_DOWN'],
      revokeFunctionalities: [],
    },
  },
};

export const mockEditProfileResponse = {
  resultDescription: 'createdProfileOK',
  model: 'ProfessionalId:1234',
};

export const mockEditProfileError = {
  resultDescription: 'INTERNAL_SERVER_ERROR',
  error: [{ error: 'User already exist!' }],
};
