export const average = (data) => {
  if (data.length <= 0) {
    return 0;
  }

  const sum = data.reduce((acc, value) => acc + value, 0);
  return sum / data.length;
};

export const percentage = (amount: number, total: number): number =>
  amount && total ? (amount / total) * 100 : 0;

export const NORMALIZATION = {
  UNBIASED: 'UNBIASED', // sample standard deviation
  UNCORRECTED: 'UNCORRECTED', // normal standard deviation
};

/**
 * @description The standard deviation formula we are replacing from
 * the mathjs library. Biased normalization is not included because
 * it is not used.
 */
export const standardDeviationFormula = (
  numArray,
  normalization = NORMALIZATION.UNBIASED,
) => {
  const length = numArray.length;

  const divisor =
    normalization === NORMALIZATION.UNBIASED ? length - 1 : length;

  if (divisor <= 0) {
    return 0;
  }

  const mean = average(numArray);

  const sumOfDiffSquared = numArray.reduce((sum, num) => {
    const difference = num - mean;

    return sum + difference * difference;
  }, 0);

  const variance = sumOfDiffSquared / divisor;

  const standardDeviation = Math.sqrt(variance);

  return standardDeviation;
};

export const standardDeviation = (
  data,
  normalization = NORMALIZATION.UNBIASED,
) => (data.length > 0 ? standardDeviationFormula(data, normalization) : 0);

export const fixToDecimalPlace = (value, decimalPlaces) =>
  Number(value.toFixed(decimalPlaces));

export function roundHalfToEven(number, decimals = 0) {
  const x = number * Math.pow(10, decimals);
  const r = Math.round(x);
  const rounded = x % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  const result = rounded / Math.pow(10, decimals);
  return isNaN(result) ? 0 : result;
}

export const randomValue = (maxValue: number) => {
  let counter = 1;
  let prevRandomValue = 1;

  const time = new Date().getTime();
  const timeDiff = time / counter;
  const prevRandomValueNonNaNError = prevRandomValue + 1;

  const randValue = (timeDiff / prevRandomValueNonNaNError) % maxValue;

  counter++;
  prevRandomValue = randValue;

  return randValue;
};
