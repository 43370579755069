import { colors } from 'src/core/styles/colors';
import { createStyledComponent } from 'src/utils/styles';
import { PopoverRectangleContainer } from 'src/components/popover/popover.style';

export const MenuToggler = createStyledComponent<'div', {}>('div')`
  position: absolute;
  top: -1rem;
  right: -1rem;
  padding: 1rem;
  cursor: pointer;

  path {
    fill: ${colors.charcoal};
  }
`;
MenuToggler.displayName = 'PatientInfoMenuToggler';

type MenuContainer = {
  shift: boolean;
};

export const MenuContainer = createStyledComponent<'div', MenuContainer>('div')`
  position: absolute;
  right: 0rem;
  top: 0;

  > * {
    white-space: nowrap;
    position: absolute;
  }

  ${PopoverRectangleContainer} {
    padding: 2rem 0;
    position: relative;
    margin-top: 1.5rem;
  }
`;
MenuContainer.displayName = 'PatientInfoMenuContainer';

export const MenuContent = createStyledComponent<'div', {}>('div')``;
MenuToggler.displayName = 'PatientInfoMenuContent';
