import styled from 'styled-components';
import { colors } from 'src/core/styles/colors';
import { fontWeights } from 'src/core/styles/font-weights/font-weights';

type ToolTipNoDataContainerPorps = {
  origin?: string;
};

export const ToolTipNoDataContainer = styled.div<ToolTipNoDataContainerPorps>`
  width: 256px;
  z-index: 20;
  display: inline-block;
  position: ${(props) =>
    props.origin && props.origin.includes('logbook-stats')
      ? 'static'
      : 'absolute'};
  border-top: 2px solid ${colors.brandBlue};
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 0.25rem 0.0625rem rgba(155, 155, 155, 0.14),
    0 0.0625rem 0.25rem 0.0625rem rgba(155, 155, 155, 0.12),
    0 0.125rem 0.125rem 0 rgba(155, 155, 155, 0.2);
  border-radius: 0.375rem;
  margin-left: ${(props) =>
    props.origin && props.origin.includes('logbook-stats') ? '0' : '-131px'};
  bottom: 175%;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -7px;
    bottom: -13px;
    left: ${(props) =>
      props.origin && props.origin.includes('lastCell') ? '73%' : '50%'};
    box-sizing: border-box;
    border: 7px solid;
    border-color: transparent transparent ${colors.white} ${colors.white};
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -0.1875rem 0.25rem 0.0625rem 0 rgba(155, 155, 155, 0.14),
      -0.0625rem 0.25rem 0.0625rem 0 rgba(155, 155, 155, 0.12),
      -0.125rem 0.125rem 0 rgba(155, 155, 155, 0.2);
  }
`;
ToolTipNoDataContainer.displayName = 'ToolTipNoDataContainer';

export const ToolTipNoDataTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${fontWeights.semiBold};
  color: ${colors.brandBlue};
  padding: 20px 10px 8px;
  text-align: center;
`;
ToolTipNoDataTitle.displayName = 'ToolTipNoDataTitle';

export const ToolTipNoDataDescription = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding: 8px 10px 30px;
  color: ${colors.charcoal};
  text-align: center;
`;
ToolTipNoDataDescription.displayName = 'ToolTipNoDataDescription';
