// DO NOT EDIT this file without consulting a jello-ds developer
// To add/remove icons update rdcp-jello-icons.js file
import jelloIconsLibrary from '@rdcs/jello-icons/esm/library';
import { JELLO_ICONS_LIST } from './rdcp-jello-icons';

import { ACTION_ICONS_NAMES } from '@rdcs/jello-icons/esm/action';
import { CALENDAR_ICONS_NAMES } from '@rdcs/jello-icons/esm/calendar';
import { COMMERCE_ICONS_NAMES } from '@rdcs/jello-icons/esm/commerce';
import { DATA_ICONS_NAMES } from '@rdcs/jello-icons/esm/data';
import { DEVICE_ICONS_NAMES } from '@rdcs/jello-icons/esm/device';
import { DIABETES_ICONS_NAMES } from '@rdcs/jello-icons/esm/diabetes';
import { FILE_ICONS_NAMES } from '@rdcs/jello-icons/esm/file';
import { FOOD_ICONS_NAMES } from '@rdcs/jello-icons/esm/food';
import { HEALTH_ICONS_NAMES } from '@rdcs/jello-icons/esm/health';
import { MEDIA_ICONS_NAMES } from '@rdcs/jello-icons/esm/media';
import { SOCIAL_ICONS_NAMES } from '@rdcs/jello-icons/esm/social';
import { STATUS_ICONS_NAMES } from '@rdcs/jello-icons/esm/status';
import { TECHNOLOGY_ICONS_NAMES } from '@rdcs/jello-icons/esm/technology';
import { TRAVEL_ICONS_NAMES } from '@rdcs/jello-icons/esm/travel';
import { USER_ICONS_NAMES } from '@rdcs/jello-icons/esm/user';
import { WEATHER_ICONS_NAMES } from '@rdcs/jello-icons/esm/weather';

export function initIconLibrary() {
  jelloIconsLibrary.addIcons(...JELLO_ICONS_LIST);
}

const ALL_JELLO_ICON_NAMES = {
  ...ACTION_ICONS_NAMES,
  ...CALENDAR_ICONS_NAMES,
  ...COMMERCE_ICONS_NAMES,
  ...DATA_ICONS_NAMES,
  ...DEVICE_ICONS_NAMES,
  ...DIABETES_ICONS_NAMES,
  ...FILE_ICONS_NAMES,
  ...FOOD_ICONS_NAMES,
  ...HEALTH_ICONS_NAMES,
  ...MEDIA_ICONS_NAMES,
  ...SOCIAL_ICONS_NAMES,
  ...STATUS_ICONS_NAMES,
  ...TECHNOLOGY_ICONS_NAMES,
  ...TRAVEL_ICONS_NAMES,
  ...USER_ICONS_NAMES,
  ...WEATHER_ICONS_NAMES,
};

const ALL_JELLO_ICON_NAMES_LIST = Object.entries(ALL_JELLO_ICON_NAMES);
const ICONS_NAMES_STRING_LIST = JELLO_ICONS_LIST.map(
  (iconObject) => iconObject.name,
);
const FILTERED_ICON_NAMES = ALL_JELLO_ICON_NAMES_LIST.filter(([_key, value]) =>
  ICONS_NAMES_STRING_LIST.includes(value),
);

export const JELLO_ICON_NAMES = Object.fromEntries(FILTERED_ICON_NAMES);
