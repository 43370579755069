import { countlyEventTrigger } from 'src/navigation/countly';

import { DEACTIVATE_COUNTLY_KEYS } from './deactivate-patient.constants';
import { DeactivatePatientMotives } from './deactivate-patient.types';

export function deactivationKeysCountly(motive, comment) {
  const countlyKeys = { ...DEACTIVATE_COUNTLY_KEYS };
  const reason = deactivationReasonCountly(motive, countlyKeys);
  const countlySegmentation = {
    [countlyKeys.SEG_REASON]: reason,
    [countlyKeys.SEG_COMMENTS]: comment
      ? countlyKeys.VALUE_TRUE
      : countlyKeys.VALUE_FALSE,
  };
  countlyEventTrigger(countlyKeys.EVENT_TITLE, countlySegmentation);
}

export function deactivationReasonCountly(reason, countlyKeys) {
  let result = '';
  switch (reason) {
    case DeactivatePatientMotives.OTHERS:
      result = countlyKeys.VALUE_OTHER;
      break;
    case DeactivatePatientMotives.CHANGE_OF_PROFESSIONAL:
      result = countlyKeys.VALUE_CHANGE;
      break;
    case DeactivatePatientMotives.SERVICE_NOT_NEEDED:
      result = countlyKeys.VALUE_NOT_NEEDED;
      break;
    default:
      result = countlyKeys.VALUE_OTHER;
  }
  return result;
}
