import { putJSON, createAuthHeader } from 'src/utils/service/service.utils';

import {
  correlatePairingCodeLoaderImplType,
  correlatePairingCodeTransformImplType,
  correlatePairingCodeResponseType,
  CorrelatePairingCodeServiceImplType,
} from './pairing-code-correlation.types';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

const getStatus = (res: string): number => (res === '409' ? 409 : 200);
const getStatusText = (res: string): string =>
  res === '409' ? 'CONFLICT' : 'OK';

export const CorrelatePairingCodeLoaderImpl: correlatePairingCodeLoaderImplType =
  (accessToken: string, apiKey: string, code: string, fhirID: string) =>
    putJSON(endpointWithParams(ENDPOINTS.pairingCodeCorrelation, { fhirID }), {
      headers: {
        Authorization: createAuthHeader(accessToken),
        ApiKey: apiKey,
        PairingCode: code,
        Accept: 'application/json',
      },
    }).then((res) => {
      if (typeof res === 'object') {
        return Promise.resolve(res);
      } else {
        throw new Error(res);
      }
    });
export const CorrelatePairingCodeTransformImpl: correlatePairingCodeTransformImplType =
  (response: correlatePairingCodeResponseType) => {
    const { status, statusText } = response;
    return {
      status: (status || getStatus) as any,
      statusText: (statusText || getStatusText) as any,
    };
  };

export const CorrelatePairingCodeServiceImpl: CorrelatePairingCodeServiceImplType =
  (load, transform) => (accessToken, apiKey, query, fhirID) => {
    return load(accessToken, apiKey, query, fhirID).then(transform);
  };
