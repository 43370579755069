import { createSelector } from 'reselect';
import { path, map } from 'ramda';
import { frequencyToDropdownOption } from './frequencies.transforms';

const selectFrequencies = path(['prescription', 'frequencies']);

export const selectFrequencyDropdownOptions = createSelector(
  selectFrequencies,
  map(frequencyToDropdownOption),
);
