import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';

import {
  SendInvitationActionType,
  SendInvitationStartAction,
  SendInvitationSuccessPayload,
  SendInvitationActions,
} from './send-invitation.actions.types';
import { InvitePatientLoaderImplType } from 'src/services/rdac/invite-patient/invite-patient.types';
import {
  sendInvitationError,
  sendInvitationSuccess,
} from './send-invitation.actions';

export const sendInvitationtEpic: (
  invitePatientService: InvitePatientLoaderImplType,
) => Epic<SendInvitationActions, State> =
  (invitePatientService) => (action$, store$) =>
    action$
      .ofType(SendInvitationActionType.SEND_INVITATION_START)
      .flatMap(({ payload }: SendInvitationStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        const gigyaToken = selectGigyaToken(store$.getState());
        return Observable.fromPromise(
          invitePatientService(
            accessToken,
            gigyaToken,
            payload.patientID,
            payload.email,
          ),
        )
          .switchMap((response: SendInvitationSuccessPayload) => {
            return [sendInvitationSuccess(response)];
          })
          .pipe(catchError((err) => Observable.of(sendInvitationError(err))));
      });
