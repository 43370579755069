import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withScrollEvent } from 'src/utils/with-scroll-event/with-scroll-event';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { BloodGlucoseGeneralStats } from './blood-glucose-general-stats.component';
import { BloodGlucoseGeneralStatsConnector } from './store/blood-glucose-general-stats.selector';

export const connectToBloodGlucoseStats = (component) =>
  compose(withRouter, connect(BloodGlucoseGeneralStatsConnector))(component);

export const BloodGlucoseGeneralStatsContainer = compose(
  connectToBloodGlucoseStats,
  withScrollEvent,
  withTranslation,
)(BloodGlucoseGeneralStats);
