export const PROFILE_TIMES_START = {
  1: {
    minutes: 0,
  },
  2: {
    minutes: 0,
  },
  3: {
    minutes: 0,
  },
  4: {
    minutes: 0,
  },
  5: {
    minutes: 0,
  },
  latestVal: 0,
  latestProfile: null,
  base: 0,
};
