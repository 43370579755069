import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import { ListHba1cActionType } from './list-values-hba1c.types';
import { Hba1cInfo } from './../list-values-hba1c.types';

export const fetchHba1cListStart = (fhirId: string): FixMe =>
  createPayloadAction(ListHba1cActionType.FETCH_HBA1C_LIST_START, {
    fhirId,
  });

export const fetchHba1cListSuccess = (hba1cListInfo: Hba1cInfo[]): FixMe =>
  createPayloadAction(
    ListHba1cActionType.FETCH_HBA1C_LIST_SUCCESS,
    hba1cListInfo,
  );

export const fetchHba1cListError = (): FixMe =>
  createAction(ListHba1cActionType.FETCH_HBA1C_LIST_ERROR);
