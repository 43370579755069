import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { BG_UNIT_KEYS } from 'src/domains/diagnostics/store/constants';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

import {
  ContainerDiv,
  ColorIndicatorSpan,
  HintIconCircle,
  HintRowDiv,
  NumOfTestsRowDiv,
  NumOfTestsSpan,
  NumOfTestsTitleSpan,
  StatColumnDiv,
  StatDiv,
  StatNumSpan,
  StatTitleDiv,
  StatUnitSpan,
  TopBarDiv,
} from './trend-tool-tip.style';

export const TrendToolTipComponent = ({
  bloodGlucoseUnit,
  max,
  min,
  mean,
  numMeasurements,
  stDev,
  icon,
  meanColor,
  maxColor,
  minColor,
  t,
}: Props) => (
  <ContainerDiv>
    <TopBarDiv color={meanColor} />
    <NumOfTestsRowDiv>
      <NumOfTestsTitleSpan>
        {t('graphs.trendGraph.toolTip.numberOfTests')}
      </NumOfTestsTitleSpan>
      {icon && (
        <HintRowDiv>
          <HintIconCircle>{icon}</HintIconCircle>
        </HintRowDiv>
      )}
      <NumOfTestsSpan>{numMeasurements}</NumOfTestsSpan>
    </NumOfTestsRowDiv>
    <HintRowDiv>
      <StatColumnDiv>
        <StatDiv>
          <StatTitleDiv>
            {t('graphs.trendGraph.toolTip.highestResult')}
          </StatTitleDiv>
          <ColorIndicatorSpan color={maxColor} />
          <StatNumSpan>
            {formatBGMeasurement(bloodGlucoseUnit)(max)}
          </StatNumSpan>
          <StatUnitSpan>{t(BG_UNIT_KEYS[bloodGlucoseUnit])}</StatUnitSpan>
        </StatDiv>
        <StatDiv>
          <StatTitleDiv>
            {t('graphs.trendGraph.toolTip.standardDeviation')}
          </StatTitleDiv>
          <StatNumSpan>
            {formatBGMeasurement(bloodGlucoseUnit)(stDev)}
          </StatNumSpan>
          <StatUnitSpan>{t(BG_UNIT_KEYS[bloodGlucoseUnit])}</StatUnitSpan>
        </StatDiv>
      </StatColumnDiv>
      <StatColumnDiv>
        <StatDiv>
          <StatTitleDiv>
            {t('graphs.trendGraph.toolTip.lowestResult')}
          </StatTitleDiv>
          <ColorIndicatorSpan color={minColor} />
          <StatNumSpan>
            {formatBGMeasurement(bloodGlucoseUnit)(min)}
          </StatNumSpan>
          <StatUnitSpan>{t(BG_UNIT_KEYS[bloodGlucoseUnit])}</StatUnitSpan>
        </StatDiv>
        <StatDiv>
          <StatTitleDiv>{t('graphs.trendGraph.toolTip.mean')}</StatTitleDiv>
          <ColorIndicatorSpan color={meanColor} />
          <StatNumSpan>
            {formatBGMeasurement(bloodGlucoseUnit)(mean)}
          </StatNumSpan>
          <StatUnitSpan>{t(BG_UNIT_KEYS[bloodGlucoseUnit])}</StatUnitSpan>
        </StatDiv>
      </StatColumnDiv>
    </HintRowDiv>
  </ContainerDiv>
);

export const TrendToolTip = withTranslation(TrendToolTipComponent);
