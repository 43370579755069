import { translate } from '../../../i18n';

export const stripModelToDropdownOption = ({
  stripModelReference,
  stripModelName,
  stripModelPackageUnits,
}) => ({
  value: stripModelReference,
  label: `${stripModelName} (${stripModelPackageUnits} ${translate(
    stripModelPackageUnits > 1 ? 'prescription.tests' : 'prescription.test',
  )})`,
});
