/* tslint:disable */

import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { DeviceSettingsLoaderImplType } from './device-settings.types';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const DeviceSettingsLoaderImpl: DeviceSettingsLoaderImplType = (
  { patientId, deviceSerialNumber, patientType, includePrevious, version },
  accessToken: string,
) => {
  return getJSON(
    endpointWithParams(ENDPOINTS.deviceSettings, {
      version,
      deviceSerialNumber,
      patientId,
      patientType,
      includePrevious,
    }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const DeviceSettingsTransformImpl: any = (data) => data;

export const DeviceSettingsServiceImpl: any =
  (load, transform) => (params, accessToken, gigyaToken) =>
    load(params, accessToken, gigyaToken).then(transform);
