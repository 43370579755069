/* tslint:disable */

import * as React from 'react';
import { TablePrimaryCell } from '../table-primary-cell/table-primary-cell.component';
import { StyledTablePrimaryRow } from './table-primary-row.style';

export const TablePrimaryRow = ({
  cellList,
  colSpan = 1,
  rowStyles,
  cellStyles,
  id,
}: any) => (
  <StyledTablePrimaryRow {...rowStyles} key={id}>
    {cellList.map((cell, key) => (
      <TablePrimaryCell
        value={cell.value}
        valueHighlighted={cell.valueHighlighted}
        icon={cell.icon}
        colSpan={cell.colSpan || colSpan}
        highlight={cell.highlight}
        disabled={cell.disabled}
        unit={cell.unit}
        key={key}
        cellWidth={cell.cellWidth}
        {...cellStyles}
      />
    ))}
  </StyledTablePrimaryRow>
);
