import {
  EMPTY_VALUE_PLACEHOLDER,
  NO_DATA_EMPTY_PLACEHOLDER,
} from 'src/domains/diagnostics/store/constants';

export const zeroCheck = (value) =>
  value === 0 || parseInt(value, 10) === 0 ? EMPTY_VALUE_PLACEHOLDER : value;

export const mealsZeroCheck = (value) =>
  value === 0 || parseInt(value, 10) === 0 ? NO_DATA_EMPTY_PLACEHOLDER : value;
