import { UNITS } from '../../device-settings.utils';

export const getLabelFromAttribute = (attr) => {
  const labels = {
    Enabled: 'deviceSettings.tabBasalBolus.bolusAdviceTurnedOn',
    ActiveTime: 'deviceSettings.tabBasalBolus.activeTime',
    Offset: 'deviceSettings.tabBasalBolus.offsetTime',
    MealRize: 'deviceSettings.tabBasalBolus.mealRise',
    SnackLimit: 'deviceSettings.tabBasalBolus.snackSize',
    Exercise1: 'deviceSettings.tabBasalBolus.exercise1',
    Exercise2: 'deviceSettings.tabBasalBolus.exercise2',
    Stress: 'deviceSettings.tabBasalBolus.stress',
    Illness: 'deviceSettings.tabBasalBolus.illness',
    Premenstrual: 'deviceSettings.tabBasalBolus.premenstrual',
    Time: 'deviceSettings.tabBasalBolus.time',
    H: 'H',
    HOURS_SMALL: 'h',
    Units: 'U',
    Day: 'graphs.insulin.day',
    // basal
  };
  return labels[attr];
};

export const getUnitFromAttribute = (attr, units = {}) => {
  const labels = {
    ActiveTime: UNITS.MINUTES,
    Offset: UNITS.MINUTES,
    MealRize: units[UNITS.GLUCO] || UNITS.BGUNIT,
    SnackLimit: UNITS.CARBUNIT,
    Exercise1: UNITS.PERCENTAGE,
    Exercise2: UNITS.PERCENTAGE,
    Stress: UNITS.PERCENTAGE,
    Illness: UNITS.PERCENTAGE,
    Premenstrual: UNITS.PERCENTAGE,
    Adjustment: UNITS.PERCENTAGE,
    Duration: UNITS.MINUTES,
  };
  return labels[attr];
};
