import React from 'react';

import { LogbookMealTimeCell } from 'src/domains/diagnostics/components/table/logbook-meal-time-cell/logbook-meal-time-cell.component';
import {
  MEAL_TIMES,
  LOGBOOK_MODAL_WIDTH,
} from '../../../constants/logbook.constants';

export const LogbookMealTimesRow = ({
  bloodGlucoseUnit,
  day,
  mealTimes,
  numberOfRows,
  onPointMouseOver,
  onPointMouseOut,
}) =>
  Object.keys(mealTimes).map((mealTime, index) => {
    const {
      hasBeforeAndAfterIntervals,
      measurements,
      numberOfRowsWithContent,
    } = mealTimes[mealTime];

    const cellMouseOverHandler = (mouseEvent) => {
      const data = {
        mealTimeData: mealTimes[mealTime],
        mealTime,
      };
      onPointMouseOver(mouseEvent, data, LOGBOOK_MODAL_WIDTH);
    };

    return (
      <LogbookMealTimeCell
        alternateFill={index === 0}
        bloodGlucoseUnit={bloodGlucoseUnit}
        key={`day row - ${day} - ${mealTime}`}
        day={day}
        hasBeforeAndAfterIntervals={hasBeforeAndAfterIntervals}
        mealTimeMatrix={measurements}
        numberOfRowsWithContent={numberOfRowsWithContent}
        numberOfTotalRows={numberOfRows}
        width={
          mealTime === MEAL_TIMES.NIGHT || mealTime === MEAL_TIMES.BEDTIME
            ? '10.5rem'
            : '17.5rem'
        }
        onPointMouseOver={cellMouseOverHandler}
        onPointMouseOut={onPointMouseOut}
      />
    );
  });
