import {
  CheckHCIDLoaderImpl,
  CheckHCIDServiceImpl,
  CheckHCIDTransformImpl,
} from './check-hcid.service';

import { CheckHCIDResponseType } from './check-hcid.types';
export const mockLoader = (): Promise<CheckHCIDResponseType> =>
  Promise.resolve({
    GigyaID: '',
    UserID: '',
    UserRole: '',
    PWDID: '',
    HCPID: '',
    DepartmentId: '',
    CenterId: '',
    EMail: '',
    PROFESSIONAL_NUMBER: '',
    HEALTH_CARE_SYSTEM_ID: '',
    CN: '',
    EMRID: '',
    Name: '',
    Surname: '',
    CenterName: '',
    Email: '',
    FHIRID: '',
    PROFESSIIONAL_NUMBER: '',
  });

export const CheckHCIDFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : CheckHCIDLoaderImpl;
  return CheckHCIDServiceImpl(loader, CheckHCIDTransformImpl);
};
