import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import {
  CGMSummaryLoaderImplType,
  CGMSummaryTransformImplType,
  CGMSummaryServiceImplType,
  CGMSummaryResponse,
} from './cgm-summary.types';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';
import { simpleDateToISOString } from 'src/utils/date';

export const CGMSummaryLoaderImpl: CGMSummaryLoaderImplType = (
  { fhirId, hasRoleFhir },
  accessToken,
  ApiKey,
) => {
  const endpoint = hasRoleFhir
    ? ENDPOINTS.getSummaryCgmFhir
    : ENDPOINTS.getSummaryCgm;

  const pathParameters = {
    ...(!hasRoleFhir && { fhirId }),
  };

  const headers = {
    Authorization: createAuthHeader(accessToken),
    ApiKey,
    ...(hasRoleFhir && { id: fhirId }),
  };

  return getJSON(endpointWithParams(endpoint, pathParameters), {
    headers,
  });
};

export const CGMSummaryTransformImpl: CGMSummaryTransformImplType = (
  data: CGMSummaryResponse,
) => {
  let firstMeasurementDate: string | null;
  let lastMeasurementDate: string | null;

  if (
    data.hasRoleFhir &&
    data.model.firstMeasurement !== null &&
    data.model.latestMeasurement !== null
  ) {
    firstMeasurementDate = simpleDateToISOString(data.model.firstMeasurement);
    lastMeasurementDate = simpleDateToISOString(data.model.latestMeasurement);
  } else {
    firstMeasurementDate = data.firstMeasurementDate;
    lastMeasurementDate = data.lastMeasurementDate;
  }

  return {
    startDate: '',
    endDate: '',
    firstMeasurementDate,
    lastMeasurementDate,
  };
};

export const CGMSummaryServiceImpl: CGMSummaryServiceImplType =
  (load: CGMSummaryLoaderImplType, transform: CGMSummaryTransformImplType) =>
  (query, accessToken, ApiKey) =>
    load(query, accessToken, ApiKey).then((data) =>
      transform({ ...data, hasRoleFhir: query.hasRoleFhir }),
    );
