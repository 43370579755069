export default [
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-11-22T08:49:07.228Z',
    hcpId: 94918,
    lastStock: 136,
    organizationId: 6901,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'delivery',
    comment: null,
    createdAt: '2019-11-22T08:48:54.651Z',
    hcpId: 94918,
    lastStock: 136,
    organizationId: 6901,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-05-16T19:35:48.854Z',
    hcpId: 120635,
    lastStock: 136,
    organizationId: null,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-05-16T19:35:32.294Z',
    hcpId: 120635,
    lastStock: 136,
    organizationId: null,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-04-02T11:26:19.266Z',
    hcpId: 162956,
    lastStock: 37,
    organizationId: null,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-04-02T11:25:15.761Z',
    hcpId: 29644,
    lastStock: 37,
    organizationId: null,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-04-02T11:24:47.514Z',
    hcpId: 29644,
    lastStock: 37,
    organizationId: null,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-04-02T11:22:52.945Z',
    hcpId: 162956,
    lastStock: 37,
    organizationId: null,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-04-02T11:18:09.491Z',
    hcpId: 29644,
    lastStock: 100,
    organizationId: null,
    patientId: 182237,
    stockChange: -63,
    stripModelReference: '06453970037',
  },
  {
    action: 'delivery',
    comment: null,
    createdAt: '2019-04-02T10:42:47.674Z',
    hcpId: 29644,
    lastStock: 0,
    organizationId: null,
    patientId: 182237,
    stockChange: 100,
    stripModelReference: '06453970037',
  },
  {
    action: 'admin adjustment',
    comment: null,
    createdAt: '2019-04-02T10:42:26.275Z',
    hcpId: 29644,
    lastStock: 0,
    organizationId: null,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'delivery',
    comment: null,
    createdAt: '2019-05-02T18:58:17.037Z',
    hcpId: 184117,
    lastStock: 36,
    organizationId: null,
    patientId: 182237,
    stockChange: 100,
    stripModelReference: '06453970037',
  },
  {
    action: 'consumption report',
    comment: null,
    createdAt: '2019-05-02T18:57:41.302Z',
    hcpId: 184117,
    lastStock: 36,
    organizationId: null,
    patientId: 182237,
    stockChange: 1,
    stripModelReference: '06453970037',
  },
  {
    action: 'lost strips report',
    comment: null,
    createdAt: '2019-05-02T18:56:38.294Z',
    hcpId: 120635,
    lastStock: 37,
    organizationId: null,
    patientId: 182237,
    stockChange: -1,
    stripModelReference: '06453970037',
  },
];
