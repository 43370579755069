import React, { useEffect } from 'react';
import { CountlySystem, getConfig } from '@roche/roche-common';

export const CountlyLoader: React.FunctionComponent<{
  permissions: any;
  gigyaUID: any;
}> = ({ permissions, gigyaUID }) => {
  const {
    REACT_APP_COUNTLY_APP_KEY,
    REACT_APP_COUNTLY_URL,
    REACT_APP_VERSION,
    REACT_APP_COUNTLY_IGNORE_BOTS,
    REACT_APP_COUNTLY_IS_ENABLED,
  } = getConfig();

  const applyRegexToURL = () => {
    let view = location.pathname;
    const regUUID = new RegExp(
      /[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}/,
    );
    view = view.replace(regUUID, '');
    const reg = new RegExp(/(\d+)/, 'g');
    view = view.replace(reg, '');
    const slashReg = new RegExp(/\/{2}/g);
    view = view.replace(slashReg, '/');
    return view;
  };

  const loadCountlyScript = () => {
    if (!REACT_APP_COUNTLY_URL && !REACT_APP_COUNTLY_APP_KEY) {
      return;
    }

    if (!document.getElementById('cly-snippet')) {
      const SHA256 = require('crypto-js/sha256');
      const gigyaDigest = SHA256(gigyaUID).toString();
      const prefix = `rdcp-client/${REACT_APP_COUNTLY_APP_KEY}`;
      const cly = document.createElement('script');
      localStorage.removeItem(`${prefix}/cly_id`);
      localStorage.removeItem(`${prefix}/cly_event`);
      localStorage.removeItem(`${prefix}/cly_queue`);

      cly.setAttribute('defer', 'defer');
      cly.setAttribute(
        'src',
        `${REACT_APP_COUNTLY_URL}/sdk/web/countly.min.js`,
      );
      cly.setAttribute('type', 'text/javascript');
      cly.setAttribute('id', 'cly-snippet');

      document.head.appendChild(cly);

      cly.onload = () => {
        const countly = new CountlySystem(window.Countly);
        countly.init({
          appKey: REACT_APP_COUNTLY_APP_KEY,
          url: REACT_APP_COUNTLY_URL,
          appVersion: REACT_APP_VERSION,
          nameSpace: 'rdcp-client',
          device_id: gigyaDigest,
          ignoreBots: REACT_APP_COUNTLY_IGNORE_BOTS === 'true',
        });
        countly.getViewUrl(applyRegexToURL);
        countly.getViewName(applyRegexToURL);
        countly.trackSessions();
        countly.trackPageview();
      };
    }
  };

  useEffect(() => {
    if (
      permissions.length > 0 &&
      REACT_APP_COUNTLY_IS_ENABLED &&
      REACT_APP_COUNTLY_IS_ENABLED === 'true'
    ) {
      loadCountlyScript();
    }
  }, [permissions]);
  return <></>;
};
