import { MOCKED_BLOCKS } from './time-blocks.mocks';

import {
  GetTimeBlocksLoaderImpl,
  GetTimeBlocksServiceImpl,
  GetTimeBlocksTransformImpl,
} from './time-blocks.service';

const mockLoader = (): Promise<any> => Promise.resolve(MOCKED_BLOCKS);

export const GetTimeBlocksFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : GetTimeBlocksLoaderImpl;
  return GetTimeBlocksServiceImpl(loader, GetTimeBlocksTransformImpl);
};
