/* tslint:disable */
/* eslint:disable */
import { FETCH_DEVICE_SETTINGS_LIST_REQUEST } from './device-settings-list.constant';
import { FetchStatus } from './device-settings-list.types';

export const INITIAL_DEVICE_SETTINGS_STATE: any = {
  status: FetchStatus.NONE,
  settings: [],
  error: '',
};

export const deviceSettingsListReducer = (
  state = INITIAL_DEVICE_SETTINGS_STATE,
  action: any,
): any => {
  switch (action.type) {
    case FETCH_DEVICE_SETTINGS_LIST_REQUEST.START: {
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    }

    case FETCH_DEVICE_SETTINGS_LIST_REQUEST.SUCCESS: {
      return {
        ...state,
        settings: action.payload,
        status: FetchStatus.SUCCESS,
      };
    }

    case FETCH_DEVICE_SETTINGS_LIST_REQUEST.ERROR:
      return {
        ...state,
        error: action.payload,
        settings: [],
        status: FetchStatus.FAILURE,
      };

    default:
      return state;
  }
};
