export const EDIT_PROFILE_INITIAL_STATE = {
  birthday: '',
  dateOfBirthMonth: '',
  dateOfBirthDay: '',
  dateOfBirthYear: '',
  countryId: '',
  departmentId: '',
  originalEmail: '',
  email: '',
  id: '',
  languageId: '',
  name: '',
  phone: '',
  profileId: '',
  surname: '',
  surname2: '',
  editHasError: false,
  editWasSuccessful: false,
  openConnectivity: '',
};
