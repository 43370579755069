import { PUMP_ICONS } from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.constant';
import {
  PumpPauseShape,
  PumpRunShape,
  PumpStopShape,
} from 'src/domains/diagnostics/components/graph/point-shapes.component';

export const HOURS_IN_DAY = 24;
export const TEN_HOURS_NORMALIZED = 10 / HOURS_IN_DAY;
export const TWO_HOURS_IN_MINUTES = 120;

export const PUMP_START_STOP_BASAL_REMARKS = {
  RUN: 'Run',
  PAUSE: 'Pause',
  STOP: 'Stop',
};

export const pumpEventYValues = {
  [PUMP_START_STOP_BASAL_REMARKS.RUN]: 0.6,
  [PUMP_START_STOP_BASAL_REMARKS.PAUSE]: 0.65,
  [PUMP_START_STOP_BASAL_REMARKS.STOP]: 0.7,
};

export const pumpStartStopIconContentMap = {
  [PUMP_ICONS.RUN]: PumpRunShape,
  [PUMP_ICONS.STOP]: PumpStopShape,
  [PUMP_ICONS.PAUSE]: PumpPauseShape,
};

export const PUMP_EVENT_DURATION_UNITS = {
  MINS: 'mins',
  HOURS: 'hours',
};
