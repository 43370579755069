import styled from 'styled-components';

export const SVGContainer = styled.div`
  display: inline-block;
  text-align: center;
  margin-right: ${({ theme }) => theme.spacing.two};
`;
SVGContainer.displayName = 'SVGContainer';

export const Item = styled.li`
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};
  padding: ${({ theme }) => theme.spacing.one} 0;
  display: flex;
  justify-content: flex-start;
  &:first-child: {
    flex: 0 0 auto;
  }
  align-items: center;
`;
Item.displayName = 'Item';
