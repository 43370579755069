export const mockEditEMRPatientResponse = {
  resultDescription: 'createPatientOK',
  model:
    'https://univ-uploader-dev.rochedc.accentureanalytics.com/eConecta/rest/api/professional/1/patients/1',
};

export const mockEditEMRPatientError = {
  resultDescription: 'WRONG_TIME_ZONE',
  error: [
    {
      error: 'The selected timeZone is incorrect: 123',
    },
  ],
};
