import { GET_CURRENT_USER } from 'src/core/user/user.constants';

import {
  UserUnitsActionTypes,
  UserUnitMeasurementState,
  UserUnitsActions,
} from './user-units-selector.types';

export const UserMeasurementUnitsState: UserUnitMeasurementState = {
  isFetching: false,
  successUserUnitMeasure: false,
  errorSaveUserUnitMeasure: false,
  getUnitSuccess: false,
  getUserUnitsError: false,
  allUserMeasurements: [],
};

export const UserUnitsReducer = (
  state = UserMeasurementUnitsState,
  action: UserUnitsActions,
): UserUnitMeasurementState => {
  switch (action.type) {
    case UserUnitsActionTypes.USER_UNITS_SAVE_START:
    case GET_CURRENT_USER.START:
    case UserUnitsActionTypes.USER_UNITS_GET_START:
      return {
        ...state,
        isFetching: true,
      };
    case UserUnitsActionTypes.USER_UNITS_SAVE_SUCCESS:
      return {
        ...state,
        successUserUnitMeasure: true,
        errorSaveUserUnitMeasure: false,
        isFetching: false,
      };
    case GET_CURRENT_USER.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case UserUnitsActionTypes.USER_UNITS_SAVE_ERROR:
      return {
        ...state,
        successUserUnitMeasure: false,
        errorSaveUserUnitMeasure: true,
        isFetching: false,
      };
    case UserUnitsActionTypes.USER_UNITS_GET_ERROR:
      return {
        ...state,
        isFetching: true,
        getUserUnitsError: true,
        getUnitSuccess: false,
      };
    case UserUnitsActionTypes.USER_UNITS_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        getUserUnitsError: false,
        getUnitSuccess: true,
        allUserMeasurements: action.payload,
      };
    case UserUnitsActionTypes.USER_UNITS_CLEAR:
      return {
        ...state,
        successUserUnitMeasure: false,
        errorSaveUserUnitMeasure: false,
        isFetching: false,
      };
    default:
      return state;
  }
};
