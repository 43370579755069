import { CheckDataSharingConsentResponseType } from './check-data-sharing-consent.types';
import { checkDataSharingConsentMockresponse } from './check-data-sharing-consent.mock';
import {
  CheckDataSharingConsentLoaderImpl,
  CheckDataSharingConsentServiceImpl,
  CheckDataSharingConsentTransformImpl,
} from './check-data-sharing-consent.service';

export const mockLoaderDataSharing =
  (): Promise<CheckDataSharingConsentResponseType> =>
    Promise.resolve(checkDataSharingConsentMockresponse);

export const CheckDataSharingConsentFactoryImp = ({ devMode }) => {
  const loader = devMode
    ? mockLoaderDataSharing
    : CheckDataSharingConsentLoaderImpl;
  return CheckDataSharingConsentServiceImpl(
    loader,
    CheckDataSharingConsentTransformImpl,
  );
};
