import React from 'react';
import { withTheme } from 'styled-components';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { GraphSvgIcon, SvgIconSpan } from 'src/components/icon/icon.component';

const PumpTbrincIconComponent = ({
  theme,
  height,
  width,
  x,
  y,
  isGraphIcon = true,
  t,
  cursor,
  opacity,
  style,
  onMouseMove,
  onMouseOut,
  onClick,
}) => {
  const originalWidth = 17;
  const originalHeight = 17;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  const iconTitle = t('graphs.iconTitles.pumpTbrInc');

  const coreIcon = (
    <GraphSvgIcon
      title={iconTitle}
      width={calculatedWidth}
      height={calculatedHeight}
      {...{ x, y, originalWidth, originalHeight }}
      titleNotToBeRendered={onMouseMove && true}
    >
      <g
        fill="none"
        fillRule="evenodd"
        cursor={cursor}
        opacity={opacity}
        style={style}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        onClick={onClick}
      >
        <rect
          fill={theme.colors.tbrBlue}
          x=".911"
          y=".775"
          width="16"
          height="16"
          rx="1"
        />
        <path
          d="M12.516 11.176a.75.75 0 0 1-.555-.245L8.907 7.579l-3.045 3.35a.75.75 0 1 1-1.11-1.007L8.905 5.35l4.166 4.57a.75.75 0 0 1-.555 1.255"
          fill={theme.colors.white}
        />
      </g>
    </GraphSvgIcon>
  );

  return !isGraphIcon ? SvgIconSpan(iconTitle, coreIcon) : coreIcon;
};

export const PumpTbrincIcon = withTranslation(
  withTheme(PumpTbrincIconComponent),
);
