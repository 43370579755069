import { path, pathOr, propOr } from 'ramda';
import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import { CIAM_SDK_NAMESPACE } from '../../constants';

export const selectConfig = (state: State) =>
  path([CIAM_SDK_NAMESPACE, 'config'], state);

export const selectGigyaToken = createSelector([selectConfig], (config) =>
  propOr('', 'gigyaToken', config),
);

export const selectAccessEndpoint = createSelector([selectConfig], (config) =>
  propOr('', 'accessEndpoint', config),
);

export const selectRedirectUri = createSelector([selectConfig], (config) =>
  propOr('', 'redirectUri', config),
);

export const selectApiGateway = createSelector([selectConfig], (config) =>
  propOr('', 'apiGateway', config),
);

export const selectOidcClientId = createSelector([selectConfig], (config) =>
  propOr('', 'oidcClientId', config),
);

export const selectHcpFullname = createSelector([selectConfig], (config) =>
  pathOr('', ['user', 'fullname'], config),
);

export const selectHcpId = createSelector([selectConfig], (config) =>
  pathOr('', ['user', 'id'], config),
);

export const selectClientSecret = createSelector([selectConfig], (config) =>
  propOr('', 'clientSecret', config),
);

export const selectUID = createSelector([selectConfig], (config) =>
  propOr('', 'gigyaUid', config),
);
