import styled from 'styled-components';

import { spacing } from 'src/core/styles/spacing';
import { fontSize } from 'src/core/styles/font-sizes';
import { colors } from 'src/core/styles/colors';
import { convertPxToRem } from 'src/utils/rem-calc';

import {
  MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM,
  COLLAPSED_STD_GRAPH_HEIGHT_IN_REM,
} from '../../graph.constants';

export const CardSectionTitleDiv = styled.div`
  font-size: ${fontSize.headline};
  color: ${colors.charcoal};
  padding-left: ${spacing.four};
  margin-bottom: ${convertPxToRem(-5)};
  display: flex;
  justify-content: flex-start;
  padding: 0 ${convertPxToRem(330)} 0 ${convertPxToRem(50)};
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

export const MetabolicWrapperDiv = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  min-height: ${(props) =>
    props.collapsed
      ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM
      : MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM};
  height: ${(props) =>
    props.collapsed ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM : '100%'};
  margin: 0 ${spacing.two} 0;
  flex-direction: column;
`;

MetabolicWrapperDiv.displayName = 'MetabolicWrapperDiv';
