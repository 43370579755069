import * as React from 'react';
import {
  LineGraphIcon,
  BoxGraphIcon,
  LogbookIcon,
  LogbookDiaryIcon,
  LogbookStatsIcon,
  Logbook24HourIcon,
} from 'src/assets/icons';

import {
  GRAPH_TYPE_DETAILS,
  GRAPH_TYPE_TREND,
  LOGBOOK_TYPE_DETAILS,
  LOGBOOK_TYPE_DIARY,
  LOGBOOK_TYPE_STATS,
  LOGBOOK_TYPE_24HOUR,
  DEVICE_SETTINGS_TYPE_BASAL_BOLUS,
  DEVICE_SETTINGS_TYPE_GENERAL,
  DEVICE_SETTINGS_TYPE_REMINDERS,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { colors } from 'src/core/styles/colors';

export const prepareListOfDeviceSettingsTabs = () => [
  { type: DEVICE_SETTINGS_TYPE_BASAL_BOLUS, text: 'Basal / Bolus' },
  { type: DEVICE_SETTINGS_TYPE_GENERAL, text: 'General' },
  { type: DEVICE_SETTINGS_TYPE_REMINDERS, text: 'Reminders' },
];

export const prepareListOfLogbookTabs = (graphType) => [
  {
    type: LOGBOOK_TYPE_24HOUR,
    icon: prepareIcon(Logbook24HourIcon, graphType, LOGBOOK_TYPE_24HOUR),
  },
  {
    type: LOGBOOK_TYPE_DETAILS,
    icon: prepareIcon(LogbookIcon, graphType, LOGBOOK_TYPE_DETAILS),
  },
  {
    type: LOGBOOK_TYPE_DIARY,
    icon: prepareIcon(LogbookDiaryIcon, graphType, LOGBOOK_TYPE_DIARY),
  },
  {
    type: LOGBOOK_TYPE_STATS,
    icon: prepareIcon(LogbookStatsIcon, graphType, LOGBOOK_TYPE_STATS),
  },
];

export const prepareListOfGraphTabs = (graphType) => [
  {
    type: GRAPH_TYPE_DETAILS,
    icon: prepareIcon(LineGraphIcon, graphType, GRAPH_TYPE_DETAILS),
  },
  {
    type: GRAPH_TYPE_TREND,
    icon: prepareIcon(BoxGraphIcon, graphType, GRAPH_TYPE_TREND),
  },
];

const prepareIcon = (icon, graphType, graphConst) =>
  updateIconProperties(icon, graphType === graphConst, 18);

const updateIconProperties = (icon, isActive, height) => {
  const Icon = icon;
  return <Icon fillColor={getIconFillColour(isActive)} height={height} />;
};

const getIconFillColour = (isActive: any) =>
  isActive ? colors.brandBlue : colors.grayDark;
