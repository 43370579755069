import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';
import { endpointWithParams } from '@roche/roche-common';

import {
  SaveUserUnitsLoaderImplType,
  SaveUserUnitsParams,
  SaveUserUnitsServiceImplType,
} from './save-user-units.types';

import { ENDPOINTS } from 'src/services/service.constants';
import { OpenId } from 'src/services/mysugr-status/mysugr-status.types';

export const saveUserUnitLoaderImpl: SaveUserUnitsLoaderImplType = (
  openId: OpenId,
  params: SaveUserUnitsParams,
) => {
  return postJSON(endpointWithParams(ENDPOINTS.saveUserUnits, {}), {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
    body: stringifyBody(params),
  });
};

export const saveUserUnitTransformImpl = (result) => result;

export const saveUserUnitServiceImpl: SaveUserUnitsServiceImplType =
  (load: SaveUserUnitsLoaderImplType, transform) => (openId, params) =>
    load(openId, params).then(transform);
