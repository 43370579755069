import {
  GetPatientInfoLoaderImpl,
  GetPatientInfoServiceImpl,
  GetPatientInfoTransformImpl,
} from './patient-info.service';

import { mockGetPatientInfoResponse } from './patient-info.mock';

const mockLoader = () => Promise.resolve(mockGetPatientInfoResponse);

export const GetPatientInfoFactoryImpl = ({ devMode }) => {
  const load = devMode ? mockLoader : GetPatientInfoLoaderImpl;
  const transform = GetPatientInfoTransformImpl;
  return GetPatientInfoServiceImpl(load, transform);
};
