import { dissoc } from 'ramda';
import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import {
  GetDeviceAssociationServiceFactoryType,
  GetDeviceAssociationServiceType,
  GetDeviceAssociationTransform,
} from './get-device-association.types';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const GetDeviceAssociationLoaderImpl: GetDeviceAssociationServiceType = (
  { associationId }: any,
  accessToken: string,
) => {
  return getJSON(
    endpointWithParams(ENDPOINTS.getDeviceAssociation, { associationId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const GetDeviceAssociationTransformImpl: GetDeviceAssociationTransform =
  (data) => ({
    patientId: data.model.patientId,
    deviceInfo: dissoc('patientId', data.model),
  });

export const GetDeviceAssociationServiceFactoryImpl: GetDeviceAssociationServiceFactoryType =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);
