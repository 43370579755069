import * as React from 'react';
import { isEmpty } from 'ramda';

import { TablePrimary } from 'src/domains/diagnostics/components/table-primary/table-primary.component';
import { RenderIf } from 'src/utils/render-if';

import { RightColumnGeneral, LeftColumnGeneral } from './tab-reminders.style';
import { GenerateTable } from './tab-general.utils';
import {
  getUnitFromAttribute,
  getLabelFromAttribute,
} from './tab-genera.constants';

import {
  getRowStyledProps,
  getCellStyledProps,
} from '../../device-settings.utils';
import { FlexContainer } from '../device-settings-header/flex-grid/flex-grid.style';
import { Disclaimer } from '../disclaimer/disclaimer.component';

export interface Props {
  meterSettings: any;
  insulinDeliverySettings: any;
  bolusDeliverySettings: any;
  previousInsulinDeliverySettings: any;
  previousBolusDeliverySettings: any;
  previousMeterSettings: any;
  comparePreviousSettings: any;
}
export const TabGeneralComponent: React.FunctionComponent<any> = ({
  meterSettings,
  insulinDeliverySettings,
  bolusDeliverySettings,
  previousInsulinDeliverySettings,
  previousBolusDeliverySettings,
  previousMeterSettings,
  t,
  isPreviousSettingsSelected,
  comparePreviousSettings,
}) => {
  const showPreviousValue = isPreviousSettingsSelected;
  const TableGenerator = new GenerateTable({
    getLabelFromAttribute,
    getUnitFromAttribute,
    units: meterSettings,
    showPreviousValue,
    comparePreviousValue: comparePreviousSettings,
    t,
  });

  const insulinDeliveryBody: any = TableGenerator.getBodyTable(
    insulinDeliverySettings,
    previousInsulinDeliverySettings,
  );
  const meterSettingsBody: any = TableGenerator.getBodyTable(
    meterSettings,
    previousMeterSettings,
  );
  const bolusDeliveryBody: any = TableGenerator.getBodyTable(
    bolusDeliverySettings,
    previousBolusDeliverySettings,
  );

  const insulinDeliveryHeader: any = TableGenerator.getHeaderTable([
    [{ colSpan: 2, value: t('deviceSettings.tabGeneral.insulinDelivery') }],
  ]);
  const meterSettingsHeader: any = TableGenerator.getHeaderTable([
    [{ colSpan: 2, value: t('deviceSettings.tabGeneral.meterSettings') }],
  ]);
  const bolusDeliveryHeader: any = TableGenerator.getHeaderTable([
    [{ colSpan: 2, value: t('deviceSettings.tabGeneral.bolusDelivery') }],
  ]);

  return (
    <FlexContainer direction="column">
      <FlexContainer width="100%">
        <LeftColumnGeneral grid="6">
          <FlexContainer width="100%" direction="column">
            <TablePrimary
              bodyData={insulinDeliveryBody}
              headerData={insulinDeliveryHeader}
              rowStyles={getRowStyledProps()}
              cellStyles={getCellStyledProps()}
            />
            <RenderIf validate={isEmpty(insulinDeliveryBody)}>
              <Disclaimer />
            </RenderIf>
          </FlexContainer>
        </LeftColumnGeneral>
        <RightColumnGeneral grid="6">
          <FlexContainer width="100%" direction="column">
            <TablePrimary
              bodyData={meterSettingsBody}
              headerData={meterSettingsHeader}
              rowStyles={getRowStyledProps()}
              cellStyles={getCellStyledProps()}
            />
            <RenderIf validate={isEmpty(meterSettingsBody)}>
              <Disclaimer />
            </RenderIf>
          </FlexContainer>
        </RightColumnGeneral>
      </FlexContainer>
      <FlexContainer width="100%">
        <LeftColumnGeneral grid="6" paddings={true}>
          <FlexContainer width="100%" direction="column">
            <TablePrimary
              bodyData={bolusDeliveryBody}
              headerData={bolusDeliveryHeader}
              rowStyles={getRowStyledProps()}
              cellStyles={getCellStyledProps()}
            />
            <RenderIf validate={isEmpty(meterSettingsBody)}>
              <Disclaimer />
            </RenderIf>
          </FlexContainer>
        </LeftColumnGeneral>
      </FlexContainer>
    </FlexContainer>
  );
};

export default TabGeneralComponent;
