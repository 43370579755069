import { flatten, head, isEmpty, path, sort } from 'ramda';

import {
  convertJSDate,
  convertJSDateGMT,
  isAfter,
  toFormat,
} from 'src/utils/date';

import { LOGBOOK_UNITS_UNIT } from '../../constants/logbook.constants';
import { toMeasurementIndicatorColor } from 'src/domains/diagnostics/widgets/logbook/graph/logbook.graph-utils';

export const getUnitsFromBeforeAndAfterMealTimeMeasurements = (measurement) => {
  const beforeRangeType = path(['before', 'rangeType'], measurement);
  const beforeGlucose = path(['before', 'glucose'], measurement);
  const beforeManuallyEntered = path(
    ['before', 'manuallyEntered'],
    measurement,
  );
  const beforeCarbohydrates = path(['before', 'carbohydrates'], measurement);
  const beforeBolus = path(['before', 'insulin', 'value'], measurement);

  const afterRangeType = path(['after', 'rangeType'], measurement);
  const afterGlucose = path(['after', 'glucose'], measurement);
  const afterManuallyEntered = path(['after', 'manuallyEntered'], measurement);
  const afterBolus = path(['after', 'insulin', 'value'], measurement);

  return [
    {
      type: 'glucose',
      value: beforeGlucose ?? beforeRangeType,
      manuallyEntered: beforeManuallyEntered,
    },
    { type: 'carbohydrates', value: beforeCarbohydrates },
    { type: 'bolus', value: beforeBolus },
    {
      type: 'glucose',
      value: afterGlucose ?? afterRangeType,
      manuallyEntered: afterManuallyEntered,
    },
    { type: 'bolus', value: afterBolus },
  ];
};

export const createMealTimeMatrix = (
  beforeMealTimeData: Array<LogbookMeasurement>,
  afterMealTimeData: Array<LogbookMeasurement>,
) => {
  const beforeDataWithChronologicalBeforeAfterFlag = beforeMealTimeData.map(
    (measurement) => ({
      ...measurement,
      chronologicallyBeforeMealTime: true,
    }),
  );
  const afterDataWithChronologicalBeforeAfterFlag = afterMealTimeData.map(
    (measurement) => ({
      ...measurement,
      chronologicallyBeforeMealTime: false,
    }),
  );

  const sortedMeasurements = sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    [
      ...beforeDataWithChronologicalBeforeAfterFlag,
      ...afterDataWithChronologicalBeforeAfterFlag,
    ],
  );

  const mealTimeMatrix = sortedMeasurements.reduce((acc, measurement) => {
    const { beforeMeal, afterMeal, chronologicallyBeforeMealTime } =
      measurement;
    if (beforeMeal || (!afterMeal && chronologicallyBeforeMealTime)) {
      return [
        ...acc,
        {
          before: measurement,
          after: {},
        },
      ];
    } else if (!acc.length || acc[acc.length - 1].after !== measurement) {
      return [
        ...acc,
        {
          before: {},
          after: measurement,
        },
      ];
    }
    return acc;
  }, []);

  return mealTimeMatrix;
};

export const getCombinedMealTimeName = (mealTime) => {
  const mealTimeHeaderMap = {
    NIGHT: 'general.mealBlocks.night',
    BEFORE_BREAKFAST: 'general.mealBlocks.breakfast',
    BEFORE_LUNCH: 'general.mealBlocks.lunch',
    BEFORE_DINNER: 'general.mealBlocks.dinner',
    BEDTIME: 'general.mealBlocks.bedTime',
  };

  return mealTimeHeaderMap[mealTime] || 'none';
};

export const getCombinedMealTimeDate = (mealTimeData) => {
  const first = head(mealTimeData.measurements);
  return mealTimeData.hasBeforeAndAfterIntervals
    ? !isEmpty(first.before)
      ? first.before.date
      : first.after.date
    : first.date;
};

export const flattenCombinedMeasurements = (combinedMeasurements) =>
  flatten(combinedMeasurements.map((m) => [m.before, m.after]));

export const toMealTimeModalMeasurements = (
  measurement,
  bloodGlucoseUnit,
  carbLabel,
) => ({
  glucose: {
    manuallyEntered: measurement.manuallyEntered,
    value: measurement.glucose ?? measurement.rangeType,
    unit: LOGBOOK_UNITS_UNIT[bloodGlucoseUnit],
  },
  bolus: {
    value: measurement.insulin && measurement.insulin.value,
    unit: LOGBOOK_UNITS_UNIT.BOLUS,
  },
  carbohydrates: {
    value: measurement.carbohydrates,
    unit: carbLabel,
  },
  time: toFormat('HH:mm')(convertJSDateGMT(measurement.date)),
  afterMeal: measurement.afterMeal,
  beforeMeal: measurement.beforeMeal,
  statusColor: toMeasurementIndicatorColor(measurement),
});

export const getMealTimeModalMeasurements = (
  mealTimeData,
  bloodGlucoseUnit,
  carbLabel,
) => {
  const measurements = mealTimeData.hasBeforeAndAfterIntervals
    ? flattenCombinedMeasurements(mealTimeData.measurements)
    : mealTimeData.measurements;

  const AscByTimeComparer = (a, b) =>
    isAfter(convertJSDate(a.time), convertJSDate(b.time));

  return measurements
    .filter((m) => !isEmpty(m))
    .map((m) => toMealTimeModalMeasurements(m, bloodGlucoseUnit, carbLabel))
    .sort(AscByTimeComparer);
};
