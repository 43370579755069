import styled, { css } from 'styled-components';
import { equals } from 'ramda';
import { TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS } from 'src/domains/diagnostics/widgets/logbook-24hrs/logbook-24hrs.constants';
import { CSSProperties } from 'node_modules/@types/react';

import { COLLAPSED_LOGBOOK24H_MIN_HEIGHT } from 'src/domains/diagnostics/utils/graph.constants';

import { setHeadersCSS } from './logbook-24hrs.util';

import { colors } from '../../../../core/styles/colors';
import { fontSize } from '../../../../core/styles/font-sizes';
import { fontWeights } from '../../../../core/styles/font-weights/font-weights';
import { spacing } from '../../../../core/styles/spacing';
import {
  GRAPHS,
  COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM,
} from '../../scenes/graphs/graph.constants';

type BorderTopVariant = {
  borderTop: boolean;
  highlight?: boolean;
};

type Logbook24hrsWrapperType = {
  blueBackground?: boolean;
  margin?: boolean;
  collapsed?: boolean;
  collapsedGP?: boolean;
};

type Logbook24hrsOuterWrapperType = {
  hasMeasurement?: boolean;
};

type Logbook24hrsLoaderAndGridType = {
  graph: string;
};

const isPrimary = equals(TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS.PRIMARY);

const STANDARD_ROW_HEIGHT = 40;

const tableBorder = (weight: number) => `${weight}px solid ${colors.grayLight}`;

export const Logbook24hrsInnerWrapper = styled.div<Logbook24hrsWrapperType>`
  height: 100%;
  background-color: ${(props) =>
    props.blueBackground ? colors.blueMarineAlpha5 : 'inherit'};
  margin: ${(props) => (props.collapsed ? '13px 0 0 10px' : '0')};
  height: ${(props) =>
    props.collapsed
      ? COLLAPSED_LOGBOOK24H_MIN_HEIGHT
      : props.collapsedGP
      ? COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM
      : 'inherit'};
  min-height: ${(props) =>
    props.collapsed ? COLLAPSED_LOGBOOK24H_MIN_HEIGHT : 'inherit'};
  overflow-y: auto;
`;

Logbook24hrsInnerWrapper.displayName = 'Logbook24hrsInnerWrapper';

export const Logbook24hrsOuterWrapper = styled.div<Logbook24hrsOuterWrapperType>`
  position: relative;
  height: ${(props) => (props.hasMeasurement ? 'unset' : '100%')};
`;

Logbook24hrsOuterWrapper.displayName = 'Logbook24hrsOuterWrapper';

export const Logbook24hrsLoader = styled.div<Logbook24hrsLoaderAndGridType>`
  box-sizing: border-box;
  margin: ${(props) =>
    props.graph !== GRAPHS.LOGBOOK ? '2.5rem 1rem 1.8rem 0' : '0 2rem 1.8rem'};
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
Logbook24hrsLoader.displayName = 'Logbook24hrsLoader';

export const Logbook24hrsGridRow = styled.div<Logbook24hrsLoaderAndGridType>`
  display: flex;
  flex-direction: row;
  margin: ${(props) =>
    props.graph !== GRAPHS.LOGBOOK ? '0' : '0 1.25rem 0 2rem'};
`;
Logbook24hrsGridRow.displayName = 'Logbook24hrsGridRow';

export const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
`;

DateColumn.displayName = 'DateColumn';

type ColumnHeaderType = {
  standardRowHeight: number;
  collapsed?: boolean;
  isSticky?: boolean;
  properStyle?: CSSProperties;
  name?: string;
};

export const ColumnHeader = styled.div<ColumnHeaderType>`
  height: ${({ standardRowHeight }) => standardRowHeight}px;
  display: flex;
  align-items: center;
  background-color: ${colors.blueMarineAlpha};
  color: ${colors.charcoal};
  font-size: ${fontSize.caption};
  font-weight: ${fontWeights.bold};
  line-height: 0.75rem;
`;

ColumnHeader.displayName = 'ColumnHeader';

export const DateColumnHeader = styled(ColumnHeader)`
  ${setHeadersCSS};
  border-top: ${tableBorder(1)};
  border-bottom: ${tableBorder(2)};
  border-right: ${tableBorder(1)};
  border-left: ${tableBorder(1)};
  padding: 0 2.75rem 0 ${spacing.two};
  width: 80px;
  z-index: 1;
`;

DateColumnHeader.displayName = 'DateColumnHeader';

export const DateColumnBody = styled.div`
  overflow: hidden !important;
`;

DateColumnBody.displayName = 'DateColumnBody';

export const BodyColumn = styled.div<Logbook24hrsWrapperType>`
  flex: 2;
  position: ${(props) => (props.collapsed ? 'static !important' : 'relative')};
`;

BodyColumn.displayName = 'BodyColumn';

export const TimeHeaderCell = styled(ColumnHeader)`
  justify-content: center;
  border-top: ${tableBorder(1)};
  border-bottom: ${tableBorder(2)};
  border-right: ${tableBorder(1)};
  text-align: center;
`;

TimeHeaderCell.displayName = 'TimeHeaderCell';

export const SummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

SummaryColumn.displayName = 'SummaryColumn';

export const SummaryColumnHeader = styled(ColumnHeader)`
  ${setHeadersCSS};
  border-top: ${tableBorder(1)};
  border-bottom: ${tableBorder(2)};
  border-right: ${tableBorder(1)};
  border-left: ${tableBorder(1)};
  padding: 0 2.75rem 0 ${spacing.two};
  width: 200px;
  z-index: 1;
`;

SummaryColumnHeader.displayName = 'SummaryColumnHeader';

export const SummaryColumnBody = styled.div`
  overflow: hidden !important;
`;

SummaryColumnBody.displayName = 'SummaryColumnBody';

type MeasurementCellType = {
  key: string;
  isLastCell: bool;
};

export const MeasurementCell = styled.div<MeasurementCellType>`
  display: flex;
  color: ${colors.charcoal};
  font-size: ${fontSize.caption};
  font-weight: ${fontWeights.bold};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  width: 100%;
  // applying min-height as a quick fix to give cells equal spacing
  min-height: ${STANDARD_ROW_HEIGHT}px;
  border-bottom: ${(props) => (props.isLastCell ? tableBorder(1) : '0')};
`;

MeasurementCell.displayName = 'MeasurementCell';

const getSummaryCellHeight = (rowHeight) =>
  `${rowHeight * STANDARD_ROW_HEIGHT}px`;

type SummaryCellType = {
  rowHeight: number;
  borderTop: boolean;
};

export const SummaryCell = styled.div<SummaryCellType>`
  box-sizing: border-box;
  // applying min-height as a quick fix to give cells equal spacing
  min-height: ${({ rowHeight }) => getSummaryCellHeight(rowHeight)};
  border-bottom: ${tableBorder(1)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.4rem;

  &:first-child {
    border-top: ${({ borderTop = false }) => tableBorder(borderTop ? 1 : 0)};
  }
`;

SummaryCell.displayName = 'SummaryCell';

export const SummaryValueContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1.1;
`;

SummaryValueContainer.displayName = 'SummaryValueContainer';

export const SummaryValueCell = styled.div`
  font-size: ${fontSize.caption};
  font-weight: ${fontWeights.bold};
  line-height: 0.825rem;
  color: ${colors.charcoal};
  padding-right: 0.5rem;
`;

SummaryValueCell.displayName = 'SummaryValueCell';

export const SummaryLabelCell = styled.div`
  font-size: ${fontSize.label};
  font-weight: ${fontWeights.semiBold};
  line-height: 1rem;
  color: ${colors.charcoal};
  flex: 1;
`;

SummaryLabelCell.displayName = 'SummaryLabelCell';

const CellTable = styled.div`
  display: table;
  border-collapse: collapse;
  width: 100%;
`;

CellTable.displayName = 'CellTable';

const getOuterCellHeight = (rowGap: number) => `calc(100% - ${rowGap}px)`;

export const OuterCell = styled(CellTable)`
  height: ${({ rowGap }) => getOuterCellHeight(rowGap)};
`;

export const DateOuterCell = styled(OuterCell)`
  border-bottom: ${tableBorder(1)};
  border-right: ${tableBorder(1)};
  border-left: ${tableBorder(1)};
  border-top: ${({ borderTop = false }) => tableBorder(borderTop ? 1 : 0)};
  background-color: ${colors.white};
  &:after {
    position: absolute;
    top: ${spacing.two};
    left: 0;
    bottom: ${spacing.three};
    width: 0.1875rem;
    border-radius: 0.1875rem;
    background: ${(props) => (props.highlight ? colors.blue : 'none')};
    content: '';
  }
`;

DateOuterCell.displayName = 'DateOuterCell';

export const TimeOuterCell = styled(OuterCell)`
  border-right: ${tableBorder(1)};
`;

TimeOuterCell.displayName = 'TimeOuterCell';

export const SummaryOuterCell = styled(OuterCell)`
  border-right: ${tableBorder(1)};
  border-left: ${tableBorder(1)};
  background: ${colors.white};
`;

SummaryOuterCell.displayName = 'SummaryOuterCell';

export const InnerCell = styled.div`
  height: 100%;
  display: table-cell;
`;

InnerCell.displayName = 'InnerCell';

const PrimaryRowPatternStyles = css`
  ${MeasurementCell}:nth-child(odd) {
    background: ${colors.silverLight};
  }
  ${MeasurementCell}:nth-child(even) {
    background: ${colors.white};
  }
`;

const SecondaryRowPatternStyles = css`
  ${MeasurementCell}:nth-child(even) {
    background: ${colors.silverLight};
  }
  ${MeasurementCell}:nth-child(odd) {
    background: ${colors.white};
  }
`;

export const MeasurementOuterCell = styled(InnerCell)`
  ${({ variant }) =>
    isPrimary(variant) ? PrimaryRowPatternStyles : SecondaryRowPatternStyles};
`;

MeasurementOuterCell.displayName = 'MeasurementOuterCell';
export const SummaryLabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1.25;
`;

SummaryLabelContainer.displayName = 'SummaryLabelContainer';

export const SummaryColumnIconContainer = styled.div`
  flex: 0.25;
`;

SummaryColumnIconContainer.displayName = 'SummaryColumnIconContainer';

export const DateInnerCell = styled(InnerCell)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${spacing.two} 0 ${spacing.two};
`;

DateInnerCell.displayName = 'DateInnerCell';

export const DateText = styled.span`
  font-size: ${fontSize.label};
  font-weight: 500;
  line-height: 0.75rem;
`;

DateText.displayName = 'DateText';

const MeasurementCellWithBorderTop = css`
  & ${MeasurementCell}:first-of-type {
    border-top: ${tableBorder(1)};
  }
`;

export const BorderTop = styled.div<BorderTopVariant>`
  ${({ borderTop }) => (borderTop ? MeasurementCellWithBorderTop : '')}
  flex-direction: column;
  display: flex;
`;

BorderTop.displayName = 'BorderTop';

export const ScrollButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 120px;
  align-items: center;
  margin-top: 10px;
`;

ScrollButtonsContainer.displayName = 'ScrollButtonsContainer';

// Solution for fixing scroll delay
// https://github.com/bvaughn/react-virtualized/issues/291#issuecomment-349641071
export const SmoothScroll = styled.div`
  z-index: 100;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  will-change: transform;
`;

SmoothScroll.displayName = 'SmoothScroll';
