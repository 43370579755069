import styled from 'styled-components';

import { colors } from 'src/core/styles/colors';

const TimeDiv = styled.span;
export const Time = TimeDiv`
    color: #FFFFFF;
    font-family: Nunito;
    font-size: 12px;
    font-weight: bold;
    padding-top: 1px;
    margin-left: 4px;
`;
const { brandBlue } = colors;
export const WrapperButton = styled.button`
  display: flex;
  height: 24px;
  width: 96px;
  border-radius: 12px;
  background-color: ${brandBlue};
  justify-content: center;
  align-content: stretch;
  align-items: center;
  color: #fff;
  font-family: Nunito;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  outline: 0;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;
