import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { LogbookTableCell } from 'src/domains/diagnostics/components/table/logbook-table-cell/logbook-table-cell.component';
import { LogbookTableRow } from 'src/domains/diagnostics/components/table/logbook-table-row/logbook-table-row.component';
import { MealTimeIconsCell } from 'src/domains/diagnostics/components/table/meal-time-icons-cell/meal-time-icons-cell.component';
import { MealTimeLabelCell } from 'src/domains/diagnostics/components/table/meal-time-label-cell/meal-time-label-cell.component';
import { LogbookUnitsHeader } from 'src/domains/diagnostics/components/table/logbook-units-header/logbook-units-header.component';
import { TableHeader } from 'src/domains/diagnostics/components/table-header/table-header.component';

const mealTimes = ['night', 'breakfast', 'lunch', 'dinner', 'bedTime'];

export const MealTimesTableHeaderComponent = ({
  children,
  t,
  bloodGlucoseUnit,
  carbLabel,
}) => (
  <TableHeader bg={colors.blueMarineAlpha5}>
    <LogbookTableRow flex={1}>
      <MealTimeLabelCell
        key={'meal time label header - DATE-0'}
        keyText={0}
        mealTime={t('graphs.logbookDiary.date')}
        alignItems="left"
      />

      {mealTimes.map((m, i) => (
        <MealTimeLabelCell
          key={`meal time label header - ${m}-${i + 1}`}
          keyText={`${i + 1}`}
          mealTime={t('general.mealBlocks.' + m)}
          alignItems="left"
          width={'9rem'}
        />
      ))}
      <MealTimeLabelCell
        key={'meal time label header - DATE-6'}
        keyText={6}
        mealTime={t('graphs.logbookDiary.notes')}
        alignItems="left"
      />
    </LogbookTableRow>
    <LogbookTableRow flex={1}>
      <LogbookTableCell />
      {mealTimes.map((m, i) => (
        <MealTimeIconsCell
          key={`meal time icon header - ${m}-${i}`}
          keyText={`${i}`}
          mealTime={m.toUpperCase()}
          alignItems="left"
        />
      ))}
      <LogbookTableCell p={1} />
    </LogbookTableRow>
    <LogbookTableRow flex={1}>
      <LogbookTableCell width="6.7rem" />
      <LogbookUnitsHeader
        bloodGlucoseUnit={bloodGlucoseUnit}
        carbLabel={carbLabel}
      />
    </LogbookTableRow>
  </TableHeader>
);

export const MealTimesTableHeader = withTranslation(
  MealTimesTableHeaderComponent,
);
