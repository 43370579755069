export const NO_DATA_VALUE = '- - ';
export const NO_ENOUGH_DATA_VALUE = '!';

export const NO_ENOUGH_DATA_OBJECT = {
  text: NO_ENOUGH_DATA_VALUE,
  toolTipTitle: 'hcpDashboard.tooltip.ned.title',
  toolTipDescription: 'hcpDashboard.tooltip.ned.description',
};

export const NO_DATA_AVAILABLE_OBJECT = {
  text: NO_DATA_VALUE,
  toolTipTitle: 'hcpDashboard.tooltip.nda.title',
  toolTipDescription: 'hcpDashboard.tooltip.nda.description',
};
