import * as React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'src/components/protected-route/protected-route.container';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

import { WdtcModuleBundle } from './bundles';

export const newDeviceLinkLinks = {
  main: '/new-device-link',
  launcher: '/new-device-link/launcher',
  uploadClinicalData: '/new-device-link/upload-clinical-data',
  all: '/new-device-link/*',
};

export const NewDeviceLinkRoutes = () => (
  <Switch>
    <ProtectedRoute
      exact
      path={newDeviceLinkLinks.main}
      component={WdtcModuleBundle}
      hasPermissions={[PERMISSIONS.ROLE_DEVICE_LINK]}
    />
    <ProtectedRoute
      exact
      path={newDeviceLinkLinks.launcher}
      component={WdtcModuleBundle}
      hasPermissions={[PERMISSIONS.ROLE_DEVICE_LINK]}
    />
    <ProtectedRoute
      exact
      path={newDeviceLinkLinks.uploadClinicalData}
      component={WdtcModuleBundle}
      hasPermissions={[PERMISSIONS.ROLE_DEVICE_LINK]}
    />
    <ProtectedRoute
      exact
      path={newDeviceLinkLinks.all}
      component={WdtcModuleBundle}
      hasPermissions={[PERMISSIONS.ROLE_DEVICE_LINK]}
    />
  </Switch>
);
