export const generatePeriodsList = (t: any) => [
  {
    label: t('patientBar.sevenDaysPeriod'),
    value: '7',
  },
  {
    label: t('patientBar.fourteenDaysPeriod'),
    value: '14',
  },
  {
    label: t('patientBar.twentyeightDaysPeriod'),
    value: '28',
  },
  {
    label: t('patientBar.customPeriod'),
    value: '4',
  },
];
