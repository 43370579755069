import { PayloadAction } from 'src/app/store/app.types';
import { FetchDeviceSettingsParams } from 'src/domains/diagnostics/scenes/device-settings/device-settings.types';

export enum FetchStatus {
  LOADING = 'loading',
  FAILURE = 'failure',
  SUCCESS = 'success',
  NONE = 'none',
}

export type FetchDeviceSettingsStartAction = PayloadAction<
  string,
  FetchDeviceSettingsParams
>;

export type FetchDeviceSettingsSuccessAction = PayloadAction<
  string,
  FetchDeviceSettingsParams
>;
