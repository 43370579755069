import styled from 'styled-components';

import { colors } from 'src/core/styles/colors';

export const Column = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  padding: ${(props) => (props.expanded ? props.theme.spacing.three : '6px')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'initial')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'initial')};
  font-size: ${(props) => (props.expanded ? '16px' : '13px')};
  span.no-data-tool-tip-text {
    color: ${colors.grayNoData};
  }
`;

Column.displayName = 'Column';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 0.0625rem solid ${(props) => props.theme.colors.grayLighter};
  ${Column}:nth-of-type(2) {
    min-width: 35%;
  }
  ${Column}:nth-of-type(3) {
    max-width: 25%;
    text-align: center;
  }
  ${Column}:nth-of-type(4) {
    max-width: 15%;
    text-align: center;
  }
`;

Row.displayName = 'Row';

export const HeaderRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.blueMarineAlpha5};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.brandBlue};
  border-bottom: none;
  & span {
    font-size: ${(props) => (props.expanded ? '16px' : '10px')};
    text-transform: uppercase;
    font-weight: bold;
  }
`;

HeaderRow.displayName = 'HeaderRow';

export const SpanWithRightPadding = styled.span`
  padding-right: ${(props) => props.theme.spacing.two};
`;

SpanWithRightPadding.displayName = 'SpanWithRightPadding';
