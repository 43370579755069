import { getConfig, isLocalEnv } from '@roche/roche-common';
import { mergeAll } from 'ramda';

const {
  REACT_APP_API_MULE: mule,
  REACT_APP_API_ROOT: root,
  REACT_APP_API_VERSION: version,
  REACT_APP_API_GATEWAY: apiGateway,
} = getConfig();

export const ENDPOINTS = {
  loginRedirect: `{{accessEndpoint}}/oidc/authorize?apiKey={{gigyaToken}}&client_id={{oidcClientId}}&redirect_uri={{redirectUri}}&prompt=login`,
  token: `${apiGateway}/identities/token`,
  accountInfo: `${apiGateway}/identities/{{gigyaUid}}`,
  logout: `${mule}/ciamcm/api/v2/identities/logout`,
  changePasswordIframe: `{{accessEndpoint}}/v1/gigya/changePassword?apiKey={{gigyaToken}}&lang={{lowerCaseLang}}`,
  activeSystemLanguages: `${mule}/settings/${root}/${version}/associations/languages/active`,
  alerts: `${mule}/clinical-data/${root}/${version}/thresholds/patients/{{patientId}}/glucose-alerts`,
  getFhirRoleClinicalDataCgm: `${mule}/clinical-data/api/v2/patients/CGM/{{fhirId}}`,
  getSummaryCgm: `${mule}/clinical-data/${root}/${version}/ObservationSearch/Patients/{{fhirId}}/Observations/cgm/date-range`,
  getSummaryCgmFhir: `${mule}/clinical-data/api/v2/patients/cgm/date-range`,
  checkEmail: `${mule}/ciam/${root}/${version}/access/patient/checkEmail?email={{email}}`,
  checkEmailV2: `${mule}/ciam/${root}/v2/access/patient/check-email?email={{email}}`,
  deleteClinicGuide: `${mule}/help/${root}/${version}/guides/custom/{{clinicGuideId}}`,
  getClinicGuides: `${mule}/help/${root}/${version}/guides`,
  getClinicGuidesWithTherapy: `${mule}/help/${root}/${version}/guides?therapyId={{therapyId}}`,
  saveClinicGuide: `${mule}/help/${root}/${version}/guides/custom`,
  clinicalData: `${mule}/clinical-data/${root}/${version}/patients/{{patientId}}`,
  clinicalDataFromFhir: `${mule}/clinical-data/${root}/v2/patients/{{patientFhirId}}`,
  clinicalStatistics: `${mule}/customers/${root}/${version}/patients/statistics`,
  clinicalStatisticsV2: `${mule}/customers/${root}/v2/patients/statistics`,
  configurables: `${mule}/ciam/${root}/${version}/access/features/patients`,
  countries: `${mule}/settings/${root}/${version}/countries`,
  currentUser: `${mule}/ciam/${root}/${version}/identity/current-user?{{random}}`,
  patientDateRange: `${mule}/clinical-data/${root}/${version}/patients/{{patientId}}/date-range`,
  delivery: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}`,
  deliveryHistory: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}/history`,
  deliveryStatus: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}/status`,
  lastDeliveryStatus: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}/status/last`,
  profiles: `${mule}/organizations/${root}/health-care-centers/${version}/departments/{{departmentId}}/profiles`,
  getDeviceAssociation: `${mule}/devices/${root}/${version}/associations/{{associationId}}`,
  updateDeviceAssociation: `${mule}/devices/${root}/${version}/associations/{{associationId}}/patients/{{patientId}}`,
  deviceSettings: `${mule}/devices/${root}/${version}/settings/{{version}}?deviceSerialNumber={{deviceSerialNumber}}&patientId={{patientId}}&patientType={{patientType}}&includePrevious={{includePrevious}}`,
  deviceSettingsList: `${mule}/devices/${root}/${version}/settings?deviceSerialNumber={{deviceSerialNumber}}&patientId={{patientId}}&patientType={{patientType}}`,
  dtc: `/${root}/${version}/downloads/dtc-installer/{{os}}`,
  frequencies: `/${root}/${version}/frequencies`,
  getLatestPatients: `${mule}/customers/${root}/${version}/practitioners/self/patients/latest`,
  hba1c: `${mule}/clinical-data/${root}/${version}/glycated/last/{{fhirID}}`,
  hba1cList: `${mule}/clinical-data/${root}/${version}/glycated/history/{{fhirID}}?stamp={{stamp}}`,
  help: `/${root}/${version}/downloads/help-pdf`,
  mySugarSendInvite: `${mule}/customers/${root}/${version}/partners/mysugr/patients/{{patientGigyaUid}}/send-invitation`,
  mySugarStatus: `${mule}/customers/${root}/${version}/partners/mysugr/patients/{{patientGigyaUid}}/status`,
  mySugarSendInvitation: `${mule}/customers/${root}/${version}/partners/mysugr/patients/{{patientGigyaUid}}/send-invitation`,
  newEntryHba1c: `${mule}/clinical-data/${root}/${version}/glycated/{{fhirID}}`,
  nextShipmentChangeDate: `${mule}/strips/${root}/${version}/delivery/patients/{{id}}/home-delivery`,
  nextShipmentChangeStatus: `${mule}/strips/${root}/${version}/delivery/patients/{{id}}/status/change-status`,
  nextShipmentChangeFetch: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}/nextshipment/delivery-program-status`,
  saveDeliveryStatusComment: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}/status/comments`,
  addOrgStock: `${mule}/strips/${root}/${version}/stocks/organizations/{{clinicId}}/received`,
  getOrgStock: `${mule}/strips/${root}/${version}/stocks/organizations/{{clinicId}}`,
  pairingCodeAvailability: `${mule}/customers/${root}/v2/practitioners/access/pairing-code`,
  pairingCodeCheck: `${mule}/customers/${root}/${version}/patients/records/pairing-code`,
  pairingCodeCorrelation: `${mule}/customers/${root}/${version}/patients/records/{{fhirID}}/correlation/pairing-code`,
  pairingCodeIdentityStatus: `${mule}/customers/${root}/${version}/patients/identity/status`,
  checkExternalPatient: `${mule}/customers/${root}/${version}/identities?type=HCID&id={{externalPatientId}}`,
  createPatient: `${mule}/customers/${root}/${version}/patients`,
  createPatientWithFhir: `${mule}/customers/${root}/v2/patients`,
  healthCareIdAvailable: `${mule}/customers/api/v1/identities?id={{healthcareId}}&type=HCID`,
  deactivatePatient: `${mule}/ciam/${root}/${version}/access/patients/{{patientId}}/deactivatePatient`,
  getDeliveryConfiguration: `${mule}/strips/${root}/${version}/delivery/patients/delivery-programs`,
  getPatientDeliveryConfiguration: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}/strips/delivery-program`,
  saveDeliveryConfiguration: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}/strips/delivery-program`,
  editEmrPatient: `${mule}/customers/${root}/${version}/partners/emr/patients/{{patientId}}`,
  editPatientWithFhir: `${mule}/customers/${root}/v2/patients/{{patientId}}`,
  getPatientSummary: `/${root}/${version}/patients/{{patientId}}/summary`,
  listDevices: `${mule}/devices/${root}/${version}/associations/patients/{{patientId}}/listdeviceassociations`,
  removeDevice: `${mule}/devices/${root}/${version}/associations/patients/{{patientId}}/deactivatedeviceassociation/{{deviceId}}`,
  timeBlocks: `${mule}/clinical-data/${root}/${version}/thresholds/patients/{{patientId}}/time-intervals`,
  patient: `${mule}/customers/${root}/${version}/patients/v1/{{patientId}}`,
  patientV2: `${mule}/customers/${root}/${version}/patients/v2/{{patientId}}`,
  patientList: `/${root}/${version}/patients/`,
  patientReports: `${mule}/clinical-data/${root}/${version}/reports/patients/{{patientId}}/generate/{{reportType}}?startDate={{startDate}}&endDate={{endDate}}`,
  patientSearch: `${mule}/customers/${root}/${version}/patients{{freeText}}`,
  patientSessionEnd: `${
    isLocalEnv() ? getConfig().REACT_APP_VISIT_BASE_URL : mule
  }/encounters/${root}/v2/patients/{{patientId}}/visits/{{visitId}}/end`,
  patientSessionStart: `${
    isLocalEnv() ? getConfig().REACT_APP_VISIT_BASE_URL : mule
  }/encounters/${root}/v2/patients/{{patientId}}/visits`,
  getPatientStock: `${mule}/strips/${root}/${version}/patients/{{patientId}}?stripModelReference={{stripModelReference}}`,
  lostStrips: `${mule}/strips/${root}/${version}/patients/{{patientId}}/lost-strips`,
  manualDelivery: `${mule}/strips/${root}/${version}/delivery/patients/{{patientId}}/manual-delivery`,
  stockAdjustments: `${mule}/strips/${root}/${version}/patients/{{patientId}}/adjustments`,
  patientsSessionExport: `${
    isLocalEnv() ? getConfig().REACT_APP_VISIT_BASE_URL : mule
  }/encounters/${root}/v2/visits/report?from={{startDate}}&to={{endDate}}`,
  permissions: `${mule}/ciam/${root}/${version}/access/permissions?{{random}}`,
  permissionsPatients: `${mule}/ciam/${root}/${version}/access/permissions/patients/{{gigyaUid}}?{{random}}`,
  permissionsWithPlatform: `${mule}/v2/ciam/api/v2/access/permissions/patients`,
  getPrescription: `${mule}/clinical-data/${root}/${version}/prescriptions/patients/{{patientId}}/current`,
  savePrescription: `${mule}/clinical-data/${root}/${version}/prescriptions/patients/{{patientId}}`,
  prescriptionReasons: `${mule}/clinical-data/${root}/${version}/prescriptions/patients/{{patientId}}/reasons`,
  getProfessionals: `${mule}/organizations/${root}/health-care-centers/${version}/departments/{{departmentId}}/professionals`,
  practitioners: `${mule}/customers/${root}/${version}/practitioners`,
  profileEmr: `${mule}/customers/${root}/${version}/partners/emr/practitioners`,
  rpm: `${mule}/settings/${root}/${version}/programs/rpm`,
  stripModels: `${mule}/strips/${root}/${version}/models`,
  targetRanges: `${mule}/clinical-data/${root}/v2/thresholds`,
  therapies: `${mule}/clinical-data/${root}/${version}/therapies`,
  thresholds: `${mule}/clinical-data/${root}/${version}/thresholds/patients/{{patientId}}`,
  getUserUnits: `${mule}/settings/${root}/${version}/configuration/unit/measurements`,
  saveUserUnits: `${mule}/settings/${root}/${version}/configuration/unit/professional`,
  checkDataSharingConsent: `${mule}/customers/${root}/${version}/patients/datasharing/status`,
  rdacInvitePatient: `${mule}/customers/${root}/${version}/patients/rdac-invitation`,
  changePassword: `${mule}/v2/ciam/${root}/v3/identities/{{accountId}}/change-password`,
  unblindClinicalStudyPatient: `${mule}/v2/ciam/${root}/v2/access/features/patients`,
};

export const getEndpoints = (endpoints) =>
  mergeAll(
    Object.entries(endpoints).map(([id, url]) => {
      return {
        [id]: {
          id,
          url,
        },
      };
    }),
  );

export const endpointWithParams = (endpoint: string, params: object) => {
  let url = endpoint;
  Object.keys(params).forEach(
    (p) => (url = url.replace(`{{${p}}}`, params[p])),
  );
  return url;
};
