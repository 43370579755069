import { includes } from 'ramda';
import {
  EditProfileActions,
  EditProfileActionType,
  EditProfileState,
} from './edit-profile.types';

import { GET_CURRENT_USER } from 'src/core/user/user.constants';
import {
  FETCH_PERMISSIONS,
  PERMISSIONS,
} from 'src/core/permissions/permissions.constants';

import { EDIT_PROFILE_INITIAL_STATE } from './edit-profile.constants';

const initialEditProfileState: EditProfileState = EDIT_PROFILE_INITIAL_STATE;

export const editProfileReducer = (
  state = initialEditProfileState,
  action: EditProfileActions,
): EditProfileState => {
  const moment = require('moment');
  switch (action.type) {
    case GET_CURRENT_USER.SUCCESS:
      const user = action.payload.user;
      const date = user.birthday ? moment(user.birthday) : null;
      return {
        ...state,
        ...user,
        dateOfBirthYear: date ? date.format('YYYY') : '',
        dateOfBirthMonth: date ? date.format('M') : '',
        dateOfBirthDay: date ? date.format('D') : '',
        originalEmail: user.email,
      };
    case FETCH_PERMISSIONS.SUCCESS:
      const hasOC = includes(
        PERMISSIONS.OPEN_CONNECTIVITY_DATA_DOWN,
        action.payload,
      );
      return {
        ...state,
        openConnectivity: hasOC ? 'true' : 'false',
      };
    case EditProfileActionType.EDIT_PROFILE_START:
    case EditProfileActionType.EDIT_EMR_PROFILE_START:
      return {
        ...state,
        editHasError: false,
        editWasSuccessful: false,
      };
    case EditProfileActionType.EDIT_PROFILE_SUCCESS:
    case EditProfileActionType.EDIT_EMR_PROFILE_SUCCESS:
      return {
        ...state,
        editHasError: false,
        editWasSuccessful: true,
      };
    case EditProfileActionType.EDIT_PROFILE_ERROR:
    case EditProfileActionType.EDIT_EMR_PROFILE_ERROR:
      return {
        ...state,
        editHasError: true,
        editWasSuccessful: false,
      };
    default:
      return state;
  }
};
