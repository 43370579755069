import { connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { equals, pathOr } from 'ramda';
import { createSelector } from 'reselect';

import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import {
  selectEC6FullName,
  selectIfusLang,
  selectIs12HourFormat,
  selectUserProfile,
} from 'src/core/user/user.selectors';
import { selectIsHcpOrGp } from 'src/core/user/user.selectors';
import {
  selectHasMngHome,
  selectFhirPermission,
  selectHasRebranding,
} from 'src/core/permissions/permissions.selectors';
import {
  selectHcpDashboard,
  selectIsAdministrative,
  selectIsPayer,
  selectSearchTerm,
} from 'src/domains/general/widgets/store/hcp-dashboard.selectors';
import { selectEC6UserUnitMeasurement } from 'src/core/user/user.selectors';
import { DEFAULT_FILTERS } from 'src/domains/general/widgets/components/advanced-search/filters/filters.utils';

import { withRouter } from 'react-router-dom';
import {
  search,
  filterPatientsSet,
  filterPatients,
  clearCriteria,
  updateSearchTerm,
  updateSearchByTab,
} from 'src/domains/general/widgets/store/hcp-dashboard.actions';

import { TopNavigationJelloComponent } from './top-navigation-jello.component';
import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  TopNavigationProps,
  TopNavigationContainerProps,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.types';

import {
  selectDepartmentName,
  selectCenterName,
} from 'src/core/user/user.selectors';
import { selectCurrentDashboardLocation } from 'src/domains/general/widgets/store/hcp-dashboard.selectors';

import {
  selectC2AIndividual,
  selectHasDeviceLink,
  selectNotificationCenterPermission,
} from 'src/core/permissions/permissions.selectors';
import {
  clearPatientExportData,
  exportPatientsSessionStartAction,
} from 'src/core/patient-session/patient-session.actions';
import { search as searchAdmin } from 'src/core/patient-search/patient-search.actions';
import { UNIT_MGDL, UNIT_MMOLL } from 'src/app/app.constants';
import { createModal, destroyModal } from 'src/core/modal/modal.actions';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';
import { TimeTrackerExportModal } from 'src/widgets/top-navigation/menu-dropdown/components/time-tracker-export-modal/time-tracker-export-modal.component';
import { SubmitModalProps } from 'src/widgets/top-navigation/menu-dropdown/components/time-tracker-export-modal/time-tracker-export-modal.types';
import { selectPermissions } from 'src/core/permissions/permissions.selectors';
import {
  selectIsPatientExportData,
  selectPatientExportData,
  selectPatientExportSessionStatus,
} from 'src/core/patient-session/patient-session.selectors';

const mapDispatchToProps = mapDispatchers({
  goHome: () => {
    const currentPath = window.location.pathname;
    const homePath = '/home';
    if (currentPath !== homePath) {
      return push(homePath);
    }
    return null;
  },
  goTo: (path) => push(path),
  updateSearchByTab,
  updateSearchTerm,
  createModal,
  destroyModal,
  search,
  searchAdmin,
  clearCriteria,
  filterPatients,
  filterPatientsSet,
  exportPatientsSessionStart: exportPatientsSessionStartAction,
  clearPatientExportData,
  showTimeTrackerExportModal: (locale, t, exportPatientsSessionStart) =>
    createModal({
      key: MODAL_TYPES.CUSTOM,
      data: {
        customComponent: TimeTrackerExportModal,
        locale,
        onSubmitModal: (props: SubmitModalProps) => {
          exportPatientsSessionStart({
            t,
            startDate: props.startDate
              .startOf('day')
              .format('YYYY-MM-DD HH.mm.ss'),
            endDate: props.endDate.endOf('day').format('YYYY-MM-DD HH.mm.ss'),
          });
        },
      },
    }),
});

const selectUnitGlucoseForService = createSelector(
  selectEC6UserUnitMeasurement,
  (unit) => (equals(unit, 'mmol/L') ? UNIT_MMOLL : UNIT_MGDL),
);

export const selectFilters = createSelector(
  selectHcpDashboard,
  pathOr([], ['filters']),
);

const mapStateToProps = (state) => ({
  routes: getCombinedRoutes(state),
  profile: selectUserProfile(state),
  hasMngHome: selectHasMngHome(state),
  isHcpOrGp: selectIsHcpOrGp(state),
  isPayer: selectIsPayer(state),
  isAdmin: selectIsAdministrative(state),
  departmentName: selectDepartmentName(state),
  centerName: selectCenterName(state),
  professionalName: selectEC6FullName(state),
  c2aIndividual: selectC2AIndividual(state),
  isFhir: selectFhirPermission(state),
  dashboardLocation: selectCurrentDashboardLocation(state),
  unitGlucoseForService: selectUnitGlucoseForService(state),
  filters: selectFilters(state),
  locale: selectIfusLang(state),
  hasNotificationCenterPermission: selectNotificationCenterPermission(state),
  hasDeviceLinkPermission: selectHasDeviceLink(state),
  defaultFilters: () => DEFAULT_FILTERS,
  searchTerm: selectSearchTerm(state),
  permissions: selectPermissions(state),
  rebranding: selectHasRebranding(state),
  isPatientExportData: selectIsPatientExportData(state),
  patientExportData: selectPatientExportData(state),
  is12HourFormat: selectIs12HourFormat(state),
  hasExportBeenSuccessful: selectPatientExportSessionStatus(state),
});

export const TopNavigationJello = compose<
  TopNavigationProps,
  TopNavigationContainerProps
>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation,
)(TopNavigationJelloComponent);
