import { postJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

import {
  MysugrSendInviteLoaderImplType,
  MysugrSendInviteTransformImplType,
  MysugrSendInviteServiceImplType,
  OpenId,
} from './mysugr-send-invite.types';

export const MysugrSendInviteLoaderImpl: MysugrSendInviteLoaderImplType = (
  openId: OpenId,
  patientGigyaUid: string,
) => {
  return postJSON(
    endpointWithParams(ENDPOINTS.mySugarSendInvitation, { patientGigyaUid }),
    {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
      },
    },
  );
};

export const MysugrSendInviteTransformImpl: MysugrSendInviteTransformImplType =
  (data: string): string => data;

export const MysugrSendInviteServiceImpl: MysugrSendInviteServiceImplType =
  (
    load: MysugrSendInviteLoaderImplType,
    transform: MysugrSendInviteTransformImplType,
  ) =>
  (openId, patientGigyaUid) =>
    load(openId, patientGigyaUid).then(transform);
