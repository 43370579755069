import {
  StripModelsLoaderImpl,
  StripModelsTransform,
  StripModelsServiceImpl,
} from './strip-models.service';
import { mockStripModels } from './strip-models.mock';

const mockLoader = (q) => Promise.resolve(mockStripModels);

export const StripModelsFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : StripModelsLoaderImpl;
  return StripModelsServiceImpl(loader, StripModelsTransform);
};
