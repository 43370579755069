import { compose } from 'recompose';
import { connect } from 'react-redux';
import { selectManifestModuleName } from 'src/core/manifest-modules/manifestModules.selectors';

import { NotificationComponent } from './notification.component';
import { selectJelloPermission } from 'src/core/permissions/permissions.selectors';

const MODULE_NAME = 'rdcp-hcp-nc-client-module';
export const NotificationContainer = compose(
  connect((state) => ({
    moduleName: selectManifestModuleName(MODULE_NAME)(state),
    isJelloActivated: selectJelloPermission(state),
  })),
)(NotificationComponent);
