import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';

import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';

import {
  CheckDuplicatedEmailActionType,
  CheckDuplicatedEmailSuccessPayload,
  CheckDuplicatedEmailStartAction,
  CheckDuplicatedEmailActions,
} from './check-duplicated-email.actions.types';
import {
  checkDuplicatedEmailError,
  checkDuplicatedEmailSuccess,
} from './check-duplicated-email.actions';

export const checkDuplicatedEmailEpic: (
  checkEmailService,
) => Epic<CheckDuplicatedEmailActions, State> =
  (checkEmailService) => (action$, store$) =>
    action$
      .ofType(CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_START)
      .flatMap(({ payload }: CheckDuplicatedEmailStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        const gigyaToken = selectGigyaToken(store$.getState());
        return Observable.fromPromise(
          checkEmailService(
            { email: payload.email, hasUserPlatformPermission: true },
            accessToken,
            gigyaToken,
          ),
        )
          .switchMap((response: CheckDuplicatedEmailSuccessPayload) => {
            return [checkDuplicatedEmailSuccess(response)];
          })
          .pipe(
            catchError((err) => Observable.of(checkDuplicatedEmailError(err))),
          );
      });
