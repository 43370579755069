import { createSelector } from 'reselect';
import { isEmpty, pick, path, propOr } from 'ramda';
import {
  selectGlucoseMeasurementsInDateSliderRange,
  selectIsLoading,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { selectPatient } from 'src/core/patient/patient.selector';

export const selectPatientHasData = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectIsLoading,
  (measurements, isLoading) => isLoading || !isEmpty(measurements),
);

export const selectPatientInfo = createSelector(
  [selectPatient],
  pick(['firstName', 'surName', 'healthCareSystemId']),
);
export const selectPatientIsEMRCompleted = createSelector(
  [selectPatient],
  (state) => propOr(false, 'isEMRCompleted', state),
);

export const selectPatientReportStatus = path(['patientReports', 'status']);
