import {
  ConfigurablePermissionsActions,
  ConfigurablePermissionsActionsType,
  ConfigurablePermissionsState,
} from './configurables.types';

export const INITIAL_CONFIGURABLE_PERMISSIONS_STATE: ConfigurablePermissionsState =
  {
    isLoading: false,
    configurables: [],
    error: false,
  };

export const configurablePermissionsReducer = (
  state: ConfigurablePermissionsState = INITIAL_CONFIGURABLE_PERMISSIONS_STATE,
  action: ConfigurablePermissionsActionsType,
): ConfigurablePermissionsState => {
  switch (action.type) {
    case ConfigurablePermissionsActions.FETCH_CONFIGURABLES_START:
      return {
        isLoading: true,
        configurables: [],
        error: false,
      };
    case ConfigurablePermissionsActions.FETCH_CONFIGURABLES_SUCCESS:
      return {
        isLoading: false,
        configurables: action.payload,
        error: false,
      };
    case ConfigurablePermissionsActions.FETCH_CONFIGURABLES_ERROR:
      return {
        isLoading: false,
        configurables: [],
        error: true,
      };
    case ConfigurablePermissionsActions.FETCH_CONFIGURABLES_RESET:
      return INITIAL_CONFIGURABLE_PERMISSIONS_STATE;
    default:
      return state;
  }
};
