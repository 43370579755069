import React from 'react';

import { Plot } from 'src/lib/graph-plot.component';
import { GridLines } from 'src/domains/diagnostics/components/graph/grid-lines.component';
import { ThresholdLine } from 'src/domains/diagnostics/components/graph/threshold-line.component';
import { TargetRange } from 'src/domains/diagnostics/components/graph/target-range.component';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

import { MetabolicPlotFrame } from './metabolic-plot-frame.component';

export const MetabolicPlot = ({
  x,
  y,
  width,
  height,
  yDirection = -1,
  padding = 0,
  thresholds,
  toggles,
  bloodGlucoseDimensions: { metabolicGraphMaxY, metabolicYIncrements },
}) => (
  <Plot
    id="main-graph"
    x={x}
    y={y}
    width={width}
    height={height}
    padding={padding}
  >
    <RenderIf validate={toggles.gridLines}>
      <GridLines
        width={width}
        height={height}
        verticalCount={3}
        horizontalCount={metabolicGraphMaxY / metabolicYIncrements}
      />
    </RenderIf>
    <ThresholdLine
      threshold={thresholds.hypoglycemiaThreshold / metabolicGraphMaxY}
      width={width}
      height={height}
      plotHeight={400}
    />
    <TargetRange
      min={thresholds.glucoseIdealIntervalMin / metabolicGraphMaxY}
      max={thresholds.glucoseIdealIntervalMax / metabolicGraphMaxY}
      width={width}
      height={height}
      plotHeight={400}
    />
    <MetabolicPlotFrame width={width} height={height} yDirection={yDirection} />
  </Plot>
);
