import { ALERT_POSITION } from 'src/core/alert/alert.constans';
import { createStyledComponent } from '../../utils/styles';

export type StyledFloatingAlertWrapperProps = {
  position?: string | null;
};

export const StyledFloatingAlertWrapper = createStyledComponent<
  'div',
  StyledFloatingAlertWrapperProps
>('div')`
  position: fixed;
  z-index: 9999;
  bottom: ${(props) => {
    switch (props.position) {
      case ALERT_POSITION.TOP_LEFT:
      case ALERT_POSITION.TOP_CENTER:
      case ALERT_POSITION.TOP_RIGHT:
        return '';
      case ALERT_POSITION.CENTER_LEFT:
      case ALERT_POSITION.CENTER_CENTER:
      case ALERT_POSITION.CENTER_RIGHT:
        return '50%';
      case ALERT_POSITION.BOTTOM_LEFT:
      case ALERT_POSITION.BOTTOM_CENTER:
      case ALERT_POSITION.BOTTOM_RIGHT:
        return '24px';
      default:
        return '';
    }
  }};
  top: ${(props) => topBottomPosition(props.position, '24px', '', '40px')};
  left: ${(props) => {
    switch (props.position) {
      case ALERT_POSITION.TOP_LEFT:
      case ALERT_POSITION.CENTER_LEFT:
      case ALERT_POSITION.BOTTOM_LEFT:
        return '221px';
      case ALERT_POSITION.TOP_CENTER:
      case ALERT_POSITION.CENTER_CENTER:
      case ALERT_POSITION.BOTTOM_CENTER:
        return '50%';
      case ALERT_POSITION.TOP_RIGHT:
      case ALERT_POSITION.CENTER_RIGHT:
      case ALERT_POSITION.BOTTOM_RIGHT:
        return '';
      default:
        return '50%';
    }
  }};
  right: ${(props) => {
    switch (props.position) {
      case ALERT_POSITION.TOP_LEFT:
      case ALERT_POSITION.CENTER_LEFT:
      case ALERT_POSITION.BOTTOM_LEFT:
      case ALERT_POSITION.TOP_CENTER:
      case ALERT_POSITION.CENTER_CENTER:
      case ALERT_POSITION.BOTTOM_CENTER:
        return '';
      case ALERT_POSITION.TOP_RIGHT:
      case ALERT_POSITION.CENTER_RIGHT:
      case ALERT_POSITION.BOTTOM_RIGHT:
        return '24px';
      default:
        return '';
    }
  }};
  transform: translate(-50%, -50%);
  max-width: 394px;
  width: 394px;
  > jello-alert {
    position: absolute;
    width: 100%;
    bottom: ${(props) => topBottomPosition(props.position, '', '100%', '')};
    left: ${(props) => {
      switch (props.position) {
        case ALERT_POSITION.TOP_CENTER:
        case ALERT_POSITION.CENTER_CENTER:
        case ALERT_POSITION.BOTTOM_CENTER:
        case ALERT_POSITION.TOP_LEFT:
        case ALERT_POSITION.CENTER_LEFT:
        case ALERT_POSITION.BOTTOM_LEFT:
          return '';
        case ALERT_POSITION.BOTTOM_RIGHT:
        case ALERT_POSITION.TOP_RIGHT:
        case ALERT_POSITION.CENTER_RIGHT:
          return '50%';
        default:
          return '';
      }
    }};
  }
`;

function topBottomPosition(position, top, centBottom, def) {
  switch (position) {
    case ALERT_POSITION.TOP_LEFT:
    case ALERT_POSITION.TOP_CENTER:
    case ALERT_POSITION.TOP_RIGHT:
      return top;
    case ALERT_POSITION.CENTER_LEFT:
    case ALERT_POSITION.CENTER_CENTER:
    case ALERT_POSITION.CENTER_RIGHT:
    case ALERT_POSITION.BOTTOM_LEFT:
    case ALERT_POSITION.BOTTOM_CENTER:
    case ALERT_POSITION.BOTTOM_RIGHT:
      return centBottom;
    default:
      return def;
  }
}
