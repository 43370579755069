import React from 'react';

import { EmptyRows } from 'src/domains/diagnostics/components/table/empty-rows/empty-rows.component';
import { LogbookTableCellDiv } from 'src/domains/diagnostics/components/table/logbook-table-cell/logbook-table-cell.component';

export const LogbookDetailsCell = ({ numberOfRows }) => (
  <LogbookTableCellDiv flex={1} width="100%">
    <EmptyRows numberOfRows={numberOfRows} numberOfCells={1} showBorder />
  </LogbookTableCellDiv>
);
