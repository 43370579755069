import * as React from 'react';
import { ModuleAnchorConfig } from 'src/widgets/moduleAnchorConfig/ModuleAnchorConfig';
import { ModuleAnchorComponent } from '@roche/roche-common';
import { ModuleLoader } from 'src/modules/visit-module/retrocompatibility/load-modules';

import { ANCHOR_NAME } from './reports.constants';

interface StateProps {
  patientId: number;
  legacyPatientId: string;
  startDate: string;
  endDate: string;
  moduleName: string;
  jelloDs: boolean;
  rebranding: boolean;
}

export class ReportsComponent extends React.Component<StateProps> {
  public componentDidMount(): void {
    ModuleLoader.emitLoadModuleEvent(this.props.moduleName);
  }

  public render() {
    const {
      patientId,
      legacyPatientId,
      startDate,
      endDate,
      moduleName,
      jelloDs,
      rebranding,
    } = this.props;

    return (
      <ModuleAnchorConfig>
        <ModuleAnchorComponent
          moduleName={moduleName}
          anchorName={ANCHOR_NAME}
          inputAttributes={{
            patientId,
            legacyPatientId,
            startDate,
            endDate,
            jelloDs,
            rebranding,
          }}
          document={document}
        />
      </ModuleAnchorConfig>
    );
  }
}
