import * as React from 'react';
import { withTranslation as wt } from 'react-i18next';

import { DEFAULT_NAMESPACE_I18N } from 'src/i18n';

export const withTranslation = wt(DEFAULT_NAMESPACE_I18N);

export const getLabel = (label) => (
  <span
    dangerouslySetInnerHTML={{
      __html: label,
    }}
  />
);
