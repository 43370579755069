import React from 'react';
import { testId } from '@roche/roche-common';

type JelloAvatarProps = {
  id: string;
  color?: string;
  label?: string;
  size?: string;
  slot?: string;
};

export const JelloAvatarComponent: React.FunctionComponent<
  JelloAvatarProps
> = ({ id, color, label, size, slot }) => {
  return (
    <jello-avatar
      color={color}
      label={label}
      size={size}
      slot={slot}
      {...testId(id, 'jello-avatar')}
    />
  );
};
