import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';

import {
  CheckDuplicatedHealthcareIdActionType,
  CheckDuplicatedHealthcareIdSuccessPayload,
  CheckDuplicatedHealthcareIdStartAction,
  CheckDuplicatedHealthcareIdActions,
} from './check-duplicated-healthcare-id.actions.types';
import {
  checkDuplicatedHealthcareIdError,
  checkDuplicatedHealthcareIdSuccess,
} from './check-duplicated-healthcare-id.actions';

export const checkDuplicatedHealthcareIdEpic: (
  checkHealthcareIdService,
) => Epic<CheckDuplicatedHealthcareIdActions, State> =
  (checkHealthcareIdService) => (action$, store$) =>
    action$
      .ofType(
        CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_START,
      )
      .flatMap(({ payload }: CheckDuplicatedHealthcareIdStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        return Observable.fromPromise(
          checkHealthcareIdService(payload.healthcareId, accessToken),
        )
          .switchMap((response: CheckDuplicatedHealthcareIdSuccessPayload) => {
            return [checkDuplicatedHealthcareIdSuccess(response)];
          })
          .pipe(
            catchError((err) =>
              Observable.of(checkDuplicatedHealthcareIdError(err)),
            ),
          );
      });
