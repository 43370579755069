import { createPayloadAction } from 'src/app/store/app.actions';

import {
  CheckPairingCodeActionType,
  CheckPairingCodeStartAction,
  CheckPairingCodeSuccessAction,
  CheckPairingCodeErrorAction,
  ConfirmPopulateFormAction,
  ClearPairingCodeDataAction,
  CheckPairingCodeSuccessPayload,
  CheckPairingCodeError,
} from './check-pairing-code.types';

export const checkPairingCodeStart = (
  payload: string,
): CheckPairingCodeStartAction =>
  createPayloadAction(
    CheckPairingCodeActionType.CHECK_PAIRING_CODE_START,
    payload,
  );

export const checkPairingCodeSuccess = (
  payload: CheckPairingCodeSuccessPayload,
): CheckPairingCodeSuccessAction =>
  createPayloadAction(
    CheckPairingCodeActionType.CHECK_PAIRING_CODE_SUCCESS,
    payload,
  );

export const checkPairingCodeError = (
  payload: CheckPairingCodeError,
): CheckPairingCodeErrorAction => ({
  type: CheckPairingCodeActionType.CHECK_PAIRING_CODE_ERROR,
  payload,
});

export const onConfirmPopulateForm = (): ConfirmPopulateFormAction => ({
  type: CheckPairingCodeActionType.CONFIRM_POPULATE_FORM,
});

export const onClearPairingCodeData = (): ClearPairingCodeDataAction => ({
  type: CheckPairingCodeActionType.CLEAR_PAIRING_CODE_DATA,
});
