import { find, propEq } from 'ramda';

import { postJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const transformBody = (blocks) => {
  return JSON.stringify({
    beforeBreakfast: find(propEq('description', 'BEFORE_BREAKFAST'))(blocks)
      .startTime,
    beforeDinner: find(propEq('description', 'BEFORE_DINNER'))(blocks)
      .startTime,
    beforeLunch: find(propEq('description', 'BEFORE_LUNCH'))(blocks).startTime,
    beforeSleep: find(propEq('description', 'BEDTIME'))(blocks).startTime,
    breakfast: find(propEq('description', 'AFTER_BREAKFAST'))(blocks).startTime,
    dinner: find(propEq('description', 'AFTER_DINNER'))(blocks).startTime,
    lunch: find(propEq('description', 'AFTER_LUNCH'))(blocks).startTime,
    night: find(propEq('description', 'NIGHT'))(blocks).startTime,
  });
};

export const PostTimeBlocksLoaderImpl: FixMe = (
  accessToken: string,
  gigyaToken: string,
  patientId: string,
  blocks: FixMe,
) =>
  postJSON(endpointWithParams(ENDPOINTS.timeBlocks, { patientId }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: transformBody(blocks),
  });

export const PostTimeBlocksTransformImpl = (blocks: FixMe): FixMe =>
  blocks.model.timeIntervals;

export const PostTimeBlocksServiceImpl: FixMe =
  (load: FixMe, transform) => (accessToken, gigyaToken, patientId, blocks) =>
    load(accessToken, gigyaToken, patientId, blocks).then(transform);
