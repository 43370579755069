import { createSelector, createStructuredSelector } from 'reselect';
import { path, includes } from 'ramda';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  PERMISSIONS,
  PATIENT_PERMISSIONS,
} from 'src/core/permissions/permissions.constants';

import { transformPermissions } from './permissions.utils';

export const selectPermissions = path(['permissions', 'permissions']);
export const selectIsFetchingPermissions = path([
  'permissions',
  'isFetchingPermissions',
]);
export const selectIsFetchingPatientPermissions = path([
  'patientPermissions',
  'isFetchingPatientPermissions',
]);
export const selectPatientPermissions = path([
  'patientPermissions',
  'patientPermissions',
]);
export const selectCurrentPermissions = createSelector(
  selectPermissions,
  (permissions) => transformPermissions(permissions),
);
export const selectCurrentPatientPermissions = createSelector(
  selectPatientPermissions,
  (permissions) => transformPermissions(permissions),
);

export const selectProfessionalPermission = createSelector(
  selectCurrentPermissions,
  (permissions) => !!permissions.hasRelevantClinicalData,
);
export const selectPatternsPermission = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.ADVANCED_INDICATORS, permissions),
);
export const selectIsEMR = createSelector(selectPermissions, (permissions) =>
  includes(PERMISSIONS.ELECTRONIC_MEDICAL_RECORD, permissions),
);

export const selectIsPatientEMR = createSelector(
  selectPatientPermissions,
  (permissions) =>
    includes(PATIENT_PERMISSIONS.ELECTRONIC_MEDICAL_RECORD, permissions),
);

export const selectNotificationCenterPermission = createSelector(
  selectPermissions,
  (permissions) =>
    includes(PERMISSIONS.NOTIFICATION_CENTER_PERMISSION, permissions),
);

export const selectIsPatientRPM = createSelector(
  [selectPatientPermissions],
  (permissions) => includes(PERMISSIONS.RPM_PROGRAM, permissions),
);

export const selectIsProfessionalRPM = createSelector(
  [selectPermissions],
  (permissions) => includes(PERMISSIONS.RPM_PROGRAM_PROF, permissions),
);

export const selectIsRPMEnrolled = createSelector(
  selectIsPatientRPM,
  selectIsProfessionalRPM,
  (isPatientRPM, isPRofessionalRPM) => isPatientRPM && isPRofessionalRPM,
);

export const selectIsInterventionalStudy = createSelector(
  selectPatientPermissions,
  (permissions) =>
    includes(PATIENT_PERMISSIONS.INTERVENTIONAL_STUDY, permissions),
);

export const selectIsTimeTrackerEnrolled = createSelector(
  [selectPermissions],
  (permissions) => includes(PERMISSIONS.ROLE_TIME_TRACKING, permissions),
);

export const selectHasCreatePatient = createSelector(
  selectPermissions,
  (permissions) => !includes(PERMISSIONS.REGISTER_PATIENT, permissions),
);

export const selectHasEditEmrProfile = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.EDIT_PROFESSIONAL_EMR, permissions),
);

export const selectHasEditEmrPatient = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.EDIT_PATIENT_EMR, permissions),
);

export const selectHasEditPatient = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.EDIT_PATIENT, permissions),
);

const permissionConnector = createStructuredSelector({
  currentPermissions: selectCurrentPermissions,
});

export const selectHasOpenConnectivity = createSelector(
  selectPermissions,
  (permissions) =>
    includes(PERMISSIONS.OPEN_CONNECTIVITY_DATA_DOWN, permissions),
);

export const selectHasStripMngProfessional = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.STRIP_MNG_PROFESSIONAL, permissions),
);

export const selectHasHomeDeliveryProfessional = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.DELIVERY_PROFESSIONAL, permissions),
);

export const selectHasMngHome = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.MANAGEMENT_HOME, permissions),
);

export const selectHasStripMng = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.STRIP_TRAFFIC_LIGHT, permissions),
);

export const selectPatientHasStripMng = createSelector(
  selectCurrentPatientPermissions,
  (patientPermissions) => patientPermissions.hasStripMngPatient,
);

export const selectPatientHasHomeDelivery = createSelector(
  selectCurrentPatientPermissions,
  (patientPermissions) => patientPermissions.hasDeliveryMgmtPatient,
);

export const selectHasHomeDelivery = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.DELIVERY_PROFESSIONAL, permissions),
);

export const selectIsFranceCountry = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.ROLE_COUNTRY_FR, permissions),
);

export const selectHasDeviceLink = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.ROLE_DEVICE_LINK, permissions),
);

export const connectToPermissions = (Component) =>
  compose(connect(permissionConnector))(Component);

export const selectTherapyPermission = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.THERAPY, permissions),
);

export const selectFhirPermission = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.ROLE_FHIR, permissions),
);

export const selectPlatformPermission = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.ROLE_PLATFORM, permissions),
);

export const selectJelloPermission = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.ROLE_JELLO, permissions),
);

export const selectC2AIndividual = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.C2A_INDIVIDUAL, permissions),
);

export const selectHasRebranding = createSelector(
  selectPermissions,
  (permissions) => includes(PERMISSIONS.BRAND_ACCUCHEK, permissions),
);

export const selectIsBlindedStudy = createSelector(
  selectPatientPermissions,
  (patientPermissions) =>
    includes(PATIENT_PERMISSIONS.BLINDED_STUDY, patientPermissions),
);

export const selectHasVideocall = createSelector(
  selectPermissions,
  (permissions) => false,
);
