import { State } from 'src/app/store/app.types';

import {
  PatientFormState,
  PatientGender,
  PatientIsPregnant,
} from 'src/core/patient/patient.types';
import { createSelector } from 'reselect';

export const selectCreatePatientForm = (state: State) => state.createPatient;

export const selectEditPatientForm = (state: State) =>
  state.editPatient.patientFormData;

export const selectActivePatientForm =
  (isEditing: boolean) =>
  (state: State): PatientFormState =>
    isEditing ? selectEditPatientForm(state) : selectCreatePatientForm(state);

export const selectGender = (isEditing: boolean) =>
  createSelector(
    [selectActivePatientForm(isEditing)],
    (patientFormState: PatientFormState): PatientGender | undefined =>
      patientFormState.patientInfo.gender,
  );

export const selectPregnant = (isEditing: boolean) =>
  createSelector(
    [selectActivePatientForm(isEditing)],
    (patientFormState: PatientFormState): boolean =>
      patientFormState.healthInfo.pregnant === PatientIsPregnant.TRUE,
  );
