import { map, toUpper } from 'ramda';

import { translate } from 'src/i18n';

export const transformClinicGuides = (ClinicGuideData) => {
  const mapper = ({ type, label, name, ...data }) => ({
    ...data,
    type: toUpper(type),
    name: label ? translate(`prescription.clinicGuide.${label}`) : name,
  });
  return map(mapper, ClinicGuideData);
};
