import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withToolTip } from 'src/utils/with-tool-tip';

import { Logbook } from './logbook.component';
import { logbookConnector } from '../selector/logbook.selector';

export const LogbookContainer = compose(
  connect(logbookConnector),
  withRouter,
  withToolTip,
)(Logbook);
