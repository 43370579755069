import { path, propOr } from 'ramda';
import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';

export const selectConfig = (state: State) =>
  path(['isChangedPassword'], state);

export const selectIsChangedPassword = createSelector(
  [selectConfig],
  (config) => propOr(false, 'changed', config),
);
