import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  CreateFhirPatientActionType,
  CreateFhirPatientStartAction,
  CreateFhirPatientSuccessAction,
  CreateFhirPatientErrorAction,
  ClearCreateFhirPatientStateAction,
  CreateFhirPatientStartPayload,
  CreateFhirPatientErrorPayload,
} from './create-patient.actions.types';

export const createFhirPatientStart = (
  payload: CreateFhirPatientStartPayload,
): CreateFhirPatientStartAction =>
  createPayloadAction(
    CreateFhirPatientActionType.CREATE_FHIR_PATIENT_START,
    payload,
  );

export const createFhirPatientSuccess = (): CreateFhirPatientSuccessAction =>
  createAction(CreateFhirPatientActionType.CREATE_FHIR_PATIENT_SUCCESS);

export const createFhirPatientError = (
  payload: CreateFhirPatientErrorPayload,
): CreateFhirPatientErrorAction =>
  createPayloadAction(
    CreateFhirPatientActionType.CREATE_FHIR_PATIENT_ERROR,
    payload,
  );

export const clearCreateFhirPatientStateAction =
  (): ClearCreateFhirPatientStateAction =>
    createAction(CreateFhirPatientActionType.CLEAR_CREATE_FHIR_PATIENT_STATE);
