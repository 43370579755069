import {
  getJSON,
  putJSON,
  stringifyBody,
  createAuthHeader,
} from 'src/utils/service/service.utils';

import { RpmSettings, EC6RpmSettings } from './rpm-settings.types';

import { ENDPOINTS } from 'src/services/service.constants';

export const GetRpmSettingsLoaderImpl = (openId: any) => {
  return getJSON(ENDPOINTS.rpm, {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
  });
};

const getDurationValues = (days) => {
  let value;
  let type;
  const daysNumber = Number(days);

  switch (true) {
    case daysNumber >= 30 && daysNumber % 30 === 0:
      value = daysNumber / 30;
      type = 'month';
      break;
    case daysNumber >= 7 && daysNumber % 7 === 0:
      value = daysNumber / 7;
      type = 'week';
      break;
    default:
      value = daysNumber;
      type = 'day';
      break;
  }

  return { value, type };
};

const getComplianceValues = (days) => {
  let value;
  let type;
  const daysNumber = Number(days);

  if (daysNumber >= 24 && daysNumber % 24 === 0) {
    value = daysNumber / 24;
    type = 'days';
  } else {
    value = daysNumber;
    type = 'hours';
  }

  return { value, type };
};

export const GetRpmSettingsTransformImpl = (
  response: EC6RpmSettings,
): RpmSettings => {
  const duration = getDurationValues(response.programDurationDays);
  const compliance = getComplianceValues(response.updatingReminderHours);

  return {
    programName: response.programName,
    programDurationNumber: duration.value,
    programComplianceReminder: compliance.value,
    programComplianceCheck: response.minimunCheckAlertDays,
    programDurationType: duration.type,
    programComplianceType: compliance.type,
  };
};

export const GetRpmSettingsServiceImpl = (load, transform) => (openId: any) =>
  load(openId).then(transform);

export const UpdateRpmSettingsTransformImpl = (value) => value;

export const UpdateRpmSettingsLoaderImpl = (openId, data: EC6RpmSettings) =>
  putJSON(ENDPOINTS.rpm, {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
    body: stringifyBody(data),
  });

export const UpdateRpmSettingsServiceImpl =
  (put, transform) => (query, accessToken, gigyaToken) =>
    put(query, accessToken, gigyaToken).then(transform);
