export const CREATE_PATIENT_STEP_CONFIG = [
  {
    title: 'createPatient.steps.one',
  },
  {
    title: 'createPatient.steps.two',
  },
  {
    title: 'createPatient.steps.three',
  },
  {
    title: 'createPatient.steps.four',
  },
];

export const CREATE_PATIENT_FORM_DATA_MODEL = 'createPatient';
