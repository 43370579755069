import {
  EditPatientLoaderImpl,
  EditPatientServiceImpl,
  EditPatientTransformImpl,
} from './edit-patient.service';

import { mockEditPatientResponse } from './edit-patient.mock';

const mockPoster = () => Promise.resolve(mockEditPatientResponse);

export const EditPatientFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPoster : EditPatientLoaderImpl;
  return EditPatientServiceImpl(loader, EditPatientTransformImpl);
};
