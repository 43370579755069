import { LocationChangeAction } from 'react-router-redux';

import { Action } from 'src/app/store/app.types';

export enum ExpiringModalActionTypes {
  SHOW_EXPIRING_MODAL = 'SHOW_EXPIRING_MODAL',
  TTL_EXPIRING = 'TTL_EXPIRING',
}

export type ShowExpiringModalAction =
  Action<ExpiringModalActionTypes.TTL_EXPIRING>;

export type ExpiringModalActions =
  | LocationChangeAction
  | ShowExpiringModalAction;
