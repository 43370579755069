import * as React from 'react';

interface IProps {
  iconName: string;
  color?: string;
  size?: 'XS' | 'S' | 'M';
  className?: string;
  styles?: object;
  disabled?: boolean;
}

export const JelloIcon = ({
  iconName,
  color,
  size,
  styles,
  disabled,
}: IProps) => {
  return (
    <jello-icon
      icon-name={iconName}
      style={{ display: 'inline-flex', color, ...styles }}
      size={size}
      disabled={disabled}
    />
  );
};
