import {
  createRequestActionTypes,
  createRequestActions,
} from 'src/core/request/request.actions';

import { TargetRangesActionType } from './target-ranges.types';

export const TARGET_RANGES_GET_REQUEST = createRequestActionTypes(
  TargetRangesActionType.TARGET_RANGES_GET,
);

export const getTargetRanges = createRequestActions(TARGET_RANGES_GET_REQUEST);

export const TARGET_RANGES_SAVE_REQUEST = createRequestActionTypes(
  TargetRangesActionType.TARGET_RANGES_SAVE,
);

export const saveTargetRanges = createRequestActions(
  TARGET_RANGES_SAVE_REQUEST,
);

export const TARGET_RANGES_CLEAR_REQUEST = createRequestActionTypes(
  TargetRangesActionType.TARGET_RANGES_CLEAR,
);

export const clearTargetRanges = createRequestActions(
  TARGET_RANGES_CLEAR_REQUEST,
);

export const TARGET_RANGES_CLEAR_SUCCESS_REQUEST = createRequestActionTypes(
  TargetRangesActionType.TARGET_RANGES_CLEAR_SUCCESS,
);

export const clearSuccessTargetRanges = createRequestActions(
  TARGET_RANGES_CLEAR_SUCCESS_REQUEST,
);
