import { compose as Rcompose, dissocPath, keys } from 'ramda';
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import {
  createTransform,
  persistStore,
  persistCombineReducers,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { CIAM_SDK_NAMESPACE } from 'src/app/session/constants';
import { storageMiddleware } from 'src/app/storage';

import { appEpic } from './epic';
import { reducers } from './reducer';
import { isDevEnv, isLocalEnv } from '@roche/roche-common';

const showDevTools = isDevEnv() || isLocalEnv();

const history = createBrowserHistory();

const colors = {
  default: '#383838',
  clear_: '#7C394B',
  _error: '#E33231',
  _success: '#4CAF50',
  _start: '#03A9F4',
  undefined: '#8ADBDE',
  gigya: '#B25FD6',
  redirect: '#12505F',
};
const mapColor = () => {
  const colorCache = {};
  return (title) => {
    if (colorCache[title]) return colorCache[title];
    let color;
    keys(colors).forEach((key) => {
      if (!color && title.toLowerCase().match(key)) {
        color = colors[key];
        colorCache[title] = color;
      }
    });
    return color ? color : colors.default;
  };
};

function _getMiddleware() {
  let middleware = [
    createEpicMiddleware(appEpic),
    routerMiddleware(history),
    storageMiddleware(),
  ];
  const cachedColorMapper = mapColor();
  if (showDevTools) {
    const logger = createLogger({
      collapsed: true,
      duration: true,
      colors: {
        title: (action) => action.type && cachedColorMapper(action.type),
        prevState: () => '#E33231',
        action: () => '#03A9F4',
        nextState: () => '#4CAF50',
        error: () => '#E33231',
      },
    });
    middleware = [...middleware, logger];
  }

  return applyMiddleware(...middleware);
}

function _getEnhancers() {
  let enhancers = [];

  if (
    showDevTools &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
  ) {
    enhancers = [...enhancers, window.__REDUX_DEVTOOLS_EXTENSION__()];
  }

  return enhancers;
}

function _enableHotLoader(store) {
  if (isLocalEnv() && module.hot) {
    module.hot.accept('./reducer', () => {
      const nextRootReducer = require('./reducer');
      store.replaceReducer(nextRootReducer);
    });
  }
}

const sensitiveDataTransform = createTransform(
  (instate) =>
    Rcompose(
      dissocPath(['config', 'user']),
      dissocPath(['session', 'gigyaUser']),
    )(instate),
  (outstate) => outstate,
  { whitelist: [CIAM_SDK_NAMESPACE] },
);
function _createPersistedReducer(reducers) {
  const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: [CIAM_SDK_NAMESPACE, 'isChangedPassword'],
    transforms: [sensitiveDataTransform],
  };

  return persistCombineReducers(persistConfig, reducers);
}

export const setForcePersist = async (action) => {
  const key = 'persist:root';
  // @ts-ignore
  const root = await window.store.persistConfig.storage.getItem(key);
  const json = JSON.parse(root);
  const ciamSdk = JSON.parse(json.ciamSdk);
  const isChangedPassword = JSON.parse(json.isChangedPassword);
  const _persist = JSON.parse(json._persist);

  ciamSdk.session = {
    ...ciamSdk.session,
    error: null,
    isGettingToken: false,
    accessToken: action.payload.accessToken,
    token: action.payload.accessToken,
    ...action.payload,
  };
  const e = JSON.stringify({
    ciamSdk: JSON.stringify(ciamSdk),
    isChangedPassword: JSON.stringify(isChangedPassword),
    _persist: JSON.stringify(_persist),
  });
  // @ts-ignore
  window.store?.persistConfig?.storage?.setItem(key, e);
};

export const configureStore = (initialState) => {
  const store = compose(_getMiddleware(), ..._getEnhancers())(createStore)(
    _createPersistedReducer(reducers),
    initialState,
  );

  const persistor = persistStore(store);

  _enableHotLoader(store);
  // persistor is attached to the store object so async modules
  // can invoke state persistence during their lifecycle.
  // See src/modules.tsx

  const _store = {
    ...store,
    persistor,
    persistConfig: {
      key: 'root',
      storage: storage,
      whitelist: [CIAM_SDK_NAMESPACE, 'isChangedPassword'],
      transforms: [sensitiveDataTransform],
    },
  };

  window.store = { ..._store };

  return {
    store: { ..._store },
    persistor,
    history,
  };
};
