export const BG_STATISTICS_DEVICES_KEYS = {
  BG_STATISTICS_DEVICE_SELECTED: 'BG Statistics Device Selected',
};

export const BG_STATISTICS_DEVICES_SEGMENT_KEYS = {
  DEVICE_SELECTED: 'Device Selected',
};

export const BG_STATISTICS_DEVICES_SEGMENT_VALUES = {
  ALL: 'All Devices',
  NO_NAME_DEVICE: 'No name device',
};
