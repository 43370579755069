import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { CurrentUser } from './current-user.types';
import { transformCurrentUser } from './current-user.util';
import { randomValue } from 'src/utils/stat.js';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const CurrentUserLoaderImpl = (accessToken: string) => {
  return getJSON(
    `${endpointWithParams(ENDPOINTS.currentUser, { random: randomValue(1) })}`,
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const CurrentUserTransformImpl = ({ model }: { model: CurrentUser }) =>
  transformCurrentUser(model.data);

export const CurrentUserServiceImpl =
  (loader, transform) => (accessToken, gigyaToken) =>
    loader(accessToken, gigyaToken).then(transform);
