import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import { getIn } from 'src/utils/ramda/ramda.utils';
import { filter, pathOr } from 'ramda';

export const selectLanguagesState = (state: State) =>
  getIn(['languages'], state);

export const selectLanguages = createSelector(
  [selectLanguagesState],
  (languagesState) => getIn(['activeLanguages'], languagesState),
);

export const selectLocaleByEditProfileId = (state: State) => {
  const id = pathOr('', ['editProfile', 'languageId'], state).toString();
  return filter((lang) => lang?.id === id, selectLanguages(state))[0].locale;
};
