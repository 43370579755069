import {
  ConnectSharingCodeActions,
  ConnectSharingCodeActionType,
  ConnectSharingCodeState,
} from './connect-sharing-code.actions.types';

export const connectSharingCodeInitialState: ConnectSharingCodeState = {
  isCheckingSharingCode: false,
  sharingCodeSuccessful: false,
  sharingCodeHasError: false,
  sharingCodeStatus: null,
  isAvailableStatus: null,
  patientData: {},
};

export const connectSharingCodeReducer = (
  state = connectSharingCodeInitialState,
  action: ConnectSharingCodeActions,
) => {
  switch (action.type) {
    case ConnectSharingCodeActionType.CONNECT_SHARING_CODE_START:
      return {
        ...state,
        isCheckingSharingCode: true,
        sharingCodeSuccessful: false,
        sharingCodeHasError: false,
      };
    case ConnectSharingCodeActionType.CONNECT_SHARING_CODE_SUCCESS:
      return {
        ...state,
        isCheckingSharingCode: false,
        sharingCodeSuccessful: true,
        sharingCodeHasError: false,
        patientData: action.payload,
        sharingCodeStatus: 200,
      };
    case ConnectSharingCodeActionType.CONNECT_SHARING_CODE_ERROR:
      return {
        ...state,
        isCheckingSharingCode: false,
        sharingCodeSuccessful: false,
        sharingCodeHasError: true,
        sharingCodeStatus: action.payload.status,
      };
    case ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_START:
      return {
        ...state,
      };
    case ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_SUCCESS:
    case ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_ERROR:
      return {
        ...state,
        isAvailableStatus: action.payload,
      };
    case ConnectSharingCodeActionType.CLEAR_SHARING_CODE_DATA:
      return {
        ...connectSharingCodeInitialState,
        isAvailableStatus: state.isAvailableStatus,
      };
    default:
      return state;
  }
};
