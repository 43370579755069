import {
  SaveClinicGuideLoaderImpl,
  SaveClinicGuideServiceImpl,
  SaveClinicGuideTransformImpl,
} from './save-clinic-guide.service';
import { mockSaveClinicGuideResponse } from './save-clinic-guide.mock';

const mockLoader = (q) => Promise.resolve(mockSaveClinicGuideResponse);

export const SaveClinicGuideFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : SaveClinicGuideLoaderImpl;

  return SaveClinicGuideServiceImpl(loader, SaveClinicGuideTransformImpl);
};
