import { colors } from 'src/core/styles/colors';

export const NUMBER_OF_DAYS_LAST_PULLED = 14;

export const NO_DATA_VALUE_DASHBOARD = '- - ';
export const NO_ENOUGH_DATA_VALUE_DASHBOARD = '!';
export const NO_DATE_VALUE_HYPO_HYPER = '- - - | - - : - -';
export const PERCENTAGE_UNIT_GLUCOSE = '%';

export const GLUCOSE_VERY_LOW_COLOR = '#cf031c';
export const GLUCOSE_LOW_COLOR = '#f5a724';
export const GLUCOSE_LO_COLOR = '#cf031c';
export const GLUCOSE_TARGET_COLOR = colors.trafficGreen;
export const GLUCOSE_HIGH_COLOR = colors.blueMarine;
export const GLUCOSE_VERY_HIGH_COLOR = colors.blueMarine;
export const GLUCOSE_HI_COLOR = colors.blueMarine;

export const CGM_GLUCOSE_VERY_LOW_COLOR = colors.hypo;
export const CGM_GLUCOSE_LOW_COLOR = colors.warning;
export const CGM_GLUCOSE_TARGET_COLOR = colors.inTarget;
export const CGM_GLUCOSE_HIGH_COLOR = colors.hyper;
export const CGM_GLUCOSE_VERY_HIGH_COLOR = colors.actualHyper;

export const RPM_RED_COLOR = '#d00044';

export const GLUCOSE_LOW_TYPE = 'LOW';
export const GLUCOSE_HIGH_TYPE = 'HIGH';
export const GLUCOSE_LO_TYPE = 'LO';
export const GLUCOSE_HI_TYPE = 'HI';

export const ALL_PATIENTS_LIST_SORT = 'allPatients';
export const FILTER_PATIENTS_LIST_SORT = 'filterPatients';
export const RPM_PROGRAM_NAME = 'RPM';
export const DIABETES_TYPES_PENDING = 'PENDING_TO_IDENTIFY';
export const SHORTCUT_BUTTON = 'Shortcut button';
