import React from 'react';
import { AutoSizer, List } from 'react-virtualized';

export const ResponsiveVirtualizedList = ({
  rowCount = 0,
  overscanRowCount = 1,
  rowHeight = 0,
  rowRenderer = () => <React.Fragment />,
  scrollToAlignment = 'start',
  scrollToIndex = 0,
}) => (
  <AutoSizer>
    {({ height, width }) => (
      <List
        height={height}
        width={width}
        rowCount={rowCount}
        rowHeight={rowHeight}
        rowRenderer={({ key, index, isScrolling, isVisible, style }) => (
          <div key={key} style={style}>
            {rowRenderer(index, isScrolling, isVisible)}
          </div>
        )}
        overscanRowCount={overscanRowCount}
        scrollToAlignment={scrollToAlignment}
        scrollToIndex={scrollToIndex}
      />
    )}
  </AutoSizer>
);
