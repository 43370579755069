import { CountlySystem, getConfig } from '@roche/roche-common';

const { REACT_APP_COUNTLY_DEBUG } = getConfig();

export const countlyEventTrigger = (
  eventTitle: string,
  segment: { [segKey: string]: any },
): void => {
  if (REACT_APP_COUNTLY_DEBUG === 'true') {
    console.log('Countly event', {
      eventTitle,
      segment,
    });
  } else {
    const countly = new CountlySystem(window.Countly);
    if (countly.Countly) {
      countly.trackCustomEvent(eventTitle, segment);
    }
  }
};
