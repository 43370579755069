import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  InvitePatientActionType,
  InvitePatientErrorAction,
  InvitePatientErrorPayload,
  InvitePatientPayloadType,
  InvitePatientStartAction,
  InvitePatientSuccessAction,
  InvitePatientSuccessPayload,
  ResetInviteAction,
} from './invite-patient.types';

export const invitePatientStart = (
  payload: InvitePatientPayloadType,
): InvitePatientStartAction =>
  createPayloadAction(InvitePatientActionType.INVITE_PATIENT_START, payload);

export const invitePatientSuccess = (
  payload: InvitePatientSuccessPayload,
): InvitePatientSuccessAction =>
  createPayloadAction(InvitePatientActionType.INVITE_PATIENT_SUCCESS, payload);

export const invitePatientError = (
  payload: InvitePatientErrorPayload,
): InvitePatientErrorAction =>
  createPayloadAction(InvitePatientActionType.INVITE_PATIENT_ERROR, payload);

export const resetInvitePatient = (): ResetInviteAction =>
  createAction(InvitePatientActionType.RESET_INVITE);
