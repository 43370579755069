/* tslint:disable */
/* eslint:disable */
export const mockDeviceSettings = [{}];

export const mockQueryParams = {
  deviceSerialNumber: 'magic serial',
  patientId: 'magic id',
  patientType: 'magic type',
  includePrevious: true,
  version: 1,
};

export const mockParams = {
  ...mockQueryParams,
  version: '1',
};

export const mockDeviceSettingsResponse = [
  {
    version: 2,
    created: '2020-03-30T15:03:56Z',
    settings: {
      TimeStamp: '2020-03-25T08:48:56.561978Z',

      Device: {
        DeviceInformation: {
          DeviceType: 'insulinpump',
          ModelNumber: '951',
          SerialNumber: 'FakeSerial_2',
          ModelName: 'ACCU-CHEK Performa Insight',
        },

        PumpPairingStatus: {
          Paired: true,
          Connected: true,
          SerialNumberConnectedPump: 'DM_01223344',
        },

        UnitsSettings: {
          TimeFormat: '24h',
          Carbohydrates: 'g',
          BloodGlucose: 'mg/dL',
          Insuline: 'U',
        },

        TimeBlocks: [
          {
            Name: 'TimeBlock1',
            StartTime: '01:00:00',
            EndTime: '11:59:00',
            TargetRanges: {
              // "Low": 80,
              High: 130,
            },
            CarbRatio: {
              Insulin: 1.1,
              // "Carbohydrates":4
            },
            InsulinSensitivity: {
              Insulin: 1,
              BloodGlucose: 10,
            },
          },
          {
            Name: 'TimeBlock2',
            StartTime: '02:00:00',
            EndTime: '12:59:00',
            TargetRanges: {
              Low: 80,
              High: 130,
            },
            CarbRatio: {
              Insulin: 1.1,
              Carbohydrates: 4,
            },
            InsulinSensitivity: {
              Insulin: 1,
              BloodGlucose: 10,
            },
          },
        ],

        HealthEvents: {
          Exercise1: 30,
          Exercise2: 20,
          Stress: -32,
          Illness: 2,
          Premenstrual: 0,
        },
      },

      Reminders: {
        BgTestReminder: {
          AfterHighBG: {
            Enabled: true,
            Duration: 60,
            EventThreshold: 120,
            CarbThreshold: 10,
          },
          AfterLowBG: {
            Enabled: false,
            Duration: 60,
            EventThreshold: 120,
            CarbThreshold: 10,
          },
          AfterMeal: {
            Enabled: true,
            Duration: 60,
            EventThreshold: 120,
            CarbThreshold: 10,
          },
        },

        AppointmentReminders: [
          {
            Type: 'drvisit',
            Status: 'On',
            Date: '2020-02-07',
            Time: '12:30:00',
          },
          {
            Type: 'drvisit',
            Status: 'Off',
            Date: '2019-05-10',
            Time: '20:31:00',
          },
          {
            Type: 'labtest',
            Status: 'On',
            Date: '2020-02-07',
            Time: '12:30:00',
          },
          {
            Type: 'labtest',
            Status: 'Off',
            Date: '2020-03-16',
            Time: '21:01:00',
          },
          {
            Type: 'custom',
            Name: 'Pick up strips',
            Status: 'On',
            Date: '2020-03-10',
            Time: '09:15:00',
          },
          {
            Type: 'custom',
            Name: 'Pick up strips at center every month',
            Status: 'Off',
            Date: '2020-04-10',
            Time: '09:16:00',
          },
        ],

        AlarmClockReminders: [
          {
            Type: 'bgtest',
            Name: '1st daily measurement',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '08:00:00',
          },
          {
            Type: 'bgtest',
            Name: 'before lunch BG Test',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '12:30:00',
          },
          {
            Type: 'bgtest',
            Name: 'before dinner BG test',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '20:27:00',
          },
          {
            Type: 'bgtest',
            Name: 'before dinner BG test',
            Status: 'Off',
            Recurrence: 'Repeat',
            Time: '21:00:00',
          },
          {
            Type: 'injection',
            Name: 'Daily BASAL injection',
            Status: 'On',
            Recurrence: 'Once',
            Time: '12:30:00',
          },
          {
            Type: 'injection',
            Name: 'Bolus Reminder',
            Status: 'On',
            Recurrence: 'Daily',
            Time: '11:25:00',
          },
          {
            Type: 'medication',
            Name: 'take OAD',
            Status: 'On',
            Recurrence: 'Daily',
            Time: '12:30:00',
          },
          {
            Type: 'other',
            Name: 'wake up',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '08:00:00',
          },
          {
            Type: 'other',
            Name: 'Snack Time',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '11:15:00',
          },
          {
            Type: 'other',
            Name: 'Go outside for some running!',
            Status: 'Off',
            Recurrence: 'Repeat',
            Time: '11:15:00',
          },
        ],

        PumpReminders: [
          {
            Type: 'deliverbolus',
            Name: 'Good morning Bolus',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '09:30:00',
          },
          {
            Type: 'deliverbolus',
            Name: 'Good afternoon Bolus',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '14:30:00',
          },
          {
            Type: 'deliverbolus',
            Name: 'Good evening Bolus',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '17:30:00',
          },
          {
            Type: 'missedbolus',
            Name: 'Whatever',
            Status: 'Off',
            Recurrence: 'Repeat',
            Time: '12:30:00',
          },
          {
            Type: 'infusionsetchange',
            Name: 'Whatever',
            Status: 'Off',
            Recurrence: 'Repeat',
            Time: '12:30:00',
          },
        ],
      },

      BolusSettings: {
        DeliverySpeed: 'moderate',
        BolusIncrement: 0.5,
        MaxBolusQuantity: 50,
        LagStatus: 'Off',
        MaxBolusDuration: 10,

        BolusAdvice: {
          Enabled: false,
          ActiveTime: 120,
          Offset: 10,
          MealRize: 20,
          SnackLimit: 40,
        },
      },

      BasalSettings: {
        ActiveProfile: 1,
        MaximunHourlyBasalRate: 25.52,
        TemporaryBasalRateMaximumAdjustment: 250,
        TemporaryBasalRateMaximunDuration: 600,

        BasalIPProfiles: [
          {
            Name: 'Profile 1',
            IdProfile: 1,
            InsulinUnitsPerDay: 18.2,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '06:59:00',
                // "InsulinUnits": 0.11
              },
              {
                StartTime: '07:00:00',
                EndTime: '09:59:00',
                InsulinUnits: 0.51,
              },
              {
                StartTime: '10:00:00',
                EndTime: '12:59:00',
                // "InsulinUnits": 0.21
              },
              {
                StartTime: '13:00:00',
                EndTime: '16:59:00',
                InsulinUnits: 0.9,
              },
              {
                StartTime: '17:00:00',
                EndTime: '19:59:00',
                InsulinUnits: 0.3,
              },
              {
                StartTime: '20:00:00',
                EndTime: '21:59:00',
                InsulinUnits: 0.61,
              },
              {
                StartTime: '22:00:00',
                EndTime: '23:59:00',
                InsulinUnits: 0.31,
              },
            ],
          },
          {
            Name: 'Profile 4',
            IdProfile: 4,
            // "InsulinUnitsPerDay": 16.2,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '06:59:00',
                InsulinUnits: 0.1,
              },
              {
                StartTime: '07:00:00',
                EndTime: '09:59:00',
                InsulinUnits: 0.5,
              },
              {
                StartTime: '10:00:00',
                EndTime: '12:59:00',
                InsulinUnits: 0.2,
              },
              {
                StartTime: '13:00:00',
                EndTime: '16:59:00',
                InsulinUnits: 0.9,
              },
              {
                StartTime: '17:00:00',
                EndTime: '19:59:00',
                InsulinUnits: 0.3,
              },
              {
                StartTime: '20:00:00',
                EndTime: '21:59:00',
                InsulinUnits: 0.6,
              },
              // {
              //     "StartTime":"22:00:00",
              //     "EndTime":  "23:59:00",
              //     "InsulinUnits": 0.30
              // }
            ],
          },
          {
            Name: 'Profile 3',
            IdProfile: 3,
            InsulinUnitsPerDay: 0,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '06:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '07:00:00',
                EndTime: '09:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '10:00:00',
                EndTime: '12:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '13:00:00',
                EndTime: '16:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '17:00:00',
                EndTime: '19:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '20:00:00',
                EndTime: '21:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '22:00:00',
                EndTime: '23:59:00',
                InsulinUnits: 0,
              },
            ],
          },
          {
            Name: 'Profile 2',
            IdProfile: 2,
            InsulinUnitsPerDay: 21.1,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '06:59:00',
                InsulinUnits: 0.1,
              },
              {
                StartTime: '07:00:00',
                EndTime: '09:59:00',
                InsulinUnits: 0.5,
              },
              {
                StartTime: '10:00:00',
                EndTime: '12:59:00',
                InsulinUnits: 0.2,
              },
              {
                StartTime: '13:00:00',
                EndTime: '16:59:00',
                InsulinUnits: 0.9,
              },
              {
                StartTime: '17:00:00',
                EndTime: '19:59:00',
                InsulinUnits: 0.3,
              },
              {
                StartTime: '20:00:00',
                EndTime: '21:59:00',
                InsulinUnits: 0.6,
              },
              {
                StartTime: '22:00:00',
                EndTime: '23:59:00',
                InsulinUnits: 0.3,
              },
            ],
          },
          {
            Name: 'Profile 5',
            IdProfile: 5,
            InsulinUnitsPerDay: 1.2,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '10:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 3.0,
              },
              {
                StartTime: '11:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 2.0,
              },
              {
                StartTime: '12:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 3.0,
              },
              {
                StartTime: '13:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 4.0,
              },
              {
                StartTime: '14:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '15:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '16:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 2.0,
              },
            ],
          },
          {
            Name: 'Profile 6',
            IdProfile: 6,
            InsulinUnitsPerDay: 1.2,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '10:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 3.0,
              },
              {
                StartTime: '11:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 2.0,
              },
              {
                StartTime: '12:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 3.0,
              },
              {
                StartTime: '13:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 4.0,
              },
              {
                StartTime: '14:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '15:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '16:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 2.0,
              },
            ],
          },
        ],

        CustomTBR: [
          {
            Name: 'Sports',
            Adjustment: 100,
            Duration: 20,
          },
          {
            Name: 'Swimming',
            Adjustment: -22,
            // "Duration": 10
          },
          {
            Name: 'Laying on the beach, having a chilly beer',
            Adjustment: 100,
            Duration: 1,
          },
        ],
      },
      PumpSettings: {
        InsulinIncrement: 1,
        CartridgeLowAlarmQuantity: 100,
        InfusionSetCannulaFillAmount: 0.7,
        InfusionSetTubingFillAmount: 11.0,
        BolusIncrement: 0.9,
        PrimeQuantity: 20,
      },
    },
  },
  {
    version: 1,
    created: '2020-03-30T15:03:56Z',
    settings: {
      TimeStamp: '2020-03-23T08:48:56.561978Z',

      Device: {
        DeviceInformation: {
          DeviceType: 'insulinpump',
          ModelNumber: '951',
          SerialNumber: 'FakeSerial_2',
          ModelName: 'ACCU-CHEK Performa Insight',
        },

        PumpPairingStatus: {
          Paired: true,
          Connected: true,
          SerialNumberConnectedPump: 'DM_01223344',
        },

        UnitsSettings: {
          TimeFormat: '24h',
          Carbohydrates: 'g',
          BloodGlucose: 'mg/dL',
          Insuline: 'U',
        },

        TimeBlocks: [
          {
            Name: 'TimeBlock1',
            StartTime: '01:00:00',
            EndTime: '11:59:00',
            TargetRanges: {
              // "Low": 80,
              High: 130,
            },
            CarbRatio: {
              Insulin: 1.1,
              // "Carbohydrates":4
            },
            InsulinSensitivity: {
              Insulin: 1,
              BloodGlucose: 10,
            },
          },
          {
            Name: 'TimeBlock2',
            StartTime: '02:00:00',
            EndTime: '12:59:00',
            TargetRanges: {
              Low: 80,
              High: 130,
            },
            CarbRatio: {
              Insulin: 1.1,
              Carbohydrates: 4,
            },
            InsulinSensitivity: {
              Insulin: 1,
              BloodGlucose: 10,
            },
          },
        ],

        HealthEvents: {
          Exercise1: 30,
          Exercise2: 20,
          Stress: -32,
          Illness: 2,
          Premenstrual: 0,
        },
      },

      Reminders: {
        BgTestReminder: {
          AfterHighBG: {
            Enabled: true,
            Duration: 60,
            EventThreshold: 120,
            CarbThreshold: 10,
          },
          AfterLowBG: {
            Enabled: false,
            Duration: 60,
            EventThreshold: 120,
            CarbThreshold: 10,
          },
          AfterMeal: {
            Enabled: true,
            Duration: 60,
            EventThreshold: 120,
            CarbThreshold: 10,
          },
        },

        AppointmentReminders: [
          {
            Type: 'drvisit',
            Status: 'On',
            Date: '2020-02-07',
            Time: '12:30:00',
          },
          {
            Type: 'drvisit',
            Status: 'Off',
            Date: '2019-05-10',
            Time: '20:31:00',
          },
          {
            Type: 'labtest',
            Status: 'On',
            Date: '2020-02-07',
            Time: '12:30:00',
          },
          {
            Type: 'labtest',
            Status: 'Off',
            Date: '2020-03-16',
            Time: '21:01:00',
          },
          {
            Type: 'custom',
            Name: 'Pick up strips',
            Status: 'On',
            Date: '2020-03-10',
            Time: '09:15:00',
          },
          {
            Type: 'custom',
            Name: 'Pick up strips at center every month',
            Status: 'Off',
            Date: '2020-04-10',
            Time: '09:16:00',
          },
        ],

        AlarmClockReminders: [
          {
            Type: 'bgtest',
            Name: '1st daily measurement',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '08:00:00',
          },
          {
            Type: 'bgtest',
            Name: 'before lunch BG Test',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '12:30:00',
          },
          {
            Type: 'bgtest',
            Name: 'before dinner BG test',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '20:27:00',
          },
          {
            Type: 'bgtest',
            Name: 'before dinner BG test',
            Status: 'Off',
            Recurrence: 'Repeat',
            Time: '21:00:00',
          },
          {
            Type: 'injection',
            Name: 'Daily BASAL injection',
            Status: 'On',
            Recurrence: 'Once',
            Time: '12:30:00',
          },
          {
            Type: 'injection',
            Name: 'Bolus Reminder',
            Status: 'On',
            Recurrence: 'Daily',
            Time: '11:25:00',
          },
          {
            Type: 'medication',
            Name: 'take OAD',
            Status: 'On',
            Recurrence: 'Daily',
            Time: '12:30:00',
          },
          {
            Type: 'other',
            Name: 'wake up',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '08:00:00',
          },
          {
            Type: 'other',
            Name: 'Snack Time',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '11:15:00',
          },
          {
            Type: 'other',
            Name: 'Go outside for some running!',
            Status: 'Off',
            Recurrence: 'Repeat',
            Time: '11:15:00',
          },
        ],

        PumpReminders: [
          {
            Type: 'deliverbolus',
            Name: 'Good morning Bolus',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '09:30:00',
          },
          {
            Type: 'deliverbolus',
            Name: 'Good afternoon Bolus',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '14:30:00',
          },
          {
            Type: 'deliverbolus',
            Name: 'Good evening Bolus',
            Status: 'On',
            Recurrence: 'Repeat',
            Time: '17:30:00',
          },
          {
            Type: 'missedbolus',
            Name: 'Whatever',
            Status: 'Off',
            Recurrence: 'Repeat',
            Time: '12:30:00',
          },
          {
            Type: 'infusionsetchange',
            Name: 'Whatever',
            Status: 'Off',
            Recurrence: 'Repeat',
            Time: '12:30:00',
          },
        ],
      },

      BolusSettings: {
        DeliverySpeed: 'moderate',
        BolusIncrement: 0.5,
        MaxBolusQuantity: 50,
        LagStatus: 'Off',
        MaxBolusDuration: 10,

        BolusAdvice: {
          Enabled: false,
          ActiveTime: 120,
          Offset: 10,
          MealRize: 20,
          SnackLimit: 40,
        },
      },

      BasalSettings: {
        ActiveProfile: 1,
        MaximunHourlyBasalRate: 25.52,
        TemporaryBasalRateMaximumAdjustment: 250,
        TemporaryBasalRateMaximunDuration: 600,

        BasalIPProfiles: [
          {
            Name: 'Profile 1',
            IdProfile: 1,
            InsulinUnitsPerDay: 18.2,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '06:59:00',
                // "InsulinUnits": 0.11
              },
              {
                StartTime: '07:00:00',
                EndTime: '09:59:00',
                InsulinUnits: 0.51,
              },
              {
                StartTime: '10:00:00',
                EndTime: '12:59:00',
                // "InsulinUnits": 0.21
              },
              {
                StartTime: '13:00:00',
                EndTime: '16:59:00',
                InsulinUnits: 0.9,
              },
              {
                StartTime: '17:00:00',
                EndTime: '19:59:00',
                InsulinUnits: 0.3,
              },
              {
                StartTime: '20:00:00',
                EndTime: '21:59:00',
                InsulinUnits: 0.61,
              },
              {
                StartTime: '22:00:00',
                EndTime: '23:59:00',
                InsulinUnits: 0.31,
              },
            ],
          },
          {
            Name: 'Profile 4',
            IdProfile: 4,
            // "InsulinUnitsPerDay": 16.2,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '06:59:00',
                InsulinUnits: 0.1,
              },
              {
                StartTime: '07:00:00',
                EndTime: '09:59:00',
                InsulinUnits: 0.5,
              },
              {
                StartTime: '10:00:00',
                EndTime: '12:59:00',
                InsulinUnits: 0.2,
              },
              {
                StartTime: '13:00:00',
                EndTime: '16:59:00',
                InsulinUnits: 0.9,
              },
              {
                StartTime: '17:00:00',
                EndTime: '19:59:00',
                InsulinUnits: 0.3,
              },
              {
                StartTime: '20:00:00',
                EndTime: '21:59:00',
                InsulinUnits: 0.6,
              },
              // {
              //     "StartTime":"22:00:00",
              //     "EndTime":  "23:59:00",
              //     "InsulinUnits": 0.30
              // }
            ],
          },
          {
            Name: 'Profile 3',
            IdProfile: 3,
            InsulinUnitsPerDay: 0,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '06:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '07:00:00',
                EndTime: '09:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '10:00:00',
                EndTime: '12:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '13:00:00',
                EndTime: '16:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '17:00:00',
                EndTime: '19:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '20:00:00',
                EndTime: '21:59:00',
                InsulinUnits: 0,
              },
              {
                StartTime: '22:00:00',
                EndTime: '23:59:00',
                InsulinUnits: 0,
              },
            ],
          },
          {
            Name: 'Profile 2',
            IdProfile: 2,
            InsulinUnitsPerDay: 21.1,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '06:59:00',
                InsulinUnits: 0.1,
              },
              {
                StartTime: '07:00:00',
                EndTime: '09:59:00',
                InsulinUnits: 0.5,
              },
              {
                StartTime: '10:00:00',
                EndTime: '12:59:00',
                InsulinUnits: 0.2,
              },
              {
                StartTime: '13:00:00',
                EndTime: '16:59:00',
                InsulinUnits: 0.9,
              },
              {
                StartTime: '17:00:00',
                EndTime: '19:59:00',
                InsulinUnits: 0.3,
              },
              {
                StartTime: '20:00:00',
                EndTime: '21:59:00',
                InsulinUnits: 0.6,
              },
              {
                StartTime: '22:00:00',
                EndTime: '23:59:00',
                InsulinUnits: 0.3,
              },
            ],
          },
          {
            Name: 'Profile 5',
            IdProfile: 5,
            InsulinUnitsPerDay: 1.2,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '10:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 3.0,
              },
              {
                StartTime: '11:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 2.0,
              },
              {
                StartTime: '12:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 3.0,
              },
              {
                StartTime: '13:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 4.0,
              },
              {
                StartTime: '14:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '15:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '16:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 2.0,
              },
            ],
          },
          {
            Name: 'Profile 6',
            IdProfile: 6,
            InsulinUnitsPerDay: 1.2,
            IPTimeBlocks: [
              {
                StartTime: '00:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '10:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 3.0,
              },
              {
                StartTime: '11:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 2.0,
              },
              {
                StartTime: '12:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 3.0,
              },
              {
                StartTime: '13:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 4.0,
              },
              {
                StartTime: '14:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '15:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 1.0,
              },
              {
                StartTime: '16:00:00',
                EndTime: '00:00:00',
                InsulinUnits: 2.0,
              },
            ],
          },
        ],

        CustomTBR: [
          {
            Name: 'Sports',
            Adjustment: 100,
            Duration: 20,
          },
          {
            Name: 'Swimming',
            Adjustment: -22,
            // "Duration": 10
          },
          {
            Name: 'Laying on the beach, having a chilly beer',
            Adjustment: 100,
            Duration: 1,
          },
        ],
      },
      PumpSettings: {
        InsulinIncrement: 1,
        CartridgeLowAlarmQuantity: 150,
        InfusionSetCannulaFillAmount: 0.7,
        InfusionSetTubingFillAmount: 11.0,
        BolusIncrement: 0.9,
        PrimeQuantity: 20,
      },
    },
  },
];
