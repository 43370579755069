/* tslint:disable */

import * as React from 'react';

import { ToggleSwitchWrapperLabel } from 'src/components/toggle-switch/toggle-switch.style';

import {
  ToggleSwitchInputPrimary,
  ToggleSwitchButtonPrimary,
  ToggleSwitchCirclePrimary,
} from './toggle-switch-primary.style';

export class ToggleSwitchPrimary extends React.Component<any, any> {
  static defaultProps = {
    id: '0',
  };

  render() {
    return (
      <ToggleSwitchWrapperLabel>
        <ToggleSwitchInputPrimary
          type="checkbox"
          name={this.props.Name}
          id={this.props.id}
          checked={this.props.disabled ? false : this.props.currentValue}
          defaultChecked={this.props.defaultChecked}
          onChange={this.handleToggleChange}
          disabled={this.props.disabled}
        />
        <ToggleSwitchButtonPrimary>
          <ToggleSwitchCirclePrimary />
        </ToggleSwitchButtonPrimary>
      </ToggleSwitchWrapperLabel>
    );
  }

  handleToggleChange = (e) => {
    if (typeof this.props.handleToggleChange === 'function') {
      this.props.handleToggleChange(e.target.checked);
    }
  };
}
