// tslint:disable:no-identical-functions
import * as React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  ModalBodyExportTT,
  Row,
  Title,
  Description,
  CalendarBox,
  ModalCardReportTimeTracker,
  ModalHeaderTT,
} from './time-tracker-export-modal.style';
import {
  ModalBody,
  ModalOverlay,
  ModalWrapper,
} from 'src/components-ds/modal/modal.style';
import { SymbolButton } from 'src/components-ds/symbol-button/symbol-button.component';

import { colors } from 'src/components-ds/colors';
import { Button } from 'src/components/button/button.component';
import {
  TimeTrackerExportModalState,
  TimeTrackerExportModalProps,
  GraphInfo,
} from './time-tracker-export-modal.types';
import { Calendar } from './components/calendar';

export class TimeTrackerExportModalComponent extends React.Component<
  TimeTrackerExportModalProps,
  TimeTrackerExportModalState
> {
  public moment = require('moment');
  constructor(props) {
    super(props);
    const startRange = 2;
    this.state = {
      startDate: this.moment().subtract(startRange, 'days').endOf('day'),
      endDate: this.moment().endOf('day'),
      range: startRange,
    };
  }

  public handleSaveButtonModal() {
    const {
      data: { onSubmitModal },
    } = this.props;
    if (onSubmitModal) {
      onSubmitModal({
        hcpId: '123456',
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        range: this.state.range,
      });
    }
  }

  public onDatesChange = (startDate, endDate, range) => {
    this.setState({ startDate, endDate, range });
  };

  public renderCalendar = () => {
    const { locale } = this.props.data;
    const maxYears = 1;
    const wrapWidth = window.outerWidth;
    const graphInfo: GraphInfo = {
      endDate: this.state.endDate.toISOString(),
      startDate: this.state.startDate.toISOString(),
      maxDate: new Date().toISOString(),
      minDate: this.moment(new Date())
        .subtract(maxYears, 'years')
        .toDate()
        .toISOString(),
    };
    return (
      <Calendar
        dateInfo={graphInfo}
        wrapWidth={wrapWidth}
        onDatesChangeSubmit={this.onDatesChange}
        locale={locale}
        showArrows={false}
        id={'calendarReportTimeTracker'}
        className={'calendarReportTT'}
      />
    );
  };

  public render() {
    const { t } = this.props;
    return (
      <>
        <ModalOverlay />
        <ModalWrapper>
          <ModalCardReportTimeTracker>
            <ModalBody>
              <ModalHeaderTT>
                <span>{t('timetracker.reportModal.header')}</span>
                <SymbolButton
                  onClick={() => {
                    this.props.destroyModal();
                  }}
                  color={colors.white}
                >
                  X
                </SymbolButton>
              </ModalHeaderTT>
              <ModalBodyExportTT>
                <Row>
                  <Title>{t('timetracker.reportModal.title')}</Title>
                </Row>
                <Row>
                  <Description>
                    {t('timetracker.reportModal.description')}
                  </Description>
                </Row>
                <Row>
                  <CalendarBox>{this.renderCalendar()}</CalendarBox>
                </Row>
                <Row>
                  <Button
                    onClick={() => {
                      this.handleSaveButtonModal();
                    }}
                    label={t('timetracker.reportModal.button')}
                  />
                </Row>
              </ModalBodyExportTT>
            </ModalBody>
          </ModalCardReportTimeTracker>
        </ModalWrapper>
      </>
    );
  }
}

export const TimeTrackerExportModal = withTranslation(
  TimeTrackerExportModalComponent,
);
