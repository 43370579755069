import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';

import { PATIENTS_ACTIONS } from './patients.constant';
import { setPatients } from './patients.action';

export const getPatientsListEpic = (patientListService) => (action$, store) =>
  action$.pipe(
    ofType(PATIENTS_ACTIONS.GET_PATIENTS),
    mergeMap(
      () =>
        patientListService(selectAccessToken(store.getState()))
          .then((patients) => setPatients(patients))
          .catch((err) => err), // dispatch error action when refactored
    ),
  );
