import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  EditPatientWithFhirActionType,
  EditPatientWithFhirStartAction,
  EditPatientWithFhirSuccessAction,
  EditPatientWithFhirErrorAction,
  EditPatientWithFhirStartPayloadType,
  ClearEditPatientWithFhitStateAction,
} from './edit-patient-with-fhir.types';

export const editPatientWithFhirStart = (
  payload: EditPatientWithFhirStartPayloadType,
): EditPatientWithFhirStartAction =>
  createPayloadAction(
    EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_START,
    payload,
  );

export const editPatientWithFhirSuccess = (
  payload: number,
): EditPatientWithFhirSuccessAction =>
  createPayloadAction(
    EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_SUCCESS,
    payload,
  );

export const editPatientWithFhirError = (
  payload: string,
): EditPatientWithFhirErrorAction =>
  createPayloadAction(
    EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_ERROR,
    payload,
  );

export const clearEditPatientWithFhirState =
  (): ClearEditPatientWithFhitStateAction =>
    createAction(
      EditPatientWithFhirActionType.CLEAR_EDIT_PATIENT_WITH_FHIR_STATE,
    );
