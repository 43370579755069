import { Action, PayloadAction } from 'src/app/store/app.types';
import {
  CheckEmailParams,
  CheckEmailResponse,
} from 'src/services/check-email/check-email.types';

export enum CheckDuplicatedEmailActionType {
  CHECK_DUPLICATED_EMAIL_START = 'CHECK_DUPLICATED_EMAIL_START',
  CHECK_DUPLICATED_EMAIL_SUCCESS = 'CHECK_DUPLICATED_EMAIL_SUCCESS',
  CHECK_DUPLICATED_EMAIL_ERROR = 'CHECK_DUPLICATED_EMAIL_ERROR',
  RESET_DUPLICATED_EMAIL = 'RESET_DUPLICATED_EMAIL',
}

export type CheckDuplicatedEmailStartPayload = CheckEmailParams;

export type CheckDuplicatedEmailSuccessPayload = CheckEmailResponse;

export type CheckDuplicatedEmailErrorPayload = CheckEmailResponse;

export type CheckDuplicatedEmailStartAction = PayloadAction<
  CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_START,
  CheckDuplicatedEmailStartPayload
>;

export type CheckDuplicatedEmailSuccessAction = PayloadAction<
  CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_SUCCESS,
  CheckDuplicatedEmailSuccessPayload
>;

export type CheckDuplicatedEmailErrorAction = PayloadAction<
  CheckDuplicatedEmailActionType.CHECK_DUPLICATED_EMAIL_ERROR,
  CheckDuplicatedEmailErrorPayload
>;

export type ResetDuplicatedEmailAction =
  Action<CheckDuplicatedEmailActionType.RESET_DUPLICATED_EMAIL>;

export type CheckDuplicatedEmailActions =
  | CheckDuplicatedEmailStartAction
  | CheckDuplicatedEmailSuccessAction
  | CheckDuplicatedEmailErrorAction
  | ResetDuplicatedEmailAction;

export type CheckDuplicatedEmailState = {
  isCheckingDuplicatedEmail: boolean;
  checkDuplicatedEmailWasSuccessful: boolean;
  checkDuplicatedEmailHasError: boolean;
  isEmailDuplicated: boolean | null;
};
