import * as React from 'react';
import { t } from 'i18next';
import {
  IconsViewComponentSections,
  IconsSections,
  IconsViewComponentBlock,
} from './icons-view.style';
import {
  FlexWrapperDiv,
  BlockTitle,
  TextBlock,
  SingleBlockText,
} from '../../manufacturer-info-modal.style';
import {
  appendSuffixIfRebranded,
  getCurrentAppVersion,
} from '../../manufacturer-info-modal.utils';
import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import { GeneralIconsComponent } from './components/general-icons/general-icons.component';
import { TrendGraphIconsComponent } from './components/trend-graph-icons/trend-graph-icons.component';
import { MetabolicRateIconsComponent } from './components/metabolic-rate-icons/metabolic-rate-icons.component';
import { LogbookIconsComponent } from './components/logbook-icons/logbook-icons.component';
import { StatusIconsComponent } from './components/status-icons/status-icons.component';
import { BolusIconsComponent } from './components/bolus-icons/bolus-icons.component';

type Props = {
  rebranding: boolean;
};

const sectionsIcons = [
  GeneralIconsComponent(),
  TrendGraphIconsComponent(),
  MetabolicRateIconsComponent(),
  LogbookIconsComponent(),
  StatusIconsComponent(),
  BolusIconsComponent(),
];

export const IconsViewComponent = ({ rebranding }: Props) => {
  const currentAppVersion = getCurrentAppVersion(rebranding);
  const genInfoText1_acc = t('manufacturerInfo.genInfoText1_ACC', {
    version: currentAppVersion,
  });

  return (
    <FlexWrapperDiv>
      <IconsViewComponentBlock>
        <BlockTitle>
          <jello-text
            class="jello-text-demo__component"
            size={JELLO_TEXT_SIZES.XL}
            weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
          >
            {t('manufacturerInfo.icons')}
          </jello-text>
        </BlockTitle>
        <TextBlock>
          <SingleBlockText>
            <jello-text
              class="jello-text-demo__component"
              size={JELLO_TEXT_SIZES.S}
            >
              {genInfoText1_acc}
            </jello-text>
          </SingleBlockText>
        </TextBlock>
        <TextBlock style={{ marginBottom: '25px' }}>
          <jello-text
            class="jello-text-demo__component"
            size={JELLO_TEXT_SIZES.S}
          >
            {`${t(
              appendSuffixIfRebranded(
                'manufacturerInfo.genInfoText2',
                rebranding,
              ),
            )}`}
          </jello-text>
        </TextBlock>
        <IconsViewComponentSections>
          <IconsSections>{sectionsIcons}</IconsSections>
        </IconsViewComponentSections>
      </IconsViewComponentBlock>
    </FlexWrapperDiv>
  );
};
