export const EVENTS_KEYS = {
  USER_SIGNED_OUT: 'User Signed Out',
};

export const SEGMENT_KEYS = {
  SIGNOUT_METHOD: 'Signout Method',
};

export const SEGMENT_VALUES = {
  MANUAL: 'Manual',
};
