import * as React from 'react';
import * as H from 'history';
import { connect } from 'react-redux';
import { TFunction } from 'i18next';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { matchPath, withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { Button } from 'src/components/button/button.component';
import { ButtonSizes } from 'src/components/button/button.types';
import {
  selectBgPatientsNumber,
  selectCgmPatientsNumber,
  selectIsSearching,
} from 'src/domains/general/widgets/store/hcp-dashboard.selectors';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { HcpDashboardSelectorContainer } from './hcp-dashboard-selector.style';

type HcpDashboardSelectorProps = {
  t: TFunction;
  location: H.Location<{}>;
  routes: FixMe;
  bgPatientsNumber: number;
  cgmPatientsNumber: number;
  isSearching: boolean;
};

export class HcpDashboardSelectorComponent extends React.Component<HcpDashboardSelectorProps> {
  public render() {
    const { t, routes, bgPatientsNumber, cgmPatientsNumber, isSearching } =
      this.props;

    const isBGActive = !!matchPath(this.props.location.pathname, {
      path: routes.general.home,
      exact: true,
    });
    const isCGMActive = !!matchPath(this.props.location.pathname, {
      path: routes.general.homeCgm,
      exact: true,
    });

    const bgNumberDisplay = isSearching ? '' : `(${bgPatientsNumber})`;
    const cgmNumberDisplay = isSearching ? '' : `(${cgmPatientsNumber})`;

    return (
      <HcpDashboardSelectorContainer>
        <NavLink onClick={this.handleClick} to={routes.general.home}>
          <Button
            mr={12}
            label={`${t('topBar.dashboards.bg')} ${bgNumberDisplay}`}
            buttonStyle={'square'}
            fontSize={'10px'}
            buttonSize={ButtonSizes.AUTO}
            selected={isBGActive}
            inactive={!isBGActive}
            uppercase={true}
            minWidth={52}
            height={32}
            onClick={() => this.cleanIt(isBGActive)}
          />
        </NavLink>
        <NavLink onClick={this.handleClick} to={routes.general.homeCgm}>
          <Button
            mr={12}
            label={`${t('topBar.dashboards.cgm')} ${cgmNumberDisplay}`}
            buttonStyle={'square'}
            fontSize={'10px'}
            buttonSize={ButtonSizes.AUTO}
            selected={isCGMActive}
            inactive={!isCGMActive}
            uppercase={true}
            minWidth={52}
            height={32}
            onClick={() => this.cleanIt(isCGMActive)}
          />
        </NavLink>
      </HcpDashboardSelectorContainer>
    );
  }

  private handleClick() {
    // TO DO operations onClick
  }

  private cleanIt = (active) => {
    // TO DO operations after click
  };
}
export const HcpDashboardSelector = compose(
  withTranslation,
  connect(
    createStructuredSelector({
      routes: getCombinedRoutes,
      bgPatientsNumber: selectBgPatientsNumber,
      cgmPatientsNumber: selectCgmPatientsNumber,
      isSearching: selectIsSearching,
    }),
  ),
  withRouter,
)(HcpDashboardSelectorComponent);
