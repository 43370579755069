import styled from 'styled-components';

import { spacing } from 'src/core/styles/spacing';
import { fontSize } from 'src/core/styles/font-sizes';
import { colors } from 'src/core/styles/colors';
import { breakpoints } from 'src/core/styles/breakpoints';
import { weight } from 'src/domains/diagnostics/components/fonts/weights/weight.style';

export const StatusCardRowWrapper = styled.div`
  display: flex;
  padding: ${spacing.two} 0;
  align-items: center;
  @media (max-width: ${breakpoints.changeMedium}) {
    padding: ${spacing.one} 0;
  }
`;

StatusCardRowWrapper.displayName = 'StatusCardRowWrapper';

export const StatusCardDetailLabel = styled.div`
  font-weight: ${weight.regular};
  font-size: ${fontSize.subheading};
  flex: 1;
  display: flex;
`;

StatusCardDetailLabel.displayName = 'StatusCardDetailLabel';

export const StatusCardDetailCaption = styled.div`
  margin-top: ${spacing.two};
  font-size: ${fontSize.caption};
`;

StatusCardDetailCaption.displayName = 'StatusCardDetailCaption';

export const StatusCardDetail = styled.div`
  display: flex;
  justify-content: space-between;
`;

StatusCardDetail.displayName = 'StatusCardDetail';

export const StatusCardDetailWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

StatusCardDetailWrapper.displayName = 'StatusCardDetailWrapper';

export const TrafficLightContainer = styled.span`
  padding-right: ${(props) => props.theme.spacing.two};
`;

TrafficLightContainer.displayName = 'TrafficLightContainer';

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  margin-left: 8px;
`;

DataContainer.displayName = 'DataContainer';

export const BGRow = styled.div`
  display: flex;
  flex: 1.3;
  align-items: center;
  width: 100%;
`;

BGRow.displayName = 'BGRow';

export const StatusCircle = styled.div`
  border: 2px solid #d8d8d8;
  border-color: ${(props) =>
    props.color === 'GREEN' ? colors.greenLegendGraph : props.color} !important;
  border-radius: 50%;
  text-align: center;
  box-sizing: border-box;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

StatusCircle.displayName = 'StatusCircle';

export const CircleText = styled.div`
  display: flex;
  flex-direction: column;
`;

CircleText.displayName = 'CircleText';

export const CircleValue = styled.div`
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  @media (max-width: ${breakpoints.changeMedium}) {
    font-size: 16px;
  }
`;

CircleValue.displayName = 'CircleValue';

export const CircleUnit = styled.div`
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
`;

CircleUnit.displayName = 'CircleUnit';

export const StatusTitle = styled.div`
  color: #737373;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 13.5px;
`;

StatusTitle.displayName = 'StatusTitle';

export const StatusResult = styled.div`
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 0.35rem;
  @media (max-width: ${breakpoints.changeMedium}) {
    font-size: 14px;
  }
`;

StatusResult.displayName = 'StatusResult';
