import { putJSON, createAuthHeader } from 'src/utils/service/service.utils';

import {
  NextShipmentChangeStatusLoaderImplType,
  NextShipmentChangeStatusServiceImplType,
  OpenId,
} from './next-shipment-change-status.types';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

export const NextShipmentChangeStatusLoaderImpl: NextShipmentChangeStatusLoaderImplType =
  (id: number, Status: string, openId: OpenId) =>
    putJSON(endpointWithParams(ENDPOINTS.nextShipmentChangeStatus, { id }), {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
        Status,
      },
    });

export const NextShipmentChangeStatusTransformImpl = (data: any) => data;

export const NextShipmentChangeStatusServiceImpl: NextShipmentChangeStatusServiceImplType =

    (load: NextShipmentChangeStatusLoaderImplType, transform) =>
    (id, status, token) =>
      load(id, status, token).then(transform);
