import { Component } from 'react';
import { Action, PayloadAction } from 'src/app/store/app.types';

export type PatientDevice = {
  active: boolean;
  addedDate: number;
  lastDownloadDate: number;
  removedDate: number;
  action: boolean;
  serial: string;
  brandName: string;
  name: string;
  type: string;
  resourceType: string;
  deviceId: number;
};

export enum FetchStatus {
  LOADING = 'loading',
  FAILURE = 'failure',
  SUCCESS = 'success',
  NONE = 'none',
}

export type ListDevicesState = {
  isFetchingDevices: boolean;
  isFetchingDevicesError: boolean;
  devices: PatientDevice[];
  status: FetchStatus;
};

export type RemoveDevicePayload = {
  patientId: string;
  deviceId: number;
  successComponent: Component;
  errorComponent: Component;
};

export enum ListDevicesActionType {
  FETCH_DEVICES_START = 'FETCH_DEVICES_START',
  FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS',
  FETCH_DEVICES_ERROR = 'FETCH_DEVICES_ERROR',
  REMOVE_DEVICE_START = 'REMOVE_DEVICE_START',
}

export type ListDevicesActions =
  | FetchDevicesStartAction
  | FetchDevicesSuccessAction
  | FetchDevicesErrorAction
  | RemoveDeviceStartAction;

export type FetchDevicesStartAction = PayloadAction<
  ListDevicesActionType.FETCH_DEVICES_START,
  number
>;

export type FetchDevicesSuccessAction = PayloadAction<
  ListDevicesActionType.FETCH_DEVICES_SUCCESS,
  PatientDevice[]
>;

export type FetchDevicesErrorAction =
  Action<ListDevicesActionType.FETCH_DEVICES_ERROR>;

export type RemoveDeviceStartAction = PayloadAction<
  ListDevicesActionType.REMOVE_DEVICE_START,
  RemoveDevicePayload
>;
