import {
  INSIdentityActions,
  INSIdentityActionType,
  INSIdentityStateType,
} from './INS-identity.types';

export const insIdentityInitialState: INSIdentityStateType = {
  isFetchingINSIdentity: false,
  isFetchingINSIdentityError: false,
  userHasCancelledRequest: false,
  successINSIdentity: false,
  patientIdentity: undefined,
};

export const INSIdentityReducer = (
  state = insIdentityInitialState,
  action: INSIdentityActions,
): INSIdentityStateType => {
  switch (action.type) {
    case INSIdentityActionType.INS_IDENTITY_GET_START:
      return {
        ...insIdentityInitialState,
        isFetchingINSIdentity: true,
      };
    case INSIdentityActionType.INS_IDENTITY_GET_SUCCESS:
      return {
        ...state,
        patientIdentity: action.payload,
        isFetchingINSIdentity: false,
        successINSIdentity: true,
        userHasCancelledRequest: false,
      };
    case INSIdentityActionType.INS_IDENTITY_GET_ERROR:
      return {
        ...state,
        isFetchingINSIdentity: false,
        isFetchingINSIdentityError: true,
        userHasCancelledRequest: false,
      };
    case INSIdentityActionType.INS_IDENTITY_CLEAR:
      return {
        ...state,
        successINSIdentity: false,
        isFetchingINSIdentity: false,
        userHasCancelledRequest: true,
      };
    default:
      return state;
  }
};
