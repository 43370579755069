import { createSelector, createStructuredSelector } from 'reselect';
import { pick, pipe, values, any, path, countBy, identity } from 'ramda';
import { getCombinedRoutes } from 'src/navigation';

import {
  selectPatientStartDate,
  selectPatientEndDate,
  selectPatientFirstMeasurementDate,
} from 'src/core/patient-date-range/patient-date-range.selector';
import {
  selectPatient,
  selectPatientProfileType,
  selectPatientFhirId,
} from 'src/core/patient/patient.selector';
import {
  selectDashboard,
  selectGraph,
  selectGraphType,
  selectLogbookType,
} from 'src/core/diagnostics/diagnostics.selector';
import {
  selectPatientPermissions,
  selectPermissions,
  selectFhirPermission,
  selectJelloPermission,
} from 'src/core/permissions/permissions.selectors';
import { selectUserProfile } from 'src/core/user/user.selectors';

import { selectDataSharingConsentResponse } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.selector';

import { selectRangeTypeInAllRangeDate } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';

import { HI_VALUE, LO_VALUE } from 'src/domains/diagnostics/store/constants';

export const selectIsFetchingData = createSelector(
  selectDashboard,
  pipe(
    pick([
      'isFetchingPatientDateRange',
      'isFetchingClinicalData',
      'isFetchingTimeIntervals',
      'isFetchingPatient',
      'isFetchingStripDeliveryInfo',
      'isFetchingThreshold',
    ]),
    values,
    any((value) => value === true),
  ),
);

export const selectIsExternalPatient = createSelector(
  selectDashboard,
  path(['isExternalPatient']),
);

export const selectExternalPatient = createSelector(
  selectDashboard,
  path(['externalPatient']),
);
export const selectIsFetchingPatient = createSelector(
  selectDashboard,
  path(['isFetchingPatient']),
);

export const selectIsFetchingPatientDateRange = createSelector(
  selectDashboard,
  path(['isFetchingPatientDateRange']),
);

export const selectPatientHasMeasurementData = createSelector(
  selectPatientFirstMeasurementDate,
  (firstMeasurementDate) => firstMeasurementDate !== null,
);

export const selectShowNewPatientDashboard = createSelector(
  selectPatientHasMeasurementData,
  (patientHasMeasurementData) => !patientHasMeasurementData,
);

export const selectShowNormalDashboard = createSelector(
  selectPatientHasMeasurementData,
  (patientHasMeasurementData) => patientHasMeasurementData,
);

const selectBannersHiddenForDateRanges = createSelector(
  selectDashboard,
  path(['bannersHiddenForDateRanges']),
);

export const selectShouldShowHILOBanner = createSelector(
  [
    selectRangeTypeInAllRangeDate,
    selectPatientStartDate,
    selectPatientEndDate,
    selectBannersHiddenForDateRanges,
  ],
  (rangeTypes, startDate, endDate, bannersHiddenForDateRanges) => {
    const key = `${startDate}-${endDate}`;

    const countValues = countBy(identity)(rangeTypes);

    const results = {
      numberOfHiValues: countValues[HI_VALUE] || 0,
      numberOfLoValues: countValues[LO_VALUE] || 0,
    };

    return {
      numberOfHiValues: results.numberOfHiValues,
      numberOfLoValues: results.numberOfLoValues,
      shouldShowBanner:
        (results.numberOfHiValues > 0 || results.numberOfLoValues > 0) &&
        !bannersHiddenForDateRanges[key],
    };
  },
);

export const patientDashboardConnector = createStructuredSelector({
  patient: selectPatient,
  graph: selectGraph,
  graphType: selectGraphType,
  logbookType: selectLogbookType,
  isFetchingData: selectIsFetchingData,
  startDate: selectPatientStartDate,
  endDate: selectPatientEndDate,
  routes: getCombinedRoutes,
  patientHasMeasurementData: selectPatientHasMeasurementData,
  isFetchingPatient: selectIsFetchingPatient,
  isFetchingPatientDateRange: selectIsFetchingPatientDateRange,
  showNewBgDashboard: selectShowNewPatientDashboard,
  showNormalDashboard: selectShowNormalDashboard,
  patientPermissions: selectPatientPermissions,
  hcpPermissions: selectPermissions,
  patientFhirId: selectPatientFhirId,
  hasUserFhirPermission: selectFhirPermission,
  dataSharingConsentResponse: selectDataSharingConsentResponse,
  isJelloActivated: selectJelloPermission,
  rangeTypeInRangeDate: selectRangeTypeInAllRangeDate,
  infoHILOBanner: selectShouldShowHILOBanner,
});

export const patientExternalConnector = createStructuredSelector({
  isExternalPatient: selectIsExternalPatient,
  externalPatient: selectExternalPatient,
  isJelloActivated: selectJelloPermission,
  profile: selectUserProfile,
});
