// @ts-nocheck
import * as React from 'react';
import { withTranslation } from 'src/utils/i18n/with-translation';

import { iconSingleTag } from 'src/widgets/modal/components/manufacturer-info-modal/components/icons-view/components/icon-component.types';

import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';

import {
  IconsGrid,
  IconSectionDiv,
  IconsSingleCard,
  IconCardItems,
  FourIconsDiv,
  FourSingleIconCard,
  FourIconCardItems,
  IconRotateImageItem,
  IconImageItem,
  IconNameItem,
  IconTitleRowDiv,
  IconRowNameItem,
} from './icons-components.style';

import { t } from 'i18next';

const columnizeIcons = (iconsArray) => {
  const arrayToPush: object[] = [];
  for (let i = 0; i * 3 < iconsArray.length; i++) {
    arrayToPush.push(iconsArray.slice(i * 3, i * 3 + 3));
  }
  return arrayToPush;
};

export type GeneralTripleProps = {
  generalIconTags: any;
  generalIconsTitle: string;
};

export const GenerateTripleColumnIcons: React.FunctionComponent<
  GeneralTripleProps
> = ({ generalIconTags, generalIconsTitle }: GeneralTripleProps) => (
  <React.Fragment>
    <IconSectionDiv>
      <jello-text
        class="jello-text-demo__component"
        size={JELLO_TEXT_SIZES.M}
        weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
      >{`${t(generalIconsTitle)}`}</jello-text>
      <IconsGrid>
        {columnizeIcons(generalIconTags).map((tripleTag: any, i) => (
          <IconsSingleCard key={i}>
            {tripleTag.map((tag: iconSingleTag, j) => (
              <IconCardItems key={j}>
                {tag.nametag ===
                'manufacturerInfo.metabolicRate.meanBGmeanSD' ? (
                  <IconRotateImageItem>
                    {tag.objectComponent}
                  </IconRotateImageItem>
                ) : (
                  <IconImageItem>{tag.objectComponent}</IconImageItem>
                )}
                <IconNameItem>
                  <jello-text
                    class="jello-text-demo__component"
                    size={JELLO_TEXT_SIZES.XS}
                    weight={JELLO_TEXT_WEIGHTS.REGULAR}
                    style={{ display: 'flex', flexdirection: 'row' }}
                  >
                    {`${t(tag.nametag)}`}
                  </jello-text>
                </IconNameItem>
              </IconCardItems>
            ))}
          </IconsSingleCard>
        ))}
      </IconsGrid>
    </IconSectionDiv>
  </React.Fragment>
);

export const GenerateTripleColumnIconsComponent = withTranslation(
  GenerateTripleColumnIcons,
);

export const GenerateContents: React.FunctionComponent = ({
  content,
  title,
}: GeneralTripleProps) => (
  <React.Fragment>
    <jello-text
      size={JELLO_TEXT_SIZES.M}
      weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
      style={{ marginBottom: '10px' }}
    >
      {`${t(title)}`}
    </jello-text>
    <jello-grid center="">
      <jello-row style={{ padding: '5px' }}>
        {content.map((col, id) => (
          <jello-col
            key={`col-${title}-icons-${id}`}
            style={{ width: 'max-content' }}
          >
            {col.map((item) => {
              const { tag, icon } = item;
              return (
                <jello-row
                  key={`row-${tag}`}
                  style={{ height: '50px', paddingRight: '10px' }}
                >
                  <div
                    style={{
                      height: '40px',
                      width: '40px',
                      paddingTop: '8px',
                      textAlign: 'center',
                    }}
                  >
                    {icon}
                  </div>
                  <div style={{ height: '40px', paddingTop: '12px' }}>
                    <jello-text
                      size={JELLO_TEXT_SIZES.XS}
                      weight={JELLO_TEXT_WEIGHTS.REGULAR}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >{`${t(tag)}`}</jello-text>
                  </div>
                </jello-row>
              );
            })}
          </jello-col>
        ))}
      </jello-row>
    </jello-grid>
  </React.Fragment>
);

export const GenerateRowIcons: React.FunctionComponent<GeneralTripleProps> = ({
  generalIconTags,
  generalIconsTitle,
}: GeneralTripleProps) => (
  <React.Fragment>
    <IconSectionDiv>
      <IconTitleRowDiv>
        <jello-text
          class="jello-text-demo__component"
          size={JELLO_TEXT_SIZES.M}
          weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
        >{`${t(generalIconsTitle)}`}</jello-text>
      </IconTitleRowDiv>
      <FourIconsDiv>
        {generalIconTags.map((Component: any, i) => (
          <FourSingleIconCard key={i}>
            <FourIconCardItems>
              <IconImageItem>{Component.icon}</IconImageItem>
              <IconRowNameItem>
                <jello-text
                  class="jello-text-demo__component"
                  size={JELLO_TEXT_SIZES.XS}
                  weight={JELLO_TEXT_WEIGHTS.REGULAR}
                  style={{ display: 'flex', flexdirection: 'row' }}
                >{`${t(Component.name)}`}</jello-text>
              </IconRowNameItem>
            </FourIconCardItems>
          </FourSingleIconCard>
        ))}
      </FourIconsDiv>
    </IconSectionDiv>
  </React.Fragment>
);

export const GenerateRowIconsComponent = withTranslation(GenerateRowIcons);
