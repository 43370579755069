import { animations } from './animations';
import { letterSpacing } from './typography';

import { boxShadows, boxShadow } from '../core/styles/box-shadow';
import {
  fontSize,
  fontSizesMap,
  BASE_FONT_SIZE,
} from '../core/styles/font-sizes';
import { fontWeights } from '../core/styles/font-weights/font-weights';
import { borderRadiusMap } from '../core/styles/border-radius';
import { breakpointsMap } from '../core/styles/breakpoints';
import { spacingMap, spacing } from '../core/styles/spacing';
import { colors } from '../core/styles/colors';
import { complexStyles } from '../core/styles/complex-styles/complex-styles';
import { strokeWidth } from '../core/styles/stroke-width';
import {
  transitions,
  transitionEasing,
  transitionSpeed,
} from '../core/styles/transitions';
import { zIndexes, zIndexScale } from '../core/styles/z-index';

export const theme = {
  BASE_FONT_SIZE,
  animations,
  boxShadow,
  boxShadows,
  borderRadius: borderRadiusMap,
  breakpoints: breakpointsMap,
  colors,
  fontSize,
  fontSizes: fontSizesMap,
  fontWeights,
  letterSpacing,
  spacing,
  space: spacingMap,
  strokeWidth,
  textStyles: complexStyles.textStyles,
  transitions,
  transitionEasing,
  transitionSpeed,
  zIndexScale,
  zIndexes,
};

export type ThemeInterface = typeof theme;
