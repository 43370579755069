import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type Props = {
  height?: number | String,
  fillColor?: string,
  strokeColor?: string,
  viewbox?: string,
  originalWidth?: number,
  originalHeight?: number,
  minX?: number,
  minY?: number,
};

export const V3HomeIcon = ({
  fillColor = colors.white,
  height = 32,
  strokeColor = colors.iconColor,
  originalWidth = 32,
  originalHeight = 32,
  minX = 0,
  minY = 0,
}: Props) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      minX={minX}
      minY={minY}
      viewBox="0 0 32 32"
    >
      <g
        id="v3-home"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="42--Patient-Settings---RPM-Program-Settings"
          transform="translate(-1027.000000, -28.000000)"
        >
          <g id="SELECTED-Nav-/-NO-TIME">
            <g id="Icon/Home" transform="translate(1027.000000, 28.000000)">
              <path
                d="M15.233062,7.36307526 C15.8632907,6.84590465 16.7556512,6.81717295 17.4150057,7.27688016 L17.5288428,7.36307526 L25.5657641,13.9582357 C25.8503886,14.1918008 25.8917805,14.6118765 25.6582154,14.896501 C25.4441141,15.1574068 25.073292,15.2139295 24.7937418,15.0416336 L24.7199501,14.9889522 L16.6830288,8.39379173 C16.529374,8.26770138 16.3165085,8.25194008 16.1480511,8.34650785 L16.078876,8.39379173 L8.04195462,14.9889522 C7.75733012,15.2225173 7.33725447,15.1811255 7.10368938,14.896501 C6.88958805,14.6355952 6.90652691,14.2608728 7.1300702,14.0203263 L7.19614062,13.9582357 L15.233062,7.36307526 Z"
                id="Combined-Shape"
                fill="#0066CC"
                fillRule="nonzero"
              ></path>
              <path
                d="M22.228472,13.7931181 C22.5683395,13.7931181 22.8488067,14.0474416 22.8899444,14.3761596 L22.8951387,14.4597848 L22.8951387,22.8407372 C22.8951387,23.1806047 22.6408152,23.4610718 22.3120973,23.5022096 L22.228472,23.5074039 L18.2857143,23.5074039 C17.9458467,23.5074039 17.6653796,23.2530804 17.6242419,22.9243624 L17.6190476,22.8407372 L17.6182857,18.2102857 L15.1420952,18.2102857 L15.1428571,22.8407372 C15.1428571,23.1806047 14.8885337,23.4610718 14.5598157,23.5022096 L14.4761905,23.5074039 L10.6666667,23.5074039 C10.3267991,23.5074039 10.046332,23.2530804 10.0051943,22.9243624 L10,22.8407372 L10,14.4597848 C10,14.091595 10.2984768,13.7931181 10.6666667,13.7931181 C11.0065342,13.7931181 11.2870013,14.0474416 11.3281391,14.3761596 L11.3333333,14.4597848 L11.3325714,22.1737143 L13.8087619,22.1737143 L13.8095238,17.5439755 C13.8095238,17.204108 14.0638473,16.9236409 14.3925652,16.8825032 L14.4761905,16.8773089 L18.2857143,16.8773089 C18.6255818,16.8773089 18.9060489,17.1316323 18.9471867,17.4603503 L18.952381,17.5439755 L18.951619,22.1737143 L21.5611429,22.1737143 L21.5618053,14.4597848 C21.5618053,14.1199173 21.8161288,13.8394502 22.1448468,13.7983124 L22.228472,13.7931181 Z"
                id="Path-38"
                fill="#0066CC"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
