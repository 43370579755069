import React, { useState, useEffect, useRef } from 'react';
import { createStructuredSelector } from 'reselect';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { lastPatientStripStatusConnector } from 'src/domains/strip-management/widgets/patient-strip-status/last-patient-strip-status.selector';
import { selectModalProps } from 'src/core/modal/modal.selectors';
import { destroyModal } from 'src/core/modal/modal.actions';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';
import { testId } from '@roche/roche-common';
import {
  DECREMENT_BTN,
  ID,
  INCREMENT_BTN,
  STRIP_MODAL,
  STRIP_MODAL_BUTTON,
  STRIP_MODAL_STRIPS,
} from './strip-modal.constants';

export const ModalContent = (props) => {
  const tubesToDeliver = props.data.data.tubesToDeliver;
  const [strips, setStrips] = useState(
    tubesToDeliver === null || tubesToDeliver === undefined
      ? 0
      : tubesToDeliver,
  );
  const [isFirstTime, setIsFirstTime] = useState(false);
  const numberOfStripsInTheTube =
    props.data.data.prescriptions.permanent.stripModelTubes;

  const numberStrips = strips * numberOfStripsInTheTube;
  const handleClickPlus = () => (strips >= 100 ? null : setStrips(strips + 1));
  const handleClickMinus = () => (strips <= 1 ? null : setStrips(strips - 1));
  const handleClickDeliver = () => {
    if (strips > 0 || isFirstTime) {
      props.data.data.onDeliverHandler(strips);
      hideModal();
    }
  };

  // const commentRef = useRef();
  const jelloRef = useRef();

  // There are no similar styles on Jello so temporary we use these styles por the
  // buttons +1 and -1 and the number inside
  const btnStyles = {
    width: '40px',
    height: '40px',
    display: 'inline-flex',
    padding: '10px',
    border: '1px solid grey',
    borderColor: 'grey',
    cursor: 'pointer',
    background: 'none',
    justifyContent: 'center',
  };

  const numberStyles = {
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    display: 'inline-flex',
    padding: '10px',
    height: '40px',
    minWidth: '40px',
    borderColor: 'grey',
    justifyContent: 'center',
  };

  const hideModal = () => {
    const { type, isOpen, destroyModal } = props;

    if (isOpen && type === MODAL_TYPES.STRIPS_MODAL) {
      destroyModal();
    }
  };

  /*   useEffect(() => {
      if (commentRef && commentRef.current) {
        commentRef.current.addEventListener(
          'jello-input-text-change',
          () => setComment(commentRef.current.value),
          false,
        );
        return function cleanup() {
          if (commentRef && commentRef.current) {
            commentRef.current.removeEventListener(
              'jello-input-text-change',
              () => setComment(commentRef.current.value),
              false,
            );
          }
        };
      }
      return;
    }, []); */

  useEffect(() => {
    setIsFirstTime(true);
  }, []);

  useEffect(() => {
    const isJelloRefRender = jelloRef && jelloRef.current;

    let confirmBtn;
    let cancelBtn;

    if (isJelloRefRender) {
      // @ts-ignore
      const shadowRoot = jelloRef.current.parentNode.shadowRoot;
      const jelloButtons = shadowRoot.querySelectorAll('jello-button');

      jelloButtons.forEach((jelloButton) => {
        if (jelloButton.getAttribute('variant') === 'primary') {
          // Confirm delivery button
          confirmBtn = jelloButton.shadowRoot.querySelector('button');

          confirmBtn.addEventListener('click', handleClickDeliver);
        }

        if (jelloButton.getAttribute('variant') === 'secondary') {
          // Cancel button
          cancelBtn = jelloButton.shadowRoot.querySelector('button');
          cancelBtn.addEventListener('click', hideModal);
        }
      });
    }
    return () => {
      if (confirmBtn && cancelBtn) {
        confirmBtn.removeEventListener('click', handleClickDeliver);
        cancelBtn.removeEventListener('click', hideModal);
      }
    };
  }, [strips, isFirstTime]);

  return (
    // @ts-ignore
    <div ref={jelloRef} {...testId(ID, STRIP_MODAL)}>
      <jello-text style={{ paddingBottom: '24px', display: 'block' }}>
        {t('stripDelivery.popUp.adjustement')}
      </jello-text>

      <div style={{ textAlign: 'center', display: 'block' }}>
        <jello-text
          size="L"
          weight="semibold"
          style={{ display: 'block', paddingBottom: '16px' }}
        >
          {t('stripDelivery.popUp.containers')}
        </jello-text>

        <button
          style={{
            ...btnStyles,
            borderRadius: '5px 0 0 5px',
          }}
          onClick={() => handleClickMinus()}
          {...testId(STRIP_MODAL_BUTTON, DECREMENT_BTN)}
        >
          -
        </button>

        <div
          style={{ ...numberStyles }}
          {...testId(STRIP_MODAL_STRIPS, strips.toString())}
        >
          {strips}
        </div>

        <button
          style={{
            ...btnStyles,
            borderRadius: '0 5px 5px 0',
          }}
          onClick={() => handleClickPlus()}
          {...testId(STRIP_MODAL_BUTTON, INCREMENT_BTN)}
        >
          +
        </button>

        <jello-text
          style={{ paddingTop: '16px', display: 'block' }}
          weight="semibold"
        >
          {t('stripDelivery.popUp.totalStrips', { numberStrips })}
        </jello-text>
      </div>

      {/* <jello-input-text
      {...testId(TEXT_AREA)}
        ref={commentRef}
        label={comment}
        size="50"
        placeholder={t('stripDelivery.popUp.placeholder')}
        style={{ paddingTop: '24px', display: 'block' }}
        error-message="This field is required"
      ></jello-input-text> */}
    </div>
  );
};

export const ModalContentElement = connect(
  null,
  mapDispatchers({
    destroyModal,
  }),
)(
  connect(
    createStructuredSelector({
      data: selectModalProps,
      tubes: lastPatientStripStatusConnector,
    }),
  )(ModalContent),
);
