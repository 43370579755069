import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

export const LOGO_ID = 'logo';
export const SCOPE_ID = 'top-navigation';
export const BACK_HOME_BUTTON_ID = 'back-home-button';
export const DEPARTMENT_CENTER_ID = 'labelDepartmentAndCenterName';
export const TOOLBAR_ID = 'jello-toolbar';
export const POPOVER_ID = 'jello-popover';
export const SEARCH_BAR_ID = 'search-box-jello';
export const BACK_HOME_BUTTON_ICON_NAME = 'HOME';
export const UPLOAD_DATA_ICON_NAME = 'CLOUD_UPLOAD';
export const UPLOAD_DATA_TOOLTIP_ID = 'upload-data-tooltip';
export const MOUSSE_OVER = 'mouseover';
export const MOUSSE_LEAVE = 'mouseleave';
export const ATTRIBUTE = 'visible';
export const KEYDOWN_EVENT = 'keydown';
export const KEY_ENTER = 'Enter';
export const JELLO_SEARCH_BOX_ACTION_EVENT = 'jello-search-box-action';
export const JELLO_SEARCH_BOX_INPUT_EVENT = 'jello-search-box-input';
export const MIN_CHARS_START_SEARCH = 3;
export const HELP_MENU_ID = 'help-center';
export const HELP_MENU_MODAL_BASE_ID = 'modal-help-center';
export const HELP_MENU_LABEL = 'navBar.help';
export const WAFFLE_MENU_ID = 'waffle-menu';
export const WAFFLE_MENU_MODAL_BASE_ID = 'modal-waffle-menu';
export const WAFFLE_MENU_LABEL = 'waffleMenu.moreOptions';
export const WAFFLE_MENU_ITEM_ID = 'waffle-menu-item';
export const WAFFLE_MENU_ITEM_MODAL_BASE_ID = 'modal-waffle-menu';
export const ONE_COLUMN = 1;
export const TWO_COLUMNS = 2;
export const THREE_COLUMNS = 3;

export const HELP_DOCUMENTATION_EVENTS_KEYS = {
  HELP_DOCUMENTATION_OPENED: 'Help Documentation Opened',
};

export const HELP_DOCUMENTATION_SEGMENT_KEYS = {
  HELP_OPTION: 'Help Option',
};

export const HELP_DOCUMENTATION_SEGMENT_VALUES = {
  GETTING_STARTED: 'Getting started',
  PRODUCT_INFORMATION: 'Product Information',
  IFU: 'Instructions for use',
  SUPPORT_CENTER: 'Support Center',
};

export const WAFFLE_MENU_DOCUMENTATION_SEGMENT_VALUES = {
  CREATE_NEW_PATIENT: 'Create new patient',
  STOCK_CENTER: 'Stock center',
  RPM_SETTINGS: 'RPM settings',
  ADD_PROFESSIONAL: 'Add a professional',
  EXPORT_BILLING_REPORT: 'Export billing report',
};

export const HELP_ELEMENT_LIST = [
  {
    key: 'gettingstarted',
    iconName: JELLO_ICON_NAMES.HELP,
    linkText: 'helpDropdown.gettingStarted',
    isAdditionalInfo: true,
    countlySegmentValue: HELP_DOCUMENTATION_SEGMENT_VALUES.GETTING_STARTED,
  },
  {
    key: 'info',
    iconName: JELLO_ICON_NAMES.INFORMATION,
    linkText: 'helpDropdown.productInfo',
    onClickModal: true,
    modalKey: MODAL_TYPES.MANUFACTURER_INFO,
    countlySegmentValue: HELP_DOCUMENTATION_SEGMENT_VALUES.PRODUCT_INFORMATION,
  },
  {
    key: 'ifu',
    iconName: JELLO_ICON_NAMES.LOGBOOK,
    linkText: 'helpDropdown.ifu',
    link: '{{REACT_APP_IFUS}}/rdcp/ifu/{{version}}/{{locale}}.pdf',
    external: true,
    countlySegmentValue: HELP_DOCUMENTATION_SEGMENT_VALUES.IFU,
  },
  {
    key: 'support',
    iconName: JELLO_ICON_NAMES.LAUNCH,
    linkText: 'helpDropdown.supportCenter',
    link: 'https://support.rochediabetes.com/careplatform/',
    external: true,
    countlySegmentValue: HELP_DOCUMENTATION_SEGMENT_VALUES.SUPPORT_CENTER,
  },
];

export const WAFFLE_ELEMENT_LIST = [
  {
    key: 'createPatient',
    iconName: JELLO_ICON_NAMES.ADD_PATIENT,
    linkText: 'waffleMenu.createNewPatient.title',
    descriptionText: 'waffleMenu.createNewPatient.description',
    countlySegmentValue:
      WAFFLE_MENU_DOCUMENTATION_SEGMENT_VALUES.CREATE_NEW_PATIENT,
    permissions: [],
    incompatibilityPermissions: [PERMISSIONS.ELECTRONIC_MEDICAL_RECORD],
    baseRoute: 'patient',
    specificRoute: 'createPatient',
  },
  {
    key: 'addProfessional',
    iconName: JELLO_ICON_NAMES.STETHOSCOPE || JELLO_ICON_NAMES.ADD_HCP_PROFILE,
    linkText: 'waffleMenu.addAProfessional.title',
    descriptionText: 'waffleMenu.addAProfessional.description',
    countlySegmentValue:
      WAFFLE_MENU_DOCUMENTATION_SEGMENT_VALUES.ADD_PROFESSIONAL,
    permissions: [PERMISSIONS.CREATE_PROFESSIONAL],
    incompatibilityPermissions: [PERMISSIONS.ELECTRONIC_MEDICAL_RECORD],
    baseRoute: 'professional',
    specificRoute: 'createProfessional',
  },
  {
    key: 'rpmProgram',
    iconName: JELLO_ICON_NAMES.FORUM,
    linkText: 'waffleMenu.rpmSettings.title',
    descriptionText: 'waffleMenu.rpmSettings.description',
    countlySegmentValue: WAFFLE_MENU_DOCUMENTATION_SEGMENT_VALUES.RPM_SETTINGS,
    permissions: [PERMISSIONS.RPM_SETTINGS_MANAGEMENT],
    incompatibilityPermissions: [],
    baseRoute: 'rpm',
    specificRoute: 'rpmSettings',
  },
  {
    key: 'stock',
    iconName: JELLO_ICON_NAMES.INVENTORY_MANAGEMENT,
    linkText: 'waffleMenu.manageStockCenter.title',
    descriptionText: 'waffleMenu.manageStockCenter.description',
    countlySegmentValue: WAFFLE_MENU_DOCUMENTATION_SEGMENT_VALUES.STOCK_CENTER,
    permissions: [PERMISSIONS.STRIP_STOCK],
    incompatibilityPermissions: [],
    baseRoute: 'stripManagement',
    specificRoute: 'stock',
  },
  {
    key: 'timeVTrackingExport',
    iconName: JELLO_ICON_NAMES.DOCUMENT_EXPORT,
    linkText: 'waffleMenu.exportBillingReport.title',
    descriptionText: 'waffleMenu.exportBillingReport.description',
    countlySegmentValue:
      WAFFLE_MENU_DOCUMENTATION_SEGMENT_VALUES.EXPORT_BILLING_REPORT,
    permissions: [PERMISSIONS.ROLE_TIME_TRACKING],
    incompatibilityPermissions: [],
    onClickModal: true,
    modalKey: 'showTimeTrackerExportModal',
  },
];

export const WAFFLE_COLUMN_STYLE = {
  [ONE_COLUMN]: {
    liElement: 'liElement1Column',
    contentWrapper: 'contentWrapper3El',
  },
  [TWO_COLUMNS]: {
    liElement: 'liElement2Column',
    contentWrapper: 'contentWrapper6El',
  },
  [THREE_COLUMNS]: {
    liElement: 'liElement3Column',
    contentWrapper: 'contentWrapper9El',
  },
  default: {
    liElement: 'liElement3Column',
    contentWrapper: 'contentWrapper10El',
  },
};

export const USER_AVATAR_TEST_ID_BASE = 'user-avatar';
export const USER_DROPDOWN_LIST_ITEM_TEST_ID_BASE = 'profile-dropdown';
export const USER_DROPDOWN_EVENTS_KEYS = {
  USER_SIGNED_OUT: 'User Signed Out',
};

export const USER_DROPDOWN_SEGMENT_KEYS = {
  SIGNOUT_METHOD: 'Signout Method',
};

export const USER_DROPDOWN_SEGMENT_VALUES = {
  MANUAL: 'Manual',
};

export const USER_DROPDOWN_LIST = [
  {
    key: 'updateDetails',
    iconName: JELLO_ICON_NAMES.DEPRECATED_USER_AVATAR,
    linkText: 'navigation.profile',
    isLink: true,
    permissions: [],
    baseRoute: 'profile',
    specificRoute: 'editProfile',
    onClick: '',
  },
  {
    key: 'changePassword',
    iconName: JELLO_ICON_NAMES.LOCKED,
    linkText: 'profileDropdownPopover.changePassword',
    isLink: true,
    permissions: [],
    baseRoute: 'profile',
    specificRoute: 'changePassword',
    onClick: '',
  },
  {
    key: 'defaultSettings',
    iconName: JELLO_ICON_NAMES.SETTINGS,
    linkText: 'profileDropdownPopover.defaultSettings',
    isLink: true,
    permissions: [PERMISSIONS.RELEVANT_CLINICAL_DATA],
    baseRoute: 'professional',
    specificRoute: 'defaultGraphicSettings',
    onClick: '',
  },
  {
    key: 'sign-out',
    iconName: JELLO_ICON_NAMES.POWER,
    linkText: 'profileDropdown.signOut',
    isLink: false,
    permissions: [],
    baseRoute: '',
    specificRoute: '',
    onClick: 'onSingOut',
  },
];
const JELLO_SEARCH_BOX_LOKALISATION_KEY = 'hcpDashboard.searchBox';
export const JELLO_SEARCH_BOX_LOKALISATION_KEYS = {
  SEARCH_BOX_PLACEHOLDER: `${JELLO_SEARCH_BOX_LOKALISATION_KEY}.placeholder`,
  SEARCH_BOX_PLACEHOLDER_ADMIN: `${JELLO_SEARCH_BOX_LOKALISATION_KEY}.placeholderAdmin`,
  SEARCH_BOX_CLEAR_BUTTON_ACCESSIBILITY_TEXT: `${JELLO_SEARCH_BOX_LOKALISATION_KEY}.clearButton.act`,
  SEARCH_BOX_SEARCH_BUTTON_ACCESSIBILITY_TEXT: `${JELLO_SEARCH_BOX_LOKALISATION_KEY}.searchButton.act`,
};
export const MORE_OPTIONS = 'More options menu';
