import * as React from 'react';
import { testId } from '@roche/roche-common';
import { ErrorCrossIcon } from 'src/assets/icons';
import styles from 'src/domains/patient/scenes/clinical-study/unblind-study-error-modal/styles.module.css';
import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';
import {
  CLINICAL_STUDY_TAB,
  UNBLIND_STUDY_ERROR_MODAL_PATH,
} from 'src/domains/patient/scenes/clinical-study/clinical-study.constants';
import { t } from 'i18next';

export type UnblindStudyErrorModalProps = {
  closeModal: () => void;
};

export const UnblindStudyErrorModalComponent: React.FC<
  UnblindStudyErrorModalProps
> = () => {
  return (
    <jello-dialog
      visible
      primary-button-text={t(`${UNBLIND_STUDY_ERROR_MODAL_PATH}.btnText`)}
      close-on-action
      {...testId(CLINICAL_STUDY_TAB, 'unblind-study-error-modal')}
    >
      <div className={styles.contentWrapper}>
        <ErrorCrossIcon width={120} height={120} />
        <jello-text size={JELLO_TEXT_SIZES.L} weight={JELLO_TEXT_WEIGHTS.BOLD}>
          {t(`${UNBLIND_STUDY_ERROR_MODAL_PATH}.title`)}
        </jello-text>
        <jello-text
          size={JELLO_TEXT_SIZES.M}
          weight={JELLO_TEXT_WEIGHTS.REGULAR}
        >
          {t(`${UNBLIND_STUDY_ERROR_MODAL_PATH}.content`)}
        </jello-text>
      </div>
    </jello-dialog>
  );
};
