import { createSelector, createStructuredSelector } from 'reselect';
import { always, isEmpty, max, reject } from 'ramda';
import React from 'react';
import { selectIsGeneralPractitioner } from 'src/domains/general/widgets/store/hcp-dashboard.selectors';

import { LocalizedText } from 'src/components/localized-text/localized-text.component';
import { selectIsHCPUserProfile } from 'src/core/user/user.selectors';
import { average } from 'src/utils/stat';
import { convertISOToJsGMT, isEqual } from 'src/utils/date';
import { BLOOD_GLUCOSE_UNITS } from 'src/domains/patient-dashboards/bg/store/bg.constants';
import { selectPatient } from 'src/domains/diagnostics/store/selectors/patient.selector';
import {
  selectBloodGlucoseUnit,
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraph,
  selectGraphDetailTargetRanges,
  selectGraphThreshold,
  selectGraphToggles,
  selectGraphType,
  selectLogbookType,
  selectPostIdealIntervalsFromThresholds,
  selectValidBolusesInDateSliderRange,
  selectGraphLoading,
  selectMaxBasalMeasurement,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import {
  selectPatientEndDate,
  selectPatientLastMeasurementDate,
  selectPatientStartDate,
} from 'src/domains/diagnostics/store/selectors/patient-date-range.selector';
import { BASAL_MAX_VALUES } from 'src/domains/diagnostics/utils/graph.constants';
import { selectLogbookStatsData } from 'src/domains/diagnostics/widgets/logbook-stats/logbook-stats.selector';
import {
  BASAL_Y_MIN,
  BOLUS_PADDING_THRESHOLD,
  GRAPH_TYPE_DETAILS,
  GRAPH_TYPE_TREND,
  GRAPH_Y_INTERVAL_MG,
  GRAPH_Y_INTERVAL_MMOL,
  GRAPH_Y_MAX_MG,
  GRAPH_Y_MAX_MMOL,
  GRAPHS,
  HYPO_TICK_COLOR,
  LOGBOOK_TYPE_24HOUR,
  LOGBOOK_TYPE_DETAILS,
  LOGBOOK_TYPE_DIARY,
  LOGBOOK_TYPE_STATS,
  TARGET_RANGE_MAX_TICK_KEY,
  TARGET_RANGE_MIN_TICK_KEY,
  TARGET_RANGE_TICK_COLOR,
  THRESHOLD_TICK_KEY,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  selectHasData,
  selectHasSufficiencyToStatisticsCalculation,
} from 'src/domains/diagnostics/widgets/status-card/status-card.selector';
import {
  selectFhirPermission,
  selectPatientPermissions,
  selectPermissions,
} from 'src/core/permissions/permissions.selectors';
import { selectPatientFhirId } from 'src/core/patient/patient.selector';

import {
  createVerticalTick,
  normalizeGraphTargetRange,
  normalizeGraphThreshold,
  normalizeVerticalTickValues,
} from './graph-shared/graph.util';
import { roundToNDecimalPlaces } from './graph-statistics.util';
import { measurementsToDateRange } from 'src/domains/diagnostics/scenes/graphs/graph-shared/measurements';
import { formatGraphsDate } from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph-date';
import { selectGraphDetailBloodGlucose } from 'src/domains/diagnostics/scenes/graphs/blood-glucose/blood-glucose.selector';

export const selectGraphTypeTranslationKey = createSelector(
  selectGraph,
  selectGraphType,
  selectLogbookType,
  (graph, graphType, logbookType) => {
    if (graph === GRAPHS.LOGBOOK) {
      switch (logbookType.toLowerCase()) {
        case LOGBOOK_TYPE_DETAILS:
          return 'graphs.logbookTitle';
        case LOGBOOK_TYPE_DIARY:
          return 'graphs.logbookDiary.diary';
        case LOGBOOK_TYPE_STATS:
          return 'graphs.logbookStatsTitle';
        case LOGBOOK_TYPE_24HOUR:
          return 'graphs.logbook24hoursTitle';
        default:
          return '';
      }
    }
    if (
      graph === GRAPHS.TREND ||
      graph === GRAPHS.STANDARD_DAY ||
      graph === GRAPHS.STANDARD_WEEK
    ) {
      switch (graphType.toLowerCase()) {
        case GRAPH_TYPE_DETAILS:
          return 'graphs.detailTitle';
        case GRAPH_TYPE_TREND:
          return 'graphs.trendTitle';
        default:
          return '';
      }
    }
  },
);

export const logBookStatsDayToGraphDetailInsulinReducer = (
  [dailyTotalUPerDay, dailyBasalUPerDay, dailyBolusUPerDay, dailyBoluses],
  { columns: [, , , , , , totalU, basalU, bolusU, boluses] },
) => [
  [...dailyTotalUPerDay, totalU],
  [...dailyBasalUPerDay, basalU],
  [...dailyBolusUPerDay, bolusU],
  [...dailyBoluses, boluses],
];

export const selectGraphDetailInsulin = createSelector(
  selectLogbookStatsData,
  (days) => {
    const [totalUPerDay, basalUPerDay, bolusUPerDay, bolusesPerDay] = days
      .reduce(logBookStatsDayToGraphDetailInsulinReducer, [[], [], [], []])
      .map(reject(isEmpty))
      .map((values) => average(values))
      .map((value) => roundToNDecimalPlaces(value, 2))
      .map((value) => (value ? value.toFixed(2) : 0));
    return { totalUPerDay, basalUPerDay, bolusUPerDay, bolusesPerDay };
  },
);

const findHighestGlucoseMeasurementValue = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) => measurements.reduce((acc, val) => max(acc, val.value), 0),
);

const roundToCeilingInterval = (value, bloodGlucoseUnit) => {
  const GRAPH_Y_MAX =
    bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
      ? GRAPH_Y_MAX_MMOL
      : GRAPH_Y_MAX_MG;
  const interval =
    bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
      ? GRAPH_Y_INTERVAL_MMOL
      : GRAPH_Y_INTERVAL_MG;
  return Math.min(GRAPH_Y_MAX, Math.ceil(value / interval) * interval);
};

export const selectVerticalAxesCeiling = createSelector(
  findHighestGlucoseMeasurementValue,
  selectPostIdealIntervalsFromThresholds,
  selectBloodGlucoseUnit,
  (value, { upperLimit }, bloodGlucoseUnit) =>
    value < upperLimit
      ? roundToCeilingInterval(upperLimit, bloodGlucoseUnit)
      : roundToCeilingInterval(value, bloodGlucoseUnit),
);

const selectBolusPaddingValue = createSelector(
  selectValidBolusesInDateSliderRange,
  selectBloodGlucoseUnit,
  (boluses, bloodGlucoseUnit) => {
    const GRAPH_Y_INTERVAL =
      bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
        ? GRAPH_Y_INTERVAL_MMOL
        : GRAPH_Y_INTERVAL_MG;
    return boluses.some(
      ({ bolusValue }) => bolusValue >= BOLUS_PADDING_THRESHOLD,
    )
      ? GRAPH_Y_INTERVAL
      : 0;
  },
);

export const selectVerticalAxesCeilingPadForBolus = createSelector(
  findHighestGlucoseMeasurementValue,
  selectPostIdealIntervalsFromThresholds,
  selectBolusPaddingValue,
  selectBloodGlucoseUnit,
  (value, { upperLimit }, paddingValue, bloodGlucoseUnit) =>
    value + paddingValue < upperLimit
      ? roundToCeilingInterval(upperLimit, bloodGlucoseUnit)
      : roundToCeilingInterval(value + paddingValue, bloodGlucoseUnit),
);

const selectFixedVerticalAxesCeiling = createSelector(
  selectBloodGlucoseUnit,
  (bloodGlucoseUnit) =>
    bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
      ? GRAPH_Y_MAX_MMOL
      : GRAPH_Y_MAX_MG,
);

export const selectGraphDetails = createSelector(
  selectGraphDetailBloodGlucose,
  selectGraphDetailTargetRanges,
  selectGraphDetailInsulin,
  selectHasSufficiencyToStatisticsCalculation,
  selectHasData,
  (
    bloodGlucoseValues,
    targetRangesValues,
    insulinValues,
    hasSufficiencyData,
    hasData,
  ) => ({
    bloodGlucoseValues,
    targetRangesValues,
    insulinValues,
    hasSufficiencyData,
    hasData,
  }),
);

export const selectDateRange = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) => {
    const dateRange = measurementsToDateRange(measurements);
    return {
      start: formatGraphsDate(dateRange.start),
      end: formatGraphsDate(dateRange.end),
    };
  },
);

export const selectVerticalLabel = (val) => [
  {
    value: 0.5,
    label: `${(<LocalizedText textKey="graphs.axisLabels.bloodGlucose" />)} (${(
      <LocalizedText textKey="graphs.axisLabels.mgPerDL" />
    )})`,
  },
];

export const selectShowGridLines = createSelector(
  selectGraphToggles,
  (toggles) => !!toggles.showGridLines,
);

export const selectTargetRange = createSelector(
  selectGraphThreshold,
  selectVerticalAxesCeiling,
  normalizeGraphTargetRange,
);

export const selectTargetRangePadForBolus = createSelector(
  selectGraphThreshold,
  selectVerticalAxesCeilingPadForBolus,
  normalizeGraphTargetRange,
);

// fixed to 400 for detail graphs
export const selectFixedTargetRange = createSelector(
  selectGraphThreshold,
  selectFixedVerticalAxesCeiling,
  normalizeGraphTargetRange,
);

export const selectThreshold = createSelector(
  selectGraphThreshold,
  selectVerticalAxesCeiling,
  normalizeGraphThreshold,
);

export const selectThresholdPadForBolus = createSelector(
  selectGraphThreshold,
  selectVerticalAxesCeilingPadForBolus,
  normalizeGraphThreshold,
);

// fixed to 400 for detail graphs
export const selectFixedThreshold = createSelector(
  selectGraphThreshold,
  selectFixedVerticalAxesCeiling,
  normalizeGraphThreshold,
);

const verticalTicks = [
  createVerticalTick(0),
  createVerticalTick(50),
  createVerticalTick(100),
  createVerticalTick(150),
  createVerticalTick(200),
  createVerticalTick(250),
  createVerticalTick(300),
  createVerticalTick(350),
  createVerticalTick(400),
];

const createThresholdTicks = (threshold, targetRange) => [
  {
    value: targetRange.max,
    label: `${targetRange.data.max}`,
    key: TARGET_RANGE_MAX_TICK_KEY,
    gridLine: false,
    color: TARGET_RANGE_TICK_COLOR,
  },
  {
    value: targetRange.min,
    label: `${targetRange.data.min}`,
    key: TARGET_RANGE_MIN_TICK_KEY,
    gridLine: false,
    color: TARGET_RANGE_TICK_COLOR,
  },
  {
    value: threshold.value,
    label: `${threshold.data.value}`,
    key: THRESHOLD_TICK_KEY,
    gridLine: false,
    color: HYPO_TICK_COLOR,
  },
];

export const selectThresholdTicks = createSelector(
  selectThreshold,
  selectTargetRange,
  createThresholdTicks,
);

export const selectThresholdTicksPadForBolus = createSelector(
  selectThresholdPadForBolus,
  selectTargetRangePadForBolus,
  createThresholdTicks,
);

// fixed to 400 for detail graphs
export const selectFixedThresholdTicks = createSelector(
  selectFixedThreshold,
  selectFixedTargetRange,
  createThresholdTicks,
);

const createVerticalTicks = (thresholdTicks, graphYMax) => {
  const normalizedVerticalTicks = normalizeVerticalTickValues(
    verticalTicks,
    graphYMax,
  );
  return [...normalizedVerticalTicks, ...thresholdTicks];
};

// This is for use by the selectors of graphs built with the graphing library in /lib
export const selectVerticalTicks = createSelector(
  selectThresholdTicks,
  selectVerticalAxesCeiling,
  createVerticalTicks,
);

// This is for use by the selectors of graphs built with the graphing library in /lib
export const selectVerticalTicksPadForBolus = createSelector(
  selectThresholdTicksPadForBolus,
  selectVerticalAxesCeilingPadForBolus,
  createVerticalTicks,
);

// fixed to 400 for detail graphs
export const selectFixedVerticalTicks = createSelector(
  selectFixedThresholdTicks,
  selectFixedVerticalAxesCeiling,
  createVerticalTicks,
);

export const pickBasalYMax = (largestBasalMeasurement) => {
  if (largestBasalMeasurement <= BASAL_MAX_VALUES[0]) {
    return BASAL_MAX_VALUES[0];
  }
  if (largestBasalMeasurement <= BASAL_MAX_VALUES[1]) {
    return BASAL_MAX_VALUES[1];
  }
  if (largestBasalMeasurement <= BASAL_MAX_VALUES[2]) {
    return BASAL_MAX_VALUES[2];
  }
  return BASAL_MAX_VALUES[3];
};

export const selectBasalYMax = createSelector(
  selectMaxBasalMeasurement,
  pickBasalYMax,
);

const selectBasalYMin = always(BASAL_Y_MIN);

export const selectBasalTicks = createSelector(
  selectBasalYMin,
  selectBasalYMax,
  (minBasal, maxBasal) => {
    const midBasal = (maxBasal - minBasal) / 2;

    return [
      { label: minBasal.toFixed(1), value: 0 },
      { label: midBasal.toFixed(1), value: 0.5 },
      { label: maxBasal.toFixed(1), value: 1 },
    ];
  },
);

const selectStartDate = createSelector(selectPatientStartDate, (date) =>
  isEqual(date, convertISOToJsGMT(null)) ? '' : date,
);

const selectEndDate = createSelector(selectPatientEndDate, (date) =>
  isEqual(date, convertISOToJsGMT(null)) ? '' : date,
);

export const graphConnector = createStructuredSelector({
  dateRange: selectDateRange,
  graph: selectGraph,
  graphType: selectGraphType,
  graphTypeTranslationKey: selectGraphTypeTranslationKey,
  logbookType: selectLogbookType,
  startDate: selectStartDate,
  endDate: selectEndDate,
  lastMeasurementDate: selectPatientLastMeasurementDate,
  patient: selectPatient,
  selectGraphToggles,
  graphDetails: selectGraphDetails,
  isHCPProfessional: selectIsHCPUserProfile,
  hasUserFhirPermission: selectFhirPermission,
  patientFhirId: selectPatientFhirId,
  hcpPermissions: selectPermissions,
  patientPermissions: selectPatientPermissions,
  isGP: selectIsGeneralPractitioner,
  isLoading: selectGraphLoading,
});
