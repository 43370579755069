import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

export const hcpHasPermission = (hcpPermissions?: string[]) =>
  hcpPermissions !== undefined
    ? hcpPermissions.includes(PERMISSIONS.VISIT_MANAGEMENT)
    : false;

export const hasVisitModulePermission = (hcpPermissions?: string[]) => {
  return hcpHasPermission(hcpPermissions);
};
