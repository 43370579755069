import { pathOr } from 'ramda';
import { createSelector } from 'reselect';

export const selectPatientSessionId = pathOr(null, [
  'patientSession',
  'patientSessionId',
]);

export const selectPatientSessionTime = pathOr(0, [
  'patientSession',
  'sessionTime',
]);

export const selectPatientSessionEndStatus = pathOr(0, [
  'patientSession',
  'sessionEndStatus',
]);

export const selectIsPatientSessionRunning = pathOr(false, [
  'patientSession',
  'isRunning',
]);

export const selectPatientExportSessionStatus = pathOr(0, [
  'patientSession',
  'exportStatus',
]);

export const selectPatientExportData = pathOr(null, [
  'patientSession',
  'exportPatientsData',
]);

export const selectIsPatientExportData = createSelector(
  selectPatientExportData,
  (data) => data !== null,
);

export const selectPatientVisitId = pathOr(null, ['patientSession', 'visitId']);
