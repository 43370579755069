import styled from 'styled-components';

import { borderRadius } from 'src/core/styles/border-radius';
import {
  transitions,
  transitionSpeed,
  transitionEasing,
} from 'src/core/styles/transitions';
import { fadeIn } from 'src/core/styles/animations';
import { colors } from 'src/core/styles/colors';

import { createStyledComponent } from 'src/utils/styles';
import { Div } from 'src/components/div/div.component';

import { POPOVER_LINK_ACTIVE_CLASS } from './menu-popover.constants';

export const SignOutLink = styled.a`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.brandBlue};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.p};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  padding: ${(props) => props.theme.spacing.four} 0;
  margin: 0 ${(props) => props.theme.spacing.four};
  border-top: 1px solid ${(props) => props.theme.colors.silverMedium};
  transition: ${transitions.default};

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: ${(props) => props.theme.colors.brandBlueDark};
  }
`;
SignOutLink.displayName = 'SignOutLink';

export const IconContainer = createStyledComponent<
  'div',
  { isJelloComponent: boolean }
>('div')`
  display: inline-block;
  width: 42px;
  height: 42px;
  border: 2px solid ${(props) =>
    props.isJelloComponent ? 'none' : colors.brandBlueDark};
  border-radius: 50%;
  position relative;
  transition: ${transitions.default};

  &:hover {
    border-color: ${colors.brandBlue};
  }

  span, .cloud-upload-icon {
    position absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cloud-upload-icon {
    color: ${colors.brandBlue};
  }
`;
IconContainer.displayName = 'IconContainer';

export const IconContainerHelp = styled(IconContainer)`
  display: inline-block;
  width: 42px;
  height: 42px;
  border: 2px solid ${colors.brandBlueDark};
  border-radius: 50%;
  position relative;
  transition: ${transitions.default};

  &:hover {
    border-color: ${colors.brandBlue};
  }

  span {
    position absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -10px;
  }
`;
IconContainerHelp.displayName = 'IconContainerHelp';

export const PopoverWrapper = styled.div`
  background: rgb(255, 255, 255);
  border-radius: ${borderRadius.three};
  boder-top: 5px solid #0066cc !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  width: 264px;
  height: auto;
  display: inline-block;
  margin: 20px;
  position: relative;
  > div {
    background: red;
  }

  background-color: ${colors.white};
  left: 33px;

  animation: ${fadeIn} ${transitionSpeed.fast} ${transitionEasing.enter}
    backwards;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #0066cc;
    position: absolute;
    top: -10px;
    left: 20px;
  }
`;
PopoverWrapper.displayName = 'PopoverWrapper';

export const PopoverListItemText = createStyledComponent<'span', { max?: any }>(
  'span',
)`
  letter-spacing: 0px;
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
  cursor: default;
  padding: 0 3px 0 3px;
`;
PopoverListItemText.displayName = 'PopoverListItemText';

export const PopoverRow = styled.div`
  display: flex;
  padding-right: 31px;
`;
PopoverRow.displayName = 'PopoverRow';

export const PopoverListItemNoLink = styled(Div)`
  border-left: 3px solid ${(props) => props.theme.colors.clear};
  color: ${(props) => props.theme.colors.grayDark};
  display: block;
  padding: 1rem 1rem;
  padding-bottom: 10px;
  text-decoration: none;
  transition: ${transitions.default};
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.silverLight};
    color: ${(props) => props.theme.colors.charcoal};
  }

  &:active,
  &.${POPOVER_LINK_ACTIVE_CLASS} {
    border-color: ${(props) => props.theme.colors.brandBlue};
    color: ${(props) => props.theme.colors.brandBlue};
  }
`;
PopoverListItemNoLink.displayName = 'PopoverListItemNoLink';
