// @ts-nockeck
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Sticky } from 'react-sticky';
import { ConditionalRouteForVisitModuleStickyPatientSummaryBar } from 'src/modules/visit-module/routes';
import { PatientInfoContainer } from 'src/widgets/top-patient-bar/patient-info/patient-info.container';
import { VisitModuleSidebarAnchor } from 'src/modules/visit-module/sidebar/visit-module-sidebar-anchor';

import { PeriodSelectors } from './period-selectors-block/period-selectors-block.component';
import {
  stickyHeaderHeight,
  StickyHeaderWrapper,
} from './patient-data-bar.style';

type PatientDataBar = {
  isJelloActivated?: boolean;
  dateControls?: boolean;
};

export const PatientDataBar: React.FunctionComponent<PatientDataBar> = ({
  dateControls = false,
  isJelloActivated = false,
}) => (
  /* Disable `transform: translateZ(0)` since it breaks the dropdown menu */
  <Sticky disableHardwareAcceleration>
    {({ style: reactStickyStyles, isSticky }) => (
      <div
        style={
          isJelloActivated ? { marginLeft: '1rem', marginRight: '1rem' } : {}
        }
      >
        <StickyHeaderWrapper
          style={
            isJelloActivated
              ? { ...reactStickyStyles, margin: isSticky ? 'auto' : '0 -1rem' }
              : { ...reactStickyStyles, width: isSticky ? '100%' : 'auto' }
          }
        >
          <PatientInfoContainer />
          <PeriodSelectors />
          <BrowserRouter>
            <ConditionalRouteForVisitModuleStickyPatientSummaryBar
              component={
                <div
                  style={{
                    position: 'relative',
                    right: '-1rem',
                    top: stickyHeaderHeight / 2 + 'px',
                  }}
                >
                  <VisitModuleSidebarAnchor />
                </div>
              }
            />
          </BrowserRouter>
        </StickyHeaderWrapper>
      </div>
    )}
  </Sticky>
);
