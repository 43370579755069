import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';
import {
  CheckHCIDServiceImplType,
  CheckHCIDTransformImplType,
  CheckHCIDResponseType,
} from './check-hcid.types';

export const CheckHCIDLoaderImpl = (
  healthcareId: string,
  accessToken: string,
): Promise<CheckHCIDResponseType | void> =>
  getJSON(
    endpointWithParams(ENDPOINTS.healthCareIdAvailable, { healthcareId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  ).then((res) => {
    if (typeof res === 'object') {
      return Promise.resolve(res);
    } else {
      throw new Error(res);
    }
  });

export const CheckHCIDTransformImpl: CheckHCIDTransformImplType = (
  response: CheckHCIDResponseType,
) => response;

export const CheckHCIDServiceImpl: CheckHCIDServiceImplType =
  (load, transform) => (healthcareId, accessToken) =>
    load(healthcareId, accessToken).then(transform);
