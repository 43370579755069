export const VARIABILITY_THRESHOLD = 36;

export const TRAFFIC_LIGHT_COLORS = {
  GRAY: 'GRAY',
  GREEN: 'GREEN',
  RED: 'RED',
  YELLOW: 'YELLOW',
};

export const NO_MEASUREMENTS_INTERVAL_LABEL = '-';

export const NO_MEASUREMENTS_ENOUGH_LABEL =
  'bloodGlucoseOverview.notEnoughData';

export const TRAFFIC_LIGHT_LABELS = {
  HYPO: 'bloodGlucoseOverview.hypo',
  HYPER: 'bloodGlucoseOverview.hyper',
  IN_RANGE: 'bloodGlucoseOverview.inRange',
  LOW: 'bloodGlucoseOverview.low',
  MEDIUM: 'bloodGlucoseOverview.medium',
  HIGH: 'bloodGlucoseOverview.high',
  STABLE: 'bloodGlucoseOverview.stable',
  UNSTABLE: 'bloodGlucoseOverview.unstable',
  INSUFFICIENT_INFO: 'bloodGlucoseOverview.na',
  NO_INFO: NO_MEASUREMENTS_INTERVAL_LABEL,
};

export const MINIMUM_TESTS_FOR_INTERVAL = 28;
export const MINIMUM_AVERAGE_TESTS_FOR_DAY = 2;

export const MINIMUM_LBGI_CALCULATED_VALUE = 1;

export const MINIMUM_DAYS_FOR_INTERVAL = 21;
export const MINIMUM_MEASUREMENTS_PER_DAY = 6;

export const MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS = 2;

export const NUMBER_BLOOD_GLUCOSE_OVERVIEW_COLUMNS = 6;

export const HYPO_RISK_THRESHOLDS = {
  MEDIUM: 2.5,
  HIGH: 5,
};
