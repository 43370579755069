import { createPayloadAction } from 'src/app/store/app.actions';
import { SAVE_PATIENT_REPORTS_REQUEST } from './patient-reports.constant';

export const savePatientReportsStart = (params): any =>
  createPayloadAction(SAVE_PATIENT_REPORTS_REQUEST.START, params);

export const savePatientReportsSuccess = (params): any =>
  createPayloadAction(SAVE_PATIENT_REPORTS_REQUEST.SUCCESS, params);

export const savePatientReportsError = (params): any =>
  createPayloadAction(SAVE_PATIENT_REPORTS_REQUEST.ERROR, params);
