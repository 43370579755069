import {
  filter,
  both,
  curry,
  lte,
  gte,
  forEachObjIndexed,
  forEach,
  keys,
  isNil,
} from 'ramda';

import { NUMBER_OF_DAYS_LAST_PULLED } from '../constants';
import { getDaysLastSync } from '../hcp-dashboard/components/device-sync-info/device-sync-info.utils';

export const filterPatients = (filters, state) => {
  const moment = require('moment');
  const allPatients = state.allPatients;
  const getAge = (b) => moment().diff(moment(b), 'years');
  const isBetween = curry((min, max, val) =>
    val || val === 0 ? both(lte(min), gte(max))(val) : false,
  );
  const getTestsPerDay = (value) =>
    Math.floor((isNil(value) ? null : value) / NUMBER_OF_DAYS_LAST_PULLED);
  const getRPMStatus = (hasProgram, compliant) => {
    if (!hasProgram) return 'NON_RPM';
    return compliant ? 'YES_COMPLIANT' : 'YES_NON_COMPLIANT';
  };
  return filter(
    ({
      patientInfoDTO: patientInfo,
      patientClinicalStatisticsDTO: statBg,
      patientCgmClinicalStatisticsDTO: statCgm,
      glycatedInfoDTO,
      patientRpmStatus,
    }) => {
      const conditions = {};
      const pi = { ...patientInfo };
      const st = { ...statBg, ...statCgm };
      const gi = { ...glycatedInfoDTO };

      const values = {
        age: getAge(pi.birthday),
        gender: pi.gender,
        diabetesType: pi.diabetesType,
        rpmStatus: getRPMStatus(pi.hasRPMProgram, patientRpmStatus),
        hcpIsAccessible: pi.hcpIsAccessible,
        variability: st.coefficientOfVariation,
        testFrequency: getTestsPerDay(st.numberOfTests),
        lastDeviceSync: getDaysLastSync(st.lastDeviceSync),
        cgmActiveTime: st.cgmActiveTime,
        timeInRange: st.gldInRange,
        gmi: st.glucoseManagementIndicator,
        timeBellowRange: st.gldLow + st.gldVeryLow,
        hbA1c: gi.hba1cValue,
        patientStatus: st.hypoEvents,
        gldInRange: st.gldInRange,
        gldVeryLow: st.gldCheckLow,
        gldVeryHigh: st.gldCheckHigh,
      };
      forEachObjIndexed((vals, key) => {
        conditions[key] = false;
        forEach((val) => {
          if (val === 'all') {
            conditions[key] = true;
          } else {
            if (key === 'lastDeviceSync' && val[0] > 4 && isNaN(values[key])) {
              values[key] = 1000;
            }
            conditions[key] =
              (typeof val === 'object'
                ? isBetween(val[0] || -10000, val[1] || 10000, values[key])
                : val === values[key]) || conditions[key];
          }
        }, vals);
      }, filters);
      return !keys(filter((c) => c === false, conditions)).length;
    },
    allPatients,
  );
};
