import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type InformationIconProps = {
  title?: string;
  height?: number;
  fillColor?: string;
  originalWidth?: number;
  originalHeight?: number;
  innerColor?: string;
  innerRadius?: number;
};

export const NoDataIcon = ({
  height = 21,
  title,
  fillColor = colors.white,
  originalWidth = 20,
  originalHeight = 21,
  innerColor = colors.brandBlue,
  innerRadius = 15,
}: InformationIconProps) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      title={title}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      innerColor={innerColor}
      radius={innerRadius}
    >
      <text
        id="i"
        fontFamily="Courier New"
        fontSize="15"
        fontWeight="2000"
        letterSpacing="-0.213284612"
        fill={innerColor}
      >
        <tspan x="0" y="15">
          --
        </tspan>
      </text>
    </SvgIcon>
  );
};
