import {
  InvitePatientLoaderImpl,
  InvitePatientTransformImpl,
  InvitePatientServiceImpl,
} from './invite-patient.service';
import { InvitePatientResponseType } from './invite-patient.types';
import { InvitePatientMockResponse } from './invite-patient.mock';
import { Promise } from 'es6-promise';

export const mockLoader = (): Promise<InvitePatientResponseType> =>
  Promise.resolve(InvitePatientMockResponse);

export const InvitePatientFactoryImp = ({ devMode }) => {
  const loader = devMode ? mockLoader : InvitePatientLoaderImpl;
  return InvitePatientServiceImpl(loader, InvitePatientTransformImpl);
};
