import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { find, propEq, path, pathOr, propOr } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { selectCountries } from 'src/core/countries/countries.selectors';
import { selectConfigurablePermissions } from 'src/core/configurables/configurables.selectors';
import { selectDepartmentTypesProfessional } from 'src/core/department/department.selectors';
import {
  selectDepartmentProfessionals,
  selectDepartmentTypes,
} from 'src/core/department/department.selectors';
import { selectLanguages } from 'src/core/languages/languages.selectors';

import {
  selectEC6DepartmentId,
  selectEC6UserId,
  selectEC6UserLanguage,
  selectEC6UserCountryId,
} from 'src/core/user/user.selectors';

import {
  convertActiveLanguagesToDropdownOptions,
  convertEC6CountriesToDropdownOptions,
  convertProfessionaTypesToDropdownOptions,
} from 'src/widgets/forms/store/utils';

const selectCreateProfessionalState = path(['createProfessional']);

const selectIsCreatingProfessional = createSelector(
  selectCreateProfessionalState,
  path(['isCreatingProfessional']),
);

const selectCreationWasSuccessful = createSelector(
  selectCreateProfessionalState,
  path(['creationWasSuccessful']),
);

const selectCreationHasError = createSelector(
  selectCreateProfessionalState,
  path(['creationHasError']),
);

export const selectNewProfessional = createSelector(
  selectCreateProfessionalState,
  (state) => path(['newProfessional'])(state),
);

export const selectCountryDropdownOptions = createSelector(
  selectCountries,
  (countries) => convertEC6CountriesToDropdownOptions(countries),
);

export const selectLanguageDropdownOptions = createSelector(
  selectLanguages,
  (languages) => convertActiveLanguagesToDropdownOptions(languages),
);

export const selectProfessionalTypesDropdownOptions = createSelector(
  selectDepartmentTypesProfessional,
  (types) => convertProfessionaTypesToDropdownOptions(types),
);

export const selectDefaultProfessionalDepartmentId = createSelector(
  selectDepartmentTypesProfessional,
  (types) => {
    const def = find(propEq('name', 'ADMINISTRATIVE'))(types);
    return propOr(null, 'departmentProfileId')(def);
  },
);

export const selectCurrentUserByEC6UserId = createSelector(
  selectEC6UserId,
  selectDepartmentProfessionals,
  (EC6UserId, professionals) =>
    professionals.find(
      (professional) => pathOr('', ['user', 'id'], professional) === EC6UserId,
    ),
);

export const selectCurrentUserLanguageByLocale = createSelector(
  selectEC6UserLanguage,
  selectLanguages,
  (languageISOCode, activeLanguages) =>
    activeLanguages.find((language) => {
      const lng = pathOr('', ['locale'], language);
      return lng.toLowerCase() === languageISOCode;
    }),
);

export const createProfessionalConnector = (state) => {
  return createStructuredSelector({
    accessToken: selectAccessToken,
    apiKey: selectGigyaToken,
    countries: selectCountryDropdownOptions,
    departmentId: selectEC6DepartmentId,
    languages: selectLanguageDropdownOptions,
    professionals: selectProfessionalTypesDropdownOptions,
    professionalsDefaultId: selectDefaultProfessionalDepartmentId,
    professionalId: selectEC6UserId,
    creationWasSuccessful: selectCreationWasSuccessful,
    creationHasError: selectCreationHasError,
    isCreatingProfessional: selectIsCreatingProfessional,
    newProfessional: selectNewProfessional,
    departmentProfileTypes: selectDepartmentTypes,
    configurablePermissions: selectConfigurablePermissions,
    countryId: selectEC6UserCountryId,
    departmentTypes: selectDepartmentTypesProfessional,
  });
};
