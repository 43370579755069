import React from 'react';
import styles from './styles.module.css';

import { RenderIf } from 'src/utils/render-if';
import {
  JELLO_TEXT_WEIGHTS,
  JELLO_TEXT_SIZES,
  JELLO_ICON_SIZES,
  JELLO_TOOLBAR_SIZES,
  JELLO_TOOLBAR_ORIENTATIONS,
  JELLO_ICON_NAMES,
  JELLO_TOOLTIP_POSITIONS,
} from 'src/app/app.jello.constants';
import { ADMINISTRATIVE } from 'src/core/user/user.constants.js';
import { PatientActionsComponent } from '../patient-actions-jello/patient-actions-jello.component';
import {
  DevicesTypes,
  PATIENT_NAV_THERAPY_TYPE_TOOLTIP,
  PATIENT_NAV_DIABETES_TYPE_TOOLTIP,
} from './patient-data-bar.constants';
import {
  formatDoB,
  formatDiagnosticsDate,
  deviceListContainsActivedDevice,
  hasPatientCGMAssociation,
  getShortTranslatedTherapyType,
  getTranslatedTherapyType,
  getShortTranslatedDiabetesType,
  getTranslatedDiabetesType,
} from './patient-data-bar-jello.utils';
import { t } from 'i18next';
import { testId } from '@roche/roche-common';

import { JelloTooltip } from 'src/components/jello-tooltip/jello-tooltip.component';

import { DataSharingStatusBtn } from '../data-sharing/data-sharing-status-btn/data-sharing-status-btn.container';
export const PatientDataBarComponent = (props) => {
  const { patient, activeDevices, cgmSummary, profile, isPlatformUser } = props;
  const {
    surName,
    firstName,
    healthCareSystemId,
    diabetesTypeEC6,
    dateOfBirth,
    diagnosticDate,
    therapyType,
  } = patient;

  const canDisableTherapyTypeTooltip = therapyType === null ? true : undefined;
  const canDisableDiabetesTypeTooltip =
    diabetesTypeEC6 === null ? true : undefined;

  return (
    <jello-toolbar
      orientation={JELLO_TOOLBAR_ORIENTATIONS.HORIZONTAL}
      size={JELLO_TOOLBAR_SIZES.S}
      style={{
        borderBottom: '1px solid var(--jello-color-background-alternative)',
        marginBottom: 0,
      }}
      {...testId('jello-toolbar', 'patient-databar')}
    >
      <div className={styles.toolbarPatientInfoWrapper}>
        <div className={styles.patientMainDataWrapper}>
          <jello-text
            class={styles.patientMainDataText}
            size={JELLO_TEXT_SIZES.M}
            weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
          >
            {`${surName}, ${firstName}`}
          </jello-text>

          <DataSharingStatusBtn small={true} />
        </div>
        <div className={styles.toolbarDivider} />
        <div className={styles.patientSecondaryDataWrapper}>
          <div
            className={styles.patientTitleAndInfoWrapper}
            style={{ maxWidth: '50px' }}
          >
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientNav.id')}
            </jello-text>
            <RenderIf validate={!!healthCareSystemId}>
              <jello-tooltip delay="300" label={`${healthCareSystemId}`}>
                <jello-text
                  ellipsis=""
                  size={JELLO_TEXT_SIZES.S}
                  weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
                >
                  {healthCareSystemId}
                </jello-text>
              </jello-tooltip>
            </RenderIf>
            <RenderIf validate={!healthCareSystemId}>
              <jello-text size={JELLO_TEXT_SIZES.S}>-</jello-text>
            </RenderIf>
          </div>
          <div className={styles.patientTitleAndInfoWrapper}>
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientNav.dateOfBirth')}
            </jello-text>
            <jello-text
              class={styles.patientMainDataText}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {formatDoB(dateOfBirth)}
            </jello-text>
          </div>
          <div className={styles.patientTitleAndInfoWrapper}>
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientNav.diabetes')}
            </jello-text>
            <JelloTooltip
              id={PATIENT_NAV_DIABETES_TYPE_TOOLTIP}
              label={t(getTranslatedDiabetesType(diabetesTypeEC6))}
              disabled={canDisableDiabetesTypeTooltip}
              position={JELLO_TOOLTIP_POSITIONS.BOTTOM}
            >
              <jello-text
                class={styles.patientMainDataText}
                size={JELLO_TEXT_SIZES.S}
                weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
              >
                {t(getShortTranslatedDiabetesType(diabetesTypeEC6))}
              </jello-text>
            </JelloTooltip>
          </div>
          <RenderIf validate={isPlatformUser}>
            <div className={styles.patientTitleAndInfoWrapper}>
              <jello-text
                class={styles.patientDataTitle}
                size={JELLO_TEXT_SIZES.S}
                weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
              >
                {t('patientNav.therapyTitle')}
              </jello-text>
              <JelloTooltip
                id={PATIENT_NAV_THERAPY_TYPE_TOOLTIP}
                label={t(getTranslatedTherapyType(therapyType))}
                disabled={canDisableTherapyTypeTooltip}
                position={JELLO_TOOLTIP_POSITIONS.BOTTOM}
              >
                <jello-text
                  class={styles.patientMainDataText}
                  size={JELLO_TEXT_SIZES.S}
                  weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
                >
                  {t(getShortTranslatedTherapyType(therapyType))}
                </jello-text>
              </JelloTooltip>
            </div>
          </RenderIf>
          <div className={styles.patientTitleAndInfoWrapper}>
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientNav.diagnosed')}
            </jello-text>
            <jello-text
              class={styles.patientMainDataText}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {formatDiagnosticsDate(diagnosticDate)}
            </jello-text>
          </div>
          <div className={styles.patientTitleAndInfoWrapper}>
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientBar.deviceTitle')}
            </jello-text>
            <div className={styles.toolbarIconWrapper}>
              <jello-tooltip
                delay="300"
                label={t('patientBar.deviceType.meter')}
                position="bottom"
              >
                <jello-icon
                  class={
                    deviceListContainsActivedDevice(
                      [DevicesTypes.Meter, DevicesTypes.Normal],
                      activeDevices,
                    )
                      ? styles.iconActive
                      : styles.iconInactive
                  }
                  size={JELLO_ICON_SIZES.S}
                  icon-name={JELLO_ICON_NAMES.ACCU_CHEK_GUIDE}
                />
              </jello-tooltip>
              <jello-tooltip
                delay="300"
                label={t('patientBar.deviceType.cgmSensor')}
                position="bottom"
              >
                <jello-icon
                  class={
                    deviceListContainsActivedDevice(
                      [DevicesTypes.Cgm],
                      activeDevices,
                    ) || hasPatientCGMAssociation({ ...cgmSummary })
                      ? styles.iconActive
                      : styles.iconInactive
                  }
                  size={JELLO_ICON_SIZES.S}
                  icon-name={JELLO_ICON_NAMES.CGM_PATCH}
                />
              </jello-tooltip>
              <jello-tooltip
                delay="300"
                label={t('patientBar.deviceType.pump')}
                position="bottom"
              >
                <jello-icon
                  class={
                    deviceListContainsActivedDevice(
                      [DevicesTypes.Meterpump],
                      activeDevices,
                    )
                      ? styles.iconActive
                      : styles.iconInactive
                  }
                  size={JELLO_ICON_SIZES.S}
                  icon-name={JELLO_ICON_NAMES.ACCU_CHEK_INSIGHT_PUMP}
                />
              </jello-tooltip>
            </div>
          </div>
        </div>
      </div>
      <jello-toolbar-spacer />
      <RenderIf validate={profile !== ADMINISTRATIVE}>
        <PatientActionsComponent
          visitModuleActiveSection={props.visitModuleActiveSection}
          setVisitModuleActiveSection={props.setVisitModuleActiveSection}
        />
      </RenderIf>
    </jello-toolbar>
  );
};
