import * as React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type Props = {
  height: number;
  outlineColor?: string;
  bgColor?: string;
  buttonsColor?: string;
  buttonsStrokeColor?: string;
  screenColor?: string;
  screenStrokeColor?: string;
  t: (key: string, options?: any) => string;
};

export const V3Device3IconComponent = ({
  height = 15,
  outlineColor = colors.brandBlueDark,
  bgColor = colors.white,
  buttonsColor = colors.white,
  buttonsStrokeColor = colors.brandBlue,
  screenColor = colors.blue3,
  screenStrokeColor = colors.brandBlueDark,
  t,
}: Props) => {
  const originalWidth = 26;
  const originalHeight = 26;
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
      title={t('patientBar.deviceType.cgm')}
    >
      <g
        stroke={outlineColor}
        strokeWidth="1"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g fillRule="nonzero" strokeWidth="1.19069767">
          <g>
            <g>
              <g transform="translate(-3, -3)">
                <circle
                  stroke={outlineColor}
                  fill={bgColor}
                  cx="16"
                  cy="16"
                  r="11.5348837"
                />
                <circle
                  stroke={buttonsStrokeColor}
                  fill={buttonsColor}
                  cx="16"
                  cy="16"
                  r="8.55813953"
                />
                <circle
                  stroke={screenStrokeColor}
                  fill={screenColor}
                  cx="16"
                  cy="16"
                  r="2.97674419"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const V3Device3Icon = withTranslation(V3Device3IconComponent);
