import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { ENDPOINTS } from 'src/services/service.constants';
import { hasValue } from 'src/utils/validation-helpers';

import {
  checkPairingCodeLoaderImplType,
  checkPairingCodeTransformImplType,
  checkPairingCodeResponseType,
  CheckPairingCodeServiceImplType,
} from './pairing-code-check.types';

export const transformEmptyValues = (value) => (hasValue(value) ? value : '');

export const CheckPairingCodeLoaderImpl: checkPairingCodeLoaderImplType = (
  accessToken: string,
  apiKey: string,
  pairingCode: string,
) => {
  return getJSON(ENDPOINTS.pairingCodeCheck, {
    headers: {
      Authorization: createAuthHeader(accessToken),
      PairingCode: pairingCode,
    },
  });
};

export const CheckPairingCodeTransformImpl: checkPairingCodeTransformImplType =
  (response: checkPairingCodeResponseType) => {
    const {
      user,
      professionalList,
      healthCareSystemId,
      diagnosticDate,
      diabetesType,
    } = response;

    const {
      address,
      birthday,
      email,
      gender,
      languageId,
      name,
      phone,
      surname,
      surname2,
    } = user;

    return {
      requiredInfo: {
        name,
        lastname: `${surname}${surname2 ? '' + surname2 : ''}`,
        email,
        birthdate: birthday,
        emailCheckbox: true,
      },
      optionalInfo: {
        healthcareId: transformEmptyValues(healthCareSystemId),
        diabetesType: transformEmptyValues(diabetesType),
        therapyType: '',
        diagnosisDate: transformEmptyValues(diagnosticDate),
        associatedProfessional: hasValue(professionalList)
          ? professionalList
          : [],
        language: transformEmptyValues(languageId),
        sexAtBirth: transformEmptyValues(gender).toLowerCase(),
        prefix: '',
        phone: transformEmptyValues(phone),
      },
      accountConfig: {
        address: {
          addressLine: address?.address,
          city: address?.city,
          postCode: address?.postalCode,
          province: address?.province,
          country: address?.country?.id,
        },
      },
    };
  };

export const CheckPairingCodeServiceImpl: CheckPairingCodeServiceImplType =
  (load, transform) => (accessToken, apiKey, query) => {
    return load(accessToken, apiKey, query).then(transform);
  };
