/* tslint:disable */

import * as React from 'react';
import {
  StyledTablePrimaryCell,
  StyledHighlightBox,
} from './table-primary-cell.style';
import { TablePrimaryCellProps } from '../table-primary.types';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

export const TablePrimaryCell = ({
  value,
  valueHighlighted,
  icon,
  id,
  colSpan,
  highlight,
  disabled,
  semiBold,
  unit,
  cellWidth,
}: TablePrimaryCellProps) => (
  <StyledTablePrimaryCell
    colSpan={colSpan}
    highlight={highlight}
    disabled={disabled}
    semiBold={semiBold}
    id={id}
    cellWidth={cellWidth}
  >
    <span>{value}</span>
    <RenderIf validate={valueHighlighted}>
      <StyledHighlightBox>{valueHighlighted}</StyledHighlightBox>
    </RenderIf>
    <RenderIf validate={unit}>
      <span>{unit}</span>
    </RenderIf>
    <span> {icon}</span>
  </StyledTablePrimaryCell>
);
