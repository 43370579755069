import { SaveTargetRangesPayload } from './save-target-ranges.types';
import { map } from 'ramda';

export const transformTargetRanges = (range: SaveTargetRangesPayload) => {
  const { unit, beforeEating, afterEating, beforeBed, nonMealRelated } = range;
  const payload = {
    unit,
    preHyper: beforeEating.actualHyper,
    preHigh: beforeEating.hyper,
    preLow: beforeEating.warning,
    preHipo: beforeEating.hypo,
    postHyper: afterEating.actualHyper,
    postHigh: afterEating.hyper,
    postLow: afterEating.warning,
    postHipo: afterEating.hypo,
    noctHyper: beforeBed.actualHyper,
    noctHigh: beforeBed.hyper,
    noctLow: beforeBed.warning,
    noctHipo: beforeBed.hypo,
    nonMealHyper: nonMealRelated.actualHyper,
    nonMealHigh: nonMealRelated.hyper,
    nonMealLow: nonMealRelated.warning,
    nonMealHipo: nonMealRelated.hypo,
  };
  return map((r) => parseFloat(r) || r, payload);
};
