import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  CheckDuplicatedHealthcareIdActionType,
  CheckDuplicatedHealthcareIdStartAction,
  CheckDuplicatedHealthcareIdSuccessAction,
  CheckDuplicatedHealthcareIdErrorAction,
  ResetDuplicatedHealthcareIdAction,
  CheckDuplicatedHealthcareIdStartPayload,
  CheckDuplicatedHealthcareIdSuccessPayload,
  CheckDuplicatedHealthcareIdErrorPayload,
} from './check-duplicated-healthcare-id.actions.types';

export const checkDuplicatedHealthcareIdStart = (
  payload: CheckDuplicatedHealthcareIdStartPayload,
): CheckDuplicatedHealthcareIdStartAction =>
  createPayloadAction(
    CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_START,
    payload,
  );

export const checkDuplicatedHealthcareIdSuccess = (
  payload: CheckDuplicatedHealthcareIdSuccessPayload,
): CheckDuplicatedHealthcareIdSuccessAction =>
  createPayloadAction(
    CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_SUCCESS,
    payload,
  );

export const checkDuplicatedHealthcareIdError = (
  payload: CheckDuplicatedHealthcareIdErrorPayload,
): CheckDuplicatedHealthcareIdErrorAction =>
  createPayloadAction(
    CheckDuplicatedHealthcareIdActionType.CHECK_DUPLICATED_HEALTHCARE_ID_ERROR,
    payload,
  );

export const resetDuplicatedHealthcareIdAction =
  (): ResetDuplicatedHealthcareIdAction =>
    createAction(
      CheckDuplicatedHealthcareIdActionType.RESET_DUPLICATED_HEALTHCARE_ID,
    );
