import { colors } from 'src/core/styles/colors';
import {
  addDays,
  diffDays,
  epochTime,
  hasSame,
  toStartOfDay,
} from '../../../../../utils/date';

import {
  BASAL_PROFILE_CHANGE_TYPE,
  GRAPH_Y_MIN,
  GRAPHS,
  Y_AXIS_TICK_VISIBILITY_TOLERANCE,
} from '../graph.constants';

export const isSameDay = (date1, date2) => hasSame('day', date1, date2);

const generateShapeType = (bg, isMean = false) => {
  const shape = isMean
    ? 'circle'
    : bg.beforeMeal || bg.afterMeal
    ? 'rectangle'
    : 'cross';
  return !isMean && bg.value > 400 ? 'triangle' : shape;
};

export const getToolTipValueColor = (value, threshold, targetRange) => {
  const { max } = targetRange;

  if (value > max) {
    return colors.blueMarine;
  } else if (value >= threshold && value <= max) {
    return colors.black;
  } else {
    return colors.red;
  }
};

const normalizeTargetRange = (
  { glucoseIdealIntervalMin, glucoseIdealIntervalMax },
  floor,
  ceiling,
) => ({
  min: glucoseIdealIntervalMin / ceiling,
  max: glucoseIdealIntervalMax / ceiling,
  data: {
    min: glucoseIdealIntervalMin,
    max: glucoseIdealIntervalMax,
  },
});

export const normalizeGraphTargetRange = (threshold, graphYMax) =>
  normalizeTargetRange(threshold, GRAPH_Y_MIN, graphYMax);

const normalizeThreshold = ({ hypoglycemiaThreshold }, floor, ceiling) => ({
  value: hypoglycemiaThreshold / ceiling,
  data: { value: hypoglycemiaThreshold },
});

export const normalizeGraphThreshold = (threshold, graphYMax) =>
  normalizeThreshold(threshold, GRAPH_Y_MIN, graphYMax);

export const createVerticalTick = (value) => ({
  value: value,
  label: value.toString(),
  gridLine: true,
});

export const normalizeVerticalTickValues = (verticalTicks, ceiling) => {
  if (!verticalTicks.some((tick) => tick.value === ceiling)) {
    const ceilingVerticalTick = createVerticalTick(ceiling);
    verticalTicks.push(ceilingVerticalTick);
  }

  return verticalTicks
    .filter((tick) => tick.value <= ceiling)
    .map((tick) => ({ ...tick, value: tick.value / ceiling }));
};

export const togglePointsFilter = (points, toggles) =>
  points.filter((point) => {
    const showAfterMeal =
      point.data.afterMeal && toggles.showBloodGlucoseAfterMealPoints;
    const showBeforeMeal =
      point.data.beforeMeal && toggles.showBloodGlucoseBeforeMealPoints;
    const showRegularPoint =
      !(point.data.afterMeal || point.data.beforeMeal) &&
      toggles.showBloodGlucosePoints;
    return showAfterMeal || showBeforeMeal || showRegularPoint;
  });

export const navigateToLogbook = (
  history,
  date,
  changeLogbookType,
  logbookType,
) => {
  if (history.location.pathname.indexOf(GRAPHS.LOGBOOK) !== -1) {
    return;
  }
  const currentPath = history.location.pathname;
  const splittedPath = currentPath.split('/');

  const dateParam = epochTime(toStartOfDay(date));
  !splittedPath.includes(GRAPHS.ROOT)
    ? history.push(
        `${currentPath}/${GRAPHS.ROOT}/${GRAPHS.LOGBOOK}/${dateParam}`,
      )
    : history.push(`${GRAPHS.LOGBOOK}/${dateParam}`);
  changeLogbookType(logbookType);
};

const getSlope = (x1, y1, x2, y2) => (y2 - y1) / (x2 - x1);
const getYIntercept = (x, y, slope) => y - slope * x;
const getY = (x, yIntercept, slope) => slope * x + yIntercept;

export const getYFromTwoPointsAndOneXValue = (x1, y1, x2, y2, x3) => {
  const slope = getSlope(x1, y1, x2, y2);
  const yIntercept = getYIntercept(x1, y1, slope);
  return getY(x3, yIntercept, slope);
};

export const createEventHandlerStream =
  (eventStream$, stop$) =>
  (
    singleEventHandler,
    multipleEventHandler, // e.g. double click
  ) => {
    eventStream$
      .bufferWhen(() => eventStream$.debounceTime(250))
      .takeUntil(stop$)
      .subscribe((values) =>
        values.length === 1
          ? singleEventHandler(values)
          : multipleEventHandler(values),
      );
  };

export const basalRateProfileChanged = ({ basalRemark }) =>
  basalRemark && basalRemark.indexOf('changed') > -1;

export const filterProfileChanges = (measurements) =>
  measurements.reduce((acc, measurement, index) => {
    if (index !== 0 && !basalRateProfileChanged(measurement)) {
      return acc;
    }

    const changeType =
      index === 0
        ? BASAL_PROFILE_CHANGE_TYPE.ACTIVE
        : BASAL_PROFILE_CHANGE_TYPE.PROFILE_CHANGE;

    return [...acc, { ...measurement, changeType }];
  }, []);

export const getBasalProfile = ({ basalRateProfile, basalRemark }, index) =>
  index === 0 ? basalRateProfile : basalRemark.split('changed')[1];

export const filterBasalRateChanges = (measurements) =>
  measurements.filter(
    (measurement) =>
      measurement.basalRemark &&
      measurement.basalRemark.match(/[0-9A-Z]{1}\s*-\s*[0-9A-Z]{1}/g),
  );

const isNotDuplicateLine = (points) =>
  points[0].x !== points[1].x || points[0].y !== points[1].y;

export const filterDuplicateLines = (lines) => lines.filter(isNotDuplicateLine);

const addMidnightBasalMeasurements = (measurement, date, endDate) => {
  const startOfNextDay = toStartOfDay(addDays(1)(date));
  const daysToAdd = Math.floor(diffDays(endDate, date).days);

  return [
    { ...measurement, date, endDate: startOfNextDay },
    ...Array.from({ length: daysToAdd }).map((_, index) => ({
      ...measurement,
      date: addDays(index)(startOfNextDay),
      endDate: addDays(index + 1)(startOfNextDay),
    })),
    { ...measurement, date: toStartOfDay(endDate), endDate },
  ];
};

export const addBasalEndDatesAndMidnightMeasurements = (measurements) =>
  measurements.reduce((acc, measurement, index, originalArray) => {
    const nextMeasurement = originalArray[index + 1];
    const isLastMeasurement = index === originalArray.length - 1;

    const { date } = measurement;
    const startOfNextDay = toStartOfDay(addDays(1)(date));
    const endDate = !nextMeasurement ? startOfNextDay : nextMeasurement.date;

    const endsAfterNextDayStart = endDate > startOfNextDay;

    let measurementsToAdd = [
      { ...measurement, date, endDate: isLastMeasurement ? date : endDate },
    ];

    if (endsAfterNextDayStart) {
      measurementsToAdd = addMidnightBasalMeasurements(
        measurement,
        date,
        endDate,
      );
    }

    return [...acc, ...measurementsToAdd];
  }, []);

export const getBasalVerticalPadding = (y1, previousBasal, yMax) =>
  previousBasal && previousBasal.basalCbrf > y1 ? 0.01 / yMax : 0;

export const getYAxisTickVisibilityTolerance = ({
  bloodGlucoseUnit,
  yAxisTickVisibilityToleranceMap = Y_AXIS_TICK_VISIBILITY_TOLERANCE,
}) => yAxisTickVisibilityToleranceMap[bloodGlucoseUnit];

export const isTbrIncrease = ({ basalTbrinc, basalTbrdec }) =>
  !!basalTbrinc && !basalTbrdec;

export const isTbrDecrease = ({ basalTbrinc, basalTbrdec }) =>
  !basalTbrinc && !!basalTbrdec;

export const transformBasal = ({ date, basalCbrf, endDate, ...basalInfo }) => ({
  date: date,
  basalCbrf: basalCbrf,
  endDate: endDate,
  tbr: isTbrIncrease(basalInfo) || isTbrDecrease(basalInfo),
});

export const getDimensionsMapper =
  (totalTime, startDate, graphYMax, measurement) =>
  ({
    color,
    insulinType,
    bolusValue,
    getInsulinDimensionsFn,
    bolusRemark = '', // only bolus insulin has bolusRemark
  }) => ({
    ...measurement,
    color,
    insulinType,
    ...getInsulinDimensionsFn(
      {
        date: measurement.date,
        bolusValue,
        bolusRemark: bolusRemark || '',
        overlappingBolusWithGlucose: measurement.overlappingBolusWithGlucose,
      },
      totalTime,
      startDate,
      graphYMax,
    ),
  });

export const sortInsulinBarsDescending = (value1, value2) =>
  value2.lineHeight - value1.lineHeight;

export const barsWithInvalidLineHeight = (value) => {
  const { lineHeight, rectHeight } = value;

  const isExtendedBolus = lineHeight === undefined;
  const hasValidInsulinValue = !isNaN(lineHeight);
  const hasNonZeroInsulinValue = lineHeight !== 0;
  const isOverlappingMultiwaveBolus =
    lineHeight === 0 && rectHeight !== undefined;

  return (
    isExtendedBolus ||
    (hasValidInsulinValue &&
      (hasNonZeroInsulinValue || isOverlappingMultiwaveBolus))
  );
};

/**
 * Quick, Standard and Multiwave sorted based on lineHeight
 * Extended are sorted to the start of the array so they stack
 * below bars (see the W3 specs for SVG Rendering Order)
 * http://www.w3.org/TR/SVG/render.html#RenderingOrder
 */
export const sortAllInsulinBarsDescending = (measurementA, measurementB) => {
  const { lineHeight: lineHeightA } = measurementA;
  const { lineHeight: lineHeightB } = measurementB;

  const isAExtendedBolus = !lineHeightA;
  const isBExtendedBolus = !lineHeightB;

  if (isAExtendedBolus) {
    return -1;
  } else if (isBExtendedBolus) {
    return 1;
  }

  return lineHeightB - lineHeightA;
};
