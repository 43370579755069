// @ts-nocheck
import * as React from 'react';

import {
  PumpTbrdecIcon,
  PumpTbrincIcon,
  InsulinToggleIcon,
  BasalToggleIcon,
  PumpStopIcon,
  PumpTbrEndDecIcon,
  PumpTbrEndIncIcon,
  PumpRunIcon,
  PumpPauseIcon,
} from 'src/assets/icons';
import { withTranslation } from 'src/utils/i18n/with-translation';

import { LegendItem } from './legend-item.component';

import {
  LegendListsContainer,
  LegendListSectionHeader,
  LegendLineData,
  LegendColumnData,
  LegendColumn,
  CgmLegendList,
  Bagde,
} from '../graph-legend.style';

const targets = [
  { color: 'orange', target: 'veryHigh' },
  { color: 'yellow', target: 'high' },
  { color: 'green', target: 'inRange' },
  { color: 'red', target: 'low' },
  { color: 'darkRed', target: 'veryLow' },
];

const i = [
  { icon: <InsulinToggleIcon />, label: 'insulinAll' },
  { icon: <BasalToggleIcon />, label: 'basalRate' },
  { icon: <PumpStopIcon width={12} height={undefined} />, label: 'stop' },
  { icon: <PumpRunIcon width={12} height={undefined} />, label: 'run' },
  { icon: <PumpPauseIcon width={12} height={undefined} />, label: 'pause' },
  {
    icon: <PumpTbrincIcon width={12} height={undefined} />,
    label: 'tbrIncrease',
  },
  {
    icon: <PumpTbrdecIcon width={12} height={undefined} />,
    label: 'tbrDecrease',
  },
  {
    icon: <PumpTbrEndIncIcon width={12} height={undefined} />,
    label: 'tbrIncreaseEnd',
  },
  {
    icon: <PumpTbrEndDecIcon width={12} height={undefined} />,
    label: 'tbrDecreaseEnd',
  },
];

const pref1 = 'cgmDashboard.dailyGlucoseProfile.legend';
const pref2 = 'graphDetails.legend';

const drawLineData = (t: any, color: string, target: string) => (
  <LegendLineData className={target}>
    <Bagde className={`badge ${color}`}>{t(`${pref1}.${color}`)}</Bagde>
    <jello-text size="XS" class={`label ${target}`}>
      {t(`${pref1}.${target}`)}
    </jello-text>
  </LegendLineData>
);

const drawIcon = ({ t, icon, label }) => (
  <LegendItem icon={icon} label={t(`${pref2}.${label}`)} color={undefined} />
);

export const CgmDailyGraphLegend = withTranslation(({ t }) => (
  <LegendListsContainer>
    <CgmLegendList className="targets">
      <LegendListSectionHeader>
        {t(`${pref1}.glucoseValues`)}
      </LegendListSectionHeader>
      {targets.map((target) => drawLineData(t, target.color, target.target))}
    </CgmLegendList>

    <CgmLegendList className="bolus">
      <LegendListSectionHeader>{t(`${pref1}.bolus`)}</LegendListSectionHeader>
      {drawIcon({ t, ...i[0] })}
    </CgmLegendList>

    <CgmLegendList className="basal">
      <LegendListSectionHeader>
        {t(`${pref2}.basalRate`)}
      </LegendListSectionHeader>
      <LegendColumn>
        <LegendColumnData>
          {i.slice(1, 5).map(({ icon, label }) => drawIcon({ t, icon, label }))}
        </LegendColumnData>
        <LegendColumnData>
          {i.slice(5, 9).map(({ icon, label }) => drawIcon({ t, icon, label }))}
        </LegendColumnData>
      </LegendColumn>
    </CgmLegendList>
  </LegendListsContainer>
));
