import { colors } from 'src/core/styles/colors';
import { spacing } from 'src/core/styles/spacing';
import { transitions } from 'src/core/styles/transitions';
import { createStyledComponent } from 'src/utils/styles';

export const MenuDropdownContainer = createStyledComponent<'div', {}>('div')`
  position: relative;
`;
MenuDropdownContainer.displayName = 'MenuDropdownContainer';

export const MenuDropdownAvatarContainer = createStyledComponent<'div', {}>(
  'div',
)`
  cursor: pointer;
  position: relative;
  margin-bottom: ${spacing.three};
  display: flex;
  align-items: center;
`;
MenuDropdownAvatarContainer.displayName = 'MenuDropdownAvatarContainer';

export const IconContainer = createStyledComponent<'div', {}>('div')`
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 2px solid ${colors.brandBlueDark};
  border-radius: 50%;
  position relative;
  transition: ${transitions.default};

  &:hover {
    border-color: ${colors.brandBlue};
  }

  span {
    position absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
IconContainer.displayName = 'IconContainer';
