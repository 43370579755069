export const mockSaveTargetRanges = {
  resultDescription: 'SaveThresholdOK',
  model: {
    unit: 'MGDL',
    preHyper: '240',
    preHigh: '125',
    preLow: '80',
    preHipo: '80',
    postHyper: '240',
    postHigh: '95',
    postLow: '80',
    postHipo: '80',
    noctHyper: '240',
    noctHigh: '95',
    noctLow: '80',
    noctHipo: '80',
    nonMealRelatedHyper: '240',
    nonMealRelatedHigh: '95',
    nonMealRelatedLow: '80',
    nonMealRelatedHipo: '80',
  },
};

export const payloadSaveTargetRanges = {
  unit: 'MGDL',
  beforeEatingValid: true,
  afterEatingValid: true,
  beforeBedValid: true,
  beforeEating: {
    actualHyper: '240',
    hyper: '125',
    hypo: '80',
    warning: '80',
  },
  afterEating: {
    actualHyper: '240',
    hyper: '95',
    hypo: '80',
    warning: '80',
  },
  beforeBed: {
    actualHyper: '240',
    hyper: '95',
    hypo: '80',
    warning: '80',
  },
  nonMealRelated: {
    actualHyper: '240',
    hyper: '95',
    hypo: '80',
    warning: '80',
  },
};

export const payloadSaveTargetRangesTransformed = {
  unit: 'MGDL',
  preHyper: 240,
  preHigh: 125,
  preHipo: 80,
  preLow: 80,
  postHyper: 240,
  postHigh: 95,
  postHipo: 80,
  postLow: 80,
  noctHyper: 240,
  noctHigh: 95,
  noctLow: 80,
  noctHipo: 80,
  nonMealHyper: 240,
  nonMealHigh: 95,
  nonMealLow: 80,
  nonMealHipo: 80,
};
