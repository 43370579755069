import {
  RemoveDeviceServiceImpl,
  RemoveDeviceLoaderImpl,
} from './remove-device.service';

const mockLoader = (): Promise<any> =>
  Promise.resolve({
    resultDescription: 'deactivateDeviceAssociationOk',
    model: null,
  });

export const RemoveDeviceFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : RemoveDeviceLoaderImpl;
  return RemoveDeviceServiceImpl(loader);
};
