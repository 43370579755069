import { standardDeviation } from 'src/utils/stat';
import {
  BLOOD_GLUCOSE_UNITS,
  EMPTY_VALUE_PLACEHOLDER,
} from 'src/domains/diagnostics/store/constants';

export const getFormattedStandardDeviation = (
  data = [],
  transform = (value) => value,
  bloodGlucoseUnit = BLOOD_GLUCOSE_UNITS.MG_PER_DL,
) => {
  if (data.length <= 1) {
    return EMPTY_VALUE_PLACEHOLDER;
  }
  if (bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L) {
    return standardDeviation(data);
  }
  return transform(standardDeviation(data));
};
