import { periodTranslationKeyBase } from './prescription.constants';

export const quantityToDropdownOption = (quantity) => ({
  value: quantity,
  label: quantity,
});

export const periodToDropdownOption = (period) => ({
  value: period,
  label: `${periodTranslationKeyBase}${period}`,
});

export const reasonToDropdownOption = ({ id, description }) => ({
  value: id,
  label: description,
});
