export const mockSaveClinicGuideResponse = {
  id: '4bb8d615-2230-4fa9-af7b-e52bf67914f4',
  maximumStrips: 15,
  minimumStrips: 0,
  name: 'Ben created custom guide 1',
  period: 'days',
  therapyId: '1efbc371-50b6-4c19-8b16-70f9c0ef3e90',
};

export const mockSaveClinicGuidePayload = {
  maximumStrips: 15,
  minimumStrips: 0,
  name: 'Ben created custom guide 1',
  period: 'days',
  therapyId: '1efbc371-50b6-4c19-8b16-70f9c0ef3e90',
};
