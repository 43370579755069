// tslint:disable no-commented-code
// @ts-nocheck
import * as React from 'react';
import {
  CalendarContainer,
  ButtonContainer,
  DatesContainer,
  IconContainer,
  DatesWrapper,
  PickerContainer,
  CalendarIconContainer,
  DoneButtonWrapper,
  DoneButton,
  NavButtonContainer,
} from './calendar.style';
import {
  V3CalendarIcon,
  V3ForwardIcon,
  V3BackIcon,
  ArrowIcon,
} from 'src/assets/icons';
import { CalendarProps, CalendarState } from './calendar.types';
import { DateRangePicker } from 'react-dates';
import { withTranslation as translateHOC } from 'react-i18next';
import { getClassName, noMeasurements, getDateFormat } from './calendar.utils';
import { t } from 'i18next';
import * as C from './calendar.constants';
import { throttle } from 'lodash';
import { breakpointsMap } from 'src/core/styles/breakpoints';

export class CalendarClass extends React.Component<
  CalendarProps,
  CalendarState
> {
  public moment = require('moment');
  public state = {
    range: 14,
    originalStartDate: this.moment(),
    originalEndDate: this.moment(),
    wrapWidth: window.outerWidth,
    startDate: this.moment(),
    endDate: this.moment(),
    minDate: this.moment(),
    maxDate: this.moment(),
    focusedInput: null,
  };

  private onResize = throttle(() => {
    this.setState({
      wrapWidth: window.outerWidth,
    });
  }, 300);

  public constructor(props) {
    super(props);
    this.moment.updateLocale(this.moment.locale(props.locale), {
      invalidDate: C.NO_DATA,
    });
  }

  public componentWillReceiveProps(nextProps) {
    const nDateInfo = nextProps.dateInfo;
    const oDateInfo = this.props.dateInfo;
    const nDisabled = nextProps.disabled;
    const oDisabled = this.props.disabled;
    if (
      JSON.stringify(nDateInfo) !== JSON.stringify(oDateInfo) ||
      nDisabled !== oDisabled
    ) {
      this.initState(nextProps);
    }
  }
  public componentDidMount() {
    this.initState(this.props);
    window.addEventListener('resize', this.onResize);
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  public render() {
    const { startDate, endDate, focusedInput } = this.state;
    const {
      wrapWidth,
      dateInfo,
      disabled,
      showArrows = true,
      className,
      id,
    } = this.props;

    const drawButton = (way) => {
      if (`${disabled}` !== 'true') {
        return (
          <ButtonContainer className={way}>
            <IconContainer>
              {way === C.PREV && <V3BackIcon height={C.BTN_HEIGHT} />}
              {way === C.NEXT && <V3ForwardIcon height={C.BTN_HEIGHT} />}
            </IconContainer>
          </ButtonContainer>
        );
      }
      return;
    };

    const drawNavButton = (way) => {
      return (
        <NavButtonContainer className={`navBtn${way}`}>
          <ArrowIcon height={8} />
        </NavButtonContainer>
      );
    };

    const renderPresets = (t, handleClick) => {
      return (
        <React.Fragment>
          <DoneButtonWrapper>
            <DoneButton onClick={handleClick}>
              {t('datePicker.done')}
            </DoneButton>
          </DoneButtonWrapper>
        </React.Fragment>
      );
    };

    const breakPoint = breakpointsMap[1];

    return (
      <CalendarContainer className={className}>
        {showArrows ? drawButton(C.PREV) : ''}
        <DatesContainer>
          <DatesWrapper>
            <CalendarIconContainer onClick={() => this.shoWCalendar()}>
              <V3CalendarIcon
                height={this.state.wrapWidth > breakPoint ? 24 : 22}
              />
            </CalendarIconContainer>
            <PickerContainer className={getClassName(wrapWidth)}>
              <DateRangePicker
                id={id}
                minimumNights={0}
                startDatePlaceholderText={C.NO_DATA}
                endDatePlaceholderText={C.NO_DATA}
                keepOpenOnDateSelect={true}
                horizontalMargin={C.HORIZONAL_MARGIN}
                daySize={C.DAY_SIZE}
                displayFormat={getDateFormat(wrapWidth)}
                readOnly={true}
                noBorder={true}
                navPrev={drawNavButton(C.PREV)}
                navNext={drawNavButton(C.NEXT)}
                disabled={disabled}
                startDate={this.getDate(disabled, startDate)}
                startDateId={C.START_DATE_ID}
                endDate={this.getDate(disabled, endDate)}
                endDateId={C.END_DATE_ID}
                onDatesChange={({ startDate, endDate }) =>
                  this.setDateChanges({ startDate, endDate })
                }
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) =>
                  this.handleFocusChange(focusedInput)
                }
                renderCalendarInfo={() =>
                  renderPresets(t, () => {
                    this.handlerChangeDates();
                  })
                }
                isOutsideRange={(d) =>
                  noMeasurements(dateInfo.minDate, dateInfo.maxDate, d)
                }
              />
            </PickerContainer>
          </DatesWrapper>
        </DatesContainer>
        {showArrows ? drawButton(C.NEXT) : ''}
      </CalendarContainer>
    );
  }
  private initState = (nextProps) => {
    if (`${nextProps.disabled}` === 'true') {
      this.setState({
        startDate: null,
        endDate: null,
      });
      return;
    }
    const { dateInfo } = nextProps;
    const { minDate, maxDate, startDate, endDate } = dateInfo;

    const getDate = (d) => (typeof d === 'string' ? this.moment(d) : d);
    const sDate = getDate(startDate);
    const eDate = getDate(endDate);
    const minDateRange = this.moment(minDate);
    const maxDateRange = this.moment(maxDate);

    this.setState({
      originalStartDate: sDate,
      originalEndDate: eDate,
      startDate: sDate,
      endDate: eDate,
      minDate: minDateRange,
      maxDate: maxDateRange,
      focusedInput: focus,
    });
  };

  private getDate = (disabled, date) => {
    return `${disabled}` === 'true' ? this.moment('--') : date;
  };

  private shoWCalendar = () => {
    const { disabled } = this.props;
    if (`${disabled}` !== 'true') {
      this.setState({ focusedInput: C.START_DATE_ID });
      if (this.props.onShowCalendar) {
        this.props.onShowCalendar();
      }
    }
  };

  private handleFocusChange = (focusedInput) => {
    const { disabled } = this.props;
    if (
      [C.START_DATE_ID, C.END_DATE_ID].indexOf(focusedInput) > -1 &&
      `${disabled}` !== 'true'
    ) {
      this.setState({ focusedInput });
      if (this.props.onFocusChange) {
        this.props.onFocusChange(focusedInput);
      }
      if (this.props.onShowCalendar) {
        this.props.onShowCalendar();
      }
    }
  };

  private handlerHideCalendar = () => {
    if (this.props.onHideCalendar) {
      this.props.onHideCalendar();
    }
    setTimeout(() => {
      this.setState({
        focusedInput: null,
      });
    }, 0);
  };

  private setDateChanges = ({ startDate, endDate }) => {
    const sDate = startDate;
    const eDate = endDate || sDate.clone();
    this.setState({
      startDate: sDate,
      endDate: eDate,
    });
    if (this.props.onDatesChange) {
      const range = eDate.diff(sDate, 'days') + 1;
      this.props.onDatesChange(startDate, endDate, range);
    }
  };

  private handlerChangeDates = () => {
    const { startDate, endDate } = this.state;
    if (this.props.onDatesChangeSubmit) {
      const range = endDate.diff(startDate, 'days') + 1;
      this.props.onDatesChangeSubmit(startDate, endDate, range);
    }
    this.handlerHideCalendar();
  };
}

export const Calendar = translateHOC('translations')(CalendarClass);
