import React from 'react';

export const RectangleSeries = ({ points, Shape, width, height }) => (
  <React.Fragment>
    {points
      .sort((a, b) => a.x - b.x)
      .map((point) => ({
        ...point,
        x: point.x * width,
        rectWidth: point.rectWidth ? point.rectWidth * width : null,
        rectHeight: point.rectHeight ? point.rectHeight * height : null,
        lineHeight: point.lineHeight ? point.lineHeight * height : null,
      }))
      .map(Shape)}
  </React.Fragment>
);
