import { Action, PayloadAction } from 'src/app/store/app.types';
import { checkPairingCodeTransformedResponseType } from 'src/services/pairing-code/pairing-code-check/pairing-code-check.types';

export enum ConnectSharingCodeActionType {
  CONNECT_SHARING_CODE_START = 'CONNECT_SHARING_CODE_START',
  CONNECT_SHARING_CODE_SUCCESS = 'CONNECT_SHARING_CODE_SUCCESS',
  CONNECT_SHARING_CODE_ERROR = 'CONNECT_SHARING_CODE_ERROR',
  CHECK_SHARING_AVAILABLE_CODE_START = 'CHECK_SHARING_AVAILABLE_CODE_START',
  CHECK_SHARING_AVAILABLE_CODE_SUCCESS = 'CHECK_SHARING_AVAILABLE_CODE_SUCCESS',
  CHECK_SHARING_AVAILABLE_CODE_ERROR = 'CHECK_SHARING_AVAILABLE_CODE_ERROR',
  CLEAR_SHARING_CODE_DATA = 'CLEAR_SHARING_CODE_DATA',
}

export type ConnectSharingCodeError = {
  errorCode: string;
  status: number;
  statusText: string;
  url: string;
};

export type CheckSharingCodeAvailableResponse = string | number;

export type ConnectSharingCodeSuccessPayload =
  checkPairingCodeTransformedResponseType;

export type ConnectSharingCodeStartAction = PayloadAction<
  ConnectSharingCodeActionType.CONNECT_SHARING_CODE_START,
  string
>;

export type ConnectSharingCodeSuccessAction = PayloadAction<
  ConnectSharingCodeActionType.CONNECT_SHARING_CODE_SUCCESS,
  checkPairingCodeTransformedResponseType
>;

export type ConnectSharingCodeErrorAction = PayloadAction<
  ConnectSharingCodeActionType.CONNECT_SHARING_CODE_ERROR,
  ConnectSharingCodeError
>;

export type CheckSharingCodeAvailableStartAction =
  Action<ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_START>;

export type CheckSharingCodeAvailableSuccessAction = PayloadAction<
  ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_SUCCESS,
  CheckSharingCodeAvailableResponse
>;
export type CheckSharingCodeAvailableErrorAction = PayloadAction<
  ConnectSharingCodeActionType.CHECK_SHARING_AVAILABLE_CODE_ERROR,
  CheckSharingCodeAvailableResponse
>;

export type ClearsharingCodeDataAction =
  Action<ConnectSharingCodeActionType.CLEAR_SHARING_CODE_DATA>;

export type ConnectSharingCodeActions =
  | ConnectSharingCodeStartAction
  | ConnectSharingCodeSuccessAction
  | ConnectSharingCodeErrorAction
  | CheckSharingCodeAvailableStartAction
  | CheckSharingCodeAvailableSuccessAction
  | CheckSharingCodeAvailableErrorAction
  | ClearsharingCodeDataAction;

export type ConnectSharingCodeState = {
  isCheckingSharingCode: boolean;
  sharingCodeSuccessful: boolean;
  sharingCodeHasError: boolean;
  sharingCodeStatus: ConnectSharingCodeError | null;
  isAvailableStatus: number | null;
  patientData: checkPairingCodeTransformedResponseType | {};
};
