import * as React from 'react';

import {
  RadioButtonIndicatorSpan,
  RadioButtonLabelSpan,
  RadioButtonLabelWrapper,
} from './input-radio.style';
import { InputRadioProps } from './input-radio.types';

export const InputRadio: React.FunctionComponent<InputRadioProps> = ({
  id,
  label,
  disabled,
  onClick,
  ...inputProps
}) => (
  <RadioButtonLabelWrapper htmlFor={id} disabled={disabled}>
    <input type="radio" id={id} onClick={onClick} {...inputProps} />
    <RadioButtonIndicatorSpan disabled={disabled} />
    {label && (
      <RadioButtonLabelSpan disabled={disabled}>{label}</RadioButtonLabelSpan>
    )}
  </RadioButtonLabelWrapper>
);
