import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

export const SearchIcon = ({ height = 12, fillColor = colors.white }) => {
  const originalWidth = 12;
  const originalHeight = 12;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <defs>
        <path
          d="M8.04557774,2.6457125 C6.55414275,1.15427751 4.13714749,1.15427751 2.6457125,2.6457125 C1.15427751,4.13714749 1.15427751,6.55414275 2.6457125,8.04557774 C4.13714749,9.53701273 6.55414275,9.53701273 8.04557774,8.04557774 C9.53701273,6.55414275 9.53701273,4.13714749 8.04557774,2.6457125 M12.0166284,12.0166284 C11.7180359,12.3144572 11.2346369,12.3144572 10.9360444,12.0166284 L8.53126778,9.6110881 C6.43730222,11.1765985 3.46817819,11.0284477 1.56589219,9.12539805 C-0.521964063,7.03830547 -0.521964063,3.65374844 1.56589219,1.56589219 C3.65298478,-0.521964063 7.03830547,-0.521964063 9.12539805,1.56589219 C11.0284477,3.46817819 11.1765985,6.43730222 9.6110881,8.53126778 L12.0166284,10.9368081 C12.3144572,11.2346369 12.3144572,11.7180359 12.0166284,12.0166284"
          id="path-1"
        ></path>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <use id="Search" fill="#FFFFFF" href="#path-1"></use>
      </g>
    </SvgIcon>
  );
};
