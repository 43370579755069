import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { ENDPOINTS } from 'src/services/service.constants';

import {
  ActiveSystemLanguages,
  EC6ActiveLanguage,
  EC6ActiveLanguagesResponse,
} from './active-system-languages.types';

export const GetActiveSystemLanguagesLoaderImpl = (openId: any) => {
  return getJSON(ENDPOINTS.activeSystemLanguages, {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
  });
};

export const GetActiveSystemLanguagesTransformImpl = ({
  model,
}: EC6ActiveLanguagesResponse): ActiveSystemLanguages => {
  return model.map((language: EC6ActiveLanguage) => {
    return {
      id: language.languageId,
      name: language.labelText,
      isoCode: language.isoCode,
      locale: language.locale,
    };
  });
};

export const GetActiveSystemLanguagesServiceImpl =
  (load, transform) => (openId: any) =>
    load(openId).then(transform);
