import * as React from 'react';
import { Block } from 'src/components/block/block.component';
import { ConcentricCircles } from 'src/components/concentric-circles/concentric-circles.component';
import { Column } from 'src/components/column/column.component';

import { DisclaimerIcon } from 'src/assets/icons';
import { spacing } from 'src/core/styles/spacing';
import { colors } from 'src/core/styles/colors';
import { combineRems } from 'src/utils/rem-calc';
import { Button } from 'src/components/button/button.component';
import { LocalizedText } from 'src/components/localized-text/localized-text.component';

import {
  ContainerDiv,
  LoginSystemErrorContentDiv,
  LoginSystemErrorMessage,
  LoginSystemErrorSubheading,
} from './login-system-error.styles';

import { ButtonContainerDiv, CommonHeader, ModalBody } from '../../modal.style';

export const LoginSystemErrorModalComponent = ({
  destroyModal,
  retryLogin,
  t,
}) => (
  <ModalBody>
    <CommonHeader>
      <strong>
        <LocalizedText fontSize={4} textKey={'systemLoginErrorModal.heading'} />
      </strong>
    </CommonHeader>
    <Column
      alignItems="center"
      flex="1 1 auto"
      pt={combineRems(spacing.four, spacing.three)}
      pl={4}
      pr={4}
    >
      <ConcentricCircles
        height={70}
        innerCircleColor={colors.trafficRed}
        borderOpacity={0.3}
        borderThickness={6}
      >
        <DisclaimerIcon
          iconColor={colors.white}
          borderColor
          height={56.25}
          width={56.25}
          minX={0.1}
          style={{ verticalAlign: 'bottom' }}
        />
      </ConcentricCircles>
      <LoginSystemErrorSubheading>
        {t('systemLoginErrorModal.errorMessage')}
      </LoginSystemErrorSubheading>
    </Column>
    <ContainerDiv>
      <LoginSystemErrorContentDiv>
        <LoginSystemErrorMessage>
          {t('systemLoginErrorModal.body3')}
        </LoginSystemErrorMessage>
      </LoginSystemErrorContentDiv>
      <Block textAlign="center">
        <ButtonContainerDiv>
          <Button
            label={t('systemLoginErrorModal.retry')}
            onClick={retryLogin}
            minWidth="7.75rem"
          />
        </ButtonContainerDiv>
      </Block>
    </ContainerDiv>
  </ModalBody>
);
