import { DateTime } from 'luxon';
import { isEmpty, isNil } from 'ramda';
import { DiabetesType, TherapyType } from 'src/services/patient/patient.types';
import {
  DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION,
  DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT,
  THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION,
  THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT,
  DEFAULT_NULL_VALUE_STRING,
} from './patient-data-bar.constants';

export const formatDoB = (date) => {
  if (!date) return '-';
  const luxon = DateTime.fromISO(date);
  const format = luxon.toFormat('dd LLL yyyy');
  const age = Math.floor(Math.abs(luxon.diffNow('years').years));
  return `${format} (${age})`;
};

export const formatDiagnosticsDate = (date) => {
  if (!date) return '-';
  const luxon = DateTime.fromISO(date);
  const format = luxon.toFormat('dd LLL yyyy');
  return `${format}`;
};

export const deviceListContainsActivedDevice = (deviceTypes, activeDevices) => {
  return deviceTypes.some((type) => activeDevices?.includes(type));
};

export const hasPatientCGMAssociation = ({ lastMeasurementDate }) => {
  return !isEmpty(lastMeasurementDate) && !isNil(lastMeasurementDate);
};

const getTranslatedValue = (
  type: TherapyType | DiabetesType | null,
  translationMap: { [key: string]: string },
) =>
  translationMap[type as TherapyType | DiabetesType] ||
  DEFAULT_NULL_VALUE_STRING;

export const getShortTranslatedTherapyType = (
  therapyType: TherapyType | null,
) =>
  getTranslatedValue(
    therapyType,
    THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT,
  );

export const getTranslatedTherapyType = (therapyType: TherapyType | null) =>
  getTranslatedValue(therapyType, THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION);

export const getTranslatedDiabetesType = (diabetesType: DiabetesType | null) =>
  getTranslatedValue(diabetesType, DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION);

export const getShortTranslatedDiabetesType = (
  diabetesType: DiabetesType | null,
) =>
  getTranslatedValue(
    diabetesType,
    DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT,
  );
