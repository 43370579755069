import * as React from 'react';
import styled from 'styled-components';

type SvgIconProps = {
  children?: React.ReactNode;
  fill?: string;
  fillOpacity?: string;
  height?: number | string;
  innerColor?: string;
  minX?: number;
  minY?: number;
  originalHeight?: number;
  originalWidth?: number;
  style?: any;
  strokeColor?: any;
  title?: string;
  titleNotToBeRendered?: boolean;
  width?: number | string;
  x?: string;
  y?: string;
  radius?: string | number;
  viewBox?: string;
  fontSize?: string;
  fontWeight?: string;
};

const SvgIconWrapper = styled.span`
  display: inline-flex;
`;

const SvgIconBlockWrapper = styled.div``;

export const GraphSvgIcon: React.FunctionComponent<SvgIconProps> = ({
  children,
  fill,
  fillOpacity,
  height,
  innerColor,
  minX = 0,
  minY = 0,
  originalHeight,
  originalWidth,
  style,
  strokeColor,
  title = '',
  titleNotToBeRendered = false,
  width,
  radius,
  x,
  y,
  viewBox,
  fontSize,
  fontWeight,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} ${originalWidth} ${originalHeight}` || viewBox}
    x={x}
    y={y}
    stroke={strokeColor}
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    fill={fill}
    fillOpacity={fillOpacity}
    radius={radius}
    fontSize={fontSize}
    fontWeight={fontWeight}
  >
    {title && !titleNotToBeRendered && <title>{title}</title>}
    {children}
  </svg>
);

/**
 * @description SvgIcon was originally the current GraphSvgIcon.
 * The <span> was added as a workaround for tooltips not showing
 * in IE and Edge. However, please be careful - if you need to wrap
 * this Icon within an <svg> plot, use GraphSvgIcon instead (<span>
 * not allowed in <svg>). Graph Svgs do not need browser hover overs
 * because they have custom ones. Credit: Herman.
 */
export const SvgIcon: React.FunctionComponent<SvgIconProps> = ({
  title,
  ...props
}) => (
  <SvgIconWrapper title={title !== '' ? title : null}>
    <GraphSvgIcon {...props} title={title !== '' ? title : undefined} />
  </SvgIconWrapper>
);

export const SvgIconSpan: React.FunctionComponent<SvgIconProps> = (
  title,
  icon,
) => (
  <SvgIconWrapper title={title !== '' ? title : null}>{icon}</SvgIconWrapper>
);

export const SvgIconDiv: React.FunctionComponent<SvgIconProps> = ({
  title,
  ...props
}) => (
  <SvgIconBlockWrapper title={title !== '' ? title : null}>
    <GraphSvgIcon {...props} />
  </SvgIconBlockWrapper>
);
