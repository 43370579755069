import React, { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { UserActivityContext } from 'src/app/session/components/user-activity/user-activity.component';
import { JELLO_DIALOG_EVENTS } from 'src/components/jello-dialog/jello-dialog.constants';
import {
  EXPIRING_MODAL,
  EXPIRING_MODAL_CONTENT,
  COUNTDOWN,
} from 'src/app/session/constants';
import { testId } from '@roche/roche-common';
import { useInterval } from './utils';
import { useCustomEventListener } from 'src/hooks/custom-hooks';
import {
  JELLO_HEADINGS_SIZES,
  JELLO_HEADINGS_LEVELS,
  JELLO_HEADINGS_WEIGHTS,
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
} from 'src/app/app.jello.constants';
import style from './expiring-modal.module.css';
import { customDatadogLog } from 'src/app/logger/utils';
import { LOGS } from 'src/app/logger/constants';
import moment from 'moment';

export const ExpiringModalComponent = () => {
  const [timeRemaining, setTimeRemaining] = useState(COUNTDOWN);
  const { onStayAction, onSignOutAction, getLastActiveTime } = useContext(
    UserActivityContext,
  ) as FixMe;
  const ONE_SECOND_DELAY = 1000;

  /* istanbul ignore next */
  useCustomEventListener(JELLO_DIALOG_EVENTS.primaryBtn, () => {
    customDatadogLog('session', LOGS.stayBtn);
    onStayAction();
  });
  /* istanbul ignore next */
  useCustomEventListener(JELLO_DIALOG_EVENTS.secondaryBtn, () => {
    customDatadogLog('session', LOGS.signOutBtn);
    onSignOutAction();
  });

  useInterval(() => {
    const lastActive = getLastActiveTime ? getLastActiveTime() : '';
    const _timeRemaining = COUNTDOWN - moment().diff(moment(lastActive), 's');
    return setTimeRemaining(_timeRemaining < 0 ? 0 : _timeRemaining);
  }, ONE_SECOND_DELAY);

  useEffect(() => {
    if (timeRemaining < 1) {
      /* istanbul ignore next */
      customDatadogLog('session', LOGS.countdownEnds);

      /* istanbul ignore next */
      onSignOutAction();
    }
  }, [timeRemaining]);

  return (
    <div className={style.alignTextCenter}>
      <p className={style.colorBlue}>
        <jello-icon
          icon-name={JELLO_ICON_NAMES.TIMER}
          size={JELLO_ICON_SIZES.M}
        />
      </p>
      <jello-heading
        {...testId(EXPIRING_MODAL, EXPIRING_MODAL_CONTENT)}
        weight={JELLO_HEADINGS_WEIGHTS.BOLD}
        size={JELLO_HEADINGS_SIZES.L}
        level={JELLO_HEADINGS_LEVELS.H4}
      >
        {t('ttlExpiringModal.content', { time: timeRemaining })}
      </jello-heading>
    </div>
  );
};
