import React from 'react';
import { Bundle } from 'src/navigation/bundle';

export const CreateProfessionalBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { CreateProfessional } = await import(
        /* webpackChunkName: "createProfessional" */ './scenes/create-professional/create-professional.container'
      );
      return CreateProfessional;
    }}
    bundleDidLoad={(CreateProfessional) => <CreateProfessional {...props} />}
  />
);

export const DefaultGraphicSettingsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DefaultGraphicSettings } = await import(
        /* webpackChunkName: "defaultGraphicSettings" */ './scenes/default-graphic-settings/default-graphic-settings.component'
      );
      return DefaultGraphicSettings;
    }}
    bundleDidLoad={(DefaultGraphicSettings) => (
      <DefaultGraphicSettings {...props} />
    )}
  />
);
