import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { mapDispatchers } from 'src/utils/map-dispatchers';
import { createModal } from 'src/core/modal/modal.actions';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';
import { V3HelpIcon } from 'src/assets/icons';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';

import { HelpDropdownPopover } from './help-dropdown-popover/help-dropdown-popover.component';
import {
  HelpDropdownContainer,
  HelpDropdownAvatarContainer,
  IconContainer,
} from './help-dropdown.style';

import { selectIfusLang } from 'src/core/user/user.selectors';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { startSignoutAction } from 'src/core/login-system-error/login-system-error.action';
import { countlyEventTrigger } from 'src/navigation/countly';
import {
  HELP_DOCUMENTATION_EVENTS_KEYS,
  HELP_DOCUMENTATION_SEGMENT_KEYS,
  HELP_DOCUMENTATION_SEGMENT_VALUES,
} from './help-dropdown.countly.constants';
import { selectHasRebranding } from 'src/core/permissions/permissions.selectors';

type HelpDropdownState = {
  showPopover: boolean;
};

type HelpDropdownProps = {
  routes: FixMe;
  locale: any;
  graphInfo: any;
  onSignOut: () => void;
  showDtcModal: () => void;
  showManufacturerInfoModal: () => void;
  t: any;
  rebranding: boolean;
};

export class HelpDropdownComponent extends React.Component<
  HelpDropdownProps,
  HelpDropdownState
> {
  private wrapperRef: HTMLElement;
  private ref = React.createRef<HTMLElement>();

  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  public componentDidMount() {
    if (this.ref.current) {
      this.wrapperRef = this.ref.current;
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const {
      onSignOut,
      routes,
      showDtcModal,
      showManufacturerInfoModal,
      locale,
    } = this.props;

    const { showPopover } = this.state;

    return (
      <HelpDropdownContainer ref={this.ref}>
        <HelpDropdownAvatarContainer onClick={this.toggleModalDisplay}>
          <IconContainer>
            <V3HelpIcon
              fillColor={'#508EE5'}
              height={27}
              strokeColor={'#FFF'}
            />
          </IconContainer>
        </HelpDropdownAvatarContainer>
        <HelpDropdownPopover
          onSignOut={onSignOut}
          showDtcModal={showDtcModal}
          showManufacturerInfoModal={showManufacturerInfoModal}
          show={showPopover}
          routes={routes}
          closeMenu={this.closeMenu}
          locale={locale}
        />
      </HelpDropdownContainer>
    );
  }

  private closeMenu = () => {
    this.setState({ showPopover: false });
  };

  private handleClickOutside = (event) => {
    const { showPopover } = this.state;
    const { wrapperRef } = this;
    if (wrapperRef && !wrapperRef.contains(event.target) && showPopover) {
      this.closeMenu();
    }
  };

  private toggleModalDisplay = () => {
    this.setState({ showPopover: !this.state.showPopover });
  };
}

export const HelpDropdown = compose<HelpDropdownProps, {}>(
  withTranslation,
  connect(
    createStructuredSelector({
      routes: getCombinedRoutes,
      locale: selectIfusLang,
      rebranding: selectHasRebranding,
    }),
    mapDispatchers({
      onSignOut: startSignoutAction,
      showDtcModal: () =>
        createModal({
          key: MODAL_TYPES.DTC,
        }),
      showManufacturerInfoModal: () => {
        countlyEventTrigger(
          HELP_DOCUMENTATION_EVENTS_KEYS.HELP_DOCUMENTATION_OPENED,
          {
            [HELP_DOCUMENTATION_SEGMENT_KEYS.HELP_OPTION]:
              HELP_DOCUMENTATION_SEGMENT_VALUES.PRODUCT_INFORMATION,
          },
        );
        return createModal({
          key: MODAL_TYPES.MANUFACTURER_INFO,
        });
      },
    }),
  ),
)(HelpDropdownComponent);
