export const DAY = 'day';
export const DAYS = 'days';
export const HOURS = 'hours';
export const PREV = 'prev';
export const NEXT = 'next';
export const START_DATE_ID = 'startDate';
export const END_DATE_ID = 'endDate';
export const BTN_HEIGHT = '24';
export const DEFAULT_PERIOD = 14;
export const DATE_FORMAT_LONG = 'ddd DD MMM';
export const DATE_FORMAT_SHORT = 'DD MMM';
export const FORMAT_BREACKPOINT = 1230;
export const PICKER_SMALL = 'small';
export const PICKER_LARGE = 'large';
export const HORIZONAL_MARGIN = 30;
export const DAY_SIZE = 50;
export const NO_DATA = '---';
