export const mockProfessionalsResponse = {
  resultDescription: 'getAssociatedProfessionalsByDepartmentOK',
  model: [
    {
      id: 26341,
      user: {
        id: 26337,
        name: 'Rangle',
        surname: 'Administrative  ',
        surname2: null,
        fullname: 'Rangle Administrative   ',
        inactivate: false,
        gender: null,
        phone: '+34650678779',
        birthday: '1963-04-11T00:00:00+0000',
        email: 'norubaj@web2mailco.com',
        loginId: 26339,
        language: 'English',
        languageId: 3,
        languageIsoCode: 'en',
        languageCountryIsoCode: 'GB',
        role: 'PROFESSIONAL',
        departmentProfileId: 6909,
        profile: 'ADMINISTRATIVE',
        countryId: 1,
        inactivateReason: null,
        inactivateComment: null,
        departmentId: 6906,
        centerId: 6901,
        areaId: 39,
        locked: false,
        lastAccess: 1524004751038,
        passwordReset: false,
        viewNotifications: false,
        countryIsoCode: 'es',
        departmentName: 'Endocrinology',
        centerName: 'Angel Hospital',
        accessEnum: null,
        profileId: 81,
        gigyaUid: 'acb7fb0dd42f482489f383ae58a9da4d',
        activeInGigya: null,
        hcpIsAccessible: true,
        fakeMail: false,
        headerWelcome: 'Rangle Administrative  ',
        languageIsoCodeComplete: 'en_GB',
        noPhone: false,
      },
      value: 'Administrative  , Rangle',
      key: '26341',
    },
    {
      id: 14301,
      user: {
        id: 14297,
        name: 'Rangle',
        surname: 'Administrative',
        surname2: null,
        fullname: 'Rangle Administrative ',
        inactivate: false,
        gender: null,
        phone: null,
        birthday: null,
        email: 'rangle.administrative@mail.com',
        loginId: 14299,
        language: 'Español',
        languageId: 1,
        languageIsoCode: 'es',
        languageCountryIsoCode: 'ES',
        role: 'PROFESSIONAL',
        departmentProfileId: 6908,
        profile: 'HCP_MASTER',
        countryId: 1,
        inactivateReason: null,
        inactivateComment: null,
        departmentId: 6906,
        centerId: 6901,
        areaId: 39,
        locked: false,
        lastAccess: null,
        passwordReset: true,
        viewNotifications: false,
        countryIsoCode: 'es',
        departmentName: 'Endocrinology',
        centerName: 'Angel Hospital',
        accessEnum: null,
        profileId: 71,
        gigyaUid: null,
        activeInGigya: null,
        hcpIsAccessible: true,
        fakeMail: false,
        headerWelcome: 'Rangle Administrative',
        languageIsoCodeComplete: 'es_ES',
        noPhone: false,
      },
      value: 'Administrative, Rangle',
      key: '14301',
    },
    {
      id: 29542,
      user: {
        id: 29539,
        name: 'Who',
        surname: 'ARE YOU DOCTOR ',
        surname2: null,
        fullname: 'Who ARE YOU DOCTOR  ',
        inactivate: false,
        gender: null,
        phone: null,
        birthday: '2018-04-18T00:00:00+0000',
        email: 'jason.santos@rangle.io',
        loginId: 29541,
        language: 'English',
        languageId: 3,
        languageIsoCode: 'en',
        languageCountryIsoCode: 'GB',
        role: 'PROFESSIONAL',
        departmentProfileId: 6908,
        profile: 'HCP_MASTER',
        countryId: 1,
        inactivateReason: null,
        inactivateComment: null,
        departmentId: 6906,
        centerId: 6901,
        areaId: 39,
        locked: false,
        lastAccess: null,
        passwordReset: true,
        viewNotifications: false,
        countryIsoCode: 'es',
        departmentName: 'Endocrinology',
        centerName: 'Angel Hospital',
        accessEnum: null,
        profileId: 71,
        gigyaUid: 'ba4e617522b1496191fefbe13ff17209',
        activeInGigya: null,
        hcpIsAccessible: true,
        fakeMail: false,
        headerWelcome: 'Who ARE YOU DOCTOR ',
        languageIsoCodeComplete: 'en_GB',
        noPhone: false,
      },
      value: 'ARE YOU DOCTOR , Who',
      key: '29542',
    },
  ],
};
