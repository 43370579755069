import { ExpiringModalActionTypes } from './expiring-modal.types';

import { translate } from 'src/i18n';

import { EXPIRING_MODAL } from '../../constants';

export const showExpiringModal = () => ({
  type: ExpiringModalActionTypes.SHOW_EXPIRING_MODAL,
  payload: {
    id: EXPIRING_MODAL,
    primaryButtonText: translate('ttlExpiringModal.button.stay'),
    secondaryButtonText: translate('ttlExpiringModal.button.signOut'),
  },
});
