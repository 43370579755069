export const LOGS = {
  pass: 'Pass: ',
  session: 'Session: ',
  errors: 'Errors: ',
  others: 'Others: ',
  tokenRefreshment: 'Refresh token.',
  tokenRefreshmentError: 'Error in token refreshment.',
  tokenError: 'Error in getting the token.',
  showExpiringModal: 'The user has been inactive: Show expiration modal.',
  tokenExpired: 'The token is expired: Redirect to login',
  thirtySecs: 'The user has tried to enter after 30 secs: Sign out',
  countdownEnds: 'The countdown ends in the expiration modal: Sign out.',
  signOutBtn: 'The user clicked the sign out button in the expiration modal.',
  stayBtn: 'The user clicked the stay button in the expiration modal.',
  login: 'Redirect from login form.',
  redirectLogin: 'Redirects to login form.',
  redirectNext: 'Redirect to next param.',
  redirectHome: 'Redirects to home.',
  errorLogging: 'Error in logs middleware.',
  gigyaValidationError: 'Something went wrong with the gigya validation.',
  signOutDropdown: 'Sign out made by the user in the profile dropdown.',
  signOutError: 'Sign out failed.',
  signOutSuccess: 'Sign out successful.',
  sessionGuardError: 'Session guard error.',
  sessionProfessionalGuardError: 'Professional guard error.',
  pageReload: 'Page reload.',
};
export const SESSION_ID = 'session_id';
