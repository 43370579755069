import { values } from 'ramda';

import { convertJSDateGMT, toFormat } from '../../../../../utils/date';

export const filterForHighestDailyTotalInsulinValues = (totals) => {
  const dailyTotal = totals.reduce((acc, measurement) => {
    const date = toFormat('D')(convertJSDateGMT(measurement.date));

    return {
      ...acc,
      [date]:
        !acc[date] || measurement.bolusValue > acc[date].bolusValue
          ? measurement
          : acc[date],
    };
  }, {});

  return values(dailyTotal);
};
