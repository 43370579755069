import { testId } from '@roche/roche-common';
import * as React from 'react';

import { BASE_ID } from './jello-tooltip.constants';

type JelloTooltipProps = {
  id: string;
  children: React.ReactNode;
  label: string;
  delay?: number;
  disabled?: boolean;
  position?: string;
  visible?: boolean;
  style?: object;
};

export const JelloTooltip: React.FunctionComponent<JelloTooltipProps> = (
  props,
) => {
  const { id, delay, disabled, label, position, visible, children, style } =
    props;

  return (
    <jello-tooltip
      id={id}
      delay={delay}
      disabled={disabled}
      label={label}
      position={position}
      visible={visible}
      style={style}
      {...testId(BASE_ID, id)}
    >
      {children}
    </jello-tooltip>
  );
};
