export const TIME_INTERVAL = {
  QUARTERLY_INTERVALS: 'QUARTERLY_INTERVALS',
  MONTHLY_INTERVALS: 'MONTHLY_INTERVALS',
  WEEKLY_INTERVALS: 'WEEKLY_INTERVALS',
};

export const PAGER_TYPE = {
  NEXT: 'NEXT',
  SUPER_NEXT: 'SUPER_NEXT',
  PREV: 'PREV',
  SUPER_PREV: 'SUPER_PREV',
};

export const TIME_FORMAT_24_HOURS = 'HH:mm';
