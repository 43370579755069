import React, { Fragment } from 'react';

import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { TrendSeries } from 'src/lib/graph-trend-series.component';
import { Candlestick } from 'src/domains/diagnostics/components/graph/candlestick.component';
import { GridLines } from 'src/domains/diagnostics/components/graph/grid-lines.component';
import { TargetRange } from 'src/domains/diagnostics/components/graph/target-range.component';
import { ThresholdLine } from 'src/domains/diagnostics/components/graph/threshold-line.component';
const GRAPH_Y_MAX = 400;
const GRAPH_Y_INTERVAL = 50;

export const TrendGraphPlot = ({
  height,
  width,
  yDirection,
  targetRange,
  threshold,
  horizontalDayTicks,
  points,
  showGridLines,
  onCandleStickMouseOver,
  onCandleStickMouseOut,
  graphYMax = GRAPH_Y_MAX,
}) => (
  <Fragment>
    <RenderIf validate={showGridLines}>
      <GridLines
        width={width}
        height={height}
        horizontalCount={graphYMax / GRAPH_Y_INTERVAL}
        verticalCount={horizontalDayTicks.length}
      />
    </RenderIf>
    <TargetRange
      width={width}
      height={height}
      min={targetRange.min}
      max={targetRange.max}
    />
    <ThresholdLine
      width={width}
      height={height}
      threshold={threshold}
      yDirection={yDirection}
    />
    <TrendSeries
      width={width}
      height={height}
      points={points}
      itemScale={0.6}
      candlestickShape={Candlestick}
      onMouseOver={onCandleStickMouseOver}
      onMouseOut={onCandleStickMouseOut}
      range={horizontalDayTicks.length}
    />
  </Fragment>
);
