import * as React from 'react';

import { GenerateTripleColumnIconsComponent } from '../icon-components.component';

import { colors } from 'src/core/styles/colors';

import {
  CircleMarkIcon,
  ConnectingLinesIcon,
  CrossMarkIcon,
  RectangleMarkIcon,
  HypoLimitIcon,
  MeanBloodGlucoseIcon,
} from 'src/assets/icons';

const trendGraphIconsTitle = 'manufacturerInfo.trendGraph.trendGraphIconsTitle';

const trendGraphsTags = [
  {
    objectComponent: (
      <CrossMarkIcon
        key={1}
        height={20}
        minX={-5}
        minY={-5}
        fillColor={colors.black}
      />
    ),
    nametag: 'manufacturerInfo.trendGraph.bloodGlucose',
  },
  {
    objectComponent: (
      <ConnectingLinesIcon key={2} height={20} minX={0} minY={-7} />
    ),
    nametag: 'manufacturerInfo.trendGraph.correctingLines',
  },
  {
    objectComponent: (
      <RectangleMarkIcon
        key={3}
        height={20}
        fillColor={colors.white}
        minX={-5}
        minY={-5}
        strokeColor={colors.green}
      />
    ),
    nametag: 'manufacturerInfo.trendGraph.targetRange',
  },
  {
    objectComponent: (
      <CrossMarkIcon
        key={4}
        height={20}
        minX={-5}
        minY={-5}
        fillColor={colors.red}
      />
    ),
    nametag: 'manufacturerInfo.trendGraph.hypoglycemia',
  },
  {
    objectComponent: (
      <RectangleMarkIcon
        key={5}
        height={20}
        fillColor={colors.charcoal}
        minX={-5}
        minY={-5}
        strokeColor={colors.charcoal}
      />
    ),
    nametag: 'manufacturerInfo.trendGraph.bgAfterMeal',
  },
  {
    objectComponent: (
      <CircleMarkIcon
        key={6}
        height={20}
        fillColor={colors.white}
        minX={-7}
        minY={-7}
        strokeColor={colors.red}
      />
    ),
    nametag: 'manufacturerInfo.trendGraph.hypoSymptoms',
  },
  {
    objectComponent: (
      <RectangleMarkIcon
        key={7}
        height={20}
        fillColor={colors.white}
        minX={-5}
        minY={-5}
        strokeColor={colors.charcoal}
      />
    ),
    nametag: 'manufacturerInfo.trendGraph.bgBeforeMeal',
  },
  {
    objectComponent: (
      <HypoLimitIcon
        key={8}
        height={20}
        fillColor={colors.red}
        minX={0}
        minY={-7}
      />
    ),
    nametag: 'manufacturerInfo.trendGraph.hypoLimit',
  },
  {
    objectComponent: (
      <MeanBloodGlucoseIcon
        key={9}
        height={20}
        fillColor={colors.black}
        minX={1}
        minY={-3}
      />
    ),
    nametag: 'manufacturerInfo.trendGraph.meanBloodGlucose',
  },
];

export const TrendGraphIconsComponent = () => (
  <GenerateTripleColumnIconsComponent
    generalIconTags={trendGraphsTags}
    generalIconsTitle={trendGraphIconsTitle}
  />
);
