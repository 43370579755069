import React, { Fragment } from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { VerticalAxis } from 'src/lib/graph-vertical-axis.component';
import { colors } from 'src/core/styles/colors';
import { strokeWidth } from 'src/core/styles/stroke-width';

import { AXIS_FONT_SIZE, TEXT_DELTA_Y, X_AXIS_RIGHT } from './axis.constants';

export const InsulinAxisComponent = ({
  y,
  width,
  height,
  ticks,
  yDirection = -1,
  t,
}) => (
  <Fragment>
    <VerticalAxis
      x={0}
      y={y * yDirection}
      height={height}
      Tick={(tick) => (
        <Fragment key={tick.value}>
          <line
            x1={0}
            x2={width * X_AXIS_RIGHT.TICK_END}
            y1={tick.value * yDirection}
            y2={tick.value * yDirection}
            strokeWidth={strokeWidth.one}
            stroke={tick.color ? tick.color : colors.silverDark}
          />
          <text
            x={width * X_AXIS_RIGHT.TEXT_OFFSET}
            y={tick.value * yDirection}
            textAnchor="start"
            alignmentBaseline="text-after-edge"
            fill={tick.color ? tick.color : colors.black}
            fontSize={AXIS_FONT_SIZE}
            dy={TEXT_DELTA_Y}
          >
            {tick.label}
          </text>
        </Fragment>
      )}
      ticks={ticks}
    />
    <text
      x={width * (1 - X_AXIS_RIGHT.TEXT_OFFSET)}
      y={height - height * X_AXIS_RIGHT.TEXT_OFFSET}
      textAnchor="middle"
      fontSize={AXIS_FONT_SIZE}
      transform={`rotate(90, ${width * (1 - X_AXIS_RIGHT.TEXT_OFFSET)}, ${
        height - height * X_AXIS_RIGHT.TEXT_OFFSET
      })`}
    >
      {`${t('graphs.logbookStats.bolus')} (U)`}
    </text>
  </Fragment>
);

export const InsulinAxis = withTranslation(InsulinAxisComponent);
