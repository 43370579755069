export const GRAPH_TYPE_KEYS = {
  GRAPH_TYPE_CHANGED: 'Graph Type Changed',
};

export const GRAPH_TYPE_SEGMENT_KEYS = {
  GRAPH_TYPE_SELECTED: 'New Graph Type',
};

export const GRAPH_TYPE_SEGMENT_VALUES = {
  SELECTED_GRAPH_TYPE_LINE: 'Line',
  SELECTED_GRAPH_TYPE_BOX: 'Box',
};

export const GRAPH_LOGBOOK_TYPE_KEYS = {
  GRAPH_LOGBOOK_TYPE_CHANGED: 'Logbook View Changed',
};

export const GRAPH_LOGBOOK_TYPE_SEGMENT_KEYS = {
  GRAPH_LOGBOOK_TYPE_SELECTED: 'New View',
};

export const GRAPH_TYPE_LOGBOOK_SEGMENT_VALUES = {
  SELECTED_LOGBOOK_TYPE_LOGBOOK: 'Logbook',
  SELECTED_LOGBOOK_TYPE_DAILY: 'Daily',
  SELECTED_LOGBOOK_TYPE_STATISTICS: 'Daily Statistics',
  SELECTED_LOGBOOK_TYPE_24H: '24 hr',
};

export const GRAPH_SEGMENT_VALUES = {
  SELECTED_TYPE_NONAME: 'No Name Type',
};
