import { PayloadAction } from '../../../../../../app/store/app.types';
import {
  PairingCodeIdentityStatusResponseType,
  PairingCodeIdentityStatusTransformedResponseType,
} from '../../../../../../services/pairing-code/pairing-code-identity-status/pairing-code-identity-status.types';

export enum PairingCodeIdentityStatusActionType {
  PAIRING_CODE_IDENTITY_STATUS_START = 'PAIRING_CODE_IDENTITY_STATUS_START',
  PAIRING_CODE_IDENTITY_STATUS_SUCCESS = 'PAIRING_CODE_IDENTITY_STATUS_SUCCESS',
  PAIRING_CODE_IDENTITY_STATUS_ERROR = 'PAIRING_CODE_IDENTITY_STATUS_ERROR',
}

export type PairingCodeIdentityStatusPayloadType =
  PairingCodeIdentityStatusResponseType;

export type PairingCodeIdentityStatusStartAction = PayloadAction<
  PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_START,
  string
>;

export type PairingCodeIdentityStatusSuccessAction = PayloadAction<
  PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_SUCCESS,
  PairingCodeIdentityStatusTransformedResponseType
>;

export type PairingCodeIdentityStatusErrorAction = PayloadAction<
  PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_ERROR,
  PairingCodeIdentityStatusError
>;

export type PairingCodeIdentityStatusError = {
  errorCode: string;
  status: number;
  statusText: string;
  url: string;
};

export type PairingCodeIdentityStatusActions =
  | PairingCodeIdentityStatusStartAction
  | PairingCodeIdentityStatusSuccessAction
  | PairingCodeIdentityStatusErrorAction;

export type ParingCodeIdentityStatusState = {
  pairingCodeIdentityStatusStart: boolean;
  pairingCodeIdentityStatusSuccess: boolean;
  pairingCodeIdentityStatusError: boolean;
  identityStatusResponse: PairingCodeIdentityStatusTransformedResponseType;
  error: PairingCodeIdentityStatusError | null;
};
