import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { addAlertAction } from 'src/core/alert/alert.actions';
import { ALERT_POSITION } from 'src/core/alert/alert.constans';
import { AlertType } from 'src/core/alert/alert.types';

import { SaveErrorModal } from 'src/widgets/modal/components/save-error-modal/save-error.modal';
import { createModal } from 'src/core/modal/modal.actions';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';

import {
  TARGET_RANGES_GET_REQUEST,
  TARGET_RANGES_SAVE_REQUEST,
  getTargetRanges,
  saveTargetRanges,
} from './target-ranges.actions';

import { TargetRangesErrorModal } from '../components/range-error-modal/range-error.modal';

export const getTargetRangesEpic =
  (getTargetRangesService) => (action$, state$) =>
    action$.ofType(TARGET_RANGES_GET_REQUEST.START).flatMap(({ payload }) => {
      const openId = {
        accessToken: selectAccessToken(state$.getState()),
        apiKey: selectGigyaToken(state$.getState()),
      };
      return Observable.fromPromise(getTargetRangesService(openId, payload))
        .switchMap((targetRanges) => [getTargetRanges.success(targetRanges)])
        .pipe(
          catchError((err) =>
            Observable.concat(
              Observable.of(getTargetRanges.error(err)),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: TargetRangesErrorModal,
                  },
                }),
              ),
            ),
          ),
        );
    });
export const saveTargetRangesEpic =
  (saveTargetRangesService) => (action$, state$) =>
    action$.ofType(TARGET_RANGES_SAVE_REQUEST.START).flatMap(({ payload }) => {
      const openId = {
        accessToken: selectAccessToken(state$.getState()),
        gigyaToken: selectGigyaToken(state$.getState()),
      };
      return Observable.fromPromise(
        saveTargetRangesService(openId, payload.range, payload.patientId),
      )
        .switchMap((targetRanges) => [
          saveTargetRanges.success(targetRanges),
          getTargetRanges.start(payload.patientId),
          addAlertAction({
            type: AlertType.SUCCESS,
            text: { [AlertType.SUCCESS]: 'alert.settings.success.message' },
            position: ALERT_POSITION.BOTTOM_RIGHT,
          }),
        ])
        .pipe(
          catchError(() =>
            Observable.concat(
              Observable.of(saveTargetRanges.error()),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: SaveErrorModal,
                    title: 'errorSettingsModal.header',
                    content: 'errorSettingsModal.targetRange.content',
                    textPrimaryBtn: 'errorSettingsModal.okBtn',
                  },
                }),
              ),
            ),
          ),
        );
    });
