import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { CaretButton } from 'src/components/caret-button/caret-button.component';
import { CARET_DIRECTION } from 'src/components/caret-button/caret-button.constant';
import { withLoader } from 'src/components/with-loader/with-loader.component';
import { hasValue } from 'src/utils/validation-helpers';
import { V3UserIcon } from 'src/assets/icons/v3-user-icon/v3-user-icon.component';
import { colors } from 'src/core/styles/colors';
import { selectEC6FullName } from 'src/core/user/user.selectors';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { getRouterPathname } from 'src/navigation';

import { ProfileDropdownPopover } from './profile-dropdown-popover/profile-dropdown-popover.component';
import {
  CaretDownContainer,
  ProfileDropdownContainer,
  ProfileDropdownAvatarAndNameContainer,
  ProfileNameContainer,
  IconContainer,
} from './profile-dropdown.style';
import { testId } from '@roche/roche-common';

type ProfileDropdownState = {
  showPopover: boolean;
  wrapWidth: number;
};

type ProfileDropdownProps = {
  fullName: string;
  currentPath: string;
  routes: FixMe;
  onSignOut: () => void;
};

const FullNameTextComponent = ({ children }) => <span>{children}</span>;
const validators = {
  fullName: hasValue,
};
const loaderProps = {
  flexibleHeight: true,
  infinite: true,
  size: 16,
};
const ProfileNameWithLoader = withLoader({
  loaderProps,
  validators,
})(FullNameTextComponent);

export class ProfileDropdownComponent extends React.Component<
  ProfileDropdownProps,
  ProfileDropdownState
> {
  private wrapperRef: HTMLElement;
  private ref = React.createRef<HTMLElement>();

  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
      wrapWidth: window.outerWidth,
    };
  }

  public componentDidMount() {
    if (this.ref.current) {
      this.wrapperRef = this.ref.current;
    }

    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.handleResize);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.handleResize);
  }

  public render() {
    const { fullName, routes, currentPath } = this.props;
    const { showPopover } = this.state;

    return (
      <ProfileDropdownContainer ref={this.ref}>
        <ProfileDropdownAvatarAndNameContainer
          onClick={this.toggleModalDisplay}
        >
          <ProfileNameContainer style={{ maxWidth: this.getMaxWidth() }}>
            <ProfileNameWithLoader fullName={fullName}>
              {fullName}
            </ProfileNameWithLoader>
          </ProfileNameContainer>
          <IconContainer>
            <V3UserIcon
              fillColor={colors.blue3}
              strokeColor={colors.brandBlue}
            />
          </IconContainer>
          <CaretDownContainer {...testId('profile-dropdown', 'caret')}>
            <CaretButton direction={CARET_DIRECTION.DOWN} />
          </CaretDownContainer>
        </ProfileDropdownAvatarAndNameContainer>
        <ProfileDropdownPopover
          show={showPopover}
          routes={routes}
          closeMenu={this.closeMenu}
          currentPath={currentPath}
        />
      </ProfileDropdownContainer>
    );
  }

  private getMaxWidth = () => {
    const { wrapWidth } = this.state;
    if (wrapWidth > 1220) {
      return '300px';
    } else if (wrapWidth < 950) {
      return '120px';
    } else {
      return wrapWidth / 8;
    }
  };

  private handleResize = () => {
    this.setState({ wrapWidth: window.outerWidth });
  };

  private closeMenu = () => {
    this.setState({ showPopover: false });
  };

  private handleClickOutside = (event) => {
    const { showPopover } = this.state;

    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      showPopover
    ) {
      this.closeMenu();
    }
  };

  private toggleModalDisplay = () => {
    this.setState({ showPopover: !this.state.showPopover });
  };
}

export const ProfileDropdown = compose<ProfileDropdownProps, {}>(
  connect(
    createStructuredSelector({
      fullName: selectEC6FullName,
      routes: getCombinedRoutes,
      currentPath: getRouterPathname,
    }),
  ),
)(ProfileDropdownComponent);
