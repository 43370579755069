import React from 'react';
import { equals } from 'ramda';

import { withLoaderErrorOnTimeout } from 'src/components/with-error/with-error-message.component';
import { NoDataDisclaimer } from 'src/domains/diagnostics/components/no-data-disclaimer/no-data-disclaimer.component';
import { LocalizedText } from 'src/components/localized-text/localized-text.component';

const loaderOptions = (loadingMessage) => ({
  loaderProps: {
    flexibleHeight: true,
    infinite: true,
    minHeight: 'inherit',
    text: (
      <LocalizedText
        textKey={loadingMessage ? loadingMessage : 'graphs.loading'}
      />
    ),
  },
  validators: {
    isLoading: equals(false),
  },
});

const createErrorOptions = (options) => ({
  ErrorComponent: () => (
    <NoDataDisclaimer
      size={options.size}
      message={<LocalizedText textKey="graphs.noAvailableData" />}
    />
  ),
});

export const withGraphLoader = (Component, loadingMessage, options = {}) =>
  withLoaderErrorOnTimeout({
    errorOptions: createErrorOptions(options),
    loaderOptions: loaderOptions(loadingMessage),
  })(Component);
