import {
  DEPRECATED_ACTION_ICON_SEND_ALT_FILLED,
  DEPRECATED_ACTION_ICON_NOTIFICATION_FILLED,
  DEPRECATED_ACTION_ICON_CHAT,
  ACTION_ICON_ARROW_DOWN,
  ACTION_ICON_ARROW_LEFT,
  ACTION_ICON_ARROW_UP,
  ACTION_ICON_HOME,
  ACTION_ICON_VIDEO,
  ACTION_ICON_LOCKED,
  ACTION_ICON_CHECKMARK,
  ACTION_ICON_SWITCHER,
  ACTION_ICON_LAUNCH,
  ACTION_ICON_SETTINGS,
  ACTION_ICON_CHEVRON_DOWN,
  ACTION_ICON_CHEVRON_LEFT,
  ACTION_ICON_CHEVRON_RIGHT,
  ACTION_ICON_CLOSE,
  ACTION_ICON_CLOSE_FILLED,
  ACTION_ICON_CHECKMARK_FILLED,
  ACTION_ICON_CLOUD_UPLOAD,
  ACTION_ICON_SEARCH,
  ACTION_ICON_MAXIMIZE,
  ACTION_ICON_MINIMIZE,
  ACTION_ICON_NOTIFICATION,
  ACTION_ICON_PAGE_FIRST,
  ACTION_ICON_PAGE_LAST,
  ACTION_ICON_SUBTRACT,
  ACTION_ICON_VIDEO_OFF_FILLED,
} from '@rdcs/jello-icons/esm/action';

import {
  CALENDAR_ICON_TIMER,
  CALENDAR_ICON_CALENDAR,
  CALENDAR_ICON_TIME,
} from '@rdcs/jello-icons/esm/calendar';

import {
  COMMERCE_ICON_INVENTORY_MANAGEMENT,
  COMMERCE_ICON_BUILDING,
} from '@rdcs/jello-icons/esm/commerce';

import {
  DATA_ICON_DOT_MARK,
  DATA_ICON_TREND,
} from '@rdcs/jello-icons/esm/data';

import {
  DEVICE_ICON_ACCU_CHEK_GUIDE,
  DEVICE_ICON_ACCU_CHEK_INSIGHT_PUMP,
  DEVICE_ICON_CGM_PATCH,
  DEVICE_ICON_GLUCOSE_DEVICES,
  DEVICE_ICON_ACTIVE_TEST_STRIPS,
} from '@rdcs/jello-icons/esm/device';

import { DIABETES_ICON_LOGBOOK } from '@rdcs/jello-icons/esm/diabetes';

import {
  FILE_ICON_DOCUMENT_EXPORT,
  FILE_ICON_DOCUMENT_SIGNATURE,
} from '@rdcs/jello-icons/esm/file';

import {
  DEPRECATED_MEDIA_ICON_SETTINGS_ADJUST,
  MEDIA_ICON_POWER,
  MEDIA_ICON_PAUSE_OUTLINE,
} from '@rdcs/jello-icons/esm/media';

import {
  STATUS_ICON_HELP,
  STATUS_ICON_INFORMATION,
  STATUS_ICON_WARNING,
  STATUS_ICON_WARNING_FILLED,
  STATUS_ICON_WARNING_ALT,
  STATUS_ICON_WARNING_ALT_FILLED,
} from '@rdcs/jello-icons/esm/status';

import {
  DEPRECATED_TECHNOLOGY_ICON_DEVICES,
  TECHNOLOGY_ICON_PRINTER,
  TECHNOLOGY_ICON_FORUM,
  TECHNOLOGY_ICON_LINKED,
} from '@rdcs/jello-icons/esm/technology';

import { DEPRECATED_TRAVEL_ICON_BROOM } from '@rdcs/jello-icons/esm/travel';

import {
  DEPRECATED_USER_ICON_USER_ACTIVITY,
  DEPRECATED_USER_ICON_USER_DATA,
  USER_ICON_ADD_HCP_PROFILE,
  USER_ICON_ADD_PATIENT,
  USER_ICON_EDIT_PATIENT,
  USER_ICON_USER_PROFILE,
  DEPRECATED_USER_ICON_USER_AVATAR,
} from '@rdcs/jello-icons/esm/user';

export const JELLO_ICONS_LIST = [
  DEPRECATED_MEDIA_ICON_SETTINGS_ADJUST,
  DEPRECATED_ACTION_ICON_SEND_ALT_FILLED,
  DEPRECATED_ACTION_ICON_CHAT,
  DEPRECATED_USER_ICON_USER_ACTIVITY,
  DEPRECATED_USER_ICON_USER_AVATAR,
  DEPRECATED_USER_ICON_USER_DATA,
  DEPRECATED_ACTION_ICON_NOTIFICATION_FILLED,
  DEPRECATED_TECHNOLOGY_ICON_DEVICES,
  DEPRECATED_TRAVEL_ICON_BROOM,
  ACTION_ICON_ARROW_DOWN,
  ACTION_ICON_ARROW_LEFT,
  ACTION_ICON_ARROW_UP,
  ACTION_ICON_CHECKMARK,
  ACTION_ICON_CHECKMARK_FILLED,
  ACTION_ICON_CHEVRON_DOWN,
  ACTION_ICON_CHEVRON_LEFT,
  ACTION_ICON_CHEVRON_RIGHT,
  ACTION_ICON_CLOSE,
  ACTION_ICON_CLOSE_FILLED,
  ACTION_ICON_CLOUD_UPLOAD,
  ACTION_ICON_HOME,
  ACTION_ICON_LAUNCH,
  ACTION_ICON_LOCKED,
  ACTION_ICON_MAXIMIZE,
  ACTION_ICON_MINIMIZE,
  ACTION_ICON_NOTIFICATION,
  ACTION_ICON_PAGE_FIRST,
  ACTION_ICON_PAGE_LAST,
  ACTION_ICON_SEARCH,
  ACTION_ICON_SETTINGS,
  ACTION_ICON_SUBTRACT,
  ACTION_ICON_SWITCHER,
  ACTION_ICON_VIDEO,
  ACTION_ICON_VIDEO_OFF_FILLED,
  CALENDAR_ICON_CALENDAR,
  CALENDAR_ICON_TIME,
  CALENDAR_ICON_TIMER,
  COMMERCE_ICON_BUILDING,
  COMMERCE_ICON_INVENTORY_MANAGEMENT,
  DATA_ICON_DOT_MARK,
  DATA_ICON_TREND,
  DEVICE_ICON_ACCU_CHEK_GUIDE,
  DEVICE_ICON_ACCU_CHEK_INSIGHT_PUMP,
  DEVICE_ICON_CGM_PATCH,
  DEVICE_ICON_GLUCOSE_DEVICES,
  DEVICE_ICON_ACTIVE_TEST_STRIPS,
  DIABETES_ICON_LOGBOOK,
  FILE_ICON_DOCUMENT_EXPORT,
  FILE_ICON_DOCUMENT_SIGNATURE,
  MEDIA_ICON_POWER,
  MEDIA_ICON_PAUSE_OUTLINE,
  STATUS_ICON_HELP,
  STATUS_ICON_INFORMATION,
  STATUS_ICON_WARNING,
  STATUS_ICON_WARNING_FILLED,
  STATUS_ICON_WARNING_ALT,
  STATUS_ICON_WARNING_ALT_FILLED,
  TECHNOLOGY_ICON_FORUM,
  TECHNOLOGY_ICON_LINKED,
  TECHNOLOGY_ICON_PRINTER,
  USER_ICON_ADD_HCP_PROFILE,
  USER_ICON_ADD_PATIENT,
  USER_ICON_EDIT_PATIENT,
  USER_ICON_USER_PROFILE,
];
