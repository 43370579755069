import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  HypoLimitIcon,
  RectangleMarkIcon,
  CrossMarkIcon,
  DoubleEllipseIcon,
  GridIcon,
} from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';

import { LegendItem } from './legend-item.component';
import { CheckboxContainerComponent } from './checkbox-container.component';

import {
  LegendList,
  LegendListsContainer,
  LegendListSectionHeader,
  RotatedIconContainer,
} from '../graph-legend.style';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { CheckboxesForm } from '../../graph-options/graph-options.style';

export const MetabolicGraphLegendComponent = ({ t, graph, expandedGraph }) => (
  <LegendListsContainer>
    <CheckboxesForm model="ui.patientDashboard">
      <LegendList>
        <LegendListSectionHeader>
          {t('graphDetails.statistics.bloodGlucose.bloodGlucose')}
        </LegendListSectionHeader>
        <LegendItem
          icon={
            <RotatedIconContainer rotationAngle={45}>
              <RectangleMarkIcon fillColor={colors.white} />
            </RotatedIconContainer>
          }
          label={t('graphDetails.legend.meanBloodGlucoseSD')}
        />
        <LegendItem
          icon={<CrossMarkIcon fillColor={colors.brandBlue} />}
          label={t('graphDetails.legend.meanBloodGlucoseMeanSD')}
        />
        <LegendItem
          icon={<DoubleEllipseIcon strokeColor={colors.blue} />}
          label={t('graphDetails.legend.oneSdTwoSd')}
        />
      </LegendList>
      <LegendList>
        <LegendListSectionHeader>
          {t('graphDetails.legend.targetRangeSectionHeader')}
        </LegendListSectionHeader>
        <LegendItem
          icon={
            <RectangleMarkIcon
              strokeColor={colors.green}
              fillColor={colors.white}
            />
          }
          label={t('graphDetails.legend.targetRange')}
        />
        <LegendItem
          icon={<HypoLimitIcon />}
          label={t('graphDetails.legend.veryLowLimit')}
        />
      </LegendList>
      <LegendList noBorder>
        <LegendListSectionHeader>
          <LocalizedText textKey="graphDetails.legend.graphVisuals" />
        </LegendListSectionHeader>
        <CheckboxContainerComponent
          t={t}
          id={'graphs.detailGraph.gridLines'}
          modelPath={'.showGridLines'}
          icon={<GridIcon />}
          graph={graph}
          expandedGraph={expandedGraph}
        />
      </LegendList>
    </CheckboxesForm>
  </LegendListsContainer>
);

export const MetabolicGraphLegend = withTranslation(
  MetabolicGraphLegendComponent,
);
