import { PayloadAction } from 'src/app/store/app.types';

export enum NextShipmentActionType {
  NEXT_SHIPMENT_FETCH = 'NEXT_SHIPMENT_FETCH',
  NEXT_SHIPMENT_FETCH_SUCCESS = 'NEXT_SHIPMENT_FETCH_SUCCESS',
  NEXT_SHIPMENT_CHANGE_STATUS = 'NEXT_SHIPMENT_CHANGE_STATUS',
  NEXT_SHIPMENT_CHANGE_DATE = 'NEXT_SHIPMENT_CHANGE_DATE',
  CLEAR_NEXT_SHIPMENT = 'CLEAR_NEXT_SHIPMENT',
}

export type NextShipmentFetchAction = PayloadAction<
  NextShipmentActionType.NEXT_SHIPMENT_FETCH,
  NextShipmentFetchParamsPayloadType
>;

export type NextShipmentFetchSuccessAction = PayloadAction<
  NextShipmentActionType.NEXT_SHIPMENT_FETCH_SUCCESS,
  Shipment
>;

export type Shipment = {
  id: number;
  shipDate: string;
  shipDateFormatted: string;
  shipStatus: string;
  shipQtty: number;
  resultDescription: string;
  status?: number;
};

export type NextShipmentFetchParamsPayloadType = {
  patientId: string;
};

export type NextShipmentChangeStatusParamsPayloadType = string;

export type NextShipmentChangeDateParamsPayloadType = {
  newDate: string;
  shipStatus: string;
};

export type NextShipmentState = Shipment;

export type NextShipmentActions =
  | NextShipmentFetchAction
  | NextShipmentFetchSuccessAction;
