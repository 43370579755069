import { matchPath } from 'react-router-dom';
import { countlyEventTrigger } from 'src/navigation/countly';
import {
  HELP_DOCUMENTATION_EVENTS_KEYS,
  HELP_DOCUMENTATION_SEGMENT_KEYS,
  JELLO_SEARCH_BOX_ACTION_EVENT,
  JELLO_SEARCH_BOX_LOKALISATION_KEYS,
  MIN_CHARS_START_SEARCH,
} from './top-navigation-jello.constants';
import { newDeviceLinkLinks } from 'src/modules/wdtc-module/routes';
import { profileLinks } from 'src/domains/profile/routes';

/* istanbul ignore next */ export const isHomeRoute = (routes) =>
  !!matchPath(location.pathname, {
    path: routes.general.home,
    exact: true,
  });
/* istanbul ignore next */ export const isHomeCgmRoute = (routes) =>
  !!matchPath(location.pathname, {
    path: routes.general.homeCgm,
    exact: true,
  });

/* istanbul ignore next */ export const validatePermissions = (
  routes,
  profile,
  isHcpOrGp,
  mngHome,
  isPayer,
): boolean => {
  return (
    !!profile &&
    !(
      !!isHcpOrGp &&
      !mngHome &&
      (!!isHomeRoute(routes) || !!isHomeCgmRoute(routes))
    ) &&
    !isPayer
  );
};

export const countlyHandleListItemLink = (countlySegmentValue: string) =>
  countlyEventTrigger(
    HELP_DOCUMENTATION_EVENTS_KEYS.HELP_DOCUMENTATION_OPENED,
    {
      [HELP_DOCUMENTATION_SEGMENT_KEYS.HELP_OPTION]: countlySegmentValue,
    },
  );

export const getSearchBoxPlaceHolder = (hasPermission: boolean) =>
  hasPermission
    ? JELLO_SEARCH_BOX_LOKALISATION_KEYS.SEARCH_BOX_PLACEHOLDER
    : JELLO_SEARCH_BOX_LOKALISATION_KEYS.SEARCH_BOX_PLACEHOLDER_ADMIN;

export const shouldTriggerSearch = (value: string, event: string) =>
  (value === '' || value.length >= MIN_CHARS_START_SEARCH) &&
  event === JELLO_SEARCH_BOX_ACTION_EVENT;

export const determineSearchFunction = (
  isHcpOrGp: boolean,
  hasMngHome: boolean,
  searchHcpFunction: (value: string) => void,
  searchAdminFunction: (value: string) => void,
) => {
  return isHcpOrGp && hasMngHome ? searchHcpFunction : searchAdminFunction;
};

export const isUnavailableRouteForNewHeader = (currentPath: string) => {
  const availableRoutes = [
    newDeviceLinkLinks.main,
    profileLinks.changePassword,
  ];
  const isContainedInAvailableRoutes = availableRoutes.some((route) =>
    currentPath.includes(route),
  );
  return isContainedInAvailableRoutes;
};

const countDots = (str: string) => str.split('.').length - 1;

const truncateVersion = (version: string) =>
  version.substring(0, version.lastIndexOf('.'));

export const getVersionIFUForLinkPDF = ({
  rebranding,
  appAccVersion = '',
  appVersion = '',
}: {
  rebranding: boolean;
  appAccVersion: string;
  appVersion: string;
}) => {
  const version = rebranding ? appAccVersion : appVersion;
  const dotsCount = countDots(version);
  return dotsCount > 1 ? truncateVersion(version) : version;
};
