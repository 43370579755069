import { getConfig } from '@roche/roche-common';
import { SessionActionType } from 'src/app/session/core/session/session.types';

const {
  REACT_APP_ACCESS_ENDPOINT,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_URL,
  REACT_APP_API_GATEWAY,
  REACT_APP_CLIENT_SECRET,
  GIGYA_UID,
} = getConfig();

const INITIAL_CONFIG_STATE = {
  accessEndpoint: REACT_APP_ACCESS_ENDPOINT,
  gigyaToken: REACT_APP_GIGYA_TOKEN,
  oidcClientId: REACT_APP_OIDC_CLIENT_ID,
  redirectUri: `${REACT_APP_URL}/auth/success`,
  apiGateway: REACT_APP_API_GATEWAY,
  clientSecret: REACT_APP_CLIENT_SECRET,
  gigyaUid: GIGYA_UID,
};

export const configReducer = (state = INITIAL_CONFIG_STATE, action) => {
  switch (action.type) {
    case SessionActionType.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        gigyaUid: action.payload?.user?.gigyaUid,
      };
    case SessionActionType.SIGN_OUT_SUCCESS:
    case SessionActionType.SIGN_OUT_ERROR:
      return {
        ...INITIAL_CONFIG_STATE,
      };
    default:
      return state;
  }
};
