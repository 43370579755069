import {
  SaveTargetRangesServiceImpl,
  SaveTargetRangesLoaderImpl,
  SaveTargetRangesTransformImpl,
} from './save-target-ranges.service';
import { payloadSaveTargetRanges } from './save-target-ranges.mock';
import { transformTargetRanges } from './save-target-ranges.util';

const mockLoader = (q) =>
  Promise.resolve(transformTargetRanges(payloadSaveTargetRanges));

export const SaveTargetRangesFetchFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : SaveTargetRangesLoaderImpl;
  return SaveTargetRangesServiceImpl(loader, SaveTargetRangesTransformImpl);
};
