export const mockHba1c = {
  resultDescription: 'OK',
  model: {
    id: 209,
    hba1cValue: 6,
    fhirId: 'xCHH9hp-ng1-2',
    hba1cDate: '2020-04-16T00:00:00+0000',
    uuid: 'edea81a9-3469-440d-b14b-a556aae1e219',
  },
};
