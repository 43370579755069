import { Action, PayloadAction } from 'src/app/store/app.types';
import { Hba1cCardInfo } from '../hba1c-card.types';

export enum Hba1cCardActionType {
  FETCH_HBA1C_CARD_START = 'FETCH_HBA1C_CARD_START',
  FETCH_HBA1C_CARD_SUCCESS = 'FETCH_HBA1C_CARD_SUCCESS',
  FETCH_HBA1C_CARD_ERROR = 'FETCH_HBA1C_CARD_ERROR',
}
export type Hba1cCardActions =
  | FetchHba1cCardStartAction
  | FetchHba1cCardSuccessAction
  | FetchHba1cCardErrorAction
  | FixMe;

export type FetchHba1cCardStartAction = PayloadAction<
  Hba1cCardActionType.FETCH_HBA1C_CARD_START,
  FetchHba1cCardStartPayloadType
>;

export type FetchHba1cCardSuccessAction = PayloadAction<
  Hba1cCardActionType.FETCH_HBA1C_CARD_SUCCESS,
  Hba1cCardInfo
>;

export type FetchHba1cCardErrorAction =
  Action<Hba1cCardActionType.FETCH_HBA1C_CARD_ERROR>;

export type FetchHba1cCardStartPayloadType = {
  patientId: string;
};

export type Hba1cCardState = {
  fhirId: string | null;
  isFetchingHba1cCard: boolean;
  isFetchingHba1cCardError: boolean;
  hba1cCardInfo: Hba1cCardInfo | null;
};
