import styled from 'styled-components';

import { convertPxToRem } from 'src/domains/diagnostics/utils/rem-calc';
import { colors } from 'src/core/styles/colors';
import {
  ToggleSwitchContainerSpan,
  ToggleSwitchIndicatorSpan,
  ToggleSwitchInput,
} from 'src/components/toggle-switch/toggle-switch.style';

export const ToggleSwitchCirclePrimary = styled(ToggleSwitchIndicatorSpan)`
  width: ${convertPxToRem(18)};
  height ${convertPxToRem(18)};
`;

export const ToggleSwitchInputPrimary = styled(ToggleSwitchInput)`
  &:focus + ${ToggleSwitchContainerSpan} {
    background-color: ${colors.brandBlue};
  }
  &:not(:checked) + ${ToggleSwitchContainerSpan} {
    background-color: ${colors.silver};
    box-shadow: none;
  }
  & + ${ToggleSwitchContainerSpan} {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const ToggleSwitchButtonPrimary = styled(ToggleSwitchContainerSpan)`
  width: ${convertPxToRem(35)};
  padding: ${convertPxToRem(3)};
`;
