import { createSelector, createStructuredSelector } from 'reselect';
import { isNil, isEmpty } from 'ramda';

import { selectPatient } from 'src/domains/diagnostics/core/patient/patient.selector';
import {
  selectBasalsInDateSliderRange,
  selectBolusesDataInDateSliderRange,
  selectIsFetchingClinicalData,
  selectNumberOfDaysInDateSliderRange,
  selectBolusPerDay,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { MINUTES_IN_DAY } from 'src/domains/diagnostics/store/constants';
import {
  selectAvgBolusBasalPerDay,
  selectHasNoData,
} from 'src/domains/diagnostics/widgets/insulin-details/insulin-details.selector';

import { PROFILE_TIMES_START } from './insulin.constant';

const reduceToMinutesByProfile = (minutesByDay, basal) => {
  const { date, basalRateProfile, basalCbrf } = basal;

  const currMinutes = date.hour * 60 + date.minute;
  const latestProfile = minutesByDay.latestProfile;

  let minutes;
  const currProfile = minutesByDay[latestProfile];
  let base = currMinutes === 0 || !currProfile ? 0 : minutesByDay.base;

  if (currProfile) {
    minutes =
      minutesByDay.latestVal === 0
        ? currProfile.minutes
        : currProfile.minutes + currMinutes - base;
  } else {
    minutes = 0;
  }

  base = currMinutes;

  return {
    ...minutesByDay,
    [latestProfile]: { ...minutesByDay[latestProfile], minutes },
    latestVal: basalCbrf,
    latestProfile: basalRateProfile,
    base,
  };
};

export const selectProfileMinutes = createSelector(
  selectBasalsInDateSliderRange,
  selectNumberOfDaysInDateSliderRange,
  (basals, numDays) => {
    const profileMinutes = basals.reduce(
      reduceToMinutesByProfile,
      PROFILE_TIMES_START,
    );

    const totalMinutes = MINUTES_IN_DAY * numDays;

    const stoppedMinutes =
      totalMinutes -
      (profileMinutes['1'].minutes +
        profileMinutes['2'].minutes +
        profileMinutes['3'].minutes +
        profileMinutes['4'].minutes +
        profileMinutes['5'].minutes);

    return { ...profileMinutes, stop: { minutes: stoppedMinutes } };
  },
);

export const selectBolusTableData = createSelector(
  selectBolusPerDay,
  ({
    numStdBolusPerDay,
    numQuickBolusPerDay,
    numExtendedBolusPerDay,
    numMultiwaveBolusPerDay,
    numStdBolus,
    numQuickBolus,
    numExtendedBolus,
    numMultiwaveBolus,
    totalBoluses,
  }) => [
    {
      bolus: totalBoluses > 0 ? numStdBolus / totalBoluses : 0,
      remoteBolus: 0,
      numberOfBolusesPerDay: numStdBolusPerDay,
    },
    {
      bolus: totalBoluses > 0 ? numQuickBolus / totalBoluses : 0,
      remoteBolus: 0,
      numberOfBolusesPerDay: numQuickBolusPerDay,
    },
    {
      bolus: totalBoluses > 0 ? numExtendedBolus / totalBoluses : 0,
      remoteBolus: 0,
      numberOfBolusesPerDay: numExtendedBolusPerDay,
    },
    {
      bolus: totalBoluses > 0 ? numMultiwaveBolus / totalBoluses : 0,
      remoteBolus: 0,
      numberOfBolusesPerDay: numMultiwaveBolusPerDay,
    },
  ],
);

const getTbrIncCountForProfile = (basals, profile) =>
  basals.filter(
    (basal) => !isNil(basal.basalTbrinc) && basal.basalRateProfile === profile,
  ).length;

const getTbrDecCountForProfile = (basals, profile) =>
  basals.filter(
    (basal) => !isNil(basal.basalTbrdec) && basal.basalRateProfile === profile,
  ).length;

const selectBasalRateTableData = createSelector(
  selectBasalsInDateSliderRange,
  selectProfileMinutes,
  selectNumberOfDaysInDateSliderRange,
  (basals, profileMinutes, numDays) => {
    const totalMinutes = MINUTES_IN_DAY * numDays;

    return [
      {
        profileName: 1,
        profilePercentage: profileMinutes['1'].minutes / totalMinutes,
        tbrIncreases: getTbrIncCountForProfile(basals, '1'),
        tbrDecreases: getTbrDecCountForProfile(basals, '1'),
        basalRateChanges: 0,
      },
      {
        profileName: 2,
        profilePercentage: profileMinutes['2'].minutes / totalMinutes,
        tbrIncreases: getTbrIncCountForProfile(basals, '2'),
        tbrDecreases: getTbrDecCountForProfile(basals, '2'),
        basalRateChanges: 0,
      },
      {
        profileName: 3,
        profilePercentage: profileMinutes['3'].minutes / totalMinutes,
        tbrIncreases: getTbrIncCountForProfile(basals, '3'),
        tbrDecreases: getTbrDecCountForProfile(basals, '3'),
        basalRateChanges: 0,
      },
      {
        profileName: 4,
        profilePercentage: profileMinutes['4'].minutes / totalMinutes,
        tbrIncreases: getTbrIncCountForProfile(basals, '4'),
        tbrDecreases: getTbrDecCountForProfile(basals, '4'),
        basalRateChanges: 0,
      },
      {
        profileName: 5,
        profilePercentage: profileMinutes['5'].minutes / totalMinutes,
        tbrIncreases: getTbrIncCountForProfile(basals, '5'),
        tbrDecreases: getTbrDecCountForProfile(basals, '5'),
        basalRateChanges: 0,
      },
      {
        profileName: 'graphs.insulin.stop',
        profilePercentage: profileMinutes.stop.minutes / totalMinutes,
      },
    ];
  },
);

const selectHasData = createSelector(
  selectBasalsInDateSliderRange,
  selectBolusesDataInDateSliderRange,
  selectIsFetchingClinicalData,
  selectHasNoData,
  (basals, bolus, isFetchingData, hasNoBolusBasalData) =>
    isFetchingData ||
    !isEmpty(basals) ||
    !isEmpty(bolus) ||
    !hasNoBolusBasalData,
);

export const selectHasBolusData = createSelector(
  selectAvgBolusBasalPerDay,
  selectBolusPerDay,
  (bolus, bolusPerDay) =>
    bolus.hasBolusData || Object.values(bolusPerDay).some((value) => value > 0),
);

export const insulinConnector = createStructuredSelector({
  basalRateTableData: selectBasalRateTableData,
  bolusTableData: selectBolusTableData,
  patient: selectPatient,
  hasData: selectHasData,
  isLoading: selectIsFetchingClinicalData,
  hasBolusData: selectHasBolusData,
});
