import { map } from 'ramda';

import { translate } from 'src/i18n';

export const transformPrescriptionReasons = (PrescriptionReasonsData) => {
  const mapper = ({ label, description, ...data }) => ({
    ...data,
    description: label
      ? translate(`prescription.reason.${label}`)
      : description,
  });
  return map(mapper, PrescriptionReasonsData);
};
