import { compose, filter, pathOr, reverse, sortBy, toLower } from 'ramda';
import { ALL_PATIENTS_LIST_SORT } from '../constants';

export const sortPatientsOnHcpDashboard = (value, state, type) => {
  const patients =
    type === ALL_PATIENTS_LIST_SORT ? state.allPatients : state.filterPatients;
  const property = value.substring(0, value.length - 3);
  const order = value.substring(value.length - 3, value.length).toLowerCase();
  return sortPatients(property, patients, order);
};

const _do =
  (...fns) =>
  (x) =>
    fns.reduce((r, f) => f(r), x);
const _when = (cond, f) => (x) => cond(x) ? f(x) : x;

const sortPatients = (sortProperty, patients, order) => {
  const mockCollator = class {
    public compare(): any {
      return null;
    }
  };
  const collator = new (Intl.Collator || mockCollator)(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  const p = patients.sort((a, b) =>
    collator.compare(
      getProperty(a, sortProperty, order),
      getProperty(b, sortProperty, order),
    ),
  );
  const sorted = sortBy(
    compose(
      _when((x) => typeof x === 'string', _do(toLower)),
      pathOr(null, ['sort', sortProperty]),
    ),
  )(p);
  const empty = [undefined, null, 0];
  const data = filter((p) => empty.indexOf(p.sort[sortProperty]) < 0, sorted);
  const nils = filter((p) => empty.indexOf(p.sort[sortProperty]) > -1, sorted);
  return [...(order === 'des' ? reverse(data) : data), ...nils];
};

export const getProperty = (patient, sortProperty, order) => {
  return compose(
    _when((x) => typeof x === 'string', _do(toLower)),
    pathOr(null, ['sort', sortProperty]),
  )(patient);
};
