import React from 'react';
import { findIndex, propEq } from 'ramda';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { ArrowIcon } from 'src/assets/icons';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { ChangeGraphTypeButtonsContainer as GraphTypeButtons } from 'src/domains/diagnostics/components/graph-type-buttons/graph-type-buttons.container';
import { colors } from 'src/core/styles/colors';

import {
  GraphControlsContainer,
  ButtonsContainer,
  ContentContainer,
  NavTabsList,
  NavTabsListItem,
  NavTabsListItemText,
  ArrowUpDown,
  selectedTabStyle,
} from './graph-controls.style.js';
import {
  getGraphDetailTabs,
  legendStatisticsTabOpenedCountly,
} from './graph-controls.utils.js';

import { Portal } from 'src/domains/diagnostics/components/portal/portal.component';
import { testId } from '@roche/roche-common';

export class GraphControlsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: null,
      portalContainer: this.props.portalContainer,
    };
  }

  render() {
    const {
      graphType,
      graphStatistics,
      showChangeGraphToggle = false,
      children,
      margin = true,
      marginLeft,
      t,
      portalContainer,
      collapsed,
      collapsedGP,
      graph,
      width,
    } = this.props;

    const tabs = getGraphDetailTabs(
      graphType,
      graphStatistics,
      showChangeGraphToggle,
      graph,
    );
    const hasSelectedTab = this.state.selectedTabIndex !== null;
    const legendIndex = findIndex(propEq('keyText', 'legend'), tabs);
    return (
      <GraphControlsContainer margin={margin} width={width}>
        <ButtonsContainer
          {...testId('graph-template', 'graph-control')}
          collapsed={collapsed}
          collapsedGP={collapsedGP}
          graphType={graphType}
        >
          {children}
          <RenderIf validate={!collapsed && !collapsedGP}>
            <GraphTypeButtons showChangeGraphToggle={showChangeGraphToggle} />
          </RenderIf>
          <NavTabsList marginLeft={marginLeft}>
            {showChangeGraphToggle ? (
              tabs.map((tab, index) => (
                <NavTabsListItem
                  key={tab.keyText}
                  onClick={() =>
                    this.handleTabClick(
                      index,
                      tab,
                      showChangeGraphToggle,
                      graph,
                    )
                  }
                  selected={index === this.state.selectedTabIndex}
                  selectedStyle={selectedTabStyle}
                  noTabSelected={!hasSelectedTab}
                >
                  <NavTabsListItemText
                    selected={index === this.state.selectedTabIndex}
                  >
                    {index === 0
                      ? t('graphDetails.legend.legend')
                      : t('graphDetails.statistics.statistics')}
                    <ArrowUpDown
                      selected={index === this.state.selectedTabIndex}
                    >
                      <ArrowIcon
                        height={6}
                        fillColor={
                          index === this.state.selectedTabIndex
                            ? colors.blue
                            : colors.charcoal
                        }
                      />
                    </ArrowUpDown>
                  </NavTabsListItemText>
                </NavTabsListItem>
              ))
            ) : (
              <NavTabsListItem
                key={tabs[0].keyText}
                onClick={() =>
                  this.handleTabClick(
                    legendIndex,
                    tabs[0],
                    showChangeGraphToggle,
                    graph,
                  )
                }
                selected={this.state.selectedTabIndex === legendIndex}
                selectedStyle={selectedTabStyle}
                noTabSelected={!hasSelectedTab}
              >
                <NavTabsListItemText
                  selected={this.state.selectedTabIndex === legendIndex}
                >
                  {t('graphDetails.legend.legend')}
                </NavTabsListItemText>
                <ArrowUpDown
                  selected={this.state.selectedTabIndex === legendIndex}
                >
                  <ArrowIcon
                    height={6}
                    fillColor={
                      this.state.selectedTabIndex === legendIndex
                        ? colors.blue
                        : colors.charcoal
                    }
                  />
                </ArrowUpDown>
              </NavTabsListItem>
            )}
          </NavTabsList>
        </ButtonsContainer>
        <RenderIf validate={hasSelectedTab}>
          {portalContainer ? (
            <Portal rootId={portalContainer}>
              <ContentContainer>
                {hasSelectedTab
                  ? tabs[this.state.selectedTabIndex].content
                  : null}
              </ContentContainer>
            </Portal>
          ) : (
            <ContentContainer graphType={graphType}>
              {hasSelectedTab
                ? tabs[this.state.selectedTabIndex].content
                : null}
            </ContentContainer>
          )}
        </RenderIf>
      </GraphControlsContainer>
    );
  }

  handleTabClick(index, tab, showChangeGraphToggle, graph) {
    if (index !== this.state.selectedTabIndex) {
      legendStatisticsTabOpenedCountly(tab, showChangeGraphToggle, graph);
    }
    this.setState({
      selectedTabIndex: index === this.state.selectedTabIndex ? null : index,
    });
  }
}

export const GraphControls = withTranslation(GraphControlsComponent);
