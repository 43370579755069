import { ERROR_409 } from '../../create-edit-form-jello.constants';
import {
  CorrelateSharingCodeActions,
  CorrelateSharingCodeActionType,
  CorrelateSharingCodeState,
} from './correlate-sharing-code.actions.types';

export const correlateSharingCodeInitialState: CorrelateSharingCodeState = {
  isCheckingCorrelation: false,
  correlationSuccessful: false,
  correlationHasError: false,
  correlationHasConflict: false,
};

export const correlateSharingCodeReducer = (
  state = correlateSharingCodeInitialState,
  action: CorrelateSharingCodeActions,
) => {
  switch (action.type) {
    case CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_START:
      return {
        isCheckingCorrelation: true,
        correlationSuccessful: false,
        correlationHasError: false,
        correlationHasConflict: false,
      };
    case CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_SUCCESS:
      return {
        isCheckingCorrelation: false,
        correlationSuccessful: true,
        correlationHasError: false,
        correlationHasConflict: false,
      };
    case CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_ERROR:
      return {
        isCheckingCorrelation: false,
        correlationSuccessful: false,
        correlationHasError: action.payload.status !== ERROR_409,
        correlationHasConflict: action.payload.status === ERROR_409,
      };
    case CorrelateSharingCodeActionType.CLEAR_CORRELATE_SHARING_CODE_DATA:
      return correlateSharingCodeInitialState;
    default:
      return state;
  }
};
