import { DiabetesType, DownloadNoticePeriod, Gender } from '../patient.types';

export const mockGetPatientInfoResponse = {
  resultDescription: 'getPatientOK',
  model: {
    timeZone: 'Europe/Madrid',
    clinicalRecordNumber: null,
    healthCareSystemId: null,
    diagnosticDate: '2008-09-15T15:53:00Z',
    expectedDeliverDate: null,
    nursingHome: false,
    pregnant: false,
    diabetesType: DiabetesType.DIABETES_TYPE1,
    downloadNoticePeriod: DownloadNoticePeriod.NO_WARNING,
    professionalList: [29542, 14301],
    grantRevokeFunctDTO: {
      grantFunctionalities: null,
      revokeFunctionalities: null,
    },
    user: {
      name: 'Andrew ',
      surname: 'Test',
      surname2: 'second surname',
      phone: null,
      email: 'andrewtest@mail.com',
      languageId: null,
      departmentProfileId: 41,
      birthday: null,
      gender: Gender.FEMALE,
      address: {
        address: '',
        city: '',
        province: '',
        postalCode: '',
        country: {
          id: 1,
        },
      },
      hcpIsAccessible: true,
    },
  },
};

export const mockGetPatientInfoError = {
  resultDescription: '31343ERROR',
  error: [
    {
      error: 'ERROR_VERIFYING_TOKEN',
    },
  ],
};
