export const mockPatientsListData = [
  {
    id: '2',
    name: 'Ms',
    surname: 'Prada',
    surname2: 'Flip Flops',
  },
  {
    id: '5',
    name: 'Mr',
    surname: 'Gucci',
    surname2: 'Slippers',
  },
];
