import { connect } from 'react-redux';
import { DataSharingStatusBtnComponent } from './data-sharing-status-btn.component';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { push } from 'react-router-redux';
import { selectCheckDataSharingConsentState } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.selector';
import { selectPatient } from 'src/core/patient/patient.selector';
import { selectIsInterventionalStudy } from 'src/core/permissions/permissions.selectors';
import { selectPermissions } from 'src/core/permissions/permissions.selectors';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

const selectIsDisabled = (state) => {
  const permissions = selectPermissions(state);

  return !permissions.includes(PERMISSIONS.ROLE_PLATFORM);
};

const mapStateToProps = (state) => {
  const map = {
    patient: selectPatient(state),
    consentState: selectCheckDataSharingConsentState(state),
    isInterventionalStudyPatient: selectIsInterventionalStudy(state),
    disabled: selectIsDisabled(state),
  };

  return map;
};

const mapDispatchToProps = mapDispatchers({ goTo: push });

export const DataSharingStatusBtn = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataSharingStatusBtnComponent);
