import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { RectangleMarkIcon, AsteriskIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';

import { LegendItem } from './legend-item.component';

import {
  LegendList,
  LegendListsContainer,
  LegendListSectionHeader,
} from '../graph-legend.style';

export const LogbookStatsGraphLegendComponent = ({ t }) => (
  <LegendListsContainer>
    <LegendList noBorder>
      <LegendListSectionHeader>
        {t('graphDetails.legend.targetRangeSectionHeader')}
      </LegendListSectionHeader>
      <LegendItem
        icon={
          <RectangleMarkIcon
            fillColor={colors.quartzBlue}
            strokeColor={colors.quartzBlue}
          />
        }
        label={t('graphDetails.legend.aboveTargetRange')}
      />
      <LegendItem
        icon={
          <RectangleMarkIcon
            fillColor={colors.trafficRed2}
            strokeColor={colors.trafficRed2}
          />
        }
        label={t('graphDetails.legend.belowTargetRange')}
      />
    </LegendList>
    <LegendItem
      icon={<AsteriskIcon iconColor={colors.redLegend} height={16} />}
      label={t('graphDetails.legend.outOfRangeValues')}
    />
  </LegendListsContainer>
);

export const LogbookStatsGraphLegend = withTranslation(
  LogbookStatsGraphLegendComponent,
);
