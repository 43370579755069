export const mockHba1cList = {
  resultDescription: 'findLastGlycatedOK',
  model: {
    glycateds: [
      {
        id: 2,
        hba1cValue: 109.7,
        fhirId: 'd426df897f73d1c1c5ad4a3700946067',
        hba1cDate: '2020-01-13T10:15:00+0100',
      },
      {
        id: 3,
        hba1cValue: 19.7,
        fhirId: 'd426df897f73d1c1c5ad4a3700946067',
        hba1cDate: '2020-01-13T10:15:00+0100',
      },
      {
        id: 4,
        hba1cValue: 9.7,
        fhirId: 'd426df897f73d1c1c5ad4a3700946067',
        hba1cDate: '2020-01-13T10:15:00+0100',
      },
    ],
  },
};

export const mockHba1cListTransformed = [
  {
    id: 2,
    fhirId: 'd426df897f73d1c1c5ad4a3700946067',
    hba1cValue: 109.7,
    hba1cDate: '2020-01-13T10:15:00+0100',
  },
  {
    id: 3,
    fhirId: 'd426df897f73d1c1c5ad4a3700946067',
    hba1cValue: 19.7,
    hba1cDate: '2020-01-13T10:15:00+0100',
  },
  {
    id: 4,
    fhirId: 'd426df897f73d1c1c5ad4a3700946067',
    hba1cValue: 9.7,
    hba1cDate: '2020-01-13T10:15:00+0100',
  },
];
