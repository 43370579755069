import {
  CorrelatePairingCodeActions,
  CorrelatePairingCodeActionType,
  CorrelatePairingCodeState,
} from './correlate-pairing-code.types';

export const correlatePairingCodeInitialState: CorrelatePairingCodeState = {
  isCheckingPairingCodeCorrelation: false,
  correlationCheckWasSuccessful: false,
  correlationCheckHasError: false,
  correlationCheckHasErrorConflict: false,
};

export const correlatePairingCodeReducer = (
  state = correlatePairingCodeInitialState,
  action: CorrelatePairingCodeActions,
) => {
  switch (action.type) {
    case CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_START:
      return {
        ...state,
        isCheckingPairingCodeCorrelation: true,
        correlationCheckWasSuccessful: false,
        correlationCheckHasError: false,
        correlationCheckHasErrorConflict: false,
      };
    case CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_SUCCESS:
      return {
        ...state,
        isCheckingPairingCodeCorrelation: false,
        correlationCheckWasSuccessful: true,
        correlationCheckHasError: false,
        correlationCheckHasErrorConflict: false,
      };
    case CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_ERROR:
      return {
        ...state,
        isCheckingPairingCodeCorrelation: false,
        correlationCheckWasSuccessful: false,
        correlationCheckHasError: true,
        correlationCheckHasErrorConflict: false,
      };
    case CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_ERROR_CONFLICT:
      return {
        ...state,
        isCheckingPairingCodeCorrelation: false,
        correlationCheckWasSuccessful: false,
        correlationCheckHasError: false,
        correlationCheckHasErrorConflict: true,
      };
    case CorrelatePairingCodeActionType.CLEAR_PAIRING_CODE_CORRELATION_STATE:
      return {
        ...state,
        isCheckingPairingCodeCorrelation: false,
        correlationCheckWasSuccessful: false,
        correlationCheckHasError: false,
        correlationCheckHasErrorConflict: false,
      };
    default:
      return state;
  }
};
