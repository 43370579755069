import * as React from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { CursorBlock } from 'src/components/block/block.component';
import { Row } from 'src/components/row/row.component';
import { XIcon, InformationIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';
import { RenderIf } from 'src/utils/render-if';
import { ImportantInformationView } from './components/important-information-view.component';

import {
  ManufacturerLeftBlock,
  ManufacturerLeftMenu,
  ManufacturerRightBlock,
  ManufacturerHeader,
  ManufacturerTitle,
} from './manufacturer-info-modal.style';
import { getCurrentAppVersion } from './manufacturer-info-modal.utils';
import { ManufacturerLeftItemComponent } from './manufacturer-info-modal-leftItem';
import { ModalBody } from '../../modal.style';
import { TFunction } from 'i18next';
import { additionalInfoActivated } from 'src/domains/diagnostics/components/additional-info/additional.info.utils';
import { selectHasRebranding } from 'src/core/permissions/permissions.selectors';
import { IntendedUseView } from './components/intended-use-view.component';
import { GeneralInfoViewContainer } from './components/general-info-view/general-info-view.container';
import { DisclaimersView } from './components/disclaimers.component';
import { IconsViewContainer } from './components/icons-view/icons-view.container';
import { ImpressumView } from './components/impressum-view.component';
import { TradeMarkView } from './components/trademark.component';
import { SysReqView } from './components/sys-req.component';

type Props = {
  destroyModal?: () => any;
  t: TFunction;
  rebranding: boolean;
};

type State = { contentShown: string };

export class ManufacturerInfoModalComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = { contentShown: 'genInfo' };
    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick(event) {
    event.preventDefault();
    this.setState({ contentShown: event.target.id });
  }

  public render() {
    const { destroyModal, t, rebranding } = this.props;

    const menuList = [
      { id: 'genInfo', text: t('manufacturerInfo.general') },
      { id: 'intUse', text: t('manufacturerInfo.intendedUse.title') },
      {
        id: 'disclaimers',
        text: t('manufacturerInfo.precautionsWarnings.title'),
      },
      { id: 'importantInfo', text: t('manufacturerInfo.importantInfo.title') },
      { id: 'icons', text: t('manufacturerInfo.icons') },
      { id: 'sysReq', text: t('manufacturerInfo.sysReq') },
      { id: 'impressum', text: t('manufacturerInfo.impressum.title') },
      { id: 'trademark', text: t('manufacturerInfo.trademark.title') },
    ];

    const mapMenu = additionalInfoActivated()
      ? menuList
      : menuList.filter((e) => e.id !== 'icons');

    const currentAppVersion = getCurrentAppVersion(rebranding);

    const version = currentAppVersion
      ? `${t('manufacturerInfo.version')}${currentAppVersion}`
      : '';

    return (
      <ModalBody
        minWidth={960}
        minHeight={600}
        maxHeight={600}
        color={colors.black}
      >
        <ManufacturerHeader>
          <InformationIcon />
          <ManufacturerTitle>
            {`${t('manufacturerInfo.product')} ${version}`}
          </ManufacturerTitle>
          <CursorBlock onClick={destroyModal}>
            <XIcon height={12} fillColor={colors.white} />
          </CursorBlock>
        </ManufacturerHeader>
        <Row>
          <ManufacturerLeftBlock>
            <ManufacturerLeftMenu>
              {mapMenu.map((item, i) => (
                <ManufacturerLeftItemComponent
                  key={i}
                  onClick={this.handleClick}
                  id={item.id}
                  item={item.text}
                  active={item.id === this.state.contentShown}
                />
              ))}
            </ManufacturerLeftMenu>
          </ManufacturerLeftBlock>
          <ManufacturerRightBlock>
            <RenderIf validate={this.state.contentShown === 'genInfo'}>
              <GeneralInfoViewContainer />
            </RenderIf>
            <RenderIf validate={this.state.contentShown === 'intUse'}>
              <IntendedUseView t={t} rebranding={rebranding} />
            </RenderIf>
            <RenderIf validate={this.state.contentShown === 'disclaimers'}>
              <DisclaimersView t={t} rebranding={rebranding} />
            </RenderIf>
            <RenderIf validate={this.state.contentShown === 'importantInfo'}>
              <ImportantInformationView t={t} />
            </RenderIf>
            <RenderIf
              validate={
                this.state.contentShown === 'icons' && additionalInfoActivated
              }
            >
              <IconsViewContainer />
            </RenderIf>
            <RenderIf validate={this.state.contentShown === 'sysReq'}>
              <SysReqView t={t} rebranding={rebranding} />
            </RenderIf>
            <RenderIf validate={this.state.contentShown === 'impressum'}>
              <ImpressumView t={t} />
            </RenderIf>
            <RenderIf validate={this.state.contentShown === 'trademark'}>
              <TradeMarkView t={t} />
            </RenderIf>
          </ManufacturerRightBlock>
        </Row>
      </ModalBody>
    );
  }
}

const mapStateToProps = (state) => ({
  rebranding: selectHasRebranding(state),
});

export const ManufacturerInfoModal = compose<Props, Props>(
  connect(mapStateToProps, null),
  withTranslation,
)(ManufacturerInfoModalComponent);
