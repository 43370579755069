import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import { NewEntryHba1cActionType } from './new-entry-hba1c.types';
import { NewEntryHba1cInfo } from './../new-entry-hba1c.types';

export const saveNewEntryHba1cStart = (
  fhirId: string,
  hba1cInfo: NewEntryHba1cInfo,
): FixMe =>
  createPayloadAction(NewEntryHba1cActionType.SAVE_NEW_ENTRY_HBA1C_START, {
    fhirId,
    hba1cInfo,
  });

export const saveNewEntryHba1cSuccess = (hba1cInfo: NewEntryHba1cInfo): FixMe =>
  createPayloadAction(
    NewEntryHba1cActionType.SAVE_NEW_ENTRY_HBA1C_SUCCESS,
    hba1cInfo,
  );

export const saveNewEntryHba1cError = (): FixMe =>
  createAction(NewEntryHba1cActionType.SAVE_NEW_ENTRY_HBA1C_ERROR);
