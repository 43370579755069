import { groupWith, keys, sort, append } from 'ramda';

import { colors } from 'src/core/styles/colors';
import {
  addDays,
  toEndOfDay,
  toStartOfDay,
  toFormat,
} from '../../../../../../../utils/date';
import {
  ARBITRARY_PROFILE_Y_VALUE,
  ARBITRARY_RATE_Y_VALUE,
  CARBOHYDRATES_Y_MAX,
  TBR_TYPE,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  getBasalProfile,
  getYFromTwoPointsAndOneXValue,
  addBasalEndDatesAndMidnightMeasurements,
  getBasalVerticalPadding,
  isTbrIncrease,
  isTbrDecrease,
  getDimensionsMapper,
  sortInsulinBarsDescending,
  barsWithInvalidLineHeight,
} from 'src/domains/diagnostics/scenes/graphs/graph-shared/graph.util';
import {
  BOLUS_TYPE,
  INSULIN_TYPE,
} from 'src/domains/diagnostics/store/constants';
import {
  getBolusType1Value,
  getBolusType2Value,
  getBolusType3Value,
} from 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.util';
import { hourToMs, convertISOGMT } from 'src/utils/date';

import {
  calculateDifferenceInDays,
  sortAndCalculateDailyStats,
} from '../trend.util';
import { MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS } from '../../../../blood-glucose-overview/store/blood-glucose-overview.constants';
import {
  bolusTypeToParseFunctionMap,
  parseStandardOrQuickBolus,
} from 'src/domains/diagnostics/scenes/graphs/bolus/bolus.util';

const MINS_IN_MS = 60000;
const HOURS_IN_MS = MINS_IN_MS * 60;

export const calculateFullRange = (startDate, endDate) => {
  const start = startDate.startOf('day');
  const end = endDate.endOf('day');
  return end - start;
};

export const convertMeasurementsToPoints = (
  measurements,
  totalTime,
  startDate,
  thresholds,
  graphYMax,
) =>
  measurements.map((measurement) => {
    let shape;

    if (measurement.value > graphYMax) {
      shape = 'triangle';
    } else if (
      !(shape === 'triangle') &&
      (measurement.beforeMeal || measurement.afterMeal)
    ) {
      shape = 'square';
    } else {
      shape = 'x';
    }

    const start = startDate.startOf('day');
    const x = (measurement.date - start) / totalTime;
    const y = shape === 'triangle' ? 1 : measurement.value / graphYMax;
    let strokeColor;

    if (measurement.value > thresholds.glucoseIdealIntervalMax) {
      strokeColor = colors.blueLight;
    } else if (measurement.value < thresholds.hypoglycemiaThreshold) {
      strokeColor = colors.red;
    } else {
      strokeColor = colors.black;
    }

    const fillColor =
      measurement.afterMeal || shape === 'triangle'
        ? strokeColor
        : colors.white;

    return {
      shape,
      x,
      y,
      strokeColor,
      fillColor,
      data: measurement,
    };
  });

export const normalizeMeans = (
  measurements,
  bloodGlucoseUnit,
  startDate,
  endDate,
  graphYMax,
) => {
  const start = startDate.startOf('day');
  const end = endDate.endOf('day');
  const range = calculateDifferenceInDays(start, end);

  const filteredMeasurements = measurements.filter(
    (glucoseValue) => glucoseValue.value,
  );

  const statsByDay = filteredMeasurements
    ? sortAndCalculateDailyStats(filteredMeasurements)
    : sortAndCalculateDailyStats([]);

  return keys(statsByDay).map((key) => {
    const date = convertISOGMT(key);

    const difference = calculateDifferenceInDays(start, date);
    return {
      y: statsByDay[key].mean / graphYMax,
      x: difference / range + 1 / (range * 2),
      fillColor: colors.black,
      data: { value: statsByDay[key].mean, bloodGlucoseUnit },
      notEnoughData:
        statsByDay[key].count < MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS,
    };
  });
};

const pointsXValueComparator = (a, b) => a.x - b.x;

const groupByDay = groupWith(
  ({ data: { date: dateA } }, { data: { date: dateB } }) =>
    toFormat('cccc, MMM d, yyyy')(dateA) ===
    toFormat('cccc, MMM d, yyyy')(dateB),
);

const generateDayCrossoverPointsReducer =
  (oneMillisecondXValue, tenHoursXValue, startDate, totalTime) =>
  (crossoverPoints, day, index, originalArray) => {
    const nextDay = originalArray[index + 1];

    if (!nextDay || !nextDay.length) {
      return crossoverPoints;
    }

    const lastPointOfDay = day[day.length - 1];
    const firstPointOfNextDay = nextDay[0];

    if (firstPointOfNextDay.x - lastPointOfDay.x > tenHoursXValue) {
      return crossoverPoints;
    }

    const x = (toEndOfDay(lastPointOfDay.data.date) - startDate) / totalTime;

    const y = getYFromTwoPointsAndOneXValue(
      lastPointOfDay.x,
      lastPointOfDay.y,
      firstPointOfNextDay.x,
      firstPointOfNextDay.y,
      x,
    );

    return [
      ...crossoverPoints,
      {
        crossOverPoint: false,
        x,
        y,
        data: {
          date: toEndOfDay(lastPointOfDay.data.date),
        },
      },
      {
        crossOverPoint: true,
        x: x + oneMillisecondXValue,
        y,
        data: {
          date: toStartOfDay(firstPointOfNextDay.data.date),
        },
      },
    ];
  };

export const generateLines = (points, startDate, endDate, totalTime) => {
  const range = calculateFullRange(startDate, endDate);
  const tenHoursXValue = (10 * HOURS_IN_MS) / range;
  const oneMillisecondXValue = tenHoursXValue / 36000000;
  const sortedPoints = sort(pointsXValueComparator, points);
  const pointsGroupedByDay = groupByDay(sortedPoints);

  const dayCrossoverPoints = pointsGroupedByDay.reduce(
    generateDayCrossoverPointsReducer(
      oneMillisecondXValue,
      tenHoursXValue,
      startDate,
      totalTime,
    ),
    [],
  );

  const sortedPointsWithDayCrossoverPointsAdded = sort(pointsXValueComparator, [
    ...sortedPoints,
    ...dayCrossoverPoints,
  ]);

  let previousX = 0;
  let previousIndex = 0;
  let lines = [];

  sortedPointsWithDayCrossoverPointsAdded.forEach(
    (point, index, originalArray) => {
      if (point.x - previousX > tenHoursXValue || point.crossOverPoint) {
        lines = append(originalArray.slice(previousIndex, index), lines);
        previousIndex = index;
      }

      if (index === originalArray.length - 1) {
        lines = append(originalArray.slice(previousIndex), lines);
      }

      previousX = point.x;
    },
  );

  return lines;
};

export const convertCarbohydratesMeasurementsToLines = (
  carbohydrates,
  startDate,
  totalTime,
) =>
  carbohydrates.map(({ date, carbohydrates }) => {
    const x = (date - startDate) / totalTime;
    return [
      { x, y: 0, date, value: carbohydrates },
      { x, y: carbohydrates / CARBOHYDRATES_Y_MAX, date, value: carbohydrates },
    ];
  });

export const convertBasalMeasurementsToLines = (measurements, yMax) => {
  const measurementsWithTbrFlag = measurements.map((measurement) => ({
    ...measurement,
    tbr: isTbrIncrease(measurement) || isTbrDecrease(measurement),
  }));

  const measurementsWithEndDates = addBasalEndDatesAndMidnightMeasurements(
    measurementsWithTbrFlag,
  );

  const reducedMeasurements = measurementsWithEndDates.reduce(
    (basalLines, measurement, index, originalArray) => {
      const previousMeasurement = originalArray[index - 1];
      const nextMeasurement = originalArray[index + 1];

      const x1 = measurement.x;
      const y1 = measurement.y;
      const x2 = nextMeasurement ? nextMeasurement.x : x1;

      const horizontalLine = [x1, x2].map((x) => ({
        ...measurement,
        x,
        y: y1,
        show: y1 > 0,
      }));

      const y2 = nextMeasurement
        ? nextMeasurement.y
        : y1 - getBasalVerticalPadding(y1, previousMeasurement, yMax);

      const verticalMeasurement =
        y1 > y2 || !nextMeasurement ? measurement : nextMeasurement;

      const verticalLine = [y1, y2].map((y) =>
        Object.assign({}, verticalMeasurement, {
          y,
          x: x2,
          show: true,
        }),
      );

      const firstMeasurement = index === 0 && [
        { ...measurement, x: 0, y: y1 },
        { ...measurement, x: x1, y: y1 },
      ];

      let lines = [horizontalLine];
      lines = !firstMeasurement ? lines : [firstMeasurement, ...lines];
      lines = !verticalLine ? lines : [...lines, verticalLine];

      return [...basalLines, ...lines];
    },
    [],
  );

  return reducedMeasurements;
};

const TBR_END = 'TBR End';
const TBR_END_CANCELLED = 'TBR End (cancelled)';
const ARBITRARY_TBR_Y_VALUE = 0.55;

const isTbrEnd = (basalRemark) => basalRemark === TBR_END;
const isTbrEndCancelled = (basalRemark) => basalRemark === TBR_END_CANCELLED;
const formatTbrEvent = ({ basalRemark, type, date }) => ({
  date,
  basalRemark,
  type,
});

const addTbrType = (basal, index, originalArray) => {
  let type = TBR_TYPE.NONE; // this should not be the final value
  const previousBasal = originalArray[index - 1];
  const { basalRemark, ...data } = basal;

  if (isTbrEndCancelled(basalRemark)) {
    if (isTbrIncrease(previousBasal)) {
      type = TBR_TYPE.END_INCREASE_CANCELLED;
    } else if (isTbrDecrease(previousBasal)) {
      type = TBR_TYPE.END_DECREASE_CANCELLED;
    }
  } else if (isTbrEnd(basalRemark) && !!previousBasal) {
    if (isTbrIncrease(previousBasal)) {
      type = TBR_TYPE.END_INCREASE;
    } else if (isTbrDecrease(previousBasal)) {
      type = TBR_TYPE.END_DECREASE;
    }
  } else if (isTbrIncrease(basal)) {
    type = TBR_TYPE.INCREASE;
  } else if (isTbrDecrease(basal)) {
    type = TBR_TYPE.DECREASE;
  }

  return { ...data, basalRemark, type };
};

const isSetback = (measurement) =>
  measurement.tsb ||
  (measurement.basalRemak &&
    measurement.basalRemak.match(/time\s*\/\s*date/gi));

const getSetbackTime = (tsbDiffMins) => {
  const hour = Math.floor(tsbDiffMins / 60);
  const min = tsbDiffMins % 60;
  const minsInTwoCharacters = min < 10 ? `0${min}` : min;
  return `${hour}:${minsInTwoCharacters}`;
};

const isTbr = ({ basalRemark, basalTbrinc, basalTbrdec }) =>
  isTbrEndCancelled(basalRemark) ||
  isTbrEnd(basalRemark) ||
  !!basalTbrinc ||
  !!basalTbrdec;

const createTbrLine =
  (totalTime, startDate) =>
  ({ date, type }) => {
    const x = (date - startDate) / totalTime;
    const y = ARBITRARY_TBR_Y_VALUE;
    const show = true;
    return [
      { x, y: 0, date, type, show },
      { x, y, date, type, show },
    ];
  };

const convertTbrLineToPoint = ([, { show, ...data }]) => ({ ...data });

export const filterTbrLines = (measurements, totalTime, startDate) =>
  measurements
    .filter(isTbr)
    .map(addTbrType)
    .map(formatTbrEvent)
    .map(createTbrLine(totalTime, startDate));

export const filterTbrPoints = (tbrLines) =>
  tbrLines.map(convertTbrLineToPoint);

export const filterTimeSetback = (measurements, totalTime, startDate) =>
  measurements.filter(isSetback).map(({ date, tsbDiffMins }) => ({
    x: (date - startDate) / totalTime,
    y: ARBITRARY_RATE_Y_VALUE,
    duration: tsbDiffMins ? getSetbackTime(tsbDiffMins) : null,
    date: date,
  }));

export const convertBasalRateChangeToPoint =
  (totalTime, startDate) => (measurement) => ({
    x: (measurement.date - startDate) / totalTime,
    y: ARBITRARY_RATE_Y_VALUE,
    profile: measurement.basalRemark.split('-')[1],
    date: measurement.date,
  });

export const convertBasalProfileChangeToPoint =
  (totalTime, startDate) => (measurement, index) => ({
    x: (measurement.date - startDate) / totalTime,
    y: ARBITRARY_PROFILE_Y_VALUE,
    profile: getBasalProfile(measurement, index),
    date: measurement.date,
    changeType: measurement.changeType,
  });

export const calculateInsulinDimensions =
  (graphYMax, startDate, totalTime) => (acc, measurement) => {
    const { bolusType, bolusRemark } = measurement;
    const { blackGraphInsulin, blueGraphInsulin, redGraphInsulin } = colors;
    const { ONE, TWO, THREE } = INSULIN_TYPE;
    const { STANDARD } = BOLUS_TYPE;

    const isGlucoseMeasurement = bolusType === undefined;
    const getBolusType = !isGlucoseMeasurement ? bolusType : STANDARD;
    const getDimensions = bolusTypeToParseFunctionMap[getBolusType];

    const insulinDimensions = [
      {
        color: redGraphInsulin,
        insulinType: ONE,
        bolusValue: getBolusType1Value(measurement),
        bolusRemark,
        getInsulinDimensionsFn: getDimensions,
      },
      {
        color: blueGraphInsulin,
        insulinType: TWO,
        bolusValue: getBolusType2Value(measurement),
        getInsulinDimensionsFn: parseStandardOrQuickBolus,
      },
      {
        color: blackGraphInsulin,
        insulinType: THREE,
        bolusValue: getBolusType3Value(measurement),
        getInsulinDimensionsFn: parseStandardOrQuickBolus,
      },
    ].map(getDimensionsMapper(totalTime, startDate, graphYMax, measurement));

    const dimensions = insulinDimensions
      .sort(sortInsulinBarsDescending)
      .filter(barsWithInvalidLineHeight);

    return dimensions.length === 0 ? acc : acc.concat(dimensions);
  };

export const crossoverDayBreakdown = (totalTime) => (acc, measurement) => {
  const { bolusType, date } = measurement;
  const { redGraphInsulin } = colors;
  const { QUICK, STANDARD } = BOLUS_TYPE;
  const { ONE } = INSULIN_TYPE;

  const isGlucose = bolusType === undefined;
  const doesNotNeedBreakdown =
    bolusType === QUICK || bolusType === STANDARD || isGlucose;

  // no need to breakdown for quick or standard bolus or glucose
  if (doesNotNeedBreakdown) {
    return [measurement, ...acc];
  }

  // const insulinOne = values.find(({ type }) => type === ONE);
  const dayInMs = hourToMs(24);
  const dayStartMs = toStartOfDay(date).valueOf();
  const dayEndMs = dayStartMs + dayInMs;
  const measurementDateInMS = date.valueOf();
  const extensionPeriodInMS = measurement.rectWidth * totalTime;
  const measurementEndMS = measurementDateInMS + extensionPeriodInMS;

  // breakdown rects if insulin intake ends in next day
  if (measurementEndMS > dayEndMs) {
    const nextDayRectWidth = (measurementEndMS - dayEndMs) / totalTime;
    const currentDayRectWidth = (dayEndMs - measurementDateInMS) / totalTime;

    const { lineHeight, rectHeight, x } = measurement;

    const currentMeasurement = {
      ...measurement,
      rectWidth: currentDayRectWidth,
    };

    const crossoverMeasurement = {
      ...measurement,
      x: x + currentDayRectWidth,
      rectWidth: nextDayRectWidth,
      lineHeight,
      color: redGraphInsulin,
      insulinType: ONE,
      rectHeight: rectHeight,
      crossoverDate: addDays(1)(date),
    };

    return [currentMeasurement, crossoverMeasurement, ...acc];
  } else {
    return [measurement, ...acc];
  }
};
