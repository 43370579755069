import {
  EditEMRPatientLoaderImpl,
  EditEMRPatientServiceImpl,
  EditEMRPatientTransformImpl,
} from './edit-emr-patient.service';

import { mockEditEMRPatientResponse } from './edit-emr-patient.mock';

const mockPoster = () => Promise.resolve(mockEditEMRPatientResponse);

export const EditEMRPatientFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPoster : EditEMRPatientLoaderImpl;
  return EditEMRPatientServiceImpl(loader, EditEMRPatientTransformImpl);
};
