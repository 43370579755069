import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

export const LoValueIcon = ({ width, height, fillColor = colors.black }) => {
  const originalWidth = 15;
  const originalHeight = 10;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;
  return (
    <SvgIcon
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      minY={-1}
    >
      <path
        d="M0.879766 9C0.455766 9 0.243766 8.788 0.243766 8.364V1.128C0.243766 0.704 0.451766 0.491999 0.867766 0.491999C1.28377 0.491999 1.49177 0.704 1.49177 1.128V7.932H5.21177C5.57977 7.932 5.76377 8.108 5.76377 8.46C5.76377 8.82 5.57977 9 5.21177 9H0.879766ZM10.0915 9.108C9.28355 9.108 8.58355 8.932 7.99155 8.58C7.39955 8.22 6.93955 7.716 6.61155 7.068C6.29155 6.412 6.13155 5.644 6.13155 4.764C6.13155 3.876 6.29155 3.108 6.61155 2.46C6.93955 1.812 7.39955 1.312 7.99155 0.96C8.58355 0.608 9.28355 0.431999 10.0915 0.431999C10.8915 0.431999 11.5875 0.608 12.1795 0.96C12.7795 1.312 13.2395 1.812 13.5595 2.46C13.8875 3.108 14.0515 3.872 14.0515 4.752C14.0515 5.64 13.8875 6.412 13.5595 7.068C13.2395 7.716 12.7795 8.22 12.1795 8.58C11.5875 8.932 10.8915 9.108 10.0915 9.108ZM10.0915 8.028C10.9315 8.028 11.5835 7.744 12.0475 7.176C12.5195 6.6 12.7555 5.796 12.7555 4.764C12.7555 3.732 12.5235 2.932 12.0595 2.364C11.5955 1.796 10.9395 1.512 10.0915 1.512C9.25155 1.512 8.59555 1.796 8.12355 2.364C7.65955 2.932 7.42755 3.732 7.42755 4.764C7.42755 5.796 7.65955 6.6 8.12355 7.176C8.59555 7.744 9.25155 8.028 10.0915 8.028Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
