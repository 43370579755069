import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

export const ComputerIconComponent = ({
  height = 120,
  fillColor = colors.grayMedium,
  t,
}) => {
  const originalWidth = 182;
  const originalHeight = 129;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <defs>
        <filter
          x="-40.3%"
          y="-1077.4%"
          width="180.6%"
          height="2254.9%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur
            stdDeviation="24.192 0"
            in="SourceGraphic"
          ></feGaussianBlur>
        </filter>
      </defs>
      <g
        id="1.-Assign-CGM-device"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="3.-Confirm-Connection-failed"
          transform="translate(-767, -658.000000)"
        >
          <g id="Main-card" transform="translate(75.927621, 192.711457)">
            <g id="Main-content" transform="translate(62.072379, 85.288543)">
              <g
                id="Computer-and-shadow"
                transform="translate(630.663326, 380.904145)"
              >
                <g id="Computer" stroke="#0066CC">
                  <path
                    d="M143.540027,1.2 C149.619125,1.2 151.840418,1.79054416 154.06737,2.9815313 C156.201822,4.12304836 157.876952,5.79817825 159.018469,7.93262957 C160.209456,10.1595819 160.8,12.3808752 160.8,18.4599734 L160.8,18.4599734 L160.8,79.5700133 C160.8,82.4396441 160.543628,83.4971069 159.980146,84.5507259 C159.466135,85.5118439 158.711844,86.2661349 157.750726,86.7801464 C156.697107,87.343628 155.639644,87.6 152.770013,87.6 L152.770013,87.6 L27.2299867,87.6 C24.3603559,87.6 23.3028931,87.343628 22.2492741,86.7801464 C21.2881561,86.2661349 20.5338651,85.5118439 20.0198536,84.5507259 C19.456372,83.4971069 19.2,82.4396441 19.2,79.5700133 L19.2,79.5700133 L19.2,18.4599734 C19.2,12.3808752 19.7905442,10.1595819 20.9815313,7.93262957 C22.1230484,5.79817825 23.7981782,4.12304836 25.9326296,2.9815313 C28.1595819,1.79054416 30.3808752,1.2 36.4599734,1.2 L36.4599734,1.2 Z"
                    id="Rectangle-3"
                    strokeWidth="2.4"
                    fill="#F7F9FD"
                  ></path>
                  <path
                    d="M139.570013,14.4 C142.439644,14.4 143.497107,14.656372 144.550726,15.2198536 C145.511844,15.7338651 146.266135,16.4881561 146.780146,17.4492741 C147.343628,18.5028931 147.6,19.5603559 147.6,22.4299867 L147.6,22.4299867 L147.6,66.3700133 C147.6,69.2396441 147.343628,70.2971069 146.780146,71.3507259 C146.266135,72.3118439 145.511844,73.0661349 144.550726,73.5801464 C143.497107,74.143628 142.439644,74.4 139.570013,74.4 L139.570013,74.4 L40.4299867,74.4 C37.5603559,74.4 36.5028931,74.143628 35.4492741,73.5801464 C34.4881561,73.0661349 33.7338651,72.3118439 33.2198536,71.3507259 C32.656372,70.2971069 32.4,69.2396441 32.4,66.3700133 L32.4,66.3700133 L32.4,22.4299867 C32.4,19.5603559 32.656372,18.5028931 33.2198536,17.4492741 C33.7338651,16.4881561 34.4881561,15.7338651 35.4492741,15.2198536 C36.5028931,14.656372 37.5603559,14.4 40.4299867,14.4 L40.4299867,14.4 Z"
                    id="Rectangle-3"
                    strokeWidth="2.4"
                    fill="#F7F9FD"
                  ></path>
                  <path
                    d="M172.8,87.6 C174.456854,87.6 175.956854,88.2715729 177.042641,89.3573593 C178.128427,90.4431458 178.8,91.9431458 178.8,93.6 L178.8,93.6 L178.8,98.4 C178.8,99.3941125 178.397056,100.294113 177.745584,100.945584 C177.094113,101.597056 176.194113,102 175.2,102 L175.2,102 L4.8,102 C3.80588745,102 2.90588745,101.597056 2.25441559,100.945584 C1.60294373,100.294113 1.2,99.3941125 1.2,98.4 L1.2,98.4 L1.2,93.6 C1.2,91.9431458 1.87157288,90.4431458 2.95735931,89.3573593 C4.04314575,88.2715729 5.54314575,87.6 7.2,87.6 L7.2,87.6 Z"
                    id="Rectangle-4"
                    strokeWidth="2.4"
                    fill="#F7F9FD"
                  ></path>
                  <path
                    d="M102,92.7 C102.579899,92.7 103.104899,92.9350505 103.484924,93.3150758 C103.864949,93.695101 104.1,94.220101 104.1,94.8 C104.1,95.379899 103.864949,95.904899 103.484924,96.2849242 C103.104899,96.6649495 102.579899,96.9 102,96.9 L102,96.9 L79.2,96.9 C78.620101,96.9 78.095101,96.6649495 77.7150758,96.2849242 C77.3350505,95.904899 77.1,95.379899 77.1,94.8 C77.1,94.220101 77.3350505,93.695101 77.7150758,93.3150758 C78.095101,92.9350505 78.620101,92.7 79.2,92.7 L79.2,92.7 L102,92.7 Z"
                    id="Rectangle-6"
                    strokeWidth="1.8"
                    fill="#FFFFFF"
                  ></path>
                </g>
                <path
                  d="M1.54645856,121.343066 L181.546459,121.343066 C181.546459,125.063234 178.530667,128.079026 174.810499,128.079026 L8.28241856,128.079026 C4.56225058,128.079026 1.54645856,125.063234 1.54645856,121.343066 L1.54645856,121.343066 L1.54645856,121.343066 Z"
                  id="Rectangle-5-Copy-5"
                  fill="#E0E8F8"
                  filter="url(#filter-1)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const ComputerIcon = withTranslation(ComputerIconComponent);
