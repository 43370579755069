import { createRequestActionTypes } from 'src/core/request/request.actions';

export const FETCH_PERMISSIONS_BASE = 'PERMISSIONS';
export const FETCH_PERMISSIONS = createRequestActionTypes(
  FETCH_PERMISSIONS_BASE,
);
export const FETCH_PATIENT_PERMISSIONS_BASE = 'PATIENT_PERMISSIONS';
export const FETCH_PATIENT_PERMISSIONS = createRequestActionTypes(
  FETCH_PATIENT_PERMISSIONS_BASE,
);

export const PATIENT_PERMISSIONS = {
  PATIENT: 'ROLE_PATIENT',
  DATA_DOWNLOAD: 'ROLE_DATA_DOWNLOAD',
  GLUCOSE_STATISTICS: 'ROLE_EXPORT_GLUCOSE_STATISTICS',
  STRIP_MNG_PATIENT: 'ROLE_STRIP_MNG_PATIENT',
  HOME_DELIVERY_PATIENT: 'ROLE_DELIVERY_MGMT_PATIENT',
  ELECTRONIC_MEDICAL_RECORD: 'ROLE_ELECTRONIC_MEDICAL_RECORD',
  INTERVENTIONAL_STUDY: 'ROLE_INTERVENTIONAL_STUDY',
  BLINDED_STUDY: 'ROLE_BLINDED_STUDY',
};

export const PERMISSIONS = {
  PROFESSIONAL: 'ROLE_PROFESSIONAL',
  ELECTRONIC_MEDICAL_RECORD: 'ROLE_ELECTRONIC_MEDICAL_RECORD',

  // data download
  DATA_DOWNLOAD: 'ROLE_DATA_DOWNLOAD',
  DATA_DOWNLOAD_ASSIGNMENT: 'ROLE_DATA_DOWNLOAD_ASSIGNMENT',

  // devices
  PATIENT_DEVICES: 'ROLE_VIEW_PATIENT_DEVICES',
  DEACTIVATE_PATIENT_DEVICES: 'ROLE_DEACTIVATE_PATIENT_DEVICES',
  HISTORICAL_DEVICE_DATA_LIST: 'ROLE_PATIENT_HISTORICAL_DEVICE_DATA_LIST',

  // time blocks
  TIME_BLOCKS_MANAGEMENT: 'ROLE_TIME_BLOCKS_MANAGEMENT',

  // professional management
  CREATE_PROFESSIONAL: 'ROLE_PROFESSIONAL_MANAGEMENT_CREATE',
  DELETE_PROFESSIONAL: 'ROLE_PROFESSIONAL_MANAGEMENT_DELETE',
  EDIT_PROFESSIONAL: 'ROLE_PROFESSIONAL_MANAGEMENT_EDIT',
  EDIT_PROFESSIONAL_EMR: 'ROLE_PROFESSIONAL_MANAGEMENT_EDIT_EMR',
  EDIT_ALL_PROFESSIONAL: 'ROLE_PROFESSIONAL_MANAGEMENT_EDIT_ALL',
  PROFESSIONAL_LIST: 'ROLE_PROFESSIONAL_MANAGEMENT_LIST',

  // user management
  USER_MANAGEMENT_RESET_PASSWORD: 'ROLE_USER_MANAGEMENT_RESET_PASSWORD',
  USER_MANAGEMENT_REACTIVATE: 'ROLE_USER_MANAGEMENT_REACTIVATE',

  // user settings
  USER_SETTINGS_GRAPH: 'ROLE_USER_SETTINGS_GRAPH',
  USER_SETTINGS_OVERVIEW: 'ROLE_USER_SETTINGS_OVERVIEW',
  USER_SETTINGS_REPORT: 'ROLE_USER_SETTINGS_REPORT',
  USER_SETTINGS_CHANGE_PASSWORD: 'ROLE_USER_SETTINGS_CHANGE_PASSWORD',
  USER_SETTINGS_EXPORT_DATA: 'ROLE_USER_SETTINGS_EXPORT_DATA',
  USER_SETTINGS_CONFIGURE_PATTERN: 'ROLE_USER_SETTINGS_CONFIGURE_PATTERN',
  USER_SETTINGS_CHANGE_SECURITY_QUESTION:
    'USER_SETTINGS_CHANGE_SECURITY_QUESTION',
  USER_SHOW_GRAPHIC_CONFIG: 'ROLE_USER_SHOW_GRAPHIC_CONFIGURATION',
  USER_SHOW_LOGBOOK_CHART: 'ROLE_USER_SHOW_LOGBOOK_CHART',

  // strip management
  STRIP_TRAFFIC_LIGHT: 'ROLE_STRIP_MNG_VIEW_TRAFFIC_LIGHT',
  STRIP_DELIVERY: 'ROLE_STRIP_MNG_DELIVERY',
  STRIP_PRESCRIPTIONS: 'ROLE_STRIP_MNG_STRIP_PRESCRIPTION',
  STRIP_FORCE_STATUS: 'ROLE_STRIP_MNG_FORCE_TRAFFIC_LIGHT',
  STRIP_STOCK: 'ROLE_STRIP_MNG_STOCK',
  STRIP_PATIENT_ALERTS: 'ROLE_STRIP_MNG_ALERTS_CONFIG',
  STRIP_MNG_PROFESSIONAL: 'ROLE_STRIP_MNG_PROFESSIONAL',

  // patient management
  REGISTER_PATIENT: 'ROLE_PATIENT_MANAGEMENT_CREATE',
  PATIENT_LIST: 'ROLE_PATIENT_MANAGEMENT_LIST',
  EDIT_PATIENT: 'ROLE_PATIENT_MANAGEMENT_EDIT',
  EDIT_PATIENT_EMR: 'ROLE_PATIENT_MANAGEMENT_EDIT_EMR',
  PATIENT_MANAGEMENT_DELETE: 'ROLE_PATIENT_MANAGEMENT_DELETE',
  PATIENT_DELIVERY_PROGRAM_CONFIG: 'ROLE_PATIENT_DELIVERY_PROGRAM_CONFIG',
  NEXT_SHIPMENT: 'ROLE_NEXT_SHIPMENT',
  MANAGEMENT_HOME: 'ROLE_PATIENT_MANAGEMENT_HOME',

  // treatment
  TREATMENT_LIST: 'ROLE_TREATMENT_LIST',
  CREATE_TREATMENT: 'ROLE_TREATMENT_CREATE',
  EDIT_TREATMENT: 'ROLE_TREATMENT_EDIT',
  COPY_TREATMENT: 'ROLE_TREATMENT_COPY',
  VIEW_TREATMENT: 'ROLE_TREATMENT_VIEW',
  TREATMENT_GRAPH: 'ROLE_TREATMENT_GRAPH',

  // home delivery
  DELIVERY_PROFESSIONAL: 'ROLE_DELIVERY_MGMT_PROFESSIONAL',

  // therapy
  ADD_THERAPY: 'ROLE_THERAPY_ADD',
  REMOVE_THERAPY: 'ROLE_THERAPY_REMOVE',

  // clinical data
  RELEVANT_CLINICAL_DATA: 'ROLE_RELEVANT_CLINICAL_DATA',

  // REMAPPED PERMISSIONS FOR SEMANTIC VALUE
  PATIENT_DIAGNOSTICS: 'ROLE_USER_SHOW_GRAPHIC_CONFIGURATION',

  // Patterns and Indicators
  ADVANCED_INDICATORS: 'ROLE_CONFIG_PATTERNS_ADVANCED_INDICATORS',

  //open connectivity
  OPEN_CONNECTIVITY_DATA_DOWN: 'ROLE_OPEN_CONNECTIVITY_DATA_DOWN',

  //Etapes
  ETAPES_PROGRAM: 'ROLE_ETAPES_PROGRAM',

  //RMP
  RPM_PROGRAM: 'ROLE_RPM_PROGRAM',
  RPM_PROGRAM_PROF: 'ROLE_RPM_PROGRAM_PROF',
  RPM_SETTINGS_MANAGEMENT: 'ROLE_RPM_SETTINGS_MANAGEMENT',

  //Visit Management
  VISIT_MANAGEMENT: 'ROLE_VISIT_MANAGEMENT',

  //MySugr
  MYSUGR_INVITATION_PAT: 'ROLE_MYSUGR_INVITATION_PAT',

  // Time Tracking
  ROLE_TIME_TRACKING: 'ROLE_TIME_TRACKING',

  // Therapy
  THERAPY: 'ROLE_THERAPY',

  ROLE_COUNTRY_FR: 'ROLE_COUNTRY_FR',
  // notification
  NOTIFICATION_CENTER_PERMISSION: 'ROLE_NOTIFICATION_CENTER',

  // PAYERS
  PAYER: 'ROLE_PAYER',
  PAYER_DASHBOARD: 'ROLE_PAYER_DASHBOARD',

  // NEW DEVICE LINK
  ROLE_DEVICE_LINK: 'ROLE_DEVICE_LINK',

  // FHIR
  ROLE_FHIR: 'ROLE_FHIR',

  // PLATFORM
  ROLE_PLATFORM: 'ROLE_PLATFORM',

  // JELLO_DS
  ROLE_JELLO: 'ROLE_JELLO_DS',

  // INDIVIDUAL USER FROM C2A
  C2A_INDIVIDUAL: 'ROLE_C2A_INDIVIDUAL',

  // BRAND_ACCUCHEK
  BRAND_ACCUCHEK: 'ROLE_BRAND_ACCUCHEK',

  // CLINICAL STUDY
  CLINICAL_STUDY: 'ROLE_CLINICAL_STUDY',
};
