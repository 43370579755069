import React from 'react';
import { isEmpty, isNil } from 'ramda';

import { ResizeWrapper } from 'src/components/resize-wrapper/resize-wrapper.component';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils/measurements';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls/graph-controls.component';
import { AdditionalInformationButtonContainer as AdditionalInformationButton } from 'src/domains/diagnostics/components/additional-information/additional-information.container';
import {
  logbookDisclaimers,
  logbookDisclaimersLastUpdated,
} from 'src/domains/diagnostics/components/additional-information/additional-information.constant';
import { LogbookWrapper } from 'src/domains/diagnostics/widgets/logbook/ui/wrapper/logbook-wrapper.component';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  NO_ENOUGH_DATA_OBJECT,
  NO_DATA_AVAILABLE_OBJECT,
} from 'src/components/no-data-tooltip/no-data-tooltip.constants';
import { ToolTip } from 'src/components/tool-tip/tool-tip.component';
import {
  NoDataToolTipTextContainer,
  NoDataToolTipText,
} from 'src/components/no-data-tooltip/no-data-tooltip.style.ts';
import { ToolTipNoDataDashboardComponent } from 'src/components/tooltip-no-data/tooltip-no-data.component.tsx';

import { BloodGlucoseCellWrapper } from './blood-glucose-cell-wrapper.style';
import { StripedColumn } from './column.style';
import { Cell } from './cell.style';
import { DayCellWrapper } from './day-cell-wrapper.style';
import { DayColumn } from './day-column.style';
import { LogbookStatsTableHeader } from './logbook-stats-table-header.component';
import { ResponsiveVirtualizedList } from './responsive-virtualized-list.component';
import { Row } from './row.style';

import TooltipPreventRender from '../../components/tooltip-prevent-render/tooltip-prevent-render.component';
import {
  LogbookGraphWrapper,
  LogbookGraphWrapperDiv,
} from '../../widgets/logbook/ui/style/logbook.style';
import {
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
  LOGBOOK_LOGBOOK_MIN_WIDTH,
} from '../../scenes/graphs/graph.constants';
import { GraphWrapperDiv } from '../../scenes/graphs/graph.style';
import { additionalInfoActivated } from 'src/domains/diagnostics/components/additional-info/additional.info.utils';
import { getSelectedRowId } from 'src/domains/diagnostics/widgets/logbook/graph/logbook.graph-utils';
import { AsteriskIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';
const LogbookGraphWrapperDivWithLoader = withGraphLoader(
  LogbookGraphWrapperDiv,
  'graphs.logbook.loading',
);

const MIN_TOOLTIP_WIDTH = 180;

const isValidToolTip = (toolTip) =>
  !isNil(toolTip.x) && !isNil(toolTip.y) && !isEmpty(toolTip.data);

const toolTipRenderer = (toolTip) => {
  const { x, y, data, toolTipWidth } = toolTip;
  const { toolTipTitle, toolTipDescription } = data;
  return (
    <ToolTip x={x} y={y}>
      <ToolTipNoDataDashboardComponent
        title={toolTipTitle}
        description={toolTipDescription}
        origin={`${toolTipWidth ? 'logbook-stats lastCell' : 'logbook-stats'}`}
        className={'tooltip-nodata-dashboard'}
      />
    </ToolTip>
  );
};

const iconsRenderer = (
  typeOfInsuffiency,
  showToolTip,
  hideToolTip,
  minToolTipWidth,
) => (
  <NoDataToolTipTextContainer>
    <NoDataToolTipText
      className={'no-data-tool-tip-text'}
      onMouseOver={(e) =>
        showToolTip(
          { ...e, pageX: e.pageX - 140, pageY: e.pageY - 160 },
          typeOfInsuffiency,
          minToolTipWidth,
        )
      }
      onMouseLeave={hideToolTip}
    >
      {typeOfInsuffiency.text}
    </NoDataToolTipText>
  </NoDataToolTipTextContainer>
);

export const LogbookStats = ({
  bloodGlucoseUnit,
  logbookStatsData,
  match,
  isLoading,
  showToolTip,
  hideToolTip,
  toolTip,
  t,
  graph,
  carbUnit,
}) => {
  const selectedRowId = getSelectedRowId(match, logbookStatsData);
  const rowRenderer = (index) => {
    const {
      columns: [
        day,
        numberOfTests,
        meanBloodGlucose,
        standardDeviation,
        hypos,
        carbohydrates,
        insulin,
        basal,
        bolus,
        numberOfBoluses,
        basalBolusPercentage,
      ],
      hasBasal,
      hasBolus,
      hasData,
      isNotSufficiencyData,
      hasHIorLOValues,
      hasLOValues,
    } = logbookStatsData[index];

    const formattedCarbohydrates =
      carbohydrates !== '' ? carbohydrates.toFixed(1) : carbohydrates;

    return (
      <React.Fragment>
        <TooltipPreventRender toolTip={toolTip}>
          <Row>
            <DayColumn highlight={selectedRowId === index}>
              <DayCellWrapper label={day.label}>
                <Cell textAlign="left">
                  <div>{day.value[0]}</div>
                  <div>{day.value[1]}</div>
                </Cell>
              </DayCellWrapper>
            </DayColumn>
            <StripedColumn>
              <Cell>
                {numberOfTests}
                <RenderIf validate={hasHIorLOValues}>
                  <AsteriskIcon height={15} iconColor={colors.redLegend} />
                </RenderIf>
              </Cell>
            </StripedColumn>
            <StripedColumn>
              <BloodGlucoseCellWrapper label={meanBloodGlucose.label}>
                <Cell>
                  {formatBGMeasurement(bloodGlucoseUnit)(
                    meanBloodGlucose.value,
                  )}
                </Cell>
              </BloodGlucoseCellWrapper>
            </StripedColumn>
            <StripedColumn>
              <Cell>
                {formatBGMeasurement(bloodGlucoseUnit)(standardDeviation)}
              </Cell>
            </StripedColumn>
            <StripedColumn>
              <Cell>
                {hypos}
                <RenderIf validate={hasLOValues}>
                  <AsteriskIcon height={15} iconColor={colors.redLegend} />
                </RenderIf>
              </Cell>
            </StripedColumn>
            <StripedColumn flex={2} pl={3} pr={3}>
              <Cell>{formattedCarbohydrates}</Cell>
            </StripedColumn>
            <StripedColumn>
              <Cell>
                {insulin
                  ? isNotSufficiencyData
                    ? iconsRenderer(
                        NO_ENOUGH_DATA_OBJECT,
                        showToolTip,
                        hideToolTip,
                      )
                    : insulin
                  : insulin}
              </Cell>
            </StripedColumn>
            <StripedColumn>
              <Cell>
                {hasBasal
                  ? basal
                  : hasBolus
                  ? iconsRenderer(
                      NO_DATA_AVAILABLE_OBJECT,
                      showToolTip,
                      hideToolTip,
                    )
                  : basal}
              </Cell>
            </StripedColumn>
            <StripedColumn>
              <Cell>
                {hasBolus
                  ? bolus
                  : hasBasal
                  ? iconsRenderer(
                      NO_DATA_AVAILABLE_OBJECT,
                      showToolTip,
                      hideToolTip,
                    )
                  : bolus}
              </Cell>
            </StripedColumn>
            <StripedColumn>
              <Cell>
                {hasBolus
                  ? numberOfBoluses
                  : hasBasal
                  ? iconsRenderer(
                      NO_DATA_AVAILABLE_OBJECT,
                      showToolTip,
                      hideToolTip,
                    )
                  : numberOfBoluses}
              </Cell>
            </StripedColumn>
            <StripedColumn>
              <RenderIf validate={basalBolusPercentage}>
                <Cell>{basalBolusPercentage}</Cell>
              </RenderIf>
              <RenderIf validate={!basalBolusPercentage && hasData}>
                <Cell>
                  {iconsRenderer(
                    NO_ENOUGH_DATA_OBJECT,
                    showToolTip,
                    hideToolTip,
                    MIN_TOOLTIP_WIDTH,
                  )}
                </Cell>
              </RenderIf>
            </StripedColumn>
          </Row>
        </TooltipPreventRender>
        <RenderIf validate={isValidToolTip(toolTip)}>
          {toolTipRenderer(toolTip)}
        </RenderIf>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <RenderIf validate={logbookStatsData.length !== 0}>
        <GraphControls
          showChangeGraphToggle
          graphType={GRAPHS.LOGBOOK_STATS}
          graph={graph}
        >
          {!additionalInfoActivated() && (
            <AdditionalInformationButton
              content={logbookDisclaimers}
              updated={logbookDisclaimersLastUpdated}
            />
          )}
        </GraphControls>
      </RenderIf>
      <LogbookWrapper blueBackground={!isEmpty(logbookStatsData) && !isLoading}>
        <LogbookGraphWrapperDivWithLoader
          hasError={isEmpty(logbookStatsData) && !isLoading}
          isLoading={isLoading}
        >
          <LogbookStatsTableHeader
            bloodGlucoseUnit={bloodGlucoseUnit}
            carbUnit={carbUnit}
          />
          <GraphWrapperDiv minWidth={LOGBOOK_LOGBOOK_MIN_WIDTH}>
            <ResizeWrapper
              minHeight={MIN_EXPANDED_STD_GRAPH_HEIGHT}
              render={(height) => (
                <LogbookGraphWrapper tableHeight={height}>
                  <ResponsiveVirtualizedList
                    rowCount={logbookStatsData.length}
                    rowHeight={68}
                    rowRenderer={rowRenderer}
                    scrollToIndex={selectedRowId}
                  />
                </LogbookGraphWrapper>
              )}
              resizeFunction={(clientHeight) => clientHeight}
            />
          </GraphWrapperDiv>
        </LogbookGraphWrapperDivWithLoader>
      </LogbookWrapper>
    </React.Fragment>
  );
};
