import {
  MysugrStatusLoaderImpl,
  MysugrStatusServiceImpl,
  MysugrStatusTransformImpl,
} from './mysugr-status.service';
import { MysugrStatusValues } from './mysugr-status.types';

const mockResponse = {
  status: MysugrStatusValues.ACTIVE,
  lastSync: 1566225099131,
};

const mockLoader = (): Promise<any> => Promise.resolve(mockResponse);

export const MysugrStatusFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : MysugrStatusLoaderImpl;
  return MysugrStatusServiceImpl(loader, MysugrStatusTransformImpl);
};
