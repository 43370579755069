import {
  CheckPairingCodeActions,
  CheckPairingCodeActionType,
  CheckPairingCodeState,
} from './check-pairing-code.types';

export const checkPairingCodeInitialState: CheckPairingCodeState = {
  isCheckingPairingCode: false,
  checkWasSuccessful: false,
  checkHasError: false,
  isPopulationConfirmed: false,
  isPopulationCleared: false,
  error: null,
  patientData: {},
};

export const checkPairingCodeReducer = (
  state = checkPairingCodeInitialState,
  action: CheckPairingCodeActions,
) => {
  switch (action.type) {
    case CheckPairingCodeActionType.CHECK_PAIRING_CODE_START:
      return {
        ...state,
        isCheckingPairingCode: true,
        checkWasSuccessful: false,
        checkHasError: false,
        isPopulationConfirmed: false,
        isPopulationCleared: false,
      };
    case CheckPairingCodeActionType.CHECK_PAIRING_CODE_SUCCESS:
      return {
        ...state,
        isCheckingPairingCode: false,
        checkWasSuccessful: true,
        checkHasError: false,
        isPopulationConfirmed: false,
        isPopulationCleared: false,
        patientData: action.payload,
      };
    case CheckPairingCodeActionType.CHECK_PAIRING_CODE_ERROR:
      return {
        ...state,
        isCheckingPairingCode: false,
        checkWasSuccessful: false,
        checkHasError: true,
        isPopulationConfirmed: false,
        isPopulationCleared: false,
        error: action.payload,
      };
    case CheckPairingCodeActionType.CONFIRM_POPULATE_FORM:
      return {
        ...state,
        isCheckingPairingCode: false,
        checkWasSuccessful: false,
        checkHasError: false,
        isPopulationConfirmed: true,
        isPopulationCleared: false,
      };
    case CheckPairingCodeActionType.CLEAR_PAIRING_CODE_DATA:
      return {
        ...state,
        isCheckingPairingCode: false,
        checkWasSuccessful: false,
        checkHasError: false,
        isPopulationConfirmed: false,
        isPopulationCleared: true,
      };
    default:
      return state;
  }
};
