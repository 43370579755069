export const mockStripModels = {
  resultDescription: 'getThresholdOK',
  model: [
    {
      stripModelName: 'Accu-Chek Active',
      stripModelReference: '07124112036',
    },
    {
      stripModelName: 'Accu-Chek Aviva',
      stripModelReference: '06453970037',
    },
  ],
};
