export enum AlertType {
  SUCCESS = 'ALERT_SUCCESS',
  ERROR = 'ALERT_ERROR',
}

export type AlertText = {
  [key in AlertType]?: any;
};

export type AlertItem = {
  id?: string;
  text: AlertText;
  type: AlertType;
  position?: string | null;
};

export type AddAlertActionProps = AlertItem;
