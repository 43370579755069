import { ensureNever } from 'src/utils/typescript';
import { setIn } from 'src/utils/ramda/ramda.utils';

import {
  RpmSettingsActionType,
  RpmSettingsActions,
  RpmSettingsState,
} from './rpm-settings.types';

export const initialRpmSettingsState: RpmSettingsState = {
  isLoading: true,
  rpmSettings: {
    programName: '',
    programDurationNumber: '',
    programComplianceReminder: '',
    programComplianceCheck: '',
    programDurationType: '',
    programComplianceType: '',
  },
  error: '',
  updateHasError: false,
  updateWasSuccessful: false,
  isUpdatingSettings: false,
};

export const rpmSettingsReducer = (
  state = initialRpmSettingsState,
  action: RpmSettingsActions,
): RpmSettingsState => {
  switch (action.type) {
    case RpmSettingsActionType.UPDATE_RPMSETTINGS_START:
      return {
        ...state,
        updateHasError: false,
        updateWasSuccessful: false,
        isUpdatingSettings: true,
      };
    case RpmSettingsActionType.UPDATE_RPMSETTINGS_SUCCESS:
      return {
        ...state,
        updateHasError: false,
        updateWasSuccessful: true,
        isUpdatingSettings: false,
      };
    case RpmSettingsActionType.UPDATE_RPMSETTINGS_ERROR:
      return {
        ...state,
        updateHasError: true,
        updateWasSuccessful: false,
        isUpdatingSettings: false,
      };
    case RpmSettingsActionType.FETCH_RPMSETTINGS_START:
      return {
        ...state,
        isLoading: true,
      };
    case RpmSettingsActionType.FETCH_RPMSETTINGS_SUCCESS:
      return {
        ...state,
        rpmSettings: action.payload,
        isLoading: false,
      };
    case RpmSettingsActionType.FETCH_RPMSETTINGS_ERROR:
      return setIn(['error'], action.payload, state);
    default:
      ensureNever(action);
      return state;
  }
};
