import * as React from 'react';

import NotificationContainer from 'src/modules/notification-module';
import { NotificationModuleWrapper } from 'src/widgets/top-navigation/icons-container/icons-container.style';

export const NotificationsCenterComponent = () => {
  return (
    <NotificationModuleWrapper>
      <NotificationContainer />
    </NotificationModuleWrapper>
  );
};
