import styled from 'styled-components';

import { spacing } from 'src/core/styles/spacing';
import { COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

import {
  MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM,
  COLLAPSED_STD_GRAPH_HEIGHT_IN_REM,
} from '../../graph.constants';

export const StandardDayPlotWrapperDiv = styled.div`
  position: relative;
  height: ${(props) =>
    props.collapsed
      ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM
      : props.collapsedGP
      ? COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM
      : 'inherit'};
  min-height: ${(props) =>
    props.collapsed
      ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM
      : props.collapsedGP
      ? COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM
      : MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM};
  margin: ${spacing.three} ${spacing.two} 0;
  display: flex;
  flex-direction: column;
`;

StandardDayPlotWrapperDiv.displayName = 'StandardDayPlotWrapperDiv';
