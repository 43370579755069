import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { FaviconComponent } from './favicon.component';

import { selectHasRebranding } from 'src/core/permissions/permissions.selectors';

type FaviconProps = {
  rebranding: boolean;
};

const mapStateToProps = (state) => ({
  rebranding: selectHasRebranding(state),
});

export const Favicon = compose<FaviconProps, {}>(
  connect(mapStateToProps),
  withRouter,
)(FaviconComponent);
