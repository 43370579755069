import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';

import {
  PumpPauseIcon,
  PumpRunIcon,
  PumpStopIcon,
  PumpTbrdecIcon,
  PumpTbrEndDecIcon,
  PumpTbrincIcon,
  PumpTbrEndIncIcon,
} from 'src/assets/icons';

import { withHoverableStyle } from './point-shapes.util';

const X_LENGTH = 0.006;
const SQUARE_LENGTH = 0.007;
const RADIUS = 0.003;
const BORDER = 1;
const R = 1;
const TRIANGLE_HYPOTENUSE = 0.006;

const MAX_TRIANGLE_WIDTH = 10;
const MIN_TRIANGLE_WIDTH = 5;

const calculateTrianglePoints = (x, y, width) => {
  width = width > MAX_TRIANGLE_WIDTH ? MAX_TRIANGLE_WIDTH : width;
  width = width < MIN_TRIANGLE_WIDTH ? MIN_TRIANGLE_WIDTH : width;
  const hypotenuse = width;
  const opposite = hypotenuse * Math.sin(Math.PI / 3);
  const adjacent = hypotenuse * Math.cos(Math.PI / 3);

  return {
    x1: x,
    y1: y,
    x2: x - opposite,
    y2: y + adjacent + hypotenuse,
    x3: x + opposite,
    y3: y + adjacent + hypotenuse,
  };
};

export const XShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    width,
    strokeColor = theme.colors.black,
    opacity = 1,
    onClick = () => null,
    onMouseMove = () => null,
    onMouseOut = () => null,
    cursor,
  }) => (
    <g>
      <line
        x1={x - width * X_LENGTH}
        x2={x + width * X_LENGTH}
        y1={y}
        y2={y}
        transform={`rotate(45 ${x}, ${y})`}
        strokeWidth={theme.strokeWidth.two}
        stroke={strokeColor}
        opacity={opacity}
      />
      <line
        x1={x - width * X_LENGTH}
        x2={x + width * X_LENGTH}
        y1={y}
        y2={y}
        transform={`rotate(-45 ${x}, ${y})`}
        strokeWidth={theme.strokeWidth.two}
        stroke={strokeColor}
        opacity={opacity}
      />
      <rect
        x={x - width * X_LENGTH * 0.8}
        y={y - width * X_LENGTH * 0.8}
        width={width * X_LENGTH * 1.6}
        height={width * X_LENGTH * 1.6}
        opacity={0}
        {...{
          onClick,
          onMouseMove,
          onMouseOut,
          cursor,
        }}
      />
    </g>
  ),
);

export const SquareShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    width,
    strokeColor = theme.colors.black,
    fillColor = theme.colors.white,
    opacity = 1,
    onClick = () => null,
    onMouseMove = () => null,
    onMouseOut = () => null,
    cursor,
  }) => (
    <g>
      <rect
        x={x - (width * SQUARE_LENGTH) / 2}
        y={y - (width * SQUARE_LENGTH) / 2}
        width={width * SQUARE_LENGTH}
        height={width * SQUARE_LENGTH}
        strokeWidth={theme.strokeWidth.two}
        {...{
          stroke: strokeColor,
          fill: fillColor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
          cursor,
        }}
      />
    </g>
  ),
);

export const CircleShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    width,
    fillColor = theme.colors.black,
    opacity = 1,
    onClick = () => null,
    onMouseMove = () => null,
    onMouseOut = () => null,
    stroke,
    strokeWidth = 0,
    r,
  }) => (
    <circle
      cx={x}
      cy={y}
      r={r ? r * R : width * RADIUS}
      stroke={stroke}
      strokeWidth={strokeWidth * BORDER}
      {...{
        fill: fillColor,
        opacity,
        onClick,
        onMouseMove,
        onMouseOut,
      }}
    />
  ),
);

export const TriangleShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    width,
    widthAdjustment = TRIANGLE_HYPOTENUSE,
    fillColor,
    opacity = 1,
    onClick = () => null,
    onMouseMove = () => null,
    onMouseOut = () => null,
    cursor,
  }) => {
    const triangle = calculateTrianglePoints(x, y, width * widthAdjustment);
    return (
      <polygon
        points={`
        ${triangle.x1},${triangle.y1}
        ${triangle.x2},${triangle.y2}
        ${triangle.x3},${triangle.y3}
      `}
        {...{
          cursor,
          fill: fillColor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
        }}
      />
    );
  },
);

const getSquareIconSideLength = (x, width) => x - (width * SQUARE_LENGTH) / 2;
const getSquareIconWidth = (theme, width) =>
  width * SQUARE_LENGTH + theme.strokeWidth.two;
const getSquareIconDimensions = (theme, x, y, width) => ({
  x: getSquareIconSideLength(x, width) - theme.strokeWidth.two / 2,
  y: getSquareIconSideLength(y, width),
  width: getSquareIconWidth(theme, width),
  height: getSquareIconWidth(theme, width),
});

export const PumpPauseShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    width,
    opacity,
    onMouseMove,
    onMouseOut,
    onClick,
    cursor,
  }) => (
    <PumpPauseIcon
      {...getSquareIconDimensions(theme, x, y, width)}
      {...{ onMouseMove, onMouseOut, opacity, onClick, cursor }}
    />
  ),
);

export const PumpRunShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    width,
    opacity,
    onMouseMove,
    onMouseOut,
    onClick,
    cursor,
  }) => (
    <PumpRunIcon
      {...getSquareIconDimensions(theme, x, y, width)}
      {...{ onMouseMove, onMouseOut, opacity, onClick, cursor }}
    />
  ),
);

export const PumpStopShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    width,
    opacity,
    onMouseMove,
    onMouseOut,
    onClick,
    cursor,
  }) => (
    <PumpStopIcon
      {...getSquareIconDimensions(theme, x, y, width)}
      {...{ onMouseMove, onMouseOut, opacity, onClick, cursor }}
    />
  ),
);

export const BasalRateChangeShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    profile,
    opacity,
    onMouseMove = () => null,
    onMouseOut = () => null,
    onClick = () => null,
    cursor,
  }) => (
    <Fragment>
      <rect
        x={x}
        y={y}
        fill={theme.colors.tbrBlue}
        width={profile?.length * 5 + 20}
        height="16"
        {...{
          cursor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
        }}
      />
      <text
        x={x + 15}
        y={y + 1}
        dy={11}
        fontSize="12"
        fontWeight="bold"
        fill={theme.colors.white}
        {...{
          cursor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
        }}
      >
        {profile}
      </text>
      <path
        transform={`translate(${x}, ${y})`}
        fill={theme.colors.white}
        stroke={theme.colors.clear}
        {...{
          cursor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
        }}
        d="M5.37794841,7.0816244 C5.53994841,7.0816244 5.69994841,7.0166244 5.81694841,6.8876244 L7.99594841,4.4896244 L10.1829484,6.8886244 C10.4029484,7.1296244 10.7789484,7.1476244 11.0219484,6.9276244 C11.2639484,6.7056244 11.2819484,6.3306244 11.0609484,6.0876244 L7.99494841,2.7246244 L4.93894841,6.0886244 C4.71794841,6.3316244 4.73594841,6.7066244 4.97894841,6.9276244 C5.09194841,7.0306244 5.23494841,7.0816244 5.37794841,7.0816244"
      />
      <path
        transform={`translate(${x}, ${y})`}
        fill={theme.colors.white}
        stroke={theme.colors.clear}
        {...{
          cursor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
        }}
        d="M8.00485049,12.9986244 L11.0618505,9.6346244 C11.2818505,9.3916244 11.2638505,9.0166244 11.0218505,8.7956244 C10.7788505,8.5756244 10.4038505,8.5936244 10.1828505,8.8356244 L8.00385049,11.2326244 L5.81685049,8.8346244 C5.59585049,8.5936244 5.22085049,8.5756244 4.97785049,8.7956244 C4.73585049,9.0176244 4.71785049,9.3926244 4.93985049,9.6356244 L8.00485049,12.9986244 Z"
      />
      <line
        x1={x}
        y1={y}
        x2={x}
        y2={0}
        strokeWidth={theme.strokeWidth.two}
        stroke={theme.colors.tbrBlue}
        {...{ opacity }}
      />
    </Fragment>
  ),
);

export const ProfileChangeShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    profile,
    opacity,
    onMouseMove = () => null,
    onMouseOut = () => null,
    onClick = () => null,
    cursor,
  }) => (
    <Fragment>
      <rect
        x={x}
        y={y}
        fill={theme.colors.tbrBlue}
        width={profile?.length * 5 + 20}
        height="15"
        {...{
          cursor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
        }}
      />
      <text
        x={x + 15}
        y={y}
        dy={11}
        fontSize="12"
        fontWeight="bold"
        fill={theme.colors.white}
        {...{
          cursor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
        }}
      >
        {profile}
      </text>
      <path
        x={x + 2}
        y={y}
        transform={`translate(${x + 1}, ${y + 3}) scale(.35)`}
        fill={theme.colors.white}
        stroke={theme.colors.white}
        {...{
          cursor,
          opacity,
          onClick,
          onMouseMove,
          onMouseOut,
        }}
        d="M16.7,22.7l9-9c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7l-9-9C16.5,3.1,16.3,3,16,3s-0.5,0.1-0.7,0.3l-1.4,1.4  c-0.4,0.4-0.4,1,0,1.4l4,4c0.3,0.3,0.1,0.9-0.4,0.9H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h16.6c0.4,0,0.7,0.5,0.4,0.9l-4,4  c-0.4,0.4-0.4,1,0,1.4l1.4,1.4c0.2,0.2,0.4,0.3,0.7,0.3C16.3,23,16.5,22.9,16.7,22.7z"
      />
      <line
        x1={x}
        y1={y}
        x2={x}
        y2={0}
        strokeWidth={theme.strokeWidth.two}
        stroke={theme.colors.tbrBlue}
        {...{ opacity }}
      />
    </Fragment>
  ),
);

export const SetbackShape = compose(
  withHoverableStyle,
  withTheme,
)(
  ({
    theme,
    x,
    y,
    profile,
    opacity,
    onMouseMove = () => null,
    onMouseOut = () => null,
    onClick = () => null,
    cursor,
  }) => (
    <Fragment>
      <g transform={`translate(${x - 12}, ${y})`} {...{ cursor, opacity }}>
        <rect fill="#4A4A4A" x="0" y="4" width="1" height="16" />
        <rect fill="#4A4A4A" x="15" y="4" width="1" height="16" />
        <rect
          fill="#4A4A4A"
          fillRule="nonzero"
          x="1"
          y="4"
          width="15"
          height="3"
        />
        <rect
          fill="#4A4A4A"
          fillRule="nonzero"
          x="1"
          y="19"
          width="15"
          height="1"
        />
        <rect
          fill="#CF021B"
          fillRule="nonzero"
          x="0"
          y="23"
          width="23"
          height="1"
        />
        <rect
          fill="#4A4A4A"
          fillRule="nonzero"
          x="4"
          y="0"
          width="1"
          height="4"
        />
        <rect
          fill="#4A4A4A"
          fillRule="nonzero"
          x="12"
          y="0"
          width="1"
          height="4"
        />
        <path
          d="M4,14.0641283 L5.11704312,13.9098196 C5.15263536,14.2340252 5.24845918,14.4818517 5.40451745,14.6533066 C5.56057573,14.8247615 5.74948554,14.9104876 5.97125257,14.9104876 C6.20944678,14.9104876 6.40999234,14.8076163 6.57289528,14.6018704 C6.73579821,14.3961246 6.81724846,14.1186836 6.81724846,13.7695391 C6.81724846,13.4390988 6.73922049,13.1772443 6.58316222,12.9839679 C6.42710394,12.7906915 6.23682522,12.6940548 6.01232033,12.6940548 C5.86447565,12.6940548 5.68788604,12.7267866 5.4825462,12.7922512 L5.60985626,11.7214429 C5.92197281,11.730795 6.16016345,11.6536414 6.32443532,11.48998 C6.48870719,11.3263185 6.57084189,11.1088858 6.57084189,10.8376754 C6.57084189,10.6069906 6.51060977,10.423069 6.39014374,10.2859051 C6.2696777,10.1487412 6.10951503,10.0801603 5.90965092,10.0801603 C5.71252468,10.0801603 5.54414854,10.1580932 5.40451745,10.3139613 C5.26488637,10.4698293 5.18001384,10.6973933 5.14989733,10.99666 L4.0862423,10.7909152 C4.16016464,10.3763061 4.27173094,10.0450914 4.42094456,9.79726119 C4.57015817,9.54943096 4.77823275,9.35459879 5.04517454,9.21275885 C5.31211632,9.07091891 5.61122353,9 5.94250513,9 C6.50924308,9 6.96372177,9.20574277 7.30595483,9.61723447 C7.58795487,9.9539095 7.72895277,10.3342219 7.72895277,10.758183 C7.72895277,11.3598338 7.4401124,11.8399002 6.862423,12.1983968 C7.20739392,12.2825656 7.48322963,12.4711631 7.6899384,12.7641951 C7.89664717,13.057227 8,13.4110422 8,13.8256513 C8,14.4273021 7.80698345,14.9401003 7.42094456,15.3640615 C7.03490567,15.7880226 6.55441766,16 5.97946612,16 C5.43463109,16 4.98289023,15.8215337 4.62422998,15.4645959 C4.26556973,15.107658 4.05749515,14.6408401 4,14.0641283 Z M11,16 L9.69099379,16 L9.69099379,10.9639946 C9.21273053,11.4205186 8.64907157,11.7581511 8,11.9769022 L8,10.7642663 C8.34161661,10.6501353 8.71273092,10.4337652 9.11335404,10.1151495 C9.51397716,9.79653373 9.78881913,9.42482096 9.9378882,9 L11,9 L11,16 Z"
          fill="#4A4A4A"
        />
        <path
          d="M21.5,15 C21.3026306,15 21.1348691,14.927537 20.9967105,14.7826087 C20.8585519,14.6376804 20.7763159,14.4275376 20.75,14.1521739 L20.0197368,5.69565217 L20,5.47826087 C20,5.02898326 20.141446,4.6702912 20.4243421,4.40217391 C20.7072383,4.13405663 21.0657873,4 21.5,4 C21.9342127,4 22.2927617,4.13405663 22.5756579,4.40217391 C22.858554,4.6702912 23,5.02898326 23,5.47826087 L22.9802632,5.7173913 L22.25,14.1521739 C22.2236841,14.4275376 22.1414481,14.6376804 22.0032895,14.7826087 C21.8651309,14.927537 21.6973694,15 21.5,15 Z M21.5,20 C21.0675654,20 20.7094609,19.8566681 20.4256757,19.57 C20.1418905,19.2833319 20,18.9266688 20,18.5 C20,18.0733312 20.1418905,17.7166681 20.4256757,17.43 C20.7094609,17.1433319 21.0675654,17 21.5,17 C21.9324346,17 22.2905391,17.1433319 22.5743243,17.43 C22.8581095,17.7166681 23,18.0733312 23,18.5 C23,18.9266688 22.8581095,19.2833319 22.5743243,19.57 C22.2905391,19.8566681 21.9324346,20 21.5,20 Z"
          fill="#CF021B"
        />
        <rect
          x="0"
          y="0"
          width="23"
          height="24"
          onClick={onClick}
          onMouseMove={onMouseMove}
          onMouseOut={onMouseOut}
          opacity="0"
        />
      </g>
      <line
        x1={x}
        y1={y + 24}
        x2={x}
        y2={0}
        strokeWidth={theme.strokeWidth.two}
        stroke={theme.colors.red}
        {...{ opacity }}
      />
    </Fragment>
  ),
);

export const CarbohydratesLine = compose(
  withHoverableStyle,
  withTheme,
)(({ theme, ax, bx, ay, by, opacity, ...props }) => (
  <line
    key={`carbohydrates - ${ax}${ay}${bx}${by}`}
    x1={ax}
    y1={ay}
    x2={bx}
    y2={by}
    strokeWidth={theme.strokeWidth.four}
    strokeDasharray="9.4 2.7"
    stroke={theme.colors.carb}
    {...{ opacity, ...props }}
  />
));

export const BasalLine = compose(
  withHoverableStyle,
  withTheme,
)(({ theme, ax, bx, ay, by, opacity, showA, showB, ...props }) => (
  <line
    id={'lineaBasall'}
    key={`basal - ${ax}${ay}${bx}${by}`}
    x1={ax}
    y1={ay}
    x2={bx}
    y2={by}
    {...{ opacity }}
    strokeWidth={theme.strokeWidth.three}
    stroke={showA && showB ? theme.colors.tbrBlue : theme.colors.clear}
    {...props}
    className="no-user-select"
  />
));

export const TbrIncreaseShape = compose(
  withHoverableStyle,
  withTheme,
)(({ theme, x, y, width, ...props }) => (
  <PumpTbrincIcon {...getSquareIconDimensions(theme, x, y, width)} {...props} />
));
export const TbrDecreaseShape = compose(
  withHoverableStyle,
  withTheme,
)(({ theme, x, y, width, ...props }) => (
  <PumpTbrdecIcon {...getSquareIconDimensions(theme, x, y, width)} {...props} />
));
export const TbrEndIncreaseShape = compose(
  withHoverableStyle,
  withTheme,
)(({ theme, x, y, width, ...props }) => (
  <PumpTbrEndIncIcon
    {...getSquareIconDimensions(theme, x, y, width)}
    {...props}
  />
));
export const TbrEndDecreaseShape = compose(
  withHoverableStyle,
  withTheme,
)(({ theme, x, y, width, ...props }) => (
  <PumpTbrEndDecIcon
    {...getSquareIconDimensions(theme, x, y, width)}
    {...{ ...props }}
  />
));
