import * as React from 'react';
import { MouseEvent } from 'react';
import styled from 'styled-components';
import fonts from '../fonts';
import { colors } from '../colors';

export const StyledSymbolButton = styled.span<{ color?: string }>`
  font-family: ${fonts.type.mainFont};
  color: ${(props) => props.color || colors.mainBlue};
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  float: right;
  cursor: pointer;
  user-select: none;
`;

export interface IButtonProps {
  children?: React.ReactNode;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  dataTestId?: string;
  color?: string;
}

export const SymbolButton: React.FunctionComponent<IButtonProps> = (
  props: IButtonProps,
) => (
  <StyledSymbolButton
    onClick={props.onClick}
    data-testid={props.dataTestId}
    color={props.color}
  >
    {props.children}
  </StyledSymbolButton>
);
SymbolButton.defaultProps = {
  children: null,
  dataTestId: 'default_test_id',
};
