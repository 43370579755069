import { mockHba1c } from './hba1c-card.mock';
import {
  GetHba1cCardLoaderImpl,
  GetHba1cCardServiceImpl,
  GetHba1cCardTransformImpl,
} from './hba1c-card.service';

const mockPosterPrograms = () => Promise.resolve(mockHba1c);

export const GetHba1cCardFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPosterPrograms : GetHba1cCardLoaderImpl;
  return GetHba1cCardServiceImpl(loader, GetHba1cCardTransformImpl);
};
