import * as React from 'react';
import { withTheme } from 'styled-components';

import { ThemeInterface } from 'src/theme';

interface TransparentRectangleProps {
  height: number;
  width: number;
  theme?: ThemeInterface;
}

const TransparentRectangleComponent: React.FunctionComponent<
  TransparentRectangleProps
> = ({ height, width, theme }) => (
  <rect {...{ height, width }} fill={theme && theme.colors.clear} />
);

export const TransparentRectangle = withTheme(TransparentRectangleComponent);
