// tslint:disable
import { head } from 'ramda';
import {
  ModalStateToApiRequestProps,
  ModalStateToApiRequestFormatOutput,
  PatientListItem,
} from './time-tracker-modal.types';
import { listPatientCareActivities } from './time-tracker-modal.constants';

export const formatStateToApiRequestFormat = (
  props: ModalStateToApiRequestProps,
): ModalStateToApiRequestFormatOutput => {
  const { communication, patientCareNote } = props;
  const selected = getListOfSelectedPatientCareActivities(props);
  const notSelected = inverseFilterListByPatientCareActivities(selected);
  return {
    methodOfCommunication:
      communication && communication.id ? communication.id : 'NONE',
    patientCareActivitiesSelected: selected,
    patientCareActivitiesNotSelected: notSelected,
    summary: patientCareNote ? patientCareNote : '',
  };
};

export const getListOfSelectedPatientCareActivities = ({
  patientCareActivities,
  dataTargeAnalysis,
  trainingPatient,
  goalsAndTreatments,
}: ModalStateToApiRequestProps) =>
  [
    ...filterListByPatientCareActivities(patientCareActivities),
    ...filterListByPatientCareActivities(dataTargeAnalysis),
    ...filterListByPatientCareActivities(trainingPatient),
    ...filterListByPatientCareActivities(goalsAndTreatments),
  ].filter((item) => item);

export const inverseFilterListByPatientCareActivities = (
  selectedList: string[] = [],
): string[] =>
  listPatientCareActivities.filter(
    (activitiesItem) => !selectedList.includes(activitiesItem),
  );

export const filterListByPatientCareActivities = (
  list: PatientListItem[],
): string[] => {
  if (!list || !Array.isArray(list)) {
    return [];
  }
  return list.map((activitiesItem) =>
    head(
      listPatientCareActivities.filter(
        (staticItem) => staticItem === activitiesItem.id,
      ),
    ),
  );
};
