export const GRAPH_LEGEND_TYPES = {
  TREND_DETAIL: 'trend-detail',
  STANDARD_DAY_DETAIL: 'standard-day-detail',
  STANDARD_WEEK_DETAIL: 'standard-week-detail',
  LOGBOOK: 'logbook',
  DEVICE_SETTINGS: 'device-settings',
  LOGBOOK_STATS: 'logbook-stats',
  LOGBOOK_24HR: '24hour',
  TREND: 'trend',
  METABOLIC: 'metabolic',
  INSULIN_PUMP: 'insulin-pump',
  CGM_DAILY_GLUCOSE: 'CGM-daily-glucose',
};
