import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { StyledLocalize } from './localized-text.style';

export const Localized = ({ t, textKey = '', values, ...props }) => (
  <StyledLocalize {...props}>{t(textKey, values)} </StyledLocalize>
);

export const LocalizedText = withTranslation(Localized);
LocalizedText.displayName = 'LocalizedText';
