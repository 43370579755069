import {
  add,
  always,
  divide,
  equals,
  identity,
  ifElse,
  length,
  pipe,
  prop,
  range,
  slice,
  sortBy,
} from 'ramda';

export const sliceResultsByCurrentPage =
  ({
    currentPage,
    resultsPerPage,
    sortByProp,
  }: {
    currentPage: number;
    resultsPerPage: number;
    sortByProp?: string;
  }) =>
  (results: any[]): any[] => {
    const start = currentPage * resultsPerPage - resultsPerPage;
    const end = currentPage * resultsPerPage;
    const optionalSort = sortByProp ? sortBy(prop(sortByProp)) : identity;
    return pipe(optionalSort, slice(start, end))(results);
  };

export const resultsToPageNumbers = ({
  resultsPerPage,
}: {
  resultsPerPage: number;
}) =>
  pipe(
    length,
    ifElse(equals(0), always(1), identity),
    (guides) => divide(guides, resultsPerPage),
    Math.ceil,
    add(1),
    range(1),
  );
