import React from 'react';
import { JelloWrapperContainer } from './jello-wrapper.container';
import { ContainerWithGrid } from './container-with-grid.component';

export const withJelloWrapper = (Component) => (props) => {
  const jelloWrapperContainerProps = { ...props, Content: ContainerWithGrid };

  return (
    <JelloWrapperContainer {...jelloWrapperContainerProps}>
      <Component {...props} />
    </JelloWrapperContainer>
  );
};
