/* tslint:disable */

import { Observable } from 'rxjs';
import { Epic } from 'redux-observable';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { catchError } from 'rxjs/operators';
import {
  fetchSettingsSuccess,
  fetchSettingsError,
  fetchAllDeviceSettingsDataSuccess,
} from './device-settings.action';
import {
  FETCH_DEVICE_SETTINGS_REQUEST,
  fetchAllDevicesActionType,
} from './device-settings.constant';
import { State } from 'src/app/store/app.types';
import { ListDevicesLoaderImplType } from 'src/services/patient/list-devices/list-devices.types';
import { fetchDevicesSuccess } from '../list-devices/list-devices.action';
import { fetchSettingsListStart } from '../device-settings-list/device-settings-list.action';

export const fetchDeviceSettingsEpic: (
  deviceSettingsService,
) => Epic<any, State> = (deviceSettingsService) => (action$, store) =>
  action$.ofType(FETCH_DEVICE_SETTINGS_REQUEST.START).switchMap((action) => {
    const settingsParams = action.payload;
    const gigyaToken = selectGigyaToken(store.getState());
    return Observable.fromPromise(
      deviceSettingsService(
        settingsParams,
        selectAccessToken(store.getState()),
        gigyaToken,
      ),
    )
      .map((devices) => fetchSettingsSuccess(devices))
      .pipe(catchError((err) => Observable.of(fetchSettingsError())));
  });

export const fetchAllDeviceSettingsDataEpic: (
  serviceDevices: ListDevicesLoaderImplType,
  serviceDeviceSettingsList: any,
  serviceDeviceSettings: any,
) => Epic<any, State> = (serviceDevices) => (action$, store) =>
  action$
    .ofType(fetchAllDevicesActionType.FETCH_START)
    .switchMap((action: any): any => {
      const openId = {
        accessToken: selectAccessToken(store.getState()),
        gigyaToken: selectGigyaToken(store.getState()),
      };
      const patientId = action.payload;
      return Observable.fromPromise(
        serviceDevices(openId, patientId).then((result) => {
          return sortDevices(result);
        }),
      ).concatMap((sortedDevices = []) => {
        if (sortedDevices.length) {
          return [
            fetchDevicesSuccess(sortedDevices),
            fetchSettingsListStart({
              deviceSerialNumber: sortedDevices[0].serial,
              patientId: patientId,
              patientType: 'PWD',
            }),
            fetchAllDeviceSettingsDataSuccess(),
          ];
        }
        return [];
      });
    });

const sortDevices = (devices) =>
  devices.sort((a: any, b: any) => {
    return new Date(b.addedDateJsDate).getTime() - a.addedDateJsDate.getTime();
  });
