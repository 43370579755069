import { getConfig } from '@roche/roche-common';
import { ID, PREFIX, TITLE } from './additional-info.constants';
import { CONTENTS } from './additional-info.constants';
import { TFunction } from 'i18next';
import { pathOr } from 'ramda';
import { Section } from './additional-info.content';

export const getProps = (
  t: TFunction,
  info: string,
  profile: string,
  active?: string,
) => {
  return {
    id: ID,
    headerTitle: t(`${PREFIX}${info}.${TITLE}`),
    showCloseButton: true,
    closeOnAction: null,
    primaryButtonText: null,
    secondaryButtonText: null,
    tertiaryButtonText: null,
    info,
    active,
    ...pathOr(pathOr(null, [info], CONTENTS), [info, profile], CONTENTS),
  };
};

export const additionalInfoActivated = () =>
  `${getConfig().REACT_APP_ADDITIONAL_INFO}` === 'true';

export const filterSections = (
  sections: Section[],
  patientPermissions: string[],
) => {
  return sections.filter(
    (section) =>
      section.sectionVisibilityPatientRole?.every((permission) =>
        patientPermissions.includes(permission),
      ) ?? true,
  );
};
