import { MOCKED_BLOCKS } from './post-time-blocks.mocks';

import {
  PostTimeBlocksLoaderImpl,
  PostTimeBlocksServiceImpl,
  PostTimeBlocksTransformImpl,
} from './post-time-blocks.service';

const mockLoader = (): Promise<any> => Promise.resolve(MOCKED_BLOCKS);

export const PostTimeBlocksFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : PostTimeBlocksLoaderImpl;
  return PostTimeBlocksServiceImpl(loader, PostTimeBlocksTransformImpl);
};
