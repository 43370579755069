import styled, { StyledFunction } from 'styled-components';

export type IconWrapperProps = {};

const IconWrapperDiv: StyledFunction<IconWrapperProps> = styled.div;

export const IconWrapper = IconWrapperDiv`
    display: flex;
    margin-bottom: 24px;
`;

export type ButtonWrapperProps = {};

const ButtonWrapperDiv: StyledFunction<ButtonWrapperProps> = styled.div;

export const ButtonWrapper = ButtonWrapperDiv`
    margin-top: 47px;
`;
