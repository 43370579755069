import { Action, PayloadAction } from 'src/app/store/app.types';
import { Hba1cInfo } from '../list-values-hba1c.types';

export enum ListHba1cActionType {
  FETCH_HBA1C_LIST_START = 'FETCH_HBA1C_LIST_START',
  FETCH_HBA1C_LIST_SUCCESS = 'FETCH_HBA1C_LIST_SUCCESS',
  FETCH_HBA1C_LIST_ERROR = 'FETCH_HBA1C_LIST_ERROR',
}
export type ListHba1cActionActions =
  | FetchHba1cListStartAction
  | FetchHba1cListSuccessAction
  | FetchHba1cListErrorAction
  | FixMe;

export type FetchHba1cListStartAction = PayloadAction<
  ListHba1cActionType.FETCH_HBA1C_LIST_START,
  FetchHba1cListStartPayloadType
>;

export type FetchHba1cListSuccessAction = PayloadAction<
  ListHba1cActionType.FETCH_HBA1C_LIST_SUCCESS,
  Hba1cInfo[]
>;

export type FetchHba1cListErrorAction =
  Action<ListHba1cActionType.FETCH_HBA1C_LIST_ERROR>;

export type FetchHba1cListStartPayloadType = {
  fhirId: string;
};

export type Hba1cListState = {
  fhirId: string | null;
  isFetchingHba1cList: boolean;
  hasData: boolean;
  isFetchingHba1cListError: boolean;
  hba1cInfo: Hba1cInfo[] | null;
};
