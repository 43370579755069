export const mock = {
  resultDescription: 'OK',
  model: {
    clinicalStatisticDTOs: [
      {
        patientInfoDTO: {
          fullName: 'Augusto Lima ',
          birthday: '1985-02-18T00:00:00.000+0000',
          diabetesType: 'DIABETES_TYPE1',
          healthCareSystemId: 'HCPWPORTAL-2561',
          hcpIsAccessible: false,
          hasEtapesProgram: true,
          etapesEnrollName: 'Dr. Gaston Leroux',
          patientId: 286024,
          gigyaUid: '39977d31adaa44b48bd5b985576bbf7f',
          gender: 'MALE',
        },
        glycatedInfoDTO: {
          hba1cValue: 19.0,
          hba1cDate: '2020-02-18T00:00:00.000+0000',
        },
        patientClinicalStatisticsDTO: {
          notEnoughData: true,
          lastDeviceSync: null,
          gldVeryLow: null,
          gldLow: null,
          gldInRange: null,
          gldHigh: null,
          gldVeryHigh: null,
          hyperEvents: null,
          bgAverage: null,
          averageBloodGlucose: null,
          standardDeviation: null,
          coefficientOfVariation: null,
          lowestBgReading: null,
          highestBgReading: null,
          deviceType: null,
          numberOfTests: null,
          postHyper: null,
          postHigh: null,
          postLow: null,
          postHipo: null,
          highestBgDate: null,
          lowestBgDate: null,
        },
      },
      {
        patientInfoDTO: {
          fullName: 'Ernesto Lopez ',
          birthday: '1985-02-18T00:00:00.000+0000',
          diabetesType: 'DIABETES_TYPE1',
          healthCareSystemId: 'HCPWPORTAL-2561',
          hcpIsAccessible: false,
          hasEtapesProgram: true,
          etapesEnrollName: 'Dr. Gaston Leroux',
          patientId: 286024,
          gigyaUid: '39977d31adaa44b48bd5b985576bbf7f',
          gender: 'MALE',
        },
        glycatedInfoDTO: {
          hba1cValue: 19.0,
          hba1cDate: '2020-02-18T00:00:00.000+0000',
        },
        patientClinicalStatisticsDTO: {
          lastDeviceSync: '2020-02-22T17:10:25.000+0000',
          gldVeryLow: 1.7,
          gldLow: 13.5,
          gldInRange: 55.7,
          gldHigh: 30.8,
          gldVeryHigh: 3.5,
          hypoEvents: 1,
          hyperEvents: 1,
          bgAverage: 1,
          averageBloodGlucose: 123,
          standardDeviation: 51,
          coefficientOfVariation: 43.2,
          lowestBgReading: 57,
          highestBgReading: 180,
          deviceType: null,
          numberOfTests: 50,
          postHyper: 250,
          postHigh: 132,
          postLow: 78,
          postHipo: 54,
          highestBgDate: '2020-03-12T11:50:00.000+0000',
          lowestBgDate: '2020-03-12T07:26:00.000+0000',
        },
      },
      {
        patientInfoDTO: {
          fullName: 'Carmen Sánchez ',
          birthday: '1985-02-18T00:00:00.000+0000',
          diabetesType: 'DIABETES_TYPE1',
          healthCareSystemId: 'HCPWPORTAL-2561',
          hcpIsAccessible: false,
          hasEtapesProgram: true,
          etapesEnrollName: 'Dr. Gaston Leroux',
          patientId: 286025,
          gigyaUid: '39977d31adaa44b48bd5b985576bbf7f',
          gender: 'FEMALE',
        },
        glycatedInfoDTO: {
          hba1cValue: 5,
          hba1cDate: '2020-02-18T00:00:00.000+0000',
        },
        patientClinicalStatisticsDTO: {
          lastDeviceSync: '2020-02-22T17:10:25.000+0000',
          gldVeryLow: 1.7,
          gldLow: 13.5,
          gldInRange: 55.7,
          gldHigh: 30.8,
          gldVeryHigh: 3.5,
          hypoEvents: 1,
          hyperEvents: 1,
          bgAverage: 1,
          averageBloodGlucose: 123,
          standardDeviation: 51,
          coefficientOfVariation: 43.2,
          lowestBgReading: 50,
          highestBgReading: 180,
          deviceType: null,
          numberOfTests: 50,
          postHyper: 250,
          postHigh: 132,
          postLow: 78,
          postHipo: 54,
          highestBgDate: '2020-03-12T11:50:00.000+0000',
          lowestBgDate: '2020-03-12T07:26:00.000+0000',
        },
      },
      {
        patientInfoDTO: {
          fullName: 'Patient RPM NO Compliant',
          birthday: null,
          diabetesType: 'DIABETES_TYPE2_MDI',
          healthCareSystemId: '8706es',
          hcpIsAccessible: true,
          hasEtapesProgram: false,
          etapesEnrollName: null,
          hasRPMProgram: true,
          rpmEnrollName: 'Dr. Nestor León',
          rpmProgramName: 'RPM',
          patientId: 290462,
          gigyaUid: 'cec73dc3fd43414bbd8acd3e726a8ff2',
          gender: 'UNSPECIFIED',
        },
        glycatedInfoDTO: null,
        patientClinicalStatisticsDTO: null,
      },
      {
        patientInfoDTO: {
          fullName: 'Patient RPM Compliant',
          birthday: null,
          diabetesType: 'DIABETES_TYPE2_MDI',
          healthCareSystemId: '870f6',
          hcpIsAccessible: true,
          hasEtapesProgram: false,
          etapesEnrollName: null,
          hasRPMProgram: true,
          rpmEnrollName: 'Dr. Nestor León',
          rpmProgramName: 'RPM',
          patientId: 291562,
          gigyaUid: 'cec73dc3fd43414bbd8acd3e726a8ff2',
          gender: 'UNSPECIFIED',
        },
        glycatedInfoDTO: null,
        patientClinicalStatisticsDTO: {
          uploadBGDataInFrequency: true,
        },
      },
      {
        patientInfoDTO: {
          fullName: 'Patient RPM NO Compliant',
          birthday: null,
          diabetesType: 'DIABETES_TYPE2_MDI',
          healthCareSystemId: 'df8706',
          hcpIsAccessible: true,
          hasEtapesProgram: false,
          etapesEnrollName: null,
          hasRPMProgram: true,
          rpmEnrollName: 'Dr. Nestor León',
          rpmProgramName: 'RPM',
          patientId: 290572,
          gigyaUid: 'cec73dc3fd43414bbd8acd3e726a8ff2',
          gender: 'UNSPECIFIED',
        },
        glycatedInfoDTO: null,
        patientClinicalStatisticsDTO: {
          uploadBGDataInFrequency: false,
        },
      },
      {
        patientInfoDTO: {
          fullName: 'Lola Vera ',
          birthday: '1985-08-19T00:00:00.000+0000',
          diabetesType: 'DIABETES_TYPE2',
          healthCareSystemId: 'HCPWPORTAL-2561',
          hcpIsAccessible: false,
          hasEtapesProgram: true,
          etapesEnrollName: 'Dr. Gaston Leroux',
          patientId: 286046,
          gigyaUid: '39977d31adaa44b48bd5b985576bbf7f',
          gender: 'FEMALE',
        },
        glycatedInfoDTO: {
          hba1cValue: 9,
          hba1cDate: '2020-02-18T00:00:00.000+0000',
        },
        patientClinicalStatisticsDTO: {
          lastDeviceSync: null,
          gldVeryLow: null,
          gldLow: null,
          gldInRange: null,
          gldHigh: null,
          gldVeryHigh: null,
          hypoEvents: null,
          hyperEvents: null,
          bgAverage: 1,
          averageBloodGlucose: 1,
          standardDeviation: 1,
          coefficientOfVariation: 1.0,
          lowestBgReading: 71,
          highestBgReading: 156,
          deviceType: null,
          numberOfTests: 50,
          postHyper: 250,
          postHigh: 132,
          postLow: 78,
          postHipo: 54,
          highestBgDate: '2020-03-12T11:50:00.000+0000',
          lowestBgDate: '2020-03-12T07:26:00.000+0000',
        },
      },
    ],
    cgmClinicalStatisticDTOs: [],
    clinicalStatisticsSummaryDTO: {
      totalPatientsBg: 32,
      totalPatientsCgm: 8,
    },
  },
};
