import styled from 'styled-components';

import { getBorder } from 'src/domains/diagnostics/utils/border';

export const LogbookCellBlocksContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.id === 'graphs.insulinPump.comments' ? 'start' : 'center'};
  margin: ${(props) => props.margin};
  border-left: ${(props) => getBorder(props.borderLeft)};
  border-right: ${(props) => getBorder(props.borderRight)};
  border-bottom: ${({ borderBottom, borderBottomString }) =>
    borderBottomString ? borderBottomString : getBorder(borderBottom)};
  padding-left: ${(props) =>
    props.id === 'graphs.insulinPump.comments' ? props.theme.spacing.six : '0'};
`;

LogbookCellBlocksContainer.displayName = 'LogbookCellBlocksContainer';
