import {
  FETCH_PERMISSIONS,
  FETCH_PATIENT_PERMISSIONS,
} from './permissions.constants';

import { STATE_ACTIONS } from '../state/state.constants';

export const INITIAL_PERMISSIONS_STATE = {
  permissions: [],
  isFetchingPermissions: false,
};
export const INITIAL_PATIENT_PERMISSIONS_STATE = {
  patientPermissions: [],
  isFetchingPatientPermissions: false,
};
export const permissionsReducer = (
  state = INITIAL_PERMISSIONS_STATE,
  action = {},
) => {
  switch (action.type) {
    case FETCH_PERMISSIONS.START:
      return {
        ...state,
        isFetchingPermissions: true,
      };
    case FETCH_PERMISSIONS.SUCCESS:
      return {
        permissions: action.payload,
        isFetchingPermissions: false,
      };
    case STATE_ACTIONS.CLEAR_PERMISSIONS:
      return INITIAL_PERMISSIONS_STATE;
    default:
      return state;
  }
};

export const patientPermissionsReducer = (
  state = INITIAL_PATIENT_PERMISSIONS_STATE,
  action = {},
) => {
  switch (action.type) {
    case FETCH_PATIENT_PERMISSIONS.START:
      return {
        ...state,
        isFetchingPatientPermissions: true,
      };
    case FETCH_PATIENT_PERMISSIONS.SUCCESS:
      return {
        ...state,
        patientPermissions: action.payload,
        isFetchingPatientPermissions: false,
      };
    case STATE_ACTIONS.CLEAR_PATIENT_PERMISSIONS:
      return INITIAL_PATIENT_PERMISSIONS_STATE;
    default:
      return state;
  }
};
