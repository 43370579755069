import * as React from 'react';
import { LoadingIndicator } from 'src/components/loading-indicator/loading-indicator.component';
import { LocalizedText } from 'src/components/localized-text/localized-text.component';
import { fontSize as fontSizes } from 'src/core/styles/font-sizes';
import { spacing } from 'src/core/styles/spacing';
import {
  BrandButton,
  SquareButton,
  ButtonContentSpan,
  ButtonIconSpan,
  ButtonLoadingIconSpan,
  ButtonTextSpan,
  ButtonTextSpanInverted,
} from './button.style';
import { ButtonSizes } from './button.types';

export type ButtonProps = {
  label: string;
  accessibilityLabel?: string;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
  inactive?: boolean;
  icon?: string | any;
  loading?: boolean;
  buttonStyle?: string;
  fontSize?: string;
  uppercase?: boolean;
  fontWeight?: string;
  minWidth?: number;
  maxHeight?: number;
  m?: number;
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;
  isCircleIcon?: boolean;
  type?: any;
  buttonSize?: ButtonSizes;
  autoFocus?: boolean;
  invertButton?: boolean;
  className?: string;
  height?: number;
};

export const Button = ({
  accessibilityLabel = '',
  disabled = false,
  selected = false,
  inactive = false,
  icon,
  label,
  loading,
  onClick,
  buttonStyle,
  fontSize,
  uppercase,
  fontWeight,
  minWidth,
  maxHeight,
  m,
  mr,
  ml,
  mt,
  mb,
  isCircleIcon,
  type = 'button',
  buttonSize = ButtonSizes.MEDIUM,
  autoFocus = false,
  invertButton = false,
  height,
  ...buttonProps
}: ButtonProps) => {
  const constructButtonContentBasedOnLoadingState = () => {
    if (loading) {
      return (
        <React.Fragment>
          <LocalizedText textKey="general.loading" />
          <ButtonLoadingIconSpan>
            <LoadingIndicator size={spacing.two} />
          </ButtonLoadingIconSpan>
        </React.Fragment>
      );
    }
    if (invertButton) {
      return (
        <ButtonContentSpan>
          {label && <ButtonTextSpanInverted>{label}</ButtonTextSpanInverted>}
          {icon && <ButtonIconSpan>{icon}</ButtonIconSpan>}
        </ButtonContentSpan>
      );
    }
    return (
      <ButtonContentSpan>
        {icon && <ButtonIconSpan>{icon}</ButtonIconSpan>}
        {label && <ButtonTextSpan>{label}</ButtonTextSpan>}
      </ButtonContentSpan>
    );
  };
  if (buttonStyle === 'square') {
    return (
      <SquareButton
        aria-label={accessibilityLabel || label}
        disabled={disabled}
        // @ts-ignore
        loading={loading}
        selected={selected}
        inactive={inactive}
        onClick={onClick}
        buttonStyle={buttonStyle}
        fontSize={fontSize}
        uppercase={uppercase}
        fontWeight={fontWeight}
        minWidth={minWidth}
        maxHeight={maxHeight}
        m={m}
        mr={mr}
        ml={ml}
        mt={mt}
        mb={mb}
        type={type}
        buttonSize={buttonSize}
        autoFocus={autoFocus}
        height={height}
        {...buttonProps}
      >
        {constructButtonContentBasedOnLoadingState()}
      </SquareButton>
    );
  }
  return (
    <BrandButton
      aria-label={accessibilityLabel || label}
      disabled={disabled}
      // @ts-ignore
      loading={loading}
      onClick={onClick}
      buttonStyle={buttonStyle}
      fontSize={fontSize}
      uppercase={uppercase}
      fontWeight={fontWeight}
      minWidth={minWidth}
      maxHeight={maxHeight}
      m={m}
      mr={mr}
      ml={ml}
      mt={mt}
      mb={mb}
      type={type}
      buttonSize={buttonSize}
      autoFocus={autoFocus}
      {...buttonProps}
    >
      {constructButtonContentBasedOnLoadingState()}
    </BrandButton>
  );
};

// @ts-ignore
Button.displayName = 'Button';

// @ts-ignore
Button.defaultProps = {
  accessibilityLabel: '',
  buttonStyle: 'primary',
  disabled: false,
  fontSize: fontSizes.p,
  loading: false,
  minWidth: '',
};
