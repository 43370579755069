import { createAction } from '../../app/store/app.actions';

import {
  LoginSystemErrorTypes,
  RetryLogin,
  LogoutAction,
  LogoutTypes,
} from './login-system-error.types';

export const retryLogin = (): RetryLogin =>
  createAction(LoginSystemErrorTypes.RETRY_LOGIN);

export const startSignoutAction = (): LogoutAction =>
  createAction(LogoutTypes.START_LOGOUT);
