import styled from 'styled-components';

import { LogbookRowCard } from 'src/domains/diagnostics/components/table/logbook-row-card/logbook-row-card.component';

export const Row = styled(LogbookRowCard)`
  margin: ${(props) => props.theme.spacing[2]} 0;
  flex: 1;
`;

Row.displayName = 'Row';
