import * as React from 'react';
import { push } from 'react-router-redux';
import { XIcon } from 'src/assets/icons';
import { Block } from 'src/components/block/block.component';
import { Button } from 'src/components/button/button.component';
import { LocalizedText } from 'src/components/localized-text/localized-text.component';
import { SuccessBadge } from 'src/components/badge/success-badge/success-badge.component';
import { ButtonReset } from 'src/core/styles/resets';
import { colors } from 'src/core/styles/colors';
import { generalLinks } from 'src/domains/general/routes';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { CommonHeader, ModalBody } from 'src/widgets/modal/modal.style';

export const DeactivatePatientSuccessModalComponent = ({
  destroyModal,
  dispatch,
  t,
}) => (
  <ModalBody>
    <CommonHeader>
      <strong>
        <LocalizedText fontSize={4} textKey="deactivatePatient.title" />
      </strong>
      <ButtonReset
        onClick={() => {
          dispatch(push(generalLinks.home));
          destroyModal();
        }}
      >
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <Block py={2} px={6}>
      <Block p={4}>
        <Block pb={2} mb={3} display="flex" justifyContent="center">
          <SuccessBadge size={75} fillColor={colors.brandBlue} />
        </Block>
        <Block pb={3} mb={4} display="flex" justifyContent="center">
          <strong>
            <LocalizedText textKey="editPatient.successModal.description" />
          </strong>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            label={t('editPatient.successModal.confirm')}
            onClick={() => {
              dispatch(push(generalLinks.home));
              destroyModal();
            }}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);

export const DeactivatePatientSuccessModal = withTranslation(
  DeactivatePatientSuccessModalComponent,
);
