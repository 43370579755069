export type GetDeviceAssociationParams = {
  associationId: string;
};

export interface DeviceInfo {
  id?: string;
  modelDevice: string;
  serialNumber: string;
  brandName: string;
  description: string;
  formatName: string;
  reference: string;
  resourceType: DEVICE_TYPE | string;
}

export enum DEVICE_TYPE {
  Meter = 'METER',
  Pump = 'PUMP',
  Cgm = 'CGM',
}

export type GetDeviceAssociationResponse = {
  patientId?: number;
  deviceInfo: DeviceInfo;
};

export type GetDeviceAssociationTransform = (
  data: GetDeviceAssociationEC6Response,
) => GetDeviceAssociationResponse;

export type GetDeviceAssociationServiceFactoryType = (
  load,
  transform: GetDeviceAssociationTransform,
) => GetDeviceAssociationServiceType;

export type GetDeviceAssociationServiceType = (
  query: GetDeviceAssociationParams,
  accessToken: string,
  apoKey: string,
) => Promise<GetDeviceAssociationResponse>;

export type GetDeviceAssociationEC6Response = {
  resultDescription: string;
  model: DeviceInfo & { patientId?: number };
};
