import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { State } from 'src/app/store/app.types';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  correlateSharingCodeSuccess,
  correlateSharingCodeError,
} from './correlate-sharing-code.actions';

import {
  CorrelateSharingCodeActionType,
  CorrelateSharingCodeStartAction,
  CorrelateSharingCodeResponse,
  CorrelateSharingCodeActions,
} from './correlate-sharing-code.actions.types';
import { correlatePairingCodeLoaderImplType } from 'src/services/pairing-code/pairing-code-correlation/pairing-code-correlation.types';
import { fetchDataSharingConsent } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.actions';

export const correlateSharingCodeEpic: (
  correlatePairingCodeService: correlatePairingCodeLoaderImplType,
) => Epic<CorrelateSharingCodeActions, State> =
  (correlatePairingCodeService) => (action$, store$) =>
    action$
      .ofType(CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_START)
      .flatMap(({ payload }: CorrelateSharingCodeStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        const apiKey = selectGigyaToken(store$.getState());
        return Observable.fromPromise(
          correlatePairingCodeService(
            accessToken,
            apiKey,
            payload.code,
            payload.fhirId,
          ),
        )
          .switchMap((response: CorrelateSharingCodeResponse) => {
            return [
              correlateSharingCodeSuccess(response),
              fetchDataSharingConsent.start(payload.fhirId),
            ];
          })

          .pipe(
            catchError((err: CorrelateSharingCodeResponse) =>
              Observable.of(correlateSharingCodeError(err)),
            ),
          );
      });
