import { createPayloadAction } from 'src/app/store/app.actions';

import {
  CurrentFormsActionType,
  SetCurrentFormsProp,
  SetCurrentFormsPropPayload,
} from './current-forms.types';

export const setCurrentFormsProp = (
  payload: SetCurrentFormsPropPayload,
): SetCurrentFormsProp =>
  createPayloadAction(CurrentFormsActionType.SET_CURRENT_FORMS_PROP, payload);
