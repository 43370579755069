import styled from 'styled-components';

import { borderRadius } from 'src/core/styles/border-radius';
import { colors } from 'src/core/styles/colors';
import { spacing } from 'src/core/styles/spacing';
import { Block } from 'src/domains/diagnostics/components/block/block.component';

export const HandPointIconWrapper = styled.span`
  position: absolute;
  margin-left: ${({ shiftRight }) => (shiftRight ? 9 : 4)}px;
  margin-top: 2px;
`;

export const LogbookCellBlockContentsBase = styled(Block)`
  border: 0.0625rem solid transparent;
  border-radius: ${borderRadius.three};
  background-color: transparent;
  width: 1.95rem;
  padding: ${spacing.one};
  color: ${colors.black};
  margin: 0 0.25rem 0 0.65rem;
  text-align: center;
  position: relative;
  white-space: nowrap;
`;

LogbookCellBlockContentsBase.displayName = 'LogbookCellBlockContentsBase';

export const LogbookCellBlockContentsBG = styled(LogbookCellBlockContentsBase)`
  color: ${colors.green};
  font-weight: 600;
  margin-right: 0;
`;

LogbookCellBlockContentsBG.displayName = 'LogbookCellBlockContentsBG';

export const LogbookCellBlockContentsHyper = styled(
  LogbookCellBlockContentsBase,
)`
  background-color: ${colors.quartzBlue};
`;

LogbookCellBlockContentsHyper.displayName = 'LogbookCellBlockContentsHyper';

export const LogbookCellBlockContentsHypo = styled(
  LogbookCellBlockContentsBase,
)`
  background-color: ${colors.trafficRed2};
  ${(props) =>
    props.hypoSymptoms
      ? `
    &:after {
      content: "";
      position: absolute;
      top: 0.0625rem;
      left: 0.0625rem;
      right: 0.0625rem;
      height: 1.5rem;
      border: 0.0625rem solid ${colors.white};
      border-radius: ${borderRadius.three};
    }
  `
      : null};
`;

LogbookCellBlockContentsHypo.displayName = 'LogbookCellBlockContentsHypo';
