import React from 'react';

import { translate } from 'src/i18n';

import { SvgIcon } from 'src/components/icon/icon.component';

export const TriangleVeryLowComponent = ({
  height,
  width,
  title = 'Triangle-very-low',
  fillColor,
}) => {
  const originalWidth = width ? width : 35;
  const originalHeight = height ? height : 38;
  const aspectRatio = width / height;
  const originalFillColor = fillColor ? fillColor : '#650E1E';
  return (
    <SvgIcon
      title={translate(title)}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      fillColor={originalFillColor}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill={fillColor}
        fillRule="evenodd"
      >
        <g
          id="triangle-very-low"
          transform="translate(-1095.000000, -138.000000)"
        >
          <g id="Group-6" transform="translate(1.000000, 118.000000)">
            <g id="Group" transform="translate(1087.000000, 21.000000)">
              <g id="!" transform="translate(7.000000, 0.000000)">
                <g id="Icon/!-triangle-Very-Low">
                  <g id="Group" transform="translate(-0.900000, -1.800000)">
                    <g id="Group-2">
                      <path
                        d="M20.3999813,2.06444557 L37.5305939,33.5847728 C37.867362,34.204426 37.6380382,34.9797585 37.0183851,35.3165266 C36.8312354,35.4182384 36.6216157,35.47152 36.4086126,35.47152 L2.14738735,35.47152 C1.44213369,35.47152 0.870412632,34.8997989 0.870412632,34.1945453 C0.870412632,33.9815422 0.923694264,33.7719226 1.02540606,33.5847728 L18.1560187,2.06444557 C18.4927867,1.44479241 19.2681193,1.2154687 19.8877724,1.55223672 C20.1043704,1.66995298 20.282265,1.84784765 20.3999813,2.06444557 Z"
                        id="Triangle"
                        fill={fillColor}
                      ></path>
                      <g id="icon" transform="translate(17.735760, 13.223520)">
                        <path
                          d="M1.61758277,8.70084327 L1.61758277,1.59917971 C1.66051095,1.00553219 2.17436076,0.545030046 2.79385221,0.545030046 C3.41334366,0.545030046 3.92719346,1.00553219 3.97012165,1.59917971 L3.97012165,8.70084327 C3.97012165,9.32337384 3.44475688,9.82803458 2.79668659,9.82803458 C2.14769291,9.82803458 1.62070293,9.32425364 1.61758277,8.70084327 Z"
                          id="Shape"
                          fill="#650E1E"
                          fillRule="nonzero"
                        ></path>
                        <ellipse
                          id="Oval"
                          fill="#650E1E"
                          fillRule="nonzero"
                          cx="2.79668659"
                          cy="13.9207752"
                          rx="1.50789239"
                          ry="1.44846806"
                        ></ellipse>
                        <line
                          x1="1.87834611"
                          y1="1.00563453"
                          x2="1.87834611"
                          y2="8.11274346"
                          id="Shape"
                          stroke="#FFFFFF"
                          strokeWidth="2.2534848"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></line>
                        <circle
                          id="Oval"
                          fill="#FFFFFF"
                          fillRule="nonzero"
                          cx="1.95805372"
                          cy="13.4990342"
                          r="1.5876"
                        ></circle>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const TriangleVeryLow = TriangleVeryLowComponent;
