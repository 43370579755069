/* eslint-disable */
/* eslint-disable react/react-in-jsx-scope */
import * as React from 'react';
import { ModuleAnchorComponent } from '@roche/roche-common';

import { ModuleAnchorConfig } from '../../widgets/moduleAnchorConfig/ModuleAnchorConfig';

type Props = {
  moduleName: string;
  isJelloActivated: boolean;
};

export const NotificationComponent: React.FunctionComponent<Props> = ({
  moduleName,
  isJelloActivated,
}) => (
  <React.Fragment>
    <ModuleAnchorConfig>
      <ModuleAnchorComponent
        anchorName={'notification-module-component'}
        moduleName={moduleName}
        inputAttributes={{
          shouldReloadNotifications: true,
          lastUpdate: new Date().getTime(),
          isJelloActivated,
        }}
        document={document}
      />
    </ModuleAnchorConfig>
  </React.Fragment>
);
