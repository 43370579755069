import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type Props = {
  height: number,
  fillColor?: string,
  strokeColor?: string,
};

export const V3InfoIcon = ({
  fillColor = colors.charcoal,
  height = 15,
  strokeColor = colors.white,
}: Props) => {
  const originalWidth = 3;
  const originalHeight = 18;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
    >
      <g
        id="v3-info"
        stroke={strokeColor}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Main-Menu"
          transform="translate(-53.000000, -252.000000)"
          fill="#508EE5"
        >
          <g id="Group-7" transform="translate(33.000000, 240.000000)">
            <g id="Group-6">
              <g id="Group-2" transform="translate(20.000000, 12.000000)">
                <rect
                  id="Rectangle"
                  x="0"
                  y="6"
                  width="3"
                  height="12"
                  rx="1.5"
                ></rect>
                <rect
                  id="Rectangle-Copy-9"
                  x="0"
                  y="0"
                  width="3"
                  height="3"
                  rx="1.5"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
