import {
  EditPatientWithFhirLoaderImpl,
  EditPatientWithFhirServiceImpl,
  EditPatientWithFhirTransformImpl,
} from './edit-patient-with-fhir.service';

import { mockEditPatientWithFhirResponse } from './edit-patient-with-fhir.mock';

const mockPutEditPatientWithFhir = () =>
  Promise.resolve(mockEditPatientWithFhirResponse);

export const EditPatientWithFhirFactoryImpl = ({ devMode }) => {
  const loader = devMode
    ? mockPutEditPatientWithFhir
    : EditPatientWithFhirLoaderImpl;
  return EditPatientWithFhirServiceImpl(
    loader,
    EditPatientWithFhirTransformImpl,
  );
};
