import { PayloadAction } from 'src/app/store/app.types';
import { ProfileFormState } from 'src/core/profile/profile.types';
import {
  GetProfileErrorResponse,
  Profile,
} from 'src/services/profile/edit-profile.types';

import {
  EditProfileErrorResponse,
  EditProfileParams as EditProfileServiceParams,
  EditProfileTransformedResponse,
} from 'src/services/profile/edit-profile.types';

import {
  EditEMRProfileErrorResponse,
  EditEMRProfileParams as EditEMRProfileServiceParams,
  EditEMRProfileTransformedResponse,
} from 'src/services/profile-emr/edit-emr-profile.types';

export enum EditProfileActionType {
  EDIT_PROFILE_START = 'EDIT_PROFILE_START',
  EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_ERROR = 'EDIT_PROFILE_ERROR',
  EDIT_EMR_PROFILE_START = 'EDIT_EMR_PROFILE_START',
  EDIT_EMR_PROFILE_SUCCESS = 'EDIT_EMR_PROFILE_SUCCESS',
  EDIT_EMR_PROFILE_ERROR = 'EDIT_EMR_PROFILE_ERROR',
}

export type EditProfileParams = EditProfileServiceParams;
export type EditProfileSuccessPayload = EditProfileTransformedResponse;
export type EditProfileErrorPayload = EditProfileErrorResponse;
export type EditEMRProfileParams = EditEMRProfileServiceParams;
export type EditEMRProfileSuccessPayload = EditEMRProfileTransformedResponse;
export type EditEMRProfileErrorPayload = EditEMRProfileErrorResponse;
export type GetCreatedProfileStartPayload = EditProfileTransformedResponse;

export type fetchProfileForEditStartActionPayload = { profileId: number };
export type fetchProfileForEditSuccessActionPayload = Profile;
export type fetchProfileForEditErrorActionPayload = GetProfileErrorResponse;
export type RRFLoadAction = any;

export type EditProfileStartAction = PayloadAction<
  EditProfileActionType.EDIT_PROFILE_START,
  EditProfileParams
>;

export type EditProfileSuccessAction = PayloadAction<
  EditProfileActionType.EDIT_PROFILE_SUCCESS,
  EditProfileSuccessPayload
>;

export type EditProfileErrorAction = PayloadAction<
  EditProfileActionType.EDIT_PROFILE_ERROR,
  EditProfileErrorResponse
>;

export type EditEMRProfileStartAction = PayloadAction<
  EditProfileActionType.EDIT_EMR_PROFILE_START,
  EditProfileParams
>;

export type EditEMRProfileSuccessAction = PayloadAction<
  EditProfileActionType.EDIT_EMR_PROFILE_SUCCESS,
  EditEMRProfileSuccessPayload
>;

export type EditEMRProfileErrorAction = PayloadAction<
  EditProfileActionType.EDIT_EMR_PROFILE_ERROR,
  EditEMRProfileErrorResponse
>;

export type EditProfileActions =
  | EditProfileStartAction
  | EditProfileSuccessAction
  | EditProfileErrorAction
  | EditEMRProfileStartAction
  | EditEMRProfileSuccessAction
  | EditEMRProfileErrorAction
  | RRFLoadAction;

export type EditProfileState = ProfileFormState & {
  editHasError: boolean;
  editWasSuccessful: boolean;
  openConnectivity: string;
};
