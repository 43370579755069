export const mockResponse = {
  data: {
    HCPIsActive: 'true',
    billAddressCountry: 'Argentina',
    SmartPix_ID: '2325450',
    billAddressCountryCode: 'ar',
    HCPIsAccessible: 'true',
    HCP_ID: '',
    homeCountryCode: 'es',
    AppUpdateSource: 'SmartPix',
    Cypher_Cloud_Gateway: 'EMEA',
    FHIR_UserType: 'Practitioner',
    homeCountry: 'Spain',
  },
  profile: {
    firstName: 'HCP Checa',
    lastName: 'Lendi Depart',
    email: 'lendi.dev.checa.hcp@yopmail.com',
    gender: 'u',
    languages: 'en',
    locale: 'en-US',
    phones: [],
  },
  preferences: {
    spolProfessional: {
      disclaimer: {
        isConsentGranted: true,
        docVersion: 4,
        lang: 'en',
        lastConsentModified: '2022-08-05T07:10:59.660Z',
        actionTimestamp: '2022-08-05T07:10:59.660Z',
        tags: [],
        customData: [],
        entitlements: [],
      },
    },
    rdac: {
      terms: {
        isConsentGranted: true,
        docVersion: 3,
        lang: 'en',
        lastConsentModified: '2022-11-17T10:46:21.759Z',
        actionTimestamp: '2022-11-17T10:46:21.759Z',
        tags: [],
        customData: [
          { key: 'idx', value: '3' },
          { key: 'mandatory', value: 'true' },
          { key: 'LinkTitle', value: 'Terms of service' },
        ],
        entitlements: [],
        locales: { en: { docVersion: 3 } },
      },
      isAdult: {
        isConsentGranted: true,
        docVersion: 1,
        lang: 'en',
        lastConsentModified: '2022-11-17T10:46:21.759Z',
        actionTimestamp: '2022-11-17T10:46:21.759Z',
        tags: [],
        customData: [
          { key: 'idx', value: '1' },
          { key: 'mandatory', value: 'true' },
        ],
        entitlements: [],
        locales: { en: { docVersion: 1 } },
      },
      privacy: {
        isConsentGranted: true,
        docVersion: 4,
        lang: 'en',
        lastConsentModified: '2022-11-17T10:46:21.759Z',
        actionTimestamp: '2022-11-17T10:46:21.759Z',
        tags: [],
        customData: [
          { key: 'mandatory', value: 'true' },
          { key: 'idx', value: '2' },
          { key: 'LinkTitle', value: 'Privacy Notice' },
        ],
        entitlements: [],
        locales: { en: { docVersion: 4 } },
      },
    },
    spolPatient: {
      disclaimer: {
        isConsentGranted: true,
        docVersion: 2,
        lang: 'en',
        lastConsentModified: '2022-03-21T11:51:57.381Z',
        actionTimestamp: '2022-03-21T11:51:57.381Z',
        tags: [],
        customData: [],
        entitlements: [],
      },
    },
  },
  isVerified: true,
  isRegistered: true,
};
