import { createRequestActions } from 'src/core/request/request.actions';
import { PRESCRIPTION_ACTIONS } from 'src/core/prescription/prescription.constants';

import {
  GET_CLINIC_GUIDES_REQUEST,
  SAVE_CLINIC_GUIDE_REQUEST,
  DELETE_CLINIC_GUIDE_REQUEST,
} from './clinic-guide.constants';

export const getClinicGuidesRequest = createRequestActions(
  GET_CLINIC_GUIDES_REQUEST,
);

export const saveClinicGuideRequest = createRequestActions(
  SAVE_CLINIC_GUIDE_REQUEST,
);

export const deleteClinicGuideRequest = createRequestActions(
  DELETE_CLINIC_GUIDE_REQUEST,
);

export const setPrescriptionClinicGuideType = ({ id, isCustom }) => ({
  type: PRESCRIPTION_ACTIONS.SET_PRESCRIPTION_CLINIC_GUIDE_TYPE,
  payload: { id, isCustom },
});

export const showCustomClinicGuidesForm = () => ({
  type: PRESCRIPTION_ACTIONS.SHOW_CUSTOM_CLINIC_GUIDES_FORM,
});

export const hideCustomClinicGuidesForm = () => ({
  type: PRESCRIPTION_ACTIONS.HIDE_CUSTOM_CLINIC_GUIDES_FORM,
});
