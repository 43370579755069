import { Action, PayloadAction } from 'src/app/store/app.types';
import { EditPatientWithFhirParams } from 'src/services/patient/edit-patient-with-fhir/edit-patient-with-fhir.types';

export enum EditPatientWithFhirActionType {
  EDIT_PATIENT_WITH_FHIR_START = 'EDIT_PATIENT_WITH_FHIR_START',
  EDIT_PATIENT_WITH_FHIR_SUCCESS = 'EDIT_PATIENT_WITH_FHIR_SUCCESS',
  EDIT_PATIENT_WITH_FHIR_ERROR = 'EDIT_PATIENT_WITH_FHIR_ERROR',
  CLEAR_EDIT_PATIENT_WITH_FHIR_STATE = 'CLEAR_EDIT_PATIENT_WITH_FHIR_STATE',
}

export type EditPatientWithFhirStartPayloadType = EditPatientWithFhirParams;

export type EditPatientWithFhirStartAction = PayloadAction<
  EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_START,
  EditPatientWithFhirStartPayloadType
>;

export type EditPatientWithFhirSuccessAction = PayloadAction<
  EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_SUCCESS,
  any
>;

export type EditPatientWithFhirErrorAction =
  Action<EditPatientWithFhirActionType.EDIT_PATIENT_WITH_FHIR_ERROR>;

export type ClearEditPatientWithFhitStateAction =
  Action<EditPatientWithFhirActionType.CLEAR_EDIT_PATIENT_WITH_FHIR_STATE>;

export type EditPatientWithFhirActions =
  | EditPatientWithFhirStartAction
  | EditPatientWithFhirSuccessAction
  | EditPatientWithFhirErrorAction
  | ClearEditPatientWithFhitStateAction;

export type EditPatientWithFhirState = {
  editionWithFhirHasError: boolean;
  editionWithFhirWasSuccessful: boolean;
  isEditingWithFhirPatient: boolean;
};
