import React from 'react';

import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';
import { LOGBOOK_UNITS_UNIT } from 'src/domains/diagnostics/constants/logbook.constants';
import { colors } from 'src/core/styles/colors';
import { fontSize } from 'src/core/styles/font-sizes';
import {
  PumpProfileChangeIcon,
  PumpRunIcon,
  PumpStopIcon,
} from 'src/assets/icons';

import { LogbookTableCell } from 'src/domains/diagnostics/components/table/logbook-table-cell/logbook-table-cell.component';

const SubheaderCells = ({
  children,
  color,
  fontSize,
  keyText,
  paddingLeft,
  textAlign,
  width,
}) => [
  <LogbookTableCell
    key={`${keyText}-cell`}
    p={1}
    fontSize={fontSize}
    textAlign={textAlign}
    paddingLeft={paddingLeft}
    color={color}
    height="auto"
    width={width}
  >
    {children}
  </LogbookTableCell>,
];

const DateHeader = ({ keyText, color }) => [
  <SubheaderCells key={`${keyText}-date`} color={color} />,
];

const TimeHeader = ({ keyText, color, is12hourTimeFormat }) => [
  <SubheaderCells
    key={`${keyText}-time`}
    color={color}
    is12hourTimeFormat={is12hourTimeFormat ? '4.8rem' : '3.8rem'}
  />,
];

const BloodGlucoseUnitHeader = ({ color, fontSize, keyText, children }) => (
  <SubheaderCells
    key={`${keyText}-unit`}
    textAlign="left"
    fontSize={fontSize}
    color={color}
  >
    {children}
  </SubheaderCells>
);

const CarbsHeader = ({ keyText, color, fontSize, children }) => [
  <SubheaderCells
    key={`${keyText}-time`}
    color={color}
    fontSize={fontSize}
    children={children}
  />,
];

const InsulinHeader = ({ children, color, fontSize, keyText, textAlign }) =>
  children.map((child, i) => (
    <SubheaderCells
      key={`${keyText}-unit-${i}`}
      textAlign={textAlign}
      fontSize={fontSize}
      color={color}
    >
      {<LocalizedText textKey={child} />}
    </SubheaderCells>
  ));

const BasalRateHeader = ({ keyText, color }) => [
  <SubheaderCells key={`${keyText}-time`} color={color} />,
];

const PumpHeader = ({ children, keyText, color, textAlign }) => {
  const printChildren = children.map((child, i) => (
    <SubheaderCells
      key={`${keyText}-icon-${i}`}
      color={color}
      textAlign={textAlign}
    >
      {child}
    </SubheaderCells>
  ));

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      {printChildren}
    </div>
  );
};

const BolusAdviceHeader = ({ keyText, color }) => [
  <SubheaderCells key={`${keyText}-time`} color={color} />,
];

export const LogbookDiaryTableSubheader = ({
  bloodGlucoseUnit,
  is12hourTimeFormat,
  t,
  carbLabel,
}) => [
  <DateHeader key="dateHeader" color={colors.blueMarine} />,
  <TimeHeader
    key="timeHeader"
    color={colors.blueMarine}
    is12hourTimeFormat={is12hourTimeFormat}
  />,
  <BloodGlucoseUnitHeader
    key="bgUnitHeader"
    fontSize={fontSize.caption}
    color={colors.blueMarine}
  >
    {<LocalizedText textKey={LOGBOOK_UNITS_UNIT[bloodGlucoseUnit]} />}
  </BloodGlucoseUnitHeader>,
  <CarbsHeader
    key="carbsHeader"
    textAlign="center"
    fontSize={fontSize.caption}
    color={colors.blueMarine}
  >
    {<LocalizedText textKey={carbLabel} />}
  </CarbsHeader>,
  <InsulinHeader
    key="insulinHeader"
    textAlign="center"
    fontSize={fontSize.caption}
    color={colors.blueMarine}
  >
    {[
      'graphs.logbookDiary.bolus',
      'graphs.logbookDiary.basal',
      'graphs.logbookDiary.other',
    ]}
  </InsulinHeader>,
  <BasalRateHeader key="basalRateHeader" color={colors.blueMarine} />,
  <PumpHeader key="pumpHeader" color={colors.blueMarine} textAlign="center">
    <PumpProfileChangeIcon width={17} />
    <PumpRunIcon title={t('graphs.iconTitles.pumpRun')} width={17} />
    <PumpStopIcon title={t('graphs.iconTitles.pumpStop')} width={17} />
  </PumpHeader>,
  <BolusAdviceHeader key="bolusAdviceHeader" color={colors.blueMarine} />,
];
