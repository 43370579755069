import styled from 'styled-components';

import { spacing } from 'src/core/styles/spacing';
import { colors } from 'src/core/styles/colors';
import { convertPxToRem, combineRems } from 'src/utils/rem-calc';

export const LoadingModalContentDiv = styled.div`
  width: ${convertPxToRem(336)};
  padding: ${combineRems(spacing.three, spacing.four)};
`;

export const ConfirmationButtonWrapperDiv = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${colors.grayLight};
  margin-top: ${combineRems(spacing.three, spacing.four)};
  padding-top: ${combineRems(spacing.three, spacing.four)};
`;
