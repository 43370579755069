import { CGMSummaryResponse } from './cgm-summary.types';

import {
  CGMSummaryLoaderImpl,
  CGMSummaryServiceImpl,
  CGMSummaryTransformImpl,
} from './cgm-summary.service';

export const mock: CGMSummaryResponse = {
  firstMeasurementDate: '2019-10-16T02:40:00.000Z',
  lastMeasurementDate: '2019-12-06T02:40:00.000Z',
  hasRoleFhir: false,
  model: {
    firstMeasurement: '2019-10-16T02:40:00.000Z',
    latestMeasurement: '2019-12-06T02:40:00.000Z',
  },
};

export const mockNull: CGMSummaryResponse = {
  firstMeasurementDate: null,
  lastMeasurementDate: null,
  hasRoleFhir: null,
  model: { firstMeasurement: null, latestMeasurement: null },
};

const mockLoader = (): Promise<any> =>
  new Promise((resolve, reject) => setTimeout(() => resolve(mock), 3500));

export const CGMSummaryFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : CGMSummaryLoaderImpl;
  return CGMSummaryServiceImpl(loader, CGMSummaryTransformImpl);
};
