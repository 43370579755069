import React, { useState } from 'react';
import { t } from 'i18next';
import {
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_BUTTON_VARIANTS,
  JELLO_ICON_NAMES,
} from '../../../../../app/app.jello.constants';
import { selectIsPayer } from '../../../../general/widgets/store/hcp-dashboard.selectors';
import {
  selectPatientHasData,
  selectPatientInfo,
  selectPatientIsEMRCompleted,
  selectPatientReportStatus,
} from 'src/widgets/top-navigation/pdf-report/pdf-report.selector';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { selectIsHCPOrGPOrAdminUserProfile } from 'src/core/user/user.selectors';
import { selectPatientFhirId } from 'src/core/patient/patient.selector';
import {
  selectIsEMR,
  selectIsPatientEMR,
} from 'src/core/permissions/permissions.selectors';
import { routes } from 'src/domains/routes';
import styles from './styles.module.css';
import {
  REPORTS_DIALOG_ID,
  SHOW_REPORTS_MODAL_EVENT,
} from 'src/modules/reports-module/reports.constants';
import { countlyEventTrigger } from 'src/navigation/countly';
import { EVENTS_KEYS } from 'src/widgets/top-navigation/menu-dropdown/menu-dropdown.countly.constants';
import {
  selectPatientEndDate,
  selectPatientStartDate,
} from 'src/domains/diagnostics/store/selectors/patient-date-range.selector';
import { savePatientReportsStart } from 'src/core/patient-reports/patient-reports.action';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { ExportToEMRModal } from 'src/components/pdf-report/export-to-EMR-modal.component';
import { DateTime } from 'luxon';
import { isHomeRoute } from 'src/utils/path-matchers';

const mapStateToProps = (state, props) => ({
  isHCPOrGPOrAdminUserProfile: selectIsHCPOrGPOrAdminUserProfile(state),
  isHcpEMR: selectIsEMR(state),
  isPatientEMR: selectIsPatientEMR(state),
  isPayer: selectIsPayer(state),
  patientHasData: selectPatientHasData(state),
  patientFhirId: selectPatientFhirId(state),
  isPatientEMRCompleted: selectPatientIsEMRCompleted(state),
  patientReportStatus: selectPatientReportStatus(state),
  patientStartDate: selectPatientStartDate(state),
  patientEndDate: selectPatientEndDate(state),
  patient: selectPatientInfo(state),
  fhirId: selectPatientFhirId(state),
});

const mapDispatchToProps = mapDispatchers({
  onSavePatientReports: savePatientReportsStart,
});

export const DocumentsExportSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const [isExportToEMROpen, setIsExportToEMROpen] = useState(false);

  const onClickPrintAndDownloadButton = () => {
    const dialog = document.querySelector(`#${REPORTS_DIALOG_ID}`);
    dialog
      ? dialog.dispatchEvent(new CustomEvent(SHOW_REPORTS_MODAL_EVENT))
      : console.error('Reports dialog not found');
    countlyEventTrigger(EVENTS_KEYS.PRINT_AND_DOWNLOAD_ICON_CLICKED, {});
  };

  const onClickExportToEMR = () => {
    callSavePatientReports();
    setIsExportToEMROpen(true);
  };

  const callSavePatientReports = () => {
    const { onSavePatientReports, patientStartDate, patientEndDate, fhirId } =
      props;
    const patientStartDateIso = toIso(patientStartDate);
    const patientEndDateIso = toIso(patientEndDate);
    onSavePatientReports({
      patientId: fhirId,
      reportType: 'EMR',
      startDate: patientStartDateIso,
      endDate: patientEndDateIso,
    });
  };

  const toIso = (stringDate: DateTime) =>
    stringDate.toUTC().startOf('day').toISODate();

  const isBGDashboardTab = !!matchPath(location.pathname, {
    path: routes.patient.patientBGDashboard,
    exact: true,
    strict: false,
  });

  const canDisplayPrintAndDownload =
    props.isHCPOrGPOrAdminUserProfile && !isHomeRoute();

  const isPrintAndDownloadEnabled = !isBGDashboardTab ? { disabled: '' } : {};

  const canDisplayExportEMR =
    props.isHcpEMR && props.isPatientEMR && !props.isPayer;

  const isExportEMREnabled =
    Boolean(props.patientHasData) &&
    Boolean(props.patientFhirId) &&
    Boolean(props.isPatientEMRCompleted)
      ? {}
      : { disabled: '' };

  return (
    <>
      {(canDisplayPrintAndDownload || canDisplayExportEMR) && (
        <section
          className={styles.buttonsSection}
          data-testid="patient-actions-buttons-section"
        >
          {canDisplayPrintAndDownload && (
            <jello-tooltip
              role="tooltip"
              title="Print & Download button"
              label={t('patientBar.printAndDownloadTooltipText')}
            >
              <jello-icon-button
                data-testid="print-and-download-button"
                size={JELLO_ICON_BUTTON_SIZES.L}
                variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
                icon-name={JELLO_ICON_NAMES.PRINTER}
                onClick={onClickPrintAndDownloadButton}
                {...isPrintAndDownloadEnabled}
              />
            </jello-tooltip>
          )}
          {canDisplayExportEMR && (
            <>
              <jello-tooltip
                role="tooltip"
                title="Export to EMR button"
                label={t('patientBar.exportToEMRTooltipText')}
              >
                <jello-icon-button
                  data-testid="export-to-emr"
                  size={JELLO_ICON_BUTTON_SIZES.L}
                  variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
                  icon-name={JELLO_ICON_NAMES.DOCUMENT_EXPORT}
                  onClick={onClickExportToEMR}
                  {...isExportEMREnabled}
                />
              </jello-tooltip>
              <ExportToEMRModal
                isOpen={isExportToEMROpen}
                setIsOpen={setIsExportToEMROpen}
                patient={props.patient}
                patientEndDate={props.patientEndDate}
                patientStartDate={props.patientStartDate}
                patientReportStatus={props.patientReportStatus}
                onRetry={callSavePatientReports}
              />
            </>
          )}
        </section>
      )}
    </>
  );
});
