export const MANUFACTURER_INFO_MANUFACTURER_KEY =
  'manufacturerInfo.manufacturer';
export const MANUFACTURER_INFO_ADDRESS_LINE1_KEY =
  'manufacturerInfo.addressLine1';
export const MANUFACTURER_INFO_ADDRESS_LINE2_KEY =
  'manufacturerInfo.addressLine2';
export const MANUFACTURER_INFO_ADDRESS_LINE3_KEY =
  'manufacturerInfo.addressLine3';
export const MANUFACTURER_INFO_ADDRESS_LINE4_KEY =
  'manufacturerInfo.addressLine4';
export const MANUFACTURER_INFO_MANUFACTURE_DATE_KEY =
  'manufacturerInfo.manufactureDate';
export const MANUFACTURER_INFO_GEN_INFO_RDCP_DATE_KEY =
  'manufacturerInfo.genInfoRdcpDate';
export const MANUFACTURER_SWITZ_NAME_KEY = 'manufacturerSwitz.name';
export const MANUFACTURER_SWITZ_ADDRESS_LINE1_KEY =
  'manufacturerSwitz.addressLine1';
export const MANUFACTURER_SWITZ_ADDRESS_LINE2_KEY =
  'manufacturerSwitz.addressLine2';
export const MANUFACTURER_SWITZ_ADDRESS_LINE3_KEY =
  'manufacturerSwitz.addressLine3';
export const MANUFACTURER_SWITZ_ADDRESS_LINE4_KEY =
  'manufacturerSwitz.addressLine4';
export const MANUFACTURER_SWITZ_PHONE_NUMBER_KEY =
  'manufacturerSwitz.phoneNumber';
export const MANUFACTURER_SWITZ_EMAIL_KEY = 'manufacturerSwitz.email';
export const MANUFACTURER_BRAZIL_NAME_KEY = 'manufacturerBrazil.name';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE1_KEY =
  'manufacturerBrazil.addressLine1';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE2_KEY =
  'manufacturerBrazil.addressLine2';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE3_KEY =
  'manufacturerBrazil.addressLine3';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE4_KEY =
  'manufacturerBrazil.addressLine4';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE5_KEY =
  'manufacturerBrazil.addressLine5';
export const MANUFACTURER_BRAZIL_ADDRESS_LINE6_KEY =
  'manufacturerBrazil.addressLine6';
export const MANUFACTURER_INFO_EXPLANATION_SYMBOLS_DEVICE_ID_KEY =
  'manufacturerInfo.explanationSymbols.deviceId';
export const MANUFACTURER_INFO_GENERAL_KEY = 'manufacturerInfo.general';
export const MANUFACTURER_INFO_VALID_INFO_FOR_KEY =
  'manufacturerInfo.validInfoFor';
export const MANUFACTURER_INFO_VERSION_KEY = 'manufacturerInfo.version';
export const MANUFACTURER_INFO_CAUTION_KEY =
  'manufacturerInfo.explanationSymbols.caution';
export const MANUFACTURER_INFO_MED_DEVICE_KEY =
  'manufacturerInfo.explanationSymbols.medDevice';
export const MANUFACTURER_INFO_CE_INFO_KEY = 'manufacturerInfo.ceInfo';
export const MANUFACTURER_INFO_CONSULT_ELECTRONIC_KEY =
  'manufacturerInfo.consultElectronic';
export const MANUFACTURER_INFO_EXPLANATION_SYMBOLS_TITLE_KEY =
  'manufacturerInfo.explanationSymbols.title';
export const MANUFACTURER_INFO_EXPLANATION_SYMBOLS_DESCRIPTION_KEY =
  'manufacturerInfo.explanationSymbols.description';

export const MANUFACTURER_ICON_ALT = 'manufacturer icon';
export const DATE_OF_MANUFACTURE_ICON_ALT = 'date of manufacture icon';
export const IMPORTER_CH_ICON_ALT = 'importer CH icon';
export const MEDICAL_DEVICE_ICON_ALT = 'medical device icon';
export const MEDICAL_DEVICE_ID_ICON_ALT = 'medical device id icon';
export const CE_CODE_ICON_ALT = 'CE code icon';
export const ADDITIONAL_INFORMATION_ALT = 'additional information';
export const IMPORTER_BR_ICON_ALT = 'importer BR icon';
