import {
  CheckDataSharingConsentState,
  CheckDataSharingConsentActions,
  CheckDataSharingConsentActionType,
} from './check-data-sharing-consent.types';

export const checkDataSharingConsentInitialState: CheckDataSharingConsentState =
  {
    checkDataSharingHasBeenCalled: false,
    isCheckingDataSharingConsent: false,
    checkDataSharingConsentSuccessful: false,
    checkDataSharingConsentHasError: false,
    dataSharingConsentResponse: 0,
    status: 0,
    last_update: '',
  };

export const checkDataSharingConsentReducer = (
  state = checkDataSharingConsentInitialState,
  action: CheckDataSharingConsentActions,
): CheckDataSharingConsentState => {
  switch (action.type) {
    case CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_START:
      return {
        ...state,
        checkDataSharingHasBeenCalled: true,
        isCheckingDataSharingConsent: true,
        checkDataSharingConsentSuccessful: false,
        checkDataSharingConsentHasError: false,
      };
    case CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_SUCCESS:
      return {
        checkDataSharingHasBeenCalled: true,
        isCheckingDataSharingConsent: false,
        checkDataSharingConsentSuccessful: true,
        checkDataSharingConsentHasError: false,
        dataSharingConsentResponse: action.payload?.status,
        status: action.payload?.status,
        last_update: action.payload?.last_update,
      };
    case CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_ERROR:
      return {
        checkDataSharingHasBeenCalled: true,
        isCheckingDataSharingConsent: false,
        checkDataSharingConsentSuccessful: false,
        checkDataSharingConsentHasError: true,
        dataSharingConsentResponse: action.payload?.status,
        status: action.payload?.errorResponseBody?.status,
        last_update: action.payload?.errorResponseBody?.last_update,
      };
    case CheckDataSharingConsentActionType.CLEAR_CHECK_DATA_SHARING_STATE:
      return checkDataSharingConsentInitialState;

    default:
      return state;
  }
};
