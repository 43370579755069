import { path } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';

export const selectLatestPatients = path(['latestPatients']);

export const selectLatestPatientsResults = createSelector(
  selectLatestPatients,
  path(['results']),
);

export const selectIsFetching = createSelector(
  selectLatestPatients,
  path(['isFetching']),
);

export const selectLatestPatientsError = createSelector(
  selectLatestPatients,
  path(['error']),
);

export const latestPatientsConnector = createStructuredSelector({
  latestPatientsResults: selectLatestPatientsResults,
  isFetchingLatestPatients: selectIsFetching,
  latestPatientsError: selectLatestPatientsError,
});
