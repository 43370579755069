import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  CreatedPatient,
  CreatePatientActionType,
  CreatePatientErrorAction,
  CreatePatientErrorPayload,
  CreatePatientParams,
  CreatePatientStartAction,
  CreatePatientSuccessAction,
  CreatePatientSuccessPayload,
  GetCreatedPatientErrorAction,
  GetCreatedPatientStartAction,
  GetCreatedPatientStartPayload,
  GetCreatedPatientSuccessAction,
} from './create-patient.types';

export const createPatientStart = (
  payload: CreatePatientParams,
): CreatePatientStartAction =>
  createPayloadAction(CreatePatientActionType.CREATE_PATIENT_START, payload);

export const createPatientSuccess = (
  payload: CreatePatientSuccessPayload,
): CreatePatientSuccessAction =>
  createPayloadAction(CreatePatientActionType.CREATE_PATIENT_SUCCESS, payload);

export const createPatientError = (
  payload: CreatePatientErrorPayload,
): CreatePatientErrorAction =>
  createPayloadAction(CreatePatientActionType.CREATE_PATIENT_ERROR, payload);

export const getCreatedPatientStart = (
  payload: GetCreatedPatientStartPayload,
): GetCreatedPatientStartAction =>
  createPayloadAction(
    CreatePatientActionType.GET_CREATED_PATIENT_START,
    payload,
  );

export const getCreatedPatientSuccess = (
  payload: CreatedPatient,
): GetCreatedPatientSuccessAction =>
  createPayloadAction(
    CreatePatientActionType.GET_CREATED_PATIENT_SUCCESS,
    payload,
  );

export const getCreatedPatientError = (): GetCreatedPatientErrorAction =>
  createAction(CreatePatientActionType.GET_CREATED_PATIENT_ERROR);
