import {
  putJSON,
  createAuthHeader,
  stringifyBody,
} from 'src/utils/service/service.utils';
import { ENDPOINTS } from 'src/services/service.constants';
import {
  UnblindBodyData,
  UnblindPatientLoaderImpl,
} from 'src/services/patient/unblind-clinical-study-patient/unblind-clinical-study-patient.types';

export const UnblindClinicalStudyPatientLoaderImpl: UnblindPatientLoaderImpl = (
  accessToken: string,
  apiKey: string,
  patientId: string,
) => {
  const body: UnblindBodyData = {
    grant: false,
    functionality: 'ROLE_BLINDED_STUDY',
  };

  return putJSON(ENDPOINTS.unblindClinicalStudyPatient, {
    headers: {
      Authorization: createAuthHeader(accessToken),
      apiKey,
      patientId,
    },
    body: stringifyBody(body),
  });
};

export const UnblindClinicalStudyPatientTransformIml = (response) => response;

export const UnblindClinicalStudyPatientServiceImpl =
  (put, transform) => (query: FixMe, accessToken: string, gigyaToken: string) =>
    put(query, accessToken, gigyaToken).then(transform);
