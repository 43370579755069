export const BG_GRAPH_KEYS = {
  BG_GRAPH_CHANGED: 'BG Graph Changed',
};

export const BG_GRAPH_SEGMENT_KEYS = {
  BG_GRAPH_SELECTED: 'New Graph',
};

export const BG_GRAPH_SEGMENT_VALUES = {
  SELECTED_GRAPH_TREND: 'Trend',
  SELECTED_GRAPH_STANDARD_DAY: 'Standard Day',
  SELECTED_GRAPH_STANDARD_WEEK: 'Standard Week',
  SELECTED_GRAPH_METABOLIC_RATE: 'Metabolic Rate',
  SELECTED_GRAPH_INSULIN: 'Insulin',
  SELECTED_GRAPH_INSULIN_PUMP: 'Insulin Pump',
  SELECTED_GRAPH_LOGBOOK: 'Logbook',
  SELECTED_GRAPH_LOGBOOK_DIARY: 'Logbook Diary',
  SELECTED_GRAPH_LOGBOOK_STATS: 'Logbook Stats',
  SELECTED_GRAPH_LOGBOOK_24H: 'Logbook 24h',
  SELECTED_GRAPH_BLOOD_GLUCOSE_GENERAL_STATS: 'Statistics',
  SELECTED_GRAPH_DEVICE_SETTINGS: 'Device Settings',
  NO_NAME_GRAPH_SELECTED: 'No name graph',
};
