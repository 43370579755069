import React, { Fragment } from 'react';

import { RenderIf } from 'src/domains/diagnostics/utils/render-if';
import { HeaderBackgroundImage } from 'src/domains/diagnostics/components/graph/header-card/header-card.style';

import { LogbookWrapper as LogbookWrapperComponent } from './../style/logbook.style';

export const LogbookWrapper = ({
  collapsed = false,
  blueBackground,
  children,
}) => (
  <Fragment>
    <RenderIf validate={!collapsed && blueBackground}>
      <HeaderBackgroundImage />
    </RenderIf>
    <LogbookWrapperComponent blueBackground={blueBackground}>
      {children}
    </LogbookWrapperComponent>
  </Fragment>
);
