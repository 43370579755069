import { mockUnblindClinicalStudyPatient } from './unblind-clinical-study-patient.mock';
import {
  UnblindClinicalStudyPatientLoaderImpl,
  UnblindClinicalStudyPatientServiceImpl,
  UnblindClinicalStudyPatientTransformIml,
} from './unblind-clinical-study-patient.service';
import { UnblindBodyData } from 'src/services/patient/unblind-clinical-study-patient/unblind-clinical-study-patient.types';

const mockLoaderUnblindPatient = (): Promise<UnblindBodyData> =>
  Promise.resolve(mockUnblindClinicalStudyPatient);

export const UnblindClinicalStudyPatientFactoryImp = ({ devMode }) => {
  const loader = devMode
    ? mockLoaderUnblindPatient
    : UnblindClinicalStudyPatientLoaderImpl;
  return UnblindClinicalStudyPatientServiceImpl(
    loader,
    UnblindClinicalStudyPatientTransformIml,
  );
};
