export const ProfessionalFormModel = {
  base: 'createProfessional',
  firstName: '.firstName',
  surname: '.surname',
  dateOfBirthMonth: '.dateOfBirthMonth',
  dateOfBirthDay: '.dateOfBirthDay',
  dateOfBirthYear: '.dateOfBirthYear',
  dateOfBirth: '.dateOfBirth',
  email: '.email',
  phone: '.phone',
  language: '.language',
  professional: '.professional',
  hcpId: '.hcpId',
  openConnectivity: '.openConnectivity',
  departmentProfileId: '.departmentProfileId',
  birthday: '.birthday',
};

export const ProfessionalFormAutocomplete = {
  firstName: 'given-name',
  surname: 'family-name',
  dateOfBirthMonth: 'dateOfBirth-month',
  dateOfBirthDay: 'dateOfBirth-day',
  dateOfBirthYear: 'dateOfBirth-year',
  email: 'email',
  phone: 'phone',
  language: 'language',
  hcpId: 'hcpId',
  professional: 'professional',
};
