// @ts-nocheck
import * as React from 'react';

import { MAX_TABS_TO_SHOW } from 'src/domains/patient-dashboards/components/tabs-header/tabs-header.constans';
import { tabSelectedCountly } from 'src/domains/patient-dashboards/components/tabs-header/tabs-header.utils';

import {
  TabsWrapperSmall,
  TabsWrapperBig,
  Btn,
  Tab,
  Dropdown,
  DropdownTabWrapper,
  DropdownTab,
} from './tabs-header.style';
import { RenderIf } from 'src/utils/render-if';
import { colors } from 'src/core/styles/colors';
import { V3BackIcon } from 'src/assets/icons';
import { TabsProps, TabsState, TabProp } from './tabs-headers.types';
import { throttle } from 'lodash';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'src/utils/i18n/with-translation';

export class TabsHeaderClass extends React.Component<TabsProps, TabsState> {
  public wrapperRef: HTMLElement;
  private ref = React.createRef<HTMLElement>();
  public onResize = throttle(() => {
    this.initComponent();
  }, 1000);

  constructor(props) {
    super(props);
    this.state = {
      itemsState: props.items,
      dropDisplayed: false,
      dropActive: 'right',
      drawCarousel: false,
      tabShow: [],
      tabDrop: [],
    };
  }

  public componentDidMount() {
    if (this.ref.current) {
      this.wrapperRef = this.ref.current;
    }

    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('resize', this.onResize);
    this.initComponent();
  }

  public componentWillUnmount() {
    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('resize', this.onResize);
  }

  public componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      this.setState({
        itemsState: nextProps.items,
      });
      this.initComponent();
    }
  }

  public render() {
    const { breakpoint, match, t, expanded } = this.props;
    const { tabShow, tabDrop, itemsState, drawCarousel } = this.state;
    const sanitizePath = (m, p) => (!p ? m.url : `${m.url}/${p}`);
    const drawTabItem = (path?: string, name?: string) => (
      <React.Fragment>
        <RenderIf validate={!!path}>
          <NavLink to={sanitizePath(match, path)}>{t(name)}</NavLink>
        </RenderIf>
        <RenderIf validate={!path}>{t(name)}</RenderIf>
      </React.Fragment>
    );

    const drawDrop = (tabDrop: any[], active: string) => (
      <DropdownTabWrapper>
        {tabDrop.map((tab: TabProp) => {
          const { activeTitle, name, onClick, path } = tab;
          return (
            <DropdownTab
              key={`tab-drop-${activeTitle}`}
              className={
                activeTitle === active ? `tab-drop active` : `tab-drop`
              }
              onClick={() => {
                this.handleClick();
                this.tabOnClick(tab, onClick);
              }}
            >
              {drawTabItem(path, name)}
            </DropdownTab>
          );
        })}
      </DropdownTabWrapper>
    );

    const drawBtn = (className: string) => {
      const { active } = this.props;
      const { dropActive, dropDisplayed } = this.state;
      const enabled = className === dropActive;
      const iconColor = enabled ? colors.blueMarine : colors.grayLight;
      return (
        <Btn
          className={`btn ${className} ${enabled ? 'enabled' : 'disabled'}`}
          onClick={() => this.toggleDropdown(enabled)}
        >
          <V3BackIcon height={25} strokeColor={iconColor} title={''} />
          <RenderIf validate={dropDisplayed && enabled}>
            <Dropdown ref={this.ref}>{drawDrop(tabDrop, active)}</Dropdown>
          </RenderIf>
        </Btn>
      );
    };

    const drawTabs = (tabs: any[]) =>
      tabs.map((tab: TabProp) => {
        const { activeTitle, name, onClick, path } = tab;
        return (
          <Tab
            data-testid={`tab-${activeTitle}`}
            key={`tab-${activeTitle}`}
            className={activeTitle === this.props.active ? `tab active` : `tab`}
            onClick={() => this.tabOnClick(tab, onClick)}
          >
            {drawTabItem(path, name)}
          </Tab>
        );
      });

    return (
      <RenderIf validate={!!tabShow && !!itemsState}>
        <TabsWrapperSmall bp={breakpoint}>
          <RenderIf validate={drawCarousel}>{drawBtn('left')}</RenderIf>
          {drawTabs(tabShow)}
          <RenderIf validate={drawCarousel}>{drawBtn('right')}</RenderIf>
        </TabsWrapperSmall>
        <TabsWrapperBig expanded={expanded} bp={breakpoint}>
          {drawTabs(itemsState)}
        </TabsWrapperBig>
      </RenderIf>
    );
  }

  private initComponent = () => {
    const { active } = this.props;
    const { itemsState } = this.state;
    const length = itemsState.length;
    const drawCarousel = length > MAX_TABS_TO_SHOW;
    const sliceOne = itemsState.slice(
      0,
      drawCarousel ? MAX_TABS_TO_SHOW : length,
    );
    const sliceTwo = itemsState.slice(
      drawCarousel ? MAX_TABS_TO_SHOW : length,
      length,
    );
    if (sliceOne.filter((i) => i.activeTitle === active).length) {
      this.setState({
        tabShow: sliceOne,
        tabDrop: sliceTwo,
        dropActive: 'right',
        drawCarousel,
      });
    } else {
      this.setState({
        tabShow: sliceTwo,
        tabDrop: sliceOne,
        dropActive: 'left',
        drawCarousel,
      });
    }
  };
  private handleClick = () => {
    setTimeout(() => {
      this.initComponent();
    }, 0);
  };

  private toggleDropdown = (enabled: boolean) => {
    if (enabled) {
      const { dropDisplayed } = this.state;
      this.setState({ dropDisplayed: !dropDisplayed });
    }
  };

  private onMouseDown = (e) => {
    const { dropActive } = this.state;
    const domElementClass = `.btn.${dropActive}.enabled`;
    const domElementToBeClosed = document.querySelector(domElementClass);
    if (domElementToBeClosed && !domElementToBeClosed.contains(e.target)) {
      this.setState({ dropDisplayed: false });
    }
  };

  private tabOnClick(tab, onClick) {
    onClick();
    tabSelectedCountly(tab);
  }
}

export const TabsHeader = withTranslation(
  compose<TabsProps, TabsState>(withRouter)(TabsHeaderClass),
);
