import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { reject, equals } from 'ramda';
import { UNIT_MMOLL } from 'src/core/patient-date-range/patient-date-range.selector';
import {
  GetTargetRangesLoaderImplType,
  GetTargetRangesServiceImplType,
} from './get-target-ranges.types';
import { ENDPOINTS } from 'src/services/service.constants';
export const GetTargetRangesLoaderImpl: GetTargetRangesLoaderImplType = (
  openId: {
    accessToken: string;
    apiKey: string;
  },
  patientId: string,
) => {
  const headers = {
    Authorization: createAuthHeader(openId.accessToken),
    ApiKey: openId.apiKey,
    patientId,
  };
  return getJSON(ENDPOINTS.targetRanges, {
    headers: reject(equals({}))(headers),
  });
};
export const GetTargetRangesTransformImpl = (results) => {
  const model = results.model;
  const parser = (val) =>
    model.unit === UNIT_MMOLL ? parseFloat(val).toFixed(1) : val;
  return {
    unit: model.unit,
    beforeEating: {
      actualHyper: parser(model.preHyper),
      hyper: parser(model.preHigh),
      warning: parser(model.preLow),
      hypo: parser(model.preHipo),
    },
    afterEating: {
      actualHyper: parser(model.postHyper),
      hyper: parser(model.postHigh),
      warning: parser(model.postLow),
      hypo: parser(model.postHipo),
    },
    beforeBed: {
      actualHyper: parser(model.noctHyper),
      hyper: parser(model.noctHigh),
      warning: parser(model.noctLow),
      hypo: parser(model.noctHipo),
    },
    nonMealRelated: {
      actualHyper: parser(model.nonMealHyper),
      hyper: parser(model.nonMealHigh),
      inTarget: null,
      warning: parser(model.nonMealLow),
      hypo: parser(model.nonMealHipo),
    },
  };
};
export const GetTargetRangesServiceImpl: GetTargetRangesServiceImplType =
  (load: GetTargetRangesLoaderImplType, transform) => (token, query) =>
    load(token, query).then(transform);
