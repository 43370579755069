import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type Props = {
  height?: number,
  fillColorUpper?: string,
  fillColorLower?: string,
};

export const DoubleArrowIcon = ({
  height = 14,
  fillColorUpper = colors.blueMarine5,
  fillColorLower = colors.blueMarine5,
}: Props) => {
  const originalWidth = 10;
  const originalHeight = 14;
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-358.000000, -421.000000)">
          <g transform="translate(229.000000, 270.000000)">
            <g transform="translate(0.000000, 137.000000)">
              <g transform="translate(32.000000, 14.000000)">
                <g transform="translate(98.000000, 1.000000)">
                  <g
                    transform="translate(0.000000, 8.000000)"
                    stroke={fillColorLower}
                    fill={fillColorLower}
                  >
                    <path
                      d="M2.60740991,-1.88557653 C2.46842297,-2.03814116 2.2433694,-2.03814116 2.10452472,-1.88557653 C1.96553778,-1.73316807 1.96553778,-1.48597278 2.10452472,-1.33356431 L5.14146758,2.00021471 L2.1042402,5.33368143 C1.96525327,5.4860899 1.96525327,5.73328519 2.1042402,5.88569365 C2.17366254,5.96189788 2.26470824,6 2.35575393,6 C2.44679962,6 2.53770305,5.96189788 2.60726765,5.88569365 L5.89586649,2.2762989 C5.96258591,2.20306164 6,2.10390244 6,2.00021471 C6,1.89652699 5.96244365,1.79736779 5.89586649,1.72413053 L2.60740991,-1.88557653 Z"
                      transform="translate(4.000000, 2.000000) scale(-1, 1) rotate(-270.000000) translate(-4.000000, -2.000000) "
                    />
                  </g>
                  <g
                    transform="translate(4.000000, 2.000000) rotate(-180.000000) translate(-4.000000, -2.000000) "
                    stroke={fillColorUpper}
                    fill={fillColorUpper}
                  >
                    <path
                      d="M2.60740991,-1.88557653 C2.46842297,-2.03814116 2.2433694,-2.03814116 2.10452472,-1.88557653 C1.96553778,-1.73316807 1.96553778,-1.48597278 2.10452472,-1.33356431 L5.14146758,2.00021471 L2.1042402,5.33368143 C1.96525327,5.4860899 1.96525327,5.73328519 2.1042402,5.88569365 C2.17366254,5.96189788 2.26470824,6 2.35575393,6 C2.44679962,6 2.53770305,5.96189788 2.60726765,5.88569365 L5.89586649,2.2762989 C5.96258591,2.20306164 6,2.10390244 6,2.00021471 C6,1.89652699 5.96244365,1.79736779 5.89586649,1.72413053 L2.60740991,-1.88557653 Z"
                      transform="translate(4.000000, 2.000000) scale(-1, 1) rotate(-270.000000) translate(-4.000000, -2.000000) "
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
