import { createSelector } from 'reselect';
import { path } from 'ramda';

import { MANIFEST_MODULE_RENDER_LIST } from './manifestModule.constants';
import { generalInfoModuleDataToShow } from './manifestModule.utils';

const moduleArray = MANIFEST_MODULE_RENDER_LIST;

export const selectManifestModulesState = path(['manifestModules']);

export const selectManifestModulesExtraProps = (nameModule: string) =>
  createSelector([selectManifestModulesState], path([nameModule, 'extras']));

export const selectManifestModulesRoutesProps = (nameModule: string) =>
  createSelector(
    [selectManifestModulesState],
    path([nameModule, 'extras', 'routes']),
  );

export const selectManifestModuleName = (nameModule: string) =>
  createSelector([selectManifestModulesState], path([nameModule, 'name']));

export const selectManifestModuleNameListKeys = createSelector(
  selectManifestModulesState,
  (ModuleDataList: object) =>
    moduleArray.map((module) =>
      generalInfoModuleDataToShow(ModuleDataList, module),
    ),
);
