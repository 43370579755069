export const NEXT_SHIPMENT_MODEL = 'nextShipment';
export const NEXT_SHIPMENT_INITIAL_STATE = {
  id: 0,
  shipDate: '',
  shipDateFormatted: '',
  shipStatus: '',
  shipQtty: 0,
  resultDescription: '',
};
export const STATUS = {
  SENT: 'SENT',
  ESTIMATED: 'ESTIMATED',
  PAUSED: 'PAUSED',
  CUT: 'CUT',
  ABORTED: 'ABORTED',
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
};
export const NEXT_SHIPMENT_OK = 'getDeliveryProgramStatusOK';
export const NO_DELIVERY_PROGRAM = 'getDeliveryProgramStatusERROR';
export const NEXT_SHIPMENT_KO = 'getDeliveryProgramStatusKO';
