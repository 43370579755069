import * as React from 'react';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { TextModal, TimePeriodModal } from '../../pdf-report.style';
import { IconWrapper, ButtonWrapper } from './pdf-report-success.style';
import { Button } from 'src/components/button/button.component';
import { CheckmarkIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';

const PdfReportsSuccessComponentModal = ({
  onSuccess,
  text,
  t,
  patientStartDate,
  patientEndDate,
}) => (
  <>
    <IconWrapper>
      <CheckmarkIcon fillColor={colors.white} />
    </IconWrapper>
    <TextModal textAlign="center">
      {t('pdfReport.finalTimePeriod')}{' '}
      <TimePeriodModal>
        {t('pdfReport.to', { patientStartDate, patientEndDate })}
      </TimePeriodModal>
    </TextModal>
    <TextModal textAlign="center">
      {t('pdfReport.pdfFullGenerated', { patientInfo: text })}
    </TextModal>
    <ButtonWrapper>
      <Button onClick={onSuccess} label={t('pdfReport.ok')} />
    </ButtonWrapper>
  </>
);

export const PdfReportsSuccessModal = withTranslation(
  PdfReportsSuccessComponentModal,
);
