import {
  mockPrograms,
  mockPatientProgram,
} from 'src/services/patient/delivery-configuration/get-delivery-configuration/delivery-configuration.mock';

import {
  GetDeliveryConfigurationLoaderImpl,
  GetDeliveryConfigurationServiceImpl,
  GetDeliveryConfigurationTransformImpl,
  GetPatientDeliveryConfigurationLoaderImpl,
  GetPatientDeliveryConfigurationServiceImpl,
  GetPatientDeliveryConfigurationTransformImpl,
} from './delivery-configuration.service';

const mockPosterPrograms = () => Promise.resolve(mockPrograms);

export const GetDeliveryConfigurationFactoryImpl = ({ devMode }) => {
  const loader = devMode
    ? mockPosterPrograms
    : GetDeliveryConfigurationLoaderImpl;
  return GetDeliveryConfigurationServiceImpl(
    loader,
    GetDeliveryConfigurationTransformImpl,
  );
};

const mockPosterPatienProgram = () => Promise.resolve(mockPatientProgram);

export const GetPatientDeliveryConfigurationFactoryImpl = ({ devMode }) => {
  const loader = devMode
    ? mockPosterPatienProgram
    : GetPatientDeliveryConfigurationLoaderImpl;
  return GetPatientDeliveryConfigurationServiceImpl(
    loader,
    GetPatientDeliveryConfigurationTransformImpl,
  );
};
