import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { selectGigyaToken } from 'src/app/session/core/config/config.selectors';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GetLatestPatientsServiceType } from '../../services/get-latest-patients/get-latest-patients.types';
import { CreatePatientActionType } from '../../widgets/patient/create-patient/store/create-patient.types';

import {
  getLatestPatientsError,
  getLatestPatientsStart,
  getLatestPatientsSuccess,
} from './latest-patients.actions';
import {
  GetLatestPatientsStartAction,
  LatestPatientsActions,
  LatestPatientsActionType,
} from './latest-patients.types';

import { selectEC6UserId } from 'src/core/user/user.selectors';

export const getLatestPatientsEpic: (
  getLatestPatientsService: GetLatestPatientsServiceType,
) => Epic<LatestPatientsActions, any> =
  (getLatestPatientsService) => (action$, store) =>
    action$
      .ofType(LatestPatientsActionType.GET_LATEST_PATIENTS_START)
      .switchMap<{}, LatestPatientsActions>((a: GetLatestPatientsStartAction) =>
        Observable.fromPromise(
          getLatestPatientsService(
            selectAccessToken(store.getState()),
            selectGigyaToken(store.getState()),
            selectEC6UserId(store.getState()),
          ),
        )
          .map(getLatestPatientsSuccess)
          .pipe(
            catchError((err) => Observable.of(getLatestPatientsError(err))),
          ),
      );

export const getLatestPatientsAfterPatientCreatedEpic =
  () => (action$, store) =>
    action$
      .ofType(CreatePatientActionType.CREATE_PATIENT_SUCCESS)
      .map(getLatestPatientsStart);
