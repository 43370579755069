import { filter, partition, sort } from 'ramda';

import { createMealTimeMatrix } from '../../logbook.util';

import { MEAL_TIMES } from 'src/domains/diagnostics/constants/logbook.constants';
import { mealTimeSortMap, relatedMealTimesMap } from '../meal-time.constants';
import { getMaxMealTimeRows } from 'src/domains/diagnostics/widgets/logbook/graph/logbook.graph-utils';

const mealTimeIsBeforeMeal = (mealTime) =>
  mealTime === 'BEDTIME' || mealTime.substring(0, 6) === 'BEFORE';
export const getLogbookMealTimeNames = (allMealTimeNames) => [
  'NIGHT',
  ...filter(
    mealTimeIsBeforeMeal,
    sort((a, b) => mealTimeSortMap[a] - mealTimeSortMap[b], allMealTimeNames),
  ),
];
/**
 * Conditionally moves measurements from current mealTime to relatedMealtime
 * (before or after) based on the afterMeal/beforeMeal flags and mealTime value.
 */
export const adjustDayMeasurementsByFlags = (
  dayMeasurementsGroupedByMealtime,
) => {
  const { mealTimes } = dayMeasurementsGroupedByMealtime;

  const adjustedMealTimes = Object.entries(mealTimes).reduce(
    (accumulator, [mealTime, measurementGroup]) => {
      const relatedMealTime = relatedMealTimesMap[mealTime];

      const [toAdjust, noAdjust] = partition(
        ({ afterMeal, beforeMeal }) =>
          (afterMeal && mealTimeIsBeforeMeal(mealTime)) ||
          (beforeMeal && !mealTimeIsBeforeMeal(mealTime)),
      )(measurementGroup);

      return {
        ...accumulator,
        [mealTime]: [...(accumulator[mealTime] || []), ...noAdjust],
        [relatedMealTime]: [
          ...(accumulator[relatedMealTime] || []),
          ...toAdjust,
        ],
      };
    },
    {},
  );

  return {
    day: dayMeasurementsGroupedByMealtime.day,
    date: dayMeasurementsGroupedByMealtime.date,
    mealTimes: adjustedMealTimes,
  };
};

export const groupMeasurementsAsMealTimeCellMatrices = (
  adjustedMeasurementsBasedOnMealTimeFlags,
) =>
  adjustedMeasurementsBasedOnMealTimeFlags.reduce((acc, dayMeasurements) => {
    const { day, date, mealTimes } = dayMeasurements;
    const numberOfRows = getMaxMealTimeRows(mealTimes);
    if (numberOfRows === 0) {
      return acc;
    }
    const logbookMealTimeNames = getLogbookMealTimeNames(
      Object.keys(mealTimes),
    );
    const groupedMealTimes = logbookMealTimeNames.reduce(
      (groupedMealTimesObject, mealTime) => {
        const mealTimeData = mealTimes[mealTime];
        if (!mealTimeData) {
          return groupedMealTimesObject;
        }
        let hasBeforeAndAfterIntervals = true;
        let mealTimeMatrix;
        let numberOfRowsWithContent;
        if (mealTime === MEAL_TIMES.NIGHT || mealTime === MEAL_TIMES.BEDTIME) {
          hasBeforeAndAfterIntervals = false;
          mealTimeMatrix = mealTimeData;
          numberOfRowsWithContent = mealTimeData.length;
        } else {
          const relatedMealTimeData = mealTimes[relatedMealTimesMap[mealTime]];
          mealTimeMatrix = createMealTimeMatrix(
            mealTimeData,
            relatedMealTimeData,
          );
          numberOfRowsWithContent =
            mealTimeData.length + relatedMealTimeData.length;
        }
        return {
          ...groupedMealTimesObject,
          [mealTime]: {
            hasBeforeAndAfterIntervals,
            measurements: mealTimeMatrix,
            numberOfRowsWithContent,
          },
        };
      },
      {},
    );
    return [
      ...acc,
      {
        day,
        date,
        mealTimes: groupedMealTimes,
        numberOfRows,
      },
    ];
  }, []);
