import React from 'react';

import { LogbookTableCell } from 'src/domains/diagnostics/components/table/logbook-table-cell/logbook-table-cell.component';
import { MealTimeBeforeAfterIcons } from 'src/domains/diagnostics/components/table/meal-time-before-after-icons/meal-time-before-after-icons.component';
import { MealTimeIcon } from 'src/domains/diagnostics/components/table/meal-time-icon/meal-time-icon.component';
import { MEAL_TIMES } from '../../../constants/logbook.constants';

export const MealTimeIconsCell = ({ keyText, mealTime, alignItems }) => (
  <LogbookTableCell key={`${mealTime}-${keyText}`} p={1}>
    {mealTime === MEAL_TIMES.NIGHT || mealTime === MEAL_TIMES.BEDTIME ? (
      <MealTimeIcon
        keyText={keyText}
        mealTime={mealTime}
        alignItems={alignItems}
      />
    ) : (
      <MealTimeBeforeAfterIcons
        keyText={keyText}
        mealTime={mealTime}
        alignItems={alignItems}
      />
    )}
  </LogbookTableCell>
);
