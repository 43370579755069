import { t } from 'i18next';

export const generatePeriodsRangeList = [
  {
    label: t('patientBar.sevenDaysPeriod'),
    value: 'option-7',
  },
  {
    label: t('patientBar.fourteenDaysPeriod'),
    value: 'option-14',
  },
  {
    label: t('patientBar.twentyeightDaysPeriod'),
    value: 'option-28',
  },
  {
    label: t('patientBar.customPeriod'),
    value: 'option-custom',
  },
];

export const periodSelectorAccessibilityText = 'range-for-days-in-calendar';
