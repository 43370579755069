import React from 'react';
import { withTheme } from 'styled-components';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { GraphSvgIcon, SvgIconSpan } from 'src/components/icon/icon.component';

const PumpTbrdecIconComponent = ({
  theme,
  height,
  width,
  x,
  y,
  isGraphIcon = true,
  t,
  opacity,
  style,
  onMouseMove,
  onMouseOut,
  onClick,
}) => {
  const originalWidth = 17;
  const originalHeight = 17;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  const iconTitle = t('graphs.iconTitles.pumpTbrDec');

  const coreIcon = (
    <GraphSvgIcon
      title={iconTitle}
      width={calculatedWidth}
      height={calculatedHeight}
      {...{ x, y, originalWidth, originalHeight }}
      titleNotToBeRendered={onMouseMove && true}
    >
      <g
        fill="none"
        fillRule="evenodd"
        opacity={opacity}
        style={style}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        onClick={onClick}
      >
        <rect
          fill={theme.colors.tbrBlue}
          x=".688"
          y=".775"
          width="16"
          height="16"
          rx="1"
        />
        <path
          d="M5.083 6.161a.75.75 0 0 1 .555.245l3.054 3.352 3.045-3.35a.75.75 0 1 1 1.11 1.007l-4.153 4.571-4.166-4.57a.75.75 0 0 1 .555-1.255"
          fill={theme.colors.white}
        />
      </g>
    </GraphSvgIcon>
  );

  return !isGraphIcon ? SvgIconSpan(iconTitle, coreIcon) : coreIcon;
};

export const PumpTbrdecIcon = withTranslation(
  withTheme(PumpTbrdecIconComponent),
);
