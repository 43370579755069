import * as React from 'react';
import {
  BodyRowType,
  HeaderRowType,
} from 'src/domains/diagnostics/components/table-primary/table-primary.types';
import { UnitValue } from '../../mock-data/tables/tables.components.style';
import { keys } from 'ramda';
import { StyledBox } from 'src/domains/diagnostics/components/table-primary/table-primary-cell/table-primary-cell.style';
export interface IGenerateTable {
  getLabelFromAttribute: (attr: string) => any;
  getUnitFromAttribute: (attr: string, units?: any) => any;
  units: any;
  showPreviousValue?: boolean;
  comparePreviousValue?: boolean;
  t?: any;
}
import { StringConstants } from './tab-genera.constants';

export class GenerateTable {
  private getLabelFromAttribute;
  private getUnitFromAttribute;
  private units;
  private showPreviousValue;
  private comparePreviousValue;
  private t;

  constructor({
    getLabelFromAttribute,
    getUnitFromAttribute,
    units,
    comparePreviousValue = true,
    showPreviousValue = false,
    t,
  }: IGenerateTable) {
    this.getLabelFromAttribute = getLabelFromAttribute;
    this.getUnitFromAttribute = getUnitFromAttribute;
    this.units = units;
    this.showPreviousValue = showPreviousValue;
    this.comparePreviousValue = comparePreviousValue;
    this.t = t;
  }

  private getUnitElement(unit: string) {
    return unit ? <UnitValue>{unit}</UnitValue> : '';
  }

  private getValueElement(value: boolean | number) {
    return this.checkBoolean(value) || value === 0
      ? this.checkBoolean(value)
      : '-';
  }

  private getPreviousValueElement(valueToCompare: boolean | number | null) {
    return this.checkBoolean(valueToCompare) || valueToCompare === 0
      ? this.checkBoolean(valueToCompare)
      : '-';
  }

  public getRowTable({ label, value, valueToCompare = null, unit }) {
    const unitElm = this.getUnitElement(unit);
    const valueElm = this.getValueElement(value);
    const valuePrevElm = this.getPreviousValueElement(valueToCompare);
    const result = {
      value: valueElm,
      unit: unitElm,
    };

    if (valueToCompare) {
      if (this.comparePreviousValue && valueToCompare !== value) {
        result[StringConstants.VALUE] = <StyledBox>{valueElm}</StyledBox>;
      }
      if (this.showPreviousValue && valueToCompare !== value) {
        result[StringConstants.VALUE_HL] = valuePrevElm;
      }
    }

    return {
      type: BodyRowType.Basic,
      data: [
        { value: label, cellWidth: 30 },
        { ...result, cellWidth: 70 },
      ],
    };
  }

  public getBodyTable(data, previousData: any = []) {
    return keys(data)
      .map((key) => {
        const label = this.t
          ? this.t(this.getLabelFromAttribute(key))
          : this.getLabelFromAttribute(key);
        const unit = this.getUnitFromAttribute(key, this.units);
        if (label) {
          const valueToCompare = previousData[key];
          return this.getRowTable({
            label,
            value: data[key],
            unit,
            valueToCompare,
          });
        }
        return null;
      })
      .filter((val) => val !== null);
  }

  public getHeaderTable(header) {
    return header.map((row) => {
      return {
        type: HeaderRowType.Extended,
        data: row,
      };
    });
  }

  private checkBoolean(value) {
    if (value === true) {
      return StringConstants.YES;
    } else if (value === false) {
      return StringConstants.NO;
    }
    return value;
  }
}
