import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const SaveDeliveryStatusCommentLoaderImpl = (
  { patientId, deliveryStatusId, comment },
  accessToken,
) =>
  postJSON(
    endpointWithParams(ENDPOINTS.saveDeliveryStatusComment, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
      body: stringifyBody({
        id: deliveryStatusId,
        comment,
      }),
    },
  );

export const SaveDeliveryStatusCommentTransformImpl = ({
  comment,
  createdAt,
  id,
}) => ({
  trafficLightStatusComment: comment,
  trafficLightStatusDateCalculated: createdAt,
  trafficLightStatusId: id,
});

export const SaveDeliveryStatusCommentServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
