import React, { FC } from 'react';
import { t } from 'i18next';
import { HiAndLoValuesAlertProps } from './hi-and-lo-alert.types';
import { JELLO_ALERT_VARIANTS } from 'src/app/app.jello.constants';
import styles from './styles.module.css';

const JELLO_ALERT_HI_AND_LO_VALUES_ID = 'hi-and-lo-alert-id';

export const HiAndLoValuesAlertComponent: FC<HiAndLoValuesAlertProps> = ({
  hiAndLoValues,
}) => {
  const alertContent = (values) => {
    const bothValues = values.numberOfHiValues && values.numberOfLoValues > 0;
    const onlyHiValue = values.numberOfHiValues > 0;
    const onlyLoValue = values.numberOfLoValues > 0;

    let result = '';

    if (bothValues) {
      result = `${values.numberOfHiValues} ${t('bgDashboard.hiAndLoAlert.HI')} -
         ${values.numberOfLoValues} ${t('bgDashboard.hiAndLoAlert.LO')}`;
    } else if (onlyLoValue) {
      result = `${values.numberOfLoValues} ${t('bgDashboard.hiAndLoAlert.LO')}`;
    } else if (onlyHiValue) {
      result = `${values.numberOfHiValues} ${t('bgDashboard.hiAndLoAlert.HI')}`;
    }
    return result;
  };

  return (
    <React.Fragment>
      <jello-alert
        id={JELLO_ALERT_HI_AND_LO_VALUES_ID}
        class={styles.hiAndLoValues}
        variant={JELLO_ALERT_VARIANTS.INFO}
        title-text={t('bgDashboard.hiAndLoAlert.title')}
        link-target="_blank"
        show-close-button
        link-text={t('bgDashboard.hiAndLoAlert.linkText')}
        close-button-accessibility-text={t(
          'bgDashboard.hiAndLoAlert.accessibilityText',
        )}
      >
        {alertContent(hiAndLoValues)}
      </jello-alert>
    </React.Fragment>
  );
};
