import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type Props = {
  height?: number,
  fillColor?: string,
};

export const PickupIcon = ({
  height = 24,
  fillColor = colors.grayMedium,
}: Props) => {
  const originalWidth = 88;
  const originalHeight = 27;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fill={fillColor} fillRule="evenodd">
        <rect width={62} height={27} rx={1.8} />
        <rect x={64} width={24} height={27} rx={1.8} />
      </g>
    </SvgIcon>
  );
};
