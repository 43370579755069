export const listPatientCareActivities: string[] = [
  'ObtainPatientConsentRpm',
  'CreateDataTargetsAndOutOfRange',
  'DiscussedDataTargetsAndOutOfRange',
  'DiscussedPossibleReasonsForOutOfDate',
  'AnalyzedAdaptedDataTargetsAndOutOfRange',
  'EstablishedLabGoals',
  'CommunicatedRpmDataToHcp',
  'ComparedRpmData',
  'ConductedPatientAssessmentData',
  'SuppliedRpmDevice',
  'TrainedPatientOnRpmDevice',
  'AnsweredQuestionOnRpm',
  'ReceivedReturnedRpmDevice',
  'DefinedGoalsOfRpm',
  'EstablishedAndDiscussedTreatmentPlan',
  'InstructedPatientOnOutOfRangePlan',
  'RevisedTreatmentPlan',
  'CommunicatedRevisedTreatmentPlan',
  'ScheduledVisitDueToRpmData',
  'ScheduledVisitDueToPatientRequest',
];

export enum MethodOfCommunication {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  TEXT_CHAT = 'TEXT_CHAT',
  FACE_TO_FACE = 'FACE_TO_FACE',
  VIDEO_CONSULTATION = 'VIDEO_CONSULTATION',
}

export const communicationRadioButton = [
  {
    id: MethodOfCommunication.PHONE,
    value: 'phone',
    label: 'timetracker.modal.PHONE',
  },
  {
    id: MethodOfCommunication.EMAIL,
    value: 'email',
    label: 'timetracker.modal.EMAIL',
  },
  {
    id: MethodOfCommunication.TEXT_CHAT,
    value: 'text chat',
    label: 'timetracker.modal.TEXT_CHAT',
  },
  {
    id: MethodOfCommunication.FACE_TO_FACE,
    value: 'Face-to-face',
    label: 'timetracker.modal.FACE_TO_FACE',
  },
  {
    id: MethodOfCommunication.VIDEO_CONSULTATION,
    value: 'VideoConsultation',
    label: 'timetracker.modal.VIDEO_CONSULTATION',
  },
];
export const patientCareActivitiesData = [
  {
    id: 'ObtainPatientConsentRpm',
    value: 'ObtainPatientConsentRpm',
    label: 'timetracker.modal.ObtainPatientConsentRpm',
  },
];
export const goalsTreatmentsData = [
  {
    id: 'DefinedGoalsOfRpm',
    value: 'DefinedGoalsOfRpm',
    label: 'timetracker.modal.DefinedGoalsOfRpm',
  },
  {
    id: 'EstablishedAndDiscussedTreatmentPlan',
    value: 'EstablishedAndDiscussedTreatmentPlan',
    label: 'timetracker.modal.EstablishedAndDiscussedTreatmentPlan',
  },
  {
    id: 'InstructedPatientOnOutOfRangePlan',
    value: 'InstructedPatientOnOutOfRangePlan',
    label: 'timetracker.modal.InstructedPatientOnOutOfRangePlan',
  },
  {
    id: 'RevisedTreatmentPlan',
    value: 'RevisedTreatmentPlan',
    label: 'timetracker.modal.RevisedTreatmentPlan',
  },
  {
    id: 'CommunicatedRevisedTreatmentPlan',
    value: 'CommunicatedRevisedTreatmentPlan',
    label: 'timetracker.modal.CommunicatedRevisedTreatmentPlan',
  },
  {
    id: 'ScheduledVisitDueToRpmData',
    value: 'ScheduledVisitDueToRpmData',
    label: 'timetracker.modal.ScheduledVisitDueToRpmData',
  },
  {
    id: 'ScheduledVisitDueToPatientRequest',
    value: 'ScheduledVisitDueToPatientRequest',
    label: 'timetracker.modal.ScheduledVisitDueToPatientRequest',
  },
];
export const trainingPatientData = [
  {
    id: 'SuppliedRpmDevice',
    value: 'SuppliedRpmDevice',
    label: 'timetracker.modal.SuppliedRpmDevice',
  },
  {
    id: 'TrainedPatientOnRpmDevice',
    value: 'TrainedPatientOnRpmDevice',
    label: 'timetracker.modal.TrainedPatientOnRpmDevice',
  },
  {
    id: 'AnsweredQuestionOnRpm',
    value: 'AnsweredQuestionOnRpm',
    label: 'timetracker.modal.AnsweredQuestionOnRpm',
  },
  {
    id: 'ReceivedReturnedRpmDevice',
    value: 'ReceivedReturnedRpmDevice',
    label: 'timetracker.modal.ReceivedReturnedRpmDevice',
  },
];
export const dataTargetAnalysisData = [
  {
    id: 'CreateDataTargetsAndOutOfRange',
    value: 'CreateDataTargetsAndOutOfRange',
    label: 'timetracker.modal.CreateDataTargetsAndOutOfRange',
  },
  {
    id: 'DiscussedDataTargetsAndOutOfRange',
    value: 'DiscussedDataTargetsAndOutOfRange',
    label: 'timetracker.modal.DiscussedDataTargetsAndOutOfRange',
  },
  {
    id: 'DiscussedPossibleReasonsForOutOfDate',
    value: 'DiscussedPossibleReasonsForOutOfDate',
    label: 'timetracker.modal.DiscussedPossibleReasonsForOutOfDate',
  },
  {
    id: 'AnalyzedAdaptedDataTargetsAndOutOfRange',
    value: 'AnalyzedAdaptedDataTargetsAndOutOfRange',
    label: 'timetracker.modal.AnalyzedAdaptedDataTargetsAndOutOfRange',
  },
  {
    id: 'EstablishedLabGoals',
    value: 'EstablishedLabGoals',
    label: 'timetracker.modal.EstablishedLabGoals',
  },
  {
    id: 'CommunicatedRpmDataToHcp',
    value: 'CommunicatedRpmDataToHcp',
    label: 'timetracker.modal.CommunicatedRpmDataToHcp',
  },
  {
    id: 'ComparedRpmData',
    value: 'ComparedRpmData',
    label: 'timetracker.modal.ComparedRpmData',
  },
  {
    id: 'ConductedPatientAssessmentData',
    value: 'ConductedPatientAssessmentData',
    label: 'timetracker.modal.ConductedPatientAssessmentData',
  },
];
