import React, { useEffect, useState } from 'react';
import { SidebarMenuComponent } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/sidebar-menu-jello/sidebar-menu-jello.container';
import { PatientDataBarComponent } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/patient-data-bar-jello/patient-data-bar-jello.component';
import { VisitModuleActiveSection } from './visit-module-active-section';

import styles from './styles.module.css';
import { isEmpty } from 'ramda';
import { RenderIf } from 'src/utils/render-if';

export const JelloWrapperComponent = (props) => {
  const { Content } = props;
  const [visitModuleActiveSection, setVisitModuleActiveSection] =
    useState<VisitModuleActiveSection>(VisitModuleActiveSection.CLOSE);

  useEffect(() => {
    props.fetchPatient({ patientId: props.match?.params?.id });
    if (isEmpty(props.patient)) {
      props.fetchPatient({ patientId: props.match?.params?.id });
    }
    if (props.fhirId) {
      props.getCGMSummary({ fhirId: props.fhirId });
    }
    window.addEventListener('vc-module-close-event', handleEvent);
  }, []);

  const handleEvent = () => {
    setVisitModuleActiveSection(VisitModuleActiveSection.CLOSE);
  };

  const contentProps = { ...props, visitModuleActiveSection };

  return (
    <>
      <PatientDataBarComponent
        visitModuleActiveSection={visitModuleActiveSection}
        setVisitModuleActiveSection={setVisitModuleActiveSection}
        {...props}
      />
      <div className={styles.wrapper}>
        <SidebarMenuComponent
          patientId={props?.patient?.id}
          location={window.location}
          permissions={props.permissions}
          patientPermissions={props.patientPermissions}
          cgmSummary={props.cgmSummary}
          activeDevices={props.activeDevices}
          patientProfileType={props.patientProfileType}
          profile={props.profile}
        />
        <RenderIf validate={props.patientPermissions.length}>
          <Content {...contentProps} />
        </RenderIf>
      </div>
    </>
  );
};
