import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon/icon.component';

type Props = {
  height: number,
  fillColor?: string,
  strokeColor?: string,
};

export const V3UploadDataIcon = ({
  fillColor = '#508EE5',
  height = 15,
  strokeColor = colors.white,
}: Props) => {
  const originalWidth = 16.47;
  const originalHeight = 16.47;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      fillColor={fillColor}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
    >
      <g
        id="v3-upload"
        stroke={strokeColor}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Main-Menu"
          transform="translate(-46.000000, -143.000000)"
          fill={fillColor}
          fillRule="nonzero"
        >
          <g id="Group-18-Copy" transform="translate(33.000000, 130.000000)">
            <g id="Group-4">
              <path
                d="M22.4964264,28.5900662 L22.4964264,16.7834581 L27.7895756,21.9415823 C28.2125937,22.3538094 28.9067772,22.3538094 29.3297953,21.9415823 L29.3297953,21.9415823 C29.7528133,21.5293551 29.7528133,20.8634498 29.3297953,20.4512226 L22.1818745,13.4856409 C21.7588565,13.0734138 21.0755196,13.0734138 20.6525015,13.4856409 L13.4937341,20.4406527 C13.0707161,20.8528798 13.0707161,21.5187852 13.4937341,21.9310124 L13.4937341,21.9310124 C13.9167522,22.3432395 14.6000891,22.3432395 15.0231072,21.9310124 L20.327103,16.7834581 L20.327103,28.5900662 C20.327103,29.1714121 20.8152008,29.6470588 21.4117647,29.6470588 L21.4117647,29.6470588 C22.0083287,29.6470588 22.4964264,29.1714121 22.4964264,28.5900662 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
