import { createAuthHeader, getJSON } from 'src/utils/service/service.utils';

import { transformTherapies } from './therapies.util';

import { ENDPOINTS } from 'src/services/service.constants';

export const TherapiesLoaderImpl = (therapies, accessToken) =>
  getJSON(ENDPOINTS.therapies, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });

export const TherapiesTransformImpl = (data) => transformTherapies(data);

export const TherapiesServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
