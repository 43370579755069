import { Action, PayloadAction } from 'src/app/store/app.types';
import { Program } from '../delivery-configuration.types';

export enum DeliveryConfigurationActionType {
  FETCH_DELIVERY_CONFIGURATION_START = 'FETCH_DELIVERY_CONFIGURATION_START',
  FETCH_DELIVERY_CONFIGURATION_SUCCESS = 'FETCH_DELIVERY_CONFIGURATION_SUCCESS',
  FETCH_DELIVERY_CONFIGURATION_ERROR = 'FETCH_DELIVERY_CONFIGURATION_ERROR',
  GET_PATIENT_PROGRAM_SUCCESS = 'GET_PATIENT_PROGRAM_SUCCESS',
  SAVE_DELIVERY_CONFIGURATION_START = 'SAVE_DELIVERY_CONFIGURATION_START',
  SAVE_DELIVERY_CONFIGURATION_SUCCESS = 'SAVE_DELIVERY_CONFIGURATION_SUCCESS',
  SAVE_DELIVERY_CONFIGURATION_ERROR = 'SAVE_DELIVERY_CONFIGURATION_ERROR',
  CHANGE_DELIVERY_CONFIGURATION = 'CHANGE_DELIVERY_CONFIGURATION',
}
export type DeliveryConfigurationActions =
  | FetchDeliveryConfigurationStartAction
  | FetchDeliveryConfigurationSuccessAction
  | FetchDeliveryConfigurationErrorAction
  | getPatientProgramSuccessAction
  | FixMe;

export type FetchDeliveryConfigurationStartAction = PayloadAction<
  DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_START,
  FetchDeliveryConfigurationStartPayloadType
>;

export type FetchDeliveryConfigurationSuccessAction = PayloadAction<
  DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_SUCCESS,
  Program[]
>;

export type FetchDeliveryConfigurationErrorAction =
  Action<DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_ERROR>;

export type getPatientProgramSuccessAction = PayloadAction<
  DeliveryConfigurationActionType.GET_PATIENT_PROGRAM_SUCCESS,
  Program
>;

export type FetchDeliveryConfigurationStartPayloadType = {
  patientId: string;
};

export type DeliveryConfigurationState = {
  isFetchingDeliveryConfiguration: boolean;
  isFetchingDeliveryConfigurationError: boolean;
  isEditingDeliveryConfiguration: boolean;
  isSavingDeliveryConfiguration: boolean;
  isSavingDeliveryConfigurationError: boolean;
  deliveryConfigurationSaved: boolean;
  patientProgram: Program | null;
  configurations: Program[];
};
