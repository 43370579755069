import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { ENDPOINTS } from 'src/services/service.constants';

export const StripModelsLoaderImpl = (
  { clinicId, departmentId }: any,
  accessToken: string,
) => {
  return getJSON(ENDPOINTS.stripModels, {
    headers: {
      Authorization: createAuthHeader(accessToken),
      centerId: clinicId,
      departmentId,
    },
  });
};

export const StripModelsTransform = (results) => {
  const models = results.model;
  return models.map(({ name, modelReference, active, packageUnits }) => ({
    stripModelName: name,
    stripModelReference: modelReference,
    stripModelId: modelReference,
    stripModelActive: active,
    stripModelPackageUnits: packageUnits,
  }));
};

export const StripModelsServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);
