import {
  InvitePatientActions,
  InvitePatientActionType,
  InvitePatientState,
} from './invite-patient.types';

export const invitePatientInitialState: InvitePatientState = {
  isInvitingPatient: false,
  inviteWasSuccessful: false,
  inviteHasError: false,
};

export const invitePatientReducer = (
  state = invitePatientInitialState,
  action: InvitePatientActions,
) => {
  switch (action.type) {
    case InvitePatientActionType.INVITE_PATIENT_START:
      return {
        ...state,
        isInvitingPatient: true,
        inviteWasSuccessful: false,
        inviteHasError: false,
      };
    case InvitePatientActionType.INVITE_PATIENT_SUCCESS:
      return {
        ...state,
        isInvitingPatient: false,
        inviteWasSuccessful: true,
        inviteHasError: false,
      };
    case InvitePatientActionType.INVITE_PATIENT_ERROR:
      return {
        ...state,
        isInvitingPatient: false,
        inviteWasSuccessful: false,
        inviteHasError: true,
      };
    case InvitePatientActionType.RESET_INVITE:
      return invitePatientInitialState;
    default:
      return state;
  }
};
