export const PatientFormModel = {
  patientFormFull: 'createPatient',
  profileType: '.profileType',
  profileTypeFull: 'createPatient.profileType',
  healthInfo: {
    full: 'createPatient.healthInfo',
    base: '.healthInfo',
    diabetesType: '.diabetesType',
    associatedProfessional: '.associatedProfessional',
    dateOfDiagnosis: '.dateOfDiagnosis',
    pregnant: '.pregnant',
    dateOfDiagnosisMonth: '.dateOfDiagnosisMonth',
    dateOfDiagnosisDay: '.dateOfDiagnosisDay',
    dateOfDiagnosisYear: '.dateOfDiagnosisYear',
    gestationalDueDateMonth: '.gestationalDueDateMonth',
    gestationalDueDateDay: '.gestationalDueDateDay',
    gestationalDueDateYear: '.gestationalDueDateYear',
    gestationalDueDate: '.gestationalDueDate',
  },
  patientInfo: {
    full: 'createPatient.patientInfo',
    base: '.patientInfo',
    firstName: '.firstName',
    lastName: '.lastName',
    healthCareId: '.healthCareId',
    email: '.email',
    language: '.language',
    allowPatientAccess: '.allowPatientAccess',
    phoneNumber: '.phoneNumber',
    street: '.street',
    city: '.city',
    province: '.province',
    postalCode: '.postalCode',
    country: '.country',
    dateOfBirthMonth: '.dateOfBirthMonth',
    dateOfBirthDay: '.dateOfBirthDay',
    dateOfBirthYear: '.dateOfBirthYear',
    dateOfBirth: '.dateOfBirth',
    gender: '.gender',
    inseeCode: '.inseeCode',
    proofOfIdentity: '.proofOfIdentity',
    insCode: '.insCode',
    insStatus: '.insStatus',
    validatedStatusDate: '.validatedStatusDate',
    birthNames: '.birthNames',
    usedName: '.usedName',
    usedSurnames: '.usedSurnames',
    birthPlaceCode: '.birthPlaceCode',
    unknownBirthdate: '.unknownBirthdate',
  },
  grantedPermissions: {
    full: 'createPatient.grantedPermissions[]',
    base: '.grantedPermissions[]',
  },
};

export const PatientFormAutocomplete = {
  healthInfo: {
    diabetesType: 'diabetesType',
    associatedProfessional: 'associatedProfessional',
    dateOfDiagnosis: 'dateOfDiagnosis',
    pregnant: 'pregnant',
    dateOfDiagnosisMonth: 'dateOfDiagnosis-month',
    dateOfDiagnosisDay: 'dateOfDiagnosis-day',
    dateOfDiagnosisYear: 'dateOfDiagnosis-year',
    gestationalDueDateMonth: 'gestationalDueDate-month',
    gestationalDueDateDay: 'gestationalDueDate-day',
    gestationalDueDateYear: 'gestationalDueDate-year',
  },
  patientInfo: {
    firstName: 'given-name',
    lastName: 'family-name',
    healthCareId: 'healthCareId',
    email: 'email',
    language: 'language',
    allowPatientAccess: 'allowPatientAccess',
    phoneNumber: 'tel',
    street: 'street-address',
    city: 'address-level2',
    province: 'address-level1',
    postalCode: 'postal-code',
    country: 'country',
    dateOfBirthMonth: 'bday-month',
    dateOfBirthDay: 'bday-day',
    dateOfBirthYear: 'bday-year',
    gender: 'sex',
  },
};

export type FormControlOption = {
  readonly label: string;
  readonly value: string;
};
