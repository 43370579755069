import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from 'react-redux-form';

import {
  changeLogbookType,
  changeGraphType,
  changeGraph,
} from 'src/domains/patient-dashboards/bg/store/bg.actions';
import { mapDispatchers } from 'src/domains/diagnostics/utils/map-dispatchers';
import { fetchPatientRequest } from 'src/domains/diagnostics/core/patient/patient.action';
import {
  getTimeIntervalsRequest,
  getThresholdsRequest,
} from 'src/domains/diagnostics/core/strip-delivery/strip-delivery.actions';
import {
  fetchPatientDateRangeRequest,
  onPatientDateRangeChange,
} from 'src/domains/diagnostics/core/patient-date-range/patient-date-range.action';
import { graphConnector } from 'src/domains/diagnostics/scenes/graphs/graph.selector';

const dispatchers = mapDispatchers({
  changeGraph,
  changeGraphType,
  changeLogbookType,
  onDatesChange: onPatientDateRangeChange,
  onFetchPatient: fetchPatientRequest.start,
  onFetchPatientDateRange: fetchPatientDateRangeRequest.start,
  onGetTimeIntervals: getTimeIntervalsRequest.start,
  onGetThresholdsRequest: getThresholdsRequest.start,
  onHideBloodGlucoseLines: () =>
    actions.change('ui.patientDashboard.showBloodGlucoseLines', false),
});

const addLifeCycles = lifecycle({
  componentWillMount() {
    const { onHideBloodGlucoseLines, isGeneralPractitioner } = this.props;
    if (isGeneralPractitioner) onHideBloodGlucoseLines();
  },
  componentDidMount() {
    this.lastFhirId = null;
    const {
      onFetchPatient,
      onFetchPatientDateRange,
      onGetThresholdsRequest,
      onGetTimeIntervals,
      hasUserFhirPermission,
      patientFhirId,
      match: { params },
      isLoading,
      dateRange = {},
      endDate,
      hcpPermissions,
      patient,
    } = this.props;

    const patientId = params.id;
    const { end = '' } = dateRange;

    if (!isLoading) {
      onFetchPatient({ patientId });
      onGetTimeIntervals({ patientId });
      onGetThresholdsRequest({ patientId });
    }

    if (
      !isLoading &&
      !end &&
      !endDate.isValid &&
      hcpPermissions.length &&
      patient.id
    ) {
      this.lastFhirId = patientFhirId;
      onFetchPatientDateRange({
        patientId,
        hasUserFhirPermission,
        patientFhirId,
      });
    }
  },
  componentDidUpdate() {
    if(this.props.patientFhirId !== this.lastFhirId &&
      this.lastFhirId === null &&
      this.props.history.action === 'POP') {
      this.lastFhirId = this.props.patientFhirId;
      this.props.onFetchPatientDateRange({
        patientId: this.props.match.params.id,
        hasUserFhirPermission: this.props.hasUserFhirPermission,
        patientFhirId: this.props.patientFhirId,
      });
    }
  }
});

export const connectToGraphs = (Component) =>
  compose(
    connect(graphConnector, dispatchers),
    withRouter,
    addLifeCycles,
  )(Component);

export const connectGraphs = (Component) => compose(connectToGraphs)(Component);
