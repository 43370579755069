import * as React from 'react';
import {
  CalendarContainer,
  ButtonContainer,
  DatesContainer,
  IconContainer,
  DatesWrapper,
  PickerContainer,
  CalendarIconContainer,
  DoneButtonWrapper,
  DoneButton,
  NavButtonContainer,
} from './calendar.style';
import {
  V3CalendarIcon,
  V3ForwardIcon,
  V3BackIcon,
  ArrowIcon,
} from 'src/assets/icons';
import { DateRangePicker } from 'react-dates';
import { getClassName, noMeasurements, getDateFormat } from './calendar.utils';
import { t } from 'i18next';
import * as C from '../../calendar.constants';
import { breakpointsMap } from 'src/core/styles/breakpoints';

export const CalendarRender = ({
  wrapWidth,
  startDate,
  endDate,
  focusedInput,
  dateInfo,
  disabled,
  showArrows,
  className,
  id,
  isCgmActive,
  cgmSelectedDate,
  onChangePeriod,
  shoWCalendar,
  getDate,
  handleDatesChange,
  handleFocusChange,
  onChangeDates,
  onHideCalendar,
}) => {
  const isCgmAndDisabled = isCgmActive && disabled;

  const drawButton = (way) => {
    if (!disabled) {
      const buttonHeight = Number(C.BTN_HEIGHT);

      return (
        <ButtonContainer className={way}>
          <IconContainer onClick={() => onChangePeriod(way)}>
            {way === C.PREV && <V3BackIcon height={buttonHeight} />}
            {way === C.NEXT && <V3ForwardIcon height={buttonHeight} />}
          </IconContainer>
        </ButtonContainer>
      );
    }

    return <></>;
  };

  const drawNavButton = (way) => {
    return (
      <NavButtonContainer className={`navBtn${way}`}>
        <ArrowIcon height={8} />
      </NavButtonContainer>
    );
  };

  const renderPresets = (handleClick) => {
    return (
      <DoneButtonWrapper>
        <DoneButton onClick={handleClick}>{t('datePicker.done')}</DoneButton>
      </DoneButtonWrapper>
    );
  };

  const breakPoint = breakpointsMap[1];
  const LARGE_ICON_HEIGHT = 24;
  const SMALL_ICON_HEIGHT = 22;
  const ICON_HEIGHT =
    wrapWidth > breakPoint ? LARGE_ICON_HEIGHT : SMALL_ICON_HEIGHT;

  return (
    <CalendarContainer className={className}>
      {showArrows ? drawButton(C.PREV) : ''}
      <DatesContainer>
        <DatesWrapper>
          <CalendarIconContainer
            disabled={disabled}
            onClick={() => shoWCalendar()}
          >
            <V3CalendarIcon height={ICON_HEIGHT} />
          </CalendarIconContainer>
          <PickerContainer
            className={getClassName(wrapWidth, isCgmAndDisabled)}
          >
            <DateRangePicker
              id={id}
              minimumNights={0}
              startDatePlaceholderText={C.NO_DATA}
              endDatePlaceholderText={C.NO_DATA}
              keepOpenOnDateSelect={true}
              horizontalMargin={C.HORIZONAL_MARGIN}
              daySize={C.DAY_SIZE}
              displayFormat={getDateFormat(wrapWidth)}
              readOnly={true}
              noBorder={true}
              navPrev={drawNavButton(C.PREV)}
              navNext={drawNavButton(C.NEXT)}
              disabled={disabled}
              startDate={getDate(
                disabled,
                startDate,
                isCgmActive,
                cgmSelectedDate,
              )}
              startDateId={C.START_DATE_ID}
              endDate={getDate(disabled, endDate, isCgmActive, cgmSelectedDate)}
              endDateId={C.END_DATE_ID}
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              onFocusChange={handleFocusChange}
              renderCalendarInfo={() =>
                renderPresets(() => {
                  onChangeDates();
                  onHideCalendar();
                })
              }
              isOutsideRange={(d) =>
                noMeasurements(
                  dateInfo.firstMeasurementDate,
                  dateInfo.lastMeasurementDate,
                  d,
                )
              }
            />
          </PickerContainer>
        </DatesWrapper>
      </DatesContainer>
      {showArrows ? drawButton(C.NEXT) : ''}
    </CalendarContainer>
  );
};
