import { createSelector, createStructuredSelector } from 'reselect';
import { flatten, pipe, sort } from 'ramda';

import { selectBloodGlucoseUnit } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import {
  selectGlucoseMeasurementsIncludingNullValues,
  selectInsulin,
  selectGraphThreshold,
  selectAllGraphThresholds,
  selectTimeIntervals,
  selectGraphLoading,
} from 'src/domains/diagnostics/scenes/graphs/logbook/logbook.core.selector';
import {
  groupMeasurementsByDate,
  sortTwoMeasurementsByAscendingDate,
} from 'src/domains/diagnostics/utils/time.util';
import { selectIs12HourFormat } from 'src/core/user/user.selectors';

import { reduceIndexed } from './logbook-diary.util';
import { logbookDiaryRowDataTransformer } from './logbook-diary.utils';
import { measurementPropertiesToBeChecked } from './logbook-diary.constant';
import { selectCarbUnitMeasurementForService } from 'src/core/patient-date-range/patient-date-range.selector';

const sortHours = (measurements) =>
  measurements.map((measurementsGrouped) =>
    measurementsGrouped.sort(
      (measurementA, measurementB) =>
        measurementA.originalDate - measurementB.originalDate,
    ),
  );

export const removeNullValues = (measurements) =>
  measurements.reduce((previous, current) => {
    const measurementEveryValueIsNull = measurementPropertiesToBeChecked.every(
      (property) => current[property] === null,
    );
    if (!measurementEveryValueIsNull) {
      previous.push(current);
    }
    return previous;
  }, []);

export const selectLogbookDiaryData = createSelector(
  selectGlucoseMeasurementsIncludingNullValues,
  selectInsulin,
  selectTimeIntervals,
  selectGraphThreshold,
  selectAllGraphThresholds,
  (measurements, insulin, timeIntervals, thresholds, allThresholds) => {
    const { basals, bolus } = insulin;
    const flattenedMeasurements = flatten([measurements, basals, bolus]);
    const removeValuesMeasurements = removeNullValues(flattenedMeasurements);

    return pipe(
      sort(sortTwoMeasurementsByAscendingDate),
      reduceIndexed(
        logbookDiaryRowDataTransformer(timeIntervals, allThresholds),
        [],
      ),
      groupMeasurementsByDate,
      sortHours,
    )(removeValuesMeasurements);
  },
);

export const logbookDiaryConnector = createStructuredSelector({
  logbookDiaryData: selectLogbookDiaryData,
  isLoading: selectGraphLoading,
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  is12hourTimeFormat: selectIs12HourFormat,
  allGlucosemeasurementswithBolusAdvices:
    selectGlucoseMeasurementsIncludingNullValues,
  carbUnit: selectCarbUnitMeasurementForService,
});
