import styled from 'styled-components';

import { Div } from '../div/div.component';

export const Block = styled(Div)``;

export const BlockNavigation = styled(Div)`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
`;

export const CursorBlock = styled(Block)`
  cursor: pointer;
`;
