import { Action, PayloadAction } from 'src/app/store/app.types';
import {
  CorrelatePatientWithPairingCodeParams,
  correlatePairingCodeTransformedResponseType,
} from 'src/services/pairing-code/pairing-code-correlation/pairing-code-correlation.types';

export enum CorrelateSharingCodeActionType {
  CORRELATE_SHARING_CODE_START = 'CORRELATE_SHARING_CODE_START',
  CORRELATE_SHARING_CODE_SUCCESS = 'CORRELATE_SHARING_CODE_SUCCESS',
  CORRELATE_SHARING_CODE_ERROR = 'CORRELATE_SHARING_CODE_ERROR',
  CLEAR_CORRELATE_SHARING_CODE_DATA = 'CLEAR_SHARING_CODE_DATA',
}

export type CorrelateSharingCodePayload = CorrelatePatientWithPairingCodeParams;

export type CorrelateSharingCodeResponse =
  correlatePairingCodeTransformedResponseType;

export type CorrelateSharingCodeStartAction = PayloadAction<
  CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_START,
  CorrelateSharingCodePayload
>;

export type CorrelateSharingCodeSuccessAction = PayloadAction<
  CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_SUCCESS,
  CorrelateSharingCodeResponse
>;

export type CorrelateSharingCodeErrorAction = PayloadAction<
  CorrelateSharingCodeActionType.CORRELATE_SHARING_CODE_ERROR,
  CorrelateSharingCodeResponse
>;

export type ClearsharingCodeCorrelateAction =
  Action<CorrelateSharingCodeActionType.CLEAR_CORRELATE_SHARING_CODE_DATA>;

export type CorrelateSharingCodeActions =
  | CorrelateSharingCodeStartAction
  | CorrelateSharingCodeSuccessAction
  | CorrelateSharingCodeErrorAction
  | ClearsharingCodeCorrelateAction;

export type CorrelateSharingCodeState = {
  isCheckingCorrelation: boolean;
  correlationSuccessful: boolean;
  correlationHasError: boolean;
  correlationHasConflict: boolean;
};
