import { mockHba1c } from './new-entry-hba1c.mock';
import {
  PostNewEntryHba1cLoaderImpl,
  PostNewEntryHba1cServiceImpl,
  PostNewEntryHba1cTransformImpl,
} from './new-entry-hba1c.service';

const mockPosterPrograms = () => Promise.resolve(mockHba1c);

export const PostNewEntryHba1cFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPosterPrograms : PostNewEntryHba1cLoaderImpl;
  return PostNewEntryHba1cServiceImpl(loader, PostNewEntryHba1cTransformImpl);
};
