import React from 'react';
import { Bundle } from 'src/navigation/bundle';

export const WdtcModuleBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { WdtcModuleContainer } = await import(
        /* webpackChunkName: "wdtcModule" */ './wdtc-module.container'
      );
      return WdtcModuleContainer;
    }}
    bundleDidLoad={(WdtcModuleContainer) => <WdtcModuleContainer {...props} />}
  />
);
