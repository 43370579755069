import styled from 'styled-components';

import { MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM } from '../../graph.constants';

export const StandardDayTrendWrapperDiv = styled.div`
  height: 100%;
  min-height: ${MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 15px;
`;

StandardDayTrendWrapperDiv.displayName = 'StandardDayTrendWrapperDiv';
