import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { mapDispatchers } from 'src/domains/diagnostics/utils/map-dispatchers';
import { createModal } from 'src/core/modal/modal.actions';
import { MODAL_TYPES } from 'src/core/modal/modal.constants';

import { AdditionalInfoElement } from './additional-info.component';

import { selectUserProfile } from 'src/core/user/user.selectors';

import * as React from 'react';

export type AdditionalInfoProps = {
  handleClick: () => void;
  onInfoClickCallback?: () => void;
};

export type AdditionalInfoContainerProps = {
  info: string;
  active?: string;
  style?: object;
  size?: string;
  children?: React.ReactNode;
  textButton?: boolean;
  textButtonLabel?: string;
  onInfoClickCallback?: () => void;
};

export const AdditionalInfo = compose<
  AdditionalInfoProps,
  AdditionalInfoContainerProps
>(
  connect(
    createStructuredSelector({
      profile: selectUserProfile,
    }),
    mapDispatchers({
      createModal,
    }),
  ),
  withHandlers({
    handleClick:
      ({ createModal, onInfoClickCallback }) =>
      (content = []) => {
        createModal({
          key: MODAL_TYPES.ADDITIONAL_INFO,
          data: {
            ...content,
          },
        });
      },
    onInfoClickCallback: ({ onInfoClickCallback }) => onInfoClickCallback,
  }),
  withTranslation,
)(AdditionalInfoElement);
