import { useState } from 'react';
import { useCustomEventListener } from 'src/hooks';
import {
  JELLO_SEARCH_BOX_ACTION_EVENT,
  JELLO_SEARCH_BOX_INPUT_EVENT,
  MIN_CHARS_START_SEARCH,
} from 'src/widgets/top-navigation-jello/top-navigation-jello.constants';

type UseSearchBoxAlertProps = {
  searchCallback: (e: unknown) => void;
};

export const useSearchBoxAlert = ({
  searchCallback,
}: UseSearchBoxAlertProps) => {
  const [showMinAlert, setShowMinAlert] = useState(false);

  const searchBoxRef = useCustomEventListener(
    [JELLO_SEARCH_BOX_ACTION_EVENT, JELLO_SEARCH_BOX_INPUT_EVENT],
    (e: CustomEvent) => {
      searchCallback(e);
      const inputValue = e?.detail
        ? e.detail.target.value
        : (e as unknown as React.ChangeEvent<HTMLInputElement>).target.value;

      const shouldShowAlert =
        e.type === JELLO_SEARCH_BOX_ACTION_EVENT &&
        inputValue.length < MIN_CHARS_START_SEARCH &&
        inputValue !== '';
      const shouldHideAlert =
        e.type === JELLO_SEARCH_BOX_INPUT_EVENT &&
        (inputValue === '' || inputValue.length >= MIN_CHARS_START_SEARCH);

      setShowMinAlert(shouldShowAlert || (showMinAlert && !shouldHideAlert));
    },
  );

  return { showMinAlert, searchBoxRef };
};
