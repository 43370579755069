import styled from 'styled-components';
import { textAlign } from 'styled-system';

import { spacing } from 'src/core/styles/spacing';

const gridContainerColumns = 12;
const gridSpacing = spacing.four; // 2rem
const gridSpacingSmall = spacing.three; // 1rem
const gridSupport = 'display: grid'; // @supports query str

// CSS Grid wrapper
// Used as outer container for scenes
// Fallback style sets bottom padding to match grid-gap
export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${(props) => (props.marginTop ? spacing.four : '0')} -${gridSpacingSmall}
    ${(props) => (props.marginBottom ? spacing.four : '0')};
  min-width: 100%;
  height: ${(props) => (props.height ? props.height : 'initial')};

  @supports (${gridSupport}) {
    display: grid;
    grid-template-rows: ${(props) =>
      props.gridTemplateRows ? props.gridTemplateRows : 'initial'};
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    grid-gap: ${gridSpacing};
    grid-auto-flow: dense;
    margin-left: 0;
    margin-right: 0;
  }
`;
GridContainer.defaultProps = {
  columns: gridContainerColumns,
};
GridContainer.displayName = 'GridContainer';

// Convert grid item width to a percentage for use in Flexbox fallback
// Ex. span=3 will cover 3 / 12 columns
const calculateWidthFromGridSpan = (span, columns = gridContainerColumns) => {
  const percentageWidth = (span / columns) * 100;
  return `${percentageWidth}%`;
};

// CSS Grid child - item column width set by prop 'span'
export const GridItem = styled.div`
  padding: ${gridSpacingSmall};
  flex: 1 1 ${(props) => calculateWidthFromGridSpan(props.span, props.columns)};
  flex-wrap: wrap;
  max-width: ${(props) =>
    calculateWidthFromGridSpan(props.span, props.columns)};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'initial')};
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px !important` : '0'};
  ${textAlign};

  @supports (${gridSupport}) {
    display: block;
    flex: none;
    grid-column: span ${(props) => `${props.span}`};
    padding: 0;
    max-width: 100%;
    height: 100%;
  }
`;
GridItem.displayName = 'GridItem';

// By default, extend grid items to full width
GridItem.defaultProps = {
  span: gridContainerColumns,
};

// Created for correcting padding removal not beign applied in IE11
export const GridItemWithoutPadding = styled(GridItem)`
  padding: 0;
`;
GridItemWithoutPadding.displayName = 'GridItemWithoutPadding';

export const GridItemWithoutVerticalPadding = styled(GridItem)`
  padding-bottom: 0;
  padding-top: 0;
`;
GridItemWithoutVerticalPadding.displayName = 'GridItemWithoutVerticalPadding';
