import { isNil, pipe, sum } from 'ramda';

import { fixToDecimalPlace } from 'src/utils/stat';
import { toFormat, convertJSDateGMT } from 'src/utils/date';

import {
  BLOOD_GLUCOSE_CELL_LABELS,
  DAY_CELL_LABELS,
} from './logbook-stats.constants';

import { formatDateAsRowHeaderList } from '../../components/table/logbook-table/logbook-table.util';
import { getFormattedStandardDeviation } from '../../utils/stat.util';

const LOGBOOK_DATE_FORMAT = 'EEEE, d LLL yyyy';

const formatGMT = pipe(convertJSDateGMT, toFormat(LOGBOOK_DATE_FORMAT));

const isWeekendDay = (day) => {
  const date = convertJSDateGMT(day);
  const dayNum = date.weekday;
  return dayNum === 6 || dayNum === 7;
};

export const getDayCellLabel = (day) =>
  isWeekendDay(day) ? DAY_CELL_LABELS.WEEKEND : DAY_CELL_LABELS.N_A;

export const getBloodGlucoseCellLabel = (
  value,
  hypoglycemiaThreshold,
  glucoseIdealIntervalMin,
  glucoseIdealIntervalMax,
) => {
  if (value < hypoglycemiaThreshold) {
    return BLOOD_GLUCOSE_CELL_LABELS.HYPO;
  } else if (value < glucoseIdealIntervalMin) {
    return BLOOD_GLUCOSE_CELL_LABELS.BELOW_TARGET_RANGE;
  } else if (
    value >= glucoseIdealIntervalMin &&
    value <= glucoseIdealIntervalMax
  ) {
    return BLOOD_GLUCOSE_CELL_LABELS.IN_RANGE;
  } else if (value > glucoseIdealIntervalMax) {
    return BLOOD_GLUCOSE_CELL_LABELS.HYPER;
  }
};

export const getNumberOfHypos = (measurementValues, hypoglycemiaThreshold) => {
  const hypoCount = measurementValues.reduce((hypoCount, value) => {
    if (value > hypoglycemiaThreshold) {
      return hypoCount;
    }

    return hypoCount + 1;
  }, 0);

  return hypoCount !== 0 ? hypoCount : '';
};

export const getCarbohydrates = (carbohydrates) =>
  carbohydrates.length > 0 ? sum(carbohydrates) : '';

export const getDay = (date) => ({
  label: getDayCellLabel(date),
  value: formatDateAsRowHeaderList(formatGMT(date)),
});

export const getMeanBloodGlucose = (
  measurementValues,
  hypoglycemiaThreshold,
  glucoseIdealIntervalMin,
  glucoseIdealIntervalMax,
) => {
  const bgTotal = measurementValues.reduce((a, b) => a + b, 0);

  const meanBloodGlucoseValue =
    Math.round((bgTotal * 10) / measurementValues.length) / 10;
  return {
    label: getBloodGlucoseCellLabel(
      meanBloodGlucoseValue,
      hypoglycemiaThreshold,
      glucoseIdealIntervalMin,
      glucoseIdealIntervalMax,
    ),
    value: fixToDecimalPlace(meanBloodGlucoseValue, 1),
  };
};

export const getStandardDeviation = (values, bloodGlucoseUnit) => {
  if (values.length === 0) {
    return '';
  }

  return getFormattedStandardDeviation(values, Math.round, bloodGlucoseUnit);
};

export const convertToCellValue = (value) => (value > 0 ? value : '');

export const groupMeasurementsByDay = (measurements) => {
  const measurementsByDay = {};

  measurements.forEach((measurement) => {
    const date = formatGMT(measurement.date);

    if (isNil(measurementsByDay[date])) {
      measurementsByDay[date] = {
        date: measurement.date,
        glucoseMeasurements: [],
      };
    }

    measurementsByDay[date].glucoseMeasurements = [
      ...measurementsByDay[date].glucoseMeasurements,
      measurement,
    ];
  });

  return measurementsByDay;
};

export const groupInsulinByDay = (insulin) => {
  const insulinByDay = {};

  const initializeInsulin = (date) => ({
    date: date,
    totalBolusPlusBasal: 0,
    totalBolus: 0,
    numberOfBolus: 0,
    totalInsulinBolusType: 0,
  });

  insulin.totalBolusPlusBasal.forEach((bolusPlusBasal) => {
    const date = formatGMT(bolusPlusBasal.date);

    if (isNil(insulinByDay[date])) {
      insulinByDay[date] = initializeInsulin(bolusPlusBasal.date);
    }

    insulinByDay[date].totalBolusPlusBasal = bolusPlusBasal.bolusValue;
  });

  insulin.totalBolus.forEach((totalBolus) => {
    const date = formatGMT(totalBolus.date);

    if (isNil(insulinByDay[date])) {
      insulinByDay[date] = initializeInsulin(totalBolus.date);
    }

    insulinByDay[date].totalBolus = totalBolus.bolusValue;
  });

  insulin.bolus.forEach((bolus) => {
    const date = formatGMT(bolus.date);

    if (isNil(insulinByDay[date])) {
      insulinByDay[date] = initializeInsulin(bolus.date);
    }

    insulinByDay[date].numberOfBolus++;
    insulinByDay[date].totalInsulinBolusType += bolus.bolusValue;
  });

  return insulinByDay;
};

export const getNumberOfHIorLOValues = (
  glucoseMeasurements,
  measurementRangeType,
) => {
  return glucoseMeasurements.reduce((acc, val) => {
    if (val.rangeType === measurementRangeType) {
      acc += 1;
    }
    return acc;
  }, 0);
};

export const getTotalNumberOfTest = (
  numberOfTests,
  numberOfHIValues,
  numberOfLOValues,
) => {
  const sum =
    Number(numberOfTests) + Number(numberOfHIValues) + Number(numberOfLOValues);
  return sum > 0 ? sum : '';
};

export const getTotalHypos = (hypos, numberOfLOValues) => {
  const sum = Number(hypos) + Number(numberOfLOValues);
  return sum > 0 ? sum : '';
};
export const checkHasHIorLOValues = (numberOfHIValues, numberOfLOValues) =>
  !!(numberOfHIValues || numberOfLOValues);
