import React from 'react';
import { withTheme } from 'styled-components';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { SvgIcon } from 'src/components/icon/icon.component';

const BasalRateChangeIconComponent = ({
  x = 0,
  y = 0,
  width = 16,
  height = 16,
  theme,
  t,
}) => {
  const originalWidth = 16;
  const originalHeight = 16;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphDetails.legend.basalRateChange')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <rect
        x={x}
        y={y}
        fill={theme.colors.tbrBlue}
        width={originalWidth}
        height={originalHeight}
      />
      <path
        transform={`translate(${x}, ${y})`}
        fill={theme.colors.white}
        stroke={theme.colors.clear}
        d="M5.37794841,7.0816244 C5.53994841,7.0816244 5.69994841,7.0166244 5.81694841,6.8876244 L7.99594841,4.4896244 L10.1829484,6.8886244 C10.4029484,7.1296244 10.7789484,7.1476244 11.0219484,6.9276244 C11.2639484,6.7056244 11.2819484,6.3306244 11.0609484,6.0876244 L7.99494841,2.7246244 L4.93894841,6.0886244 C4.71794841,6.3316244 4.73594841,6.7066244 4.97894841,6.9276244 C5.09194841,7.0306244 5.23494841,7.0816244 5.37794841,7.0816244"
      />
      <path
        transform={`translate(${x}, ${y})`}
        fill={theme.colors.white}
        stroke={theme.colors.clear}
        d="M8.00485049,12.9986244 L11.0618505,9.6346244 C11.2818505,9.3916244 11.2638505,9.0166244 11.0218505,8.7956244 C10.7788505,8.5756244 10.4038505,8.5936244 10.1828505,8.8356244 L8.00385049,11.2326244 L5.81685049,8.8346244 C5.59585049,8.5936244 5.22085049,8.5756244 4.97785049,8.7956244 C4.73585049,9.0176244 4.71785049,9.3926244 4.93985049,9.6356244 L8.00485049,12.9986244 Z"
      />
    </SvgIcon>
  );
};

export const BasalRateChangeIcon = withTranslation(
  withTheme(BasalRateChangeIconComponent),
);
