import styled from 'styled-components';

import { colors } from '../../core/styles/colors';
import { fontWeights } from '../../core/styles/font-weights/font-weights';

export const NoDataToolTipText = styled.span`
  color: ${colors.gray33};
  font-weight: ${fontWeights.bold};
  font-size: 20px;
`;
NoDataToolTipText.displayName = 'NoDataToolTipText';

export const NoDataToolTipTextContainer = styled.div`
  padding: 0 0.5rem;
`;
NoDataToolTipTextContainer.displayName = 'NoDataToolTipTextContainer';

export const NoDataToolTipContainer = styled.div`
  position: relative;
`;
NoDataToolTipContainer.displayName = 'NoDataToolTipContainer';
