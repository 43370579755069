import * as React from 'react';

import { ITEMS_BG, ITEMS_CGM } from './filters.constants';

export const prefix = (key: string) => `hcpDashboard.${key}`;

export const setOptions = (id: string, options: any[]) => {
  const result: any[] = [];
  options.forEach(({ label, condition, label2, value }, i) => {
    result.push({
      key: `${id}-${i}`,
      label,
      condition,
      label2,
      value,
    });
  });
  return result;
};

export const setFilter = (id: string, title: string, options: any[]) => ({
  id,
  title: prefix(title),
  options: setOptions(id, options),
});

export const FILTERS: any[] = [
  setFilter('age', 'age', [
    { label: prefix('all'), value: 'all' },
    { label: '0-21', value: [null, 21] },
    { label: '22-25', value: [22, 25] },
    { label: '26-35', value: [26, 35] },
    { label: '36-45', value: [36, 45] },
    { label: '46-55', value: [46, 55] },
    { label: '56-65', value: [56, 65] },
    { label: '65+', value: [66, null] },
  ]),
  setFilter('gender', 'gender', [
    { label: prefix('all'), value: 'all' },
    { label: prefix('male'), value: 'MALE' },
    { label: prefix('female'), value: 'FEMALE' },
    { label: prefix('unspecified'), value: 'UNSPECIFIED' },
  ]),
  setFilter('diabetesType', 'diabetesType', [
    { label: prefix('all'), value: 'all' },
    { label: prefix('DIABETES_TYPE1'), value: 'DIABETES_TYPE1' },
    { label: prefix('DIABETES_TYPE2'), value: 'DIABETES_TYPE2' },
    { label: prefix('DIABETES_TYPE2_MDI'), value: 'DIABETES_TYPE2_MDI' },
    {
      label: prefix('DIABETES_GESTATIONAL'),
      value: 'DIABETES_GESTATIONAL',
    },
    {
      label: prefix('PENDING_TO_IDENTIFY'),
      value: 'PENDING_TO_IDENTIFY',
    },
  ]),
  setFilter('testFrequency', 'avgTestPerDay', [
    { label: '< 2', value: [null, 1.9] },
    { label: '2', value: [2, 2.9] },
    { label: '3-4', value: [3, 4] },
    { label: '> 4', value: [4.1, null] },
  ]),
  setFilter('hbA1c', 'hbA1c', [
    { label: '< 7', value: [null, 6.9] },
    { label: '7-8', value: [7, 8] },
    { label: '8-9', value: [8, 9] },
    { label: '> 9', value: [9.1, null] },
  ]),
  setFilter('variability', 'variability', [
    { label: '>= 36%', value: [36, null] },
  ]),
  setFilter('lastDeviceSync', 'lastDeviceSync', [
    {
      label: `${prefix('lastDeviceSync')}`,
      condition: '< 2',
      label2: `${prefix('days')}`,
      value: [0, 1],
    },
    {
      label: `${prefix('lastDeviceSync')}`,
      condition: '2-4',
      label2: `${prefix('days')}`,
      value: [2, 4],
    },
    {
      label: `${prefix('lastDeviceSync')}`,
      condition: '> 4',
      label2: `${prefix('days')}`,
      value: [5, null],
    },
  ]),
  setFilter('hcpIsAccessible', 'remoteAccess', [
    { label: prefix('yes'), value: true },
    { label: prefix('no'), value: false },
  ]),
  setFilter('rpmStatus', 'rpmStatus', [
    { label: prefix('no'), value: 'NON_RPM' },
    { label: prefix('yesCompliant'), value: 'YES_COMPLIANT' },
    { label: prefix('yesNonCompliant'), value: 'YES_NON_COMPLIANT' },
  ]),
  setFilter('cgmActiveTime', 'cgmActiveTime', [
    { label: '< 70%', value: [null, 69.9] },
    { label: '>= 70%', value: [70, null] },
  ]),
  setFilter('timeInRange', 'timeInRange', [
    { label: '50-70%', value: [50, 70] },
    { label: '> 70%', value: [70.1, null] },
  ]),
  setFilter('timeBellowRange', 'timeBellowRange', [
    { label: '1-4%', value: [1, 4] },
    { label: '> 4%', value: [4.1, null] },
  ]),
  setFilter('gmi', 'gmi', [
    { label: '< 7.5%', value: [null, 7.4] },
    { label: '>= 7.5%', value: [7.5, null] },
  ]),
  setFilter('checkInStatus', 'checkInStatus', [
    {
      label: `${prefix('lastChecked')}`,
      condition: '< 7',
      label2: `${prefix('days')}`,
      value: [null, 6],
    },
    {
      label: `${prefix('lastChecked')}`,
      condition: '7-30',
      label2: `${prefix('days')}`,
      value: [7, 30],
    },
    {
      label: `${prefix('lastChecked')}`,
      condition: '> 30',
      label2: `${prefix('days')}`,
      value: [31, null],
    },
  ]),
  setFilter('patientStatus', 'patientStatus', [
    {
      condition: '2',
      label2: `${prefix('orMoreHypos')}`,
      value: [1.9, null],
    },
  ]),
];

export const DEFAULT_FILTERS = Object.assign(
  {},
  ...FILTERS.map((f) => ({ [f.id]: ['all'] })),
);

export const getFilters = (isCgm = false) => {
  const filters: any[] = [];
  const items = isCgm ? ITEMS_CGM : ITEMS_BG;
  items.forEach((i) => filters.push(...FILTERS.filter((f) => f.id === i)));
  return filters;
};

export const getLabel = (label) => (
  <span
    dangerouslySetInnerHTML={{
      __html: label.replace('>=', '&#8805;').replace('<=', '&#8804;'),
    }}
  />
);

export const conuntlyFiltersTransformer = (values) => {
  const transformedObject = {};
  for (const filter in values) {
    if (values.hasOwnProperty(filter)) {
      Object.defineProperty(transformedObject, filter, {
        value: values[filter].toString() !== 'all',
      });
    }
  }
  return transformedObject;
};
