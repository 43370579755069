import { UNITS } from '../../device-settings.utils';

export const getLabelFromAttribute = (attr) => {
  const labels = {
    AfterHighBG: 'deviceSettings.tabReminder.afterMeal',
    AfterLowBG: 'deviceSettings.tabReminder.afterLowBg',
    AfterMeal: 'deviceSettings.tabReminder.afterHighBg',
  };
  return labels[attr] || attr;
};

export const getUnitFromAttribute = (attr, units = {}) => {
  const labels = {
    Duration: UNITS.MINUTES,
    EventThreshold: units[UNITS.GLUCO],
    CarbThreshold: units[UNITS.CARBO],
  };
  return labels[attr];
};

export const TRANSLATE_PREFIX = 'deviceSettings.tabReminder';

export const bgReminderHeaderKeys = [
  { key: 'Name', header: 'deviceSettings.tabReminder.bgTableTitle' },
  { key: 'Enabled', header: 'deviceSettings.tabReminder.status' },
  { key: 'Duration', header: 'deviceSettings.tabReminder.duration' },
  { key: 'EventThreshold', header: 'deviceSettings.tabReminder.bgThreshold' },
  { key: 'CarbThreshold', header: 'deviceSettings.tabReminder.carboThreshold' },
];

export const TabRemaindersStrings = {
  STATUS: 'Status',
  OFF: 'Off',
  ENABLED: 'Enabled',
  VALUE: 'value',
  DISABLED: 'disabled',
  VALUE_HL: 'valueHighlighted',
  ON: 'ON',
};
