import {
  PatientTimeBlocksActions,
  PatientTimeBlocksActionType,
  TimePeriodsState,
} from './time-periods.types';

export const TIME_PERIODS_INITIAL_STATE: TimePeriodsState = {
  isFetchingBlocks: false,
  isFetchingBlocksError: false,
  isEditingBlocks: false,
  isSavingBlocks: false,
  isSavingBlocksError: false,
  blocksSaved: false,
  blocks: null,
};

export const timePeriodsReducer = (
  state = TIME_PERIODS_INITIAL_STATE,
  action: PatientTimeBlocksActions,
): TimePeriodsState => {
  switch (action.type) {
    case PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_START:
      return {
        ...TIME_PERIODS_INITIAL_STATE,
        isFetchingBlocks: true,
      };
    case PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_SUCCESS:
    case PatientTimeBlocksActionType.MOVE_TIME_BLOCKS_UI:
      return {
        ...TIME_PERIODS_INITIAL_STATE,
        blocks: action.payload,
      };
    case PatientTimeBlocksActionType.FETCH_PATIENT_TIMEBLOCKS_ERROR:
      return {
        ...TIME_PERIODS_INITIAL_STATE,
        isFetchingBlocksError: true,
      };
    case PatientTimeBlocksActionType.SAVE_PATIENT_TIMEBLOCKS_SUCCESS:
      return {
        ...TIME_PERIODS_INITIAL_STATE,
        blocks: action.payload,
        blocksSaved: true,
      };
    case PatientTimeBlocksActionType.SAVE_PATIENT_TIMEBLOCKS_START:
      return {
        ...state,
        isSavingBlocks: true,
      };
    case PatientTimeBlocksActionType.SAVE_PATIENT_TIMEBLOCKS_ERROR:
      return {
        ...state,
        isSavingBlocks: false,
        isSavingBlocksError: true,
      };
    case PatientTimeBlocksActionType.PATIENT_TIMEBLOCKS_STATUS_RESET:
      return {
        ...state,
        blocksSaved: false,
        isSavingBlocksError: false,
      };
    default:
      return state;
  }
};
