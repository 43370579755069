import {
  EditProfileLoaderImpl,
  EditProfileServiceImpl,
  EditProfileTransformImpl,
} from './edit-profile.service';

import { mockEditProfileResponse } from './edit-profile.mock';

const mockPoster = () => Promise.resolve(mockEditProfileResponse);

export const EditProfileFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPoster : EditProfileLoaderImpl;
  return EditProfileServiceImpl(loader, EditProfileTransformImpl);
};
