import { PatientInfo } from '../../patient-info.types';
import { MethodOfCommunication } from './time-tracker-modal.constants';

export type PatientListKey =
  | 'patientCareActivities'
  | 'trainingPatient'
  | 'goalsAndTreatments'
  | 'dataTargeAnalysis';

export type ModalStateToApiRequestProps = TimeTrackerModalState;

export type ModalStateToApiRequestFormatOutput = {
  methodOfCommunication: MethodOfCommunication | string;
  patientCareActivitiesSelected: string[];
  patientCareActivitiesNotSelected: string[];
  summary: string;
};

export type ModalOutputData = ModalStateToApiRequestFormatOutput;

export type PatientListItem = {
  id: string | MethodOfCommunication;
  value: string;
  label: string;
};

export type TimeTrackerModalProps = {
  data: {
    patientSessionTime: number;
    showErrorMessage: boolean;
    patient: PatientInfo;
    onSaveModal: (props: OnSaveModalProps) => void;
    onClearInterval: () => void;
    setSessionToNotRunning: () => void;
  };
  destroyModal: () => void;
  t: (prop: string) => string;
  modalCreationType?: ModalCreationType;
  isOpen?: boolean;
  endSessionStart?: () => void;
  setIsOpen?: () => void;
  patientId?: number;
};

export enum ModalCreationType {
  'BY_CREATE_MODAL',
  'BY_COMPONENT',
}

export type OnSaveModalProps = {
  data: ModalOutputData;
  destroyModal: () => void;
};

export type TimeTrackerModalState = {
  patientCareNote: string;
  communication: PatientListItem;
  patientCareActivities: PatientListItem[];
  dataTargeAnalysis: PatientListItem[];
  trainingPatient: PatientListItem[];
  goalsAndTreatments: PatientListItem[];
};
