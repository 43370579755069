import { match } from 'ramda';
import {
  putJSON,
  createAuthHeader,
  stringifyBody,
} from 'src/utils/service/service.utils';
import {
  EditProfileParams,
  EditProfileResponse,
  EC6Model,
} from './edit-profile.types';

import { ENDPOINTS } from 'src/services/service.constants';

export const EditProfileLoaderImpl = (
  { profile }: EditProfileParams,
  accessToken: string,
) =>
  putJSON(ENDPOINTS.practitioners, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
    body: stringifyBody(profile),
  });

export const EditProfileTransformImpl = ({
  model,
}: EditProfileResponse): EC6Model => ({
  profileId: Number(match(/([0-9](.*))/g, model)[0]),
});

export const EditProfileServiceImpl =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);
