import { DateTime } from 'luxon';
import { pathOr } from 'ramda';

export const getLastSync = (dateLastSync) => {
  if (dateLastSync) {
    return Math.abs(
      Math.trunc(Number(DateTime.fromISO(dateLastSync).diffNow('minutes'))),
    );
  }
  return null;
};

export const searchTransform = (data) => {
  const isCGM = data.model.cgmClinicalStatisticDTOs.length !== 0;
  const stats =
    data.model.cgmClinicalStatisticDTOs.length !== 0
      ? data.model.cgmClinicalStatisticDTOs
      : data.model.clinicalStatisticDTOs;
  stats.forEach((p) => {
    const pInfo = p.patientInfoDTO;
    const gInfo = p.glycatedInfoDTO;
    const totalGldHigh = isCGM
      ? p.patientCgmClinicalStatisticsDTO
        ? p.patientCgmClinicalStatisticsDTO.gldHigh +
          p.patientCgmClinicalStatisticsDTO.gldVeryHigh
        : null
      : p.patientClinicalStatisticsDTO
      ? p.patientClinicalStatisticsDTO.gldHigh +
        p.patientClinicalStatisticsDTO.gldVeryHigh
      : null;
    const totalGldLow = isCGM
      ? p.patientCgmClinicalStatisticsDTO
        ? p.patientCgmClinicalStatisticsDTO.gldLow +
          p.patientCgmClinicalStatisticsDTO.gldVeryLow
        : null
      : p.patientClinicalStatisticsDTO
      ? p.patientClinicalStatisticsDTO.gldLow +
        p.patientClinicalStatisticsDTO.gldVeryLow
      : null;

    const gldCheckHigh = totalGldHigh === 0 ? null : totalGldHigh;

    const gldCheckLow = totalGldLow === 0 ? null : totalGldLow;

    const cInfo = p.patientClinicalStatisticsDTO
      ? p.patientClinicalStatisticsDTO
      : p.patientCgmClinicalStatisticsDTO;

    p.patientClinicalStatisticsDTO = isCGM
      ? {
          ...p.patientClinicalStatisticsDTO,
        }
      : {
          ...p.patientClinicalStatisticsDTO,
          gldCheckHigh,
          gldCheckLow,
        };
    p.patientCgmClinicalStatisticsDTO = isCGM
      ? {
          ...p.patientCgmClinicalStatisticsDTO,
          gldCheckHigh,
          gldCheckLow,
        }
      : {
          ...p.patientCgmClinicalStatisticsDTO,
        };
    p.sort = {};
    p.sort.lastName = pInfo.fullName;
    p.sort.deviceSync = getLastSync(pathOr(null, ['lastDeviceSync'], cInfo));
    p.sort.testDay = pathOr(null, ['numberOfTests'], cInfo);
    p.sort.lastHba1c = pathOr(null, ['hba1cValue'], gInfo);
    p.sort.hypers = pathOr(null, ['hyperEvents'], cInfo);
    p.sort.hypos = pathOr(null, ['hypoEvents'], cInfo);
    p.sort.averageBG = pathOr(null, ['averageBloodGlucose'], cInfo);
    p.sort.standardDeviation = pathOr(null, ['standardDeviation'], cInfo);
    p.sort.cgmActiveTime = pathOr(null, ['cgmActiveTime'], cInfo);
    p.sort.gmi = pathOr(null, ['glucoseManagementIndicator'], cInfo);
  });

  const cgmPatientsNumber = data.model.clinicalStatisticsSummaryDTO
    ? data.model.clinicalStatisticsSummaryDTO.totalPatientsCgm
    : 0;
  const bgPatientsNumber = data.model.clinicalStatisticsSummaryDTO
    ? data.model.clinicalStatisticsSummaryDTO.totalPatientsBg
    : 0;

  return {
    bgPatientsNumber,
    cgmPatientsNumber,
    stats,
  };
};
