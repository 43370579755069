export const mockClinicGuides = [
  {
    id: 'e18aed49-6296-45fd-b5dd-c027c6b036f3',
    maximumStrips: 15,
    minimumStrips: 0,
    name: 'Insuline',
    period: 'days',
    type: 'preset',
    isRemovable: false,
    isActive: true,
    therapyId: '1efbc371-50b6-4c19-8b16-70f9c0ef3e90',
  },
  {
    id: 'e28aed49-6296-45fd-b5dd-c027c6b036f3',
    maximumStrips: 15,
    minimumStrips: 0,
    name: 'Accu chek',
    period: 'days',
    type: 'preset',
    isRemovable: false,
    isActive: true,
    therapyId: '1efbc371-50b6-4c19-8b16-70f9c0ef3e90',
  },
  {
    id: '4bb8d615-2230-4fa9-af7b-e52bf67914f4',
    maximumStrips: 15,
    minimumStrips: 3,
    name: 'custom guide 1',
    period: 'days',
    therapyId: '1efbc371-50b6-4c19-8b16-70f9c0ef3e90',
    type: 'custom',
    isRemovable: false,
    isActive: true,
    organizationId: 11,
    hcpId: 3214,
    ownerName: 'Dr. Peter',
    updatedAt: 'June 20 2018',
  },
  {
    id: '66593c75-c25c-430d-b567-73831fcb9d25',
    maximumStrips: 15,
    minimumStrips: 1,
    name: 'custom guide 2',
    period: 'weeks',
    therapyId: '1efbc371-50b6-4c19-8b16-70f9c0ef3e90',
    type: 'custom',
    isRemovable: true,
    isActive: true,
    organizationId: 11,
    hcpId: 3214,
    ownerName: 'Dr. Marry',
    updatedAt: 'June 21 2018',
  },
  {
    id: '4bc8d615-2230-4fa9-af7b-e52bf67914f4',
    maximumStrips: 25,
    minimumStrips: 0,
    name: 'custom guide 3',
    period: 'weeks',
    therapyId: '1efbc371-50b6-4c19-8b16-70f9c0ef3e90',
    type: 'custom',
    isRemovable: false,
    isActive: false,
    organizationId: 11,
    hcpId: 3214,
    ownerName: 'Dr. Hanna',
    updatedAt: 'June 23 2018',
  },
];
